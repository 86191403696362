import { h, Component } from 'preact';


export default class SuggestionBox extends Component {

	// Make sure we never update it (its content is handled by our code)
	shouldComponentUpdate() {
		return false;
	}

	render({ ...props }) {
		return (
			<div {...props}></div>
		);
	}

}
