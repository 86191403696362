/* Action Types
-----------------------*/

export const UPDATE_STORE      = 'UPDATE_STORE';
export const BEFORE_LOAD       = 'BEFORE_LOAD';
export const PAGE_STATE_LOADED = 'PAGE_STATE_LOADED';
export const START_API         = 'START_API';
export const GMAPS_LOADED      = 'GMAPS_LOADED';
export const URL_CHANGED       = 'URL_CHANGED';
export const API_ERROR         = 'API_ERROR';

export const CLOSE_APP = 'CLOSE_APP';

// Loader
export const UPDATE_LOADER = 'UPDATE_LOADER';

// Bg Status
export const SET_BG_STATUS   = 'SET_BG_STATUS';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';

// App Dimensions/Browser Stats
export const SET_CLIENT_SIZE                = 'SET_CLIENT_SIZE';
export const START_FORCE_CLIENT_ORIENTATION = 'START_FORCE_CLIENT_ORIENTATION';
export const STOP_FORCE_CLIENT_ORIENTATION  = 'STOP_FORCE_CLIENT_ORIENTATION';

// Background
export const SET_BACKGROUND_VIDEO_PLAYING = 'SET_BACKGROUND_VIDEO_PLAYING';
export const SET_BACKGROUND_VIDEO_ENABLED = 'SET_BACKGROUND_VIDEO_ENABLED';
export const SET_BACKGROUND_VIDEO_MUTED   = 'SET_BACKGROUND_VIDEO_MUTED';
export const SET_BACKGROUND_ELEMENT       = 'SET_BACKGROUND_ELEMENT';
export const UPDATE_BACKGROUND_SETTINGS   = 'UPDATE_BACKGROUND_SETTINGS';

// Home page
export const CALENDAR_CLICKED       = 'CALENDAR_CLICKED';
export const MESSENGER_CLICKED      = 'MESSENGER_CLICKED';
export const HOMEPAGE_TRAILER_ENDED = 'HOMEPAGE_TRAILER_ENDED';
export const CLICKED_FOLLOW         = 'CLICKED_FOLLOW';
export const HOME_BUTTON_CLICKED    = 'HOME_BUTTON_CLICKED';

// Gallery
export const GALLERY_VIEW_IMAGE            = 'GALLERY_VIEW_IMAGE';
export const GALLERY_VIEW_IMAGE_FULLSCREEN = 'GALLERY_VIEW_IMAGE_FULLSCREEN';
export const GALLERY_DOWNLOAD_IMAGE        = 'GALLERY_DOWNLOAD_IMAGE';

// Videos
export const VIDEO_PLAYER_PROGRESS = 'VIDEO_PLAYER_PROGRESS';
export const VIMEO_VIDEO_PROGRESS  = 'VIMEO_VIDEO_PROGRESS';
export const VIDEOS_TICKET_CLICK   = 'VIDEOS_TICKET_CLICK';
export const KALTURA_VIDEO = 'KALTURA_VIDEO';
export const MATTERHORN_VIDEO = 'MATTERHORN_VIDEO';
export const JW_PLAYER_PROGRESS = 'JW_PLAYER_PROGRESS';

// Home Ent
export const HOMEENT_VENDOR_CLICKED     = 'HOMEENT_VENDOR_CLICKED';
export const SMART_HOMEENT_SELECT       = 'SMART_HOMEENT_SELECT';
export const SMART_HOMEENT_LINK_CLICKED = 'SMART_HOMEENT_LINK_CLICKED';

// Menu
export const SET_SIDE_NAV_OPEN     = 'SET_SIDE_NAV_OPEN';
export const SET_SIDE_NAV_ACTIVE   = 'SET_SIDE_NAV_ACTIVE';
export const CLICKED_SHARE         = 'CLICKED_SHARE';
export const LINK_CLICKED          = 'LINK_CLICKED';
export const EXTERNAL_LINK_CLICKED = 'EXTERNAL_LINK_CLICKED';
export const HEADER_HIDDEN         = 'HEADER_HIDDEN';

// Footer
// export const FOOTER_SIZE = 'FOOTER_SIZE';

// Client Geo
export const SET_CLIENT_GEO = 'SET_CLIENT_GEO';
export const GET_SCREENINGS = 'GET_SCREENINGS';

// Dynamic Data
export const UPDATE_DYNAMIC_DATA = 'UPDATE_DYNAMIC_DATA';

// Theaters
export const SET_THEATERS                       = 'SET_THEATERS';
export const ADD_THEATERS                       = 'ADD_THEATERS';
export const SELECT_THEATER                     = 'SELECT_THEATER';
export const SELECT_MACCS_THEATER               = 'SELECT_MACCS_THEATER';
export const VISIBLE_MACCS_THEATER              = 'VISIBLE_MACCS_THEATER';
// export const SELECT_FIRST_THEATER               = 'SELECT_FIRST_THEATER';
export const SET_MAP_OPEN                       = 'SET_MAP_OPEN';
export const SET_CITIES                         = 'SET_CITIES';
export const SET_CITY_SHOW_COUNT                = 'SET_CITY_SHOW_COUNT';
export const SELECT_NEARBY_CITY                 = 'SELECT_NEARBY_CITY';
export const UPDATE_THEATER_FILTERED_SCREENINGS = 'UPDATE_THEATER_FILTERED_SCREENINGS';
export const AFTER_THEATERS_UPDATE              = 'AFTER_THEATERS_UPDATE';
export const EXTRA_THEATERS                     = 'EXTRA_THEATERS';
export const DO_LOCATION_SEARCH                 = 'DO_LOCATION_SEARCH';
export const FIRST_DATA_LOADED                  = 'FIRST_DATA_LOADED';
export const SCREENINGS_LOADED                  = 'SCREENINGS_LOADED';
export const SELECT_ST_DATE                     = 'SELECT_ST_DATE';
export const SELECT_PROVIDER                    = 'SELECT_PROVIDER';
export const SELECT_TIME                        = 'SELECT_TIME'; // DON'T USE FOR TRACKING, use SELECT_SCREENING INSTEAD
export const SELECT_SCREENING                   = 'SELECT_SCREENING';
export const TICKET_LINK_CLICKED                = 'TICKET_LINK_CLICKED';
export const SHOW_SCREENING_AUTH                = 'SHOW_SCREENING_AUTH';

// Ticketing
export const OPEN_TICKETING               = 'OPEN_TICKETING';
export const REOPEN_TICKETING             = 'REOPEN_TICKETING';
export const GO_TICKETING_TYPES           = 'GO_TICKETING_TYPES';
export const UPDATE_TICKET_TYPE_QUANTITY  = 'UPDATE_TICKET_TYPE_QUANTITY';
export const CREATE_OR_UPDATE_ORDER       = 'CREATE_OR_UPDATE_ORDER';
export const UPDATE_ORDER_TICKETS         = 'UPDATE_ORDER_TICKETS';
export const GO_TICKETING_SEATING         = 'GO_TICKETING_SEATING';
export const PICK_OR_UNPICK_SEATS         = 'PICK_OR_UNPICK_SEATS';
export const UPDATE_TICKETING_SEATS       = 'UPDATE_TICKETING_SEATS';
export const FLUSH_SEAT_LAYOUT            = 'FLUSH_SEAT_LAYOUT';
export const GO_TICKETING_REVIEW          = 'GO_TICKETING_REVIEW';
export const GO_TICKETING_CONTACT_DETAILS = 'GO_TICKETING_CONTACT_DETAILS';
export const GO_TICKETING_PAYMENT         = 'GO_TICKETING_PAYMENT';
export const OPEN_PAYMENT                 = 'OPEN_PAYMENT';
export const PAYMENT_MESSAGE_RECEIVED     = 'PAYMENT_MESSAGE_RECEIVED';
export const CLOSE_PAYMENT                = 'CLOSE_PAYMENT';
export const TICKET_PURCHASE_COMPLETE     = 'TICKET_PURCHASE_COMPLETE';
export const SET_TICKETING_MESSAGE        = 'SET_TICKETING_MESSAGE';
export const CLEAR_TICKETING_MESSAGE      = 'CLEAR_TICKETING_MESSAGE';
export const GO_TICKETING_TIMEOUT         = 'GO_TICKETING_TIMEOUT';
export const CLOSE_TICKETING              = 'CLOSE_TICKETING';
export const MAP_REQUEST_DIRECTIONS       = 'MAP_REQUEST_DIRECTIONS';

// Filters
export const CHECK_FILTERS         = 'CHECK_FILTERS';
export const RELOAD_FILTERS        = 'RELOAD_FILTERS';
export const TOGGLE_FILTER         = 'TOGGLE_FILTER';
export const FILTER_EXCLUDE        = 'FILTER_EXCLUDE';
export const FILTER_DISABLE        = 'FILTER_DISABLE';
export const SET_FILTER_VISIBILITY = 'SET_FILTER_VISIBILITY';
export const RESET_FILTER          = 'RESET_FILTER';
export const RESET_FILTERS         = 'RESET_FILTERS';
export const APPLY_FILTERS         = 'APPLY_FILTERS';
export const SEARCH_NEW_CITY       = 'SEARCH_NEW_CITY';

// Modal
export const SHOW_MODAL          = 'SHOW_MODAL';
export const HIDE_MODAL          = 'HIDE_MODAL';
export const UPDATE_MODAL        = 'UPDATE_MODAL';
export const SHOW_COOKIES_MODAL  = 'SHOW_COOKIES_MODAL';
// MODAL RESULTS (should probably move that somewhere else)
export const MODAL_RESULT_OK     = 'OK';
export const MODAL_RESULT_CANCEL = 'CANCEL';

export const APP_LIST_SCROLLED        = 'APP_LIST_SCROLLED';
export const MOVIES_LIST_SCROLLED     = 'MOVIES_LIST_SCROLLED';
export const SCREENINGS_LIST_SCROLLED = 'SCREENINGS_LIST_SCROLLED';
export const EVENT_DATA_LIST_SCROLLED = 'EVENT_DATA_LIST_SCROLLED';

// QUIZ
export const QUIZ_STARTED = 'QUIZ_STARTED';
export const RIGHT_OR_WRONG_QUIZ_FINISHED = 'RIGHT_OR_WRONG_QUIZ_FINISHED';

// USER
export const SET_USER = 'SET_USER';
export const SET_USER_REGION_MODAL_DISMISSED = 'SET_USER_REGION_MODAL_DISMISSED';

export const SET_IOSFIXEDFIX = 'SET_IOSFIXEDFIX';

// YOUTUBE
export const YOUTUBE_READY    = 'YOUTUBE_READY';
export const YOUTUBE_PROGRESS = 'YOUTUBE_PROGRESS';
export const YOUTUBE_ENDED    = 'YOUTUBE_ENDED';
export const YOUTUBE_PAUSE    = 'YOUTUBE_PAUSE';
export const YOUTUBE_PLAY     = 'YOUTUBE_PLAY';
export const YOUTUBE_START    = 'YOUTUBE_START';

// VR
export const VR_SET_MODE = 'VR_SET_MODE';

// SCROLL CONTROLS
export const SCROLL_LOCK   = 'SCROLL_LOCK';
export const SCROLL_UNLOCK = 'SCROLL_UNLOCK';

// TRACKING
export const ONETRUST_SHOW   = 'ONETRUST_SHOW';
export const ENABLE_STORAGE  = 'ENABLE_STORAGE';
export const DISABLE_STORAGE = 'DISABLE_STORAGE';
export const ENABLE_PIXELS   = 'ENABLE_PIXELS';
export const DISABLE_PIXELS  = 'DISABLE_PIXELS';
export const POWSTER_GA_EVENT_TRACK = 'POWSTER_GA_EVENT_TRACK';
export const POWSTER_GA_PAGE_VIEW_TRACK = 'POWSTER_GA_PAGE_VIEW_TRACK';
export const LOAD_STATIC_PIXELS = 'LOAD_STATIC_PIXELS';
export const LINK_CLICK_OUT = 'LINK_CLICK_OUT';

// AUTHENTICATION
export const AUTH_RESPONSE = 'AUTH_RESPONSE';
export const AUTH_CLEAR    = 'AUTH_CLEAR';

// CLOSABLE TRAILER
export const CLOSABLE_TRAILER_OPEN   = 'CLOSABLE_TRAILER_OPEN';
export const CLOSABLE_TRAILER_CLOSED = 'CLOSABLE_TRAILER_CLOSED';

// NETFLIX BOOKMARKS
export const DOWNLOAD_WORKBOOK = 'DOWNLOAD_WORKBOOK';

// SCROLLSITE
export const SCROLLSITE_BUTTON_CLICK = 'SCROLLSITE_BUTTON_CLICK';
export const SCROLLSITE_GALLERY_ITEM_CLICK = 'SCROLLSITE_GALLERY_ITEM_CLICK';
export const SCROLLSITE_RESOURCE_CLICK = 'SCROLLSITE_RESOURCE_CLICK';

// SIGN UP
export const SIGN_UP_SUBMIT = 'SIGN_UP_SUBMIT';
