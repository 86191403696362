import { h } from 'preact';
import defaultsDeep from '@nodeutils/defaults-deep';
import { show as showQuickInput } from 'utils/quickInput';
import { clamp } from 'utils/math';

const icons = {
	string: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Text</title><path d="m12.396 14.167 1.666-3.334h-3.229V5h5.834v5.792l-1.688 3.375Zm-7.5 0 1.666-3.334H3.333V5h5.834v5.792l-1.688 3.375Z" /></svg>,
	number: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Number</title><path d="m5.5 16 .625-2.5H3l.375-1.5H6.5l.875-3.5H4L4.375 7H7.75l.75-3H10l-.75 3h3L13 4h1.5l-.75 3H17l-.375 1.5h-3.25L12.5 12H16l-.375 1.5h-3.5L11.5 16H10l.625-2.5h-3L7 16ZM8 12h3l.875-3.5h-3Z" /></svg>,
	boolean: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Toggle</title><path d="M10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18ZM6.25 9.062 7.292 8l1.062 1.062.708-.708L8 7.292 9.062 6.25l-.708-.729-1.062 1.062L6.25 5.521l-.729.729 1.062 1.042-1.062 1.062ZM10 16.5q2.729 0 4.615-1.896Q16.5 12.708 16.5 10q0-1.25-.479-2.448t-1.417-2.156l-9.208 9.208q.958.958 2.156 1.427Q8.75 16.5 10 16.5Zm1.646-2.208-1.771-1.771.708-.709 1.063 1.063 1.937-1.937.709.708Z" /></svg>,
	array: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Array</title><path d="M12 16v-1.5h2.5v-9H12V4h4v12Zm-8 0V4h4v1.5H5.5v9H8V16Z" /></svg>,
	object: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Item</title><path d="M12 16v-1.5h1.75q.312 0 .531-.219.219-.219.219-.531v-1.5q0-.854.573-1.469.573-.614 1.427-.719v-.083q-.854-.167-1.427-.771-.573-.604-.573-1.458v-1.5q0-.312-.219-.531-.219-.219-.531-.219H12V4h1.75q.938 0 1.594.656Q16 5.312 16 6.25v1.5q0 .312.219.531.219.219.531.219H18v3h-1.25q-.312 0-.531.219-.219.219-.219.531v1.5q0 .938-.656 1.594-.656.656-1.594.656Zm-5.75 0q-.938 0-1.594-.656Q4 14.688 4 13.75v-1.5q0-.312-.219-.531-.219-.219-.531-.219H2v-3h1.25q.312 0 .531-.219Q4 8.062 4 7.75v-1.5q0-.938.656-1.594Q5.312 4 6.25 4H8v1.5H6.25q-.312 0-.531.219-.219.219-.219.531v1.5q0 .875-.573 1.49-.573.614-1.427.718v.084q.854.083 1.427.708.573.625.573 1.5v1.5q0 .312.219.531.219.219.531.219H8V16Z" /></svg>,
	null: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Disabled</title><path d="M10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Zm0-1.5q2.708 0 4.604-1.896T16.5 10q0-1.146-.375-2.167t-1.042-1.854l-9.104 9.104q.833.667 1.854 1.042Q8.854 16.5 10 16.5Zm-5.083-2.479 9.104-9.104q-.833-.667-1.854-1.042Q11.146 3.5 10 3.5q-2.708 0-4.604 1.896T3.5 10q0 1.146.375 2.167t1.042 1.854Z" /></svg>,
	color: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Color</title><path d="M10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.635-3.115.636-1.447 1.75-2.541Q5.5 3.25 6.99 2.625 8.479 2 10.167 2q1.625 0 3.052.562 1.427.563 2.489 1.542 1.063.979 1.677 2.292Q18 7.708 18 9.208q0 2-1.396 3.396T13.208 14h-1.416q-.167 0-.292.104-.125.104-.125.271 0 .313.313.521Q12 15.104 12 16q0 .771-.562 1.385Q10.875 18 10 18Zm-4.5-7.25q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q6.021 8.25 5.5 8.25t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm2.5-3q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q8.521 5.25 8 5.25t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm4 0q.521 0 .885-.365.365-.364.365-.885t-.365-.885Q12.521 5.25 12 5.25t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Zm2.5 3q.521 0 .885-.365.365-.364.365-.885t-.365-.885q-.364-.365-.885-.365t-.885.365q-.365.364-.365.885t.365.885q.364.365.885.365Z" /></svg>,
	date: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Date</title><path d="M12 15q-.833 0-1.417-.583Q10 13.833 10 13q0-.833.583-1.417Q11.167 11 12 11q.833 0 1.417.583Q14 12.167 14 13q0 .833-.583 1.417Q12.833 15 12 15Zm-7.5 3q-.625 0-1.062-.448Q3 17.104 3 16.5v-11q0-.604.438-1.052Q3.875 4 4.5 4H6V2h1.5v2h5V2H14v2h1.5q.625 0 1.062.448Q17 4.896 17 5.5v11q0 .604-.438 1.052Q16.125 18 15.5 18Zm0-1.5h11V9h-11v7.5Z" /></svg>,
	datetime: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Date+Time</title><path d="M12 15q-.833 0-1.417-.583Q10 13.833 10 13q0-.833.583-1.417Q11.167 11 12 11q.833 0 1.417.583Q14 12.167 14 13q0 .833-.583 1.417Q12.833 15 12 15Zm-7.5 3q-.625 0-1.062-.448Q3 17.104 3 16.5v-11q0-.604.438-1.052Q3.875 4 4.5 4H6V2h1.5v2h5V2H14v2h1.5q.625 0 1.062.448Q17 4.896 17 5.5v11q0 .604-.438 1.052Q16.125 18 15.5 18Zm0-1.5h11V9h-11v7.5Z" /></svg>,
};

// Does not handle keys that contain a .
// Does not handle self-referencing objects (infinite recursion)
function getPaths(obj, prefix = '') {
	return Object.entries(obj).flatMap(([key, value]) => {
		let current = prefix + key;
		if (value && typeof value === 'object') {
			return [current].concat(getPaths(value, current + '.'));
		}
		return current;
	});
}

export async function quickNavigation({ appData, schema, navigate }) {
	// Cache to speed things up
	let schemaCache = {};

	let list = getPaths(appData).map(path => {
		if (path.startsWith('dev.history') || path.startsWith('dev.disabledItems') || path.startsWith('dev.branches')) {
			return;
		}
		// Some parts of thundr work with paths ending with a dot
		if (path.endsWith('.')) path = path.slice(0, -1);
		const parts = path.split('.');
		const dataValue = parts.reduce((o, p) => o?.[p], appData);
		let iconType = Array.isArray(dataValue) ? 'array' : typeof dataValue;
		let partNames = parts;

		// Get computed name based on schema
		try {
			// (the baseSchema should be set at this level rather than using window.currentSchema)
			// let baseSchema = window.currentSchema;
			let currentSchema = schema;
			partNames = parts.map((attr, i) => {
				let cacheKey = parts.slice(0, i + 1).join('.');
				let newSchema;
				if (cacheKey in schemaCache) {
					newSchema = schemaCache[cacheKey];
				} else {
					const localPath = parts.slice(0, i);
					const currentObject = localPath?.reduce((o, p) => o?.[p], appData)?.[attr];
					const schemaProps = currentSchema?.properties;
					let newSchemaParts = [
						schemaProps?.[attr],
						...(schemaProps?.allOf || []),
					].flatMap(part => {
						if (part?.attr) {
							let result = part.attr.split(',').some(attr => part?.attrValue ? currentObject?.[attr] === part.attrValue : currentObject?.[attr]);
							if (!result) {
								return null;
							}
						}
						if (part?.$ref) {
							let { $ref, ...rest } = part;
							let ref = $ref.replace(/^#?\//, '').split('/').reduce((o, p) => o?.[p], schema);
							return !Object.keys(rest).length ? ref : [rest, ref];
						}
						return part;
					}).filter(e => e);
					newSchema = newSchemaParts.length ? defaultsDeep(...newSchemaParts) : null;
					schemaCache[cacheKey] = newSchema;
				}
				currentSchema = newSchema;
				return currentSchema?.title || attr;
			});
			// TODO: after schema update, change this to schema?.display
			if (currentSchema?.type in icons) {
				iconType = currentSchema.type;
			}
		} catch (e) {}

		if (dataValue === null) {
			iconType = 'null';
		}

		const name = partNames.pop();
		return {
			value: path,
			partsCount: parts.length,
			name,
			// description: parts.join('.'),
			description: partNames.join(' > '),
			icon: icons[iconType],
		};
	});

	// Garbage collection
	schemaCache = null;
	list = list.filter(e => e).sort((a, b) => (a.partsCount - b.partsCount) || a.name.localeCompare(b.name));

	const computeResults = (search) => {
		if (!search.startsWith('%') || search.length < 2) {
			// Regular search
			return;
		}
		search = search.slice(1);
		const searchLower = search.toLowerCase();
		return list.map(el => {
			const parts = el.value.split('.');
			const value = parts.reduce((o, p) => o?.[p], appData);
			if (typeof value !== 'string') return false;
			let position = value.toLowerCase().indexOf(searchLower);
			if (position === -1) return false;
			const snippetLength = clamp(80, search.length, value.length);
			const snippetFrom = clamp(Math.floor(position + search.length / 2 - snippetLength / 2), 0, value.length - snippetLength);
			const snippetTo = snippetFrom + snippetLength;
			return {
				...el,
				score: (value.includes(search) ? 1 : 0) + search.length / value.length + position / value.length,
				extra: [
					snippetFrom > 0 ? '…' : '',
					value.slice(snippetFrom, position),
					<strong>{value.slice(position, position + search.length)}</strong>,
					value.slice(position + search.length, snippetTo),
					snippetTo < value.length ? '…' : '',
				],
			};
		}).filter(e => e).sort((a, b) => b.score - a.score);
	};

	let pick = await showQuickInput({
		list,
		labelSeparator: ' > ',
		computeResults,
		prompt: 'Filter results, or start with % to search values',
	});

	if (pick) {
		return navigate ? navigate(pick.value) : pick.value;
	}
}