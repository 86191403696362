// Live testing http://jsfiddle.net/845v7d2p/
//   (see documentation/dateFormat.md for more info)

// Based on .Net DateTimeFormat.Format
// See https://msdn.microsoft.com/en-us/library/8kb3ddd4(v=vs.110).aspx for list of format specifiers
// MODIFICATIONS :
//  - unsupported: g (Era), K (time zone info)
//  - f and F are only precise up to 3 (= milliseconds)
//  - t/tt is lowercase and supports T/TT for uppercase alternative
//  - added oo for ordinal version of the date (e.g. 12th)
//  - adding more M or d changes the case:
//     - 1 = number            1
//     - 2 = padded number     01
//     - 3 = standard short    Jan
//     - 4 = standard long     January
//     - 5 = uppercase short   JAN
//     - 6 = uppercase long    JANUARY
//     - 7 = lowercase short   jan
//     - 8 = lowercase long    january
// Be careful to use \\ instead of \ when in a json/js string literal to prevent default backslash behaviour
// (Base implementation used as reference :
//   'FormatCustomized' @ http://referencesource.microsoft.com/#mscorlib/system/globalization/datetimeformat.cs,429)
export default function formatDateTime(date, format, copy) {
	if (!date || !format) return '';
	if (!(date instanceof Date)) {
		date = new Date(date);
	}
	if (copy) {
		copy = Object.assign({}, localeDefaults, copy);
	} else {
		copy = localeDefaults;
	}
	let result = '';
	let i, l, ch, tokenLen;
	for (i = 0, l = format.length; i < l; i += tokenLen) {
		tokenLen = 1;
		ch = format[i];
		let fn = baseFunctions[ch];
		if (fn) {
			tokenLen = parseRepeatPattern(format, i, ch, l);
			result += fn(date, tokenLen, copy);
		} else {
			switch (ch) {
				case ':':
					result += copy.$TIME_SEPARATOR;
					break;
				case '/':
					result += copy.$DATE_SEPARATOR;
					break;
				case '\'':
				case '"':
					tokenLen = parseQuoted(format, i); // Length including quotes
					// Add the string without quotes
					result += format.substr(i + 1, tokenLen - 2);
					break;
				case '\\':
					tokenLen = 2;
					if (i < l - 1) {
						result += format[i + 1];
					} else {
						// If \ is the last character of the string, add it as a literal
						result += '\\';
					}
					break;
				default:
					result += ch;
			}
		}
	}
	return result;
}

// Default that should be used if no copy is specified
let localeDefaults = {
	$AM: 'am',
	$PM: 'pm',
	$TIME_SEPARATOR: ':',
	$DATE_SEPARATOR: '/',
	$DAYS_SHORT: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	$DAYS_FULL: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	$MONTHS_SHORT: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	$MONTHS_FULL: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	$ORD: [
		//0     1     2     3     4     5     6     7     8     9
		'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th',
		//10   11    12    13    14    15    16    17    18    19
		'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th',
		//20   21    22    23    24    25    26    27    28    29
		'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th',
		//30   31
		'th', 'st'
	]
};

let transforms = [
	str => str,
	str => (str || '').toUpperCase(),
	str => (str || '').toLowerCase()
];
// Specifiers implementations
let baseFunctions = {
	h: (d, l) => pad((d.getHours() % 12) || 12, l),
	H: (d, l) => pad(d.getHours(), l),
	m: (d, l) => pad(d.getMinutes(), l),
	s: (d, l) => pad(d.getSeconds(), l),
	f: (d, l, copy, noZeroes) => {
		let v = d.getMilliseconds();
		let vl = l;
		while (vl++ < 3) v = ~~(v / 10);
		while (vl-- > 4) v *= 10;
		if (noZeroes && !v) return '';
		return pad(v, l);
	},
	F: (d, l, copy) => baseFunctions.f(d, l, copy, true),
	t: (d, l, copy, isUppercase) => {
		let designator = d.getHours() < 12 ? copy.$AM : copy.$PM;
		designator = isUppercase ? designator.toUpperCase() : designator.toLowerCase();
		if (l == 1) designator = designator.slice(0, 1);
		return designator;
	},
	T: (d, l, copy) => baseFunctions.t(d, l, copy, true),
	d: (d, l, copy) => {
		if (l <= 2) return pad(d.getDate(), l);
		l -= 3;
		let mode = l % 2, trf = transforms[(l >> 1) % transforms.length];
		return trf(copy[mode ? '$DAYS_FULL' : '$DAYS_SHORT'][d.getDay()]);
	},
	o: (d, l, copy) => {
		let day = d.getDate();
		return pad(day, l) + copy.$ORD[day];
	},
	M: (d, l, copy) => {
		if (l <= 2) return pad(d.getMonth() + 1, l);
		l -= 3;
		let mode = l % 2, trf = transforms[(l >> 1) % transforms.length];
		return trf(copy[mode ? '$MONTHS_FULL' : '$MONTHS_SHORT'][d.getMonth()]);
	},
	y: (d, l) => {
		let v = d.getFullYear();
		if (l <= 2) v %= 100;
		return pad(v, l);
	},
	z: (d, l, copy) => {
		let v = d.getTimezoneOffset();
		let ret = v < 0 ? '-' : '+';
		v = Math.abs(v);
		let hours = ~~(v / 60);
		ret += pad(hours, l <= 2 ? l : 2);
		// Add minutes
		if (l > 2) ret += copy.$TIME_SEPARATOR + pad(v - 60 * hours, 2);
		return ret;
	}
};

// Detect how many of the same character are in a row
function parseRepeatPattern(format, pos, ch, l) {
	let i = pos + 1;
	if (l == undefined) l = format.length;
	for (; i < l && format[i] === ch; i++);
	return i - pos;
}
// Detect a quoted string length (starting at a specified position)
function parseQuoted(format, pos) {
	let quote = format[pos];
	let next = pos;
	do {
		next = format.indexOf(quote, next + 1);
	} while(next !== -1 && format[next - 1] === '\\');
	if (next === -1) next = format.length;
	return next - pos + 1;
}

// Cast a positive int to string making sure it is at least the specified length
// If the string representation of the number is less than desired, 0s are added in front of it
function pad(n, l) {
	let v = n + '', vl = v.length;
	while (vl++ < l) v = '0' + v;
	return v;
}
