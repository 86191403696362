import { h, Component } from 'preact';
import cloneDeep from 'lodash/cloneDeep';
// import Store from 'store/store';
// import * as actions from 'store/actions';
import { joinClasses } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import dateDistance from 'utils/dateDistance.mjs';
import createNested from 'utils/createNested';
import HTML from 'components/core/html/html';

import s from 'components/shared/infoSidebar/sidebarNotes/sidebarNotes.sss';

export default class SidebarNotes extends Component {
	constructor() {
		super();
		this.state.editNote = false;

		this.saveNote = this.saveNote.bind(this);
		this.removeNote = this.removeNote.bind(this);
		this.openEditor = this.toggleNoteEditor.bind(this, true);
		this.closeEditor = this.toggleNoteEditor.bind(this, false);
		this.renderNote = this.renderNote.bind(this);
	}

	async saveNote() {
		let newData = cloneDeep(this.props.data);
		newData.edited = newData.edited || cloneDeep(newData.attributes);

		const notes = createNested(newData, 'edited.dev.productionNotes');

		let legacyLevel = 'app_data_' + this.props.activeLevel;
		if (notes[legacyLevel]) {
			delete notes[legacyLevel];
		}

		notes[this.props.activeLevel] = {
			createdAt: new Date().toISOString(),
			user: window.user.name,
			text: this.$noteText.value
		};

		await this.props.saveData({ data: newData });
		this.closeEditor();
	}

	async removeNote() {
		let newData = cloneDeep(this.props.data);
		newData.edited = newData.edited || cloneDeep(newData.attributes);
		if (!newData.edited?.dev?.productionNotes) {
			return;
		}
		delete newData.edited.dev.productionNotes;

		await this.props.saveData({ data: newData });
		this.closeEditor();
	}

	toggleNoteEditor(editNote) {
		this.setState({ editNote: editNote });
	}

	toggleCollapse(level) {
		let newState = {};
		let key = 'collapsed-' + level;
		newState[key] = !this.state[key];
		this.setState(newState);
	}

	renderNote([level, note]) {
		const sanitizedLevel = level.replace('app_data_', '');
		const editable = sanitizedLevel === this.props.activeLevel;

		if (!note.user && !editable) {
			return;
		}

		let levelNames = { branches: 'branch' };
		let levelName = levelNames[sanitizedLevel] || sanitizedLevel.slice(0, -1);

		let createdAt = new Date(note?.createdAt || 0);

		return (
			<div class={s.note}>
				{!note.user && editable && !this.state.editNote && (
					<button class={s.noteBtn} onClick={this.openEditor}>leave a note</button>
				)}

				{note.user && (!editable || !this.state.editNote) && (
					<div class={joinClasses(s.noteExisting, editable && s.editable, this.state['collapsed-' + level] && s.collapsed)}>
						<div class={s.noteText}>
							<div class={s.levelIndicator} onClick={() => this.toggleCollapse(level)}>{levelName + ': '}</div>
							<HTML class={s.noteTextContent} content={note.text} markdown forceNewTabLinks />
						</div>
						<div class={s.noteOrigin} title={createdAt.toISOString()}>by {note.user} {dateDistance(createdAt)}</div>
						{editable && (
							<div class={s.noteInteractions}>
								<button class={s.noteBtnInteract} onClick={this.removeNote}>Remove</button>
								<button class={s.noteBtnInteract} onClick={this.openEditor}>Update</button>
							</div>
						)}
					</div>
				)}

				{this.state.editNote && editable && (
					<div>
						<textarea
							class={s.noteTextEdit}
							placeholder="Type your note"
							ref={r => this.$noteText = r}
							value={note?.text}
						/>
						<div class={s.noteInteractions}>
							<button class={joinClasses(s.noteBtnInteract, s.cancel)} onClick={this.closeEditor}>Cancel</button>
							<button class={s.noteBtnInteract} onClick={this.saveNote}>Save</button>
						</div>
					</div>
				)}
			</div>
		);
	}

	// assetUpdateDateTime soon to be legacy, dynamicAssetUpdate is the new way
	navigateToAssetUpdate(e) {
		e.preventDefault();
		window.setActivePath?.('dev.assetUpdateDateTime.');
	}

	navigateToDynamicAssetUpdate(e, key) {
		e.preventDefault();
		window.setActivePath?.(`dev.dynamicAssetUpdate.dateTimesForUpdates.${key}.`);
	}

	// assetUpdateDateTime soon to be legacy, dynamicAssetUpdate is the new way
	renderAssetUpdateWarning(assetUpdateDateTime) {
		const displayedDateTime = new Date(assetUpdateDateTime);
		return (
			<div class={joinClasses(s.note, s.assetUpdate)}>
				<div class={s.noteExisting}>
					<div class={s.noteText}>
						<p>Asset update set to <a class={s.time} href="#" onClick={this.navigateToAssetUpdate} title={displayedDateTime.toISOString()}>{formatDateTime(displayedDateTime, 'dd MMM yyyy "at" HH:mm ("UTC" zzz)')}</a> ({dateDistance(displayedDateTime)})</p>
						<p>Assets using <code>%IMAGE_REGION_ROOT_DYNAMIC%</code> will use the <code>updates</code> folder within image region root after this date.</p>
					</div>
				</div>
			</div>
		);
	}

	renderDynamicAssetUpdateWarning(dynamicDateTimesKeys, dynamicDateTimesValues) {
		const displayedDateTimes = dynamicDateTimesValues.map(dateTime => new Date(dateTime));
		return (
			<div class={joinClasses(s.note, s.assetUpdate)}>
				<div class={s.noteExisting}>
					<div class={s.noteText}>
						<p>Dynamic assets update at:</p>
						{displayedDateTimes.map((datetime, i) => {
							if (isNaN(datetime)) {
								return (
									<p key={`assetupdate${i}`}>
										<a class={s.time} href="#" onClick={(e) => this.navigateToDynamicAssetUpdate(e, dynamicDateTimesKeys[i])}>Invalid date detected, please check</a>
									</p>
								);
							}
							return (
								<p key={`assetupdate${i}`}>
									<a class={s.time} href="#" onClick={(e) => this.navigateToDynamicAssetUpdate(e, dynamicDateTimesKeys[i])} title={datetime.toISOString()}>{formatDateTime(datetime, 'dd MMM yyyy "at" HH:mm ("UTC" zzz)')} </a>
									({dateDistance(datetime)})
									<span class={s.assetFolder}>--- /updates{i + 1}</span>
								</p>
							);
						})}
						<p>Assets using <code>%IMAGE_REGION_ROOT_DYNAMIC%</code> will use the relevant <code>updates&lt;num&gt;</code> folder within image region root after each date.</p>
					</div>
				</div>
			</div>
		);
	}

	renderEarlyAccessTicketing(data) {
		const notes = Object.values(data).map(launch => {
			if (!launch.startDateTime || !launch.endDateTime) {
				return;
			}
			const startTime = new Date(launch.startDateTime?.trim());
			const endTime = new Date(launch.endDateTime?.trim());
			const codes = launch.earlyAccessCodes?.split(',').map(code => code.trim().toUpperCase()).join(', ');
			const displayedStartTime = formatDateTime(startTime, 'dd MMM yyyy "at" HH:mm ("UTC" zzz)');
			const displayedEndTime = formatDateTime(endTime, 'dd MMM yyyy "at" HH:mm ("UTC" zzz)');
			return (
				<div class={s.noteText}>
					<hr />
					<p>{`${displayedStartTime} to ${displayedEndTime}`}</p>
					<p>{`Codes: ${codes}`}</p>
				</div>
			);
		}).filter(a => a);

		return (
			<div class={s.note}>
				<div class={s.noteExisting}>
					<h3 class={s.noteText}>Early Access Ticketing</h3>
					{notes}
				</div>
			</div>
		);
	}


	render({ merged, activeLevel }) {
		const productionNotes = merged.dev?.productionNotes || {};

		let legacyLevel = 'app_data_' + activeLevel;
		if (!productionNotes[activeLevel] && !productionNotes[legacyLevel]) {
			productionNotes[activeLevel] = {};
		}

		// assetUpdateDateTime soon to be legacy, dynamicAssetUpdate is the new way
		const assetUpdateDateTime = merged.dev?.assetUpdateDateTime;
		const dynamicAssetUpdate = merged.dev?.dynamicAssetUpdate || {};
		const dynamicDateTimes = dynamicAssetUpdate.dateTimesForUpdates || {};
		const dynamicDateTimesValues = Object.values(dynamicDateTimes).sort((a, b) => a - b);
		const dynamicDateTimesKeys = Object.keys(dynamicDateTimes).sort((a, b) => dynamicDateTimes[a] - dynamicDateTimes[b]);
		const dynamicAssetUpdateEnabled = dynamicAssetUpdate.useDynamicUpdates && dynamicDateTimesValues.length > 0;
		const earlyAccessTicketing = merged.dev?.earlyAccessTicketing;

		return (
			<div class={s.noteContainer}>
				{dynamicAssetUpdateEnabled && this.renderDynamicAssetUpdateWarning(dynamicDateTimesKeys, dynamicDateTimesValues)}
				{assetUpdateDateTime && this.renderAssetUpdateWarning(assetUpdateDateTime)}
				{earlyAccessTicketing && this.renderEarlyAccessTicketing(earlyAccessTicketing)}
				{Object.entries(productionNotes).map(this.renderNote)}
			</div>
		);
	}
}
