import { h, Component } from 'preact';
import { joinClasses, triggerAnim } from 'utils/utils';
import { clamp } from 'utils/math';
import s from 'components/shared/colorInput/colorInput.sss';

import Color from 'components/shared/colorInput/color';
import ColorAnalyzer from 'components/shared/colorInput/colorAnalyzer';

const transparentChecker = `
linear-gradient(to top right, #666 25%, transparent 25%) 0 0 / 21px 21px repeat,
linear-gradient(to bottom right, #666 25%, transparent 25%) 10px 0 / 21px 21px repeat,
linear-gradient(to bottom left, #666 25%, transparent 25%) 10px -10px / 21px 21px repeat,
linear-gradient(to top left, #666 25%, transparent 25%) 0 10px / 21px 21px repeat,
linear-gradient(to bottom, #999, #999)
`;
const opacitySliderChecker = `
linear-gradient(to top right, #666 25%, transparent calc(25% + 1px)) 0 0 / 10px 10px repeat,
linear-gradient(to bottom right, #666 25%, transparent calc(25% + 1px)) 5px 0 / 10px 10px repeat,
linear-gradient(to bottom left, #666 25%, transparent 25%) 5px 5px / 10px 10px repeat,
linear-gradient(to top left, #666 25%, transparent 25%) 0 5px / 10px 10px repeat,
linear-gradient(to bottom, #999, #999)
`;

const errCrossColor = '#e0284a';
const errorBg = `
linear-gradient(to bottom right, transparent calc(50% - 2px), ${errCrossColor} calc(50% - 2px), ${errCrossColor} calc(50% + 2px), transparent calc(50% + 2px)) center / 50% 50% no-repeat,
linear-gradient(to bottom left, transparent calc(50% - 2px), ${errCrossColor} calc(50% - 2px), ${errCrossColor} calc(50% + 2px), transparent calc(50% + 2px)) center / 50% 50% no-repeat,
${transparentChecker}
`;

// Very permissive color parsing
// returns a color if the string is color-like or null otherwise
function tryParseColor(str) {
	if (!str || typeof str !== 'string') return null;
	str = str.trim();
	let rgbMatch = str.match(/^\D*(\d+)\D+(\d+)\D+(\d+)\D*$/);
	if (rgbMatch && +rgbMatch[1] <= 255 && +rgbMatch[2] <= 255 && +rgbMatch[3] <= 255) {
		return new Color(+rgbMatch[1], +rgbMatch[2], +rgbMatch[3]);
	}
	// Matches hexa color codes (3 or 6) with or without # EXCEPT 3 digits without #
	// let hexMatch = str.match(/(?:^#|^(?!\d{3}$))([0-9a-f]{3}|[0-9a-f]{6})$/i);
	let hexMatch = str.match(/^#?([0-9a-f]{3,4}|[0-9a-f]{6}|[0-9a-f]{8})$/i);
	if (hexMatch) {
		return new Color('#' + hexMatch[1]);
	}
	return null;
}

export default class ColorInput extends Component {

	constructor(props) {
		super(props);
		this.showColorPicker = this.showColorPicker.bind(this);
		this.colorPickerSelection = this.colorPickerSelection.bind(this);
		this.value = new Color();
		this.valid = this.value.fromString(props.value);
	}

	componentWillReceiveProps(props) {
		if (this.props.value !== props.value) {
			this.valid = this.value.fromString(props.value);
		}
	}

	showColorPicker() {
		this.$picker.show(this.value, {
			suggestions: this.props.suggestions,
			extractFromImages: this.props.extractFromImages
		});
	}

	colorPickerSelection(value) {
		this.value = value;
		this.valid = true;
		if (typeof this.props.onChange === 'function') {
			this.props.onChange({ target: this });
		}
	}

	render(props) {
		let colorStr = this.value.toString();
		let style = { background: 'linear-gradient(to bottom, ' + colorStr + ', ' + colorStr + '),' + transparentChecker };
		if (typeof props.invalid === 'boolean' ? props.invalid : !this.valid) {
			style.background = errorBg;
		}
		return (
			<div class={joinClasses(s.wrapper, props.wrapperClass)}>
				<button class={joinClasses(s.pickerButton, props.class)} style={style} onClick={this.showColorPicker} />
				<ColorPicker ref={e => this.$picker = e} callback={this.colorPickerSelection} />
			</div>
		);
	}

}

class ColorPicker extends Component {

	constructor() {
		super();
		this.close = this.close.bind(this);
		this.validate = this.validate.bind(this);
		this.highlight = this.highlight.bind(this);
		this.mouseDownHue = this.mouseDownHue.bind(this);
		this.mouseDownShade = this.mouseDownShade.bind(this);
		this.mouseDownOpacity = this.mouseDownOpacity.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.manualInput = this.manualInput.bind(this);
		this.update = this.update.bind(this);
		this.analyzeCustomImageColors = this.analyzeCustomImageColors.bind(this);
		this.value = new Color();
	}

	componentDidMount() {
		document.addEventListener('mousemove', this.mouseMove);
		document.addEventListener('mouseup', this.mouseUp);
	}
	componentWillUnmount() {
		document.removeEventListener('mousemove', this.mouseMove);
		document.removeEventListener('mouseup', this.mouseUp);
	}

	shouldComponentUpdate() {
		return false;
	}

	mouseDownHue(e) {
		if (e.button !== 0) {
			return;
		}
		e.preventDefault();
		this.hueDown = true;
		this.mouseMove(e);
	}
	mouseDownShade(e) {
		if (e.button !== 0) {
			return;
		}
		e.preventDefault();
		this.shadeDown = true;
		this.mouseMove(e);
	}
	mouseDownOpacity(e) {
		if (e.button !== 0) {
			return;
		}
		e.preventDefault();
		this.opacityDown = true;
		this.mouseMove(e);
	}
	mouseMove(e) {
		if (!this.hueDown && !this.shadeDown && !this.opacityDown) {
			return;
		}
		e.preventDefault();
		if (this.hueDown) {
			let rect = this.$hue.getBoundingClientRect();
			this.hue = clamp((e.pageX - rect.left) / rect.width, 0, 1);
		}
		if (this.shadeDown) {
			let rect = this.shadeCanvas.getBoundingClientRect();
			this.sat = clamp((e.pageX - rect.left) / rect.width, 0, 1);
			this.val = 1 - clamp((e.pageY - rect.top) / rect.height, 0, 1);
		}
		if (this.opacityDown) {
			let rect = this.$opacity.getBoundingClientRect();
			let pos = clamp((e.pageX - rect.left) / rect.width, 0, 1);
			this.alpha = Math.round(pos * 100) / 100;
		}
		this.update();
	}
	mouseUp() {
		this.hueDown = false;
		this.shadeDown = false;
		this.opacityDown = false;
	}
	manualInput(e) {
		let res = tryParseColor(e.target.value);
		if (res) {
			this.blockInput = true;
			this.setColor(res);
			this.blockInput = false;
		}
	}

	drawShade() {
		let ctx = this.shadeCtx;
		let imd = this.shadeImageData;
		let data = imd.data;
		let w = imd.width;
		let h = imd.height;
		let col = new Color();
		for (let j = 0; j < h; j++) {
			for (let i = 0; i < w; i++) {
				col.fromHSV(this.hue, i / (w - 1), 1 - j / (h - 1));
				let base = (j * w + i) * 4;
				data[base] = col.r;
				data[base + 1] = col.g;
				data[base + 2] = col.b;
				data[base + 3] = 255;
				// ctx.fillStyle = col.toString();
				// ctx.fillRect(i, j, 1, 1);
			}
		}
		ctx.putImageData(imd, 0, 0);
	}

	update() {
		this.value.fromHSV(this.hue, this.sat, this.val, this.alpha);

		let currentHue = Math.round(this.hue * 360);
		if (this.drawnHue !== currentHue) {
			this.drawnHue = currentHue;
			this.drawShade();
			let hueIndicator = this.$hueIndicator;
			hueIndicator.style.backgroundColor = 'hsl(' + currentHue + ', 100%, 50%)';
			hueIndicator.style.left = (100 * this.hue) + '%';
		}

		let opaque = this.value.toString(1);
		if (this.drawnOpacity !== opaque) {
			this.drawnOpacity = opaque;
			this.$opacity.style.background = 'linear-gradient(to right, ' + this.value.toString(0) + ', ' + opaque + '), ' + opacitySliderChecker;
		}

		let currentValue = this.value.toString();

		let opacityIndicator = this.$opacityIndicator;
		opacityIndicator.style.background = 'linear-gradient(to bottom, ' + currentValue + ',' + currentValue + '), ' + opacitySliderChecker;
		opacityIndicator.style.backgroundAttachment = 'fixed';
		opacityIndicator.style.left = (100 * this.alpha) + '%';

		let shadeIndicator = this.$shadeIndicator;
		shadeIndicator.style.backgroundColor = opaque;
		shadeIndicator.style.left = (100 * this.sat) + '%';
		shadeIndicator.style.top = (100 * (1 - this.val)) + '%';

		this.$preview.style.backgroundColor = opaque;
		let lightness = this.value.r * 0.299 + this.value.g * 0.587 + this.value.b * 0.114;
		let textColor = lightness > 127 ? 'black' : 'white';
		this.$preview.style.color = textColor;
		this.$preview.setAttribute('data-rgb', this.value.toRGBString());

		if (!this.blockInput) {
			this.$input.value = this.value.toHex();
		}
		this.$native.value = opaque;
	}

	setColor(color) {
		this.value.fromString(color);

		let hsv = this.value.toHSV();
		this.hue = hsv.h;
		this.sat = hsv.s;
		this.val = hsv.v;
		this.alpha = hsv.a;

		this.drawnHue = undefined;

		this.update();
	}

	show(color, options) {
		options = options || {};
		this.updateSuggestions(options.suggestions);
		this.updateImagesList(options.extractFromImages);

		try {
			this.oldParent = this.base.parentElement;
			document.body.appendChild(this.base);
			this.base.style.display = '';
		} catch (e) {}

		let shadeCanvas = this.shadeCanvas;
		this.shadeCtx = shadeCanvas.getContext('2d');
		shadeCanvas.width = shadeCanvas.offsetWidth || 500;
		shadeCanvas.height = shadeCanvas.offsetHeight || 200;
		this.shadeImageData = this.shadeCtx.createImageData(shadeCanvas.width, shadeCanvas.height);

		this.fromValue = color;
		this.setColor(color);
	}

	close() {
		try {
			this.base.style.display = 'none';
			this.oldParent.appendChild(this.base);
		} catch (e) {}
	}

	updateImagesList(urls) {
		let parent = this.$extractFromList;
		let customImage = this.$customImage;
		while (parent.firstChild && parent.firstChild !== customImage) {
			parent.removeChild(parent.firstChild);
		}
		urls = urls || {};
		Object.keys(urls).forEach(name => {
			let url = urls[name];
			if (!url) return;
			let elem = document.createElement('button');
			elem.className = s.dropdownElement;
			elem.onclick = () => this.findColors(url);
			let nameElem = document.createElement('div');
			nameElem.className = s.imageName;
			nameElem.textContent = name;
			let preview = document.createElement('div');
			preview.className = s.imagePreview;
			let previewContent = new Image();
			previewContent.crossOrigin = 'anonymous';
			previewContent.src = url;
			preview.appendChild(previewContent);
			elem.appendChild(nameElem);
			elem.appendChild(preview);
			parent.insertBefore(elem, customImage);
		});
	}

	updateSuggestions(list) {
		let parent = this.$suggestions;
		while (parent.firstChild) {
			parent.removeChild(parent.firstChild);
		}
		(list || []).forEach(suggestion => {
			let elem = document.createElement('div');
			elem.className = s.value;
			// elem.onclick = () => this.validateWithColor(suggestion);
			elem.onclick = () => this.setColor(suggestion);
			let icon = document.createElement('div');
			icon.className = s.icon;
			icon.style.background = 'linear-gradient(to bottom, ' + suggestion + ', ' + suggestion + '),' + transparentChecker;
			let color = document.createElement('span');
			color.className = s.color;
			color.textContent = suggestion;
			elem.appendChild(icon);
			elem.appendChild(color);
			parent.appendChild(elem);
		});
	}

	findColors(url) {
		let image = new Image();
		image.crossOrigin = 'anonymous';
		image.onload = () => {
			try {
				let analyzer = new ColorAnalyzer(image);
				let bg = analyzer.detectBackground();
				let palette = analyzer.analyseImage(6, bg);
				this.updateSuggestions([new Color(...bg)].concat(palette[0].map(d => new Color(...d[0]))));
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('Error during image analysis', e);
				// eslint-disable-next-line no-alert
				alert('Error during image analysis');
			}
		};
		image.onerror = () => {
			// eslint-disable-next-line no-alert
			alert('Error loading image');
		};
		image.src = url;
	}
	analyzeCustomImageColors(e) {
		this.findColors(URL.createObjectURL(e.target.files[0]));
	}

	validate() {
		this.close();
		if (typeof this.props.callback === 'function') {
			this.props.callback(this.value);
		}
	}

	validateWithColor(color) {
		this.setColor(color);
		this.validate();
	}

	highlight(e) {
		if (!e || e.target === this.base) {
			triggerAnim(this.base.firstChild, s.highlight);
			if (e && e.preventDefault) {
				e.preventDefault();
			}
		}
	}

	render() {
		// Idea: could replace the shade canvas by an image of the red version and use a css filter hue-rotate
		return (
			<div class={s.pickerWrapper} onMousedown={this.highlight} style={{ display: 'none' }}>
				<div class={s.picker}>
					<div class={s.main}>
						<div class={s.preview} ref={e => this.$preview = e}>
							<input type="text" ref={e => this.$input = e} onInput={this.manualInput} onBlur={this.update} />
						</div>
						<div class={s.selector}>
							<div class={s.shade} onMousedown={this.mouseDownShade}>
								<canvas ref={e => this.shadeCanvas = e} />
								<div class={s.indicator} ref={e => this.$shadeIndicator = e} />
							</div>
							<div class={s.slidersWrapper}>
								<div class={s.hue} ref={e => this.$hue = e} onMousedown={this.mouseDownHue}>
									<div class={s.indicator} ref={e => this.$hueIndicator = e} />
								</div>
								<div class={s.opacity} ref={e => this.$opacity = e} onMousedown={this.mouseDownOpacity}>
									<div class={s.indicator} ref={e => this.$opacityIndicator = e} />
								</div>
							</div>
						</div>
					</div>
					<div class={s.buttons}>
						<div class={joinClasses(s.button, s.extract)}>
							Extract colors from...
							<div class={s.dropdown} ref={e => this.$extractFromList = e}>
								<div class={joinClasses(s.dropdownElement, s.customImage)} ref={e => this.$customImage = e}>
									<input type="file" class={s.customImageInput} onChange={this.analyzeCustomImageColors} />
									Image from disk
								</div>
							</div>
						</div>
						<div class={joinClasses(s.button, s.native)}>
							<input type="color" class={s.nativeInput} onChange={e => this.setColor(e.target.value)} ref={e => this.$native = e} />
							Native colorpicker
						</div>
						<button class={joinClasses(s.button, s.cancel)} onClick={this.close}>Cancel</button>
						<button class={joinClasses(s.button, s.ok)} onClick={this.validate}>Ok</button>
					</div>
					<div class={s.values} ref={e => this.$suggestions = e} />
				</div>
			</div>
		);
	}

}
