import { handleApiResponse } from 'utils/handleApi';
import { toQueryString } from 'utils/utils';

let API_BASE = 'https://showtimes-v2.s-prod.pow.io/v2.0';

async function apiCall(path, options) {
	if (options?.qs) {
		path += toQueryString(options.qs);
		delete options.qs;
	}

	let res = await fetch(
		API_BASE + path,
		{
			...options,
			headers: {
				Accept: 'application/json', 'Content-Type': 'application/json',
				...options?.headers
			},
		},
	);
	return await handleApiResponse(res);
}

export const getMovieInfo = async (ids) => apiCall('/thundr/movie-id-info', { qs: { movie_id: ids } });
