import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import pure from 'utils/pure';
import s from 'components/shared/notification/notification.sss';


export default @pure class Notifications extends Component {

	removeNotification = () => {
		Store.emit(actions.UPDATE_NOTIFICATION, null);
	}

	render({ notification }) {
		const { text, title, children } = notification;

		return (
			<div class={s.container} onClick={this.removeNotification}>
				<p class={s.title}>{title}</p>
				<p class={s.text}>{text}</p>
				<div class={s.children}>
					{children}
				</div>
			</div>
		);
	}

}
