import { h, Component } from 'preact';
// import { accessNested } from 'utils/utils';
// import Store from 'store/store';
// import * as actions from 'store/actions';
import s from 'components/shared/appInfo/appInfo.sss';
import Seo from 'components/shared/seo/seo';
import History from 'components/shared/history/history';
import Live from 'components/shared/live/live';
import { joinClasses } from 'utils/utils';


export default class AppInfo extends Component {

	constructor(props) {
		super(props);
		this.state = {};
		let list = this.getComponents(props);
		this.state.components = list;
		this.state.component = list && list[0];
	}

	componentWillReceiveProps(props) {
		let components = this.getComponents(props);

		let currentId = this.state.component?.id;
		let component = components && (components.find(e => e.id === currentId) || components[0]);

		this.setState({ components, component });
	}

	getComponents(props = this.props) {
		let allComponents = [];

		let type = props.activeType;
		if (type === 'apps') {
			allComponents.push(
				{ id: 'history', name: 'History', element: History },
				{ id: 'seo', name: 'SEO', element: Seo }
			);
		}
		if (['title', 'studio', 'studio_regions'].includes(type)) {
			allComponents.push(
				{ id: 'live', name: 'Live', element: Live }
			);
		}

		return allComponents;
	}

	handleInfoClick(component) {
		this.setState({ component });
	}

	render(props) {
		let tab = this.state.component;
		let Comp = tab?.element;

		return (
			<div class={s.container}>
				<div class={s.titles}>
					{this.state.components.map(type => (
						<button
							class={joinClasses(s.title, this.state.component === type && s.selected)}
							onClick={() => this.handleInfoClick(type)}
							key={type.id}
						>
							{type.name || type.id}
						</button>
					))}
				</div>
				<Comp {...props} />
			</div>
		);
	}

}
