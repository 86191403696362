import { h, Component } from 'preact';
import { parseDate, joinClasses } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import pure from 'utils/pure';
import Select from 'components/shared/select/select';
import s from 'components/shared/formGenerator/formField.sss';

export default @pure class FormField extends Component {

	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value,
			pageStatus: null,
		};

		this.onUpdate = this.onUpdate.bind(this);
		this.selectHandler = this.selectHandler.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.value !== nextProps.value) {
			this.setState({ value: nextProps.fieldData?.value });
		}
	}

	componentWillMount() {
		const fieldData = this.props.fieldData;
		let value = this.props.value;

		if (fieldData.type === 'date') {
			let date = parseDate(value);
			if (!date || isNaN(+date)) date = new Date();
			value = formatDateTime(date, 'yyyy-MM-dd');
		}

		this.setState({ value });
	}

	callUpdate() {
		const fieldData = this.props.fieldData;
		if (fieldData) {
			fieldData.value = this.getValue();
		}
		this.props.onUpdate?.(fieldData ? [fieldData] : []);
	}

	onUpdate(e) {
		let value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		if (e.isTrusted && e.target.type === 'radio') {
			// Make it easier for linked radio fields to detect when being turned off by triggering a change event
			//  (by default, change only triggers on the one that gets activated)
			[...document.querySelectorAll('input[name="' + e.target.name + '"]')].forEach(el => {
				if (el !== e.target) {
					el.dispatchEvent(new Event('change'));
				}
			});
		}
		this.setState({ value });
		this.callUpdate();
	}

	getValue() {
		return this.state.value;
	}

	mapValuesForSelect(values, fromAppdata) {
		if (Array.isArray(values)) {
			if (fromAppdata) {
				values = values.map(e => e.name);
			}
		} else if (typeof values === 'string') {
			values = values.split(',').map(e => e.trim());
		} else {
			values = [];
		}

		return values.map(val => ({
			label: val,
			value: val,
			color: 'black'
		}));
	}

	selectHandler(updated) {
		this.setState({ value: updated.map(genre => ({ name: genre.value })) });
		this.callUpdate();
	}

	render(props, state) {
		const {
			title,
			type,
			update,
			placeholder = '',
			notes,
			inputName,
		} = props.fieldData;

		const { value } = state;

		let path = typeof update === 'string' && update;

		let input;
		switch (type) {
			case 'dropdown':
				// TODO: make our own multiSelect ? Seems heavy to import react-dom just for one select
				// Basic similar component in native JS (would need to be converted to preact): https://github.com/POWSTER/showtimes-analytics-dash/blob/master/public/js/multiselect.js
				//    (demo: https://insights.powster.com/report/full - the "movies" field)
				input = <Select defaultValue={this.mapValuesForSelect(value, true)} isMulti onChange={this.selectHandler} options={this.mapValuesForSelect(props.fieldData.options)} className={s.select} classNamePrefix="select" />;
				break;
			case 'textarea':
				input = <textarea class={s.input} value={value} onChange={this.onUpdate} placeholder={placeholder} cols="50" />;
				break;
			case 'date':
				input = <input class={s.input} value={value} onChange={this.onUpdate} type="date" />;
				break;
			case 'disabler':
			case 'checkbox':
			case 'radio':
				input = <input class={s.input} checked={value} onChange={this.onUpdate} type={type === 'radio' ? 'radio' : 'checkbox'} name={inputName} />;
				break;
			case 'input':
			default:
				input = <input class={s.input} value={value} onChange={this.onUpdate} type="text" placeholder={placeholder} />;
		}
		return (
			<label class={joinClasses(s.container, props.class)}>
				<div class={s.label}>
					{!!title && (
						<div class={s.title}>
							{title}
							{!!path && <div class={s.path}>{path}</div>}
						</div>
					)}
					<div class={s.notes}>{notes}</div>
				</div>
				{input}
			</label>
		);
	}

}
