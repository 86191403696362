import { h } from 'preact';
import pure from 'utils/pure';
// import FormRemoveButton from 'components/shared/editor/formEditor/formRemoveButton';
import ObjectAttribute from 'components/shared/editor/formEditor/attributes/objectAttribute';
import s from 'components/shared/editor/formEditor/attributes/youtubePlaylistAttribute/youtubePlaylistAttribute.sss';


export default @pure class YoutubePlaylistAttribute extends ObjectAttribute {

	renderItemContent() {
		let value = this.props.value || {};
		if (value.playlistId) {
			return (
				<div class={s.item}>
					<div class={s.playlistName}>Playlist <br /><span class={s.playlistId}>{value.playlistId}</span></div>
				</div>
			);
		}
		return (
			<div class={s.item}>
				{!!value.thumbSrc && <div class={s.itemThumb} style={{ backgroundImage: 'url(' + value.thumbSrc + ')' }} />}
				<div class={s.itemName}>{value.name}</div>
			</div>
		);
	}

}
