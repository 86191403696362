import { h, Component } from 'preact';
import stringify from 'json-stable-stringify';
// import * as monaco from 'monaco-editor';

import { joinClasses } from 'utils/utils';

import s from 'components/shared/editor/codeEditor/codeEditor.sss';

// Loading the main module loads all languages and features instead of respecting the MonacoWebpackPlugin options
// let getMonaco = async () => import('monaco-editor');
let getMonaco = async () => import('monaco-editor/esm/vs/editor/editor.api');
// TODO: add completion ? constants/autoCompleteSuggestions

export default class CodeEditor extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tmpValue: this.stringify(this.props.value || this.props.initialValue)
		};

		this.updateTmpValue = this.updateTmpValue.bind(this);
		this.updateVal = this.updateVal.bind(this);
		this.clearDirty = this.clearDirty.bind(this);
	}

	componentDidMount() {
		this.setUpEditor();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) {
			let stringified = this.stringify(nextProps.value);
			if (stringified !== this.stringify(this.props.value)) {
				this.value = stringified;
			}
		}
		let diffEditor = this.editor?.getEditorType() === this.monaco?.editor?.EditorType?.IDiffEditor;
		if (diffEditor && nextProps.diffWith !== this.props.diffWith) {
			this.editor?.getOriginalEditor()?.setValue(this.stringify(nextProps.diffWith));
		}
		if (nextProps.language !== this.props.language && this.editor) {
			const models = diffEditor ? Object.values(this.editor.getModel()) : [this.editor.getModel()];
			models.forEach(model => model.setLanguage(nextProps.language));
		}
	}

	componentWillUnmount() {
		if (this.editor) {
			this.editor.dispose();
			// Remove the dom element to make sure there are no events left
			this.$container.remove();
			this.editor = null;
		}
	}

	async setUpEditor() {
		if (typeof window === 'undefined' || this.mounted) {
			return;
		}
		this.mounted = true;

		try {
			this.monaco = await getMonaco();
			this.base.classList.remove(s.loading);

			// let value = this.stringify(this.props.value || this.props.initialValue);
			let value = this.state.tmpValue;
			let language = this.props.language || 'json';
			const editorOptions = {
				language: language,
				readOnly: this.props.readOnly,
				automaticLayout: true,
				insertSpaces: false,
				fontSize: '12px',
				lineNumbers: this.props.hideLineNumbers ? 'off' : 'on',
				minimap: { enabled: !this.props.hideMinimap, showSlider: 'always' },
				theme: this.props.dark ? 'vs-dark' : 'vs',
			};
			if (this.props.diffWith) {
				this.editor = this.monaco.editor.createDiffEditor(this.$container, { ...editorOptions, renderMarginRevertIcon: !editorOptions.readOnly });
				this.editor.setModel({
					original: this.monaco.editor.createModel(this.stringify(this.props.diffWith), language),
					modified: this.monaco.editor.createModel(value, language)
				});
			} else {
				this.editor = this.monaco.editor.create(this.$container, { ...editorOptions, value });
			}

			let mainEditor = this.getMainEditor();
			mainEditor.onDidChangeModelContent(this.updateVal);
			mainEditor.onDidBlurEditorText(this.clearDirty);

			// this.value = this.props.json;

			this.setState({ loaded: true });
		} catch (e) {
			console.error(e);
			this.setState({ editorLoadError: true });
		}
	}

	clearDirty() {
		if (!this.dirty) {
			return;
		}
		this.rawSetValue(this.dirty.content);
		this.dirty = false;
	}

	resize() {
		this.editor?.layout();
	}

	stringify(v) {
		if (v == null) {
			return '';
		}
		if (typeof v === 'object') {
			return stringify(v, { space: '\t' });
		}
		return v.toString();
	}

	getMainEditor() {
		if (!this.editor) {
			return null;
		}
		if (this.editor.getEditorType() === this.monaco.editor.EditorType.IDiffEditor) {
			return this.editor.getModifiedEditor();
		}
		return this.editor;
	}
	rawSetValue(str) {
		return this.getMainEditor()?.setValue(str);
	}
	rawGetValue() {
		return this.getMainEditor()?.getValue();
	}
	set value(val) {
		let newValue = this.stringify(val);
		if (newValue === this.stringify(this.props.value) || newValue === this.rawGetValue()) {
			return;
		}
		if (this.editor?.hasTextFocus()) {
			// Avoid changing the text as the user is editing it
			this.dirty = { content: newValue };
		} else {
			this.rawSetValue(newValue);
		}
	}
	get value() {
		let language = this.props.language || 'json';
		if (language !== 'json') {
			return this.rawGetValue() || '';
		}
		let parsed;
		try {
			parsed = JSON.parse(this.rawGetValue());
		} catch (e) {}
		return parsed;
	}
	toString() {
		return this.rawGetValue();
	}

	updateTmpValue(e) {
		let tmpValue = e.target.value;
		this.setState({ tmpValue });
		if (!this.props.onChange) {
			return;
		}
		let language = this.props.language || 'json';
		let finalValue;
		if (language === 'json') {
			try {
				finalValue = JSON.parse(tmpValue);
			} catch (e) {}
		} else {
			finalValue = tmpValue;
		}
		if (finalValue != undefined && this.stringify(finalValue) !== this.stringify(this.props.value)) {
			this.props.onChange?.(finalValue);
		}
	}

	updateVal() {
		if (!this.editor || this._manualChange) {
			return;
		}

		let val = this.value;
		if (val != undefined && this.stringify(val) !== this.stringify(this.props.value)) {
			this.props.onChange?.(val);
		}
	}

	render(props, state) {
		let resize = props.resize;
		let resizeVertical = resize === 'vertical' || resize === 'both';
		let resizeHorizontal = resize === 'horizontal' || resize === 'both';
		const loading = !state.loaded && !state.editorLoadError;
		const useFallback = !!state.editorLoadError;
		return (
			<div
				class={
					joinClasses(props.class, {
						s,
						container: true,
						loading,
						fallback: useFallback,
						dark: props.dark,
						resizeVertical,
						resizeHorizontal
					})
				}
			>
				<div class={s.editor} onChange={this.editorChanged} ref={r => this.$container = r} />
				<textarea class={s.tmp} value={this.state.tmpValue} onInput={this.updateTmpValue} />
			</div>
		);
	}

}
