import { h } from 'preact';
import { parseDate } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import pure from 'utils/pure';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import s from 'components/shared/editor/formEditor/formEditor.sss';


export default @pure class DateAttribute extends DisplayAttribute {

	constructor(props) {
		super(props);
		this.computeDateValue(this.state.value);
	}

	computeDateValue(v) {
		let date = parseDate(v);
		if (!date || isNaN(+date)) date = new Date();
		const inputFormatDate = formatDateTime(date, 'yyyy-MM-dd');
		this.fieldValue = inputFormatDate;
	}

	getInput = (value, description) => {
		return (
			<input
				type="date"
				value={this.fieldValue}
				class={s.input}
				ref={$ref => this.$input = $ref}
				onChange={this.updateTempValue}
				onBlur={this.onDateChange}
				placeholder={description}
			/>
		);
	}

	updateTempValue = () => {
		this.fieldValue = this.$input.value;
	}

	onDateChange = () => {
		const newVal = this.$input.value;
		this.setState({ value: newVal });
		this.onSave();
	}

}
