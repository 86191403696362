import { h } from 'preact';

const Spotify = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" {...props}>
			<path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-5.522-4.477-10-10-10Zm4.586 14.422a.623.623 0 0 1-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 1 1-.277-1.216c3.809-.87 7.076-.495 9.712 1.115.294.181.387.564.207.857ZM17.81 13.7a.78.78 0 0 1-1.073.257c-2.687-1.652-6.785-2.13-9.965-1.165a.78.78 0 0 1-.973-.52.781.781 0 0 1 .52-.972c3.632-1.102 8.147-.569 11.233 1.328a.779.779 0 0 1 .258 1.072Zm.105-2.835C14.69 8.95 9.375 8.775 6.297 9.708a.935.935 0 1 1-.543-1.79c3.533-1.072 9.405-.865 13.115 1.338a.935.935 0 0 1-.954 1.609Z" />
		</svg>
	);
};

export default Spotify;