import { h, Component } from 'preact';
import * as actions from 'store/actions';
import Store from 'store/store';

import defaultsDeepNode from '@nodeutils/defaults-deep';
import stringify from 'json-stable-stringify';
import isEqual from 'lodash/isEqual';
import pure from 'utils/pure';
import { joinClasses } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import dateDistance from 'utils/dateDistance.mjs';
import * as keyboardShortcuts from 'utils/keyboardShortcuts';

import FormEditor from 'components/shared/editor/formEditor/formEditor';
import CodeEditor from 'components/shared/editor/codeEditor/codeEditor';
import CreativeBuildInfo from 'components/shared/editor/creativeBuild/creativeBuildInfo';
import AppHistory from 'components/shared/appHistory/appHistory';
import AppInfo from 'components/shared/appInfo/appInfo';
import ViewChangesModal from 'components/pages/apps/modals/viewChangesModal';

import s from 'components/pages/apps/appDataEditor.sss';

export default @pure class AppDataEditor extends Component {
	constructor() {
		super();
		this.setData = this.setData.bind(this);
		this.revertChanges = this.revertChanges.bind(this);
		this.showHistory = this.updateViewMode.bind(this, 'history');
		this.showMerged = this.toggleMerged.bind(this, true);
		this.showUnmerged = this.toggleMerged.bind(this, false);
		this.viewChanges = this.viewChanges.bind(this);
		this.toggleMerged = this.toggleMerged.bind(this);
		this.updateSidebarContent = this.updateSidebarContent.bind(this);
		this.confirmLeaving = this.confirmLeaving.bind(this);
		this.renderFormEditor = this.renderFormEditor.bind(this);
		this.renderCodeEditor = this.renderCodeEditor.bind(this);
		this.renderHistory = this.renderHistory.bind(this);
		this.renderAppInfo = this.renderAppInfo.bind(this);
		this.state.extraSidebarContent = null;
		this.state.viewMode = 'editor';
		// this.state.viewMode = 'history';
	}

	setData(editedData) {
		let appData = this.props.data;
		Store.emit(actions.UPDATE_EDITED_APP_DATA, appData, editedData);
	}

	update(props) {
		props = props || this.props;
		// NOTE: use stringify to alphabetically order the object each time
		this.merged = JSON.parse(stringify(props.merged, { space: '\t' }));
		// Merged full will dsiplay the disabled, merged doesn't have the disabled
		this.mergedFull = JSON.parse(stringify(props.mergedFull, { space: '\t' }));

		let data = props.data;
		let attr = data.attributes || {};

		if (data.edited) {
			attr = defaultsDeepNode({}, data.edited);
		} else {
			attr = defaultsDeepNode({}, attr);
		}
		this.attributes = attr;
	}

	componentWillMount() {
		this.update();
	}

	componentWillReceiveProps(nextProps) {
		const { data, merged, mergedFull } = nextProps;

		if (data !== this.props.data) {
			this.setState({ isEdited: data.edited && !isEqual(data.edited, data.attributes) });
		}

		if (data !== this.props.data || merged !== this.props.merged || mergedFull !== this.props.mergedFull) {
			this.update(nextProps);
		}

		if (data?.type !== this.props.data?.type) {
			this.setState({ viewMode: 'editor' });
		}
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.confirmLeaving);
		window.beforePageChange = this.confirmLeaving;

		this.actions = this.props.registerActions([
			{ value: 'viewChanges', name: 'View changes since last save', action: this.viewChanges, keybinding: ['ctrl', 'shift', 'S'] }
		]);
		this.shortcutsHandler = keyboardShortcuts.register({
			'ctrl+H': { action: this.showHistory, name: 'Show app history' },
			'ctrl+shift+S': { action: this.viewChanges, name: 'View changes since last save' },
		});
	}

	componentWillUnmount() {
		this.actions?.unregister();
		this.shortcutsHandler?.stop();
		window.removeEventListener('beforeunload', this.confirmLeaving);
		window.beforePageChange = undefined;
	}

	updateViewMode(viewMode) {
		if (viewMode === this.state.viewMode) {
			return;
		}
		this.setState({ viewMode, extraSidebarContent: null });
	}

	updateSidebarContent(content) {
		this.setState({ extraSidebarContent: content });
	}

	async revertChanges(e) {
		if (!this.props.data.edited) {
			return;
		}

		let sure = true;
		if (!e.ctrlKey && !e.metaKey) {
			// eslint-disable-next-line no-alert
			sure = confirm('Are you sure you want to revert all your changes since the last save?\nThis cannot be undone, your changes will be lost forever.');
		}
		if (!sure) {
			return;
		}

		this.setData(undefined);
	}

	viewChanges() {
		Store.emit(
			actions.SHOW_MODAL,
			<ViewChangesModal data={this.props.data} />,
			'Changes since last save',
			'view-changes',
			result => {
				// Nothing currently
			}
		);
	}

	toggleMerged(merged) {
		if (typeof merged !== 'boolean') {
			merged = !this.state.showMergedEditor;
		}
		this.setState({ showMergedEditor: merged });
	}

	confirmLeaving(e) {
		if (!this.state.isEdited) return;
		let message = 'You have unsaved changes. u sure u wanna leave?';
		if (e) {
			e.returnValue = message;
		}
		return message;
	}

	renderFormEditor() {
		const props = this.props;

		const movieIdInfo = Store.get().movieIdInfo || {};

		return (
			<FormEditor
				edited={!!this.state.isEdited}
				data={props.data}
				query={props.query}
				path={props.query?.path}
				onChange={this.setData}
				overwriteTypes={props.devTypes}
				json={this.merged}
				jsonFull={this.mergedFull}
				jsonPaths={props.mergedPaths}
				activeType={props.activeType}
				getMerged={props.getMerged}
				activeEditedAppData={props.data?.edited || {}}
				activeAppData={this.attributes}
				branchData={props.branchData}
				updateSidebarContent={this.updateSidebarContent}
				thundrAppData={this.props.thundrAppData}
				saveData={this.props.saveData}
				movieIdInfo={movieIdInfo}
				registerActions={props.registerActions}
			/>
		);
	}

	renderCodeEditor() {
		let showMerged = this.state.showMergedEditor;
		let id = this.props.data.type + ':' + this.props.data.id;
		let key = showMerged ? 'merged-' + id : 'edit-' + id;
		let editor = (
			<CodeEditor
				class={s.codeEditor}
				key={key}
				value={showMerged ? this.merged : this.attributes}
				readOnly={showMerged}
				onChange={!showMerged && this.setData}
				dark
			/>
		);

		return (
			<div key="code-editor" class={s.code}>
				<div class={joinClasses(s.mergedSwitcher, showMerged && s.isMerged)} key="switcher">
					<span class={s.current} onClick={this.showUnmerged}>
						Current
					</span>
					<div class={s.indicator} onClick={this.toggleMerged} />
					<span class={s.merged} onClick={this.showMerged}>
						Merged
					</span>
				</div>
				{editor}
			</div>
		);
	}

	renderHistory() {
		return <AppHistory {...this.props} />;
	}

	renderAppInfo() {
		return <AppInfo {...this.props} />;
	}

	renderSaveButtons() {
		if (this.props.saving) {
			return <button class={s.savingBtn} key="saving">Saving...</button>;
		}
		const data = this.props.data;
		const conflicts = data.conflicts || [];
		let buttons = [];
		if (this.state.isEdited) {
			buttons.push(<button class={s.revertBtn} onClick={this.revertChanges} key="revert">Revert</button>);
		}
		if (conflicts.length) {
			buttons.push(
				<button class={s.conflictsBtn} onClick={() => this.props.showConflicts(data)} key="conflicts">
					{conflicts.length + ' conflict' + (conflicts.length !== 1 ? 's' : '')}
				</button>
			);
		} else if (this.state.isEdited) {
			buttons.push(<button class={s.saveBtn} onClick={this.props.saveData} key="save">Save Data</button>);
		}
		return buttons;
	}

	renderUpdatedAt() {
		let at = this.props.data?.modifiedAt;
		if (!at) {
			return;
		}
		let dateObject = new Date(at);
		let lastSavedFull = formatDateTime(dateObject, 'yyyy-MM-dd HH:mm:ss');
		return (
			<p class={s.lastSaved} title={lastSavedFull} key="modified-at">
				Last Saved<br />{dateDistance(dateObject)}
			</p>
		);
	}

	render(props, state) {
		let {
			wizards,
			activeType,
		} = props;

		let { viewMode } = state;

		const viewTypes = [
			{ type: 'editor', title: 'Editor' },
			{ type: 'codeEditor', title: 'Code Editor' },
			{ type: 'history', title: 'History' }
		];
		if (activeType === 'apps') {
			viewTypes.push({ type: 'appInfo', title: 'App Info' });
		} else if (activeType === 'titles') {
			viewTypes.push({ type: 'appInfo', title: 'Title Info' });
		} else if (activeType === 'studios' || activeType === 'studio_regions') {
			viewTypes.push({ type: 'appInfo', title: 'Studio Info' });
		}

		let renderers = {
			editor: this.renderFormEditor,
			codeEditor: this.renderCodeEditor,
			history: this.renderHistory,
			appInfo: this.renderAppInfo
		};

		let content = renderers[state.viewMode];
		if (typeof content === 'function') {
			content = content();
		}

		let branches = this.mergedFull?.dev?.branches || {};
		let branchIds = Object.keys(branches);
		let creativeBranchId = branchIds.length === 1 ? branchIds[0] : 'creative';
		let creativeBranch = branches[creativeBranchId];

		return (
			<div class={s.editor}>
				<div class={s.contentWrapper}>
					{creativeBranch && (
						<CreativeBuildInfo
							json={this.mergedFull}
							branchId={creativeBranchId}
							creativeBranch={creativeBranch}
							currentAppData={this.attributes}
							updateData={this.setData}
						/>
					)}
					<div class={s[viewMode + 'Container']} key="container">
						<div class={s.nav} key="nav">
							{viewTypes.map(viewType => (
								<button key={viewType.type} class={joinClasses(s.navSetting, viewMode === viewType.type && s.active)} onClick={() => this.updateViewMode(viewType.type)}>
									<span>{viewType.title}</span>
								</button>
							))}
							{state.extraSidebarContent}
							<p class={s.navTitle} key="options-title">Options</p>
							{wizards.map(w => {
								const enabled = w.enabled ?? true;
								const visible = w.visible ?? true;
								const commonProps = {
									class: joinClasses(s.menuBtn, w.class, !visible && s.hidden, !enabled && s.disabled),
									tabIndex: enabled ? 0 : -1,
									key: w.id,
								};
								if (w.link) {
									return <a {...commonProps} href={w.link} target="_blank">{w.label}</a>;
								}
								return <button {...commonProps} onClick={enabled && w.activate}>{w.label}</button>;
							})}
							{this.renderSaveButtons()}
							{this.renderUpdatedAt()}
						</div>
						{content}
					</div>
				</div>
			</div>
		);
	}
}
