export default class Filter {

	// matchProp: property used to determine if two filters are equal (default id)
	constructor(order, visible, id, displayProp, labelAll, options, current, excluded, matchProp, groupProp) {
		this.order = +order || 0;
		this.visible = visible;
		this.id = id;
		this.displayProp = displayProp;
		this.labelAll = labelAll;
		this.options = options || [];
		this.current = current || [];
		this.excluded = excluded || [];
		this.matchProp = matchProp || 'id';
		this.groupProp = groupProp;

		// add other theater attributes here
	}

	// set options(val) { this.$options = val || []; }
	// get options() { return this.$options; }

	// set current(val) { this.$current = val || []; }
	// get current() { return this.$current; }

}
