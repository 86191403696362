module.exports = [
	'%STATIC_ROOT%',
	'%IMAGE_ROOT%',
	'%MEDIA_ROOT%',
	'%MOVIE_NAME%',
	'%STUDIO_NAME%',
	'%GET_TICKETS%',
	'%IMAGE_REGION_ROOT%',
	'%IMAGE_STUDIO_ROOT%',
	'%VIDEO_ROOT%',
	'%REGION%',
	'%CURRENT_YEAR%',
	'%RELEASE_DATE%',
	'%TICKETS_ON_SALE%',
	'%HASHTAG%',
	'%PRIVACY_URL%'
];
