import { h, Component } from 'preact';
import { accessNested } from 'utils/utils.js';
import s from 'components/shared/form/selector.sss';

export default class Selector extends Component {

	constructor() {
		super();
		this.selected = this.selected.bind(this);
		this.state = {
			items: [],
			getLabel: o => o
		};
	}

	componentWillMount() {
		const props = this.props;
		this.updateItems(props.items || [], props.getLabel);
	}

	componentWillReceiveProps(props) {
		if (props.items.length !== this.props.items.length || props.items.some((e, i) => e?.id !== this.props.items[i]?.id)) {
			this.updateItems(props.items || [], props.getLabel);
		}
	}

	updateItems(items, getLabel) {
		let fn;
		if (typeof getLabel === 'function') {
			fn = getLabel;
		} else if (typeof getLabel === 'string' && getLabel.length) {
			fn = o => accessNested(o, getLabel);
		} else {
			fn = o => o;
		}
		this.setState({
			getLabel: fn,
			items: items.slice().sort(function(a, b) {
				a = fn(a);
				b = fn(b);
				if (typeof a === 'string' && typeof b === 'string') {
					return a.localeCompare(b);
				}
				if (a < b) return -1;
				if (a > b) return 1;
				return 0;
			})
		});
	}

	getValue() {
		let val = this.$selector && this.$selector.value;
		return this.props.items.find(o => {
			let elem = o;
			if (o && o.id) {
				elem = o.id.toString();
			}
			return elem === val;
		});
	}

	selected() {
		const value = this.getValue();
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	render(props, state) {
		const {
			value,
			disabled,
			selectMessage,
			name
		} = props;

		// Need to not be a const, getLabel is conditionally updated later
		let {
			items,
			getLabel
		} = state;

		const vid = value && value.id;
		if (typeof getLabel !== 'function') {
			getLabel = typeof getLabel === 'string' ? o => accessNested(o, getLabel) : o => o;
		}
		return (
			<div class={s.wrapper}>
				<select ref={e => (this.$selector = e)} class={s.select} onChange={this.selected} disabled={disabled} name={name} value={vid}>
					<option class={s.default} value="" selected={!value}>{selectMessage || '-- Select an option --'}</option>
					{items.filter(o => o).map(o => (
						<option value={o.id || o} selected={(o.id && o.id === vid) || o === value}>
							{getLabel(o)}
						</option>
					))}
				</select>
			</div>
		);
	}

}
