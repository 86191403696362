import { h, Component } from 'preact';
import fuzzaldrin from 'fuzzaldrin-plus';
import { joinClasses } from 'utils/utils';
import HTML from 'components/core/html/html';

import s from 'components/shared/inputWithSuggestions/inputWithSuggestions.sss';

const fuzzaldrinWrapped = (result, search) => {
	const wrapped = fuzzaldrin.wrap(result, search);
	// The library returns an empty array instead of an empty string if the search or result is empty
	if (typeof wrapped !== 'string') {
		return result;
	}
	return wrapped || result;
};

export default class InputWithSuggestions extends Component {

	constructor(props) {
		super(props);
		this.callOnChange = this.callOnChange.bind(this);
		this.onInput = this.onInput.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);

		this.state = {
			suggestions: [],
			selectedSuggestion: 0,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.list !== prevProps.list) {
			this.checkCompletion();
		}
		if (this.needsScroll) {
			this.needsScroll = false;
			let selected = this.base.querySelector('.' + s.suggestion + '.' + s.selected);
			if (selected) {
				selected.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'nearest'
				});
			}
		}
	}

	callOnChange() {
		this.props.onChange?.(this.value, this);
	}

	focus() {
		this.$input?.focus();
	}
	select() {
		this.$input?.select();
	}

	get value() {
		return this.$input?.value || '';
	}
	set value(v) {
		if (this.$input) {
			this.$input.value = v;
			this.checkCompletion();
		}
	}

	getSuggestionHTML(suggestion, search, wrapped) {
		return <HTML class={s.suggestionContent} content={wrapped} />;
	}

	onInput(e) {
		this.checkCompletion();
		this.props.onInput?.({ target: this });
	}

	checkCompletion() {
		if (!this.$input) return;
		let val = this.$input.value;

		let list = this.props.list;
		if (typeof list === 'function') {
			list = list(val);
		}
		let results = fuzzaldrin.filter(list || [], val);
		if (!val && this.props.showAllWhenEmpty) {
			results = list || [];
		}
		if (results[0] === val) results = [];
		let getHTML = this.props.suggestionHTML || this.getSuggestionHTML;
		return this.setState({
			selectedSuggestion: 0,
			suggestions: results.slice(0, 10).map(res => ({
				value: res,
				display: getHTML(res, val, fuzzaldrinWrapped(res, val))
			}))
		});
	}

	offsetSuggestionSelection(n) {
		let selectedSuggestion = this.state.selectedSuggestion + (n || 0);
		let length = this.state.suggestions.length || 1;
		while (selectedSuggestion >= length) selectedSuggestion -= length;
		while (selectedSuggestion < 0) selectedSuggestion += length;
		if (selectedSuggestion !== this.state.selectedSuggestion) {
			this.needsScroll = true;
			this.setState({ selectedSuggestion });
		}
	}

	useSuggestion(suggestion) {
		if (typeof suggestion === 'number') {
			suggestion = this.state.suggestions[suggestion];
		}
		if (!suggestion) return;
		this.$input.value = suggestion.value;
		this.checkCompletion();
		this.callOnChange();
	}

	onKeyDown(e) {
		const cancel = () => {
			e.preventDefault();
			e.stopPropagation();
		};
		if (e.keyCode === 27 && this.$input.value) { // ESC
			this.$input.value = '';
			this.checkCompletion();
			return cancel();
		}
		if (e.keyCode === 38) { // UP
			this.offsetSuggestionSelection(-1);
			return cancel();
		}
		if (e.keyCode === 40) { // DOWN
			this.offsetSuggestionSelection(1);
			return cancel();
		}
		// ~TAB or~ ENTER
		// const confirmKey = e.keyCode === 9 || e.keyCode === 13;
		const confirmKey = e.keyCode === 13;
		if (confirmKey && this.state.suggestions.length) {
			this.useSuggestion(this.state.selectedSuggestion);
			return cancel();
		}
		this.props.onKeydown?.({ target: this });
	}

	render(props) {
		let c = n => joinClasses(s[n], props.classes?.[n]);
		let suggestionsElems = this.state.suggestions.map((suggestion, i) => (
			<button class={joinClasses(c('suggestion'), i === this.state.selectedSuggestion && c('selected'))} onClick={() => this.useSuggestion(suggestion)} style={`--index:${i};`}>
				{suggestion.display}
			</button>
		));
		return (
			<div class={c('wrapper')}>
				<input
					type="text"
					class={c('input')}
					ref={e => this.$input = e}
					placeholder={props.placeholder}
					onKeyDown={this.onKeyDown}
					onInput={this.onInput}
					onChange={this.callOnChange}
					{...(props.value != undefined ? { value: props.value } : {})}
				/>
				<div class={c('suggestions')}>{suggestionsElems}</div>
			</div>
		);
	}

}
