// NOTE: some keyboard shortcuts cannot be overriden in some browsers (in chrome: ctrl+n, ctrl+t, ctrl+w)

// Mappings example:
// {
// 	'ctrl+A': { action: selectAll },
// 	'ctrl+shift+S': { action: saveAs },
// 	'/': { action: focusSearch, allowDefaultHandling: true },
// 	'ctrl+K': { chord: true },
// 	'ctrl+K,ctrl+U': { action: makeUppercase },
// }

// Warning! If a shortcut is defined as a chord, it will not be possible to use it as a single shortcut

// import createTooltip from 'utils/tooltip';

export const CATCH_ALL = Symbol('CATCH_ALL');
export const STOP_PROCESSING = Symbol('STOP_PROCESSING');

function groupByKey(arr) {
	return arr.reduce((grouped, [key, val]) => {
		if (!grouped[key]) {
			grouped[key] = [];
		}
		grouped[key].push(val);
		return grouped;
	}, {});
}

let listening = false;
const allMappings = {};
let mappings = {};
const chord = [];

// Consistent keyboard shortcuts across layouts is annoying (modifiers affect e.key, and e.code is about the physical key)
//   See https://github.com/w3c/uievents/issues/247
let simpleKeyCodeConverter = Object.fromEntries([
	...Array.from({ length: 10 }, (_, i) => [i + 48, String(i)]),
	...Array.from({ length: 26 }, (_, i) => [i + 65, String.fromCharCode(i + 65)]),
	...'0123456789*+-./'.split('').map((char, i) => [i + 96, char]),
	...Array.from({ length: 12 }, (_, i) => [i + 112, 'F' + i]),
	...';=,-./`'.split('').map((char, i) => [i + 186, char]),
	...'[\\]\''.split('').map((char, i) => [i + 219, char]),
]);
let keyboardLayout;
if (typeof window !== 'undefined' && navigator.keyboard) {
	navigator.keyboard.getLayoutMap().then(layout => {
		keyboardLayout = layout;
	});
}
function keyName(e) {
	const name = keyboardLayout?.get(e.code) || simpleKeyCodeConverter[e.keyCode] || e.key;
	return name?.length === 1 ? name.toUpperCase() : name;
}

function maybeStart() {
	if (!listening) {
		listening = true;
		document.body.addEventListener('keydown', handle);
	}
}
function maybeStop() {
	if (listening && !Object.keys(allMappings).length) {
		listening = false;
		document.body.removeEventListener('keydown', handle);
	}
}

function setMappings(id, mappings) {
	allMappings[id] = mappings;
	updateMappings();
}
function allEntries(obj) {
	// Object.entries does not list Symbols
	// return Object.entries(obj);
	return Reflect.ownKeys(obj).map(key => [key, obj[key]]);
}
function updateMappings() {
	mappings = groupByKey(Object.values(allMappings).flatMap(allEntries));
}

function sortHandlers(a, b) {
	return (+b?.priority || 0) - (+a?.priority || 0);
}

function runHandlers(handlers, event, data) {
	for (let handler of handlers) {
		try {
			const result = handler?.action?.call?.(this, handler, event, data);
			if (result === STOP_PROCESSING) {
				return;
			}
		} catch (e) { /**/ }
	}
}

function doesHandlerAllowDefaultHandling(handler, evt) {
	if (typeof handler?.allowDefaultHandling === 'function') {
		return handler.allowDefaultHandling(evt, handler);
	}
	return handler?.allowDefaultHandling;
}

const modifiers = ['Control', 'Meta', 'Alt', 'Shift'];
export function handle(keyboardEvent) {
	const key = keyboardEvent.key;
	if (!key || modifiers.includes(key)) {
		return;
	}

	let keyBinding = [
		// Always make cmd+key equivalent to ctrl+key
		(keyboardEvent.ctrlKey || keyboardEvent.metaKey) && 'ctrl',
		keyboardEvent.altKey && 'alt',
		keyboardEvent.shiftKey && 'shift',
		keyName(keyboardEvent),
	].filter(e => e).join('+');

	const fullChord = chord.concat(keyBinding).join(',');

	let results = mappings[fullChord];
	const catchAll = mappings[CATCH_ALL];
	if (!results) {
		if (chord.length) {
			// createTooltip(`Unrecognized keyboard shortcut: ${chord.concat(keyBinding).join(', ')}`, { duration: 1000 });
			chord.length = 0;
		}
		if (!catchAll) {
			return;
		}
	}

	results = (results || []).concat(catchAll || []).sort(sortHandlers);
	const handlerData = { chord: fullChord };

	if (!results.every(handler => doesHandlerAllowDefaultHandling(handler, keyboardEvent))) {
		keyboardEvent.preventDefault();
	}
	if (results.some(e => e?.chord)) {
		chord.push(keyBinding);
		if (catchAll) {
			runHandlers(
				catchAll.filter(handler => handler.ignoreChords).sort(sortHandlers),
				keyboardEvent,
				{ ...handlerData, waitingForChord: true }
			);
		}
		return;
	}
	chord.length = 0;
	runHandlers(results, keyboardEvent, handlerData);
}

let globalId = 0;
export function register(mappings) {
	let id = ++globalId;
	setMappings(id, mappings);
	maybeStart();
	return {
		setMappings: newMappings => setMappings(id, newMappings),
		stop: () => {
			delete allMappings[id];
			updateMappings();
			maybeStop();
		},
	};
}

export function getAll() {
	return mappings;
}