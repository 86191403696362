import { h } from 'preact';

const Giphy = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 32 32" role="img" {...props}>
			<path d="M2 2.057h2.797v19.886H2zM18.787 10.996l2.797-2.463v13.41h-2.797z" />
			<path d="M2 21.257h19.584V24H2zM2 0h10.912L11 2.743H2zM18.787 5.485V2.743h-2.799V0h-2.797v8.228h8.393V5.485" />
		</svg>
	);
};

export default Giphy;