import { h } from 'preact';
import Page from 'components/pages/page.js';
import s from 'components/pages/theatersMatcher/theatersMatcher.sss';
import TheaterUnmatcher from 'components/pages/theatersMatcher/theaterUnmatcher';
// import MatcherMap from 'components/pages/theatersMatcher/matcherMap';
import FilterTheaterMatcher from 'components/pages/theatersMatcher/filterTheaterMatcher';
import { condClass } from 'utils/utils';

const tabs = [
	{ id: 'filterTheaterMatcher', name: 'Theater Matcher', comp: FilterTheaterMatcher },
	{ id: 'theaterUnmatcher', name: 'Unmatching', comp: TheaterUnmatcher }
];

// Modulo that is always positive (-32 % 10 returns -2, mod(-32, 10) returns 8)
function mod(n, m) {
	while (n < 0) n += m;
	while (n >= m) n -= m;
	return n;
}

export default class TheatersMatcher extends Page {
	constructor(props, state) {
		super(props, state);
		this.state = {
			currentTab: tabs[0].id
		};
	}

	selectTab(id) {
		this.setState({ currentTab: id });
	}

	tabWheel = (e) => {
		let quantity = e.deltaY;
		let changeTab = e.deltaMode === e.DOM_DELTA_LINE || (e.deltaMode === e.DOM_DELTA_PIXEL && Math.abs(quantity) >= 50);
		if (changeTab && quantity) {
			let index = tabs.findIndex(e => e.id === this.state.currentTab);
			index += (quantity < 0 ? -1 : 1);
			index = mod(index, tabs.length);
			this.selectTab(tabs[index].id);
		}
	}

	renderTab = (tab) => {
		return (
			<div class={s.tab + condClass(this.state.currentTab === tab.id, s.active)} title={tab.name} onClick={() => this.selectTab(tab.id)}>
				<div class={s.name} data-text={tab.name} />
			</div>
		);
	}

	renderUnknownTab() {
		return (
			<div class={s.error}>Sorry, an error occurred!</div>
		);
	}

	render(props, { currentTab }) {
		let tab = tabs.find(tab => tab.id === currentTab);
		let TabComp = tab ? tab.comp : this.renderUnknownTab;
		return (
			<div class={s.wrapper}>
				<div class={s.tabs} onWheel={this.tabWheel}>
					{ tabs.map(this.renderTab) }
				</div>
				<div class={s.content}>
					<TabComp />
				</div>
			</div>
		);
	}
}
