import Store from 'store/store';

// Utils related to static data

let staticData = {};
let isStaticDataSet = false;

// Use this function if you need to grab static data from the Store
// This way you don't have to pass it down as a prop everywhere
// Don't use for pageData unless you are sure the data won't get
// updated anywhere
export const getStaticData = () => {
	if (!isStaticDataSet) {
		// Haven't retrieved data yet, so lets do that first
		let store = Store.get();
		staticData = store.appData;
		isStaticDataSet = true;
	}

	return staticData;
};
