import { h } from 'preact';
import Page from 'components/pages/page';
import pure from 'utils/pure';
import s from 'components/pages/dashboard/dashboard.sss';
import { Link } from 'components/core/link/link';
import ScrollingNumber from 'components/shared/scrollingNumber/scrollingNumber';
import ScrollBox from 'components/shared/scrollBox/scrollBox';
// import CachedButton from 'components/pages/dashboard/cachedButton';
// import moment from 'moment';
import formatDateTime from 'utils/formatDateTime';
import dateDistance from 'utils/dateDistance.mjs';
import { joinClasses, parseDate } from 'utils/utils';

function getTitles(appList) {
	return appList.reduce((a, app) => {
		let t = app.rel.title;
		if (!a.includes(t)) {
			a.push(t);
		}
		return a;
	}, []);
}

function getHistory(appList) {
	let allDeploys = appList.flatMap(app => (
		app.history?.map(hist => ({ ...hist, app }))
	)).filter(e => e);
	return allDeploys.sort((a1, a2) => a2.at - a1.at).slice(0, 50);
}


export default @pure class Dashboard extends Page {

	constructor(props) {
		super(props);
		this.state = {
			totalSessionCount: 0,
			v3Total: 0,
			v3Titles: 0,
			v3CreatedMonthly: 0,
			v3TitlesCreatedMonthly: 0,
			v3LiveMonthly: 0,
			v3TitlesLiveMonthly: 0,
		};
		this.onesheetError = this.onesheetError.bind(this);
		this.renderHistoryApp = this.renderHistoryApp.bind(this);
	}

	onesheetError(e) {
		e.target.src = '//dx35vtwkllhj9.cloudfront.net/images/default-onesheet.jpg';
	}

	updateCounts(props) {
		if (props.activeSessionCounts) {
			this.setState({
				totalSessionCount: Object.values(props.activeSessionCounts).reduce((sum, c) => sum + c, 0)
			});
		}
		let appsList = Object.values(props.list?.apps || {});
		let currentDate = new Date();
		let currentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth());
		let nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
		let createdThisMonth = appsList.filter(app => {
			let createdAt = new Date(app.createdAt);
			return createdAt >= currentMonth && createdAt < nextMonth;
		});
		let liveThisMonth = appsList.filter(app => {
			let release = parseDate(app.release);
			return release >= currentMonth && release < nextMonth;
		});
		this.setState({
			v3Total: appsList.length,
			v3Titles: getTitles(appsList).length,
			v3CreatedMonthly: createdThisMonth.length,
			v3TitlesCreatedMonthly: getTitles(createdThisMonth).length,
			v3LiveMonthly: liveThisMonth.length,
			v3TitlesLiveMonthly: getTitles(liveThisMonth).length,
			historyApps: getHistory(appsList)
		});
	}

	componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}
		this.updateCounts(this.props);
	}

	componentWillReceiveProps(props) {
		// if(!props.loaded) return;
		this.updateCounts(props);
	}

	renderHistoryApp(hist) {
		const props = this.props;
		const list = props.list;

		let studio = list.studios[hist.app.rel?.studio];
		let title = list.titles[hist.app.rel?.title];
		let region = list.regions[hist.app.rel?.region];
		if (!studio || !title || !region) {
			return;
		}
		let isBolt = hist.app.rel.base === 2;

		let at = new Date(hist.at);
		// let createdAtNice = moment(hist.at).fromNow();
		let createdAtNice = dateDistance(at);
		let createdAtFull = formatDateTime(at, 'yyyy-MM-dd HH:mm:ss');

		let linkProps = {
			pageId: 'editApp',
			queryString: { app: hist.app.id, title: null, studio: null, studio_region: null, region: null, base: null, branch: null }
		};

		return (
			<Link
				class={s.historyItem}
				{...linkProps}
			>
				<div class={s.historyImage}>
					<img
						class={s.historyOnesheet}
						src={`//dx35vtwkllhj9.cloudfront.net/${studio.slug}/${title.slug}/images/regions/${region.slug}/onesheet-standard-250.jpg`}
						onError={this.onesheetError}
					/>
					<div class={joinClasses(s.historyName, s[hist.by])}>{hist.by}</div>
				</div>
				<div class={s.historyTime} title={createdAtFull}>{createdAtNice}</div>
				<div class={s.historyStudio}>{studio.name}</div>
				<div class={s.historyTitle}>{isBolt ? '⚡️ ' : ''}{title.en} - {region.slug?.toUpperCase()}</div>
			</Link>
		);
	}

	render(props, state) {
		let {
			error,
			loaded
		} = props;

		if (error) {
			return (
				<div class={joinClasses(s.container, s.error)} key="error">
					Oh no! An error occurred while loading thundr
					<span class={s.note}>(we are probably having issues with the servers)</span>
				</div>
			);
		}
		if (!loaded) {
			return <div class={joinClasses(s.container, s.loading)} key="loading" />;
		}

		return (
			<div class={s.container} key="container">
				{/* <CachedButton /> */}
				<div class={s.metrics}>
					<div class={s.line}>
						<div class={s.metric}>
							<div class={s.title} title="Sessions with events in the last 30 minutes">Active sessions</div>
							<ScrollingNumber value={state.totalSessionCount} from={0} duration={1000} class={s.value} />
						</div>
					</div>
					<div class={joinClasses(s.line, s.withName)}>
						<div class={s.name}>Thundr Apps</div>
						<div class={s.metric}>
							<div class={s.title} title={state.v3Titles + ' unique movies'}>Total</div>
							<ScrollingNumber value={state.v3Total} from={0} duration={500} class={s.value} />
						</div>
						<div class={s.metric}>
							<div class={s.title} title={state.v3TitlesCreatedMonthly + ' unique movies'}>Created this month</div>
							<ScrollingNumber value={state.v3CreatedMonthly} from={0} duration={500} class={s.value} />
						</div>
						<div class={s.metric}>
							<div class={s.title} title={state.v3TitlesLiveMonthly + ' unique movies'}>Live this month</div>
							<ScrollingNumber value={state.v3LiveMonthly} from={0} duration={500} class={s.value} />
						</div>
					</div>
				</div>
				<div class={s.history}>
					<h2 class={s.subTitle}>Latest Production Pushes</h2>
					<ScrollBox class={s.historyContent}>
						{state.historyApps?.map(this.renderHistoryApp)}
					</ScrollBox>
				</div>
			</div>
		);
	}
}

export const dependencies = {
	activeSessionCounts : true,
	list: true,
	apps: true,
	titles: true,
	studios: true,
	regions: true
};
