import { accessNested } from 'utils/utils';

export default function(code, appData) {
	if (!code) return false;
	let ret = false;
	code = code.trim();
	// Handle gsheet strings
	if (code.startsWith('"') && code.endsWith('"')) {
		let sliced = code.slice(1, -1);
		// extra check: only update if there is no non-duplicated quotes
		if (!sliced.match(/(^|[^"])"([^"]|$)/)) {
			code = sliced.replace(/""/g, '"');
		}
	}
	for (let i = 0; i < checkList.length && !ret; i++) {
		ret = checkList[i](code, appData);
	}
	return ret;
}

const checkList = [
	checkWeborama,
	checkFacebook,
	checkTwitter,
	checkRhythmOne,
	checkOperam,
	checkFloodlight,
	checkGTM,
	checkGTag,
	checkGA4Tag,
	checkGTMConversion,
	checkRemarketing,
	checkAppNexus,
	checkTurbine,
	checkAdobeDTM,
	checkTradeDesk,
	checkHotJar,
	checkEulerian,
	checkRetargeting,
	checkLightningBolt,
	checkOneAd,
	checkEnhance,
	checkPlista,
	checkSnapchat,
	checkPinterest,
	checkTikTok,
	checkAd2,
	checkYahoo
];

const basePath = 'apis.tracking.pixels';

function standardMatch(name, path, categoryPath, value) {
	// let edits = path;
	let edits = categoryPath;
	if (!Array.isArray(edits)) {
		// edits = [{ path, value }];
		edits = [{ categoryPath, value }];
	}
	return { name, path, categoryPath, edits };
}
function cancel() {
	return { cancelled: true };
}
function error(name, error) {
	return { name, error: error || true };
}

const concatToPath = (appData, path, paramMatch) => {
	let currentData = accessNested(appData, path);
	let newList = typeof currentData === 'string' ? currentData.split(',').map(e => e.trim()) : [];
	let newParam = paramMatch;
	if (newList.indexOf(newParam) === -1) {
		newList.push(newParam);
	}
	return newList.filter(e => e).join(', ');
};


// function findFunctionCall(code, functionName, firstArgument) {
// 	let pos = -1;
// 	while ((pos = code.indexOf(functionName + '(', pos + 1)) !== -1) {
// 		let params = code.slice(pos).match(/^(("(\\"|[^"])*"|'(\\'|[^'])*'|[^)])*)\)/);
// 		if (!params) continue;
// 		params = params[1];
// 		if (firstArgument) {
// 			let trimmed = params.trim();
// 			if (typeof firstArgument === 'number' && !trimmed.startsWith(firstArgument)) {
// 				continue;
// 			}
// 			if (typeof firstArgument === 'string' && !trimmed.startsWith('"' + firstArgument.replace(/"/g, '\\"') + '"') && !trimmed.startsWith("'" + firstArgument.replace(/'/g, "\\'") + "'")) {
// 				continue;
// 			}
// 		}
// 		// Parses argument using eval, probably too dangerous
// 		return (new Function('return [' + params + ']'))();
// 	}
// 	return null;
// }

// function extractJSValue(str) {
// 	if (!str || typeof str !== 'string') return null;
// 	str = str.trim();
// 	// Base values
// 	if (str === 'true') return true;
// 	if (str === 'false') return false;
// 	if (str === 'undefined') return undefined;
// 	if (str === 'null') return null;
// 	// Numbers + Infinity
// 	if (!isNaN(+str)) {
// 		return +str;
// 	}
// 	// String
// 	let strMatch = str.match(/^('([^'\\]|\\.)*'|"([^"\\]|\\.)*")$/);
// 	if (strMatch) {
// 		// BAD - let javascript eval to avoid having to parse all potential escape sequences
// 		return (new Function('return [' + str + ']'))();
// 	}
// 	// TODO: object, array
// 	return;
// }

const pixelTypes = {
	landing: {
		type: 'landing',
		short: 'l',
		description: '(L)anding: when the user lands on the site'
	},
	view: {
		type: 'view',
		short: 'v',
		description: '(V)iew: When the user sees a page'
	},
	ticket: {
		type: 'ticket',
		short: 't',
		description: '(T)icket: when the user clicks on a ticket link'
	},
};
function askType(allowedTypes) {
	allowedTypes = allowedTypes || ['landing', 'ticket'];
	let types = pixelTypes;
	if (!Array.isArray(allowedTypes) && typeof allowedTypes === 'object') {
		types = allowedTypes;
		allowedTypes = Object.keys(types);
	}
	if (!Array.isArray(allowedTypes)) {
		return undefined;
	}
	allowedTypes = allowedTypes.map(type => types[type]).filter(e => e);
	if (!allowedTypes.length) {
		return undefined;
	}
	let lines = [
		'Which type of pixel is this? (you can type only the first letter)',
		''
	].concat(allowedTypes.map(type => type.description));
	// eslint-disable-next-line no-alert
	let selected = prompt(lines.join('\n'), allowedTypes[0].short.toUpperCase());
	if (!selected) {
		return false;
	}
	let selectedLetter = selected.trim().toLowerCase()[0];
	let selectedData = allowedTypes.find(type => type.short === selectedLetter) || allowedTypes[0];
	return selectedData.type;
}

// TODO: folder with separate files ?

function checkWeborama(code, appData) {
	if (code.indexOf('solution.weborama.fr') === -1) {
		return false;
	}
	let match = code.match(/(^|"|')((https?:)?\/\/\w+.solution.weborama.fr[^"']*)(\1|$)/);
	if (!match) {
		return error('Weborama', 'Cannot find url');
	}
	let url = match[2];
	let key = askType(['landing', 'ticket']);
	if (!key) return cancel();

	const path = `${basePath}.weborama.${key}`;
	const categoryPath = `${basePath}.weborama`;

	let finalObj = accessNested(appData, categoryPath, {});
	if (key === 'landing') {
		finalObj.landing = url;
	}
	if (key === 'ticket') {
		finalObj.ticket = url;
	}

	return standardMatch('Weborama', path, categoryPath, finalObj);
}

// TODO: utils
// Parses javascript object litteral in a string (bit different than JSON as it accepts unquoted keys, single quotes, etc.)
// Inspired by https://github.com/daepark/JSOL
// Might be dangerous (pls don't use outside of thundr)
function parseJSOL(str) {
	if (!str || typeof str !== 'string') return null;
	str = str.trim();
	// Magic check to prevent injections and ensure the format
	if (!/^[\],:{}\s]*$/.test(
		str.replace(/\\(?:["'\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@')
			.replace(/"[^"\\\n\r]*"|'[^'\\\n\r]*'|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
			.replace(/(?:^|:|,)(?:\s*\[)+/g, ':')
			// This next one allows unquoted keys
			.replace(/\w*\s*:/g, ':')
	)) {
		throw new Error('Invalid object');
	}
	// Object seems correct, so eval it
	return (new Function('return ' + str))();
}

function checkFacebook(code, appData) {
	if (code.indexOf('fbevents.js') === -1) {
		return false;
	}

	// matches fbq('init', '321654978')
	let fbqInit = code.match(/fbq\(\s*('|")init\1\s*,\s*('|")(\d+)\2(?:\s*,\s*([^)]+))?\s*\)/);
	if (!fbqInit) {
		return error('Facebook', 'Cannot find tag id');
	}
	let id = fbqInit[3];
	let initData;
	try {
		initData = parseJSOL(fbqInit[4]);
	} catch(e) { /* nope */ }

	let path = `${basePath}.facebookTag`;
	const categoryPath = path;

	// matches fbq('track', 'PageView')
	let fbqTrack = code.match(/fbq\(\s*('|")track\1\s*,\s*('|")([^'"]+)\2\s*\)/);
	let trackId = (fbqTrack && fbqTrack[3]) || 'PageView';

	id = concatToPath(appData, path + '.id', id);
	initData = concatToPath(appData, path + '.initData', initData);
	trackId = concatToPath(appData, path + '.trackId', trackId);
	const finalObj = initData ? { id, initData, trackId } : { id, trackId };

	return standardMatch('Facebook', path, categoryPath, finalObj);
}


function checkTwitter(code, appData) {
	if (code.indexOf('ads-twitter.com/uwt.js') === -1) {
		return false;
	}

	// matches twq('init', 'sdflj')
	let twqInit = code.match(/twq\(\s*('|")init\1\s*,\s*('|")(\w+)\2\s*\)/);
	if (!twqInit) {
		return error('Twitter', 'Cannot find tag id');
	}
	let id = twqInit[3];

	let path = `${basePath}.twitterTag`;
	const categoryPath = path;

	let twqTrack = code.match(/twq\(\s*('|")track\1\s*,\s*('|")([^'"]+)\2\s*\)/);
	let trackId = (twqTrack && twqTrack[3]) || 'PageView';

	id = concatToPath(appData, path + '.id', id);
	trackId = concatToPath(appData, path + '.trackId', trackId);
	const finalObj = { id, trackId };

	return standardMatch('Twitter', path, categoryPath, finalObj);
}

function checkRhythmOne(code, appData) {
	if (code.indexOf('rs.gwallet.com') === -1) {
		return false;
	}
	let match = code.match(/\(\s*document\s*,\s*([^)]+)\)/);
	if (!match) {
		return error('RhythmOne', 'Cannot find id');
	}
	let id = match[1].trim();
	if ((id[0] === '"' || id[0] === "'") && id.endsWith(id[0])) {
		id = id.slice(1, -1);
	}

	const path = `${basePath}.gwalletTag.id`;
	const categoryPath = `${basePath}.gwalletTag`;
	const finalObj = { id };

	return standardMatch('RhythmOne', path, categoryPath, finalObj);
}

function checkOperam(code, appData) {
	if (code.indexOf('oprm.co/pixel.js') === -1) {
		return false;
	}
	let initLine = code.match(/operam\(\s*('|")init\1\s*,\s*('|")([0-9a-z-]+)\2/);
	if (!initLine) {
		return error('Operam', 'Cannot find init id');
	}
	let initId = initLine[3];

	let loadLine = code.match(/operam\(\s*('|")load\1\s*,\s*('|")([^'"]+)\2/);
	if (!loadLine) {
		return error('Operam', 'Cannot find load id');
	}
	let loadId = loadLine[3];

	let path = 'apis.tracking.operam';
	const categoryPath = path;

	initId = concatToPath(appData, path + '.initId', initId);
	loadId = concatToPath(appData, path + '.loadId', loadId);
	const finalObj = { initId, loadId };

	return standardMatch('Operam', path, categoryPath, finalObj);
}

function checkFloodlight(code, appData) {
	if (code.indexOf('fls.doubleclick.net/activityi') === -1) {
		return false;
	}
	let data = code.match(/activityi;src=(\d+);type=([^;]+);cat=([^;]+);/i);
	if (!data) {
		return error('Floodlight', 'Cannot find params');
	}
	let key = askType(['landing', 'ticket']);
	if (!key) return cancel();
	const path = `${basePath}.floodlight.${key}`;
	const categoryPath = `${basePath}.floodlight`;
	let currentData = accessNested(appData, path);
	let newList = typeof currentData === 'string' ? currentData.split(',').map(e => e.trim()) : [];
	let newId = data.slice(1, 4).join(':');
	if (newList.indexOf(newId) === -1) {
		newList.push(newId);
	}
	const newListString = newList.filter(e => e).join(', ');

	let finalObj = accessNested(appData, categoryPath, {});
	if (key === 'landing') {
		finalObj.landing = newListString;
	}
	if (key === 'ticket') {
		finalObj.ticket = newListString;
	}

	return standardMatch('Floodlight', path, categoryPath, finalObj);
}

// Google Tag Manager Global Site Tag
function checkGTag(code, appData) {
	if (code.indexOf('googletagmanager.com/gtag/js') === -1) {
		return false;
	}

	if (code.indexOf('googletagmanager.com/gtag/js?id=G-') !== -1 ||
		code.indexOf('googletagmanager.com/gtag/js?id=UA-') !== -1) {
		//Google Analytics 4 || Universal Analytics
		return checkGA4Tag(code, appData);
	}

	// Added (?!(G-))(?!(UA-)) to ensure not match with Google Analytics 4 and Universal Analytics
	// Might be safer (but more annoying) to check for the line gtag('config', 'ID-HERE');
	let data = code.match(/googletagmanager.com\/gtag\/js\?id=((?!(G-))(?!(UA-))[a-z0-9_-]+)/i);

	if (!data) {
		return error('GTag', 'Cannot find id');
	}

	const path = 'apis.tracking.googleTagManager.uaId';
	const categoryPath = 'apis.tracking.googleTagManager';
	let currentData = accessNested(appData, path);
	let newList = typeof currentData === 'string' ? currentData.split(',').map(e => e.trim()) : [];
	let newId = data[1];
	if (newList.indexOf(newId) === -1) {
		newList.push(newId);
	}
	newList = newList.filter(e => e).join(', ');
	const finalObj = { uaId: newList };

	return standardMatch('GTag', path, categoryPath, finalObj);
}

//  Google Analytics 4 & Universal Analytics, sits under powsterGATracking
function checkGA4Tag(code, appData) {
	if (code.indexOf('googletagmanager.com/gtag/js?') === -1) {
		return false;
	}
	// Might be safer (but more annoying) to check for the line gtag('config', 'ID-HERE');
	let data = code.match(/googletagmanager.com\/gtag\/js\?id=([a-z0-9_-]+)/i);
	if (!data) {
		return error('GTag', 'Cannot find id');
	}

	const path = 'apis.tracking.powsterGATracking';
	const currentData = accessNested(appData, path, {});

	if (Object.values(currentData).includes(data[1])) {
		return error('GTag', 'Tracking ID already added');
	}

	let tagName = prompt('What do you want to call this tracking ID?', 'extraGATag');

	if (currentData[tagName]) {
		return error('GTag', 'Tag name already used');
	} else {
		currentData[tagName] = data[1];
	}

	const finalObj = currentData;

	return standardMatch('GTag', path, path, finalObj);
}

// Google Tag Manager Tag ID
function checkGTM(code, appData) {
	if (code.indexOf('googletagmanager.com/gtm.js') === -1) {
		return false;
	}
	let data = code.match(/['"](GTM-[A-Z0-9_-]+)/i);
	if (!data) {
		return error('GTM', 'Cannot find id');
	}

	let path = 'apis.tracking.googleTagManager.tagId';
	const categoryPath = 'apis.tracking.googleTagManager';
	let newId = data[1];

	let currentData = accessNested(appData, path);
	// let currentData = accessNested(appData, categoryPath);

	let newData = newId;
	let finalObj = {};

	// tagId field is a string in schema.js
	if (typeof currentData === 'string') {
		finalObj.tagId = newId;
	} else if (Array.isArray(currentData)) {
		newData = currentData.concat(newId);
		finalObj.tagId = newData;
	} else if (typeof currentData === 'object' && currentData) {
		path += `.${newId}`;
		// newData = true;

		// new GTM
		if (!currentData.tagId) {
			finalObj.tagId = '';
			finalObj.tagId = newId;
		}
		// when GTM exists as a string
		if (typeof currentData.tagId === 'string') {
			// add new GTM id
			currentData = currentData.tagId.split(' ').concat(newData);
			currentData.length > 1 ? currentData = currentData.join(', ') : currentData = currentData.join('');
			finalObj.tagId = currentData;
		}
		// when GTM exists as an object
		// 06-02-20: not sure if this works
		if (typeof currentData.tagId === 'object') {
			finalObj = currentData.tagId;
			finalObj[newId] = newData;
		}
	}

	return standardMatch('GTM', path, categoryPath, finalObj);
}

function checkGTMConversion(code, appData) {
	if (!/gtag\(('|")event\1\s*,\s*('|")conversion\2\s*,\s*{/.test(code)) {
		return false;
	}
	let data = code.match(/send_to['"]?\s*:\s*(?:'([^']+)'|"([^"]+)")/);
	if (!data) {
		return error('GTM (conversion)', 'Cannot find id');
	}
	let send_to = data[1] || data[2];
	let key = askType(['landing', 'view', 'ticket']);
	if (!key) return cancel();

	const path = `apis.tracking.googleTagManager.${key}Conversion`;
	const categoryPath = 'apis.tracking.googleTagManager';

	const finalObj = {
		[`${key}Conversion`]: send_to
	};

	return standardMatch('GTM (conversion)', path, categoryPath, finalObj);
}

function checkRemarketing(code, appData) {
	if (code.indexOf('googleads.g.doubleclick.net/pagead') === -1 && code.indexOf('googleadservices.com/pagead') === -1) {
		return false;
	}
	let idMatch = code.match(/google_conversion_id\s*=\s*('[^']'|"[^"]*"|\d+)/);
	if (!idMatch) {
		return error('Remarketing', 'Cannot find id');
	}
	let id = idMatch[1];
	if (id[0] === '"' || id[0] === "'") {
		id = id.slice(1, -1);
	}
	let label;
	let labelMatch = code.match(/google_conversion_label\s*=\s*('[^']'|"[^"]*")/);
	if (labelMatch) {
		label = labelMatch[1].slice(1, -1);
	}
	// TODO: also check for standard other boolean (e.g. !1) ? Or eval ?
	let remarketingOnly = true;
	let remarketingOnlyMatch = code.match(/google_remarketing_only\s*=\s*(true|false)/);
	if (remarketingOnlyMatch) {
		remarketingOnly = remarketingOnlyMatch[1] !== 'false';
	}

	let key = askType(['landing', 'ticket']);
	if (!key) return cancel();
	let finalObj = { id };
	if (key === 'landing') {
		finalObj.remarketingOnly = remarketingOnly;
	}
	if (label) {
		finalObj.label = label;
	}
	let path = `${basePath}.remarketing`;
	const categoryPath = path;
	if (key !== 'landing') {
		path += '.' + key;
	}
	return standardMatch('Remarketing', path, categoryPath, finalObj);
}

function checkAppNexus(code, appData) {
	// https://secure.adnxs.com/px?id=1027461&seg=14438001&t=2
	if (code.indexOf('secure.adnxs.com/px') === -1) {
		return false;
	}
	let qsMatch = code.match(/adnxs.com\/px\?([^ '"#]+)/);
	if (!qsMatch) {
		return error('AppNexus', 'Cannot extract url');
	}

	let initialObj = {};
	qsMatch[1].split('&').map(el => {
		let [key, value] = el.split('=');
		if (key === 'id' || key === 'seg') {
			initialObj[key] = value;
		}
	});
	if (!initialObj.id) {
		return error('AppNexus', 'Could not find ID');
	}

	const path = `${basePath}.appNexus`;
	const categoryPath = path;

	let currentData = accessNested(appData, categoryPath, {});

	// modify existing data in Thundr when pixel was only for landing page
	if (currentData && currentData.id) {
		let modifyData = {};
		currentData.seg ? modifyData.landing = `${currentData.id}:${currentData.seg}` : modifyData.landing = currentData.id;
		if (currentData.trackingCategories) {
			modifyData.trackingCategories = currentData.trackingCategories;
		}
		currentData = Object.assign(modifyData, {});
	}
	let finalObj = Object.assign(currentData, {});

	let key = askType(['landing', 'ticket']);
	if (!key) return cancel();

	initialObj.seg ? finalObj[key] = `${initialObj.id}:${initialObj.seg}` : finalObj[key] = initialObj.id;

	return standardMatch('AppNexus', path, categoryPath, finalObj);
}

function checkPlista(code, appData) {
	if (code.indexOf('farm-ch.plista.com') === -1) {
		return false;
	}
	let qsMatch = code.match(/farm-ch.plista.com\/activity2;([^ '"#]+)/);
	if (!qsMatch) {
		return error('Plista', 'Cannot extract url');
	}
	let finalObj = {};
	qsMatch[1].split(';').map(el => {
		let [key, value] = el.split(':');
		if (key === 'domainid' || key === 'campaignid') {
			finalObj[key] = value;
		}
	});
	if (!finalObj.domainid) {
		return error('Plista', 'Could not find Domain ID');
	}
	if (!finalObj.campaignid) {
		return error('Plista', 'Could not find Campaign ID');
	}
	const path = `${basePath}.plista`;
	const categoryPath = path;

	return standardMatch('Plista', path, categoryPath, finalObj);
}

function checkTurbine(code, appData) {
	if (code.indexOf('au-gmtdmp.mookie1.com/t') === -1) {
		return false;
	}
	let qsMatch = code.match(/\/activity\?([^ '"#]+)/);
	if (!qsMatch) {
		return error('Turbine', 'Cannot extract url');
	}
	let finalObj = {};
	qsMatch[1].split('&').map(el => {
		let [key, value] = el.split('=');
		if (key === 'tagid') {
			finalObj[key] = value;
		}
	});
	if (!finalObj.tagid) {
		return error('Turbine', 'Could not find ID');
	}

	const path = `${basePath}.turbine`;
	const categoryPath = path;
	return standardMatch('Turbine', path, categoryPath, finalObj);
}

function checkAdobeDTM(code, appData) {
	// assets.adobedtm.com/60352dc6c5edfc390ef3beb45ec641420956fbbb/satelliteLib-52d788f898e7f62f0c059164028a42fe02d1cc40.js
	if (code.indexOf('assets.adobedtm.com') === -1) {
		return false;
	}
	let urlMatch = code.match(/assets.adobedtm.com\/\S+.js/);
	if (!urlMatch) {
		return error('Adobe DTM', 'Cannot extract url');
	}

	const path = `${basePath}.adobeDTM`;
	const categoryPath = path;

	return standardMatch('Adobe DTM', path, categoryPath, '//' + urlMatch[0]);
}

function checkTradeDesk(code, appData) {
	if (code.indexOf('TTDUniversalPixelApi') === -1) {
		return false;
	}

	// matches universalPixelApi.init("y80w81u", ["q7kads8"], "https://insight.adsrvr.org/track/up")
	let idsMatch = code.match(/universalPixelApi\.init\(\s*('|")([^'"]+)\1\s*,\s*(\[[^\]]+\])(?:\s*[,)])/);
	if (!idsMatch) {
		return error('Trade Desk', 'Cannot extract ids');
	}
	let adv = idsMatch[2];
	let upid;
	try {
		upid = parseJSOL(idsMatch[3]);
	} catch(e) { /* nope */ }

	const path = `${basePath}.tradeDesk`;
	const categoryPath = path;

	return standardMatch('Trade Desk', path, categoryPath, { adv, upid: upid.join(', ') });
}

function checkHotJar(code, appData) {
	if (code.indexOf('static.hotjar.com') === -1) {
		return false;
	}

	let hotjarIdMatch = code.match(/hjid:(\d+),/);
	let snippetVersionMatch = code.match(/hjsv:(\d+)}/);

	if (!hotjarIdMatch || !snippetVersionMatch) {
		return error('Hot Jar', 'Cannot extract ids');
	}

	let idPath = 'apis.tracking.hotjar.hotjarID';
	let svPath = 'apis.tracking.hotjar.snippetVersion';
	const path = 'apis.tracking.hotjar';
	const categoryPath = path;

	let hotjarID = concatToPath(appData, idPath, hotjarIdMatch[1]);
	let snippetVersion = concatToPath(appData, svPath, snippetVersionMatch[1]);
	const finalObj = { hotjarID, snippetVersion };

	return standardMatch('Hot Jar', path, categoryPath, finalObj);
}

function checkEulerian(code, appData) {
	if (code.indexOf('EA_data') === -1) {
		return false;
	}
	let eulerianDomainMatch = code.match(/var\s+td\s*=\s*(?:"|')(\w+[.]\w+[.]\w+)(?:"|')/);
	if (!eulerianDomainMatch) {
		return error('Eularian', 'Cannot extract domain');
	}
	let domain = eulerianDomainMatch[1];
	const path = 'apis.tracking.eulerian';
	const categoryPath = path;
	const finalObj = { domain };
	return standardMatch('Eulerian', path, categoryPath, finalObj);
}

function checkRetargeting(code, appData) {
	if (code.indexOf('bs.serving-sys.com') === -1) {
		return false;
	}
	let retargetingMatch = code.match(/ActivityID=(\d+)&/);
	if (!retargetingMatch) {
		return error('Retargeting', 'Cannot extract id');
	}
	let  activityID = retargetingMatch[1];
	const path = 'apis.tracking.retargeting';
	const categoryPath = path;
	const finalObj = { activityID };
	return standardMatch('Retargeting', path, categoryPath, finalObj);
}

function checkLightningBolt(code, appData) {
	if (code.indexOf('LightningBolt.js') === -1) {
		return false;
	}

	let matchID = code.match(/var\s+lbTrans\s*=\s*(?:"|')([a-zA-Z0-9]+)(?:"|')/);
	let matchValue = code.match(/var\s+lbValue\s*=\s*(?:"|')([0-9.]+)(?:"|')/);
	let matchData = code.match(/var\s+lbData\s+=\s+([^;]*);/);

	if (!matchID || !matchValue || !matchData) {
		return error('Lightning Bolt', 'Cannot extract values');
	}

	let transactionID = matchID[1];
	let transactionValue = matchValue[1];
	let transactionData = matchData[1];

	const path = 'apis.tracking.lightningBolt';
	const categoryPath = path;
	const finalObj = { transactionID, transactionValue, transactionData };
	return standardMatch('Lightning Bolt', path, categoryPath, finalObj);
}


function checkOneAd(code, appData) {
	if (code.indexOf('onetag.onead') === -1) {
		return false;
	}
	let matchID = code.match(/\/(\d+)\.js/);
	if (!matchID) {
		return error('OneAd', 'Cannot extract id');
	}
	let tagCode = matchID[1];
	const path = 'apis.tracking.oneAd';
	const categoryPath = path;
	const finalObj = { tagCode };
	return standardMatch('OneAd', path, categoryPath, finalObj );
}


function checkEnhance(code, appData) {
	if (code.indexOf('jscdn.appier.net') === -1) {
		return false;
	}

	const grabObjects = code.match(/push\(([^)]+)\)/);
	const stringifiedObjects = grabObjects && grabObjects[1].trim().split('\n');
	if (!stringifiedObjects.length) {
		return error('Enhance', 'Cannot extract values');
	}

	let firstObject = stringifiedObjects.shift();
	const idMatch = firstObject.match(/"id"\s*:\s*("([^"]*)")/);
	const siteMatch = firstObject.match(/"site"\s*:\s*("([^"]*)")/);
	if (!idMatch || !idMatch[1] || !siteMatch || !siteMatch[1]) {
		return error('Enhance', 'Cannot extract ID or site');
	}

	const path = 'apis.tracking.enhance';
	const categoryPath = path;

	const finalObj = { id: idMatch[2], site: siteMatch[2] };
	let counter = 0;
	stringifiedObjects.forEach(obj => {
		if (obj.indexOf('"pv_track"') === -1) {
			return;
		}
		const actionIdMatch = obj.match(/"action_id"\s*:\s*("([^"]*)")/);
		const trackIdMatch = obj.match(/"track_id"\s*:\s*("([^"]*)")/);
		if (!actionIdMatch || !trackIdMatch) {
			return;
		}
		finalObj['pv_track_' + counter] = { action_id: actionIdMatch[2], track_id: trackIdMatch[2] };
		counter++;
	});

	return standardMatch('Enhance', path, categoryPath, finalObj);

}

function checkSnapchat(code, appData) {
	if (code.indexOf('sc-static.net/scevent.min.js') === -1) {
		return false;
	}

	let snapInit = code.match(/snaptr\(\s*('|")init\1\s*,\s*('|")([0-9a-z-]+)\2/);
	if (!snapInit) {
		return error('Snapchat', 'Cannot find init id');
	}
	let id = snapInit[3];

	let path = `${basePath}.snapchat`;
	const categoryPath = path;

	let snapTrack = code.match(/snaptr\(\s*('|")track\1\s*,\s*('|")([^'"]+)\2\s*\)/);
	let trackId = (snapTrack && snapTrack[3]) || 'Page_View';

	id = concatToPath(appData, path + '.id', id);
	trackId = concatToPath(appData, path + '.trackId', trackId);
	const finalObj = { id, trackId };

	return standardMatch('Snapchat', path, categoryPath, finalObj);

}

function checkPinterest(code, appData) {
	if (code.indexOf('s.pinimg.com/ct/core.js') === -1) {
		return false;
	}

	let pinId = code.match(/pintrk\(\s*('|")load\1\s*,\s*('|")(\d+)\2(?:\s*,\s*([^)]+))?\s*\)/);
	if (!pinId) {
		return error('Pinterest', 'Cannot find init id');
	}
	let id = pinId[3];

	let path = `${basePath}.pinterest`;
	const categoryPath = path;

	id = concatToPath(appData, path + '.id', id);
	const finalObj = { id };

	return standardMatch('Pinterest', path, categoryPath, finalObj);

}

function checkTikTok(code, appData) {
	if (code.indexOf('analytics.tiktok.com') === -1) {
		return false;
	}

	let sdkid = code.match(/analytics.tiktok.com\/i18n\/pixel\/sdk.js\?sdkid=([a-z0-9_-]+)/i) || code.match(/ttq\.load\('([a-z0-9_-]+)'\)/i);

	if (!sdkid) {
		return error('TikTok', 'Cannot find sdkid');
	}
	let id = sdkid[1];

	let path = `${basePath}.tikTok`;
	const categoryPath = path;

	id = concatToPath(appData, path + '.id', id);
	const finalObj = { id };

	return standardMatch('TikTok', path, categoryPath, finalObj);
}

function checkAd2(code, appData) {
	if (code.indexOf('adlogs.ad2iction.com') === -1) {
		return false;
	}

	let dnMatch = code.match(/dn=([.a-zA-Z0-9]+)/);

	if (!dnMatch) {
		return error('Ad2', 'Cannot find dn');
	}

	let dn = dnMatch[1];

	let eventsMatch = code.match(/trackAdEvent\((.+)\)/);

	if (!eventsMatch) {
		return error('Ad2', 'No events specified');
	}
	let events = eventsMatch[1];
	events = events.split(',');
	events = events.map(event => {
		let e = event.trim();
		if (e.startsWith("'") && e.endsWith("'") || e.startsWith('"') && e.endsWith('"')) {
			e = e.substring(1, e.length - 1);
		}
		return e;
	});
	events = events.join(',');

	let path = `${basePath}.ad2`;
	const categoryPath = path;

	dn = concatToPath(appData, path + '.dn', dn);
	events = concatToPath(appData, path + '.events', events);
	const finalObj = { dn, events };

	return standardMatch('Ad2', path, categoryPath, finalObj);
}

function checkYahoo(code, appData) {
	if ((code.indexOf('s.yimg.com') === -1) && (code.indexOf('y=YAHOO') === -1)) {
		return false;
	}

	let projectIdMatch = code.match(/['|"]projectId['|"]:['|"]([0-9a-zA-Z]+)['|"]/);
	if (!projectIdMatch) {
		return error('Yahoo', 'Cannot find Project ID');
	}
	let projectId = projectIdMatch[1];

	let pixelIdMatch = code.match(/['|"]pixelId['|"]:['|"]([0-9a-zA-Z]+)['|"]/);
	if (!pixelIdMatch) {
		return error('Yahoo', 'Cannot find Pixel ID');
	}
	let pixelId = pixelIdMatch[1];

	let path = `${basePath}.yahoo`;
	const categoryPath = path;

	projectId = concatToPath(appData, path + '.projectId', projectId);
	pixelId = concatToPath(appData, path + '.pixelId', pixelId);
	const finalObj = { projectId, pixelId };

	return standardMatch('Yahoo', path, categoryPath, finalObj);
}
