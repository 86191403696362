import { h } from 'preact';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';

import { joinClasses, accessNested, debounce, commaSeparatedList, arrayUnique } from 'utils/utils';
import slugify from 'utils/slugify';
import { fetchMovieDataIfNecessary } from 'utils/fetchMovieDataIfNecessary';
import suggestions from 'constants/autoCompleteSuggestions';

import HTML from 'components/core/html/html';
import InputWithSuggestions from 'components/shared/inputWithSuggestions/inputWithSuggestions';

import s from 'components/shared/editor/formEditor/attributes/stringAttribute.sss';

export default class StringAttribute extends DisplayAttribute {

	/**
	 * LifeCycle
	**/

	constructor(props) {
		super(props);

		this.state.showDropdown = false;
		this.state.markdown = null;
		this.state.filteredSuggestions = suggestions;
		this.checkSuggestionsDebounced = debounce(this.checkSuggestions.bind(this), 150);
	}

	/**
	 * Event Handlers
	**/

	selectSuggestion = (sug) => {
		// Select the suggestion
		let value = this.state.value;
		let ending = value.slice(this.caretPosition);
		let match = ending.match(/^[^ /]+/);
		if (match && sug.endsWith(match[0])) {
			ending = ending.slice(match[0].length);
		}
		let newValue = value.slice(0, this.caretPosition - this.wordTyping.length) + sug + ending;
		this.setState({ value: newValue });
		let newPosition = this.caretPosition - this.wordTyping.length + sug.length;
		// TODO: do this in componentDidUpdate rather than setTimeout hack
		setTimeout(() => {
			if (this.$input) {
				this.$input.selectionStart = newPosition;
				this.$input.selectionEnd = newPosition;
				this.$input.focus();
			}
		}, 0);
	};

	keyUpHandler = (event) => {
		// if escape key is pressed, close the dropdown
		if (event.keyCode === 27) {
			this.setState({ showDropdown: false });
		}
	};

	blurHandler = () => {
		// Needs to be async so the clickhandler on the suggestion still gets triggered
		this.setState({ showDropdown: false });
	};


	stringUpdate = (event) => {
		// Replace dumb invisible char that are not considered as newline by JSON.stringify but are by the JS parser
		const value = event.target.value.replace(/[\u2028\u2029]/g, '');

		this.checkSuggestionsDebounced(event.target, value);

		this.setState({ value });

		// Too laggy to run on each keystroke
		// this.onSave();
	};

	updateAndSave = (event) => {
		this.stringUpdate(event);
		this.onSave();
	};

	checkSuggestions = (input, value) => {
		if (!(input instanceof HTMLTextAreaElement || input instanceof HTMLInputElement)) {
			return;
		}
		const caretPosition = input.selectionStart;
		if (value.charAt(caretPosition - 1) === ' ') {
			// Cancel dropdown when it's just a space
			this.setState({ showDropdown: false });
			return;
		}

		// Get the word that we are typing (split words by space or slash)
		const wordTyping = value.substring(0, caretPosition).split(/[ /]/).pop();
		this.wordTyping = wordTyping;
		this.caretPosition = caretPosition;

		// Only show dropdown if it starts with a % and doesn't end with one
		if (wordTyping.charAt(0) === '%' && (wordTyping.length === 1 || wordTyping.slice(-1) !== '%')) {
			// Filter all the words
			const filtered = suggestions.filter(sug => sug.toLowerCase().includes(wordTyping.replace(/%/g, '').toLowerCase()));
			// show the dropdown
			this.setState({
				showDropdown: true,
				filteredSuggestions: filtered
			});

		} else {
			this.setState({ showDropdown: false });
		}
	};

	copyToClipboard = () => {
		if (!this.$source) return;
		try {
			this.$source.select();
			document.execCommand('copy');
		} catch (e) {}
	};

	onSelectChange(evt, schema) {
		let possibilities = schema.options?.possibilities || [];
		let selectedPossibility = possibilities.find(x => x.value === evt.target.value);
		let help = selectedPossibility?.help;
		this.setState({ help });

		this.updateAndSave(evt);
	}

	getExtraContent(sentValue, description, jsonSchema) {
		let schema = this.getSchema(jsonSchema);
		let helpElements = [schema.help, this.state.help].filter(Boolean);
		if (helpElements.length) {
			return <HTML content={helpElements.join('\n\n')} markdown />;
		}
		const { movieIdInfo, value } = this.props;

		if (schema.showCTAButtonTextToolTip && !this.props?.parent?.textAboveButtons) {
			return <p>Please set the text CTA for prefacing the button options</p>;
		}

		if (schema.showSlugifiedTitle && !this.props?.parent?.querySlug) {
			const sluggedTitle = slugify(this.props?.parent?.title, { replaceAccentedChars: true });
			return <p><strong>Auto Slug: </strong>{sluggedTitle}</p>;
		}

		if (schema.expandMovieID) {
			// Fetch any missing ids
			// Not great to do it from render but it only fetches ids that were never seen before so probably ok
			fetchMovieDataIfNecessary(value);

			const idsRaw = commaSeparatedList(value);
			const ids = arrayUnique(idsRaw);
			const result = ids.map(id => {
				const info = movieIdInfo[id];
				let content;
				if (!info) {
					content = <span class={s.loading}>Waiting</span>;
				} else if (info === 'loading') {
					content = <span class={s.loading}>Loading<span class={s.dot} /><span class={s.dot} /><span class={s.dot} /></span>;
				} else if (info === 'error' || !info?.movieSlug) {
					content = <span class={s.error}>Error</span>;
				} else {
					let slug = info.movieSlug;
					let c = info.numScreenings;
					content = (
						<span class={s.content}>
							<span class={s.slug}>{slug}</span> (<span class={s.count}>{c.toLocaleString()}</span> screening{c !== 1 ? 's' : ''})
						</span>
					);
				}
				return (
					<div class={s.movieIdInfo} key={id}>
						<span class={s.id}>{id}</span>: {content}
					</div>
				);
			});
			if (idsRaw.length !== ids.length) {
				result.push(<div class={s.warning} key="dupe-warning">Warning: duplicate IDs found</div>);
			}
			return result;
		}

		return super.getExtraContent(sentValue, description, jsonSchema);
	}

	getSchema = (jsonSchema) => {
		const attrName = this.props.attributeName;
		let schema = jsonSchema;
		const inheritedSuggestions = this.props.activePath.split('.').filter(e => e).reduce((acc, p, i, arr) => {
			let path = arr.slice(0, -i).join('.properties.');
			const item = accessNested(this.props.baseJSONSchema.properties, path, '');
			if (!item || !item.$children || !item.$children.properties) {
				return acc;
			}

			return item.$children.properties.reduce((acc, k) => {
				Object.keys(k).forEach(key => {
					acc.keys.push(key);
					acc.keyPaths[key] = path;
				});
				return acc;
			}, acc);
		}, {
			keys: [],
			keyPaths: {}
		});

		let keyPath = inheritedSuggestions.keyPaths[attrName];
		const inheritedProps = accessNested(this.props.baseJSONSchema, `properties.${keyPath}.$children.properties`) || [];
		let inheritedKey;

		inheritedProps.forEach(p => {
			if (p && Object.prototype.hasOwnProperty.call(p, attrName)) {
				inheritedKey = p[attrName];
			}
		});

		schema = inheritedKey || schema || {};

		if (schema.$ref) {
			schema = Object.assign({}, this.props.baseJSONSchema.definitions[schema.$ref.split('/').pop()]);
		}
		return schema;
	};

	getInput = (sentValue, description, jsonSchema) => {
		let value = this.state.value + '';

		let schema = this.getSchema(jsonSchema);

		if (schema.options?.possibilities) {
			if (schema.options.allowCustom) {
				return (
					<div class={s.wrapper}>
						<InputWithSuggestions
							classes={{ wrapper: s.withSuggestions, input: s.input, suggestions: s.suggestions }}
							value={value}
							onInput={this.stringUpdate}
							onChange={(v, e) => this.onSave(e, v)}
							onKeyDown={this.inputKeyDown}
							list={schema.options.possibilities}
							showAllWhenEmpty
							ref={$ref => this.$source = $ref}
						/>
					</div>
				);
			}
			return (
				<div class={s.wrapper}>
					<select class={s.select} value={value} onChange={evt => this.onSelectChange(evt, schema)}>
						{schema.options.possibilities.map((possibility) => {
							const extraProps = {};
							if (possibility === value) {
								extraProps.selected = 'selected';
							}

							if (typeof possibility === 'object') {
								const { title = '', value = '' } = possibility;
								return <option value={value} {...extraProps}>{title}</option>;
							} else if (typeof possibility === 'string') {
								return <option value={possibility} {...extraProps}>{possibility}</option>;
							}
						})}
					</select>
				</div>
			);
		}

		let isBig = value.length > 44 || value.includes('\n');
		let inputClass = joinClasses(s.textarea, isBig && s.big);

		let source;
		if (value.match(/^\$[A-Z_]+$/)) {
			let copyPath = ['copy', value];

			const isBolt = accessNested(this.props.activeValueFull, 'dev.defaultBase') === 'bolt';
			if (isBolt) {
				let baseCopyPath = ['copy', 'baseCopy', value];
				let accessibilityCopyPath = ['copy', 'accessibilityCopy', value];
				copyPath = accessNested(this.props.activeValueFull, baseCopyPath) ? baseCopyPath : accessNested(this.props.activeValueFull, accessibilityCopyPath) ? accessibilityCopyPath : [];
			}

			let sourceVal = accessNested(this.props.activeValueFull, copyPath);
			if (sourceVal) {
				source = (
					<div class={s.source} key="source">
						<button class={s.viewSourceIcon} />
						<div class={s.sourceContent} tabindex="-1">
							<textarea class={joinClasses(s.sourceValue, sourceVal.length > 44 && s.big)} readonly ref={e => this.$source = e}>{sourceVal}</textarea>
							<button class={s.copy} onClick={this.copyToClipboard} />
							<button class={s.goto} onClick={() => this.props.setActivePath(copyPath.join('.') + '.')} />
						</div>
					</div>
				);
			}
		}

		// Show a dropdown when there are suggestions that could help the user
		if (this.state.showDropdown && this.state.filteredSuggestions.length > 0) {
			source = (
				<ul class={s.showDropdown} key="dropdown">
					{this.state.filteredSuggestions.map(sug => <li onMouseDown={this.selectSuggestion.bind(this, sug)}>{sug}</li>)}
				</ul>
			);
		}

		let placeholder = schema.placeholder || description;
		return (
			<div class={s.wrapper}>
				<textarea
					ref={$ref => this.$input = $ref}
					class={inputClass}
					value={value}
					onInput={this.stringUpdate}
					onChange={this.onSave}
					placeholder={placeholder}
					key="input"
					onKeyUp={this.keyUpHandler}
					onBlur={this.blurHandler}
					disabled={schema.disabled}
				/>
				{source}
			</div>
		);
	};
}
