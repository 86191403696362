import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import pure from 'utils/pure';
import s from 'components/shared/modal/modal.sss';
import { joinClasses } from 'utils/utils';

export default @pure class Modal extends Component {

	closeModal = () => {
		if (!this.props.blockClose) {
			Store.emit(actions.HIDE_MODAL);
		}
	}

	renderCloseButton() {}

	render(props) {
		let {
			active, // boolean - whether the modal is currently shown
			title, // string - the modal title
			blockClose, // boolean - whether the modal can be closed or not
			type, // the type of modal (used for custom styling)
			content: Content, // JSX | function | string - the modal content
			backdropColor, // ?string (CSS color) - the color of the backdrop. Using an alpha value (i.e. rgba, hsla) is recommended
			fadeIn, // ?number - duration of the modal fade in if boolean true, will use the default 500ms duration
			...extraProps
		} = props;

		if (!active) {
			return;
		}

		if (typeof Content === 'string') {
			// If the content is a string instead of a component,
			// just go for a simplistic render
			Content = <div class={s.simpleContent}>{Content}</div>;
		}

		if (typeof Content !== 'function') {
			let _content = Content;
			Content = () => _content;
		}

		let backgroundStyle = {};
		if (backdropColor) {
			backgroundStyle.background = backdropColor;
		}

		let containerStyle = {};
		let useFadeIn = fadeIn === true;
		if (typeof fadeIn === 'number' && fadeIn > 0) {
			useFadeIn = true;
			containerStyle.animationDuration = fadeIn + 'ms';
		}

		return (
			<div class={joinClasses(s.container, useFadeIn && s.fadeIn)} style={containerStyle}>
				<div onClick={this.closeModal} class={s.background} style={backgroundStyle} />
				{this.renderCloseButton()}
				<div class={joinClasses(s.modal, s[type])}>
					{title && <h3 class={s.title}>{title}</h3>}
					<div class={s.content}>
						<Content {...extraProps} />
					</div>
				</div>
			</div>
		);
	}

}
