import { h, Component } from 'preact';
import FormField from 'components/shared/formGenerator/formField';
import { joinClasses } from 'utils/utils';

import s from 'components/shared/formGenerator/formGenerator.sss';

export default class FormGenerator extends Component {

	constructor(props) {
		super(props);

		this.finish = this.finish.bind(this);
	}

	finish() {
		this.props.nextForm(this.getFormData());
		if (typeof this.props.activateWebedia === 'function') {
			this.props.activateWebedia();
		}
	}

	getFormData() {
		const data = this.props.formData.fields.flatMap((fields, key) => {
			let data = Array.isArray(fields) ? fields : fields?.data;
			return data?.map(field => {
				const ref = this[`$input-${key}_${field.title}`];
				if (ref) {
					field.value = ref.getValue();
					return field;
				}
			});
		}).filter(e => e);
		return data;
	}

	renderField(field, index, className) {
		if (!field) {
			return;
		}
		return (
			<FormField
				class={className}
				ref={ref => this[`$input-${index}_${field.title}`] = ref}
				fieldData={field}
				value={field.value}
				onUpdate={this.props.updateData}
				key={field.title}
			/>
		);
	}

	renderButtons(list) {
		return list?.map(({ text, title, disabled, onClick }) => (
			<button class={s.button} type="button" title={title} disabled={disabled} onClick={onClick}>{text}</button>
		));
	}

	render() {
		const fields = (this.props.formData?.fields || []).map((fieldGroup, index) => {
			if (!fieldGroup) {
				return;
			}

			let { group, groupPath, settings, data, buttons, floatingButtons } = fieldGroup;
			if (!data?.length) {
				return;
			}

			const key = fieldGroup.key || groupPath || group || index;

			return (
				<div class={joinClasses(s.card, settings && s.settings)} key={key}>
					{group && <div class={s.group}>{group}</div>}
					{/* {groupPath && <div class={joinClasses(s.path, s.groupPath)}>{groupPath}</div>} */}
					<div class={s.content}>
						{data.map(field => this.renderField(field, index))}
					</div>
					<div class={s.buttons}>{this.renderButtons(buttons)}</div>
					<div class={joinClasses(s.buttons, s.floating)}>{this.renderButtons(floatingButtons)}</div>
				</div>
			);
		}).filter(e => e);

		return (
			<div class={joinClasses(s.formWrapper, this.props.class)}>
				<div class={s.scroller}>
					{fields}
				</div>
			</div>
		);
	}

}
