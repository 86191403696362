import { h, Component } from 'preact';
import { arrayUnique, joinClasses } from 'utils/utils';
import { getAppData, getRegionData } from 'services/dataApi';
import { marked } from 'marked';
import Store from 'store/store';
import * as actions from 'store/actions';

import s from 'components/shared/modal/launchEmailGenerator.sss';
import editorStyle from 'components/shared/editor/formEditor/formEditor.sss';
import stringStyle from 'components/shared/editor/formEditor/attributes/stringAttribute.sss';

export default class LaunchEmailGenerator extends Component {
	constructor(props) {
		super(props);
		const { appData } = props;
		this.LOCAL_STORAGE_SIGN_OFF_KEY = 'thundrEmailGenSignOff';
		this.LOCAL_STORAGE_NAME_KEY = 'thundrEmailGenName';
		this.LOCAL_STORAGE_TITLE_KEY = 'thundrEmailGenTitle';
		this.onGenerateEmail = this.onGenerateEmail.bind(this);
		this.assetRoot = 'https://dx35vtwkllhj9.cloudfront.net/powster/live-email';
		this.titleImageRoot = 'https://dx35vtwkllhj9.cloudfront.net/' + (appData?.meta?.studio?.slug || '') + '/' + (appData?.meta?.title?.slug || '') + '/images';
		this.titleImageRegionRoot = this.titleImageRoot + '/regions/' + (appData?.meta?.region?.slug || '');
		this.movieTitle = appData?.meta?.title?.en || '';
		this.siteLanguage = appData?.meta?.region?.language || 'en';
		this.siteUrl = appData?.meta?.url || '';
		this.baseCopy = appData?.copy?.baseCopy || {};
		this.extraVanities = this.getExtraVanities(appData?.meta?.vanities, this.siteUrl);
		this.toolkitPage = appData?.pages?.['bolt-toolkit'];
		this.toolkitPath = this.toolkitPage?.path || '/';
		this.clientGADashboard = appData?.pages?.['bolt-toolkit']?.data?.GADashboard;
		this.newsletterSubscribe = 'https://www.powster.com/?newsletter=visible';
		this.facebook = 'https://www.facebook.com/Powster';
		this.instagram = 'https://www.instagram.com/powster';
		this.twitter = 'https://twitter.com/powster';
		this.defaultMultiLanguage = appData?.dev?.ga4?.multiRegion;
		this.state = {
			multiLanguageEnabled: this.defaultMultiLanguage,
			headerImage: '%IMAGE_REGION_ROOT%/responsive.png',
			headerCopy: '$LIVE_EMAIL_HEADER_COPY',
			toolkitEnabled: true,
			gaEnabled: true,
			notes: this.baseCopy.$LIVE_EMAIL_NOTES ? '$LIVE_EMAIL_NOTES' : '',
			signOff: 'Many thanks!',
			name: '',
			title: '',
			generating: false,
			htmlCopied: false,
		};
	}

	componentDidMount() {
		this.prefillSomeFields();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.htmlCopied && !prevState.htmlCopied) {
			setTimeout(() => {
				this.closeModal();
			}, 800);
		}
	}

	async setUpMultiLanguage() {
		const { appData, titleRegions } = this.props;
		this.mainRegion = appData?.meta?.region?.slug;
		this.allRegions = await Promise.all(
			titleRegions
				.filter(region => region.slug !== this.mainRegion && region.slug !== 'ALL' && region.slug.match(/^[a-z]+[^_]/)[0] === this.mainRegion.match(/^[a-z]+[^_]/)[0])
				.map(async (region) => {
					const appData = await getAppData(region.app);
					const regionData = await getRegionData(region.id);
					const siteUrl = appData?.attributes?.meta?.url || '';
					const extraVanities = this.getExtraVanities(appData?.attributes?.meta?.vanities, siteUrl);
					const language = regionData?.attributes?.meta?.region?.language || 'en';
					const toolkitPath = appData?.attributes?.pages?.['bolt-toolkit']?.path || '/toolkit/';
					const isHub = !!(appData?.attributes?.pages?.boltSplashHub);
					return { region: region.slug, siteUrl, extraVanities, language, toolkitPath, isHub };
				})
		);
		this.allRegions.unshift({
			region: this.mainRegion,
			siteUrl: this.siteUrl,
			extraVanities: this.extraVanities,
			language: this.siteLanguage,
			toolkitPath: this.toolkitPath,
			isHub: !!this.props.appData?.pages?.boltSplashHub,
		});
		this.allRegions.sort((a, b) => {
			if (a.isHub) return -1;
			if (b.isHub) return 1;
			return 0;
		});
	}

	closeModal() {
		Store.emit(actions.HIDE_MODAL);
	}

	prefillSomeFields() {
		const signOff = localStorage.getItem(this.LOCAL_STORAGE_SIGN_OFF_KEY);
		const name = localStorage.getItem(this.LOCAL_STORAGE_NAME_KEY);
		const title = localStorage.getItem(this.LOCAL_STORAGE_TITLE_KEY);
		if (signOff) {
			this.setState({ signOff });
		}
		if (name) {
			this.setState({ name });
		}
		if (title) {
			this.setState({ title });
		}

		if (!this.toolkitPage) {
			this.setState({
				toolkitEnabled: false,
				noToolkit: true
			});
		}

		if (this.props.appData?.tracking?.variables?.disablePowsterGA) {
			this.setState({
				gaEnabled: false,
				powGADisabled: true
			});
		}
	}

	getCleanDomain(link) {
		return link.trim().replace(/^((https?:)?\/\/)?(www\.)?|\/$/gi, '');
	}

	stripHttp(link) {
		return link.replace(/^(https?:)?\/\//i, '');
	}

	getExtraVanities(vanities, siteUrl) {
		if (!vanities) return [];
		const domainsWithWWW = new Set();
		const cleanVanities = vanities.map(vanity => {
			const clean = this.getCleanDomain(vanity);
			if (/^((https?:)?\/\/)?www\./i.test(vanity)) {
				domainsWithWWW.add(clean);
			}
			return clean;
		});
		const cleanMainURL = this.getCleanDomain(siteUrl);
		return arrayUnique(cleanVanities).filter(v => v !== cleanMainURL).map(v => `https://${domainsWithWWW.has(v) ? 'www.' : ''}${v}`);
	}

	getHeaderImage() {
		const headerImage = this.baseCopy[this.state.headerImage] || this.state.headerImage;
		return headerImage
			.replace(/%IMAGE_REGION_ROOT%/, this.titleImageRegionRoot)
			.replace(/%IMAGE_ROOT%/, this.titleImageRoot);
	}

	getHeaderCopy() {
		let copy = this.baseCopy[this.state.headerCopy] || this.state.headerCopy;
		if (this.state.multiLanguageEnabled) {
			copy = copy.replace(/\ssite\s/, ' sites ').replace(/\sis\s/, ' are ');
		}
		return copy.replace(/%MOVIE_NAME%/, `<span style="font-weight: bold">${this.movieTitle}</span>`);
	}

	saveSignOffDetails() {
		const { signOff, name, title } = this.state;
		localStorage.setItem(this.LOCAL_STORAGE_SIGN_OFF_KEY, signOff);
		localStorage.setItem(this.LOCAL_STORAGE_NAME_KEY, name);
		localStorage.setItem(this.LOCAL_STORAGE_TITLE_KEY, title);
	}

	createLiveLink(link, isMainLink) {
		return `
			<tr>
				<td align="center" ${isMainLink ? 'style="padding-bottom: 16px;"' : ''}>
					<a href="${link}" style="color: #F66163; text-decoration: none; font-size: ${isMainLink ? '26px' : '20px'}; line-height: ${isMainLink ? '44px' : '24px'}; overflow-wrap: anywhere;">${this.stripHttp(link)}</a>
				</td>
			</tr>
		`;
	}

	async onGenerateEmail() {
		const { toolkitEnabled, gaEnabled, notes, signOff, name, title, generating, multiLanguageEnabled } = this.state;
		if (generating) return;
		if (multiLanguageEnabled) {
			this.setState({ generating: true });
			await this.setUpMultiLanguage();
		}
		this.saveSignOffDetails();
		const tableStyle = 'border="0" cellpadding="0" cellspacing="0" style="padding: 0; margin: 0 auto; width: 100%; font-family: Proxima Nova, sans-serif;';
		const headerImage = this.getHeaderImage();
		const headerCopy = this.getHeaderCopy();
		const headerCode = `
				<tr>
					<td align="center" valign="top">
						<table ${tableStyle} background-image: url('${this.assetRoot}/backdrop.png'); background-size: cover;">
							<tr>
								<td align="left" style="padding: 28px;">
									<img src="${this.assetRoot}/powster-logo.png" style="display: block; width: 35%; max-width: 255px;" />
								</td>
							</tr>
							<tr>
								<td align="center" style="padding: 0">
									<img src="${headerImage}" style="width: 95%;" />
								</td>
							</tr>
							<tr>
								<td class="darkModeWhiteText" align="center" style="padding: 24px 48px 44px; color: #fff; font-size: 24px;">
									${headerCopy}
								</td>
							</tr>
						</table>
					</td>
				</tr>
		`;

		let liveLinks = `
			<tr>
				<td align="center" valign="top">
					<table ${tableStyle} margin-bottom: 52px;">
						<tr>
							<td class="darkModeWhiteText" align="center" style="padding-top: 80px; font-size: 19px; line-height: 36px">Your main live link is:</td>
						</tr>
						${this.createLiveLink(this.siteUrl, true)}
						${this.extraVanities.map((vanity) => this.createLiveLink(vanity)).join('\n')}
					</table>
				</td>
			</tr>
		`;

		const toolkitLink = this.siteUrl + this.toolkitPath;

		let toolkit = `
			<tr>
				<td align="center" valign="top">
					<table ${tableStyle} margin-bottom: 64px;">
						<tr>
							<td class="darkModeWhiteText" align="center" style="font-size: 18px; line-height: 36px; padding: 0 32px 48px;">
								Use your toolkit to create
								<a class="darkModeWhiteText" href="${toolkitLink}#campaign" style="color: #000; text-decoration: underline;">tagged links</a>,
								improve your
								<a class="darkModeWhiteText" href="${toolkitLink}#seo" style="color: #000; text-decoration: underline;">SEO</a>,
								${this.clientGADashboard ? ` view your <a class="darkModeWhiteText" href="${this.clientGADashboard}" style="color: #000; text-decoration: underline;">analytics dashboard</a> ` : ' '}
								and enhance all stages of your campaign.
							</td>
						</tr>
						<tr>
							<td align="center">
								<a class="darkModeWhiteText" href="${toolkitLink}" style="color: #FFF; text-decoration: none; background: #F66163; width: 200px; height: 48px; border-radius: 40px; display: block; line-height: 48px; font-size: 16px;">
									Toolkit
								</a>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		`;

		if (multiLanguageEnabled) {
			let multiLangLinks = '';
			this.allRegions.forEach((region, i) => {
				const isFirstRegion = i === 0;
				multiLangLinks += `
					<tr>
						<td align="center" valign="top" width="104px" style="padding-left: 16px; padding-top: ${isFirstRegion ? '0px' : '16px'};">
							<div style="border: 1px solid #F66163; border-radius: 40px; background: transparent; width: 104px; height: 36px;">
								<p style="line-height: 36px; font-size: 16px; color: #F66163; margin: 0;">${region.language.toUpperCase()}</p>
							</div>
						</td>
						<td align="left" valign="middle" style="padding-left: 16px; padding-right: 16px; padding-top: ${isFirstRegion ? '0px' : '16px'};">
							<a href="${region.siteUrl}" style="color: #F66163; text-decoration: none; font-size: 20px; font-weight: 700; overflow-wrap: anywhere;">${this.stripHttp(region.siteUrl)}</a>
						</td>
					</tr>
				`;
				if (region.extraVanities.length > 0) {
					region.extraVanities.forEach((vanity) => {
						multiLangLinks += `
							<tr>
								<td></td>
								<td align="left" valign="middle" style="padding-left: 16px; padding-right: 16px; padding-top: 16px;">
									<a href="${vanity}" style="color: #F66163; text-decoration: none; font-size: 16px; font-weight: 400; overflow-wrap: anywhere;">${this.stripHttp(vanity)}</a>
								</td>
							</tr>
						`;
					});
				}
			});
			liveLinks = `
				<tr>
					<td align="center" valign="top">
						<table ${tableStyle} margin-bottom: 16px;">
							<tr>
								<td class="darkModeWhiteText" align="center" style="padding-top: 80px; font-size: 19px; line-height: 36px">Your live links are:</td>
							</tr>
						</table>
						<table ${tableStyle} margin-bottom: 52px; width: auto; max-width: 100%;">
							${multiLangLinks}
						</table>
					</td>
				</tr>
			`;
			let toolkitButtons = '';
			this.allRegions.forEach((region, i) => {
				const isFirstRegion = i === 0;
				toolkitButtons += `
						<tr>
							<td align="center" ${isFirstRegion ? '' : 'style="padding-top: 16px;"'}>
								<a class="darkModeWhiteText" href="${region.siteUrl + region.toolkitPath}" style="color: #FFF; text-decoration: none; background: #F66163; width: 200px; height: 48px; border-radius: 40px; display: block; line-height: 48px; font-size: 16px;">
									${region.language.toUpperCase()} Toolkit
								</a>
							</td>
						</tr>
				`;
			});
			toolkit = `
				<tr>
					<td align="center" valign="top">
						<table ${tableStyle} margin-bottom: 64px;">
							<tr>
								<td class="darkModeWhiteText" align="center" style="font-size: 18px; line-height: 36px; padding: 0 32px 48px;">
									Use your toolkits to create tagged links, improve your SEO,
									${this.clientGADashboard ? ` view your <a class="darkModeWhiteText" href="${this.clientGADashboard}" style="color: #000; text-decoration: underline;">analytics dashboard</a> ` : ' '}
									and enhance all stages of your campaign.
								</td>
							</tr>
							${toolkitButtons}
						</table>
					</td>
				</tr>
			`;
		}

		const ga = `
			<tr>
				<td align="center" valign="top">
					<table ${tableStyle} margin-bottom: 80px; padding: 0 20px;">
						<tr>
							<td class="darkModeWhiteText" align="center" style="font-size: 14px; line-height: 26px;">
								You have also been granted access to your
								<a class="darkModeWhiteText" href="https://analytics.google.com" style="color: #000; text-decoration: underline;">Google Analytics 4</a>
								Account and Dashboard. FAQs and tips are available in the toolkit if needed.
							</td>
						</tr>
					</table>
				</td>
			</tr>
		`;

		const notesSection = `
			<tr>
				<td align="center" valign="top">
					<table ${tableStyle} background: rgba(52, 220, 134, 0.25); width: 95%; max-width: 750px; margin: 32px auto; padding: 32px;">
						<tr>
							<td class="darkModeWhiteText" align="center" style="font-weight: bold; font-size: 26px; line-height: 36px; padding-bottom: 15px;">
								Notes
							</td>
						</tr>
						<tr>
							<td class="darkModeWhiteText" align="left" style="font-size: 16px; line-height: 36px;">
								${marked(this.baseCopy[notes] || notes)}
							</td>
						</tr>
					</table>
				</td>
			</tr>
		`;

		const signOffSection = `
			<tr>
				<td class="darkModeWhiteText" align="center" valign="top">
					<table ${tableStyle} font-size: 16px; line-height: 36px; width: 95%; max-width: 750px; margin-bottom: 56px;">
						<tr>
							<td align="left" style="padding-bottom: 48px;">
								${marked.parseInline(this.baseCopy[signOff] || signOff)}
							</td>
						</tr>
						<tr>
							<td align="left" style="font-weight: bold;">
								${marked.parseInline(this.baseCopy[name] || name)}
							</td>
						</tr>
						<tr>
							<td align="left">
								${marked.parseInline(this.baseCopy[title] || title)}
							</td>
						</tr>
					</table>
				</td>
			</tr>
		`;

		const footer = `
			<tr>
				<td align="center" valign="top">
					<table class="powFooter" ${tableStyle} background: #000;">
						<tr>
							<td colspan="2" style="width: 100%; padding: 56px 24px;">
								<img src="${this.assetRoot}/newsletter.png" style="opacity: 0.6; width: 48px; margin-right: 20px;" />
								<div style="color: rgba(255, 255, 255, 0.6); font-size: 18px; line-height: 28px; display: inline-block; vertical-align: bottom;">
									<p style="margin: 0;">Product, creative & tech news</p>
									<p style="margin: 0;">direct to your inbox. <a href="${this.newsletterSubscribe}" style="text-decoration: underline; color: #fff;">Subscribe</a></p>
								</div>
							</td>
						</tr>
						<tr>
							<td align="left" style="padding-left: 24px;">
								<img src="${this.assetRoot}/powster-logo.png" style="width: 100px;" />
							</td>
							<td align="right" style="padding-right: 24px;">
								<a href="${this.facebook}" style="margin-right: 28px; display: inline-block;"><img src="${this.assetRoot}/facebook.png" style="opacity: 0.6; width: 20px; display: block;" /></a>
								<a href="${this.instagram}" style="margin-right: 28px; display: inline-block;"><img src="${this.assetRoot}/instagram.png" style="opacity: 0.6; width: 20px; display: block;" /></a>
								<a href="${this.twitter}" style="display: inline-block;"><img src="${this.assetRoot}/twitter.png" style="opacity: 0.6; width: 17px; display: block; margin-bottom: 2px;" /></a>
							</td>
						</tr>
						<tr>
							<td colspan="2" align="center" style="width: 100%; padding: 40px 24px 14px">
								<table align="center" valign="top" ${tableStyle}">
									<tr>
										<td>
											<div style="height: 1px; background-color: #34dc86;" />
										</td>
										<td>
											<div style="height: 1px; background-color: #f66163;" />
										</td>
										<td>
											<div style="height: 1px; background-color: #8256e8;" />
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td colspan="2" align="right" style="padding: 0 24px 78px; font-size: 14px;">
								<p style="color: rgba(255, 255, 255, 0.6); margin: 0;">© Powster ${new Date().getFullYear()}</p>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		`;

		let htmlCode = `
			<html>
				<head>
					<meta name="viewport" content="width=device-width" />
					<meta name="color-scheme" content="light dark">
					<meta name="supported-color-schemes" content="light dark only">
					<style type="text/css">
						:root {
							color-scheme: light dark;
							supported-color-schemes: light dark;
						}
						body {
							background-color: #fff;
						}
						@media (prefers-color-scheme: dark) {
							body {
								background-color: #000;
							}
							.darkModeWhiteText {
								color: #fff !important;
							}
							.powFooter {
								background-color: #000 !important;
							}
						}
					</style>
				</head>
				<body style="width: 100%; margin: 0; padding: 0;">
					<table ${tableStyle}" id="powBodyTable">
						<tr>
							<td align="center" valign="top">
								<table ${tableStyle} width: 100%; max-width: 800px;" id="powEmailContainer">
									${headerCode}
									${liveLinks}
									${toolkitEnabled ? toolkit : ''}
									${gaEnabled ? ga : ''}
									${notes ? notesSection : ''}
									${signOffSection}
									${footer}
								</table>
							</td>
						</tr>
					</table>
				</body>
			</html>
		`;

		const data = [
			new ClipboardItem({
				'text/html': new Blob([htmlCode], { type: 'text/html' }),
				'text/plain': new Blob([htmlCode], { type: 'text/plain' })
			})
		];
		navigator.clipboard.write(data);
		this.setState({ htmlCopied: true, generating: false });
	}

	onToggleChange(e, toggleName) {
		this.setState({ [`${toggleName}Enabled`]: e.target.checked });
	}

	onTextAreaChange(e, textAreaName) {
		this.setState({ [textAreaName]: e.target.value });
	}

	renderTextInput({ label, value, textAreaName }) {
		const sourceVal = this.baseCopy[value];
		let source;

		if (sourceVal) {
			const copyPath = ['copy', 'baseCopy', value];
			source = (
				<div class={joinClasses(stringStyle.source, s.source)} key="source">
					<button class={stringStyle.viewSourceIcon} />
					<div class={stringStyle.sourceContent} tabindex="-1">
						<textarea class={joinClasses(stringStyle.sourceValue, sourceVal.length > 44 && stringStyle.big)} readonly ref={e => this.$source = e}>{sourceVal}</textarea>
						<button class={stringStyle.goto} onClick={() => window.setActivePath(copyPath.join('.') + '.')} />
					</div>
				</div>
			);
		}

		return (
			<div class={s.field}>
				<label class={s.label}>{label}</label>
				<textarea class={joinClasses(editorStyle.textarea, editorStyle.input, s.textarea)} value={value} onChange={(e) => this.onTextAreaChange(e, textAreaName)} />
				{source}
			</div>
		);
	}

	renderToggle({ label, checked, toggleName }) {
		return (
			<div class={s.field}>
				<span>{label}</span>
				<label class={joinClasses(editorStyle.checkbox, s.checkbox)}>
					<input class={editorStyle.input} type="checkbox" checked={checked} onChange={(e) => this.onToggleChange(e, toggleName)} />
					<div class={editorStyle.styledInput}>
						<div class={editorStyle.slider} />
					</div>
				</label>
			</div>
		);
	}

	render() {
		const {
			multiLanguageEnabled,
			headerImage,
			headerCopy,
			toolkitEnabled,
			noToolkit,
			gaEnabled,
			notes,
			signOff,
			name,
			title,
			powGADisabled,
			htmlCopied,
			generating
		} = this.state;

		return (
			<div class={s.container}>
				<button class={s.closeButton} onClick={this.closeModal} />
				{this.renderToggle({ label: 'Multi-Region Email', checked: multiLanguageEnabled, toggleName: 'multiLanguage' })}
				{this.renderTextInput({ label: 'Header image', value: headerImage, textAreaName: 'headerImage' })}
				{this.renderTextInput({ label: 'Header copy', value: headerCopy, textAreaName: 'headerCopy' })}
				{noToolkit ? <div class={s.noToolkit}>No toolkit page found for this app.</div>
					: this.renderToggle({ label: 'Show toolkit info', checked: toolkitEnabled, toggleName: 'toolkit' })
				}
				{powGADisabled ? <div class={s.gaDisabled}>Powster GA tracking is disabled on this app.</div>
					: this.renderToggle({ label: 'Show GA info', checked: gaEnabled, toggleName: 'ga' })}
				{this.renderTextInput({ label: 'Notes', value: notes, textAreaName: 'notes' })}
				{this.renderTextInput({ label: 'Sign off', value: signOff, textAreaName: 'signOff' })}
				{this.renderTextInput({ label: 'Producer name', value: name, textAreaName: 'name' })}
				{this.renderTextInput({ label: 'Producer title', value: title, textAreaName: 'title' })}
				<button class={joinClasses(s.copyEmail, generating && s.generating)} onClick={this.onGenerateEmail}>
					{htmlCopied ? 'Copied!' : 'Copy as HTML'}
					{generating && <div class={s.spinner} />}
				</button>
			</div>
		);

	}
}