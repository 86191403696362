module.exports = {
	ENV: 'production',
	PORT: 4010,
	REDIS_HOST: 'stdatacache.nunpvq.0001.euw1.cache.amazonaws.com',
	REDIS_PORT: 6379,
	STATIC_ROOT: 'https://dx35vtwkllhj9.cloudfront.net',
	GROUPR_API: 'https://groupr.powster.com',
	API_BASE: 'https://stdata.powster.com',
	TICKETING_API: 'https://ticketing.powster.com',
	AUTH_API: 'https://auth.powster.com',
	COLLECTOR_HOST: '//data.powster.com',
};
