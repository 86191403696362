import { h } from 'preact';

const Mail = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="img" {...props}>
			<path d="M14 3H2a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1zm-1.519 1L8 7.671 3.519 4h8.962zM14 12H2V4l5.341 4.424a1 1 0 001.318 0L14 4v8z" />
		</svg>
	);
};

export default Mail;
