import { h } from 'preact';
import s from './buildSockets.sss';
import Store from 'store/store';
import * as actions from 'store/actions';
import socketActions from 'constants/socketActions';
import { getMergedAppData, attachDummyTitle, attachDemoRegionSelectorData } from 'utils/appDataUtils';
import { accessNested } from 'utils/utils';

export const initSockets = (guid) => {
	if (!window.socket) return;
	const socket = window.socket;

	socket.emit('initialConnect', guid);

	socket.on(socketActions.START_APP_BUILD, startedAppBuild);
	socket.on(socketActions.APP_BUILD_PROGRESS, appBuildProgress);
	socket.on(socketActions.FINISHED_APP_BUILD, finishedAppBuild);
	socket.on(socketActions.ERROR_APP_DATA, errorAppData);
	socket.on(socketActions.ERROR_APP_BUILD, errorAppBuild);

	socket.on('request_restart', (data) => Store.emit('REQUEST_RESTART', data));

	socket.io.on('reconnect', () => {
		// The server probably just restarted so make sure the "restart thundr" warning is not shown if not necessary
		fetch('/misc/server_start').then(r => r.text()).then(data => +data && (window.__SERVER_START__ = +data));
		socket.emit('initialConnect', guid);
		Store.emit(actions.CLEAR_MY_APPS);
		Store.emit(actions.HIDE_MODAL);
	});

	let isLocal = location.hostname !== 'thundr.powster.com';
	let modalShown = false;
	socket.on('disconnect', (reason) => {
		console.log('DISCONNECT', reason);
		// Don't show the modal on thundr.powster.com since you can't build anyway
		if (modalShown || !isLocal) return;
		modalShown = true;

		let modalContent = (
			<div class={s.disconnectedModal}>
				The connection to the server was closed (reason: <code>{reason}</code>).<br />
				This means you will not be able to build apps until you reload the page.
				<div class={s.buttons}>
					<div class={s.button} onClick={() => Store.emit(actions.HIDE_MODAL)}>I'm just editing apps</div>
					{/* <div class={s.button} onClick={() => socket.connect()}>Try to reconnect</div> */}
					<div class={s.button} onClick={() => document.location.reload()}>Reload the page</div>
				</div>
			</div>
		);

		Store.emit(actions.SHOW_MODAL, modalContent, 'Socket error');
	});
};

function startedAppBuild(data) {
	let myApp = {};
	let basesList = data.bases || [];
	let bases = basesList.reduce((bases, base) => {
		bases[base] = {
			name: base,
			progress: 0,
			messages: ''
		};
		return bases;
	}, {});
	myApp.status = {
		build: {
			startedAt: data.time || Date.now(),
			error: null, errorAt: 0,
			bases: bases
		}
	};
	Store.emit(actions.UPDATE_MY_APP, myApp, data.appData.meta.id);
}

function appBuildProgress({ appId, base, progress, messages }) {
	// Issue: causes modal rerendering

	let existing = accessNested(Store.get(), ['myApps', appId, 'status', 'build', 'bases', base], {});
	// let existingProgress = existing.progress || 0;
	// Only update progress if at least 1 extra percent (limit the refreshes)
	// if (existingProgress === 1 || (progress < existingProgress + .01 && progress !== 1)) {
	// 	return;
	// }
	let fullMessages = existing.messages || '';
	let newMessages = messages.filter(e => e).join(' > ');
	if (newMessages) {
		if (fullMessages) fullMessages += '\n';
		fullMessages += `[${new Date().toLocaleTimeString()}] ${newMessages}`;
	}
	Store.emit(actions.UPDATE_MY_APP, {
		status: {
			build: {
				bases: { [base]: { progress, messages: fullMessages } }
			}
		}
	}, appId);
}

function finishedAppBuild(data) {
	// Lets check if there is a modal open for an error
	let store = Store.get();
	if (store.modal && store.modal.type === 'BUILD_ERROR') {
		Store.emit(actions.HIDE_MODAL);
	}

	let myApp = {};
	myApp.status = {
		build: {
			finishedAt: Date.now(),
			error: null, errorAt: 0
		}
	};
	Store.emit(actions.UPDATE_MY_APP, myApp, data.appData.meta.id, true);
}

function errorAppData({ errors, appId }) {
	console.error('errors', appId, errors);
}

function errorAppBuild({ err, appData }) {
	let myApp = {};
	myApp.status = {
		build: {
			errorAt: Date.now(),
			error: err
		}
	};
	Store.emit(actions.UPDATE_MY_APP, myApp, appData.meta.id);
}

export const updateBuildAppData = (appId) => {
	let appData = getMergedAppData(appId);
	attachDummyTitle([appData]);
	[appData] = attachDemoRegionSelectorData([appData]);
	window.socket.emit('updateAppData', { appData });
};

export const clearBuildCache = (mergedAppData) => {
	window.socket.emit('clearBuildCache', { appData: mergedAppData });
};

export const stopWatch = (appId) => {
	const list = Store.get().list;
	const app = list.apps[appId];
	const title = list.titles[app?.rel?.title]?.slug;
	const region = list.regions[app?.rel?.region]?.slug;
	if (title && region) {
		window.socket.emit('stopWatch', { title, region });
	}
};
