import { h, Component } from 'preact';
import pure from 'utils/pure';
import Store from 'store/store';
import * as actions from 'store/actions';
import s from 'components/shared/modal/exitModal.sss';
import { track } from 'utils/tracking';
import { getCopy } from 'utils/copy';
import { Link } from 'components/core/link/link';


export default @pure class ExitModal extends Component {

	constructor() {
		super();
		this.closeModal = this.closeModal.bind(this);
		this.openLink = this.openLink.bind(this);
	}

	closeModal() {
		Store.emit(actions.HIDE_MODAL);
		track({ a:'exit_modal_click_cancel' });
	}

	openLink() {
		Store.emit(actions.HIDE_MODAL);
	}

	getTitle() {
		// Should we use the current location.host instead of meta > vanity ?
		// Would be a bit weird if multiple vanities are available
		const vanity = this.props.vanity || '';
		// get rid of the http://
		const strippedVanity = vanity.split('://').pop();
		let content = getCopy('EXIT_MODAL_TITLE', { vanity: strippedVanity });
		return (
			<h2 class={s.title}>{content}</h2>
		);
	}

	render(props) {
		// the href that it needs to direct them to
		const href = props.href;

		return (
			<div class={s.container}>
				{this.getTitle()}
				<Link
					href={href}
					extraOnClick={this.openLink}
					ignoreExitModal
					trackId="exit_modal_click_ok"
					trackLabel={href}
					target="_blank"
					class={s.button}
				>
					{getCopy('OK')}
				</Link>
				<button onClick={this.closeModal} class={s.button}>{getCopy('CANCEL')}</button>
			</div>
		);
	}

}
