import { h, Component } from 'preact';
import pure from 'utils/pure';
import { debounce, accessNested, addClass, removeClass, condClass, joinClasses, asList } from 'utils/utils';
import * as dataApi from 'services/dataApi';

import values from 'lodash/values';
import Store from 'store/store';
import * as actions from 'store/actions';

import HTML from 'components/core/html/html';
import AutoCompleteInput from 'components/shared/autoCompleteInput/autoCompleteInput';
import { smoothScrollTo } from 'utils/smoothScroll';

import s from './matchTitles.sss';

const weirdFlags = {
	intl: '🗺️',
	mde: '🗺️',
	lam: '🗺️'
};
// From https://github.com/thekelvinliu/country-code-emoji
let getFlag = country => weirdFlags[country] || String.fromCodePoint(...[...country.slice(0, 2).toUpperCase()].map(c => c.charCodeAt() + 127397));

// Get the list from
//   SELECT provider_id, string_agg(DISTINCT country, ', ')
//   FROM theaters
//   GROUP BY provider_id
const providerCountries = {
	amc: '🇺🇸',
	bfi: '🇬🇧',
	cinemex: '🇲🇽',
	cinepolis: '🇲🇽',
	ciner: '🇨🇭',
	emp: '🇬🇧',
	flicks: '🇳🇿',
	fox: '🇺🇸',
	grace: '🇺🇸',
	hexa: '🇫🇷',
	ingre: '🇧🇷',
	mtau: '🇦🇺',
	ebroadcast: '🇦🇺',
	nordisk: '🇳🇴',
	odeon: '🇬🇧',
	reserv: '🇪🇸',
	swit: '🇮🇹',
	velox: '🇧🇷',
	vue: '🇳🇱',
	webuk: '🇬🇧',
	webus: '🇺🇸',
	yandex: '🇷🇺'
};

const countryProviders = {
	base: { wwm: true, mx: true },
	ae: { cpass: true },
	at: { cpass: true },
	au: { ebroadcast: true },
	br: { velox: true, ingre: true },
	ch: { ciner: { except: { studio: ['universalstudios', 'patersonentertainment'] } } },
	de: { cpass: true },
	es: { reserv: true },
	fr: { hexa: true },
	it: { swit: { only: { studio: 'universalstudios' } } },
	mx: { cinemex: true, cinepolis: true },
	nl: { vue: true },
	no: { nordisk: true },
	nz: { flicks: true },
	pt: { cpass: true },
	ru: { yandex: true },
	gb: { webuk: true, bfi: true, emp: true, odeon: true },
	us: { amc: true, webus: true, fox: true, grace: true },
};

const disabledProvidersList = [
	'grace',
	'mtau',
	'hexa'
];

// const formatGroups = [
// 	'',
// 	'2D',
// 	'3D',
// 	'IMAX',
// 	'ATMOS',
// 	'D-Box',
// 	'4DX',
// ];

export default @pure class MatchTitles extends Component {
	constructor(props) {
		super(props);
		this.addFormat = this.addFormat.bind(this);
		this.assign2dToMatched = this.assign2dToMatched.bind(this);
		this.matchAll = this.matchAll.bind(this);
		this.keyDown = this.keyDown.bind(this);
		this.selectCountry = this.selectCountry.bind(this);
		this.setDisabledProvider = this.setDisabledProvider.bind(this);
		this.searchMovies = debounce(this.searchMovies.bind(this), 500, this);
		this.submitProviderTitle = this.submitProviderTitle.bind(this);
		this.providers = [];
		dataApi.getProvidersList().then(response => {
			this.providers = response.map(e => e.id).sort();
		});
		this.state.formats = [];
		this.state.copy = Store.get().appData.copy;
		this.studios = [];
		this.regions = [];
		if (typeof window !== 'undefined') {
			this.init(props);
		}
	}

	async init(props) {
		props = props || this.props;
		this.setState({
			search: null,
			searchLoading: false,
			results: null,
			country: null,
			hideDisabledProviders: true
		});
		let titleId = props.titleId;
		await Store.emit('REQUEST_MISSING', [{ type: 'title', id: titleId }]);
		let store = Store.get();
		if (store.titles[titleId]) {
			Store.emit(actions.UPDATE_PROVIDER_TITLES, titleId);
		}
		let apps = Object.values(store.list.apps).filter(app => app.rel.title === titleId);
		let studioIds = [...new Set(apps.map(app => app.rel.studio))];
		let regionIds = [...new Set(apps.map(app => app.rel.region))];
		this.studios = studioIds.map(id => store.list.studios[id]?.slug);
		this.regions = regionIds.map(id => store.list.regions[id]?.slug).sort();
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keyDown);
		this.loadFormats();
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keyDown);
	}

	keyDown(e) {
		let ctrl = e.ctrlKey || e.metaKey;

		// Ctrl + Shift + C
		if (ctrl && e.shiftKey && e.keyCode === 67) {
			e.preventDefault();
			let checkboxes = [...this.base.querySelectorAll('.' + s.groupCheckbox)];
			let allUnchecked = checkboxes.every(el => !el.checked);
			checkboxes.forEach(el => el.checked = allUnchecked);
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.titleId !== this.props.titleId) {
			this.init(newProps);
		}
	}

	formatSortValue(format) {
		return format?.attributes?.name?.trim() || '';
	}

	async loadFormats() {
		try {
			const formats = await dataApi.getFormats();
			formats.sort((a, b) => this.formatSortValue(a).localeCompare(this.formatSortValue(b)));
			this.setState({ formats });
		} catch (err) {
			console.log('Error getting formats', err);
		}
	}

	addFormat(format) {
		if (!format?.id) {
			return;
		}
		let list = this.state.formats || [];
		if (list.some(f => f.id) === format.id) {
			return;
		}
		list = list.slice();
		const sortValue = this.formatSortValue(format);
		// Faster lookup than findIndex (not very important)
		let low = 0;
		let high = list.length;
		while (low < high) {
			const mid = (low + high) >>> 1;
			if (this.formatSortValue(list[mid]).localeCompare(sortValue) < 0) {
				low = mid + 1;
			} else {
				high = mid;
			}
		}
		list.splice(low, 0, format);
		this.setState({ formats: list });
	}

	selectCountry(country) {
		if (country && country.target) {
			country = country.target.value;
		}
		if (!country) {
			this.setState({ displayedProviders: null });
			return;
		}
		let countryClean = country.replace(/^([a-z]{2})_.+/, '$1');
		let displayedProviders = Object.assign({}, countryProviders.base, countryProviders[countryClean]);
		let validate = context => {
			// TODO: work with multiple keys
			if (context.studio) {
				let validStudios = asList(context.studio);
				return validStudios.some(studio => this.studios.includes(studio));
			}
		};
		let list = Object.keys(displayedProviders).filter(provider => {
			let val = displayedProviders[provider];
			if (!val) return false;
			if (typeof val !== 'object') return true;
			if (val.only && !validate(val.only)) {
				return false;
			}
			if (val.except && validate(val.except)) {
				return false;
			}
			return true;
		});
		this.setState({ displayedProviders: list });
	}

	setDisabledProvider(e) {
		this.setState({ hideDisabledProviders: e.target.checked });
	}

	updateProviderTitles(providerTitles, update) {
		let full = Promise.resolve();
		providerTitles.forEach(pt => full = full.then(() => dataApi.updateProviderTitle(pt.id, update)));
		return full.then(() => Store.emit(actions.UPDATE_PROVIDER_TITLES, this.props.titleId));
	}

	assign2dToMatched() {
		let matched = this.props.title.uiExtras.matched || [];
		let formatId = values(this.props.formats).find(f => f.attributes.name === '2d').id;
		this.setState({ assigning: true });
		this.updateProviderTitles(matched, { format_id: formatId }).catch(e => console.log('2d assign error', e)).then(() => this.setState({ assigning: false }));
	}

	matchAll() {
		let suggested = this.props.title.uiExtras.suggested || [];
		let masterId = accessNested(this.props, 'title.uiExtras.movieMaster.id');
		if (!masterId || !suggested.length) return;

		this.updateProviderTitles(suggested, { movie_master_id: masterId });
	}

	searchMovies(e) {
		let search = e.target.value;
		this.setState({ search });
		if (search.length >= 3) {
			if (!this.searchedOnce) {
				this.searchedOnce = true;
				setTimeout(() => smoothScrollTo(this.base, this.base.scrollHeight - this.base.clientHeight, 500), 0);
			}
			const filters = {
				'filter[movie_like]': search,
				'filter[disabled_providers][0]': 'grace'
			};
			this.setState({ searchLoading: true });
			dataApi.getProviderTitles(filters).then(data => {
				if (this.state.search !== search) return;
				this.setState({ results: data, searchLoading: false });
				// if (doScroll) ...
			}).catch(err => {
				if (this.state.search !== search) return;
				console.log('getProviderTitles error', err);
				this.setState({ results: [], searchLoading: false });
			});
		} else {
			this.setState({ results: [], searchLoading: false });
		}
	}

	submitProviderTitle(e) {
		e.preventDefault();

		let { movieMaster } = this.props.title.uiExtras;

		let formData = new FormData(e.target);
		let titleAttributes = {
			title: formData.get('title'),
			provider_id: formData.get('provider_id'),
			provider_movie_id: formData.get('provider_movie_id'),
			format_id: formData.get('format_id'),
			movie_master_id: movieMaster.id
		};
		let error = null;
		if (!titleAttributes.title) error = 'Please input a title';
		else if (!titleAttributes.provider_id) error = 'Please select a provider';
		else if (!titleAttributes.provider_movie_id) error = 'Please input a '+titleAttributes.provider_id+' movie id';
		if (error) {
			this.setState({ createTitleError: error });
			return;
		} else if (this.state.createTitleError) {
			this.setState({ createTitleError: null });
		}

		dataApi.createProviderTitle(titleAttributes)
			.then(() => {
				Store.emit(actions.UPDATE_PROVIDER_TITLES, this.props.title.id);
			}).catch(err => {
				console.log('Create Provider Title error', err);
				this.setState({ createTitleError: 'Error while creating the provider title (are you sure it does not already exists ?)' });
			});
	}

	sortMovies(a, b) {
		let strA = a.attributes.provider_id + ':' + a.attributes.provider_movie_id;
		let strB = b.attributes.provider_id + ':' + b.attributes.provider_movie_id;
		return strA.localeCompare(strB);
	}

	sortMoviesFormat(a, b) {
		if (a.attributes.format_id === null) {
			return -1;
		}
		if (b.attributes.format_id === null) {
			return 1;
		}
		if (a.attributes.format_id === b.attributes.format_id) {
			return 0;
		}
		return a.attributes.format_id < b.attributes.format_id ? -1 : 1;
	}

	groupByProvider(suggestions) {
		if (!suggestions) return;
		return suggestions.reduce((out, ps) => {
			const p = ps.attributes.provider_id;
			if (!out[p]) out[p] = [];
			out[p].push(ps);
			return out;
		}, {});
	}

	getGroupedSection = (title, groupedList, emptyMessage) => {
		if (!groupedList) return <div class={s.loading} />;
		if (!Object.keys(groupedList).length) return <li class={s.noMatch}>{emptyMessage || 'No matched titles'}</li>;
		return Object.keys(groupedList).map(group => (
			<div class={s.providerGroup}>
				<div class={s.providerGroupName}>{group}</div>
				<div class={s.providerGroupItems}>
					{groupedList[group].map(pt => <ProviderTitle pt={pt} title={title} />)}
				</div>
			</div>
		));
	};

	getNoFormatCount(providerMovies) {
		return providerMovies.filter(m => !m.attributes.format_id).length;
	}

	render({ title }, state) {
		let uiExtras = title?.uiExtras || {};
		if (typeof uiExtras.toJS === 'function') {
			uiExtras = uiExtras.toJS();
		}
		let { matched, suggested, movieMaster } = uiExtras;

		if (!title || !movieMaster) {
			return <div class={joinClasses(s.container, s.loadingMasterId)}><div class={s.loading} /></div>;
		}

		let addTitle = (e, match) => {
			e.title = match.value;
			e.textContent = match.label || match.value;
		};
		let autoCompleteFormatList = state.formats.map(f => ({
			value: f.attributes.name,
			type: 'format',
			customContent: addTitle,
			src: f
		}));

		let matchedSorted = matched ? matched.slice().sort(this.sortMovies) : [];
		let matchedPerProvider = this.groupByProvider(matchedSorted);

		let suggestedSorted = suggested && suggested.filter(pt => {
			if (pt.attributes.movie_master_id === movieMaster.id) {
				return false;
			}
			if (state.displayedProviders && !state.displayedProviders.includes(pt.attributes.provider_id)) {
				return false;
			}
			return true;
		}).filter(providerMovie => {
			if (this.state.hideDisabledProviders) {
				return !disabledProvidersList.includes(providerMovie.attributes.provider_id);
			}
			return true;
		});
		const suggestedGrouped = this.groupByProvider(suggestedSorted);

		const noFormatCountPerProvder = matchedPerProvider && Object.keys(matchedPerProvider).reduce((perProviderCount, p) => {
			const noFormatcount = this.getNoFormatCount(matchedPerProvider[p]);
			perProviderCount[p] = noFormatcount;
			return perProviderCount;
		}, {});

		let matchedSection;
		if (!matched) {
			matchedSection = <div class={s.loading} />;
		} else if (!matched.length) {
			matchedSection = <li class={s.noMatch}>No matched titles</li>;
		} else {
			matchedSection = Object.keys(matchedPerProvider).map(provider => {
				if (state.displayedProviders && !state.displayedProviders.includes(provider)) {
					return;
				}
				let checkboxId = 'matched-provider-group-' + provider;
				let list = matchedPerProvider[provider].sort(this.sortMoviesFormat);
				const nfCount = noFormatCountPerProvder[provider];
				return (
					<div class={s.group}>
						<input class={s.groupCheckbox} type="checkbox" id={checkboxId} />
						<label for={checkboxId} class={s.groupTitle}>
							<span class={s.country}>{providerCountries[provider] || '🗺️'}</span>
							<span class={s.name}>{provider}</span>
							<span class={s.count}>{list.length}</span>
							{nfCount > 0 ? <span class={s.noFormatCount}>{nfCount}!</span> : null}
						</label>
						<div class={s.groupContent}>
							{
								list.map(pt => (
									<ProviderTitle
										pt={pt}
										title={title}
										formatList={state.formats}
										autoCompleteFormatList={autoCompleteFormatList}
										addFormat={this.addFormat}
										matched
										noFlag
									/>
								))
							}
						</div>
					</div>
				);
			});
		}
		let suggestedSection = this.getGroupedSection(title, suggestedGrouped, 'No suggested titles.');
		let results;
		if (state.searchLoading) {
			results = <div class={s.loading} />;
		} else if (state.results) {
			if (state.results.length) {
				let filteredResults = state.results.sort(this.sortMovies).filter(pt => {
					if (pt.attributes.movie_master_id === movieMaster.id) {
						return false;
					}
					if (state.displayedProviders && !state.displayedProviders.includes(pt.attributes.provider_id)) {
						return false;
					}
					return true;
				});
				results = filteredResults.map(pt => <ProviderTitle pt={pt} title={title} />);
			} else {
				results = <div class={s.noResults} />;
			}
		}

		// let assign2dButton;
		// if (matched && matched.length) {
		// 	assign2dButton = <button class={joinClasses(s.button, state.assigning && s.waiting)} onClick={this.assign2dToMatched}>Assign All 2d</button>;
		// }

		return (
			<div class={s.container}>
				<div class={s.containerHeader}>
					<div class={s.headTitles}>
						<h2 class={s.headTitle}>Title Matcher</h2>
						<h5 class={s.mmid}>
							Movie Master:
							<span class={s.title}> {movieMaster && movieMaster.attributes.slug} </span>
							<span class={s.id}>{movieMaster && movieMaster.id}</span>
						</h5>
						<div class={s.filters}>
							<label class={s.countryFilterSelectLabel} for="countrySelect">
								Show providers for country:
							</label>
							<select class={s.countryFilterSelect} onChange={this.selectCountry} name="countrySelect">
								<option value="">ALL</option>
								{this.regions.map(region => {
									return <option value={region}>{getFlag(region) + ' ' + region}</option>;
								})}
							</select>
							<label class={s.disabledProvidersLabel} for="disabledProviders">
								Hide disabled providers:
							</label>
							<input
								type="checkbox"
								class={s.disabledProvidersCheckbox}
								onChange={this.setDisabledProvider}
								checked={this.state.hideDisabledProviders}
								name="disabledProviders"
							/>
						</div>
					</div>
					<div class={joinClasses(s.movieProviderDataGuide, state.dataGuideCollapsed && s.collapsed)}>
						<p class={s.dataGuidelineHeader} onClick={() => this.setState({ dataGuideCollapsed: !state.dataGuideCollapsed })}>Countries Data List</p>
						<HTML class={s.dataGuideline} content={this.state.copy.$DATA_PROVIDER_LIST} markdown />
					</div>
				</div>
				<div class={s.content}>
					<h4 class={s.sectionName}>Matched Provider Titles</h4>
					<div class={s.section}>
						<div class={s.matched}>{matchedSection}</div>
						{/* assign2dButton */}
					</div>
					<h4 class={s.sectionName}>Suggested Provider Titles</h4>
					<div class={s.section}>
						<div class={s.suggested}>{suggestedSection}</div>
						{/* suggested && suggested.length ? (<button class={s.button} onClick={this.matchAll}>Match All Suggested</button>) : null */}
					</div>
					<h4 class={s.sectionName}>Search More</h4>
					<div class={s.section}>
						<div class={s.searchWrapper}>
							<input class={s.search} type="text" name="searchMore" placeholder="Search other movies" onInput={this.searchMovies} />
						</div>
						<ul class={s.results}>{results}</ul>
					</div>
					<h4 class={s.sectionName}>Create Provider Title</h4>
					<div class={s.section + condClass(s.createTitle)}>
						<form onSubmit={this.submitProviderTitle}>
							<div class={s.provider}>
								<select class={s.none} name="provider_id" onChange={e => (e.target.value ? removeClass : addClass)(e.target,s.none)}>
									<option class={s.none} value="">provider</option>
									{this.providers.map(p => <option value={p}>{p}</option>)}
								</select>
								<span class={s.separator}>:</span>
								<input placeholder="id" type="text" name="provider_movie_id" />
							</div>
							<div class={s.title}><input placeholder="Title" type="text" name="title" /></div>
							<div class={s.format}>
								<select class={s.none} name="format_id" onChange={e => (e.target.value ? removeClass : addClass)(e.target,s.none)}>
									<option class={s.none} value="">-- FORMAT --</option>
									{state.formats.map(f => <option value={f.id}>{f.attributes.name}</option>)}
								</select>
							</div>
							<div class={s.error}>{state.createTitleError}</div>
							<div class={s.buttons}>
								<button type="submit" class={s.button}>Create</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

class ProviderTitle extends Component {
	constructor() {
		super();
		this.formatChanged = this.formatChanged.bind(this);
		this.setFormat = this.setFormat.bind(this);
		this.match = this.match.bind(this);
		this.unmatch = this.unmatch.bind(this);
		this.selectFormatName = this.selectFormatName.bind(this);
		this.state.disableFormatInput = false;
	}

	formatChanged(e) {
		let formatId = e.target.value || null;
		if (!formatId) addClass(e.target, s.noFormat);
		else removeClass(e.target, s.noFormat);
		this.setFormat(formatId);
	}

	setFormat(formatId) {
		let id = this.props.pt.id;
		this.setState({ disableFormatInput: true });
		dataApi.updateProviderTitle(id, {
			format_id: formatId
		}).then(() => {
			Store.emit(actions.UPDATE_PROVIDER_TITLES, this.props.title.id);
			this.setState({ disableFormatInput: false });
		});
	}

	match() {
		let confirmOverride = Promise.resolve();
		let alreadyMatched = accessNested(this.props, 'pt.attributes.movie_master_id');
		if (alreadyMatched) {
			confirmOverride = dataApi.getMovieMasterFromID(alreadyMatched).then(d => {
				let currentSlug = accessNested(this.props, 'title.uiExtras.movieMaster.attributes.slug');
				// eslint-disable-next-line no-alert
				return !confirm('This title is already matched'+(d ? ' to ' + d.attributes.slug : '')+'.\nUnmatch it and match it to '+currentSlug+'?');
			});
		}
		confirmOverride.then(cancel => {
			if (cancel) return;
			let mmid = accessNested(this.props, 'title.uiExtras.movieMaster.id');
			if (mmid) this.updateProviders(mmid);
		});
	}

	updateProviders(movie_master_id) {
		// Instant local update for better UX
		this.props.pt.attributes.movie_master_id = movie_master_id;
		let title = Store.get().titles[this.props.title.id];
		let uiExtras = title.uiExtras ? title.uiExtras.toJS() : {};

		let matched = uiExtras.matched || [];
		let suggested = uiExtras.suggested || [];
		// Update the version of the provider title in the suggested list
		let inSuggested = suggested.find(pt => pt.id === this.props.pt.id);
		if (inSuggested) inSuggested.attributes.movie_master_id = movie_master_id;

		if (movie_master_id) {
			uiExtras.matched = matched.concat(this.props.pt);
		} else {
			uiExtras.matched = matched.filter(pt => pt.id !== this.props.pt.id);
			if (!suggested.find(pt => pt.id === this.props.pt.id)) {
				uiExtras.suggested = suggested.concat(this.props.pt);
			}
		}
		title.set({ uiExtras });

		// If it was manually matched or unmatched, the confidence is 100%
		dataApi.updateProviderTitle(this.props.pt.id, {
			movie_master_id,
			movie_master_confidence: 100
		}).then(() => {
			// Trigger a refresh to make sure all is ok ?
			// Store.emit(actions.UPDATE_PROVIDER_TITLES, this.props.title.id);
		}).catch(err => {
			// eslint-disable-next-line no-alert
			alert('Sorry, an error occurred while matching, please call a dev');
			console.log('Error matching', this.props, movie_master_id, err);
		});
	}

	unmatch() {
		let pta = this.props.pt.attributes;
		let curentSlug = accessNested(this.props, 'title.uiExtras.movieMaster.attributes.slug');
		// eslint-disable-next-line no-alert
		let confirmed = confirm(`Are you sure you want to remove ${pta.provider_id}:${pta.provider_movie_id} from ${curentSlug}?`);
		if (!confirmed) return;

		this.updateProviders(null);
		// TODO: add back to search results list
	}

	// From autocomplete
	selectFormatName(data) {
		if (!data) return;
		if (data.type === 'match') {
			return this.setFormat(data.match.src.id);
		}
		let name = data.value || '';
		if (!name) {
			return this.setFormat(null);
		}
		let match = this.props.formatList.find(f => f.attributes.name === name);
		if (match) {
			return this.setFormat(match.id);
		}
		dataApi.createFormat(name).then(data => {
			if (!data) {
				throw 'NO_DATA';
			}
			this.props.addFormat?.(data);
			this.setState({ disableFormatInput: false });
			return this.setFormat(data.id);
		}).catch(err => {
			let previousFormatId = this.props.pt.attributes.format_id;
			let previousFormat = this.props.formatList.find(f => f.id === previousFormatId);
			let previousFormatName = previousFormat ? previousFormat.attributes.name : '';
			console.log('ERROR creating format', err);
			// eslint-disable-next-line no-alert
			alert('Could not create the format ' + name + '\nCheck with a dev if the issue persists.');
			this.$format.setValue(previousFormatName);
			this.setState({ disableFormatInput: false });
		});
	}

	render({ pt, formatList, autoCompleteFormatList, matched, noFlag }) {
		let formatElem;
		if (formatList && autoCompleteFormatList) {
			let selectedFormat = formatList.find(f => f.id === pt.attributes.format_id);
			let selectedFormatName = selectedFormat ? selectedFormat.attributes.name : '';
			formatElem = (
				<div class={s.formatInput}>
					<AutoCompleteInput
						ref={e => this.$format = e}
						key={'format-' + pt.id}
						class={joinClasses(s.autoCompleteFormat, !pt.attributes.format_id && s.noFormat)}
						placeholderClass={s.placeholder}
						unsavedClass={s.editing}
						onChange={this.selectFormatName}
						value={selectedFormatName}
						words={autoCompleteFormatList}
						copy={{ $SEARCH: 'format' }}
						placeholder="No format selected"
						noResetButton
						disabled={this.state.disableFormatInput}
					/>
					{this.state.disableFormatInput && <div class={joinClasses(s.loading, s.loadingInline)} />}
				</div>
			);
		}
		return (
			<div class={s.elem} key={pt.id}>
				<span class={s.matchButton + condClass(matched, s.unmatch)} onClick={this[matched ? 'unmatch' : 'match']} />
				<span class={joinClasses(s.pid, noFlag && s.noFlag)}>
					<span class={s.country}>{providerCountries[pt.attributes.provider_id] || '🗺️'}</span>
					<span class={s.provider}>{pt.attributes.provider_id}</span>
					<span class={s.sep}>:</span>
					<span class={s.movie}>{pt.attributes.provider_movie_id}</span>
				</span>
				<div class={s.title}>{pt.attributes.title}</div>
				{formatElem}
			</div>
		);
	}
}
