/**
 * Youtube api interactions
 */

import { handleApiResponse } from '../utils/handleApi';
const API_KEY = 'AIzaSyBtZ_ReV0X_SCLIYosdhO3ICgvjo4HmjbA';
const isServer = typeof window === 'undefined';

export const getPlaylistData = (playlistID) => {
	if (isServer || !playlistID) return Promise.reject();

	return fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistID}&key=${API_KEY}`)
		.then(handleApiResponse);
};

export const getVideoData = (videoId) => {
	if (isServer || !videoId) return Promise.reject();

	return fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&maxResults=50&id=${videoId}&key=${API_KEY}`)
		.then(handleApiResponse);
};

export const getPublicPlaylistData = (playlistID) => {
	// Not sure if this should be a reject, but only way to make sure we don't run our other commands i guess
	if (isServer || !playlistID) return Promise.reject();

	return fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,status&maxResults=50&playlistId=${playlistID}&key=${API_KEY}`)
		.then(handleApiResponse)
		.then(data => {
			// Make sure all the videos returned are public
			return data.items.filter(item => item.status.privacyStatus === 'public');
		});
};
