import { h, Component } from 'preact';
import s from 'components/pages/theatersMatcher/theaterUnmatcher.sss';
import { apiGet, apiUpdate, getProvidersList } from 'services/dataApi';
import deserialise from 'utils/deserialise';

// TODO: endpoint
const countries = (
	'ad,ae,al,ao,ar,at,au,aw,az,ba,bd,be,bf,bg,bh,bm,bo,bq,br,bw,by,ca,ch,cl,' +
	'cm,cn,co,cr,cw,cy,cz,de,dk,do,dz,ec,ee,eg,en,es,fi,fj,fl,fo,fr,ga,gb,ge,' +
	'gi,gl,gr,gt,gu,hk,hn,ho,hr,hu,id,ie,il,in,iq,is,it,je,jm,jo,jp,ke,kg,kr,' +
	'kw,ky,kz,lb,lc,li,lt,lu,lv,ma,mc,me,mg,mk,mn,mo,mp,mt,mx,my,na,ng,ni,nl,' +
	'no,nz,om,pa,pe,pg,ph,pk,pl,pn,pr,pt,py,qa,qc,re,ro,rs,ru,sa,se,sg,sh,si,' +
	'sk,sn,sp,sv,sx,sz,th,tr,tt,tw,tx,ua,uk,us,uy,ve,vn,xk,za,zw'
).split(',');

const states = [
	'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL',
	'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH',
	'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
	'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC',
	'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
];

// From https://github.com/thekelvinliu/country-code-emoji
let getFlag = country => String.fromCodePoint(...[...country.toUpperCase()].map(c => c.charCodeAt() + 127397));

export default class TheaterUnmatcher extends Component {

	// LIFE CYCLE

	constructor() {
		super();
		this.countryInputChange = this.countryInputChange.bind(this);
		this.providerInputChange = this.providerInputChange.bind(this);
		this.stateInputChange = this.stateInputChange.bind(this);
		this.refreshTheaters = this.refreshTheaters.bind(this);

		this.state = {
			previous: null,
			current: 0,
			providers: [],
			selectedTheater: '',
			selectedSuggestion: '',
			limit: 50,
			theaters: [],
			currentTheaterPagination: 0,
			matched: {},
			searchTerm: '',
			country: 'us',
			state: '',
			provider: '',
			loadingTheaters: true,
			done: false,
			requestingMore: false
		};

		getProvidersList().then(response => {
			this.setState({ providers: response.map(e => e.id).sort() });
		});
	}

	componentDidMount() {
		this.refreshTheaters();
	}

	// FUNCTIONS

	// matched theater process
	updateTheaters() {
		let queryParams = [];
		if (this.state.country) {
			queryParams.push('country=' + this.state.country);
		}
		if (this.state.provider) {
			queryParams.push('provider=' + this.state.provider);
		}
		//to make sure the it is us
		if (this.state.country === 'us' && this.state.state && this.state.state !== '') {
			queryParams.push('state=' + this.state.state);
		}
		if (this.state.searchTerm !== '') {
			queryParams.push('term=' + this.state.searchTerm);
		}
		if (this.state.currentTheaterPagination >= this.state.limit) {
			queryParams.push('from=' + this.state.currentTheaterPagination);
		}
		return apiGet('/proxy/stdata/theaters/search?'+ queryParams.join('&')).then(deserialise);
	}

	refreshTheaters() {
		this.setState({ loadingTheaters: true, done: false });
		this.updateTheaters().then(response => {
			this.setState({ theaters: response, current: 0, previous: null, loadingTheaters: false }, this.getMatching);
			this.setState({ selectedTheater: this.state.theaters[0].id});
		}).catch(err => {
			console.log(err);
			this.setState({ error: err, loadingTheaters: false });
		});
	}

	updateSearch = (e) => {
		if (e && e.target.value) {
			this.setState({searchTerm: e.target.value}, this.refreshTheaters);
			// this.refreshTheaters();
			// console.log('searchTerm', this.state.searchTerm);
		}
	}

	getMatching() {
		let queryParams = [];
		if (this.state.currentTheaterPagination >= this.state.limit) {
			queryParams.push('from=' + this.state.currentTheaterPagination);
		}
		let selected;
		this.state.theaters.map((theater) => {
			if(theater.id === this.state.selectedTheater) {
				selected = theater;
			}
		});
		const toMatch = selected && selected.attributes.theater_master_id;
		if (toMatch && this.state.theaters.length !== 0) {
			queryParams.push('theater_master_id=' + toMatch);
			apiGet('/proxy/stdata/theaters/search?'+ queryParams.join('&')).then(deserialise).then(response => {
				this.setState({matched: response});
			});
		} else {
			this.setState({matched: {}});
		}
	}

	unmatching(matched) {
		let toUpdate;
		this.state.matched.map((m) => {
			if (matched === m.id) {
				toUpdate = m;
			}
		});
		if (toUpdate) {
			let payload = {
				data: {
					attributes: {
						theater_master_id: null,
						theater_master_confidence: 0
					}
				}
			};
			apiUpdate('/proxy/stdata/theaters/' + toUpdate.id, payload).then(() => this.getMatching());
		}
	}


	// CLICK HANDLERS

	// filters (region, state, provider)
	countryInputChange(e) {
		this.selectCountry(e.target.value);
	}
	providerInputChange(e) {
		this.setState({
			selectedTheater: '',
			currentTheaterPagination: 0
		});
		this.selectProvider(e.target.value);
	}

	stateInputChange(e) {
		this.setState({
			selectedTheater: '',
			currentTheaterPagination: 0
		});
		this.selectState(e.target.value);
	}

	selectState(state) {
		this.setState({state: state}, this.refreshTheaters);
	}

	selectCountry(country) {
		this.setState({
			selectedTheater: '',
			currentTheaterPagination: 0
		});
		if (country && countries.indexOf(country) === -1) return;
		this.setState({ country }, this.refreshTheaters);
	}
	selectProvider(provider) {
		if (provider && this.state.providers.indexOf(provider) === -1) return;
		this.setState({ provider }, this.refreshTheaters);
	}

	// remove matching selection
	removeMatch(theater) {
		let matched = theater.id;
		let current = this.state.selectedTheater;
		if (!current) return;
		this.unmatching(matched);
	}

	selectTheater = (e) => {
		const id = e.target.__preactattr_.key;
		this.setState({selectedTheater: id});
		this.renderTheaters();
		this.getMatching();
	}

	clearFilters = () => {
		this.setState({
			selectedTheater: '',
			country: 'us',
			state: '',
			provider: '',
			searchTerm: '',
			theaters: []
		});
		this.$searchTerm.value = '';
		this.refreshTheaters();
		this.getMatching();
	}

	//pagination
	nextTheaterPagination = () => {
		let currentTheaterPagination = this.state.currentTheaterPagination;
		this.setState({currentTheaterPagination: currentTheaterPagination + this.state.limit});
		this.refreshTheaters();
	}

	prevTheaterPagination = () => {
		let currentTheaterPagination = this.state.currentTheaterPagination;
		if (currentTheaterPagination - this.state.limit > 0) {
			this.setState({currentTheaterPagination: currentTheaterPagination - this.state.limit});
			this.refreshTheaters();
		}
	}


	// RENDER METHODS (main at bottom)

	renderTheater(theater) {
		if (!theater) return;
		let attr = theater.attributes;
		let city = attr.city_en || attr.city || '<no city>';
		if (attr.state) {
			city += ', ' + attr.state;
		}
		if (attr.postcode) {
			city += ', ' + attr.postcode;
		}

		let idStyle = {
			backgroundColor: 'hsl(' + (360 * this.state.providers.indexOf(attr.provider_id) / this.state.providers.length) + ', 50%, 50%)'
		};

		return (
			<div
				class={this.state.selectedTheater === theater.id ? s.selectedTheater : s.theater}
				key={theater.id}
				onClick={this.selectTheater}
			>
				<div class={s.country}>{getFlag(attr.country)}</div>
				<div class={s.id} style={idStyle}>
					<span>{attr.provider_id}</span>:{attr.provider_theater_id}
				</div>
				<div class={s.name}>{attr.name_en || attr.name}</div>
				<div class={s.location}>
					<div class={s.address}>{attr.address_en || attr.address}</div>
					<div class={s.city}>{city}</div>
					<a class={s.pin} href={'http://maps.google.com/?q='+attr.lat+','+attr.lon} target="_blank">{attr.lat}, {attr.lon}</a>
				</div>
				<p class={s.id}>{theater.id}</p>
			</div>
		);
	}

	renderTheaters() {
		const result = this.state.theaters;
		if (result && result.length < 1) return <h2>Nothing</h2>;
		return (
			<div class={s.result}>
				<h2 class={s.title}>Theaters</h2>
				{result && Object.keys(result).map((theater) => (
					this.renderTheater(result[theater])
				))}
				{result && result.length === this.state.limit && <button class={s.prevPagination} onClick={this.prevTheaterPagination}>Prev</button>}
				{result && result.length === this.state.limit && <button class={s.nextPagination} onClick={this.nextTheaterPagination}>Next</button>}

			</div>
		);
	}

	renderMatching() {
		let matched = this.state.matched;
		return (
			<div class={s.matching}>
				<h2 class={s.title}>Matched with</h2>
				{matched && matched.length > 1 && Object.keys(matched).map((match) => (
					<div class={s.matched}>
						{matched[match].id !== this.state.selectedTheater ? this.renderTheater(matched[match]) : null}
						{this.state.selectedTheater !== matched[match].id && <button class={s.select} onClick={() => this.removeMatch(matched[match])}>
							<span class={s.text}>Unmatch</span>
						</button>}
					</div>
				))}
				{matched && matched.length === this.state.limit && <button class={s.prevPagination} onClick={this.prevMatchedPagination}>Prev</button>}
				{matched && matched.length === this.state.limit && <button class={s.nextPagination} onClick={this.nextMatchedPagination}>Next</button>}
			</div>
		);
	}

	render() {
		return (
			<div class={s.container}>
				<div class={s.filters}>
					<div class={s.countryWrapper}>
						Region:
						<select class={s.countrySelect} value={this.state.country} onChange={this.countryInputChange}>
							<option value="">all</option>
							{countries.map(country => <option value={country}>{country}</option>)}
						</select>
					</div>
					{this.state.country === 'us' && <div class={s.stateWrapper}>
						State:
						<select class={s.stateSelect} value={this.state.state} onChange={this.stateInputChange}>
							<option value="">all</option>
							{states.map(state => <option value={state}>{state}</option>)}
						</select>
					</div>}
					<div class={s.providerWrapper}>
						Provider:
						<select class={s.providerSelect} value={this.state.provider} onChange={this.providerInputChange}>
							<option value="">all</option>
							{this.state.providers.map(provider => <option value={provider}>{provider}</option>)}
						</select>
					</div>
					<div class={s.searchWrapper}>
						<input class={s.searchInput} placeholder="🔍 SEARCH all Theaters by Name, Address or City  🔎" ref={e => this.$searchTerm = e} onInput={this.updateSearch} type="text" />
					</div>
					<div class={s.clearFilters} onClick={this.clearFilters}>Clear Filters</div>
				</div>
				<div class={s.allTheatersContainer}>
					{this.renderTheaters()}
					{this.renderMatching()}
				</div>
				<div class={s.matchedContainer}></div>
			</div>
		);
	}

}
