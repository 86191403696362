import { h, Component } from 'preact';
import Store from 'store/store';
import { accessNested, joinClasses, hasClass, addClass, removeClass } from 'utils/utils';
import watch from 'utils/watch';
import pure from 'utils/pure';
import s from 'components/shared/scrollBox/scrollBox.sss';

// Get position on mouse/touch events
function getEventPos(event) {
	if (event.touches && event.touches.length) {
		event = event.touches[0];
	}
	if (event.clientX === undefined) {
		return [undefined, undefined];
	}
	return [event.clientX, event.clientY];
}

// TODO: customization via props (minDist, trigger, speedDecay, ...)
export default @pure class ScrollBox extends Component {

	constructor(props) {
		super();
		this.data = {
			minDist: +props.slop || 3, // Minimum mouse movement (in pixels) before starting to actually scroll / cancelling the click
			trigger: props.trigger == undefined ? 20 : +props.trigger, // Distance from the borders from which we consider being against it
			grabbed: false,
			init: 0,
			moved: false,
			speed: 0
		};
		// Bind event handlers
		this.grab = this.grab.bind(this);
		this.move = this.move.bind(this);
		this.release = this.release.bind(this);
		this.click = this.click.bind(this);
		this.checkScrollPos = this.checkScrollPos.bind(this);
		this.momentum = this.momentum.bind(this);
	}

	componentDidMount() {
		this.registerHandlers();
	}

	// TODO: check, is this necessary ?
	componentDidUpdate() {
		this.registerHandlers();
	}

	componentWillUnmount() {
		this.clearHandlers();
	}

	getDirection() {
		return (this.props.direction || 'X').toUpperCase();
	}

	updateSpeed(pos) {
		const d = this.data;
		d.lastEvent = Date.now();
		d.speed = d.lastPos - pos;
		d.lastPos = pos;
	}

	get scroll() {
		return this.base[this.getDirection() === 'Y' ? 'scrollTop' : 'scrollLeft'];
	}
	set scroll(value) {
		this.base[this.getDirection() === 'Y' ? 'scrollTop' : 'scrollLeft'] = value;
	}

	momentum() {
		const d = this.data;
		cancelAnimationFrame(d?.timer);
		if (!this.base || !d) return;
		d.speed *= .9; // Speed decay (closer to 1 for longer "slide", closer to 0 for instant stop ; should be a prop I guess)
		d.lastPos += d.speed;
		const vert = this.getDirection() === 'Y';
		this.base[vert ? 'scrollTop' : 'scrollLeft'] = d.lastPos;
		if (Math.abs(d.speed) > 0.1) {
			d.timer = requestAnimationFrame(this.momentum);
		}
	}

	stopMomentum() {
		// Stops the momentum if it was started
		const d = this.data || {};
		cancelAnimationFrame(d.timer);
	}

	// Called on mousedown / touchstart
	grab(e) {
		// Left/middle clic : don't grab
		if (e.button !== undefined && e.button !== 0) return;
		const vert = this.getDirection() === 'Y';
		// If the element can't scroll, do nothing
		if (!this.props.alwaysHandleDrag && this.base[vert ? 'scrollHeight' : 'scrollWidth'] <= this.base[vert ? 'clientHeight' : 'clientWidth']) {
			return;
		}
		const d = this.data;
		this.stopMomentum();
		// Prevent selection with mouse
		if (!e.touches) {
			e.preventDefault();
			e.stopPropagation();
		}

		d.lastPos = d.grabbed = getEventPos(e)[vert ? 1 : 0];
		d.init = this.base[vert ? 'scrollTop' : 'scrollLeft'];
		this.updateSpeed(d.grabbed);
		d.moved = false;
	}

	// Called on mousemove
	move(e) {
		const d = this.data;
		// !e.cancelable occurs when it's a touch event and we shouldn't handle it
		if (d.grabbed === false || !e.cancelable) {
			return;
		}
		const vert = this.getDirection() === 'Y';
		const pos = getEventPos(e)[vert ? 1 : 0];
		const delta = d.grabbed - pos;
		if (!d.moved && Math.abs(delta) < d.minDist) {
			return;
		}
		d.moved = true;
		e.preventDefault();
		e.stopPropagation();
		this.base[vert ? 'scrollTop' : 'scrollLeft'] = d.init + delta;
		this.updateSpeed(pos);
	}

	// Called on mouseup
	release() {
		const data = this.data;
		if (data.grabbed === false) return;

		data.grabbed = false;
		if (!data.moved) return;

		// Decrease momentum depending on how long we stayed still (add this as an option too)
		data.speed *= Math.max(0, 1 - (Date.now() - data.lastEvent) / 200);
		const vert = this.getDirection() === 'Y';
		data.lastPos = this.base[vert ? 'scrollTop' : 'scrollLeft'];
		data.timer = requestAnimationFrame(() => this.momentum());
	}

	click(event) {
		if (!this.data.moved) return;
		// If we were dragging, don't handle the click
		event.preventDefault();
		event.stopPropagation();
	}

	addForce(force) {
		const data = this.data;
		if (!this.base || !data) return;
		cancelAnimationFrame(data.timer);
		const vert = this.getDirection() === 'Y';
		data.lastPos = this.base[vert ? 'scrollTop' : 'scrollLeft'];
		data.speed += force;
		data.timer = requestAnimationFrame(() => this.momentum());
	}

	// Check if the scroll is all the way to the left / right
	checkScrollPos() {
		const vert = this.getDirection() === 'Y';
		const props = this.props;
		const startVisibleClass = props[vert ? 'scrollableTopClass' : 'scrollableLeftClass'];
		const endVisibleClass = props[vert ? 'scrollableBottomClass' : 'scrollableRightClass'];
		if (!startVisibleClass && !endVisibleClass) return;

		const base = this.base;
		let v = base[vert ? 'scrollTop' : 'scrollLeft'];
		if (!vert && this.props.isRTL) v = Math.abs(v);
		const vr = base[vert ? 'scrollHeight' : 'scrollWidth'] - base[vert ? 'clientHeight' : 'clientWidth'] - v;

		const startShouldBeVisible = (v >= this.data.trigger);
		const endShouldBeVisible = (vr >= this.data.trigger);

		if (startVisibleClass && startShouldBeVisible !== hasClass(base, startVisibleClass)) {
			(startShouldBeVisible ? addClass : removeClass)(base, startVisibleClass);
		}
		if (endVisibleClass && endShouldBeVisible !== hasClass(base, endVisibleClass)) {
			(endShouldBeVisible ? addClass : removeClass)(base, endVisibleClass);
		}
	}

	clearHandlers() {
		if (!this.handlersRegistered) return;
		this.handlersRegistered = false;

		document.removeEventListener('mousemove', this.move);
		document.removeEventListener('mouseup', this.release);

		if (this.scrollWatch) {
			this.scrollWatch.remove();
			this.scrollWatch = null;
		}
		this.base.removeEventListener('scroll', this.checkScrollPos);
	}

	registerHandlers() {
		if (this.handlersRegistered) return;
		this.handlersRegistered = true;

		document.addEventListener('mousemove', this.move);
		document.addEventListener('mouseup', this.release);

		if (this.props.useSimpleEventListeners) {
			this.base.addEventListener('scroll', this.checkScrollPos);
		} else {
			// Might need to update even when it doesn't scroll (when the content changes)
			// Urgh. Use watch ? Look into this https://github.com/marcj/css-element-queries/blob/master/src/ResizeSensor.js for event based
			let events = ['scrollLeft', 'scrollWidth'];
			if (this.getDirection() === 'Y') {
				events = ['scrollTop', 'scrollHeight'];
			}
			this.scrollWatch = watch(this.base, events, this.checkScrollPos);
		}
	}

	render(allProps) {
		let {
			children,
			direction,
			scrollableLeftClass, scrollableRightClass,
			scrollableTopClass, scrollableBottomClass,
			hideScrollerBar,
			device,
			trigger,
			slop,
			alwaysHandleDrag,
			...props
		} = allProps;
		if (!device) {
			device = accessNested(Store.get(), 'client.device') || 'desktop';
		}

		// A lot of components pretend to be mobile to not have overflow hidden and be able to use the mousewheel
		// We should probably always use overflow auto (this way we don't een need the device)
		const isMobile = device !== 'desktop'; // ???

		// We can't use props for mousemove/mouseup since it's better if they are set on document
		return (
			<div
				{...props}
				class={joinClasses(s.scroller, s['dir' + this.getDirection()], isMobile && s.mobile, props.class, hideScrollerBar && s.hideBar)}
				onMouseDown={this.grab}
				onClickCapture={this.click}
				ref={e => this.$scroller = e}
			>
				{children}
			</div>
		);
	}

}
