import { h } from 'preact';

const Youtube = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" {...props}>
			<path d="M12 5s-6.26 0-7.825.412c-.84.23-1.532.922-1.763 1.779C2 8.755 2 12 2 12s0 3.261.412 4.81c.23.856.906 1.531 1.763 1.762C5.756 19 12 19 12 19s6.26 0 7.825-.412a2.506 2.506 0 0 0 1.763-1.762c.412-1.565.412-4.81.412-4.81s.016-3.26-.412-4.825a2.506 2.506 0 0 0-1.763-1.763C18.26 5 12 5 12 5Zm-1.994 4.003L15.212 12l-5.206 2.997V9.003Z" />
		</svg>
	);
};

export default Youtube;