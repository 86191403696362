import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import { accessNested } from 'utils/utils';
import s from 'components/pages/apps/modals/conflictsModal.sss';


export default class ConflictsModal extends Component {

	// Issue : props are not auto updated (only when we generate the modal)
	// Handle that ourselves
	constructor() {
		super();
	}

	componentWillMount() {
		let data = this.props.data;

		if (data.toJS) data = data.toJS();
		else data = JSON.parse(JSON.stringify(data));

		this.setState({
			data: data,
			conflicts: data.conflicts || []
		});
	}

	componentWillUpdate(props, state) {
		if (!state.conflicts.length) {
			Store.emit(actions.HIDE_MODAL, 'FINISHED');
		}
	}

	choice(id, edit) {
		const data = this.state.data;
		const conflicts = this.state.conflicts.slice();
		const resolved = conflicts.splice(id, 1)[0];
		data.conflicts = conflicts;

		if (edit) {
			const edited = data.edited;
			const path = resolved.path.slice(1).split('/').map(e => e.replace(/~1/g, '/').replace(/~0/g, '~'));
			const attribute = path.pop();

			if (resolved.op === 'remove') {
				delete accessNested(edited, path)[attribute];
			} else {
				accessNested(edited, path)[attribute] = resolved.value;
			}
		}

		Store.emit(actions.UPDATE_APP_DATA, data);

		this.setState({
			conflicts: conflicts
		});
	}

	render(props, { data, conflicts }) {
		const tableContent = conflicts.reduce((lines, conflict, id) => {
			lines.push(<tr><td class={s.path} colSpan="2">{conflict.path}</td></tr>);

			let newValue = '';
			let theirClass = s.removed;

			if (conflict.op !== 'remove') {
				newValue = conflict.value;
				theirClass = newValue === null ? s.disabled : '';
				if (typeof newValue === 'object') {
					newValue = JSON.stringify(newValue, null, '    ');
				}
			}

			const path = conflict.path.slice(1).split('/').map(e => e.replace(/~1/g, '/').replace(/~0/g, '~'));
			let myValue = accessNested(data.edited, path);
			if (typeof myValue === 'object') {
				myValue = JSON.stringify(myValue, null, '    ');
			}
			const myClass = myValue === undefined ? s.removed : myValue === null ? s.disabled : '';

			lines.push(
				<tr class={s.values}>
					<td class={myClass}>{myValue}</td>
					<td class={theirClass}>{newValue}</td>
				</tr>
			);

			lines.push(
				<tr class={s.choices}>
					<td><button onClick={this.choice.bind(this, id, false)}>Keep this</button></td>
					<td><button onClick={this.choice.bind(this, id, true)}>Use this</button></td>
				</tr>
			);

			return lines;
		}, []);

		return (
			<div class={s.wrapper}>
				<table class={s.conflicts}>
					<thead><tr><td>Your changes</td><td>Their changes</td></tr></thead>
					<tbody>{tableContent}</tbody>
				</table>
			</div>
		);
	}

}
