import { h, Component } from 'preact';
import pure from 'utils/pure';
import Store from 'store/store';
import * as actions from 'store/actions';

import { clamp } from 'utils/math';
import { joinClasses } from 'utils/utils';

import MyApps from 'components/shared/infoSidebar/myApps/myApps.js';
import Issues from 'components/shared/infoSidebar/issues/issues.js';

import s from 'components/shared/infoSidebar/infoSidebar.sss';

const STORAGE_SIDEBAR_WIDTH = 'thundr-infosidebar-width';

export default @pure class InfoSidebar extends Component {

	constructor() {
		super();

		this.state = {
			saveStarted: false,
			tab: 'apps'
		};

		if (typeof window !== 'undefined') {
			try {
				let width = parseInt(localStorage.getItem(STORAGE_SIDEBAR_WIDTH), 10);
				if (Number.isFinite(width)) {
					this.state.width = width;
				}
			} catch (e) {}
		}

		this.grabSizer = this.grabSizer.bind(this);
		this.moveSizer = this.moveSizer.bind(this);
		this.releaseSizer = this.releaseSizer.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousemove', this.moveSizer, true);
		document.addEventListener('mouseup', this.releaseSizer, true);
		document.addEventListener('touchmove', this.moveSizer, { passive: false });
		document.addEventListener('touchend', this.releaseSizer);
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.moveSizer, true);
		document.removeEventListener('mouseup', this.releaseSizer, true);
		document.removeEventListener('touchmove', this.moveSizer, { passive: false });
		document.removeEventListener('touchend', this.releaseSizer);
	}

	setActiveView(tab) {
		Store.emit(actions.VALIDATE_MY_APPS);
		this.setState({ tab });
	}

	grabSizer(e) {
		let el = this.base;
		let max = el.parentElement.clientWidth;
		let pos = e.touches?.[0]?.clientX ?? e.clientX;
		this.sizerGrabbed = { offset: el.offsetWidth + pos, min: 1, max };
		this.setState({ sizerGrabbed: true });
	}

	moveSizer(e) {
		if (!this.sizerGrabbed) {
			return;
		}
		e.preventDefault();
		let { offset, min, max } = this.sizerGrabbed;
		let pos = e.touches?.[0]?.clientX ?? e.clientX;
		let width = clamp(offset - pos, min, max);
		let snapAmount = 16;
		let anchors = [min, 300, max];
		anchors.some(anchor => {
			if (Math.abs(width - anchor) < snapAmount) {
				width = anchor;
				return true;
			}
		});
		if (width !== this.state.width) {
			this.setState({ width });
		}
	}

	releaseSizer(e) {
		if (!this.sizerGrabbed) {
			return;
		}
		this.sizerGrabbed = null;
		this.setState({ sizerGrabbed: false });
		if (this.state.width != undefined) {
			try {
				localStorage.setItem(STORAGE_SIDEBAR_WIDTH, this.state.width);
			} catch (e) {}
		}
	}

	renderNav() {
		let apps = this.props.myApps || {};
		let appKeys = Object.keys(apps);

		let errorCount = appKeys.reduce((count, appKey) => {
			const app = apps[appKey];
			if (app.status && app.status.errors) {
				count += app.status.errors.length;
			}
			return count;
		}, 0);

		let tabs = [
			{
				id: 'apps',
				label: 'My Apps',
				count: appKeys.length
			},
			{
				id: 'issues',
				label: 'Issues',
				count: errorCount
			}
		];

		let renderedTabs = tabs.map(tab => {
			let active = this.state.tab === tab.id;
			let className = active ? s.activeNavItem : s.navItem;
			let content = [tab.label];
			if ('count' in tab) {
				content.push(<span class={s.navItemNr}>{tab.count}</span>);
			}
			return (
				<button class={className} onClick={() => this.setActiveView(tab.id)}>
					{content}
				</button>
			);
		});

		return <div class={s.nav}>{renderedTabs}</div>;
	}

	render(props, state) {
		let content;
		switch (state.tab) {
			case 'issues':
				content = <Issues myApps={props.myApps} />;
				break;
			case 'apps':
			default:
				content = (
					<MyApps
						getAppsList={props.getAppsList}
						toggleIssues={() => this.setActiveView('issues')}
						myApps={props.myApps}
						activeLevel={props.activeLevel}
						merged={props.merged}
						getMerged={props.getMerged}
						branch={this.props.branch}
						data={props.data}
						saveData={props.saveData}
						warningLevel={props.warningLevel}
						activeAppMode={props.activeAppMode}
					/>
				);
		}

		return (
			<div class={s.outer} style={{ width: state.width != undefined ? state.width + 'px' : '' }}>
				<div class={joinClasses(s.sizer, state.sizerGrabbed && s.grabbed)} onMouseDown={this.grabSizer} onTouchStart={this.grabSizer} />
				<div class={s.container}>
					{this.renderNav()}
					{content}
				</div>
			</div>
		);
	}
}
