import { h } from 'preact';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import pure from 'utils/pure';
import s from 'components/shared/editor/formEditor/formEditor.sss';


export default @pure class BooleanAttribute extends DisplayAttribute {

	updateAndSave = () => {
		this.setState({ value: !this.state.value });
		this.onSave();
	}

	getInput = () => {
		return (
			<label class={s.checkbox}>
				<input class={s.input} type="checkbox" checked={!!this.state.value} onChange={this.updateAndSave} />
				<div class={s.styledInput}>
					<div class={s.slider} />
				</div>
			</label>
		);
	}
}
