/* global powTracker */
// TODO: class to override more easily, instead of extra file for getGAData

import Store from 'store/store';
import * as actions from 'store/actions';
import { getSize, getDims } from 'utils/clientUtils';
import { isObject, accessNested } from 'utils/utils';
import getGAData from 'utils/getGAData';

let gaEnabled = false;
let gaCategory = '';
let gaPrefixList = [];
let tracked = {};

let isInit = false;
let queue = [];
let gaQueue = [];

/**
 * Call this function to track an action
 * @param  {Object} data
 */
export function track(data) {
	if (typeof window === 'undefined') {
		console.trace('TRACKING SERVER-SIDE');
		return;
	}

	// Edit is enabled, so stop tracking
	if (typeof __INITIAL_STATE__ === 'object' && __INITIAL_STATE__.editable) {
		return;
	}

	if (typeof data === 'string') data = { a: data };
	let {
		a, // action name
		l, // label, used for action value like the theater id that was clicked
		ni, // non-interactive, used to help determine bounce rate though currently not used with the piwik-based tracker
		once, // should the event only be called once
		v, // event value (google analytics only)
		useCustomPageTrack // if true, add potential activePage tracking suffix
	} = data;

	// (temporarily <- :/) separate static from standard
	a = 'static-' + a;

	let suffix = '';
	if (useCustomPageTrack) {
		let activePage = Store.get().activePage;
		if (activePage && activePage.trackingSuffix && activePage.id !== 'showtimes') {
			// true: use the page id as the suffix (e.g. event-name-pageid)
			// string: use this value as the suffix
			suffix = activePage.trackingSuffix === true ? activePage.id : activePage.trackingSuffix;
		}
	}
	if (suffix) {
		a += '-' + suffix;
	}

	let currentTime = new Date().getTime();
	// check if time difference is less than 100ms or if it should only be called once
	if (tracked[a] && (currentTime - tracked[a] < 100 || once)) return;
	// store time of last tracking
	tracked[a] = currentTime;

	// Tracking debuging
	// console.trace();
	// console.debug(`%cTRACK: %c${a} ${typeof l !== 'undefined' ? '[label:' + l + ']' : ''}`, 'color:blue; font-weight: bold;', 'font-style: italic;');

	// Send out event to store for pixels
	try {
		let actionName = a.replace(/^static-/, '').toUpperCase();
		actionName = actionName.replace(/[- ]/g, '_');
		Store.emit('TRACK_' + actionName, data);
	} catch(e) {
		console.log('Error during track event', e);
	}

	// powT.trackEvent('cat', a, l);
	if (isInit && data.isBeacon) {
		beacon(a, l);
		return;
	}
	if (isInit) {
		powTracker.trackEvent(a, l);
	} else {
		queue.push([a, l]);
	}

	// -- GOOGLE ANALYTICS
	if (a.slice(0, 7) === 'static-') {
		// Test before slicing in case we remove "static-" in the future
		a = a.slice(7);
	}
	gaCustom('send', getGAData(a, data, gaCategory));
}

export function gaCustom(eventName) {
	if (gaEnabled) {
		let args = [].slice.call(arguments, 1);
		gaPrefixList.forEach(name => ga.apply(ga, [name + eventName].concat(args)));
	} else {
		// if(!window.gaQueue) window.gaQueue = [];
		// window.gaQueue.push(arguments);
		gaQueue.push(arguments);
	}
}

function beacon(a, n) {
	if (typeof navigator !== 'undefined' && navigator.sendBeacon) {
		let preq = '?e_a=' + encodeURIComponent(a);
		if (n !== undefined) {
			preq += '&e_n=' + encodeURIComponent(n);
		}
		// let data = powT.getRequest(preq);
		// let baseUrl = powT.getTrackerUrl();
		let data = powTracker.getRequest(preq);
		let baseUrl = powTracker.getTrackerUrl() || '//:0';
		if (baseUrl.startsWith('//:0')) return;
		try {
			navigator.sendBeacon(baseUrl + data);
		} catch (e) {
			console.log('failed to send beacon', baseUrl + data);
		}
	}
}

/**
 * Tracking function for going to an external link
 * @param  {String} href
 * @param  {String} trackId
 * @param  {String} label
 */
export function trackLinkOut(href, trackId, label) {
	label = label instanceof Event ? undefined : label;
	Store.emit(actions.EXTERNAL_LINK_CLICKED, { href, trackId, label });
	track({ a: trackId || 'clicked-link-out', l: label || href });
}

function setupGA(id, name, anonymize, ref, activePage, campaign) {
	if (!id) return;
	let namePrefix = name ? name + '.' : '';
	let optsObj = { cookieExpires: 31536000, cookieFlags: 'SameSite=None; Secure' };
	if (name) optsObj.name = name;
	ga('create', id, optsObj);
	if (anonymize) ga(namePrefix + 'set', 'anonymizeIp', true);
	if (ref) ga(namePrefix + 'set', 'referrer', decodeURIComponent(ref));
	if (campaign) ga(namePrefix + 'set', 'campaignName', campaign);
	if (activePage) {
		ga(namePrefix + 'set', 'page', activePage.path + location.search);
	}
	ga(namePrefix + 'send', 'pageview');

	gaPrefixList.push(namePrefix);
}

/**
 * Init piwik tracking
 * @param  {Object} appData - appData coming from thundr
 */
export function initTracker(appData, options) {
	if (isInit) {
		return;
	}
	let disabled = !appData || (appData.options && appData.options.disableTracking);
	if (!disabled) {
		let disabledForDemo = (__IS_DEMO__ || __IS_LOCAL__) && location.search.indexOf('tracking=true') === -1;
		disabled = disabledForDemo || /[?&]toolkit=true(&|$)/.test(location.search);
	}
	if (disabled) {
		return;
	}
	if (typeof powTracker === 'undefined') {
		let trackingScript = document.querySelector('script[src*="tracking.powster.com"]');
		if (trackingScript) {
			trackingScript.onload = initTracker.bind(this, appData, options);
		} else {
			let script = document.createElement('script');
			script.src = 'https://tracking.powster.com/js/tracker.js';
			script.onload = initTracker.bind(this, appData, options);
			document.body.appendChild(script);
		}
		return;
	}
	isInit = true;

	const studio = appData.meta.studio.slug;
	const title = appData.meta.title.slug;
	const region = appData.meta.region.slug;
	const viewMode = getSize(getDims());
	const pageType = 'website';
	const ref = window.customReferrer || document.referrer;

	powTracker.initTracker(
		accessNested(appData, 'apis.tracking.insights.root') + '/t',
		accessNested(appData, 'meta.title.slug'),
		options
	);

	// Make sure the campaign url parameter is always lowercase
	let search = location.search;
	let campaign = search.match(/(?:\?|&)(campaign)=([^&]+)/i);
	let campaignValue = decodeURIComponent((campaign && campaign[2]) || '');
	if (campaign && campaign[1] !== 'campaign') {
		let newSearch = search.replace(/(\?|&)campaign=/i, '$1campaign=');
		let newUrl =
			location.protocol +
			'//' +
			location.host +
			location.pathname +
			newSearch +
			location.hash;
		powTracker.setCustomUrl(newUrl);
	}
	if (window.__IS_FACEBOOK__) {
		powTracker.setCustomUrl(appData.meta.url + '?campaign=fbtab');
		campaignValue = 'fbtab';
	}

	if (ref) {
		// powT.setReferrerUrl(decodeURIComponent(ref));
		powTracker.setReferrerUrl(decodeURIComponent(ref));
	}
	// powT.setCampaignNameKey("campaign");
	// powT.setCustomVariable(1, "studio", studio, "visit");
	// powT.setCustomVariable(2, "movie", title, "visit");
	// powT.setCustomVariable(3, "region", region, "visit");
	// powT.setCustomVariable(4, "page_type", pageType, "visit");
	// powT.setCustomVariable(5, "view_mode", viewMode, "visit");
	// powT.trackPageView();

	// Powster Tracking
	powTracker.setCustomVariable(1, 'studio', studio);
	powTracker.setCustomVariable(2, 'movie', title);
	powTracker.setCustomVariable(3, 'region', region);
	powTracker.setCustomVariable(4, 'page_type', pageType);
	powTracker.setCustomVariable(5, 'view_mode', viewMode);
	// Initial Page view event
	powTracker.trackEvent('visit');

	// powT.trackPageView();
	queue.forEach(ev => {
		// powT.trackEvent(__INITIAL_STATE__.appData.meta.title.slug, ev[0], ev[1])
		powTracker.trackEvent(ev[0], ev[1]);
	});

	// Google analytics ?
	if (
		!appData.options.coppa &&
		appData.options.useGoogleAnalytics &&
		appData.apis.tracking.googleAnalytics
	) {
		let gad = appData.apis.tracking.googleAnalytics;
		let actuallyLoadGA = function() {
			if (gaEnabled) {
				return;
			}
			gaEnabled = true;
			(
				function(p, o, w, s, t, e, r) {
					p['GoogleAnalyticsObject'] = t;
					(p[t] = p[t] || function() { (p[t].q = p[t].q || []).push(arguments); }),
					(p[t].l = 1 * new Date());
					(e = o.createElement(w)), (r = o.getElementsByTagName(w)[0]);
					e.async = 1; e.src = s;
					r.parentNode.insertBefore(e, r);
				}
			)(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

			// TODO: this as a param ?
			let activePage = Store.get().activePage;
			let anonymize = true;

			// setupGA(gad.global);
			// setupGA(gad.showtimes, 'showtimes');
			// setupGA(gad.local, 'local');
			Object.keys(gad).forEach(name => {
				let id = gad[name];
				if (typeof id !== 'string' || id.slice(0, 3) !== 'UA-') {
					return;
				}
				setupGA(
					id,
					name === 'global' ? null : name,
					anonymize,
					ref,
					activePage,
					campaignValue
				);
			});
			gaCategory = gad.category;
			gaQueue.forEach(gaEv => {
				if (gaEv[1] && gaEv[1].eventCategory === '') {
					gaEv[1].eventCategory = gaCategory;
				}
				gaCustom.apply(window, gaEv);
			});
		};
		if (gad.oneTrustCategory) {
			if (window.powsterOneTrust && window.powsterOneTrust.indexOf(gad.oneTrustCategory) !== -1) {
				actuallyLoadGA();
			}
			window.addEventListener('categoriesUpdated', (evt) => {
				if (evt.detail.categories.indexOf(gad.oneTrustCategory.toUpperCase()) !== -1) {
					actuallyLoadGA();
				}
			});
		} else {
			actuallyLoadGA();
		}
	}

	track({ a: 'page_is', ni: 1 });
}

export function updateDataLayer(obj) {
	// Nothing to do here if there is no dataLayer
	if (!window.dataLayer || typeof dataLayer.push !== 'function' || !window.googleTagManagerActive) {
		return;
	}

	dataLayer.push(obj);
}

/**
 * Update the data layer with info about the current screening
 * @param  {Object} screening
 * @param  {Object} theater
 * @param  {string} event
 * @param  {string} href
 * @param  {string} provider
 */
export function updateDataLayerScreening(
	screening,
	theater,
	event,
	href,
	cta,
	provider
) {
	if (!screening) return;
	let obj = {};
	if (event) {
		obj.event = event;
	}
	if (href) {
		obj.href = href;
	}
	if (screening.movie && screening.movie.title) {
		const studio = accessNested(Store.get(), 'appData.meta.studio.slug');
		// client request to use titleName
		if (studio.includes('lionsgate')) {
			obj.titleName = screening.movie.title;
		} else {
			obj.filmTitle = screening.movie.title;
		}
	}
	if (theater) {
		if (isObject(theater.location)) {
			if (typeof theater.location.toJS === 'function') {
				obj.location = theater.location.toJS();
			} else {
				obj.location = theater.location;
			}
		}
		obj.theater = theater.name;
		if (theater.exhibitor) {
			obj.exhibitor = theater.exhibitor.domain;
		}
	}
	if (screening.stDate) {
		obj.day = screening.stDate.ddmmyyyy;
	}
	if (screening.time24) {
		obj.time = screening.time24;
	}

	obj.cta = cta || undefined;
	// NOTE: ^ Clients want undefined when cta not present

	if (provider) {
		obj.provider = provider;
	}

	updateDataLayer(obj);
}

/**
 * Update the data layer when the page changes
 * @param  {String} pageId
 * @param  {Boolean} firstLoad
 */
export function updateDataLayerPageView(pageId, firstLoad) {
	// Let's send a different event depending if it's the initial load
	// or just a page change
	updateDataLayer({
		event: firstLoad ? 'page_load' : 'page_change',
		page: pageId
	});
}
