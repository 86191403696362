import { h } from 'preact';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import { accessNested } from 'utils/utils';
import CodeEditor from 'components/shared/editor/codeEditor/codeEditor';
// import suggestions from 'constants/autoCompleteSuggestions';

import s from 'components/shared/editor/formEditor/formEditor.sss';

export default class CodeAttribute extends DisplayAttribute {
	constructor(props) {
		super(props);
		this.inputFullWidth = true;
		this.updateAndSave = this.updateAndSave.bind(this);
	}

	updateAndSave(value) {
		this.setState({ value });
		this.onSave();
	}

	getSchema(jsonSchema) {
		const attrName = this.props.attributeName;
		let schema = jsonSchema;
		const inheritedSuggestions = this.props.activePath.split('.').filter(e => e).reduce((acc, p, i, arr) => {
			let path = arr.slice(0, -i).join('.properties.');
			const item = accessNested(this.props.baseJSONSchema.properties, path, '');
			if (!item || !item.$children || !item.$children.properties) {
				return acc;
			}

			return item.$children.properties.reduce((acc, k) => {
				Object.keys(k).forEach(key => {
					acc.keys.push(key);
					acc.keyPaths[key] = path;
				});
				return acc;
			}, acc);
		}, {
			keys: [],
			keyPaths: {}
		});

		let keyPath = inheritedSuggestions.keyPaths[attrName];
		const inheritedProps = accessNested(this.props.baseJSONSchema, `properties.${keyPath}.$children.properties`) || [];
		let inheritedKey;

		inheritedProps.map( (p, i) => {
			if (Object.prototype.hasOwnProperty.call(inheritedProps[i], attrName)) {
				inheritedKey = inheritedProps[i][attrName];
			}
		});

		schema = inheritedKey || schema || {};

		if (schema.$ref) {
			schema = Object.assign({}, this.props.baseJSONSchema.definitions[schema.$ref.split('/').pop()]);
		}
		return schema;
	}

	getInput(sentValue, description, jsonSchema) {
		let value = this.state.value + '';

		let schema = this.getSchema(jsonSchema);

		// let placeholder = schema.placeholder || description;
		let language = schema.language || 'html';
		if (language === 'html-or-javascript') {
			language = /^\s*</.test(value) ? 'html' : 'javascript';
		}
		return (
			<div class={s.wrapper}>
				<CodeEditor class={s.editor} value={value} language={language} onChange={this.updateAndSave} hideMinimap />
			</div>
		);
	}
}
