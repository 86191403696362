import { h, Component } from 'preact';
import * as jsonpatch from 'fast-json-patch';
import defaultsDeep from '@nodeutils/defaults-deep';
import Store from 'store/store';
import * as actions from 'store/actions';
import { updateAppData } from 'services/dataApi';
import { joinClasses } from 'utils/utils';

import { goToPage } from 'components/core/link/link';
import DiffList from 'components/pages/apps/diffList';
import CodeEditor from 'components/shared/editor/codeEditor/codeEditor';

import s from 'components/shared/modal/mergeBranchModal.sss';

export default class MergeBranchModal extends Component {

	constructor(props) {
		super(props);

		const { data, branch, getMerged } = props;
		const { mergedFull: mergedBase } = getMerged(data);
		const { mergedFull: mergedBranch } = getMerged(data, branch.id);
		const newData = defaultsDeep(structuredClone(branch.attributes), structuredClone(data.attributes));
		const displayedBase = structuredClone(data.attributes);
		const displayedNew = structuredClone(newData);
		try {
			delete displayedBase.dev.branches;
			delete displayedNew.dev.branches;
			delete newData.dev.branches[branch.id];
		} catch (e) { /**/ }
		this.state = {
			mode: 'list',
			newData,
			displayedBase,
			displayedNew,
			mergedDiff: jsonpatch.compare(mergedBase, mergedBranch)
		};

		this.changeMode = this.changeMode.bind(this);
		this.close = this.close.bind(this);
		this.completeMerge = this.completeMerge.bind(this);
	}

	changeMode(e) {
		this.setState({ mode: e.currentTarget.value });
	}

	close() {
		Store.emit(actions.HIDE_MODAL);
	}

	async completeMerge() {
		if (this.state.merging) {
			return;
		}
		this.setState({ merging: true });
		try {
			const data = this.props.data;
			let updated = await updateAppData(data.id, data.type, { attributes: this.state.newData }, data);
			Store.emit(actions.UPDATE_APP_DATA, updated);
			Store.emit(actions.VALIDATE_MY_APPS);
			Store.emit(actions.HIDE_MODAL);
			goToPage('editApp', { branch: null });
		} finally {
			this.setState({ merging: false });
		}
	}

	renderModeCheckbox(mode, name) {
		let checked = this.state.mode === mode;
		return (
			<label key={mode}>
				<input type="radio" name="mode" value={mode} checked={checked} onChange={this.changeMode} />
				{name}
			</label>
		);
	}

	render({ branch }, { mode, displayedBase, displayedNew, mergedDiff, merging }) {
		return (
			<div class={s.container}>
				<div class={s.title}>
					Are you sure you want to merge <strong>{branch.name}</strong> into <strong>Production</strong>?
				</div>
				<div class={s.mode}>
					{this.renderModeCheckbox('content', 'Branch Content')}
					{this.renderModeCheckbox('full', 'Full Diff')}
					{this.renderModeCheckbox('list', 'Changes List')}
				</div>
				<div class={joinClasses(s.display, s['mode-' + mode])}>
					<CodeEditor
						class={s.diffFull}
						// value={this.state.mergedBranch}
						// diffWith={this.state.mergedBase}
						value={displayedNew}
						diffWith={displayedBase}
						readOnly
						dark
						key="full"
					/>
					<CodeEditor
						class={s.diffContent}
						value={branch.attributes}
						readOnly
						dark
						key="content"
					/>
					<DiffList class={s.diffList} changes={mergedDiff} key="list" />
				</div>
				<div class={s.buttons}>
					<button class={joinClasses(s.confirm, merging && s.loading)} onClick={this.completeMerge} disabled={merging}>Merge and delete branch</button>
					<button onClick={this.close}>Cancel</button>
				</div>
			</div>
		);
	}

}
