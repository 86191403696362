export * from 'store/actions.js';

// MY APPS
export const SET_ACTIVE_APP_MODE = 'SET_ACTIVE_APP_MODE';
export const ADD_MY_APPS = 'ADD_MY_APPS';
export const CLEAR_MY_APPS = 'CLEAR_MY_APPS';
export const UPDATE_MY_APP = 'UPDATE_MY_APP';
export const REMOVE_MY_APP = 'REMOVE_MY_APP';
export const UPDATE_MY_APP_STATUS = 'UPDATE_MY_APP_STATUS';
export const VALIDATE_MY_APPS = 'VALIDATE_MY_APPS';

// UPDATES
// export const UPDATE_SESSION_COUNTS = 'UPDATE_SESSION_COUNTS';

// CONTEXT MENU
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';

// USER LIST
export const SET_USERS = 'SET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

// WATCHING APPS
export const SET_WATCHED_APP = 'SET_WATCHED_APP';
export const UPDATE_EDITED_APP_DATA = 'UPDATE_EDITED_APP_DATA';
export const NEW_TITLE = 'NEW_TITLE';
export const NEW_TITLES = 'NEW_TITLES';
export const NEW_STUDIO = 'NEW_STUDIO';
export const NEW_APP = 'NEW_APP';
export const REMOVE_APP = 'REMOVE_APP';
export const UPDATE_APP_DATA = 'UPDATE_APP_DATA';
export const UPDATE_APP_DATA_ATTRIBUTES = 'UPDATE_APP_DATA_ATTRIBUTES';
export const UPDATE_PROVIDER_TITLES = 'UPDATE_PROVIDER_TITLES';
export const ATTACH_MOVIE_MASTER_ID = 'ATTACH_MOVIE_MASTER_ID';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

// GETTING MOVIE DATA
export const GET_MOVIE_ID_INFO = 'GET_MOVIE_ID_INFO';
