import Store from 'store/store';
import { arrayUnique, commaSeparatedList } from 'utils/utils';
import { getMovieInfo } from 'services/serviceShowtimesApi';

const requestedMovieIds = new Set();
export async function fetchMovieDataIfNecessary(ids) {
	if (!Array.isArray(ids)) {
		ids = arrayUnique(commaSeparatedList(ids));
	}
	ids = ids.filter(e => !requestedMovieIds.has(e));
	if (!ids.length) {
		return;
	}
	ids.forEach(e => requestedMovieIds.add(e));
	Store.get().movieIdInfo.set(Object.fromEntries(ids.map(id => [id, 'loading'])));

	let movieInfo;
	try {
		movieInfo = await getMovieInfo(ids);
	} catch (error) {
		console.log('Error getting movie info', error);
	}
	const data = movieInfo?.response;
	if (!data) {
		Store.get().movieIdInfo.set(Object.fromEntries(ids.map(id => [id, 'error'])));
		return;
	}
	Store.get().movieIdInfo.set(data);
}