import { h, Component } from 'preact';
import s from './runImporter.sss';
import Selector from 'components/shared/form/selector';

import { getProvidersList } from 'services/dataApi';

const specialProviders = {
	pow: false,
	fox: false,
	grace: 'sync.js',
	wwm: 'sync.js',
	webuk: 'sync.js',
	webus: 'sync.js',
	// Until we do it better. Needs to be index.js with a sheet id as an arg
	maccs: false,
};

export default class RunImporter extends Component {
	constructor() {
		super();
		this.state = {
			providers: []
		};
		this.runScript = this.runScript.bind(this);

		getProvidersList().then(response => {
			this.setState({ providers: response.map(e => e.id).filter(id => specialProviders[id] !== false).sort() });
		});
	}

	runScript() {
		let provider = this.$provider.getValue();
		if (!provider) return;
		let file = 'all.js';
		let specialCase = specialProviders[provider];
		// False: shouldn't be manually triggerable (should not get to this function anyway as these providers are filtered out earlier)
		if (specialCase === false) {
			return;
		}
		if (specialCase && typeof specialCase === 'string') {
			file = specialCase;
		}
		let instructions = {
			command: 'node',
			args: [
				'./importers/' + provider + '/' + file
			],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		};
		this.setState({ running: true });
		this.props.runScript({ instructions }).then(() => this.setState({ running: false }));
	}

	render(props, state) {
		let btnDisabled = state.running;

		return (
			<div class={s.wrapper}>
				<div class={s.info}>Note: please be very careful while using this. Do not spam imports. If you are unsure if something is in the db or not yet, prefer asking a dev to running an import.</div>
				<label>
					<div class={s.description}>Provider</div>
					<Selector ref={e => (this.$provider = e)} items={state.providers} selectMessage="-- Select a provider --" />
				</label>
				<button onClick={this.runScript} disabled={btnDisabled}>Gas, gas, gas 🏎️</button>
			</div>
		);
	}
}
