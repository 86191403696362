import { h } from 'preact';
import Page from 'components/pages/page.js';
import { accessNested } from 'utils/utils';
import panels from 'components/pages/scripts/panels';
import * as scriptApi from 'services/scriptApi';

import s from 'components/pages/scripts/scripts.sss';

export default class Scripts extends Page {

	constructor() {
		super();

		this.updateResult = this.updateResult.bind(this);
		this.state = {
			script: '',
			result: null,
			resultFull: true
		};
	}

	onChangeScript = (event) => {
		this.setState({ script: event.target.value });
	}

	componentWillUpdate() {
		this.scrollLocked = false;
		if (this.$results) {
			let top = this.$results.scrollTop;
			let max = this.$results.scrollHeight - this.$results.clientHeight;
			this.scrollLocked = top >= max - 10;
		}
	}

	componentDidUpdate() {
		if (this.scrollLocked && this.$results) {
			this.$results.scrollTop = this.$results.scrollHeight;
		}
	}

	async updateResult() {
		let scriptId = this.currentScriptRunnerId;
		try {
			let data = await scriptApi.getScriptResult(scriptId, accessNested(this, 'currentScript.options'));
			if (scriptId !== this.currentScriptRunnerId) {
				return;
			}
			if (data.error) {
				throw data;
			}
			let result = data.current;
			if (typeof this.currentScript.formatResponse === 'function') {
				result = this.currentScript.formatResponse(result, data);
			}
			if (result !== this.state.result) {
				this.setState({ result: result });
			}
			if (!data.finished) {
				clearTimeout(this.updateTimer);
				this.updateTimer = setTimeout(this.updateResult, 3000);
			} else {
				this.setState({ resultFull: true });
			}
		} catch (e) {
			console.log('Script error', e);
			if (scriptId === this.currentScriptRunnerId) {
				this.setState({ result: this.state.result + '\n[An error occurred while getting the rest of the result]', resultFull: true });
			}
		}
	}

	runScript = async (script, persistent = true) => {
		this.setState({ loading: true, result: '', resultFull: false });
		try {
			let identifier = this.currentScriptIdentifier = {};
			let response = await scriptApi.runScript(script.instructions, persistent, script.options);
			if (this.currentScriptIdentifier !== identifier) {
				return;
			}

			if (typeof response !== 'object') {
				response = { current: response, finished: true };
			}
			if (response.error) {
				throw response;
			}
			let msg = response.current;
			this.currentScript = script;
			if (typeof script.formatResponse === 'function') {
				msg = script.formatResponse(msg, response);
			}
			this.setState({ result: msg, loading: false, resultFull: response.finished });
			if (response.id && !response.finished) {
				this.currentScriptRunnerId = response.id;
				clearTimeout(this.updateTimer);
				this.updateTimer = setTimeout(this.updateResult, 3000);
			}
		} catch (e) {
			console.log(e);
			let message = 'Unknown error';
			if (e) {
				message = e.message || e.error || e;
			}
			this.setState({ loading: false, result: 'An error occurred while running the script: ' + message, resultFull: true });
		}
	}

	conditionallyDisplayResult() {
		if (!this.state.result && this.state.resultFull) {
			return;
		}
		let more;
		if (!this.state.resultFull) {
			more = (
				<div class={s.more}>
					<div class={s.dot} />
					<div class={s.dot} />
					<div class={s.dot} />
				</div>
			);
		}
		return (
			<div class={s.resultBox} ref={e => this.$results = e} key="results">
				{this.state.result}
				{more}
			</div>
		);
	}

	displayScriptControlPanel() {
		let panel = panels.find(panel => panel.id === this.state.script);
		if (!panel) {
			return;
		}
		return panel.controlPanel(this.runScript, panel, this.props);
	}

	render() {
		let opts = panels.map(panel => {
			if (!panel?.id || panel.hidden) {
				return;
			}
			return <option value={panel.id} key={panel.id}>{panel.description}</option>;
		});
		return (
			<div class={s.contentBox}>
				<h1>Run a script</h1>

				<div class={s.selectScriptBox}>
					<select name="selectScript" onChange={this.onChangeScript} value={this.state.script}>
						<option value="">What do you want to do today?</option>
						{opts}
					</select>
				</div>
				{this.displayScriptControlPanel()}
				{this.conditionallyDisplayResult()}
			</div>
		);
	}
}

export const dependencies = {
	apps: true,
	titles: true,
	studios: true,
	studioRegions: true,
	regions: true,
	bases: true,
};
