export * from 'utils/handleApi.js';

const jsonpatch = require('fast-json-patch');

function generatePATCHApiData(data, original, id) {
	let body = { id: id || data.id };

	if (original) {
		body.changes = jsonpatch.compare(original.attributes, data.attributes);
	} else {
		// body.data.attributes = data.attributes;
		// console.warn('Original data has not been supplied with which to create a diff. Overwriting all attributes.');

		// No non-diff alternative at the moment
		throw new Error('Original data has not been supplied with which to create a diff');
	}

	return body;
}

function generatePOSTApiData(data) {
	return {
		attributes: data.attributes,
		rel: data.rel
	};
}

export {
	generatePATCHApiData,
	generatePOSTApiData
};
