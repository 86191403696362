import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import * as keyboardShortcuts from 'utils/keyboardShortcuts';
import { joinClasses } from 'utils/utils';

import s from 'components/shared/modal/keyboardShortcuts.sss';

const isMac = typeof navigator !== 'undefined' && navigator.userAgent.includes('Mac OS X');
const keyNames = {
	ctrl: isMac ? 'Cmd' : 'Ctrl',
	alt: isMac ? 'Option' : 'Alt',
	shift: 'Shift',
};

export default class KeyboardShortcutsHelper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			chord: '',
			namedShortcuts: Object.entries(keyboardShortcuts.getAll()).filter(([, handlers]) => handlers.some(handler => handler.name)),
		};

		this.updateDisplay = this.updateDisplay.bind(this);
	}

	componentDidMount() {
		this.shortcutsHandler = keyboardShortcuts.register({
			[keyboardShortcuts.CATCH_ALL]: {
				action: this.updateDisplay,
				priority: 999,
				ignoreChords: true,
				allowDefaultHandling: this.shouldAllowDefaultKeyEvent
			},
		});
	}

	componentWillUnmount() {
		this.shortcutsHandler?.stop();
	}

	shouldAllowDefaultKeyEvent(e) {
		return e.key === 'Tab' || e.key === 'Enter' || e.key === ' ';
	}

	updateDisplay(mapping, e, { chord, waitingForChord }) {
		this.setState({ chord, waitingForChord });
		return keyboardShortcuts.STOP_PROCESSING;
	}

	closeModal() {
		Store.emit(actions.HIDE_MODAL);
	}

	renderChord(chord) {
		if (!chord) {
			return;
		}
		// Split chord per keybinding (e.g. ctrl+a,b -> [ctrl+a, b])
		return [...chord.matchAll(/((?:.+?)(?:\+.+?)*)(?:,|$)/g)].flatMap(([, keybinding]) => {
			// Split keybinding into keys (e.g. ctrl+a -> [ctrl, a])
			return [...keybinding.matchAll(/(.+?)(?:\+|$)/g)].flatMap(([, key], i) => [
				!!i && <span class={s.keybindingSep}>+</span>,
				<kbd>{keyNames[key] || key}</kbd>,
			]);
		}).filter(e => e);
	}

	render() {
		return (
			<div class={s.container}>
				<output
					class={joinClasses(s.chord, this.state.waitingForChord && s.waiting)}
				>
					{this.renderChord(this.state.chord)}
				</output>
				<p class={s.listDescription}>Named shortcuts currently available:</p>
				<dl class={s.list}>
					{this.state.namedShortcuts.map(([chord, handlers]) => (
						<div class={chord === this.state.chord && s.active}>
							<dt>{this.renderChord(chord)}</dt>
							{[...handlers.map(handler => <dd>{handler.name}</dd>)]}
						</div>
					))}
				</dl>
				<div class={s.buttons}>
					<button onClick={this.closeModal}>Ok</button>
				</div>
			</div>
		);

	}
}