import { h } from 'preact';
import moment from 'moment';
import identity from 'lodash/identity';
import uniqBy from 'lodash/uniqBy';
import Page from 'components/pages/page';
import { getHealthCheck } from 'services/dataApi';
import { joinClasses } from 'utils/utils';
import s from 'components/pages/health/health.sss';

// TODO just redo the whole damn thing
// (probably reuse the table component used by the thundr screenings page)

const importRow = ({ provider, status, started, completed, duration }) => {
	let durSecs = duration || moment().diff(started, 's');
	let durString = moment.duration(durSecs, 's').humanize();
	return (
		<tr class={s.tr}>
			<td class={s.td}>{provider}</td>
			<td class={s.td}>{status.replace(/_/g, ' ')}</td>
			<td class={joinClasses(s.td, s.datetime)}>{started.format('DD/MM/YYYY HH:mm')}</td>
			<td class={joinClasses(s.td, s.datetime)}>{completed && completed.format('DD/MM/YYYY HH:mm')}</td>
			<td class={s.td} title={durSecs + ' seconds'}>{duration ? durString : 'Incomplete (currently '+durString+')'}</td>
		</tr>
	);
};

const cleanseRow = raw => {
	const started = moment(raw.created_at);

	const completed = raw.name !== 'import_started' && moment(raw.modified_at);
	const duration = completed && completed.diff(started, 'seconds');

	return {
		provider: raw.provider_id,
		status: raw.name,
		started,
		completed,
		duration,
	};
};


const applySort = (imports, sortProp, reverse) => {
	if (!sortProp) {
		return imports;
	}
	let strCmp = (a, b) => (a + '').localeCompare(b);
	let now = moment();
	let dateCmp = (a, b) => (a || now) - (b || now);

	let cmp = sortProp === 'completed' || sortProp === 'started' ? dateCmp : strCmp;
	return imports.slice().sort((a, b) => cmp(a[sortProp], b[sortProp]) * (reverse ? -1 : 1) || dateCmp(a.started, b.started));
};

const getFilterFn = (filterType) => {
	if (filterType === 'latest_unique_provider') {
		return data => uniqBy(applySort(data, 'started', true), 'provider');
	}
	return identity;
};

const sortImports = ({ imports, sortProp, reverse, filterType }) => {
	const filterFn = getFilterFn(filterType);
	const filteredImports = filterFn(imports);
	const sortedImports = applySort(filteredImports, sortProp);

	return reverse ? sortedImports.reverse() : sortedImports;
};

export default class Health extends Page {
	constructor() {
		super();
		this.state = {
			filterType: 'latest_unique_provider',
			imports: [],
			sortProp: 'started',
			reverse: true
		};
	}

	componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}
		getHealthCheck().then(data => data.map(cleanseRow)).then(this.setImports);
	}

	setImports = (imports) => this.setState({ imports })

	sortBy = (sortProp) => (e) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		const reverse = this.state.sortProp === sortProp && !this.state.reverse;
		this.setState({ sortProp, reverse });
	};

	getRows = () => sortImports(this.state);

	handleFilterTypeChange = event => this.setState({ filterType: event.target.value })

	render() {
		return (
			<div class={s.contentBox}>
				<h1 class={s.h1}>Latest Imports</h1>
				<div class={s.filterPanel}>
					Show:
					<select value={this.state.filterType} onChange={this.handleFilterTypeChange}>
						<option value="latest_unique_provider">Latest imports (unique provider)</option>
						<option value="all">All imports</option>
					</select>
				</div>

				<table>
					<tr>
						<th><button onClick={this.sortBy('provider')}>Provider</button></th>
						<th><button onClick={this.sortBy('status')}>Status</button></th>
						<th><button onClick={this.sortBy('started')}>Started at</button></th>
						<th><button onClick={this.sortBy('completed')}>Completed at</button></th>
						<th><button onClick={this.sortBy('completed')}>Build duration</button></th>
					</tr>
					{this.getRows().map(importRow)}
				</table>
			</div>
		);
	}
}
