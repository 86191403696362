import { h, Component } from 'preact';
import bbsReader from 'bbs-reader'; // convert ansi to html
// import Store from 'store/store';
import pure from 'utils/pure';
import { accessNested } from 'utils/utils';

import s from 'components/shared/modal/errorModal.sss';

export default @pure class ErrorModal extends Component {

	constructor() {
		super();
		this.sendToSlack = this.sendToSlack.bind(this);
	}

	componentDidMount() {
		if (this.$helpButton) {
			this.$helpButton.disabled = false;
			this.$helpButton.classList.remove(s.sent);
		}
	}

	async pull(data) {
		this.setState({ pulling: true });
		try {
			const res = await fetch('/misc/pull');
			if (!res.ok) throw new Error('Error pulling');
			data?.callback?.();
		} finally {
			this.setState({ pulling: false });
		}
	}

	async sendToSlack(e) {
		if (e) {
			e.target.disabled = true;
		}
		let channel = this.$helpChannel && this.$helpChannel.value;
		let isGlobal = !channel || channel === '#showtimes-dev';

		// let store = Store.get();
		let user = window.user.name;
		let app = this.props.app;
		let error = this.props.error.split('\n');
		let titleLine = error.shift().split(': ');
		let title = titleLine.slice(0, 2).join(': ');
		let titleRemaining = titleLine.slice(2).join(': ');
		if (titleRemaining) {
			titleRemaining += '\n';
		}
		let body = titleRemaining + error.join('\n');

		let mention = !isGlobal ? '' : ' <!subteam^S0GKZKR0F|dev>';
		let message = 'Hey' + mention + ', ' + user + ' needs help';
		if (app) {
			let appUrl = 'http://localhost:4010/apps/edit/?app=' + accessNested(app, 'meta.id');
			let appName = accessNested(app, 'meta.title.slug') + ' ' + accessNested(app, 'meta.region.slug').toUpperCase();
			let appLink = '<' + appUrl + '|' + appName + '>';
			message += ' on ' + appLink;
		}

		// Remove ANSI coloring
		// eslint-disable-next-line no-control-regex
		body = body.replace(/\u001b\[[0-9;]+m/g, '');
		let lines = body.split('\n');
		let truncated = lines.length > 50;
		body = lines.slice(0, 50).join('\n');
		// Truncate
		if (body.length >= 1800) {
			truncated = true;
			body = body.slice(0, 1800);
		}
		if (truncated) body = body + '\n...';
		if (body.trim()) body = '```\n' + body + '\n```';

		let attachment = {
			fallback: title,
			color: 'danger',
			fields: [{ title: title, value: body, short: false }],
			mrkdwn_in: ['fields']
		};

		let messageData = {
			text: message,
			attachments: [attachment]
		};
		if (channel) {
			messageData.channel = channel;
		}

		try {
			let res = await fetch('/misc/cry_for_help', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(messageData)
			});
			if (!res.ok) {
				throw new Error(`Error sending help request (${res.status})`);
			}
			e?.target?.classList?.add(s.sent);
		} catch (e) {
			// eslint-disable-next-line no-alert
			alert('An error occured while sending your help request. Please ask manually');
			if (e) {
				e.target.disabled = false;
			}
		}
	}

	render(props, state) {
		let {
			error,
			extras,
			showHelpRequest
		} = props;
		if (typeof error !== 'string') error = 'Undefined error';

		// bbsReader cannot parse color code 90 so change it to 38
		// eslint-disable-next-line no-control-regex
		let errs = error.replace(/\u001b\[90m/g, '\u001b[38m').split('\n');
		const title = errs.shift().split(':');

		// Is this the best way to check if it's a webpack/termin coded error?
		// (no)
		if (error.includes('\u001b')) {
			const content = [title.slice(2).join(':').trim()].concat(errs).filter(e => e).join('\n');
			errs = <div class={s.term} dangerouslySetInnerHTML={{ __html: bbsReader(content).html }} />;
		} else {
			let titleRemaining = title.slice(2).join(':').trim();
			errs = [
				!!titleRemaining && <div class={s.line}>{titleRemaining}</div>
			].concat(errs.map((err) => {
				// Really hacky, but for now lets just check if it is a normal error or not
				if (!err.includes(' at ')) {
					return <div class={s.line}>{err}</div>;
				}

				// It's a normal stacktrace, so let's format it
				const [, , functionName = '', location = ''] = err.split(/\s+/);
				const [filePath, lineNumber] = location.replace(/[()?]|webpack:\/\//g, '').split(':');

				return (
					<div class={s.stack}>
						<p><span class={s.fileName}>{filePath.split('/').pop()}:{lineNumber}</span> {functionName}</p>
						<p class={s.filePath}>{filePath}</p>
					</div>
				);
			}));
		}

		const extraContent = extras?.map(extra => {
			if (extra?.type === 'pull-button') {
				return (
					<div class={s.line}><button type="button" class={s.pull} onClick={() => this.pull(extra)} disabled={state.pulling}>Pull now</button></div>
				);
			}
		});

		let helpButton;
		if (showHelpRequest) {
			const channelSelector = (
				<select ref={e => this.$helpChannel = e} class={s.helpChannel}>
					<option value="">#showtimes-dev</option>
					{[
						'@anthony',
						'@ash',
						'@ben',
						'@chris',
						'@christian',
						'@daniel',
						'@dom',
						'@joy',
						'@matthew',
						'@vaclav',
					].map(e => <option value={e}>{e}</option>)}
				</select>
			);
			helpButton = (
				<div class={s.help}>
					<button ref={e => this.$helpButton = e} class={s.helpButton} onClick={this.sendToSlack}>Cry for help</button>
					<span class={s.channelCTA}>(in {channelSelector})</span>
				</div>
			);
		}

		// TODO: let's prettify the error
		return (
			<div class={s.container}>
				<div class={s.title}>
					<div class={s.mainTitle}>{title[0]}</div>
					<div class={s.subTitle}>{title[1]}</div>
				</div>
				{errs}
				{extraContent}
				{helpButton}
			</div>
		);
	}

}
