import { h, Component } from 'preact';
import s from 'components/pages/apps/wizards/basicStartWizard.sss';
import Store from 'store/store';
import * as actions from 'store/actions';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
// import clone from 'lodash/clone';
// import _set from 'lodash/set';
import Selector from 'components/shared/form/selector';
import FormGenerator from 'components/shared/formGenerator/formGenerator';
import { YoutubeVideo } from 'components/shared/modal/addNewCustomEdit/customHelpers.js';
import { getGoogleSpreadsheet } from 'services/buildApi.js';
// import {
// 	getMatchingImagePath,
// 	getImageDirectoryValues
// } from 'components/pages/apps/wizards/imageMatcher';
import {
	accessNested,
	joinClasses,
	parseDate
} from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import schema from 'schema/schema';

const DELETE = Symbol('delete');
function setNested(obj, path, value) {
	if (!obj) {
		return obj;
	}
	if (typeof path === 'string') {
		path = path ? path.split('.') : [];
	}
	let len = path.length;
	if (!len) return obj;
	for (let i = 0; i < len; i++) {
		let prop = path[i];
		if (i !== len - 1) {
			if (!obj[prop] || typeof obj[prop] !== 'object') {
				obj[prop] = !Number.isNaN(+path[i + 1]) ? [] : {};
			}
			obj = obj[prop];
		} else {
			if (value === DELETE) {
				delete obj[prop];
			} else {
				obj[prop] = value;
			}
		}
	}
}

const findInListInput = (string, input) => {
	const list = input.split(/[\s,|↵-]+/).map(s => s.trim());
	return list.find(el => el.includes(string));
};
const findFacebook = input => findInListInput('facebook', input);
const findTwitter = input => findInListInput('twitter', input);
const findInstagram = input => findInListInput('instagram', input);

export default class BasicStartWizard extends Component {

	constructor(props) {
		super(props);

		this.selectClientFormData = this.selectClientFormData.bind(this);
		this.applyClientFormData = this.applyClientFormData.bind(this);
		this.storeSheetData = this.storeSheetData.bind(this);
		this.cancelEdit = this.cancelEdit.bind(this);
		this.ytAssist = this.ytAssist.bind(this);
		this.linkYtId = this.linkYtId.bind(this);
		this.nextSection = this.changeSection.bind(this, 1);
		this.prevSection = this.changeSection.bind(this, -1);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.saveData = this.saveData.bind(this);

		this.editedData = {};

		this.state = { activeForm: 0 };
	}

	componentWillMount() {
		let app = this.props.app || {};

		const formData = this.getFormDataTemplate(this.props);
		this.editedData = cloneDeep(app.edited || app.attributes);

		if (this.props.sheetId) {
			this.getClientResponseSheet(this.props.sheetId);
		}

		this.setState({ formData });
	}

	componentWillReceiveProps(newProps) {
		let stateUpdate = {};

		if (newProps.sheetId !== this.props.sheetId) {
			if (newProps.sheetId) {
				this.getClientResponseSheet(newProps.sheetId);
			} else {
				stateUpdate.sheetData = null;
			}
		}

		if (newProps.app !== this.props.app) {
			stateUpdate.formData = this.getFormDataTemplate(newProps);
			stateUpdate.selectedClientFormData = null;
		}

		if (Object.keys(stateUpdate).length) {
			this.setState(stateUpdate);
		}
	}

	////////////////////////////////////////////////////////////////////
	// UTILS ///////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////

	getClientResponseSheet(sheetId) {
		this.setState({ sheetLoading: true });
		getGoogleSpreadsheet(sheetId).then(this.storeSheetData).catch(error => {
			console.error(error);
			this.setState({ sheetError: error, sheetLoading: false });
		});
	}

	storeSheetData(data) {
		if (data.statusCode >= 400) {
			return this.setState({ sheetLoading: false, sheetError: data && data.message });
		}
		const sheetData = data.map(fd => {
			Object.keys(fd).forEach(header => {
				let newHeader = header.toLowerCase().replace(/[^a-z0-9]+/g, '');
				if (!fd[newHeader]) {
					fd[newHeader] = fd[header];
				}
			});
			fd.id = fd.entermoviename + ', ' + fd.platformregions;
			return fd;
		});
		this.setState({ sheetLoading: false, sheetData });
	}

	selectClientFormData(selectedClientFormData) {
		this.setState({ selectedClientFormData });
	}

	applyClientFormData() {
		const {
			formData,
			selectedClientFormData
		} = this.state;

		const clientFormData = selectedClientFormData;
		if (!clientFormData) {
			return;
		}
		const newFormData = formData.slice();
		formData.forEach(section => {
			section.fields.forEach(field => {
				if (!field.googleSheetValue) {
					return;
				}
				let value = clientFormData[field.googleSheetValue];
				if (field.type === 'date') {
					const date = parseDate(value);
					value = date && formatDateTime(date, 'yyyy-MM-dd');
				}
				if (typeof field.parse === 'function') {
					value = field.parse(value);
				}
				field.value = value;
			});
		});

		this.setState({ formData: newFormData, sheetLoaded: true });
	}

	cancelEdit() {
		let modalButtonClick = e => Store.emit(actions.HIDE_MODAL, e.target.value);
		const confirmChangesModal = (
			<aside class={s.confirmationModalContainer}>
				<p class={s.confirmationPrompt}>
					You've been saving your changes each time you pressed <em>save & continue</em>, but would you like to keep them or discard them?
				</p>
				<fieldset class={s.fieldset}>
					<legend class={s.legend}>Would you like to keep these changes?</legend>
					<button class={s.button} value="keep" onClick={modalButtonClick}>Yes, keep 'em!</button>
					<button class={s.button} value="discard" onClick={modalButtonClick}>Nah, toss 'em!</button>
					<button class={s.button} value="cancel" onClick={modalButtonClick}>Wait! Nevermind...</button>
				</fieldset>
			</aside>
		);

		Store.emit(actions.SHOW_MODAL, confirmChangesModal, 'Woah there!', 'confirmChangesModal', v => {
			if (!v || v === 'cancel') {
				return;
			}
			if (v === 'discard') {
				Store.emit(actions.UPDATE_EDITED_APP_DATA, this.props.app, this.props.attributes);
			}
			this.props.closeWizard();
		});
	}

	ytAssist(values, formData) {
		if (!formData || !values) {
			return;
		}
		let idField = values.find(field => field.id === 'youtube-id');
		if (!idField || !idField.value) {
			return;
		}
		YoutubeVideo({ ytidValue: idField.value }).then(vid => {
			let update = (id, val) => {
				let field = formData.fields.find(f => f.id === id);
				if (field) {
					field.value = val;
				}
			};
			update('youtube-name', vid.nameValue);
			update('youtube-published', vid.publishedAtValue);
			update('youtube-thumb', vid.thumbSrcValue);
			this.forceUpdate();
		});
	}

	linkYtId(ytidValue) {
		this.ytidValue = ytidValue;
	}

	changeSection(offset) {
		if (!+offset) {
			return;
		}
		this.saveData(this.$formGenerator.getFormData());
		let state = this.state;
		let newForm = state.activeForm + (+offset);
		if (newForm >= state.formData.length || newForm < 0) {
			return this.props.closeWizard();
		}
		this.setState({ activeForm: newForm });
	}

	setSectionIndex(index) {
		this.saveData(this.$formGenerator.getFormData());
		this.setState({ activeForm: index });
	}

	saveAndClose() {
		this.saveData(this.$formGenerator.getFormData());
		this.props.closeWizard();
	}

	saveData(data) {
		if (!data) {
			return;
		}
		let appAttr = this.editedData;

		data.forEach(field => {
			let val = field.value;

			let update = field.update;
			let path = typeof field.update === 'string' && field.update;

			if (path) {
				let baseVal = accessNested(appAttr, path);
				if (field.type === 'disabler') baseVal = baseVal !== null;
				if (val === baseVal) {
					return;
				}
			}

			if (field.type === 'disabler') {
				const disabledPath = path && `dev.disabledItems.${path.replace(/\./g, '|')}`;
				const activeValue = (disabledPath && accessNested(appAttr, disabledPath)) || field.activeValue || {};
				val = val ? activeValue : null;
				if (path) {
					update = [
						{ path, value: val },
						{ path: disabledPath, value: val === null ? accessNested(appAttr, path) : DELETE }
					];
				}
			}

			if (val === undefined) {
				return;
			}
			if (typeof field.parse === 'function') {
				val = field.parse(val);
			}

			if (typeof update === 'function') {
				update = update(val, appAttr);
			}
			if (!update) {
				return;
			}

			let updates = Array.isArray(update) ? update : [update];
			updates.forEach(update => {
				if (typeof update === 'string') {
					update = { path: update, value: val };
				}
				if (!update || !update.path) {
					return;
				}
				let value = update.value;
				if (typeof value === 'function') {
					value = value(val);
				}
				setNested(appAttr, update.path, value);
			});
		});

		this.editedData = { ...appAttr };
		Store.emit(actions.UPDATE_EDITED_APP_DATA, this.props.app, appAttr);
	}

	getFormDataTemplate(props = this.props) {
		const app = props.mergedData;
		const studio = accessNested(app, 'meta.studio.slug');

		let vanities = app?.meta?.vanities;
		if (vanities && Array.isArray(vanities)) {
			vanities = vanities.join('\n');
		}
		let parseVanities = val => val && val.split('\n').map(s => s.trim().toLowerCase());

		let releaseDate = accessNested(app, 'meta.dates.release');
		if (releaseDate && typeof releaseDate === 'string') {
			releaseDate = releaseDate.split('/').reverse().join('-');
		}
		let ticketsDate = accessNested(app, 'meta.dates.most');
		if (ticketsDate && typeof ticketsDate === 'string') {
			ticketsDate = ticketsDate.split('/').reverse().join('-');
		}

		let field = (type, title, gSheet, update, extra) => {
			if (!extra?.value && typeof update === 'string') {
				extra = extra || {};
				extra.value = accessNested(app, field.update);
				if (type === 'disabler') extra.value = extra.value !== null;
			}
			return {
				type, title, update,
				googleSheetValue: gSheet,
				...extra
			};
		};
		let date = (...args) => field('date', ...args);
		let input = (...args) => field('input', ...args);
		let textarea = (...args) => field('textarea', ...args);
		let disabler = (title, update, activeValue, extra) => field('disabler', title, null, update, { activeValue, ...extra });
		let dropdown = (title, gSheet, update, options, extra) => field('dropdown', title, gSheet, update, { options, ...extra });

		const sections = [
			{
				id: 'dates', title: 'Dates',
				fields: [
					{
						data: [
							date('Release Date', 'moviereleasedate', 'meta.dates.release', { value: releaseDate }),
							date('Tickets Available', 'whenwilltheboxofficeopenforthisrelease', 'meta.dates.boxOffice', { value: ticketsDate }),
						]
					}
				]
			},
			{
				id: 'social', title: 'Social',
				fields: [
					{
						data: [
							input('Facebook Page ID', 'facebookpagegetticketstab', 'meta.messenger.pageId'),
							input('Facebook Link', 'moviesociallinks', 'meta.social.facebook.link', { parse: findFacebook }),
							input('Instagram Link', 'moviesociallinks', 'meta.social.instagram.link', { parse: findInstagram }),
							input('Twitter Link', 'moviesociallinks', 'meta.social.twitter.link', { parse: findTwitter }),
							input('Hashtag', 'officialmoviehashtag', 'copy.$HASHTAG'),
						]
					}
				]
			},
			{
				id: 'synopsis', title: 'Synopsis',
				fields: [
					{
						data: [
							dropdown('Genres', 'whatgenreisyourmovie', 'meta.genres', accessNested(schema, 'properties.meta.properties.genre.options')),
							textarea('Synopsis Description', 'moviesynopsis', 'copy.$SYNOPSIS_DESCRIPTION'),
							textarea('Synopsis Cast', null, 'copy.$SYNOPSIS_CAST'),
						]
					}
				]
			},
			{
				id: 'urls', title: 'URLs',
				fields: [
					{
						data: [
							textarea('Vanity Domains', 'pleasespecifywhatyouwouldliketouseforyourvanityurl', 'meta.vanities', { value: vanities, parse: parseVanities }),
							input('Main Vanity', 'officialfilmwebsitepleaseincludehttpwww', 'meta.url'),
						]
					}
				]
			},
			{
				id: 'pages', title: 'Pages',
				fields: [
					{
						data: [
							disabler('Home', 'pages.home', { id: 'home', $copy: '$HOME', path: '/', data: {} }),
							input('Home Meta Description', null, 'copy.$HOME_META_DESCRIPTION'),
							input('Home Meta Title', null, 'copy.$HOME_META_TITLE'),
							disabler('Showtimes', 'pages.showtimes', { id: 'showtimes', $copy: '$SHOWTIMES', path: '/tickets/', data: {} }),
							input('Showtimes Meta Description', null, 'copy.$SHOWTIMES_META_DESCRIPTION'),
							input('Showtimes Meta Title', null, 'copy.$SHOWTIMES_META_TITLE'),
							disabler('Synopsis', 'pages.synopsis', { id: 'synopsis', $copy: '$SYNOPSIS', path: '/synopsis/', data: {} }),
							input('Synopsis Meta Description', null, 'copy.$SYNOPSIS_META_DESCRIPTION'),
							input('Synopsis Meta Title', null, 'copy.$SYNOPSIS_META_TITLE'),
							disabler('Videos', 'pages.videos', { id: 'videos', $copy: '$VIDEOS', path: '/videos/', data: {} }),
							input('Videos Meta Description', null, 'copy.$VIDEOS_META_DESCRIPTION'),
							input('Videos Meta Title', null, 'copy.$VIDEOS_META_TITLE'),
							disabler('Gallery', 'pages.gallery', { id: 'gallery', $copy: '$GALLERY', path: '/gallery/', data: {} }),
							input('Gallery Meta Description', null, 'copy.$GALLERY_META_DESCRIPTION'),
							input('Gallery Meta Title', null, 'copy.$GALLERY_META_TITLE'),
						]
					}
				]
			},
			{
				id: 'meta', title: 'Meta',
				fields: [
					{
						data: [
							input('Meta Description', null, 'copy.$META_DESCRIPTION'),
							input('Meta Title', null, 'copy.$META_TITLE'),
						]
					}
				]
			},
			{
				id: 'youtube', title: 'Youtube',
				fields: [
					{
						data: [
							input('Playlist ID', null, 'pages.videos.data.list.0.playlistId'),
							input('Video ID', null, 'pages.videos.data.list.0.ytid', { id: 'youtube-id', value: this.state.ytidValue }),
							input('Video Name', null, 'pages.videos.data.list.0.name', { id: 'youtube-name', value: this.state.ytNameValue }),
							input('Video Published', null, 'pages.videos.data.list.0.publishedAt', { id: 'youtube-published', value: this.state.ytPublishedValue }),
							input('Video Thumb URL', null, 'pages.videos.data.list.0.thumbSrc', { id: 'youtube-thumb', state: this.state.ytThumbValue }),
						]
					}
				]
			},
			{
				id: 'options', title: 'Features',
				fields: [
					{
						data: [
							disabler('Showtimes Vertical Story', 'options.showtimesVerticalStory', true),
							// disabler('Group Booking', 'options.groupBookingEnabled', true),
							// disabler('Messenger Reminder Bot', 'options.useMessengerRemind', true),
							disabler('Event Cinema', 'meta.eventCinema', true),
						]
					}
				]
			},
			{
				id: 'keys', title: 'App Keys',
				fields: [
					{
						data: [
							disabler('Vertical Story', 'meta.keys.verticalStory', 'VS', { value: !!accessNested(app, 'options.showtimesVerticalStory') }),
							// disabler('Group Booking', 'meta.keys.groupBooking', 'GPBK', { value: !!accessNested(app, 'options.groupBookingEnabled') }),
							disabler('Creative Build', 'meta.keys.creative', 'CR'),
							disabler('Messenger Bot', 'meta.keys.messenger', 'MSGR'),
							disabler('Quiz', 'meta.keys.quiz', 'QZ', { value: !!accessNested(app, 'pages.quiz') }),
							disabler('Home Entertainment', 'meta.keys.homeEntertainment', 'HE', { value: !!accessNested(app, 'pages.homeEnt') }),
							disabler('Showtimes Only', (value, attr) => {
								setNested(attr, 'pages.home', value ? null : { id: 'home', $copy: '$HOME', path: '/', data: {} });
								setNested(attr, 'pages.showtimes.path', value ? '/' : '/tickets/');
								setNested(attr, 'meta.keys.showtimesOnly', value);
								setNested(attr, 'meta.keys.forceLive', !!value);
								setNested(attr, 'background.noVideo', !!value);
							}, 'SH'),
							disabler('Virtual Reality', 'meta.keys.vr', 'VR'),
						]
					}
				]
			},
			{
				id: 'misc', title: 'Misc',
				fields: [
					{
						data: [disabler('Static Background', 'background.noVideo', true)]
					}
				]
			}
		];

		if (studio === 'lionsgateus') {
			sections.push({
				id: 'tracking', title: 'Tracking',
				fields: [
					{
						data: [input('Lionsgate US DXNumber', null, 'apis.tracking.pixels.lionsgateus.id')]
					}
				]
			});
		}

		// if (app.dev.enableThundrImages) {
		// 	sections.push({
		// 		id: 'images', title: 'Images',
		// 		fields: [
		// 			{
		// 				type: 'image',
		// 				title: 'Image Uploader',
		// 				update: (value, attr) => {
		// 					function addOrRemove(value, remove) {
		// 						const name = value.imgName && value.imgName.split('.')[0];
		// 						const path = getMatchingImagePath(name);
		// 						if (!path) {
		// 							return;
		// 						}
		// 						setNested(attr, path, remove ? null : value.imgLocation);
		// 						let existing = accessNested(attr, 'dev.dynamicSchemaRef') || {};
		// 						existing[path + '.'] = remove ? null : 'image';
		// 						setNested(attr, 'dev.dynamicSchemaRef', existing);
		// 					}
		// 					if (value && value.next) {
		// 						value.next.forEach(val => addOrRemove(val, false));
		// 					}
		// 					if (value && value.prev) {
		// 						value.prev.forEach(val => addOrRemove(val, true));
		// 					}
		// 				},
		// 				value: getImageDirectoryValues(app)
		// 			}
		// 		]
		// 	});
		// }

		return sections;
	}

	//////////////////////////////////////////////////////////////
	// RENDER FUNCTIONS //////////////////////////////////////////
	//////////////////////////////////////////////////////////////

	renderPagination() {
		let active = this.state.activeForm;
		return (
			<nav class={s.paginationContainer} key="pagination">
				<ul>
					{this.state.formData.map((section, key) => (
						<li
							class={joinClasses(s.sectionLink, key === active && s.active)}
							onClick={this.setSectionIndex.bind(this, key)}
							key={key}
						>
							{section.title}
						</li>
					))}
				</ul>
			</nav>
		);
	}

	renderClientDataSelection() {
		let props = this.props;
		let state = this.state;
		if (!props.sheetId) {
			return <p>Please attach the studio form responses google sheet id to <span class={s.appDataPath}>dev.formResponsesSheetId</span> at studio level.</p>;
		}
		if (state.sheetLoading) {
			return <div class={s.googleFormWrapper}><div class={s.loading} /></div>;
		}
		if (state.sheetError) {
			let email = 'showtimes-data-importer@encoded-source-178411.iam.gserviceaccount.com';
			let sheetLink = 'https://docs.google.com/spreadsheets/d/' + props.sheetId + '/edit';
			return (
				<div class={s.errorContainer}>
					<div class={s.error}>Error getting client answers data</div>
					<div class={s.errorMessage}>{state.sheetError}</div>
					<div class={s.note}>If that is not already the case, please ensure that <span class={s.authEmail}>{email}</span> has been granted permission to edit/view <a href={sheetLink} target="_blank">this google sheet</a>.</div>
				</div>
			);
		}
		let sheetLoaded = state.sheetLoaded;
		return (
			<div class={s.googleFormWrapper}>
				<div class={sheetLoaded ? s.close : s.hide} onClick={props.closeWizard}>x</div>
				<h1>Google Sheets Importer</h1>
				<h3 class={s.cta}>
					The sheet ID can be set in the app in
					<span>Development > formResponsesSheetId</span>
				</h3>
				<Selector
					items={sortBy(state.sheetData, 'id')}
					getLabel="id"
					value={state.selectedClientFormData}
					selectMessage="Select a Google Form"
					onChange={this.selectClientFormData}
					disabled={sheetLoaded}
				/>
				<button
					class={sheetLoaded ? s.applied : s.apply}
					onClick={this.applyClientFormData}
				>
					Apply
				</button>
			</div>
		);
	}

	render(props, state) {
		const {
			app = {}, // (object) current app object used to create the appData
			closeWizard,
			activateWebedia,
		} = props;

		const {
			formData = [], // (array) output of getFormDataTemplate based on current app state
			activeForm,
		} = state;

		const firstPage = !activeForm;
		const lastPage = activeForm >= formData.length - 1;

		const currentData = formData[activeForm] || {};

		const buttons = [
			<button class={s.button} onClick={this.saveAndClose} key="save">Save & Close</button>,
			!lastPage && <button class={s.button} onClick={this.cancelEdit} key="cancel">Close</button>,
			!firstPage && <button class={s.button} onClick={this.prevSection} key="prev">Go Back</button>,
			!lastPage && <button class={s.button} onClick={this.nextSection} key="next">Save & Continue</button>,
			!!lastPage && <button class={s.button} onClick={this.saveAndClose} key="finish">Finish</button>,
		];

		return (
			<section class={s.wizard}>
				<div class={s.header}>
					<h1 class={s.title}>{currentData.title}</h1>
				</div>
				{this.renderPagination(props, state)}
				<FormGenerator
					ref={ref => this.$formGenerator = ref}
					class={s.formGenerator}
					formData={currentData}
					pageIndex={activeForm}
					pageCount={formData.length}
					// firstPage={firstPage}
					// lastPage={lastPage}
					activateWebedia={activateWebedia}
					closeWizard={closeWizard}
					nextForm={this.nextSection}
					prevForm={this.prevSection}
					saveClose={this.saveAndClose}
					onCancel={this.cancelEdit}
					updateData={this.saveData}
				/>
				<div class={s.buttonWrapper}>
					{buttons}
				</div>
				<progress
					class={s.progressBar}
					min="0"
					max={formData.length}
					value={activeForm + 1}
					data-section={currentData.title}
				/>
				<div class={s.clientDataSelection}>
					{this.renderClientDataSelection()}
				</div>
			</section>
		);
	}
}
