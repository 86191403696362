import { h, Component } from 'preact';
// import { Link } from 'components/core/link/link.js';
import pure from 'utils/pure';
// import vis from 'vis';
import { getGoogleSpreadsheet } from 'services/buildApi.js';
import s from 'components/pages/docs/arch.sss';

export default @pure class Arch extends Component {
	constructor(props) {
		super(props);

		if (typeof window !== 'undefined' && !window.visLoaded) {
			window.visLoaded = true;
			let script = document.createElement('script');
			script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vis/4.20.1/vis.min.js';
			document.body.appendChild(script);
		}

		this.shapeMap = {
			client: 'box',
			database: 'database',
			service: 'triangle',
			support: 'diamond'
		};
		this.sheetId = '1hfSYkB2Pg30-By0Gi6y3Em2SqxgRxg1FB0DKDAyqr2o';
		this.state.sheetData = {};
	}

	componentWillMount() {
		getGoogleSpreadsheet(this.sheetId, { full: true }).then(sheetData => {
			this.setState({ sheetData, activeArchId: Object.keys(sheetData)[0] });
		}).catch(err => console.error(err.message));
	}

	componentDidUpdate() {
		// Object.keys(this.state.sheetData).map(k => {
		// 	let services = this.state.sheetData[k];
		// 	let nodes = services.filter(s=>!s.hide).map(s=>{
		// 		let node = {id: s.id, label: s.title/*`${s.id}\n${s.description}`*/, group: s.type, shape: this.shapeMap[s.type]};
		// 		if(s.big) {
		// 			node.font = {size: 30};
		// 			node.size = 30;
		// 		}
		// 		return node;
		// 	});
		// 	let edges = services.reduce((prev, s)=>{
		// 		let deps = s.deps ? s.deps.split(',').map(Function.prototype.call, String.prototype.trim) : [];
		// 		return prev.concat(deps.map(d=>{
		// 			return {from: d, to: s.id, arrows: 'to'};
		// 		}));
		// 	}, []);
		//
		// 	create a network
		// 	let data = { nodes, edges };
		// 	let network = new vis.Network(this['$'+k], data, {});
		// });
	}

	setActiveArch(activeArchId){
		this.setState({ activeArchId });
	}

	render(props, { sheetData, activeArchId }) {

		return (
			<div class={s.container}>
				<h3>Go to <a href={'https://docs.google.com/spreadsheets/d/' + this.sheetId + '/edit'} target="_blank">this google spreadsheet</a> to edit services and dependencies</h3>
				{Object.keys(sheetData).length ?
					(
						<div>
							<ul>{Object.keys(sheetData).map(k => <li onClick={this.setActiveArch.bind(this, k)} class={activeArchId === k ? s.activeArchTab : s.archTab}>{k}</li>)}</ul>
							<ul>{Object.keys(sheetData).map(k => <li ref={e => this['$'+k] = e} class={activeArchId === k ? s.activeArch : s.arch}></li>)}</ul>
						</div>
					)
					:
					'...loading'
				}
			</div>
		);
	}
}
