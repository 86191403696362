import { h, Component } from 'preact';
import defaultsDeep from '@nodeutils/defaults-deep';

import Store from 'store/store';
import * as actions from 'store/actions';
import { accessNested, joinClasses, sortObjectByOrder } from 'utils/utils';
import * as quickInput from 'utils/quickInput';
import createTooltip from 'utils/tooltip';
import * as helpers from 'components/shared/modal/addNewCustomEdit/customHelpers';

import FormGenerator from 'components/shared/formGenerator/formGenerator';

import IconFacebook from 'components/bolt/icons/facebook';
import IconTwitter from 'components/bolt/icons/twitter';
import IconInstagram from 'components/bolt/icons/instagram';
import IconYoutube from 'components/bolt/icons/youtube';
import IconSnapchat from 'components/bolt/icons/snapchat';
import IconTiktok from 'components/bolt/icons/tiktok';
import IconSpotify from 'components/bolt/icons/spotify';
import IconPinterest from 'components/bolt/icons/pinterest';
import IconGiphy from 'components/bolt/icons/giphy';
import IconTumblr from 'components/bolt/icons/tumblr';
import IconVimeo from 'components/bolt/icons/vimeo';
import IconMail from 'components/bolt/icons/mail';

import s from 'components/pages/apps/wizards/boltWizard.sss';

const LinkIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" viewBox="0 0 24 24">
		<path d="M20 11a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1Z" />
		<path d="M16 5h1.58l-6.29 6.28a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0L19 6.42V8a1 1 0 0 0 1 1a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2Z" />
	</svg>
);

const DELETE = Symbol('delete');
function setNested(obj, path, value) {
	if (!obj) {
		return obj;
	}
	if (typeof path === 'string') {
		path = path ? path.split('.') : [];
	}
	let len = path.length;
	if (!len) return obj;
	for (let i = 0; i < len; i++) {
		let prop = path[i];
		if (i !== len - 1) {
			if (!obj[prop] || typeof obj[prop] !== 'object') {
				obj[prop] = !Number.isNaN(+path[i + 1]) ? [] : {};
			}
			obj = obj[prop];
		} else {
			if (value === DELETE) {
				delete obj[prop];
			} else {
				obj[prop] = value;
			}
		}
	}
}

export default class BoltWizard extends Component {

	constructor(props) {
		super(props);

		this.cancelEdit = this.cancelEdit.bind(this);
		this.nextSection = this.changeSection.bind(this, 1);
		this.prevSection = this.changeSection.bind(this, -1);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.saveData = this.saveData.bind(this);
		this.editedData = {};

		this.state = { activeForm: 0 };
	}

	componentWillMount() {
		let app = this.props.app || {};
		const formData = this.getFormDataTemplate(this.props);
		this.editedData = structuredClone(app.edited || app.attributes);
		this.updateFullData();
		this.setState({ formData });
	}

	cancelEdit() {
		let modalButtonClick = e => Store.emit(actions.HIDE_MODAL, e.target.value);
		const confirmChangesModal = (
			<aside class={s.confirmationModalContainer}>
				<p class={s.confirmationPrompt}>
					Would you like to keep any changes you've made so far or discard them?
				</p>
				<fieldset class={s.fieldset}>
					<legend class={s.legend}>Keep your changes?</legend>
					<button class={s.button} value="keep" onClick={modalButtonClick}>Yes</button>
					<button class={s.button} value="discard" onClick={modalButtonClick}>No</button>
					<button class={s.button} value="cancel" onClick={modalButtonClick}>Stay in the wizard</button>
				</fieldset>
			</aside>
		);

		Store.emit(actions.SHOW_MODAL, confirmChangesModal, 'Woah there!', 'confirmChangesModal', v => {
			if (!v || v === 'cancel') {
				return;
			}
			if (v === 'discard') {
				Store.emit(actions.UPDATE_EDITED_APP_DATA, this.props.app, this.props.attributes);
			}
			this.props.closeWizard();
		});
	}

	changeSection(offset) {
		if (!+offset) {
			return;
		}
		let newIndex = this.state.activeForm + (+offset);
		if (newIndex >= this.state.formData.length || newIndex < 0) {
			return this.props.closeWizard();
		}
		this.setSectionIndex(newIndex);
	}

	setSectionIndex(index) {
		this.saveData();
		this.setState({ activeForm: index });
	}

	saveAndClose() {
		this.saveData();
		this.props.closeWizard();
	}

	changeEditedData(data = this.editedData) {
		// Clone the object to trigger re-renders
		this.editedData = { ...data };
		this.updateFullData();
		Store.emit(actions.UPDATE_EDITED_APP_DATA, this.props.app, this.editedData);
	}

	updateFullData() {
		this.fullData = defaultsDeep({}, this.editedData, this.props.mergedData);
	}

	saveData(data = this.$formGenerator?.getFormData()) {
		if (!data) {
			return;
		}
		let appAttr = this.editedData;

		data.forEach(field => {
			let val = field.value;

			let update = field.update;
			let path = typeof field.update === 'string' && field.update;

			if (path) {
				let baseVal = this.getItem(path);
				if (field.type === 'disabler') baseVal = baseVal !== null;
				if (val === baseVal) {
					return;
				}
			}

			if (field.type === 'disabler') {
				const disabledPath = path && this.getDisabledPath(path);
				const activeValue = (disabledPath && this.getItem(disabledPath)) || field.activeValue || {};
				val = val ? activeValue : null;
				if (path) {
					update = [
						{ path, value: val },
						{ path: disabledPath, value: val === null ? this.getItem(path) : DELETE }
					];
				}
			}

			if (val === undefined) {
				return;
			}
			if (typeof field.parse === 'function') {
				val = field.parse(val);
			}

			if (typeof update === 'function') {
				update = update(val, appAttr);
			}
			if (!update) {
				return;
			}

			let updates = Array.isArray(update) ? update : [update];
			updates.forEach(update => {
				if (typeof update === 'string') {
					update = { path: update, value: val };
				}
				if (!update || !update.path) {
					return;
				}
				let value = update.value;
				if (typeof value === 'function') {
					value = value(val);
				}
				setNested(appAttr, update.path, value);
			});
		});

		this.changeEditedData(appAttr);
	}

	field = (type, title, update, extra = {}) => {
		if (!extra.value && typeof update === 'string') {
			extra.value = this.getItem(update);
			if (type === 'disabler') extra.value = extra.value !== null;
		}
		return { type, title, update, ...extra };
	};

	date = (...args) => this.field('date', ...args);
	input = (...args) => this.field('input', ...args);
	textarea = (...args) => this.field('textarea', ...args);
	checkbox = (...args) => this.field('checkbox', ...args);
	disabler = (...args) => this.field('disabler', ...args);

	getNewMaxOrder(path) {
		const items = this.getItem(path);
		return items ? Math.max(...Object.values(items).map(item => +item?.order || 0)) + 1 : 1;
	}

	createSynopsisCreditItem = async () => {
		const promptResult = await quickInput.show({
			allowCustom: true,
			prompt: 'Name of the item (e.g. writer)',
			noOptionsLabel: 'Escape to cancel'
		});
		const id = promptResult?.value;
		if (!id) {
			return;
		}

		const idUpper = id.toUpperCase().replace(/\W/g, ' ').replace(/[ _-]+/g, '_');
		const heading = `$${idUpper}`;
		const names = `$${idUpper}_NAMES`;
		setNested(this.editedData, `pages.boltSynopsis.data.credits.${id}`, { heading, names, order: this.getNewMaxOrder('pages.boltSynopsis.data.credits') });
		setNested(this.editedData, `copy.baseCopy.${heading}`, '');
		setNested(this.editedData, `copy.baseCopy.${names}`, '');

		this.changeEditedData();
	};

	extractYoutubePlaylistId(url) {
		return url?.match(/[?&]list=([^?&/#]+)/i)?.[1];
	}
	extractYoutubeVideoId(url) {
		const match = url?.match(/[&?]v=([^&/#]+)|youtu\.be\/([^?&#]+)|^([a-z0-9_-]+)$/i);
		return match?.[1] || match?.[2] || match?.[3];
	}

	async youtubeAssistant(path) {
		const data = this.getItem(path);
		if (!data || !('youtubeId' in data)) {
			return;
		}
		const idOrUrl = data.youtubeId || (await quickInput.show({ allowCustom: true, prompt: 'Youtube ID or URL', noOptionsLabel: 'Escape to cancel' }))?.value;
		const id = this.extractYoutubeVideoId(idOrUrl);
		if (!id) {
			return;
		}
		try {
			const result = await helpers.YoutubeVideo({ ytidValue: id });
			[['youtubeId', id], ...Object.entries(result).map(([key, val]) => [key.replace(/Value$/, ''), val])].forEach(([key, val]) => {
				setNested(this.editedData, `${path}.${key}`, val);
			});
			this.changeEditedData();
		} catch (e) {
			createTooltip(`Error while getting youtube data for ${id}`, { duration: 1000 });
		}
	}

	getFreeVideoId() {
		let id;
		{
			id = Math.floor(36 ** 5 * crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32).toString(36);
		} while (this.getItem(`pages.boltVideos.data.list.${id}`));
		return id;
	}
	createYoutubeVideoItem(videoId) {
		let id = this.getFreeVideoId();
		const newData = {
			youtubeId: videoId || '',
			name: '',
			thumbSrc: '',
			order: this.getNewMaxOrder('pages.boltVideos.data.list')
		};
		setNested(this.editedData, `pages.boltVideos.data.list.${id}`, newData);
		this.changeEditedData();
		return id;
	}
	createYoutubePlaylistItem(playlistId) {
		let id = this.getFreeVideoId();
		setNested(this.editedData, `pages.boltVideos.data.list.${id}`, { youtubePlaylistId: playlistId || '', order: this.getNewMaxOrder('pages.boltVideos.data.list') });
		this.changeEditedData();
		return id;
	}
	async createYoutubeItemFromURL() {
		const result = await quickInput.show({ allowCustom: true, prompt: 'Youtube URL (video or playlist)', noOptionsLabel: 'Escape to cancel' });
		if (!result) {
			return;
		}
		let playlist = this.extractYoutubePlaylistId(result.value);
		if (playlist) {
			this.createYoutubePlaylistItem(playlist);
			return;
		}
		let video = this.extractYoutubeVideoId(result.value);
		if (video) {
			const thundrId = this.createYoutubeVideoItem(video);
			await this.youtubeAssistant(`pages.boltVideos.data.list.${thundrId}`);
			return;
		}
	}

	async createNewFollowItem() {
		const existing = Object.keys(this.getItem('header.powsterHeader.follow') || {});
		const promptResult = await quickInput.show({
			list: [
				{ value: 'facebook', icon: <IconFacebook /> },
				{ value: 'twitter', icon: <IconTwitter /> },
				{ value: 'instagram', icon: <IconInstagram /> },
				{ value: 'youtube', icon: <IconYoutube /> },
				{ value: 'snapchat', icon: <IconSnapchat /> },
				{ value: 'tiktok', icon: <IconTiktok /> },
				{ value: 'spotify', icon: <IconSpotify /> },
				{ value: 'pinterest', icon: <IconPinterest /> },
				{ value: 'giphy', icon: <IconGiphy /> },
				{ value: 'tumblr', icon: <IconTumblr /> },
				{ value: 'vimeo', icon: <IconVimeo /> },
				{ value: 'email', icon: <IconMail /> },
			].filter(e => !existing.includes(e.value)),
			allowCustom: true,
			prompt: 'Social platform (e.g. twitter)',
			noOptionsLabel: 'Escape to cancel'
		});
		const id = promptResult?.value?.replace(/\W/g, '').toLowerCase();
		if (!id) {
			return;
		}
		setNested(this.editedData, `header.powsterHeader.follow.${id}`, { link: '', order: this.getNewMaxOrder('header.powsterHeader.follow') });
		this.changeEditedData();
	}

	getDisabledPath(path) {
		return `dev.disabledItems.${path.replace(/\./g, '|')}`;
	}

	getItem(path) {
		// const edited = accessNested(this.editedData, path);
		// return edited !== undefined ? edited : accessNested(this.props.mergedData, path);
		return accessNested(this.fullData, path);
	}

	getDisabledItem(path) {
		return this.getItem(this.getDisabledPath(path));
	}

	enable = (attr, path, defaultContent = {}) => {
		const value = this.getItem(path);
		if (value) return;
		const disabledPath = this.getDisabledPath(path);
		const disabledValue = this.getItem(disabledPath) || defaultContent;
		// console.log('enabling', structuredClone(attr), path, disabledPath, disabledValue);
		setNested(attr, disabledPath, DELETE);
		setNested(attr, path, disabledValue);
	}

	disable = (attr, path) => {
		const value = this.getItem(path);
		if (value === null) return;
		const disabledPath = this.getDisabledPath(path);
		setNested(attr, path, null);
		setNested(attr, disabledPath, value);
	}

	disableFromCurrentData(path) {
		this.disable(this.editedData, path);
		this.changeEditedData();
	}

	changeOrder(parentPath, key, offset) {
		const items = sortObjectByOrder(this.getItem(parentPath), (val, key) => key);
		let from = items.indexOf(key);
		if (from === -1 || !offset) return;
		let to = from + offset;
		if (to < 0 || to >= items.length) return;
		items.splice(to, 0, items.splice(from, 1)[0]);
		items.forEach((e, i) => setNested(this.editedData, `${parentPath}.${e}.order`, i + 1));
		this.changeEditedData();
	}

	generalTemplate() {
		return {
			id: 'general',
			title: 'General',
			fields: [
				{
					group: 'Build Type',
					data: [
						this.field('radio', 'Full Site', (value, attr) => {
							if (!value) return;

							this.enable(attr, 'pages.boltHome');
							setNested(attr, 'pages.boltHome.path', '/');

							this.enable(attr, 'pages.boltShowtimes');
							setNested(attr, 'pages.boltShowtimes.path', '/tickets/');

							this.enable(attr, 'pages.boltHomeEnt');
							setNested(attr, 'pages.boltHomeEnt.path', '/watch-at-home/');

							this.enable(attr, 'pages.boltSynopsis');
							this.enable(attr, 'pages.boltVideos');
							this.enable(attr, 'pages.boltWatchMore');
							this.enable(attr, 'pages.boltGallery');

							setNested(attr, 'meta.keys.showtimesOnly', false);
							setNested(attr, 'options.forceLive', false);
						}, { inputName: 'buildType', notes: `Set app to default, enable all main pages.` } ),

						this.field('radio', 'Showtimes Only', (value, attr) => {
							if (!value) return;

							this.disable(attr, 'pages.boltHome');

							this.enable(attr, 'pages.boltShowtimes');
							setNested(attr, 'pages.boltShowtimes.path', '/' );

							this.disable(attr, 'pages.boltHomeEnt');

							this.disable(attr, 'pages.boltSynopsis');
							this.disable(attr, 'pages.boltVideos');
							this.disable(attr, 'pages.boltWatchMore');
							this.disable(attr, 'pages.boltGallery');

							setNested(attr, 'meta.keys.showtimesOnly', true);
							setNested(attr, 'options.forceLive', true);
						}, { inputName: 'buildType', notes: `Set boltShowtimes as landing and disable other pages.` }),

						this.field('radio', 'HomeEnt Only', (value, attr) => {
							if (!value) return;

							this.disable(attr, 'pages.boltHome');

							this.disable(attr, 'pages.boltShowtimes');

							this.enable(attr, 'pages.boltHomeEnt');
							setNested(attr, 'pages.boltHomeEnt.path', '/' );

							this.disable(attr, 'pages.boltSynopsis');
							this.disable(attr, 'pages.boltVideos');
							this.disable(attr, 'pages.boltWatchMore');
							this.disable(attr, 'pages.boltGallery');

							setNested(attr, 'meta.keys.showtimesOnly', false);
							setNested(attr, 'options.forceLive', false);
						}, { inputName: 'buildType', notes: `Set boltHomeEnt landing and disable other pages.` }),
					]
				},
				{
					group: 'Individual Pages',
					data: [
						this.disabler('boltHome', 'pages.boltHome'),
						this.disabler('boltShowtimes', 'pages.boltShowtimes'),
						this.disabler('boltHomeEnt', 'pages.boltHomeEnt'),
						this.disabler('boltSynopsis', 'pages.boltSynopsis'),
						this.disabler('boltVideos', 'pages.boltVideos'),
						this.disabler('boltWatchMore', 'pages.boltWatchMore'),
						this.disabler('boltGallery', 'pages.boltGallery'),
					]
				}
			],
		};
	}

	metaDataTemplate() {
		return {
			id: 'metaData',
			title: 'Metadata',
			fields: [
				{
					group: 'Meta',
					data: [
						this.input('Movie Title', 'meta.title.locale'),
						this.date('Release Date', 'meta.dates.release'),
						this.input('URL', 'meta.url'),
					]
				},
				{
					group: 'Base Copy',
					data: [
						this.input('Site Title', 'copy.baseCopy.$META_TITLE'),
						this.textarea('Site Meta Description', 'copy.baseCopy.$META_DESCRIPTION'),
					]
				},
			]
		};
	}

	synopsisTemplate() {
		const tagline = this.getItem('pages.boltSynopsis.data.tagline');
		const data = { tagline };
		const synopsis = {
			id: 'synopsis',
			title: 'Synopsis',
			fields: [
				{
					key: 'settings',
					settings: true,
					data: [
						this.disabler('Enable synopsis page?', 'pages.boltSynopsis'),
					],
				},
				...sortObjectByOrder(data, (value, key, index, all) => {
					if (!value) {
						return;
					}
					return {
						key,
						group: `${index + 1} - Tagline & Body`,
						groupPath: `pages.boltSynopsis.data.credits.${key}`,
						data: [
							this.input('Tagline', 'copy.baseCopy.$SYNOPSIS_TAGLINE'),
							this.textarea('Body', 'copy.baseCopy.$SYNOPSIS_BODY'),
						],
					};
				}).filter(e => e),
			],
		};
		return synopsis;
	}

	creditsTemplate() {
		const credits = this.getItem('pages.boltSynopsis.data.credits');
		const synopsis = {
			id: 'credits',
			title: 'Credits',
			fields: [
				{
					key: 'settings',
					settings: true,
					data: [
						this.disabler('Enable synopsis page?', 'pages.boltSynopsis'),
					],
				},
				...sortObjectByOrder(credits, (value, key, index, all) => {
					if (!value) {
						return;
					}
					const heading = value.heading;
					const names = value.names;
					return {
						key,
						group: `${index + 1} - ${key}`,
						groupPath: `pages.boltSynopsis.data.credits.${key}`,
						data: [
							this.input('Header', `copy.baseCopy.${heading}`),
							this.textarea('Names', `copy.baseCopy.${names}`),
						],
						floatingButtons: [
							{ text: '⬆️', title: 'Move up', onClick: () => this.changeOrder('pages.boltSynopsis.data.credits', key, -1), disabled: !index },
							{ text: '🗑️', title: `Remove "${key}"`, onClick: () => this.disableFromCurrentData(`pages.boltSynopsis.data.credits.${key}`) },
							{ text: '⬇️', title: 'Move down', onClick: () => this.changeOrder('pages.boltSynopsis.data.credits', key, +1), disabled: index === all.length - 1 },
						],
					};
				}).filter(e => e),
			],
			buttons: [
				{ text: 'Add Credit', onClick: this.createSynopsisCreditItem }
			]
		};
		return synopsis;
	}

	videosTemplate() {
		const videos = this.getItem('pages.boltVideos.data.list');
		return {
			id: 'videos',
			title: 'Videos',
			fields: [
				{
					key: 'settings',
					settings: true,
					data: [
						this.disabler('Enable videos page?', 'pages.boltVideos'),
					],
				},
				...sortObjectByOrder(videos, (value, key, index, all) => {
					if (!value) {
						return;
					}
					const isYoutubeVideo = 'youtubeId' in value;
					const isYoutubePlaylist = 'youtubePlaylistId' in value;
					let name = isYoutubeVideo ? 'Video' : isYoutubePlaylist ? 'Playlist' : key;

					// For now: don't show other video types (vimeo etc.)
					if (!isYoutubeVideo && !isYoutubePlaylist) {
						return;
					}

					let data = [];
					let buttons;
					if (isYoutubeVideo) {
						data = [
							this.input('Youtube Id', `pages.boltVideos.data.list.${key}.youtubeId`),
							this.input('Video Name', `pages.boltVideos.data.list.${key}.name`),
							this.input('Thumbnail URL', `pages.boltVideos.data.list.${key}.thumbSrc`),
							this.checkbox('No Cookies', `pages.boltVideos.data.list.${key}.noCookies`),
						];
						buttons = [
							{ text: '🤖 Assistant', onClick: () => this.youtubeAssistant(`pages.boltVideos.data.list.${key}`) }
						];
					} else if (isYoutubePlaylist) {
						data = [
							this.input('Playlist Id', `pages.boltVideos.data.list.${key}.youtubePlaylistId`),
						];
					}

					return {
						key,
						group: [index + 1, name].filter(e => e).join(' - '),
						groupPath: `pages.boltVideos.data.list.${key}`,
						data,
						buttons,
						floatingButtons: [
							{ text: '⬆️', title: 'Move up', onClick: () => this.changeOrder('pages.boltVideos.data.list', key, -1), disabled: !index },
							{ text: '🗑️', title: 'Remove', onClick: () => this.disableFromCurrentData(`pages.boltVideos.data.list.${key}`) },
							{ text: '⬇️', title: 'Move down', onClick: () => this.changeOrder('pages.boltVideos.data.list', key, +1), disabled: index === all.length - 1 },
						],
					};
				}).filter(e => e),
			],
			buttons: [
				{ text: 'Add From URL', onClick: () => this.createYoutubeItemFromURL() },
				{ text: 'Add Video', onClick: () => this.createYoutubeVideoItem() },
				{ text: 'Add Playlist', onClick: () => this.createYoutubePlaylistItem() },
			]
		};
	}

	socialTemplate() {
		const followItems = this.getItem('header.powsterHeader.follow');
		return {
			id: 'social',
			title: 'Social',
			fields: [
				...sortObjectByOrder(followItems, (value, key, index, all) => {
					if (!value) {
						return;
					}
					return {
						group: `${index + 1} - ${key}`,
						groupPath: `header.powsterHeader.follow.${key}`,
						data: [
							this.input('Link', `header.powsterHeader.follow.${key}.destination`),
						],
						floatingButtons: [
							{ text: '⬆️', title: 'Move up', onClick: () => this.changeOrder('header.powsterHeader.follow', key, -1), disabled: !index },
							{ text: '🗑️', title: 'Remove', onClick: () => this.disableFromCurrentData(`header.powsterHeader.follow.${key}`) },
							{ text: '⬇️', title: 'Move down', onClick: () => this.changeOrder('header.powsterHeader.follow', key, +1), disabled: index === all.length - 1 },
						],
					};
				}).filter(e => e),
			],
			buttons: [
				{ text: 'Add Social', onClick: () => this.createNewFollowItem() }
			]
		};
	}

	getFormDataTemplate() {
		return [
			this.generalTemplate(),
			this.metaDataTemplate(),
			this.synopsisTemplate(),
			this.creditsTemplate(),
			this.videosTemplate(),
			this.socialTemplate()
		];
	}

	renderPagination() {
		let active = this.state.activeForm;
		return (
			<nav class={s.paginationContainer} key="pagination">
				<ul>
					{this.state.formData.map((section, key) => (
						<li class={joinClasses(s.sectionLink, key === active && s.active)} key={key}>
							<button type="button" onClick={this.setSectionIndex.bind(this, key)}>{section.title}</button>
						</li>
					))}
				</ul>
			</nav>
		);
	}

	render(props, state) {
		const { activeForm } = state;

		const formData = this.getFormDataTemplate();
		const currentData = formData[activeForm] || {};

		const firstPage = !activeForm;
		const lastPage = activeForm >= formData.length - 1;

		const buttons = [];
		if (currentData?.buttons) {
			buttons.push(...currentData.buttons.map(({ text, onClick }) => (
				<button class={joinClasses(s.button, s.highlight)} type="button" onClick={onClick}>{text}</button>
			)));
		}
		buttons.push(
			<button class={s.button} onClick={this.saveAndClose} key="save">Save & Close</button>,
			!lastPage && <button class={s.button} onClick={this.cancelEdit} key="cancel">Close</button>,
			!firstPage && <button class={s.button} onClick={this.prevSection} key="prev">Go Back</button>,
			!lastPage && <button class={s.button} onClick={this.nextSection} key="next">Save & Continue</button>,
			!!lastPage && <button class={s.button} onClick={this.saveAndClose} key="finish">Finish</button>,
		);

		return (
			<section class={s.wizard}>
				<div class={s.header}>
					<h1 class={s.title}>{currentData.title}</h1>
					<a class={joinClasses(s.button, s.documentation)} href="https://powster.atlassian.net/wiki/spaces/THDR/pages/2592997377/Bolt+Bolt+Wizard" target="_blank">
						{LinkIcon}
						<span>Documentation</span>
					</a>
				</div>
				{this.renderPagination(props, state)}
				<FormGenerator
					ref={ref => this.$formGenerator = ref}
					formData={currentData}
					firstPage={firstPage}
					lastPage={lastPage}
					nextForm={this.nextSection}
					prevForm={this.prevSection}
					updateData={this.saveData}
				/>
				<div class={s.buttonWrapper}>
					{buttons}
				</div>
				<div class={s.progressWrapper} data-indicator={`Progress: ${activeForm + 1}/${formData.length} - ${currentData.title}`}>
					<progress
						class={s.progressBar}
						min="0"
						max={formData.length}
						value={activeForm + 1}
					/>
				</div>
			</section>
		);
	}
}
