import { h, Component } from 'preact';
import { joinClasses } from 'utils/utils';
import createTooltip from 'utils/tooltip';
import { apiGet, apiCreate } from 'services/dataApi';
import InputWithSuggestions from 'components/shared/inputWithSuggestions/inputWithSuggestions';

import Store from 'store/store';
import * as actions from 'store/actions';

import s from 'components/pages/apps/modals/gaSetup.sss';

const availableGoogleEmails = [
	'ga-europe@powster.com',
	'ga-row@powster.com',
	'ga-us-ca@powster.com',
];

// Need a base account to make the "account creation" links work
const baseAccountPerEmail = {
	'ga-europe@powster.com': 'a224342849p404573235',
	'ga-row@powster.com': 'a256467491p352643420',
	'ga-us-ca@powster.com': 'a186951601p258324992',
};

export default class GASetup extends Component {

	constructor(props) {
		super(props);
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onAccountChange = this.onAccountChange.bind(this);
		this.onPropertyChange = this.onPropertyChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.submit = this.submit.bind(this);

		if (!props.appData) {
			Store.emit(actions.HIDE_MODAL, 'INVALID_INVOCATION');
			return;
		}

		this.state = {
			loading: false,
			email: props.appData?.dev?.ga4?.email || availableGoogleEmails[0],
			inexistantAccount: false,
			inexistantProperty: false,
			acountSummaries: [],
			accountNames: [],
			propertyNames: [],
			propertyName: this.props.appData?.dev?.ga4?.property || this.props.appData?.meta?.title?.en || '',
			multiRegion: !!this.props.appData?.dev?.ga4?.multiRegion,
		};
	}

	componentDidMount() {
		this.$aAcc.value = this.props.appData?.dev?.ga4?.account || '';
		this.$prop.value = this.state.propertyName || '';
		this.loadAccountsSummary();
		this.$prop?.select();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.email !== this.state.email) {
			this.loadAccountsSummary();
		}
	}

	async loadAccountsSummary() {
		let email = this.state.email;
		this.setState({ loadingAccountSummaries: true, acountSummaries: [], accountNames: [], propertyNames: [], inexistantAccount: false, inexistantProperty: false });
		try {
			const acountSummaries = await apiGet('/google/analytics-account-summaries', { email });
			if (this.state.email !== email) {
				return;
			}
			let currentAccountValue = this.$aAcc.value?.toLowerCase()?.trim();
			let currentPropertyValue = this.$prop.value?.toLowerCase()?.trim();
			let activeAccount = acountSummaries.find(e => e.name.toLowerCase().trim() === currentAccountValue);
			let propertyNames = activeAccount?.properties?.map(e => e.name) || [];
			this.setState({
				loadingAccountSummaries: false,
				acountSummaries,
				accountNames: acountSummaries.map(e => e.name),
				propertyNames,
				inexistantAccount: currentAccountValue && !activeAccount,
				inexistantProperty: !propertyNames.find(e => e.toLowerCase().trim() === currentPropertyValue)
			});
		} catch (e) {
			console.error(e);
			this.setState({ loadingAccountSummaries: false });
		}
	}

	setError(error, elem) {
		this.setState({ error, loading: false });
		elem?.focus?.();
	}

	onEmailChange(e) {
		this.setState({ email: e.target.value });
		this.setError(null);
	}

	onAccountChange(value) {
		if (this.state.loadingAccountSummaries) {
			return;
		}
		let searchValue = value?.toLowerCase()?.trim();
		let activeAccount = this.state.acountSummaries.find(e => e.name.toLowerCase().trim() === searchValue);
		let propertyNames = activeAccount?.properties?.map(e => e.name) || [];
		this.setState({ inexistantAccount: searchValue && !activeAccount, propertyNames });
		this.setError(null);
	}
	onPropertyChange(value) {
		if (this.state.loadingAccountSummaries) {
			return;
		}
		let searchValue = value?.toLowerCase()?.trim();
		let activeProperty = this.state.propertyNames.find(e => e.toLowerCase().trim() === searchValue);
		this.setState({ inexistantProperty: !activeProperty, propertyName: value });
		this.setError(null);
	}

	onKeyDown(e) {
		if (e.keyCode === 27) { // ESC
			this.cancel();
			return e.preventDefault();
		}
	}

	cancel() {
		Store.emit(actions.HIDE_MODAL, 'CANCEL');
	}

	async submit(e) {
		// Prevent default form submission
		e?.preventDefault?.();

		if (this.state.loading) {
			return;
		}

		const state = this.state;

		const email = state.email;
		if (!email) {
			return this.setError('Please specify a google account to use', this.$gAcc);
		}
		const accountName = this.$aAcc.value?.trim();
		if (!accountName) {
			return this.setError('Please specify an analytics account to use', this.$aAcc);
		}
		const title = this.$prop.value?.trim();
		if (!title) {
			return this.setError('Please specify a property name', this.$prop);
		}

		this.setState({ loading: true });
		const appData = this.props.appData;
		const region = appData?.meta?.region?.slug;
		try {
			const streamName = state.multiRegion ? region : title;
			let result = await apiCreate('/google/analytics-property', {
				email,
				accountName,
				title,
				region,
				studioSlug: appData?.meta?.studio?.slug,
				titleSlug: appData?.meta?.title?.slug,
				streamName,
			});

			if (!result.ok) {
				// Unlikely to get here (this would be returned with an error response code, which would go throw)
				return this.setError(result.error || 'An error occured');
			}

			if (result.silentFailMessages && result.silentFailMessages.length) {
				// eslint-disable-next-line no-alert
				window.alert(`Some errors have occured: ${result.silentFailMessages.join(', ')}`);
			}

			const streams = result.dataStreams || [];
			let measurementId = (streams.find(s => s.displayName === streamName) || streams[0])?.webStreamData?.measurementId;
			if (!measurementId) {
				console.error(`Could not find stream ${streamName}. GA creation result:`, result);
				return this.setError('Could not find the right stream');
			}

			if (result.isNew) {
				createTooltip(`Property ${result.property?.displayName} created (${measurementId})`, { duration: 5000 });
			}

			Store.emit(actions.HIDE_MODAL, {
				ok: true,
				email,
				account: result.account?.displayName,
				property: result.property?.displayName,
				multiRegion: !!state.multiRegion,
				measurementId
			});
		} catch (e) {
			console.error(e);
			try {
				let data = await e.json();
				if (data.error) {
					return this.setError(data.error);
				}
			} catch (e) {}
			return this.setError('An unknown error occured, sorry!');
		}
		this.setState({ loading: false });
	}

	render() {
		const state = this.state;
		const region = this.props.appData?.meta?.region?.slug;
		const baseAccount = baseAccountPerEmail[state.email] || 'a224342849p404573235';
		const accountCreationLinkRaw = `https://analytics.google.com/analytics/web/#/${baseAccount}/admin/account/create`;
		const accountCreationLink = `https://accounts.google.com/AccountChooser?continue=${encodeURIComponent(accountCreationLinkRaw)}&Email=${encodeURIComponent(state.email)}}`;
		return (
			<form class={s.container} onSubmit={this.submit} onKeyDown={this.onKeyDown}>
				<label class={joinClasses(s.line, s.googleAccountWrapper, state.loadingAccountSummaries && s.loading)}>
					<span class={s.description}>Google account</span>
					<select ref={e => this.$gAcc = e} onChange={this.onEmailChange} value={state.email}>
						{availableGoogleEmails.map(email => <option value={email} ket={email}>{email}</option>)}
					</select>
				</label>
				<label class={joinClasses(s.line, s.analyticsAccountWrapper, state.inexistantAccount && s.showInexistantWarning)}>
					<span class={s.description}>Analytics account</span>
					<InputWithSuggestions ref={e => this.$aAcc = e} list={state.accountNames} onChange={this.onAccountChange} />
					<div class={joinClasses(s.warning, s.inexistantWarning)}>
						The analytics account you entered does not exist in the Google account <code>{state.email}</code>. Please ensure you have the correct Google account selected in the drop down above. Or <a href={accountCreationLink} target="_blank">click here</a> to manually create a new Analytics account on Google Analytics.
					</div>
				</label>
				<label class={joinClasses(s.line, s.propertyWrapper)}>
					<span class={s.description}>Property</span>
					<InputWithSuggestions ref={e => this.$prop = e} list={state.propertyNames} onChange={this.onPropertyChange} />
				</label>
				<label class={joinClasses(s.line, s.propertyWrapper)}>
					<input type="checkbox" name="multiregion" onChange={this.linkState('multiRegion')} checked={state.multiRegion} /> Multi-Region
				</label>
				<div class={s.line}>
					Stream: <span class={s.value}>{state.multiRegion ? region : state.propertyName}</span>
				</div>
				<div class={s.error}>
					{state.error}
				</div>
				<div class={s.buttons}>
					<button class={s.cancel} type="button" onClick={this.cancel}>Cancel</button>
					<button class={joinClasses(s.confirm, state.loading && s.loading)} type="submit">{state.inexistantProperty ? 'Create' : 'Confirm'}</button>
				</div>
			</form>
		);
	}

}
