import { h } from 'preact';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import pure from 'utils/pure';
import s from 'components/shared/editor/formEditor/formEditor.sss';


export default @pure class ChecklistAttribute extends DisplayAttribute {
	constructor(props) {
		super(props);
		this.listItemRefs = {};
		this.checklistItems = this.props.jsonSchema.list;
	}

	updateAndSave = () => {
		const value = Object.values(this.listItemRefs).filter(e => e.checked).map(e => e.name);
		this.setState({ value: value.join(',') });
		this.onSave();
	}

	getInput = () => {
		return (
			<div class={s.checklist}>
				{this.checklistItems.map(item => (
					<div class={s.checklistItem}>
						<label>{item}</label>
						<input
							name={item}
							ref={ref => this.listItemRefs[item] = ref}
							class={s.input}
							type="checkbox"
							onChange={this.updateAndSave}
							checked={this.state.value.split(',').includes(item)}
						/>
					</div>
				))}
			</div>
		);
	}
}