import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import pure from 'utils/pure';
import { sortObjectByOrder, accessNested, joinClasses } from 'utils/utils';
import { buildApp } from 'services/buildApi.js';
import dateDistance from 'utils/dateDistance.mjs';

import { Link } from 'components/core/link/link.js';
import MergeBranchModal from 'components/shared/modal/mergeBranchModal.js';
import MyAppsItem from 'components/shared/infoSidebar/myApps/myAppsItem.js';
import SidebarNotes from 'components/shared/infoSidebar/sidebarNotes/sidebarNotes.js';

import s from 'components/shared/infoSidebar/myApps/myApps.sss';

const BUILDTYPES = {
	comingSoon: 'comingSoon',
	local: 'local',
	demo: 'demo',
	prod: 'prod'
};

/**
 * My Apps screen with currently build apps
 * @prop {Array} buildAppList - array with apps to be build
 * @prop {Object} myApps - object with all of the currently build apps
 * @prop {Function} toggleIssues - toggle to issues view
 * @prop {String} activeLevel - current active Level
**/
export default @pure class MyApps extends Component {

	constructor() {
		super();
		this.state.saveStarted = false;
		// this.state.activeAppMode = BUILDTYPES.local;
	}

	/**
	 * Data Handlers
	**/

	build = async (type, debug) => {
		if (type !== 'local' && type !== 'demo') {
			let allowBranchBuild = accessNested(this.props, 'merged.dev.allowBranchBuild');
			if (this.props.branch && !allowBranchBuild) {
				// eslint-disable-next-line no-alert
				alert('ERROR: trying to build production app from a branch');
				return;
			}

			let warningLevel = this.props.warningLevel;
			// Error
			if (warningLevel > 2) {
				// eslint-disable-next-line no-alert
				alert('Remaining errors, please check by hovering the 🚫 icon');
				return;
			}
			// Warning
			if (warningLevel === 2) {
				// eslint-disable-next-line no-alert
				let buildAnyway = confirm('There are warnings remaining, build anyway?');
				if (!buildAnyway) return;
			}
		}
		this.toggleActive(type);
		this.setState({ loadingApps: type });
		let list = await this.props.getAppsList();
		this.setState({ loadingApps: false });
		try {
			let data = await buildApp(list, type, list.title, debug);
			if (data?.error) {
				let header = [data.statusCode, data.error].filter(e => e).join(' - ');
				let message = data.message;
				let extras;
				if (data.code === 'NEEDS_PULL') {
					const extra = {
						'fetch-time': ({ lastFetch }) => `Your last pull was ${+lastFetch ? dateDistance(new Date(+lastFetch)) : 'a long time ago'}`,
						'unpulled-changes': ({ count }) => `There ${count == 1 ? 'is' : 'are'} ${count || 'some'} change${count == 1 ? '' : 's'} waiting to be pulled`,
					}[data.details?.reason]?.(data.details);
					header = `Please pull!: ${extra || ''}`;
					extras = [
						{
							type: 'pull-button',
							callback: () => {
								Store.emit(actions.HIDE_MODAL);
								this.build(type, debug);
							}
						}
					];
				}
				let errorMsg = [header, message].filter(e => e).join('\n');
				let errorData = { status: { build: { errorAt: Date.now(), error: errorMsg, errorExtras: extras } } };
				list.forEach(app => Store.emit(actions.UPDATE_MY_APP, errorData, app.meta.id));
			}
		} catch (err) {
			console.log('error building app', err);
			list.forEach(app => Store.emit(actions.UPDATE_MY_APP, { status: { build: { errorAt: Date.now(), error: 'Error' } } }, app.meta.id));
		}
	};

	getAppsByType = (apps) => {
		let perType = Object.keys(apps).reduce((perType, appKey) => {
			const app = apps[appKey];
			if (!app || !perType[app.buildType]) {
				return perType;
			}
			perType[app.buildType].push(app);
			return perType;
		}, {
			comingSoon: [],
			local: [],
			demo: [],
			prod: [],
		});

		Object.keys(perType).forEach(type => perType[type] = sortObjectByOrder(perType[type]).reverse());

		return perType;
	};

	/**
	 * Interaction Handlers
	 */

	toggleActive = (type) => {
		// this.setState({ activeAppMode: type });
		Store.emit(actions.SET_ACTIVE_APP_MODE, type);
	};

	mergeBranch = () => {
		if (this.props.data.edited) {
			// eslint-disable-next-line no-alert
			alert('Make sure all the data is saved before starting to merge.');
			return;
		}
		Store.emit(actions.SHOW_MODAL, <MergeBranchModal data={this.props.data} branch={this.props.branch} getMerged={this.props.getMerged} />);
	};


	render(props) {
		let { activeAppMode } = props;
		if (!activeAppMode) {
			activeAppMode = BUILDTYPES.local;
		}

		// const { localApps, demoApps, prodApps, comingSoonApps } = this.getAppsByType(props.myApps || {});
		let perType = this.getAppsByType(props.myApps || {});
		let currentApps = perType[activeAppMode];

		let buildApps;

		if (props.activeLevel === 'apps' || props.activeLevel === 'titles') {
			let comingSoon = accessNested(props, 'merged.dev.comingSoonMode');
			let allowBranchBuild = accessNested(props, 'merged.dev.allowBranchBuild');
			let prodDisabled = comingSoon || (props.branch && !allowBranchBuild);
			let load = this.state.loadingApps;
			let buildButtons = [
				{ text: 'Local', type: BUILDTYPES.local },
				{ text: 'Demo', type: BUILDTYPES.demo },
				{ text: 'Production', type: BUILDTYPES.prod, disabled: prodDisabled },
			].map(btn => {
				let onClick = () => this.build(btn.type);
				let onContextMenu = e => {
					e.preventDefault();
					Store.emit(
						actions.SHOW_CONTEXT_MENU,
						{ x: e.clientX, y: e.clientY },
						[
							{ name: 'Start', action: onClick, autoClose: true },
							{ name: 'Start in Debug Mode', action: () => this.build(btn.type, true), autoClose: true },
						].filter(e => e)
					);
				};
				return (
					<button
						class={joinClasses(s.build, load === btn.type && s.loading, btn.disabled && s.disabled)}
						onClick={!btn.disabled && onClick}
						onContextMenu={!btn.disabled && onContextMenu}
						disabled={btn.disabled}
					>
						{btn.text}
						<div class={s.loadingIcon} />
					</button>
				);
			});

			buildApps = (
				<div key="build-buttons">
					<p class={s.subTitle} key="subtitle">Build for</p>
					{/* {comingSoon ? <div class={s.buildContainer} key="comingsoon"><button class={s.build} onClick={this.buildComingSoon}>Coming Soon</button></div> : null} */}
					<div class={s.buildContainer} key="buttons">
						{buildButtons}
					</div>
					{props.branch && (
						<div class={s.warning} key="branchwarning">
							You are working on a branch. Switch back to Production to deploy to production
							<Link queryString={{ branch: null }} pageId="editApp" class={s.warningBtn}>Switch to Production</Link>
							<button class={s.warningBtn} onClick={this.mergeBranch}>Merge into Production</button>
						</div>
					)}
					{!this.props.branch && <div class={s.divider} key="divider" />}
				</div>
			);
		}

		let subMenus = [
			{ type: 'local', name: 'Local' },
			{ type: 'demo', name: 'Demo' },
			{ type: 'prod', name: 'Prod' },
		].map(subMenu => {
			return (
				<button
					key={subMenu.type}
					onClick={() => this.toggleActive(subMenu.type)}
					class={joinClasses(s.subMenuItem, activeAppMode === subMenu.type && s.active)}
				>
					{subMenu.name} <span class={s.subMenuItemCount}>{perType[subMenu.type].length}</span>
				</button>
			);
		});

		return (
			<div class={s.container}>
				{buildApps}
				<SidebarNotes
					merged={this.props.merged}
					data={this.props.data}
					activeLevel={this.props.activeLevel}
					saveData={this.props.saveData}
					key="notes"
				/>
				<p class={s.subTitle} key="subtitle">Active Apps</p>
				<div class={s.subMenu} key="submenu">
					{subMenus}
				</div>
				<div class={s.appsList} key="apps">
					{currentApps.map(app => (
						<MyAppsItem
							key={app.appId}
							app={app.app}
							buildType={app.buildType}
							status={app.status}
							toggleIssues={this.props.toggleIssues}
							dataChanged={app.dataChanged}
						/>
					))}
				</div>
			</div>
		);

	}

}
