import { h } from 'preact';

const Snapchat = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" {...props}>
			<path d="M12.117 20.248c-.05 0-.098-.002-.134-.004a1.03 1.03 0 0 1-.088.004c-1.045 0-1.744-.494-2.361-.93-.443-.313-.86-.608-1.348-.69-.24-.04-.479-.06-.71-.06-.415 0-.743.065-.983.112a2.185 2.185 0 0 1-.373.054c-.103 0-.228-.023-.28-.201-.042-.14-.072-.277-.1-.408-.072-.327-.124-.527-.246-.546-1.312-.203-2.087-.5-2.24-.862a.335.335 0 0 1-.028-.114.21.21 0 0 1 .176-.22c1.041-.17 1.967-.721 2.752-1.636a6.171 6.171 0 0 0 .944-1.47c.151-.306.181-.571.09-.787-.17-.399-.729-.577-1.1-.694a3.955 3.955 0 0 1-.248-.084c-.328-.13-.868-.404-.796-.782.052-.276.417-.468.712-.468.082 0 .154.015.215.043.333.156.633.235.89.235.321 0 .476-.122.513-.156l-.031-.523v-.002c-.076-1.197-.17-2.686.211-3.54 1.14-2.555 3.558-2.754 4.271-2.754l.311-.003h.043c.715 0 3.138.199 4.279 2.756.38.854.287 2.345.211 3.543l-.003.057c-.01.162-.02.316-.028.466.035.032.176.144.465.155.245-.01.528-.088.838-.233a.663.663 0 0 1 .275-.055c.11 0 .22.021.313.06l.005.002c.264.094.436.28.44.477.003.183-.133.457-.802.722a4.062 4.062 0 0 1-.249.084c-.37.118-.93.295-1.099.693-.092.216-.061.482.09.789l.005.01c.046.11 1.174 2.681 3.691 3.096a.21.21 0 0 1 .176.22.338.338 0 0 1-.028.114c-.153.36-.928.657-2.24.86-.123.018-.175.217-.246.543-.029.135-.059.267-.1.405-.038.13-.123.194-.259.194h-.02a2.1 2.1 0 0 1-.374-.047 4.91 4.91 0 0 0-.983-.104c-.231 0-.47.02-.71.06-.488.081-.905.376-1.346.688-.619.437-1.318.931-2.363.931" />
		</svg>
	);
};

export default Snapchat;