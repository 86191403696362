import { h, Component } from 'preact';
import s from 'components/pages/theatersMatcher/filterTheaterMatcher.sss';
import { getProvidersList, apiGet, apiUpdate, apiCreate } from 'services/dataApi';
import deserialise from 'utils/deserialise';
import { joinClasses, debounce } from 'utils/utils';

// Duplicated from theaterUnmatcher. Can we make it a common file ?
// TODO: endpoint
const countries = (
	'ad,ae,al,ao,ar,at,au,aw,az,ba,bd,be,bf,bg,bh,bm,bo,bq,br,bw,by,ca,ch,cl,' +
	'cm,cn,co,cr,cw,cy,cz,de,dk,do,dz,ec,ee,eg,en,es,fi,fj,fl,fo,fr,ga,gb,ge,' +
	'gi,gl,gr,gt,gu,hk,hn,ho,hr,hu,id,ie,il,in,iq,is,it,je,jm,jo,jp,ke,kg,kr,' +
	'kw,ky,kz,lb,lc,li,lt,lu,lv,ma,mc,me,mg,mk,mn,mo,mp,mt,mx,my,na,ng,ni,nl,' +
	'no,nz,om,pa,pe,pg,ph,pk,pl,pn,pr,pt,py,qa,qc,re,ro,rs,ru,sa,se,sg,sh,si,' +
	'sk,sn,sp,sv,sx,sz,th,tr,tt,tw,tx,ua,uk,us,uy,ve,vn,xk,za,zw'
).split(',');

const states = [
	'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL',
	'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH',
	'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM',
	'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC',
	'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
];

// From https://github.com/thekelvinliu/country-code-emoji
let getFlag = country => String.fromCodePoint(...[...country.toUpperCase()].map(c => c.charCodeAt() + 127397));

export default class FilterTheaterMatcher extends Component {

	// LIFE CYCLE

	constructor() {
		super();
		this.countryInputChange = this.countryInputChange.bind(this);
		this.providerInputChange = this.providerInputChange.bind(this);
		this.stateInputChange = this.stateInputChange.bind(this);
		this.refreshTheaters = this.refreshTheaters.bind(this);
		this.getUpcomingSuggestions = this.getUpcomingSuggestions.bind(this);
		this.updateSearch = debounce(this.updateSearch, 700);
		this.updateFilterSearch = debounce(this.updateFilterSearch, 700);

		this.state = {
			toMatch: [],
			masterList: [],
			previous: null,
			current: 0,
			providers: [],
			selectedSuggestion: '',
			limit: 50,
			allTheaters: {},
			currentSearchPagination: 0,
			currentUnmatchedPagination: 0,
			searchTerm: '',
			country: 'us',
			state: '',
			provider: '',
			searchFilterTerm: '',
			loadingTheaters: true,
			done: false,
			requestingMore: false,
			savedForLater: 0,
			multipleSelected: []
		};

		if (typeof window !== 'undefined') {
			getProvidersList().then(response => {
				this.setState({ providers: response.map(e => e.id).sort() });
			});
		}
	}

	componentDidMount() {
		this.refreshTheaters();
	}

	// FUNCTIONS

	//unmatchedTheater theater process
	theatersRequest() {
		let queryParams = [];
		if (this.state.country) {
			queryParams.push('country=' + this.state.country);
		}
		if (this.state.provider) {
			queryParams.push('provider=' + this.state.provider);
		}
		//to make sure the it is us
		if (this.state.country === 'us' && this.state.state && this.state.state !== '') {
			queryParams.push('state=' + this.state.state);
		}
		if (this.state.searchFilterTerm !== '') {
			queryParams.push('term=' + this.state.searchFilterTerm);
		}
		if (this.state.currentUnmatchedPagination >= this.state.limit) {
			queryParams.push('from=' + this.state.currentUnmatchedPagination);
		}
		apiGet('/proxy/stdata/theaters/search?' + queryParams.join('&')).then(deserialise).then(response => {
			this.setState({ masterList: response.map(e => e.id).sort() });
		});
		return apiGet('/proxy/stdata/theaters/unmatched?' + queryParams.join('&')).then(deserialise);
	}

	refreshTheaters() {
		this.setState({ loadingTheaters: true, done: false });
		this.theatersRequest().then(response => {
			this.setState({ toMatch: response, current: 0, previous: null, loadingTheaters: false }, this.getUpcomingSuggestions);
			// this.setState({ multipleSelected: [this.state.toMatch[0].id]});
		}).catch(err => {
			console.log(err);
			this.setState({ error: err, loadingTheaters: false });
		});
	}

	// suggestions
	getUpcomingSuggestions() {
		const multipleSelected = this.state.multipleSelected;
		if (!multipleSelected) return;
		if (multipleSelected.find(multi => !!this.state[`sugg_${multi}`])) {
			return false;
		}

		let newState = {};
		this.state.toMatch.forEach(t => {
			if (multipleSelected.indexOf(t.id) === -1) {
				return;
			}
			apiGet('/proxy/stdata/theaters/matcher/suggestions?id=' + t.id).then(deserialise).then(response => {
				let tmid = t.attributes.theater_master_id;
				if (tmid) {
					response.forEach(suggestion => {
						if (suggestion.attributes.theater_master_id === tmid) {
							suggestion.selected = true;
						}
					});
				}
				let results = response.filter(r => r.attributes.theater_master_id);

				newState['sugg_' + t.id] = { value: results };
				this.setState(newState);
			});
		});
	}

	// search
	updateSearch = (e) => {
		if (e && e.target.value) {
			this.setState({ searchTerm: e.target.value });
		}
		let queryParams = [];
		if (this.state.country) {
			queryParams.push('country=' + this.state.country);
		}
		if (this.state.currentStartingPagination) {
			queryParams.push('from=' + this.state.currentStartingPagination);
		}
		if (this.state.searchTerm !== '') {
			queryParams.push('term=' + this.state.searchTerm);
		}
		apiGet('/proxy/stdata/theaters/search?'+ queryParams.join('&')).then(deserialise).then(response => {
			let results = [];
			response.forEach(r => {
				if (r.attributes.theater_master_id) {
					results.push(r);
				}
			});
			this.setState({ allTheaters: results });
		});
	}

	// filter search
	updateFilterSearch = (e) => {
		if (e && e.target.value) {
			this.setState({ searchFilterTerm: e.target.value }, this.refreshTheaters);
		}
	}

	// match process
	setMaster(theater, masterId) {
		if (theater.attributes.theater_master_id === masterId) {
			return Promise.resolve();
		}
		let payload = {
			data: {
				attributes: {
					theater_master_id: masterId,
					theater_master_confidence: 100
				}
			}
		};
		// Instantly update the local version
		theater.attributes.theater_master_id = masterId;
		theater.attributes.theater_master_confidence = 100;
		return apiUpdate('/proxy/stdata/theaters/' + theater.id, payload);
	}

	validateSelection(theaterMatch) {
		let state = this.state;

		state.toMatch.forEach(t => {
			if (this.state.multipleSelected.indexOf(t.id) === -1) {
				return;
			}

			let selectedMatch = theaterMatch;
			let finalMaster = (selectedMatch && selectedMatch.attributes.theater_master_id) || null;
			// Selected a suggestion that does not have a master id yet: create one and assign ot both
			if (selectedMatch && !finalMaster) {
				apiCreate('/proxy/stdata/theater_masters', { data: { attributes: { created_at: new Date() } } }).then(response => {
					if (response.errors) throw new Error(response.errors);
					let tmid = response.data.id;
					return this.setMaster(selectedMatch, tmid).then(() => this.setMaster(t, tmid)).then(() => this.refreshTheaters());
				});
			} else {
				this.setMaster(t, finalMaster);
			}
		});
	}


	// CLICK HANDLERS

	// filters (region, state, provider)
	countryInputChange(e) {
		this.selectCountry(e.target.value);
	}
	providerInputChange(e) {
		this.setState({
			multipleSelected: [],
			currentUnmatchedPagination: 0,
			currentSearchPagination: 0
		});
		this.selectProvider(e.target.value);
	}

	stateInputChange(e) {
		this.setState({
			multipleSelected: [],
			currentUnmatchedPagination: 0,
			currentSearchPagination: 0
		});
		this.selectState(e.target.value);
	}

	selectState(state) {
		this.setState({state: state}, this.refreshTheaters);
	}

	selectCountry(country) {
		this.setState({
			multipleSelected: [],
			currentUnmatchedPagination: 0,
			currentSearchPagination: 0
		});
		if (country && countries.indexOf(country) === -1) return;
		this.setState({ country }, this.refreshTheaters);
		this.renderSearch();
	}
	selectProvider(provider) {
		if (provider && this.state.providers.indexOf(provider) === -1) return;
		this.setState({ provider }, this.refreshTheaters);
	}

	clearFilters = () => {
		this.setState({
			searchFilterTerm: '',
			country: 'us',
			state: '',
			provider: '',
		});
		this.$searchFilterTerm.value = '';
		this.refreshTheaters();
	}

	//for theaters that are unmatched but should be hidden for a little bit (feature request)
	later = (e) => {
		const selected = e.target.parentElement.__preactattr_.key;
		const theaters = this.state.toMatch;
		let newTheaters = [];
		theaters.map((theater) => {
			if (theater.id !== selected) {
				newTheaters.push(theater);
			}
		});
		this.setState({
			toMatch: newTheaters,
			savedForLater: this.state.savedForLater += 1
		});
		this.getUpcomingSuggestions();
	}

	//matching selection
	selectSuggestion(suggestion) {
		if (!suggestion) return;
		this.validateSelection(suggestion);
		this.refreshTheaters();
	}

	addToMultipleSelect = (e) => {
		const id = e.target.parentNode.parentNode.parentNode.__preactattr_.key;
		const newSelected = this.state.multipleSelected;
		if (newSelected.indexOf(id) === -1) {
			newSelected.push(id);
			this.setState({multipleSelected: newSelected});
			this.getUpcomingSuggestions();
		} else {
			const sliceIndex = newSelected.findIndex(arrayId => {
				if (arrayId === id) {
					return id;
				}
			});
			newSelected.splice(sliceIndex, 1);
			this.setState({multipleSelected: newSelected});
		}
	}

	//pagination
	nextSearchPagination = () => {
		let currentSearchPagination = this.state.currentSearchPagination;
		this.setState({currentSearchPagination: currentSearchPagination + this.state.limit});
		this.updateSearch();
	}

	prevSearchPagination = () => {
		let currentSearchPagination = this.state.currentSearchPagination;
		if (currentSearchPagination - this.state.limit > 0) {
			this.setState({currentSearchPagination: currentSearchPagination - this.state.limit});
		}
		this.updateSearch();
	}
	nextUnmatchedPagination = () => {
		let currentUnmatchedPagination = this.state.currentUnmatchedPagination;
		this.setState({
			currentUnmatchedPagination: currentUnmatchedPagination + this.state.limit,
			savedForLater: 0
		});
		this.refreshTheaters();
	}

	prevUnmatchedPagination = () => {
		let currentUnmatchedPagination = this.state.currentUnmatchedPagination;
		if (currentUnmatchedPagination - this.state.limit > 0 || currentUnmatchedPagination - this.state.limit === 0) {
			this.setState({
				currentUnmatchedPagination: currentUnmatchedPagination - this.state.limit,
				savedForLater: 0
			});
			this.refreshTheaters();
		}
	}

	// RENDER METHODS (main at bottom)

	renderTheater(theater, unmatched) {
		if (!theater) return;
		let attr = theater.attributes;
		let city = attr.city_en || attr.city || '<no city>';
		if (attr.state) {
			city += ', ' + attr.state;
		}
		if (attr.postcode) {
			city += ', ' + attr.postcode;
		}

		let idStyle = {
			backgroundColor: 'hsl(' + (360 * this.state.providers.indexOf(attr.provider_id) / this.state.providers.length) + ', 50%, 50%)'
		};

		return (
			<div
				class={this.state.multipleSelected.indexOf(theater.id) !== -1 ? s.selectedTheater : s.theater}
				key={theater.id}
			>
				<div class={s.country}>{getFlag(attr.country)}</div>
				<div class={s.id} style={idStyle}>
					<span>{attr.provider_id}</span>:{attr.provider_theater_id}
				</div>
				<div class={s.later} onClick={this.later}>👈🏽 Look at Later</div>
				<div class={s.name}>{attr.name_en || attr.name}</div>
				<div class={s.location}>
					<div class={s.address}>{attr.address_en || attr.address}</div>
					<div class={s.city}>{city}</div>
					<a class={s.pin} href={'http://maps.google.com/?q='+attr.lat+','+attr.lon} target="_blank">{attr.lat}, {attr.lon}</a>
				</div>
				{unmatched && <li class={s.fakeCheckbox}>
					<label><input onChange={this.addToMultipleSelect} type="checkbox" /></label>
				</li>}
				<p class={s.id}>{theater.id}</p>
			</div>
		);
	}

	renderSearch() {
		const allTheaters = this.state.allTheaters;

		let allTheatersData = Object.keys(allTheaters).map(theater => allTheaters[theater]);

		if (!allTheatersData[0]) {
			return (
				<div class={s.search}>
					<div class={s.result}>Nothing matches your search criteria</div>
				</div>
			);
		}
		let pagination;
		if (allTheatersData.length === this.state.limit) {
			pagination = [
				<button class={s.prevPagination} onClick={this.prevSearchPagination} key="prev">Prev</button>,
				<button class={s.nextPagination} onClick={this.nextSearchPagination} key="next">Next</button>
			];
		}
		return (
			<div class={s.search}>
				<h2 class={s.title}>Search Results</h2>
				<div class={s.searchResults}>
					{allTheatersData.map(theater => {
						let show = this.state.multipleSelected.find(t => t === theater.id);
						return (
							<div class={s.result} key={theater.id}>
								{this.renderTheater(theater)}
								{show && <button class={s.select} onClick={() => this.selectSuggestion(theater)}>
									<span class={s.text}>Select</span>
								</button>}
							</div>
						);
					})}
					{pagination}
				</div>
			</div>
		);
	}

	renderTheaters() {
		const unmatched = this.state.toMatch;
		if (unmatched.length === 0) {
			return (
				<div>
					<h2>All Theaters Matched! Or Search result <span class={s.noResultTerm}>{this.state.searchFilterTerm}</span> returned nothing</h2>
					<div class={s.clearFilters} onClick={() => document.location.reload()}>Reset</div>
				</div>
			);
		}
		return (
			<div class={s.unmatched}>
				<h2 class={s.title}>Unmatched Theaters</h2>
				<div class={s.buttons}>
					<button class={this.state.currentUnmatchedPagination < this.state.limit ? s.prevPaginationDisabled : s.prevPagination} onClick={this.prevUnmatchedPagination}>Prev</button>
					{unmatched.length === this.state.limit || (unmatched.length + this.state.savedForLater) === this.state.limit && <button class={s.nextPagination} onClick={this.nextUnmatchedPagination}>Next</button>}
				</div>
				{unmatched.map(theater => this.renderTheater(theater, true))}
				<div class={s.buttons}>
					{unmatched.length === this.state.limit && <button class={this.state.currentUnmatchedPagination < this.state.limit ? s.prevPaginationDisabled : s.prevPagination} onClick={this.prevUnmatchedPagination}>Prev</button>}
					{unmatched.length === this.state.limit && <button class={s.nextPagination} onClick={this.nextUnmatchedPagination}>Next</button>}
				</div>

			</div>
		);
	}

	renderSelector() {
		if (this.state.toMatch.length < 0) return;
		if (this.state.multipleSelected.length < 0) return;
		let state = this.state;
		if (state.loadingTheaters) {
			return <div class={joinClasses(s.selector, s.loading)}><div class={s.loader} /></div>;
		}

		// let multipleSuggestedElem;

		return state.multipleSelected.map(current => {
			let attr;

			this.state.toMatch.forEach(t => {
				if (t.id === current) {
					attr = t.attributes;
				}
			});

			let masterIds = {};
			if (attr && attr.theater_master_id) {
				masterIds[attr.theater_master_id] = true;
			}

			let suggestions = current && state['sugg_' + current];
			suggestions = suggestions && suggestions.value;

			if (suggestions) {
				suggestions.forEach(suggestion => {
					let tmid = suggestion.attributes.theater_master_id;
					if (tmid) {
						masterIds[tmid] = true;
					}
				});
			}
			let suggestionsElem = (
				<div>
					<h2>Loading Suggestions</h2>
					<br/>
					<p>Make sure you have selected an Unmatched Theater (from left)</p>
					<div class={s.loader}/>
				</div>
			);
			let title = '';
			if (suggestions) {
				title = 'Suggestions';
				suggestionsElem = suggestions.map((suggestion) => (
					<div class={s.theaterContainer}>
						{this.renderTheater(suggestion)}
						<button
							class={joinClasses(s.select, suggestion.selected && s.selected)}
							style={{
								backgroundColor: 'hsl(' + (60 + 360 * this.state.masterList.indexOf(suggestion.attributes.theater_master_id) / this.state.masterList.length) + ', 100%, 98%)'
							}}
							onClick={() => this.selectSuggestion(suggestion)}
						>
							<span class={s.selectMasterId}>theater_master_id: {suggestion.attributes.theater_master_id}</span><span class={s.text}>Select</span>
						</button>
					</div>
				));
			}
			return (
				<div class={s.selector}>
					<div class={s.right}>
						<h2 class={s.title}>{title}</h2>
						<div class={joinClasses(s.suggestions, !suggestions && s.loading)}>
							{suggestionsElem}
						</div>
					</div>
				</div>
			);
		});
	}

	render() {
		return (
			<div class={s.container}>
				<div class={s.filters}>
					<div class={s.countryWrapper}>
						Region:
						<select class={s.countrySelect} value={this.state.country} onInput={this.countryInputChange}>
							<option value="">all</option>
							{countries.map(country => <option value={country}>{country}</option>)}
						</select>
					</div>
					{this.state.country === 'us' && <div class={s.stateWrapper}>
						State:
						<select class={s.stateSelect} value={this.state.state} onInput={this.stateInputChange}>
							<option value="">all</option>
							{states.map(state => <option value={state}>{state}</option>)}
						</select>
					</div>}
					<div class={s.providerWrapper}>
						Provider:
						<select class={s.providerSelect} value={this.state.provider} onInput={this.providerInputChange}>
							<option value="">all</option>
							{this.state.providers.map(provider => <option value={provider}>{provider}</option>)}
						</select>
					</div>
					{this.state.toMatch.length > 0 && <div class={s.overviewMessage}>
						{this.state.currentUnmatchedPagination + 1} - <span>{this.state.currentUnmatchedPagination + this.state.toMatch.length}</span> Unmatched Theaters
					</div>}
					{this.state.savedForLater > 0 && <div class={s.overviewMessage}><span>{this.state.savedForLater}</span> Saved For Later</div>}
					<div class={s.searchWrapper}>
						<input class={s.searchInputFilter} placeholder="🔍 Filter UNMATCHED Theaters by Name, Address or City  🔎" ref={e => this.$searchFilterTerm = e} value={this.state.searchFilterTerm} onInput={this.updateFilterSearch} type="text" />
					</div>
					<div class={s.clearFilters} onClick={this.clearFilters}>Clear Filters</div>
					<div class={s.searchWrapper}>
						<input class={s.searchInput} placeholder="🔍 Search MATCHED Theaters by Name, Address or City  🔎" ref={e => this.$searchTerm = e} onInput={this.updateSearch} type="text" />
					</div>
				</div>
				<div class={s.unmatchedContainer}>
					{this.renderTheaters()}
					<div class={s.matching}>
						{this.renderSelector()}
						{this.renderSearch()}
					</div>
				</div>
				<div class={s.matchedContainer}>
				</div>
			</div>
		);
	}
}
