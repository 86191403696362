import { h, Component } from 'preact';
import pure from 'utils/pure';
import { accessNested, joinClasses } from 'utils/utils';
import s from 'components/shared/seo/seo.sss';
import Loading from 'components/shared/misc/loading';
import dateDistance from 'utils/dateDistance.mjs';
import { replaceWithVNode } from 'utils/vnode';

function camelCase(str) {
	return str.toLowerCase().replace(/_(.)/g, (m, c) => c.toUpperCase());
}

export default @pure class AppInfo extends Component {

	constructor(props) {
		super(props);
		this.apiKey = 'AIzaSyBJOOiAGROAsEQf-dmuTcoDZL6QVw-t9nw';
		this.state = {};
		try {
			this.state.seoResults = JSON.parse(localStorage.getItem('seo'));
			this.state.resultsFrom = +localStorage.getItem('lastSeoSearch') || 0;
		} catch(e) {}
		this.expandHandlerCache = {};
		this.updateData = this.updateData.bind(this);
		this.renderImageUrl = this.renderImageUrl.bind(this);
	}

	componentWillMount() {
		let lastSeoUrl;
		try {
			lastSeoUrl = localStorage.getItem('lastSeoUrl');
		} catch(err) {}
		// Check if data is older than 1 day, if there was no prior search, or if the URL has changed.
		let pageUrl = accessNested(this.props, 'data.attributes.meta.url');
		let shouldUpdate = pageUrl !== lastSeoUrl || this.state.resultsFrom < Date.now() - 24 * 60 * 60 * 1000;
		if (shouldUpdate) {
			this.updateData();
		}
	}

	getExpandHandler(ruleType) {
		if (!this.expandHandlerCache[ruleType]) {
			this.expandHandlerCache[ruleType] = () => {
				let key = 'expanded' + ruleType;
				let update = {};
				update[key] = !this.state[key];
				this.setState(update);
			};
		}
		return this.expandHandlerCache[ruleType];
	}

	// Format data returned by API into readable string
	//   e.g. { format: "There are {{COUNT}} errors", args: { type: 'INT_LITERAL', key: 'COUNT', value: '17' } }
	//        returns "There are 17 errors"
	// note: removes hyperlinks
	formatStringToPlainText(frmt) {
		if (!frmt || !frmt.format) {
			return '';
		}
		let newStr = frmt.format;
		(frmt.args || []).forEach(arg => {
			if (arg.type === 'HYPERLINK') {
				newStr = newStr.replace('{{BEGIN_' + arg.key + '}}', '').replace('{{END_' + arg.key + '}}', '');
				return;
			}
			newStr = newStr.replace('{{' + arg.key + '}}', arg.value);
		});
		return newStr;
	}
	formatStringToJSX(frmt) {
		if (!frmt) {
			return;
		}
		let base = <span class={s.formatString}>{frmt.format}</span>;
		(frmt.args || []).forEach(arg => {
			let { type, key, value } = arg;
			if (type === 'HYPERLINK') {
				base = replaceWithVNode(base, new RegExp('{{BEGIN_' + key + '}}(.*?){{END_' + key + '}}', 'g'), match => {
					return <a class={s.hyperlink} href={value} target="_blank">{match[1]}</a>;
				});
				return;
			}
			if (type === 'URL') {
				base = replaceWithVNode(base, '{{' + key + '}}', <a class={s.url} href={value} target="_blank">{value}</a>);
				return;
			}
			base = replaceWithVNode(base, '{{' + key + '}}', <span class={s[camelCase(type)]}>{value}</span>);
		});
		return base;
	}

	renderImageUrl(url) {
		let result = url.result;
		let imageArg = result.args && result.args.find(arg => arg.key === 'URL');
		if (!imageArg) {
			return <li class={s.url}>{this.formatStringToJSX(result)}</li>;
		}
		let sizeArg = result.args.find(arg => arg.key === 'SIZE_IN_BYTES');
		let percentageArg = result.args.find(arg => arg.key === 'PERCENTAGE');
		let savings = [
			sizeArg && 'Could save ' + sizeArg.value,
			percentageArg && '(' + percentageArg.value + ' reduction)',
		].filter(e => e).join(' ');
		return (
			<li class={s.imageUrl}>
				<img class={s.preview} src={imageArg.value} alt={'Preview of ' + imageArg.value} />
				<div class={s.details}>
					<a class={s.link} href="" target="_blank">{imageArg.value}</a>
					<div class={s.savings}>{savings}</div>
				</div>
			</li>
		);
	}

	renderUrlBlock(block, renderUrl) {
		if (!block || !block.header) {
			return;
		}
		if (typeof renderUrl !== 'function') {
			renderUrl = url => <li class={s.url}>{this.formatStringToJSX(url.result)}</li>;
		}
		let urls;
		if (block.urls && block.urls.length) {
			urls = <ul class={s.urls}>{block.urls.map(renderUrl)}</ul>;
		}
		return (
			<div class={joinClasses(s.block, s.urlBlock)}>
				<div class={s.header}>{this.formatStringToJSX(block.header)}</div>
				{urls}
			</div>
		);
	}

	scoreType(score) {
		if (score >= 90) {
			return 'good';
		}
		if (score >= 50) {
			return 'average';
		}
		return 'bad';
	}
	impactCategory(impact) {
		if (impact > 19) {
			return 'important';
		}
		if (impact > 9) {
			return 'medium';
		}
		return 'low';
	}

	updateData() {
		this.setState({ seoResults: null, loading: true });

		let pageUrl = accessNested(this.props, 'data.attributes.meta.url');
		if (!pageUrl) {
			return;
		}
		let apiUrl = 'https://www.googleapis.com/pagespeedonline/v4/runPagespeed?url=' + pageUrl + '&key=' + this.apiKey;
		fetch(apiUrl).then(response => {
			if (response.status >= 400) {
				return response.json().then(err => Promise.reject(err));
			}
			return response.json();
		}).then(data => {
			let resultsFrom = Date.now();
			try {
				localStorage.setItem('lastSeoUrl', pageUrl);
				localStorage.setItem('lastSeoSearch', resultsFrom);
				localStorage.setItem('seo', JSON.stringify(data));
			} catch(e) {}
			this.setState({ seoResults: data, resultsFrom, loading: false });
		}).catch(err => {
			this.setState({ error: err, loading: false });
		});
	}

	render() {
		if (this.state.loading) {
			return <div class={s.loading}><Loading /></div>;
		}

		// Check for error returned by API
		if (this.state.error || !this.state.seoResults) {
			const errors = (accessNested(this.state, 'error.error.errors') || []).map(err => {
				return (
					<div class={s.error}>
						<div class={s.reason}>{err.reason}: {err.location}</div>
						<div class={s.message}>{err.message}</div>
					</div>
				);
			});
			return <div>{errors}</div>;
		}

		// Filter out impacts that are less than 1
		let rulesObj = accessNested(this.state.seoResults, 'formattedResults.ruleResults') || {};
		let rules = Object.keys(rulesObj).map(ruleType => {
			return {
				...rulesObj[ruleType],
				ruleType: ruleType
			};
		}).filter(rule => rule.ruleImpact > 1).sort((a, b) => {
			return b.ruleImpact - a.ruleImpact;
		});

		let intro = [];
		if (this.state.resultsFrom) {
			let at = new Date(this.state.resultsFrom);
			intro.push(
				<div class={s.refresh}>
					<span class={s.text} title={at.toString()}>This data was fetched {dateDistance(at)}.</span>
					<button class={s.button} onClick={this.updateData}>Refresh Data</button>
				</div>
			);
		}

		let speedScore = accessNested(this.state.seoResults, 'ruleGroups.SPEED.score');
		if (speedScore) {
			intro.push(
				<div class={joinClasses(s.speedScore, s[this.scoreType(speedScore)])}>
					<span class={s.text}>Global speed score:</span>
					<div class={s.value}>
						{speedScore}
						<svg class={s.meter} viewBox="0 0 100 100">
							<circle class={s.outline} cx="50" cy="50" r="50" />
							<circle class={s.fill} cx="50" cy="50" r="50" style={{ strokeDashoffset: 314.16 * (1 - speedScore / 100) }} />
						</svg>
					</div>
				</div>
			);
		}

		let content = <div class={s.none}>No optimizations necessary</div>;
		if (rules.length) {
			content = rules.map(rule => {
				if (!rule.ruleImpact) {
					return;
				}

				let hasMore = rule.urlBlocks && rule.urlBlocks.length;
				let extraContent;
				if (hasMore) {
					extraContent = (
						<div class={s.details}>
							{rule.urlBlocks.map(urlBlock => {
								return this.renderUrlBlock(urlBlock, rule.ruleType === 'OptimizeImages' ? this.renderImageUrl : null);
							})}
						</div>
					);
				}

				let impactCategory = this.impactCategory(rule.ruleImpact);

				return (
					<div class={joinClasses({ s, rule: true, collapsible: hasMore, expanded: this.state['expanded' + rule.ruleType] })}>
						<div class={s.title}>
							<div class={s.name}>{rule.localizedRuleName}</div>
							<div class={joinClasses(s.impact, s[impactCategory])}>
								<span class={s.label}>Impact</span>
								<span class={s.value}>
									{Math.round(rule.ruleImpact)}
								</span>
							</div>
						</div>
						<button class={s.summary} onClick={hasMore && this.getExpandHandler(rule.ruleType)}>{this.formatStringToJSX(rule.summary)}</button>
						{extraContent}
					</div>
				);
			});
		}

		return (
			<div class={s.container}>
				{intro}
				{content}
			</div>
		);
	}

}
