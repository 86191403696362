import { h, Component } from 'preact';
import pure from 'utils/pure';
import s from 'components/shared/modal/addNewModal.sss';
import Store from 'store/store';
import * as actions from 'store/actions';
import CodeEditor from 'components/shared/editor/codeEditor/codeEditor';
import AddNewCustomEdit from 'components/shared/modal/addNewCustomEdit/addNewCustomEdit';
import ColorInput from 'components/shared/colorInput/colorInput';
// import DropzoneComponent from 'components/shared/dropzone/dropzone.js';
import formatDateTime from 'utils/formatDateTime';
import { joinClasses, accessNested, arrayUnique, parseDate } from 'utils/utils';
import checkCondition from 'utils/checkCondition';

export default @pure class AddNewModal extends Component {

	constructor(props) {
		super();

		this.state.newKey = '';
		this.state.value = '';
		this.state.errors = [];
		this.state.showErrors = false;

		this.listItemRefs = [];

		this.setType = this.setType.bind(this);
		this.updateKey = this.updateKey.bind(this);
		this.reset = this.reset.bind(this);
		this.handleDropzoneChange = this.handleDropzoneChange.bind(this);
		this.handleChecklistChange = this.handleChecklistChange.bind(this);

		// Lets see if there are any other types
		const allOf = props.jsonSchema && props.jsonSchema.properties && props.jsonSchema.properties.allOf;

		let types = {
			string: {
				value: 'string',
				label: 'Text'
			},
			text: {
				value: 'text',
				label: 'Text'
			},
			toggle: {
				value: 'boolean',
				label: 'Toggle'
			},
			item: {
				value: 'object',
				label: 'Item'
			},
			json: {
				value: 'json',
				label: 'JSON'
			},
			date: {
				value: 'date',
				label: 'Date'
			},
			datetime: {
				value: 'datetime',
				label: 'Date & Time'
			}
		};

		this.state.types = types;
		this.state.type = 'string';

		if (allOf) {
			// this.state.types = {};
			let newType = null;
			allOf.forEach(def => {
				if (!def.$ref) return;
				const typeKey = def.$ref.split('/').pop();
				const type = props.baseJSONSchema.definitions[typeKey];
				if (!type) return;
				if (type.title || type.typeName) {
					types[typeKey] = {
						value:             typeKey,
						type:              'custom',
						label:             type.title || type.typeName,
						properties:        type.properties,
						required:          type.required,
						helper:            type.helper,
						nameGeneratedFrom: type.nameGeneratedFrom
					};
					newType = newType || typeKey;
					this.state.value = {};
				} else if (type.baseType) {
					newType = newType || type.baseType;
					this.state.value = {};
				}
			});
			this.state.type = newType || this.state.type;
		}

	}

	/**
	 * LIFECYCLE METHODS
	 */

	componentDidMount() {
		this.checkSelectState();
		const titleInput = this.base.querySelector('.' + s.titleInput);
		if (titleInput) {
			titleInput.focus();
		}
	}

	componentDidUpdate(_, prevState) {
		this.checkSelectState();
		if (prevState.newKey === this.state.newKey) {
			this.generateNewKeyFromProperties(prevState);
		}
	}

	/**
	 * INTERACTION HANDLERS
	**/
	updateKey(e) {
		const newKey = e.target.value;
		let suggested = this.getSuggestionsRaw().find(s => s.key === newKey);
		if (suggested?.key) {
			// this.setType(suggested.type);
			this.setSuggestedItem(suggested.key, suggested);
		} else {
			this.setState({ newKey, forceHideSuggestions: false });
		}

	}
	reset() {
		this.setState({ newKey: '', forceHideSuggestions: false });
	}

	setType(e) {
		const type = e?.target?.value || e;
		if (typeof type !== 'string' || type === this.state.type) return;
		let value = this.state.value;
		if (['string', 'text', 'image', 'color'].includes(type) && typeof value !== 'string') {
			value = '';
		}
		if (type === 'boolean' && typeof value !== 'boolean') {
			value = true;
		}
		if (type === 'object' && typeof value !== 'object') {
			value = {};
		}
		if (type === 'array' && !Array.isArray(value)) {
			value = [];
		}
		if ((type === 'date' || type === 'datetime') && !(value instanceof Date)) {
			value = new Date();
		}
		this.setState({ type, value });
	}

	handleDropzoneChange(value) {
		this.setState({ value });

		// save dynamicSchemaRef
		this.props.updateJSON(`dev.dynamicSchemaRef.`, {
			...this.props.dynamicSchemaRef,
			[this.props.activePath + this.state.newKey + '.']: 'image'
		});
	}

	handleChecklistChange() {
		const value = Object.values(this.listItemRefs).filter(e => e.checked).map(e => e.name);
		this.setState({ value: value.join(',') });
	}

	checkSelectState() {
		const $type = this.base.querySelector('.' + s.select);
		if ($type && $type.selectedIndex === -1) {
			// Make sure something is selected
			$type.selectedIndex = 0;
		}
	}

	closeModal = () => {
		Store.emit(actions.HIDE_MODAL);
	};

	setSuggestedItem = (key, item) => {
		if (item.$ref) {
			item = Object.assign({}, item, this.props.baseJSONSchema.definitions[item.$ref.split('/').pop()]);
		}

		this.setType(item.type);

		const newState = {
			newKey: key,
			// type: item.type,
			// value: item.value || item.default,
			forceHideSuggestions: true
		};

		if (item.default) newState.value = item.default;

		const options = item.options?.possibilities;
		if (options?.length) {
			newState.options = options;
			newState.value = typeof options[0] === 'object' ? options[0].value : options[0];
		}

		this.setState(newState);
	};

	generateNewKeyFromProperties(prevState) {
		const generatedFrom = this.state.types?.[this.state.type]?.nameGeneratedFrom;
		if (!generatedFrom || !this.state.value) {
			return;
		}

		const prevName = prevState?.value && generatedFrom.map(el => prevState.value[el]).filter(e => e).join(' - ');
		if (this.state.newKey && this.state.newKey !== prevName) {
			return;
		}

		const name = generatedFrom.map(el => this.state.value[el]).filter(e => e).join(' - ');
		this.setState({ newKey: name, forceHideSuggestions: true });
	}

	save = () => {
		this.state.errors = [];
		this.state.showErrors = false;

		// hack to be able to put dots in the key names
		let title = (this.state.newKey || '').replace(/\./g, '[[[dot]]]');
		if (!title && !this.isArray) { //added array check as it was breaking when adding list items before
			this.setState({
				errors: ['No name set'],
				showErrors: true
			});
			return;
		}

		if (this.isArray) {
			const keys = Object.keys(this.props.parentValue).filter(v => !isNaN(v));
			title = keys.length ? (Math.max.apply(Math, keys) || 0) + 1 : 0;
		}
		let value = this.state.value;
		if (this.state.type === 'datetime') {
			const it = this.state.value || new Date();
			value = +parseDate(it);
		}

		if (this.state.type === 'json' && typeof value === 'string') {
			try {
				value = JSON.parse(value);
			} catch (err) {
				console.log(err);
				this.state.errors.push('The JSON value provided seems invalid');
			}
		}

		// checking for required items
		const schemaObj = this.state.types[this.state.type];

		if (value && typeof value === 'object' && schemaObj?.required) {
			const requiredErrors = schemaObj.required.filter(item => value[item] === undefined);
			this.state.errors.push(
				...requiredErrors.map(err => `Missing required item: ${schemaObj.properties[err]?.title || err}`)
			);
		}

		this.setState({
			showErrors: this.state.errors.length > 0
		});

		if (this.state.errors.length === 0 && (title.length > 0 || this.isArray)) {
			this.props.updateJSON(`${this.props.activePath}${title}.`, value);
			Store.emit(actions.HIDE_MODAL);
		} else {
			// TODO: make this show up better
			// alert('An error occurred, make sure the value you typed in is valid');
		}
	};

	getSuggestionsRaw() {
		const schemaProps = this.props.jsonSchema && this.props.jsonSchema.properties;

		// Find items inherited from parents
		const inheritedSuggestions = this.props.activePath
			.split('.').filter(e => e)
			.reduce((acc, p, i, arr) => {
				const path = arr.slice(0, -i).join('.properties.');
				const item = accessNested(this.props.baseJSONSchema.properties, path);
				if (!item || !item.$children || !item.$children.properties) {
					return acc;
				}
				item.$children.properties.forEach(prop => {
					if (!prop) {
						return;
					}
					Object.keys(prop).forEach(key => acc[key] = path);
				});
				return acc;
			}, {});

		let inheritedKeys = Object.keys(inheritedSuggestions);
		let hideFromSuggestions = ['vfx'];
		// Calculate the suggested items
		return inheritedKeys.concat(
			Object.keys(schemaProps || {}).filter(prop => prop !== 'allOf')
		).map(key => {
			if (hideFromSuggestions.includes(key)) {
				return;
			}
			let item = schemaProps?.[key];
			if (!item) {
				const inheritedProps = accessNested(this.props.baseJSONSchema.properties, [inheritedSuggestions[key], '$children', 'properties']) || [];
				const matchingProp = inheritedProps.find(p => p && Object.prototype.hasOwnProperty.call(p, key));
				item = matchingProp && matchingProp[key];
			}
			if (item) {
				item = Object.assign({ key }, item);
			}
			if (item?.$ref) {
				let defs = this.props.baseJSONSchema.definitions || {};
				item = Object.assign(item, defs[item.$ref.split('/').pop()]);
			}
			let hidden = item?.hidden;
			if (hidden && typeof hidden === 'object') {
				hidden = checkCondition(hidden, this.props.mergedValue, { localPath: this.props.activePath, currentAttribute: key });
			}
			if (!item || hidden || item.type === 'hidden') {
				return;
			}
			return item;
		}).filter(e => e);
	}

	getSuggested() {
		if (this.isArray) {
			return { list: [], render: false };
		}

		const currentKey = this.state.newKey?.toLowerCase() || '';
		const suggestions = this.getSuggestionsRaw()
			.sort((a, b) => a.title?.localeCompare(b.title))
			.filter(e => (e.title || e.key)?.toLowerCase().includes(currentKey));

		let renderedSuggestions = suggestions.filter(e => this.props.parentValue[e.key] === undefined);

		let shouldRender = !this.state.forceHideSuggestions;

		return {
			list: suggestions,
			render: shouldRender && !!renderedSuggestions.length && (
				<div class={s.suggested} key="suggested">
					<div class={s.suggestedTitle}>Suggested</div>
					<div class={s.suggestedContent}>
						{renderedSuggestions.map(item => (
							<button class={s.suggestedItem} onClick={() => this.setSuggestedItem(item.key, item)} key={item.key}>
								<div class={s.suggestedItemTitle}>{item.title || item.key}</div>
								<p class={s.suggestedItemDescription}>
									{item.description}
								</p>
							</button>
						))}
					</div>
				</div>
			)
		};
	}

	getEditor(schema) {
		schema = schema || {};

		let state = this.state;
		let onChange = this.linkState('value');
		let editor;
		// Make sure we can still see when it's a custom one
		const typeData = state.types[state.type];
		const type = (typeData && typeData.type) || state.type;
		const useSelector = state.options && state.options.length;
		let extraContainerClasses = [];
		// TODO: do not set the values here... (setState in render is bad practice)
		switch (type) {
			case 'string':
			case 'text':
			case 'image':
				if (useSelector) {
					editor = (
						<select class={s.select} onChange={onChange}>
							{state.options.map(opt => {
								if (typeof opt === 'string') {
									opt = { title: opt, value: opt };
								}
								return <option value={opt.value}>{opt.title}</option>;
							})}
						</select>
					);
					extraContainerClasses.push(s.selectContainer);
				} else if (!schema.disabled) {
					editor = <CodeEditor class={s.editor} onChange={onChange} ref={$ref => this.$editor = $ref} language="plaintext" hideMinimap hideLineNumbers key="text" />;
				}
				break;
			case 'boolean':
				editor = (
					<label class={s.checkbox} key="toggle">
						<input class={s.input} type="checkbox" checked={!!this.state.value} onChange={onChange} />
						<div class={s.styledInput}>
							<div class={s.slider} />
						</div>
					</label>
				);
				break;
			case 'object':
			case 'array':
				editor = undefined;
				break;
			case 'date':
				editor = <input type="date" class={s.input} defaultValue={formatDateTime(new Date(), 'yyyy-MM-dd')} onChange={onChange} key="date" />;
				break;
			case 'datetime':
				editor = <input type="datetime-local" class={s.input} defaultValue={formatDateTime(new Date(), 'yyyy-MM-dd\\THH:mm')} onChange={onChange} key="datetime" />;
				break;
			case 'custom':
				editor = <AddNewCustomEdit type={state.type} typeData={state.types[this.state.type]} onChange={onChange} mergedValue={this.props.mergedValue} key="custom" />;
				break;
			case 'json':
				editor = <CodeEditor class={s.editor} onChange={onChange} ref={$ref => this.$editor = $ref} language="json" hideMinimap key="json" />;
				break;
			case 'color':
				let invalid = (this.state.value + '').startsWith('$') ? false : undefined;
				editor = (
					<div class={s.colorWrapper} key="color">
						<div class={s.colorBox}>
							<ColorInput class={s.colorInput} value={this.state.value} onChange={e => this.setState({ value: e.target.value.toString() })} invalid={invalid} />
						</div>
						<div class={s.inputSizer}>
							<input
								type="text"
								class={s.input}
								value={this.state.value}
								onInput={onChange}
							/>
						</div>
					</div>
				);
				break;
			case 'checklist':
				const list = this.props.jsonSchema?.properties[this.state.newKey].list;
				editor = (
					<div class={s.checklist}>
						{list.map(item => (
							<div class={s.checklistItem}>
								<label>{item}</label>
								<input
									name={item}
									ref={ref => this.listItemRefs[item] = ref}
									class={s.input}
									type="checkbox"
									onChange={this.handleChecklistChange}
									checked={(this.state.value || '').split(',').includes(item)}
								/>
							</div>
						))}
					</div>
				);
				break;
		}

		return <div class={joinClasses(s.editorContainer, ...extraContainerClasses)} key="editor">{editor}</div>;
	}

	render(props, state) {
		this.isArray = Array.isArray(this.props.parentValue) || (this.props.jsonSchema && this.props.jsonSchema.type === 'array');

		let suggested = this.getSuggested();

		let editor;
		if (!suggested.render) {
			let matchingSchema = suggested.list && suggested.list.find(e => e.key === state.newKey);
			editor = this.getEditor(matchingSchema);
		}

		let types = Object.values(state.types);
		let extraTypes = arrayUnique(suggested.list.map(e => e.type)).concat(state.type).filter(e => e);
		extraTypes.forEach(newType => {
			if (!types.find(type => type.value === newType)) {
				types.push({ value: newType, label: newType[0].toUpperCase() + newType.slice(1) });
			}
		});
		// Duplication text <-> string
		let typeOptions = types.filter(e => e.value !== 'text').map(type => <option value={type.value}>{type.label}</option>);
		let typeSelect = (
			<div class={joinClasses(s.selectContainer, s.typeSelect)} key="type">
				<select class={s.select} onChange={this.setType} value={state.type}>
					{typeOptions}
				</select>
			</div>
		);

		let errorsContent;
		if (state.showErrors) {
			errorsContent = (
				<div class={s.errorWrap} key="error">
					<h2>Whooops</h2>
					{state.errors.map(err => <div class={s.error}>{err}</div>)}
				</div>
			);
		}

		return (
			<div class={joinClasses(s.container, this.isArray && s.array)}>
				<div class={s.titleContainer}>
					{typeSelect}
					<input
						type="text"
						class={s.titleInput}
						key="input"
						placeholder="Name"
						value={state.newKey}
						onInput={this.updateKey}
					/>
					{!!state.newKey && <div key="reset" class={s.reset} onClick={this.reset} />}
				</div>
				<div class={s.contentContainer}>
					{suggested && suggested.render}
					{editor}
					{errorsContent}
				</div>
				<div class={s.buttons}>
					<button class={s.discard} onClick={this.closeModal}>Discard</button>
					<button class={s.addBtn} onClick={this.save}>Add</button>
				</div>
			</div>
		);
	}

}
