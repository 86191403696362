/////// EXAMPLES ///////
/*
let data = { a: 12, b: 'str', c: { bool: true, ref: 'STR' } };

// (all the examples below return true)

// data.b == 'str'
checkCondition({ equal: ['@b', 'str'] }, data);

// data.a is either 5, 10 or 12
checkCondition({ oneOf: ['@a', 5, 10, 12] }, data);

// data.a > 10 && data.c.bool
checkCondition({ all: [{ greater: ['@a', 10] }, { truthy: '@c.bool' }] }, data);

// data.b === data.c.ref (case-insensitive)
checkCondition({
	equal: [
		{ type: 'data', value: '@b', transforms: 'lower' },
		{ type: 'data', value: '@c.ref', transforms: 'lower' }
	]
}, data);

// (data.c.title !== undefined && data.a) || (data.c.title === undefined && data.b)
checkCondition({
	any: [
		{ all: [{ not: { unset: '@c.title' } }, { truthy: '@a' }] },
		{ all: [{ unset: '@c.title' }, { truthy: '@b' }] }
	]
}, data);
*/

/////// REFERENCE ///////
/*
data: object that the condition can access
options:
    localPath: can specify a path in the data that is considered the "local" part (needs to end with a dot e.g. 'current.path.')
    currentAttribute: can specify which is the "target" element (within the localPath)
	customValueTypes: object with custom value types that can be used in the condition
	    e.g. { random: (value, data, { localPath, currentAttribute }) => Math.random() }

condition: {
	?all: [cond1, cond2, ...]  cond1 && cond2 && ...
	?any: [cond1, cond2, ...]  cond1 || cond2 || ...
	?not: cond                 !cond
	?truthy: value             !!value
	?falsy: value              !value ("not" inverts a condition, "falsy" checks a value)
	?unset: value              value === undefined
	?greater: [a, b]           a > b
	          [a, b, true]     a >= b
	?less: [a, b]              a < b
	       [a, b, true]        a <= b
	?equal: [a, b]             a == b
	        [a, b, true]       a === b
	?different: [a, b]         a != b
	            [a, b, true]   a !== b
	?oneOf: [a, b, c, ...]     a === b || a === c || ...
}
A condition is truthy if ALL its keys are validated
However as a convention, to make things more explicit and more consistent, a condition should only contain one key
  and you should use all/any to combine them
e.g.
BAD
{ greater: [5, 4], truthy: 'value' }
GOOD
{ all: [{ greater: [5, 4] }, { truthy: 'value' }] }

interpretation of values in conditions:
- 123 / true / undefined       as is
- 'const'                      as is
- '=const'                     'const' ("=" can be used as a prefix to prevent other special chars handling)
- '@path.to.prop'              shortcut for { type: 'data', value: 'path.to.prop' }
- '.prop'                      gets data from the "local" part of the data object (if specified - typically siblings of the target element)
- '>prop'                      gets data INSIDE the target element (if specified via localPath and currentAttribute)
- ['path', 'to', 'prop']       shortcut for { type: 'data', value: ['path', 'to', 'prop'] }
- { type, value, ?tranforms }  depends on "type"
  - type = 'const'
	value: constant to be used as is
  - type = 'data'
	value: path of a value to get from the data object (array or string with '.' used as separator)
  - any other type specified in customValueTypes

tranforms in value objects:
- lower: use the lowercase version of the result
- upper: use the uppercase version of the result

(mostly borrowed from https://github.com/POWSTER/messenger/blob/master/common/flowElement/condition.js)
*/

const comparisons = {
	equal: (v1, v2, strict) => strict ? v1 === v2 : v1 == v2,
	different: (v1, v2, strict) => strict ? v1 !== v2 : v1 != v2,
	greater: (v1, v2, orEqual) => orEqual ? v1 >= v2 : v1 > v2,
	less: (v1, v2, orEqual) => orEqual ? v1 <= v2 : v1 < v2,
	oneOf: (v1, ...v2) => v2.includes(v1),
	contains: (v1, v2) => String(v1).includes(v2),
	startsWith: (v1, v2) => String(v1).startsWith(v2),
	endsWith: (v1, v2) => String(v1).endsWith(v2)
};
export default function checkCondition(condition, data, options) {
	if (!condition) return true;
	if (typeof condition !== 'object') return !!condition;
	const { localPath, currentAttribute, customValueTypes, any } = options || {};

	const someOrEvery = any ? 'some' : 'every';
	if (Array.isArray(condition)) {
		return condition[someOrEvery](cond => checkCondition(cond, data, { localPath, currentAttribute, customValueTypes }));
	}

	return Object.keys(condition)[someOrEvery](key => {
		let value = condition[key];
		if (Object.prototype.hasOwnProperty.call(comparisons, key)) {
			// TODO: checks for non-array on equal, greater, less, ...
			// What should happen ?
			const first = getValue(value[0], data, localPath, currentAttribute, customValueTypes);
			const second = getValue(value[1], data, localPath, currentAttribute, customValueTypes);
			return comparisons[key](first, second, value[2]);
		}
		switch (key) {
			case 'unset': return value === undefined;
			case 'all': return checkCondition(value, data, { localPath, currentAttribute, customValueTypes });
			case 'any': return checkCondition(value, data, { localPath, currentAttribute, customValueTypes, any: true });
			case 'not': return !checkCondition(value, data, { localPath, currentAttribute, customValueTypes });
			case 'truthy': return getValue(value, data, localPath, currentAttribute, customValueTypes);
			case 'falsy': return !getValue(value, data, localPath, currentAttribute, customValueTypes);
			// If we are in a "&&" type of check, short-circuit
			default: return !any;
		}
	});
}

function getValue(value, data, localPath, currentAttribute, customValueTypes) {
	if (!value) {
		return value;
	}
	if (typeof value === 'string') {
		if ('@.>'.includes(value[0])) {
			value = { type: 'data', value };
		} else {
			return value[0] === '=' ? value.slice(1) : value;
		}
	}
	if (Array.isArray(value)) {
		value = { type: 'data', value };
	}
	if (typeof value !== 'object') {
		return value;
	}

	let result = value.value;
	if (value.type === 'data') {
		let path = value.value;
		if (!Array.isArray(path)) {
			let pathStr = String(path);
			switch (pathStr[0]) {
				case '@': pathStr = pathStr.slice(1); break;
				case '.': pathStr = (localPath || '') + pathStr.slice(1); break;
				case '>': pathStr = (localPath || '') + (currentAttribute ? currentAttribute + '.' : '') + pathStr.slice(1); break;
			}
			path = pathStr.split('.');
		}
		let upIndex;
		while ((upIndex = path.indexOf('^')) > -1) {
			path.splice(upIndex > 0 ? upIndex - 1 : 0, upIndex > 0 ? 2 : 1);
		}
		result = path.reduce((r, p) => r?.[p], data);
	} else {
		const custom = customValueTypes?.[value.type];
		if (typeof custom === 'function') {
			result = custom(value, data, { localPath, currentAttribute });
		}
	}
	if (!value.transforms) {
		return result;
	}

	let transforms = Array.isArray(value.transforms) ? value.transforms : [value.transforms];
	result = transforms.reduce((v, transform) => {
		switch (transform) {
			case 'lower': return typeof v === 'string' ? v.toLowerCase() : v;
			case 'upper': return typeof v === 'string' ? v.toUpperCase() : v;
		}
		return v;
	}, result);

	return result;
}