import { h, Component } from 'preact';
import pure from 'utils/pure';
import * as dataApi from 'services/dataApi';
// import values from 'lodash/values';
// import deserialise from 'utils/deserialise';
import s from './screenings.sss';
// import cloneDeep from 'lodash/cloneDeep';
// import defaultsDeep from '@nodeutils/defaults-deep';
import Table from 'components/shared/table/table.js';
// import Store from 'store/store';
// import * as actions from 'store/actions';
import { accessNested, condClass, debounce, joinClasses } from 'utils/utils';
import { getMergedAppData } from 'utils/appDataUtils';

export default @pure class Screenings extends Component {

	constructor() {
		super();
		this.state.screenings = [];
		this.getScreenings = this.getScreenings.bind(this);
		this.pageSelectorKeydown = this.pageSelectorKeydown.bind(this);
		this.computeTotalsPerFormat = this.computeTotalsPerFormat.bind(this);
		this.computeTotalsPerProvider = this.computeTotalsPerProvider.bind(this);
		this.filterMissingBookingLinks = this.filterMissingBookingLinks.bind(this);
		this.listCinemasPerProvider = this.listCinemasPerProvider.bind(this);
		this.refreshScreenings = this.refreshScreenings.bind(this);
		this.refreshColumns = this.refreshColumns.bind(this);
		this.cache = {};
		this.perPage = 50;
		this.state.page = 0;
		this.state.filter = '';
		this.state.includeBoundingRegions = false;
		this.state.englishNames = false;
		this.state.extraContent = null;
		this.state.extraMovieIds = [];
		this.state.withoutBookingListFiltered = false;
		this.state.filterCinemasPerProvider = false;
		this.fullScreenings = [];

		this.refreshColumns();
	}

	componentWillMount() {
		this.checkLoading();
	}

	componentWillReceiveProps(newProps, newState) {
		let reload = newProps.appId !== this.props.appId;
		if (reload) {
			this.setState({
				appDataLoaded: false,
				extraContent: null,
				includeBoundingRegions: false,
				englishNames: false,
				page: 0,
				filter: ''
			});
		}
		this.checkLoading(newProps, newState, reload);
	}

	checkLoading(props = this.props, state = this.state, forceLoading) {
		let appDataLoaded = ['app', 'title', 'region', 'studio', 'studioRegion', 'base'].every(t => props[t]);
		if ((!state.appDataLoaded && appDataLoaded) || forceLoading) {
			this.getScreenings(props, state);
		}
		if (!state.appDataLoaded !== !appDataLoaded) {
			this.setState({ appDataLoaded });
		}
	}

	refreshScreenings() {
		if (this.state.appDataLoaded) {
			this.getScreenings();
		}
	}

	refreshColumns() {
		let formatLatLon = (v) => (+v).toFixed(3);

		let cols = this.cols = {
			// id: {type: 'string', name: 'ID'},
			provider_id:         { type: 'string', name: 'Provider' },
			title:               { type: 'string', name: 'Title'},
			format:              { type: 'string', name: 'Format'},
			date:                { type: 'date',   name: 'Date' },
			time:                { type: 'time',   name: 'Time' },
			booking_url:         { type: 'url',    name: 'Booking' },
			provider_theater_id: { type: 'string', name: 'Prov Th ID', title: 'Provider Theater ID' },
			name:                { type: 'string', name: 'Theater', locale: true },
			name_en:             { type: 'string', name: 'Theater', english: true },
			address:             { type: 'string', name: 'Address', locale: true },
			address_en:          { type: 'string', name: 'Address', english: true },
			city:                { type: 'string', name: 'City', locale: true },
			city_en:             { type: 'string', name: 'City', english: true },
			postcode:            { type: 'string', name: 'Postcode' },
			country:             { type: 'string', name: 'Country' },
			theater_url:         { type: 'url',    name: 'Theater url' },
			latitude:            { type: 'number', name: 'Lat', format: formatLatLon, class: s.latLon },
			longitude:           { type: 'number', name: 'Lon', format: formatLatLon, class: s.latLon },
		};

		this.defaultSorting = [
			'exhibitor_id',
			'country_en', 'city_en',
			'name_en',
			'date', 'time'
		];

		let colList = Object.keys(cols);
		this.keys = colList.filter(k => !cols[k].english);
		this.keyTypes = colList.reduce((p,k) => (p[k]=cols[k].type,p), {});
		this.columnNames = colList.reduce((p,k) => (p[k]=cols[k].name,p), {});
		this.columnTitles = colList.reduce((p,k) => (p[k]=cols[k].title,p), {});
		this.formats = colList.reduce((p,k) => (p[k]=cols[k].format,p), {});
		this.columnClasses = colList.reduce((p,k) => (p[k]=cols[k].class,p), {});

		this.filterChanged = debounce(this.filterChanged, 500);
	}

	toggleIncludeBoundingRegions = () => {
		let newIBR = !this.state.includeBoundingRegions;
		this.setState({ includeBoundingRegions: newIBR });
		this.getScreenings(this.props, { includeBoundingRegions: newIBR });
	}
	toggleEnglishNames = () => {
		let newVal = !this.state.englishNames;
		this.keys = Object.keys(this.cols).filter(k => !this.cols[k][newVal ? 'locale' : 'english']);
		this.setState({ englishNames: newVal });
	}

	getScreenings(props, state) {
		props = props || this.props;
		state = state || this.state;
		this.setState({ loading: true, error: null });
		let regionSlug = accessNested(props, 'region.attributes.meta.region.slug');
		let titleSlug = accessNested(props, 'title.attributes.meta.title.slug');
		if (!regionSlug || !titleSlug) {
			return this.setState({ loading: false, error: 'Error with title or region ' + titleSlug + ' ' + regionSlug });
		}
		let filters = {
			"include[0]": "theaters",
			"include[1]": "exhibitors",
			"include[2]": "movies",
			"include[3]": "formats",
			"filter[titles][slug][0]": titleSlug,
			// "unique_theaters": true
		};
		if (regionSlug !== 'intl') {
			let currentRegion = regionSlug.slice(0, 2);
			let merged = props.appId && getMergedAppData(props.appId);
			if (state.includeBoundingRegions) {
				let bounding = accessNested(merged, 'pages.showtimes.data.boundingRegions', []);
				if (!Array.isArray(bounding)) {
					bounding = Object.keys(bounding).filter(r => bounding[r] && r !== currentRegion);
				}
				if (bounding.indexOf('intl') === -1) {
					filters['filter[theaters][country][0]'] = currentRegion;
					bounding.forEach((br, i) => {
						filters['filter[theaters][country][' + (i + 1) + ']'] = br;
					});
				}
			} else {
				filters['filter[theaters][country][0]'] = currentRegion;
			}
			let extraMovieIds = accessNested(merged, 'pages.showtimes.data.extraMovieIds');
			if (extraMovieIds) {
				// add in screenings where extraMovieIds has been added in thundr for app
				Object.keys(extraMovieIds).map((id, i) => {
					filters['filter[movies][pidmod]['+ (i + 1) + ']'] = extraMovieIds[id];
				});
			}
		}
		dataApi.getScreenings(filters).then(({ data, included }) => {
			data = data || [];
			included = included || [];
			let counts = {};
			included.forEach(i => {
				this.cache[i.id] = i.attributes;
				counts[i.type] = (counts[i.type] || 0) + 1;
			});
			this.fullScreenings = data.map(s => {
				let screening = s.attributes;
				let theater = this.cache[screening.theater_id];
				let movie = this.cache[screening.movie_id];
				return {
					provider_id: screening.provider_id,
					title: movie.title,
					format: movie.format_id ? this.cache[movie.format_id].name : '',
					date: screening.date,
					time: screening.time,
					booking_url: screening.url,
					provider_theater_id: theater.provider_theater_id,
					name: theater.name,
					name_en: theater.name_en || theater.name,
					exhibitor_id: theater.exhibitor_id,
					address: theater.address,
					address_en: theater.address_en || theater.address,
					city: theater.city,
					city_en: theater.city_en || theater.city,
					postcode: theater.postcode,
					country: theater.country,
					theater_url: theater.url,
					latitude: theater.lat,
					longitude: theater.lon,
					theater_id: screening.theater_id
				};
			});

			this.setState({
				loading: false,
				counts: counts,
				screenings: this.fullScreenings
			});
		}).catch(err => {
			console.log('Error getting screenings', err);
			this.setState({ loading: false, error: err });
		});
	}

	updateFilter(fullFilter) {
		let filterList = fullFilter.split(',').map(e => e.trim()).filter(e => e);
		let search = (haystack, needle) => needle.every(part => haystack.includes(part));
		let searchExact = (haystack, needle) => haystack === needle;
		let filters = filterList.map(str => {
			let value = str.trim().toLowerCase(), negative = false, fn = search;
			if (value.startsWith('!')) {
				value = value.slice(1);
				negative = true;
			}
			if (value.startsWith('=')) {
				value = value.slice(1);
				fn = searchExact;
			}
			if (!negative && fn === search) {
				// split by spaces (or quotes): 'multiple words "to ge ther" end' =>  ['multiple', 'words', '"to ge ther"', 'end']
				value = value.match(/"(?:\\"|[^"])+"|'(?:\\'|[^'])+'|\S+/g) || [value];
				// Unquote the quoted value
				value = value.map(v => v.replace(/^('|")(.*)\1$/, '$2'));
			}
			return {
				value: value,
				negative: negative,
				compare: fn
			};
		});
		let filtered = this.fullScreenings;
		if (filters.length) {
			filtered = filtered.filter(screening => {
				let keys = Object.keys(screening), values = keys.map(k => (screening[k] + '').toLowerCase());
				return filters.every(filter => filter.negative !== values.some(value => filter.compare(value, filter.value)));
			});
		}
		// Page always to 0 or just capped by new maxPage ?
		let page = 0;
		this.setState({ filter: fullFilter, screenings: filtered, page: page });
	}

	filterChanged = (e) => {
		if (!e || !e.target) return;
		let val = e.target.value || '';
		if (val !== this.state.filter) {
			this.updateFilter(val);
		}
	}

	pageSelectorKeydown(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			let val = +e.target.value;
			let maxPage = Math.ceil(this.state.screenings.length / this.perPage);
			if (!val || val < 1 || val > maxPage) return;
			this.setState({ page: val - 1 });
			e.target.value = '';
			return;
		}
		if (!e.target.value && (e.keyCode === 37 || e.keyCode === 39)) {
			e.preventDefault();
			let currentPage = this.state.page;
			let maxPage = Math.ceil(this.state.screenings.length / this.perPage) - 1;
			let val = currentPage + (e.keyCode === 37 ? -1 : 1);
			if (val < 0 || val > maxPage) return;
			this.setState({ page: val });
			return;
		}
	}

	// TODO: merge these function
	computeTotalsPerFormat() {
		if (this.state.extraContent && this.state.extraContentType === 'totalsperformat') {
			this.setState({extraContent: null, extraContentType: ''});
			return;
		}
		let keys = ['format', 'screenings', 'theaters', 'exhibitors'];

		let aggregated = this.state.screenings.reduce((agg, screening) => {
			let format = screening.format;
			let o = agg[format] || (agg[format] = { format: format, screenings: 0, theaters: {}, exhibitors: {} });
			o.screenings++;
			o.theaters[screening.theater_id] = true;
			o.exhibitors[screening.exhibitor_id] = true;
			return agg;
		}, {});
		aggregated = Object.values(aggregated).map(e => {
			e.theaters = Object.keys(e.theaters).length;
			e.exhibitors = Object.keys(e.exhibitors).length;
			return e;
		});

		let result = <Table keys={keys} data={aggregated} class={s.table} defaultSorting={[{key: 'screenings', dir: -1}, 'format']} />;
		this.setState({ extraContent: result, extraContentType: 'totalsperformat' });
	}

	computeTotalsPerProvider() {
		if (this.state.extraContent && this.state.extraContentType === 'totalsperprovider') {
			// Reset the list when it's already active
			this.setState({ extraContent: null, extraContentType: '' });
			return;
		}

		let keys = ['provider', 'screenings', 'theaters', 'exhibitors'];

		let aggregated = this.state.screenings.reduce((agg, screening) => {
			let provider = screening.provider_id;
			let o = agg[provider] || (agg[provider] = { provider: provider, screenings: 0, theaters: {}, exhibitors: {} });
			o.screenings++;
			o.theaters[screening.theater_id] = true;
			o.exhibitors[screening.exhibitor_id] = true;
			return agg;
		}, {});
		aggregated = Object.values(aggregated).map(e => {
			e.theaters = Object.keys(e.theaters).length;
			e.exhibitors = Object.keys(e.exhibitors).length;
			return e;
		});

		let result = <Table keys={keys} data={aggregated} class={s.table} defaultSorting={[{key: 'screenings', dir: -1}, 'format']} />;
		this.setState({ extraContent: result, extraContentType: 'totalsperprovider' });

	}

	filterMissingBookingLinks() {
		if (!this.state.withoutBookingListFiltered) {
			const { screenings } = this.state;
			let withoutBookingList = [];
			screenings.map(screening => {
				if (!screening.booking_url) {
					if (withoutBookingList.length === 0) {
						withoutBookingList.push(screening);
					}
					let obj = withoutBookingList.findIndex(o => o.name === screening.name && o.provider_theater_id === screening.provider_theater_id);
					if (obj < 0) {
						withoutBookingList.push(screening);
					}
				}
			});
			// to remove unwanted columns from the main table
			let cols = this.cols = {
				format:              { type: 'string', name: 'Format'},
				provider_theater_id: { type: 'string', name: 'Prov Th ID', title: 'Provider Theater ID' },
				name:                { type: 'string', name: 'Theater', locale: true },
				name_en:             { type: 'string', name: 'Theater', english: true },
				address:             { type: 'string', name: 'Address', locale: true },
				address_en:          { type: 'string', name: 'Address', english: true },
				city:                { type: 'string', name: 'City', locale: true },
				city_en:             { type: 'string', name: 'City', english: true },
				postcode:            { type: 'string', name: 'Postcode' },
				country:             { type: 'string', name: 'Country' },
				theater_url:         { type: 'url',    name: 'Theater url' }
			};

			let colList = Object.keys(cols);
			this.keys = colList.filter(k => !cols[k].english);
			this.keyTypes = colList.reduce((p,k) => (p[k]=cols[k].type,p), {});
			this.columnNames = colList.reduce((p,k) => (p[k]=cols[k].name,p), {});
			this.columnTitles = colList.reduce((p,k) => (p[k]=cols[k].title,p), {});
			this.formats = colList.reduce((p,k) => (p[k]=cols[k].format,p), {});
			this.columnClasses = colList.reduce((p,k) => (p[k]=cols[k].class,p), {});
			this.filterChanged = debounce(this.filterChanged, 500);

			this.setState({
				screenings: withoutBookingList,
				withoutBookingListFiltered: true
			});
		} else {
			this.setState({withoutBookingListFiltered: false});
			this.getScreenings();
			this.refreshColumns();
		}
	}

	listCinemasPerProvider() {
		if (!this.state.filterCinemasPerProvider) {
			const { screenings } = this.state;
			let newList = [];
			screenings.map(screening => {
				let newEntry = {
					provider_id: screening.provider_id,
					provider_theater_id: screening.provider_theater_id,
					name: screening.name,
					address: screening.address,
					city: screening.city,
					postcode: screening.postcode,
					country: screening.country,
					theater_url: screening.theater_url,
					latitude: screening.latitude,
					longitude: screening.longitude
				};

				if (newList.length === 0) {
					newList.push(newEntry);
				}

				let index = newList.findIndex(o => o.name === screening.name && o.provider_id === screening.provider_id);
				if (index === -1) {
					newList.push(newEntry);
				}
			});
			newList.sort((a,b) => a.name.localeCompare(b.name));
			let formatLatLon = (v) => (+v).toFixed(3);
			// to remove unwanted columns from the main table
			let cols = this.cols = {
				provider_id:         { type: 'string', name: 'Provider' },
				provider_theater_id: { type: 'string', name: 'Prov Th ID', title: 'Provider Theater ID' },
				name:                { type: 'string', name: 'Theater', locale: true },
				name_en:             { type: 'string', name: 'Theater', english: true },
				address:             { type: 'string', name: 'Address', locale: true },
				address_en:          { type: 'string', name: 'Address', english: true },
				city:                { type: 'string', name: 'City', locale: true },
				city_en:             { type: 'string', name: 'City', english: true },
				postcode:            { type: 'string', name: 'Postcode' },
				country:             { type: 'string', name: 'Country' },
				theater_url:         { type: 'url',    name: 'Theater url' },
				latitude:            { type: 'number', name: 'Lat', format: formatLatLon, class: s.latLon },
				longitude:           { type: 'number', name: 'Lon', format: formatLatLon, class: s.latLon },
			};

			let colList = Object.keys(cols);
			this.keys = colList.filter(k => !cols[k].english);
			this.keyTypes = colList.reduce((p,k) => (p[k]=cols[k].type,p), {});
			this.columnNames = colList.reduce((p,k) => (p[k]=cols[k].name,p), {});
			this.columnTitles = colList.reduce((p,k) => (p[k]=cols[k].title,p), {});
			this.formats = colList.reduce((p,k) => (p[k]=cols[k].format,p), {});
			this.columnClasses = colList.reduce((p,k) => (p[k]=cols[k].class,p), {});
			this.filterChanged = debounce(this.filterChanged, 500);

			this.setState({
				screenings: newList,
				filterCinemasPerProvider: true
			});
		} else {
			this.setState({filterCinemasPerProvider: false});
			this.getScreenings();
			this.refreshColumns();
		}
	}

	renderTable() {
		let { screenings, page, filterCinemasPerProvider } = this.state;
		let from = page * this.perPage;
		if (!filterCinemasPerProvider) {
			return (
				<Table
					keys={this.keys}
					data={screenings}
					from={from}
					count={this.perPage}
					keyTypes={this.keyTypes}
					columnNames={this.columnNames}
					columnTitles={this.columnNames}
					class={s.table}
					columnClasses={this.columnClasses}
					formats={this.formats}
					defaultSorting={this.defaultSorting} />
			);
		} else {
			//split the array into each provider so that tables can be rendered based on that
			const splitOnProvider = screenings.reduce((acc, prov)=> {
				if (acc[prov.provider_id]) {
					acc[prov.provider_id].push(prov);
				} else {
					acc[prov.provider_id] = [prov];
				}
				return acc;
			}, {});
			const tables = Object.keys(splitOnProvider).map(providerData => (
				<Table
					keys={this.keys}
					data={splitOnProvider[providerData]}
					from={from}
					count={this.perPage}
					keyTypes={this.keyTypes}
					columnNames={this.columnNames}
					columnTitles={this.columnNames}
					class={s.multipleTable}
					columnClasses={this.columnClasses}
					formats={this.formats}
					defaultSorting={this.defaultSorting}
				/>
			));
			return tables;
		}
	}


	render({ title, region }, state) {
		let { counts, page } = state;
		// let keyTypes = {"release": "number", "screenings": "number", "visits": "number", "active_sessions": "number"};

		let content;
		let refreshButton;
		if (!state.appDataLoaded) {
			content = <div class={s.loading}><div class={s.icon} /></div>;
		} else if (state.loading) {
			content = <div class={s.loading}><div class={s.icon} /></div>;
		} else if (state.error) {
			content = <div class={s.error}>Error getting screenings</div>;
		} else {
			refreshButton = (
				<button class={s.refresh} onClick={this.refreshScreenings} key="refresh">Refresh</button>
			);
			if (!this.fullScreenings.length) {
				content = <div class={s.noScreenings}>No screenings</div>;
			} else {
				let plur = (word, n) => word + (n !== 1 ? 's' : '');
				let countsElems;
				if (counts) {
					let countsKeys = Object.keys(counts);
					countsElems = countsKeys.map(k => <div class={s[k]} data-description={plur(k.replace(/s$/, ''), counts[k])}>{counts[k]}</div>);
				}
				let maxPage = Math.ceil(state.screenings.length / this.perPage) - 1;
				let hasPrev = page > 0, hasNext = page < maxPage;
				let navigation = (
					<div class={s.navigation}>
						<div class={s.prev + condClass(!hasPrev, s.disabled)} onClick={hasPrev && (() => this.setState({page: page - 1}))} />
						<div class={s.current}>
							<input type="text" placeholder={page+1} onKeydown={this.pageSelectorKeydown} />/{maxPage+1}
						</div>
						<div class={s.next + condClass(!hasNext, s.disabled)} onClick={hasNext && (() => this.setState({page: page + 1}))} />
					</div>
				);
				let renderTable = this.renderTable();
				content = [
					<div class={s.extra}>
						{this.state.filterCinemasPerProvider ?
							<div class={s.buttons}>
								<button class={s.clearFilter} onClick={this.listCinemasPerProvider}>Reset</button>
							</div> :
							<div class={s.buttons}>
								<button onClick={this.computeTotalsPerFormat}>Totals per format</button>
								<button onClick={this.computeTotalsPerProvider}>Totals per Provider</button>
								{this.state.withoutBookingListFiltered ? <button class={s.clearFilter} onClick={this.filterMissingBookingLinks}>View All</button> : <button onClick={this.filterMissingBookingLinks}>Missing Booking Links</button>}
								<button onClick={this.listCinemasPerProvider}>List Cinemas Per Provider</button>
							</div>
						}
						{state.extraContent}
					</div>,
					<div class={s.overall}>
						<div class={s.screenings} data-description={plur('screening', this.fullScreenings.length)}>{this.fullScreenings.length}</div>
						{countsElems}
					</div>,
					<div class={s.searchWrapper}>
						<input class={s.search} placeholder="Filter..." onInput={this.filterChanged} />
					</div>,
					navigation,
					<div class={s.tableSection}>{renderTable}</div>,
					navigation
				];
			}
		}
		content = [
			<div class={s.settings}>
				<label class={joinClasses(s.toggle, s.bounding)} key="bounding">
					<input type="checkbox" checked={state.includeBoundingRegions} onChange={this.toggleIncludeBoundingRegions} />
					<div class={s.indicator}><div class={s.slider} /></div>
					<span class={s.description}>Include bounding regions</span>
				</label>
				<label class={joinClasses(s.toggle, s.english)} key="english">
					<input type="checkbox" checked={state.englishNames} onChange={this.toggleEnglishNames} />
					<div class={s.indicator}><div class={s.slider} /></div>
					<span class={s.description}>English names</span>
				</label>
				{refreshButton}
			</div>
		].concat(content);

		console.log(state.appDataLoaded, title, region);
		let pageTitle = state.appDataLoaded ? title?.attributes?.meta?.title?.en + ' - ' + region?.attributes?.meta?.region?.slug?.toUpperCase() : 'Loading App Data';

		return (
			<div class={s.container}>
				<h2 class={s.legend}>Screenings</h2>
				<h5 class={s.movieName}>{pageTitle}</h5>
				{content}
			</div>
		);
	}
}
