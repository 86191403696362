import { h } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import qs from 'qs';

import pure from 'utils/pure';
import { joinClasses } from 'utils/utils';
import { setPageTitle } from 'utils/setPageTitle';
import * as keyboardShortcuts from 'utils/keyboardShortcuts';

import { Link, goToPage } from 'components/core/link/link';
import Page from 'components/pages/page';
import AppList from 'components/pages/apps/appList';
import MainEdit from 'components/pages/apps/mainEdit';
import AppCreator from 'components/pages/apps/appCreator';
import MatchTitles from 'components/pages/apps/matchTitles';
import Screenings from 'components/pages/apps/screenings';
import Insights from 'components/pages/apps/insights';

import s from 'components/pages/apps/apps.sss';

const titles = {
	editApp: 'Edit',
	matchTitle: 'Movie matcher',
	insights: 'Insights',
	newApp: 'New app',
	screenings: 'Screenings',
};

export default @pure class Apps extends Page {

	constructor() {
		super();
		this.filterKeydown = this.filterKeydown.bind(this);
		this.toggleMinimize = this.toggleMinimize.bind(this);
		this.focusSearchMaybe = this.focusSearchMaybe.bind(this);
		this.focusNextAppMaybe = this.focusNextAppMaybe.bind(this);
		this.onFilterInput = this.onFilterInput.bind(this);

		if (typeof window !== 'undefined') {
			let qsObj = qs.parse(location.search.substr(1));
			this.state.filter = qsObj.s || '';
		}
		this.gotApps = false;
		this.state.minimized = false;
	}

	componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}
		this.shortcutsHandler = keyboardShortcuts.register({
			'/': { action: this.focusSearchMaybe, allowDefaultHandling: true, name: 'Focus apps list search bar' },
			'ctrl+alt+shift+B': { action: this.goToBaseLevel, name: 'Go to base AppData level' },
			ArrowDown: { action: this.focusNextAppMaybe, direction: 1, allowDefaultHandling: true },
			ArrowUp: { action: this.focusNextAppMaybe, direction: -1, allowDefaultHandling: true },
		});
		if (this.$filter) {
			this.$filter.focus();
		}
	}

	componentWillUnmount() {
		this.shortcutsHandler?.stop();
	}

	componentWillUpdate(nextProps) {
		if (nextProps.id !== this.props.id) {
			setPageTitle(titles[nextProps.id], 'Apps');
		}
	}

	componentDidUpdate(prevProps) {
		let props = this.props;
		let state = this.state;

		if (props.loaded && !prevProps.loaded && this.$filter) {
			this.$filter.focus();
		}

		// Update the filter with the selected app title on load
		if (!this.gotApps && props.loaded && !props.error) {
			this.gotApps = true;

			if (props.query.app && !state.filter) {
				let selectedApp = props.list.apps[props.query.app];
				let titleName = props.list.titles[selectedApp?.rel?.title]?.en;
				let regionSlug = props.list.regions[selectedApp?.rel?.region]?.slug;
				if (titleName && regionSlug) {
					this.setState({
						// filter: '='+titleName
						filter: titleName + ', ' + regionSlug,
						minimized: window.innerWidth < 1024,
					});
				}
			}
		}
	}

	listScrolled(e) {
		Store.emit(actions.APP_LIST_SCROLLED, e);
	}

	filterKeydown(e) {
		// If enter is pressed
		if (e.keyCode === 13 && this.$appList) {
			// Go to edit mode for the first result
			this.$appList.editApp(0);
		}
	}

	focusSearchMaybe(data, e) {
		const focused = document.activeElement || document.body;
		if (focused.matches('input, textarea')) {
			return;
		}
		e.preventDefault();
		this.$filter.focus();
	}

	focusNextAppMaybe(data, e) {
		const focused = document.activeElement || document.body;
		if (focused !== this.$filter && !focused.matches('[data-app]')) {
			return;
		}
		e.preventDefault();
		let allApps = [...this.base.querySelectorAll('[data-app]')];
		let index = allApps.indexOf(focused) + data.direction;
		if (index < 0) {
			index = allApps.length - 1;
		} else if (index >= allApps.length) {
			index = 0;
		}
		allApps[index]?.focus();
	}

	goToBaseLevel() {
		goToPage('editApp', { base: 2, app: null, title: null, studio: null, studio_region: null, region: null, branch: null });
	}

	onFilterInput(e) {
		this.setState({ filter: e.target.value });
	}

	toggleMinimize() {
		this.setState({ minimized: !this.state.minimized });
	}

	render(props, { filter, minimized }) {
		let {
			error,
			loaded,
			id,
			query,
			myApps,
			list,
			apps,
			titles,
			studios,
			studioRegions,
			regions,
			bases,
			pageData,
			appData,
			activePage,
			screeningCounts,
			visitCounts,
			activeSessionCounts,
			activeAppMode
		} = props;

		if (error) {
			return (
				<div class={joinClasses(s.page, s.error)} key="error">
					Oh no! An error occurred while loading thundr
					<span class={s.note}>(we are probably having issues with the servers)</span>
				</div>
			);
		}

		let isServer = typeof window === 'undefined';
		let subPage = null;
		let imfeelinglucky = 'imfeelinglucky' in query;

		// let appIds = Object.keys(apps || {});
		if (loaded && pageData.subPageOf === 'apps' && !isServer) {
			switch (id) {
				case 'editApp': {
					let editProps = {
						thundrAppData: appData,
						warnOn: pageData.warnOn,
						devTypes: appData.dev && appData.dev.types,
						list,
						apps,
						regions,
						titles,
						studioRegions,
						activePage,
						studios,
						query,
						myApps,
						bases,
						activeAppMode
					};
					subPage = <MainEdit {...editProps} key="main" />;

					break;
				}
				case 'newApp': {
					let app = apps[+query.app];
					subPage = (
						<AppCreator
							appData={appData}
							activePage={activePage}
							query={query}
							list={list}
							apps={apps}
							titles={titles}
							studios={studios}
							studioRegions={studioRegions}
							regions={regions}
							bases={bases}
							like={app}
							onCreated={this.appCreated}
						/>
					);
					break;
				}
				case 'matchTitle': {
					let titleData = list.titles[+query.title];
					if (!titleData) {
						break;
					}
					subPage = <MatchTitles key="matchTitles" titleId={titleData.id} title={titles[titleData.id]} />;
					break;
				}
				case 'screenings': {
					let appInfo = list.apps[+query.app];
					if (!appInfo) {
						break;
					}
					let necessary = [['app', +query.app]].concat(Object.entries(appInfo?.rel) || {}).map(([type, id]) => ({ type, id }));
					Store.emit('REQUEST_MISSING', necessary);
					subPage = (
						<Screenings
							list={list}
							appId={appInfo.id}
							app={apps[appInfo.id]}
							title={titles[appInfo.rel.title]}
							region={regions[appInfo.rel.region]}
							studio={studios[appInfo.rel.studio]}
							studioRegion={studioRegions[appInfo.rel.studioRegion]}
							base={bases[appInfo.rel.base]}
						/>
					);
					break;
				}
				case 'insights': {
					let app = list.apps[+query.app];
					if (!app) break;
					let title = list.titles[app.rel.title];
					let region = list.regions[app.rel.region];
					let studio = list.studios[app.rel.studio];
					subPage = <Insights app={app} studio={studio} title={title} region={region} />;
					break;
				}
			}
		}

		let appList;
		if (!loaded) {
			appList = <div class={s.loading} key="loading" />;
		} else {
			appList = (
				<AppList
					class={s.appList} list={list} apps={apps} titles={titles} studios={studios}
					regions={regions} minimal={!!subPage} filter={filter} screeningCounts={screeningCounts}
					visitCounts={visitCounts} activeSessionCounts={activeSessionCounts}
					appData={appData} activePage={activePage} imfeelinglucky={imfeelinglucky}
					ref={e => this.$appList = e}
				/>
			);
		}

		let selectionClass = joinClasses(s.selection, subPage && s.minimal, minimized && s.minimized, loaded && s.loaded);
		return (
			<div class={s.page} key="apps">
				<div class={selectionClass} onScroll={this.listScrolled}>
					<div class={s.searchContainer}>
						<div class={s.searchInputWrapper}>
							<input
								value={filter}
								class={s.searchInput}
								onInput={this.onFilterInput}
								ref={e => this.$filter = e}
								onKeydown={this.filterKeydown}
								placeholder="Search by Title, Studio, or Region"
							/>
						</div>
						<button class={s.minimize} onClick={this.toggleMinimize} />
						<Link class={s.back} pageId="apps" />
					</div>
					{appList}
				</div>
				{subPage}
			</div>
		);
	}

	/**
	 * Children Callbacks
	**/

	appCreated(app) {
		goToPage('editApp', { app: app.id }, true);
	}

}

export const dependencies = {
	myApps : true,
	users: true,
	activeAppMode: true
};
