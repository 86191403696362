export default function() {
	let resolve;
	let reject;
	let prom = new Promise((_resolve, _reject) => {
		resolve = _resolve;
		reject = _reject;
	});
	prom.resolve = resolve;
	prom.reject = reject;
	return prom;
}
