/**
 * Access a deep member of an object and create intermediates if they are undefined.
 *
 * @param {!Object} obj - the object to look in
 * @param {Array.<string>|string} path - the path of the member in the object
 */
export default function createNested(obj, path) {
	if (typeof path === 'string') {
		path = path ? path.split('.') : [];
	}
	const levelCount = path.length;
	if (levelCount === 0) return obj;

	for (let i = 0; i < levelCount; i++) {
		if (obj === null || typeof obj !== 'object') {
			console.error(`Cannot access property "${path.slice(i).join('.')}" in ` +
				`"${path.slice(0, i).join('.')} (object is ${obj === null ? 'null' : typeof obj})`);
			return obj;
		}
		const level = path[i];
		if (!Object.prototype.hasOwnProperty.call(obj, level)) {
			obj[level] = {};
		}
		obj = obj[level];
	}
	return obj;
}
