import { h, Component } from 'preact';
import s from 'components/pages/apps/createOverride.sss';
import { joinClasses } from 'utils/utils';
import HTML from 'components/core/html/html';
import InputWithSuggestions from 'components/shared/inputWithSuggestions/inputWithSuggestions';

import Store from 'store/store';
import * as actions from 'store/actions';

import { svgRenderer } from 'components/shared/svgIcons/svgIcons';

// https://github.com/jesseweed/seti-ui/tree/master/icons
const SvgJS = svgRenderer(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
		<path fill="#F1DD3F" d="M11.4 10h2.7v7.6c0 3.4-1.6 4.6-4.3 4.6-.6 0-1.5-.1-2-.3l.3-2.2c.4.2.9.3 1.4.3 1.1 0 1.9-.5 1.9-2.4V10zm5.1 9.2c.7.4 1.9.8 3 .8 1.3 0 1.9-.5 1.9-1.3s-.6-1.2-2-1.7c-2-.7-3.3-1.8-3.3-3.6 0-2.1 1.7-3.6 4.6-3.6 1.4 0 2.4.3 3.1.6l-.6 2.2c-.5-.2-1.3-.6-2.5-.6s-1.8.5-1.8 1.2c0 .8.7 1.1 2.2 1.7 2.1.8 3.1 1.9 3.1 3.6 0 2-1.6 3.7-4.9 3.7-1.4 0-2.7-.4-3.4-.7l.6-2.3z" />
	</svg>
);
const SvgCSS = svgRenderer(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
		<path fill="#529BBA" d="M10.3 23.3l.8-4H8.6v-2.1h3l.5-2.5H9.5v-2.1h3.1l.8-3.9h2.8l-.8 3.9h2.8l.8-3.9h2.8l-.8 3.9h2.5v2.1h-2.9l-.6 2.5h2.6v2.1h-3l-.8 4H16l.8-4H14l-.8 4h-2.9zm6.9-6.1l.5-2.5h-2.8l-.5 2.5h2.8z" />
	</svg>
);

export default class CreateOverride extends Component {

	constructor(props) {
		super(props);
		this.getFilesList = this.getFilesList.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.toggleExtends = this.toggleExtends.bind(this);
		this.toggleReplace = this.toggleReplace.bind(this);
		this.submit = this.submit.bind(this);

		this.state = {
			files: [],
			extends: true,
			replace: false
		};

		fetch('/misc/overrides/file_list', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		}).then(res => res.text()).then(JSON.parse).then(files => this.setState({ files }));

		if (!props.studio && !props.region) {
			Store.emit(actions.HIDE_MODAL, 'INVALID_INVOCATION');
		}
	}

	componentDidMount() {
		this.$file.value = '';
		this.$file.focus();
	}

	onKeyDown(e) {
		if (e.keyCode === 27) { // ESC
			this.cancel();
			return e.preventDefault();
		}
	}

	toggleExtends(e) {
		let val = e && e.target ? e.target.checked : !this.state.extends;
		this.setState({ extends: val });
	}
	toggleReplace(e) {
		let val = e && e.target ? e.target.checked : !this.state.replace;
		this.setState({ replace: val });
	}

	cancel() {
		Store.emit(actions.HIDE_MODAL, 'CANCEL');
	}

	submit(e) {
		if (e && e.preventDefault) {
			// Prevent default form submission
			e.preventDefault();
		}

		let value = this.$file.value.trim();
		if (!this.state.files.includes(value)) {
			// let message = this.state.suggestions.length ? 'Please select a file from the suggestions' : 'No matching file found, please try something else';
			let message = 'No matching file found, are you sure you want to create an override for ' + value + '?';
			// eslint-disable-next-line no-alert
			if (!confirm(message)) {
				return this.$file.focus();
			}
		}
		Store.emit(actions.HIDE_MODAL, { file: value });
		fetch('/misc/overrides/create', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				file: value,
				extendsFromBase: this.state.extends,
				replace: this.state.replace,
				studio: this.props.studio,
				title: this.props.title,
				region: this.props.region
			})
		}).catch(err => {
			console.error(err);
			// eslint-disable-next-line no-alert
			alert('An error occurred while creating your override. Call a dev or something I guess.');
		});
	}

	getFilesList(val) {
		let files = this.state.files;
		// Remove /apps/ files unless explicitely requested
		if (!val.match(/\bapps\b/)) {
			files = files.filter(e => !e.includes('/apps/'));
		}
		// Same for bolt
		// if (!val.match(/\bbolt\b/)) {
		// 	files = files.filter(e => !e.includes('/bolt/'));
		// }
		return files;
	}

	renderSuggestion(suggestion, search, wrapped) {
		let name = suggestion.slice(suggestion.lastIndexOf('/') + 1);
		let icon = <div class={s.icon} />;
		if (suggestion.endsWith('.js')) {
			icon = <SvgJS class={s.icon} />;
		} else if (suggestion.match(/\.[cs]ss$/)) {
			icon = <SvgCSS class={s.icon} />;
		}
		return [
			<div class={s.filename}>
				{icon}
				<span class={s.name}>{name}</span>
			</div>,
			<HTML class={s.path} content={wrapped} />
		];
	}

	render() {
		return (
			<form class={s.container} onSubmit={this.submit} onKeyDown={this.onKeyDown}>
				<InputWithSuggestions
					ref={e => this.$file = e}
					placeholder="File..."
					list={this.getFilesList}
					classes={{ wrapper: s.fileWrapper, suggestion: s.suggestion, selected: s.selected }}
					suggestionHTML={this.renderSuggestion}
				/>
				<label class={joinClasses(s.toggle, s.extends)}>
					<input type="checkbox" checked={this.state.extends} onChange={this.toggleExtends} />
					<div class={s.indicator}><div class={s.slider} /></div>
					<span class={s.description}>Extend from base file</span>
				</label>
				<label class={joinClasses(s.toggle, s.replace)}>
					<input type="checkbox" checked={this.state.replace} onChange={this.toggleReplace} />
					<div class={s.indicator}><div class={s.slider} /></div>
					<span class={s.description}>Replace the file if it already exists</span>
				</label>
				<div class={s.buttons}>
					<button class={s.cancel} type="button" onClick={this.cancel}>Cancel</button>
					<button class={s.create} type="submit">Create</button>
				</div>
			</form>
		);
	}

}
