const defaultsDeep = require('@nodeutils/defaults-deep'); // QUESTION: should these be imports?
const pickBy = require('lodash/pickBy');
// const isEqual = require('lodash/isEqual');
import Store from 'store/store';
// import { updateAppData } from 'services/dataApi';
import { accessNested } from 'utils/utils';
import deleteNulls from 'utils/deleteNulls';

export const getLocalAppDataMerged = (appData, appDataTitle, appDataStudioRegion, appDataStudio, appDataRegion, appDataBase) => {
	let defaults = defaultsDeep(appData, appDataTitle, appDataStudioRegion, appDataStudio, appDataRegion, appDataBase);
	pickBy(defaults, true);

	return defaults;
};

// Gets the data attributes (or edited attributes if any)
function getAttributes(obj) {
	if (!obj) return null;
	let val = obj.edited || obj.attributes;
	if (typeof val.toJS === 'function') val = val.toJS();
	return val;
}
/**
 * Get the updated merged app data of a specific app
 * @param  {Interger} appId - the id of the app
 * @return {Object} The merged appdata
 */
export const getMergedAppData = (appId) => {
	let store = Store.get();

	let { apps, regions, studios, studioRegions, bases, titles } = store;

	let branch = accessNested(store, 'query.branch');

	let app = apps[appId];
	let branchAttr = null;
	let appAttr = getAttributes(app);
	let titleAttr = getAttributes(titles[app.rel.title]);
	let studioRegionAttr = getAttributes(studioRegions[app.rel.studioRegion]);
	let studioAttr = getAttributes(studios[app.rel.studio]);
	let regionAttr = getAttributes(regions[app.rel.region]);
	let baseAttr = getAttributes(bases[app.rel.base]);

	if (branch) {
		branchAttr = accessNested(appAttr, ['dev', 'branches', branch, 'content'], null);
	}

	let mergedApp = defaultsDeep({}, branchAttr, appAttr, titleAttr, studioRegionAttr, studioAttr, regionAttr, baseAttr);
	deleteNulls(mergedApp, true);

	return mergedApp;
};

export const getUnmergedAppData = (appData) => {
	let store = Store.get();

	let unmergedAppData = {};
	let { apps, regions, studios, studioRegions, bases, titles } = store;
	let appId = appData.meta && appData.meta.id;
	let titleSlug = appData.meta && appData.meta.title && appData.meta.title.slug;
	let studioSlug = appData.meta && appData.meta.studio && appData.meta.studio.slug;
	let regionSlug = appData.meta && appData.meta.region && appData.meta.region.slug;

	if (appId) {
		// Add in app data
		unmergedAppData.app = apps[appData.meta.id];
	}

	if (titleSlug) {
		// Add in title data
		unmergedAppData.title = Object.values(titles).find(t => accessNested(t, 'attributes.meta.title.slug') === titleSlug);
	}

	if (studioSlug) {
		// Add in studio data
		unmergedAppData.studio = Object.values(studios).find(s => accessNested(s, 'attributes.meta.studio.slug') === studioSlug);
	}

	if (regionSlug) {
		// Add in studio data
		unmergedAppData.region = Object.values(regions).find(r => accessNested(r, 'attributes.meta.region.slug') === regionSlug);
	}

	if (unmergedAppData.studio && unmergedAppData.region) {
		let studioId = unmergedAppData.studio.id;
		let regionId = unmergedAppData.region.id;
		// Add in studio data
		unmergedAppData.studioRegion = Object.values(studioRegions).find(sr => {
			return sr.rel.studio === studioId && sr.rel.region === regionId;
		});
	}

	unmergedAppData.base = bases[1];

	return unmergedAppData;
};

export function attachDummyTitle(apps, state) {
	state = state || Store.get();
	let counts = state.screeningCounts || {};
	let countKeys = Object.keys(counts);
	let allAppsO = state.apps;
	let allApps = Object.values(allAppsO);
	let allTitles = Object.values(state.titles);
	let allRegions = Object.values(state.regions);
	let getId = o => o && o.id;
	apps.forEach(app => {
		if (!app.dev.useDemoDummyData || app.dev.suggestedDummySlug) {
			return;
		}
		let studioId = allAppsO[app.meta.id].rel.studio;
		let region = app.meta.region.slug;
		if (region === 'intl') {
			region = 'us';
		}
		let regionShort = region.slice(0, 2);
		let regionId = getId(allRegions.find(r => r.attributes.meta.region.slug === region));
		let regionCounts = {};
		countKeys.forEach(ck => {
			let idx = ck.lastIndexOf('|' + regionShort);
			if (idx === -1) {
				return;
			}
			let name = ck.slice(0, idx);
			if (!Object.prototype.hasOwnProperty.call(regionCounts, name)) {
				regionCounts[name] = 0;
			}
			regionCounts[name] += counts[ck];
		});
		let ordered = Object.keys(regionCounts).sort((a, b) => regionCounts[b] - regionCounts[a]);
		let max = ordered[0];
		// Get the top app from the same studio if poss
		let maxStudio = regionId && studioId && ordered.find(slug => {
			if (regionCounts[slug] <= 100) {
				return false;
			}
			let titleId = getId(allTitles.find(t => t.attributes.meta.title.slug === slug));
			if (!titleId) {
				return false;
			}
			let foundApp = allApps.find(a => a.rel.title === titleId && a.rel.region === regionId);
			return foundApp && foundApp.rel.studio === studioId;
		});
		if (maxStudio) {
			max = maxStudio;
		}
		if (max && regionCounts[max] > 30) {
			app.dev.suggestedDummySlug = max;
		}
	});
}

export function attachDemoRegionSelectorData(apps, state) {
	state = state || Store.get();
	let allApps = state.list.apps;
	let allAppsArray = Object.values(allApps);
	return apps.map(app => {
		if (!accessNested(app, 'header.powsterHeader.regionSelector')) {
			return app;
		}
		let appMeta = allApps[app.meta && app.meta.id];
		let titleId = appMeta && appMeta.rel.title;
		let baseId = appMeta?.rel?.base;
		if (!titleId) {
			return app;
		}
		app = JSON.parse(JSON.stringify(app));
		let regionSelector = accessNested(app, 'header.powsterHeader.regionSelector');
		regionSelector.extraRegions = regionSelector.extraRegions || {};
		let studioSlug = accessNested(app, 'meta.studio.slug');
		let titleSlug = accessNested(app, 'meta.title.slug');
		allAppsArray.forEach(alt => {
			if (alt.rel.title !== titleId || alt.rel.base !== baseId) {
				return;
			}
			let region = state.list.regions[alt.rel.region];
			let regionSlug = region?.slug;
			let languageFull = region?.languageFull;
			let lang = region?.language;
			if (!regionSlug || regionSelector.extraRegions[regionSlug]) {
				return;
			}
			let url = 'http://demo.pow.io/' + studioSlug + '/' + titleSlug + '/' + regionSlug + '/';
			regionSelector.extraRegions[regionSlug] = { url, lang, languageFull };
		});
		return app;
	});
}
