import createTooltip from 'utils/tooltip';

function createHiddenInput() {
	const input = document.createElement('textarea');
	input.style.position = 'fixed';
	input.style.left = '-999px';
	input.style.top = '-999px';
	input.style.width = '1px';
	input.style.height = '1px';
	document.body.appendChild(input);
	return input;
}

export default function pasteData(options) {
	if (typeof window === 'undefined') {
		return Promise.resolve(null);
	}
	let {
		tooltipText
	} = (options || {});
	return new Promise(resolve => {
		const key = navigator.userAgent.indexOf('Mac OS X') !== -1 ? 'Cmd' : 'Ctrl';
		if (!tooltipText) {
			tooltipText = 'Press %MOD_KEY%+V to paste (click anywhere to cancel)';
		}
		tooltipText = tooltipText.replace(/%MOD_KEY%/g, key);
		let tooltip = createTooltip(tooltipText);

		let input = createHiddenInput();
		input.focus();

		let removed = false;
		const remove = () => {
			if (removed) return;
			removed = true;
			document.body.removeChild(input);
			input = null;
			tooltip.remove();
		};
		function cancel() {
			if (removed) return;
			remove();
			resolve(null);
		}

		input.addEventListener('blur', cancel);
		// input.addEventListener('input', () => {
		// 	if (removed) return;
		// 	const v = input.value;
		// 	remove();
		// 	resolve(v);
		// });
		input.addEventListener('paste', (e) => {
			if (removed) return;
			const clipboard = e.clipboardData || window.clipboardData;
			remove();
			resolve(clipboard.getData('text'));
		});
		input.addEventListener('keydown', (e) => {
			if (removed) return;
			if (e.keyCode === 27) cancel();
		});
	});
}
