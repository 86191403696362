import { h, Component } from 'preact';
import pure from 'utils/pure';
import { accessNested } from 'utils/utils';
import Issue from 'components/shared/infoSidebar/issues/issue';

import s from 'components/shared/infoSidebar/issues/issues.sss';

/**
 * Listing out all the existing issues
 * @prop {Object} myApps - object with all of the currently build apps
**/
export default @pure class Issues extends Component {

	render(props) {
		let errorApps = [];
		if (props.myApps) {
			errorApps = Object.keys(props.myApps).map(key => props.myApps[key]).filter(app => app?.status?.errors?.length);
		}

		if (!errorApps.length) {
			return (
				<div class={s.container}>
					<h2 class={s.success}>Yay! No Issues Detected</h2>
				</div>
			);
		}

		return (
			<div class={s.container}>
				{errorApps.map(app => {
					const region = accessNested(app, 'app.meta.region.slug') || '??';
					const studio = accessNested(app, 'app.meta.studio.name') || '????';
					const title = accessNested(app, 'app.meta.title.en') || '????';
					return (
						<div class={s.item}>
							<div class={s.itemHeader}>
								<p class={s.titleDetails}>{region + ' | ' + studio}</p>
								<div>{title}</div>
							</div>
							<div class={s.issues}>
								{app.status.errors.map(err => <Issue appId={app.app.meta.id} issue={err} />)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

}
