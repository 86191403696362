import { h } from 'preact';

const Pinterest = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="img" {...props}>
			<path d="M11.493 3.23C10.667 2.437 9.523 2 8.273 2c-1.908 0-3.082.783-3.731 1.439-.8.808-1.258 1.882-1.258 2.947 0 1.335.559 2.361 1.494 2.743a.487.487 0 00.188.039c.197 0 .354-.129.408-.336.033-.12.105-.413.137-.54.068-.252.013-.372-.136-.548-.27-.321-.397-.699-.397-1.192 0-1.464 1.09-3.02 3.11-3.02 1.603 0 2.599.911 2.599 2.378 0 .926-.2 1.782-.561 2.413-.252.438-.695.961-1.373.961a.912.912 0 01-.724-.331.83.83 0 01-.146-.723c.071-.303.169-.618.263-.923.173-.557.335-1.084.335-1.503 0-.718-.441-1.2-1.098-1.2-.835 0-1.489.848-1.489 1.93 0 .531.141.927.205 1.08l-.849 3.594c-.069.291-.482 2.595.202 2.778.768.207 1.455-2.037 1.524-2.29.057-.206.255-.986.376-1.465.37.357.968.598 1.548.598 1.095 0 2.079-.493 2.773-1.387.672-.867 1.042-2.077 1.042-3.406.001-1.036-.445-2.06-1.222-2.806z" />
		</svg>
	);
};

export default Pinterest;
