import { h, Component } from 'preact';
import { encodeHtml, joinClasses, loadScript } from 'utils/utils';

import HTML from 'components/core/html/html';

import s from 'components/pages/apps/diffList.sss';

const loadStyle = url => document.querySelector('link[href="' + url + '"]') || new Promise((res, rej) => {
	let link = document.createElement('link');
	link.setAttribute('rel', 'stylesheet');
	link.onload = res;
	link.onerror = rej;
	link.href = url;
	document.head.appendChild(link);
});

const displayJSON = (value) => {
	if (window.prettyPrintJson) {
		return window.prettyPrintJson.toHtml(value, { indent: 4, linkUrls: false, trailingCommas: false });
	}
	return encodeHtml(JSON.stringify(value, null, 4));
};

const isDeploy = diff => diff?.op === 'add' && /^\/dev\/history(\/|$)/.test(diff.path);

export default class DiffList extends Component {
	componentDidMount() {
		Promise.all([
			!document.querySelector('link[href*="pretty-print-json"]') && loadStyle('https://cdn.jsdelivr.net/npm/pretty-print-json@3.0.1/dist/css/pretty-print-json.min.css'),
			!document.querySelector('script[src*="pretty-print-json"]') && loadScript('https://cdn.jsdelivr.net/npm/pretty-print-json@3.0/dist/pretty-print-json.min.js')
		]).then(() => this.setState({ prettyPrintLoaded: true }));
	}

	render(props) {
		const changes = props.changes || [];
		return (
			<table class={joinClasses(s.wrapper, props.class)} key="list">
				<thead><tr><td>Action</td><td>Path</td><td>Value</td></tr></thead>
				<tbody>
					{changes.map(diff => {
						let { path, op, value } = diff;
						if (path.startsWith('/dev/disabledItems')) return;
						if ((op === 'add' || op === 'replace') && value === null) {
							op = 'disable';
						}
						if (isDeploy(diff)) {
							op = 'deploy'; path = 'prod'; value = '';
						}
						let isJSON = value && typeof value === 'object';
						let valueElement;
						if (isJSON) {
							valueElement = <HTML tag="td" class={s.value} content={displayJSON(value)} />;
						} else {
							valueElement = <td class={s.value}>{value != null && value.toString()}</td>;
						}
						return (
							<tr class={joinClasses(s.row, s['op-' + op], value === null && s.disabled)}>
								<td class={s.op}>{op}</td>
								<td class={s.path}>{path}</td>
								{valueElement}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}