import { h, Component } from 'preact';
import { addClass, removeClass, joinClasses, debounce } from 'utils/utils';
import { track } from 'utils/tracking';
import { clamp } from 'utils/math';
import s from 'components/shared/autoCompleteInput/autoCompleteInput.sss';
import SuggestionsBox from 'components/shared/autoCompleteInput/suggestionsBox';
import pure from 'utils/pure';

// TODO: more reacty (generate suggestions as VDom, put them in the state, render them using [p]react)
export default @pure class AutoCompleteInput extends Component {

	constructor(props) {
		super(props);
		this.completion = this.completion.bind(this);
		this.triggerOnChange = this.triggerOnChange.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.inputInput = this.inputInput.bind(this);
		if (props.debounce) {
			let delay = props.debounce === 'number' ? props.debounce : 250;
			this.inputInput = debounce(this.inputInput, delay);
		}
		this.selfModif = false;
		this.matches = [];
		this.selectedIndex = -1;
		this.savedValue = props.value;
		// Issue with the selection thing : as we do a fuzzy search (indexOf and not startsWith) how should it work when you select something that doesn't start with your input ?
		// this.handleSelection = false;
		this.changeNext = false;
		this.words = props.words;
	}

	componentDidMount() {
		if (this.$input && this.$input.value) {
			this.setState({ editing: true });
		}
		this.setValue(this.props.value);
	}

	componentWillReceiveProps(props) {
		if (props.words !== this.props.words) {
			this.words = props.words
			this.handleSuggestions(false);
		}
	}

	componentWillUpdate(props) {
		if (props.value !== this.props.value) {
			this.setValue(props.value);
		}
	}

	getValue() {
		const val = { type: 'custom', value: '' };
		if (this.$input) {
			val.value = this.$input.value;
		}
		if (this.selectedMatch) {
			val.type = 'match';
			val.match = this.selectedMatch;
			val.value = val.match.value;
		}
		// Check if selected from suggestions
		return val;
	}

	setValue(v) {
		if (!this.$input || typeof v === 'undefined') return;
		this.savedValue = v;
		this.$input.value = v;
		// this.$input.dispatchEvent(new Event('input'));
		this.inputInput();
	}

	reset() {
		this.setValue('');
	}

	selectAll() {
		this.$input.select();
		track({ a: 'search_click' });
	}

	triggerOnChange() {
		let currentValue = this.getValue();
		this.savedValue = currentValue.value;
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(currentValue);
		}
	}

	selectMatch(match) {
		this.selectedMatch = match;
		if (match) {
			this.$input.value = match.value;
		}
	}

	// Generates a function used to tell which value is a match or not
	getWordFilter(word) {
		if (typeof this.props.validate === 'function') {
			return elem => this.props.validate(word, elem);
		}
		let validate = value => value.toLowerCase().indexOf(word) !== -1;
		if (this.props.handleSelection) {
			validate = value => value.toLowerCase().indexOf(word) === 0;
		}
		return (elem) => {
			if (!elem.value || typeof elem.value !== 'string') return false;
			// indexOf or startsWith ?
			if (validate(elem.value)) return true;
			let alt = elem.alt || [];
			if (!Array.isArray(alt)) alt = [alt];
			return alt.some(validate);
		};
	}

	handleSuggestions(noInputModif) {
		const input = this.$input;
		let val = input.value && input.value.toLowerCase();
		let valLen = val.length;
		const oldSelection = this.matches[this.selectedIndex];

		// if (this.props.handleSelection && input.selectionEnd === valLen) {
		// 	val = val.slice(0, input.selectionStart);
		// 	valLen = val.length;
		// }
		// console.trace(val, oldSelection, input.selectionStart, input.selectionEnd);

		this.setState({ editing: val || input == document.activeElement });
		// this.matches = val && input == document.activeElement ? (this.props.words || []).filter(e => e.value && e.value.toLowerCase().indexOf(val) !== -1) : [];
		this.matches = val && input == document.activeElement ? (this.words || []).filter(this.getWordFilter(val)) : [];
		if (!val && this.props.showAllWhenEmpty) {
			this.matches = this.words || [];
		}

		this.matches.sort((a, b) => {
			// Sort by order first
			if (a.order !== b.order) {
				return a.order - b.order;
			}
			const vA = a.value.toLowerCase();
			const vB = b.value.toLowerCase();
			const inA = vA.indexOf(val) !== -1;
			const inB = vB.indexOf(val) !== -1;
			if (inA !== inB) {
				return inA ? 1 : -1;
			}

			let aHasSubOrder = typeof a.subOrder === 'number' && !Number.isNaN(a.subOrder);
			let bHasSubOrder = typeof b.subOrder === 'number' && !Number.isNaN(b.subOrder);
			if (!aHasSubOrder && !bHasSubOrder) {
				// Alphabetical
				return vA < vB ? -1 : 1;
			}
			if (!aHasSubOrder || !bHasSubOrder) {
				return aHasSubOrder ? -1 : 1;
			}
			return a.subOrder - b.subOrder;
		});

		// Then reduce
		const counts = {};
		const maxPerType = this.props.maxPerType;
		if (maxPerType) this.matches = this.matches.filter(e => {
			if (!e.type) return true;
			const count = counts[e.type] = (counts[e.type] || 0) + 1;
			return count <= maxPerType;
		});

		this.refreshSuggestions();
		let newSelection = this.matches.indexOf(oldSelection);
		let defaultSelect = this.props.handleSelection || this.props.defaultSelect;
		if (newSelection === -1 && defaultSelect) {
			newSelection = 0;
		}
		this.changeSelection(newSelection, noInputModif || (this.props.handleSelection && (input.selectionStart !== valLen || input.selectionEnd !== valLen)));
	}

	refreshSuggestions() {
		let e;
		while ((e = this.suggestionsBox.firstChild)) this.suggestionsBox.removeChild(e);
		const generateMouseDownEvent = (match) => {
			return () => {
				this.selectMatch(match);
				this.triggerOnChange();
				this.$input.blur();
			};
		};
		for (let i = 0, l = this.matches.length; i < l; i++) {
			const match = this.matches[i];
			e = document.createElement('div');
			addClass(e, [s.suggestion, match.className].filter(e => e).join(' '));
			if (match.customContent) {
				match.customContent(e, match);
			} else {
				e.textContent = match.label || match.value;
			}
			// Pass the match
			e.addEventListener('mousedown', generateMouseDownEvent(match));
			this.suggestionsBox.appendChild(e);
		}
		if (this.matches.length) {
			const box = this.suggestionsBox.getBoundingClientRect();
			const ibox = this.$input.getBoundingClientRect();

			if (box.bottom > window.innerHeight - 30 && ibox.top - (box.bottom - box.top) > 0) {
				addClass(this.suggestionsBox, s.over);
			} else {
				removeClass(this.suggestionsBox, s.over);
			}
		}
	}

	changeSelection(id, noInputModif) {
		let c = this.suggestionsBox.firstChild;
		// const match = matches[selectedIndex];
		while (this.selectedIndex-- > 0 && c && (c = c.nextSibling));
		if (c) removeClass(c, s.selected);
		this.selectedIndex = id;
		if (id === -1) return;
		c = this.suggestionsBox.firstChild;
		while (id-- > 0 && c && (c = c.nextSibling));
		if (c) {
			addClass(c, s.selected);
			// Make sure it's visible (if there is more than the max height)
			this.suggestionsBox.scrollTop = clamp(this.suggestionsBox.scrollTop, c.offsetTop + c.offsetHeight - this.suggestionsBox.offsetHeight, c.offsetTop);
		}
		if (this.props.handleSelection && !noInputModif) {
			const newText = this.matches[this.selectedIndex].value;
			const start = this.$input.selectionStart;
			if (newText && this.$input.value.slice(0, start).toLowerCase() === newText.slice(0, start).toLowerCase()) {
				this.$input.value = newText;
				this.$input.selectionStart = start;
			}
		}
	}

	// inputMousedown = () => {
	// 	this.handleSuggestions();
	// }

	inputBlur = () => {
		this.setState({ focused: false });
		if (this.$input.value === '') {
			this.setState({ editing: false });
			// input.value = that.props.value || '';
		}
		this.handleSuggestions();
		if (this.props.validateOnBlur) {
			this.triggerOnChange();
		}
	}

	inputFocus = () => {
		this.setState({ focused: true });
		this.handleSuggestions();
		this.selectAll();
	}

	inputKeydown = (e) => {
		if (e && typeof this.props.onKeydown === 'function') {
			this.props.onKeydown(e);
		}
		if (e.keyCode == 13) {
			if (!this.matches[this.selectedIndex] && this.props.doNotRedirectToEmpty) {
				e.preventDefault();
				return;
			} else {
				this.selectMatch(this.matches[this.selectedIndex]);
				e.preventDefault();
				this.$input.blur();
				this.triggerOnChange();
				return;
			}
		}
		if (e.keyCode == 27) {
			this.matches = [];
			this.refreshSuggestions();
			this.changeSelection(-1);
			return;
		}
		if (e.keyCode == 38) {
			const newId = this.selectedIndex - 1;
			this.changeSelection(newId < -1 ? newId + 1 + this.matches.length : newId);
			e.preventDefault();
			return;
		}
		if (e.keyCode == 40) {
			const newId = this.selectedIndex + 1;
			this.changeSelection(newId >= this.matches.length ? newId - this.matches.length - 1 : newId);
			e.preventDefault();
			return;
		}
		if (e.keyCode == 37 || e.keyCode == 39) {
			this.handleSuggestions(true);
		}
	}

	inputKeypress = (e) => {
		if (e.charCode === 0 || e.charCode === 13) return;
		this.changeNext = true;
	}

	inputInput(e) {
		if (e && typeof this.props.onInput === 'function') {
			let result = this.props.onInput(e);
			if (result === false) return;
		}
		this.selectedMatch = null;
		this.handleSuggestions(!this.changeNext);
		this.changeNext = false;
	}

	completion() {
		if (!this.$input) return;

		this.matches = [];
		this.selectedIndex = -1;
		this.changeNext = false;

		this.registerHandlers();
	}

	clearSelection = () => {
		// TODO more cleanup
		track({ a: 'theater-search-cleared' });
		this.setState({ editing: false });
		const clearingSomething = this.$input.value != "";

		//prevent another search from happening if the user clears an empty input
		if(clearingSomething){
			// TODO go back to original search city
			// this.$input.value = '';
			this.reset();
			this.triggerOnChange();
		}
	}

	render(props) {
		const {
			extraClasses,
			focusedClass,
			editingClass,
			unsavedClass,
			placeholder,
			noResetButton
		} = props;

		let placeholderClass = joinClasses(s.placeholder, props.placeholderClass);
		let closeClass = s.closeBtn;

		let resetBtn;
		if (!noResetButton) {
			resetBtn = <div class={closeClass} onClick={this.clearSelection} />;
		}

		let classes = joinClasses(
			s.autocomplete,
			props.class || extraClasses,
			!noResetButton && s.withResetButton,
			this.state.editing && s.editing,
			this.state.editing && editingClass,
			this.state.focused && s.focused,
			this.state.focused && focusedClass,
			this.getValue().value !== this.savedValue && unsavedClass,
		);

		return (
			<div class={classes}>
				<div class={placeholderClass}>{placeholder}</div>
				{resetBtn}
				<input ref={$ref => this.$input = $ref}
					class={joinClasses(s.input, 'autoCompleteInput')}
					onMousedown={this.inputMousedown}
					onMouseup={this.props.onMouseup}
					onBlur={this.inputBlur}
					onFocus={this.inputFocus}
					onKeydown={this.inputKeydown}
					onKeypress={this.inputKeypress}
					onKeyup={this.props.onKeyup}
					onInput={this.inputInput}
					disabled={props.disabled}
					aria-label={props.ariaLabel || ''}
				/>
				<SuggestionsBox class={s.suggestions} ref={$ref => $ref && (this.suggestionsBox = $ref.base)} />
			</div>
		);
	}

}
