import Store from 'store/store';
import * as actions from 'store/actions';
import { getAppDataMerged, updateAppData } from 'services/dataApi.js';
import { getUnmergedAppData, attachDummyTitle, attachDemoRegionSelectorData } from 'utils/appDataUtils';
import { accessNested } from 'utils/utils';
import createNested from 'utils/createNested';
// import validateAppData from '../../utils/validateAppData';
import cloneDeep from 'lodash/cloneDeep';

// should set this in the baseDoc or using app server after user login as __API_KEY__ or something
const API_KEY = typeof window !== 'undefined' && (window.__AUTH_USER__.apiKey || localStorage['__API_KEY__']);
const authHeader = {
	Accept: 'application/vnd.api+json',
	'Content-Type': 'application/vnd.api+json',
	Authorization: API_KEY
};

export const buildApp = async (apps, type, titleData, debug) => {
	let buildStatsData = {};
	// let buildApps = [];

	apps = apps.filter(app => app.meta && app.meta.id);

	let buildTime = Date.now();

	const initialStatus = apps.map(() => ({ validating: true }));
	Store.emit(actions.ADD_MY_APPS, apps, type, initialStatus, titleData);

	const movieIdInfo = Store.get().movieIdInfo || {};

	let buildApps = await Promise.all(apps.map(async (app) => {
		buildStatsData[app.meta.id] = {
			extraData: getUnmergedAppData(app),
			time: buildTime
		};

		const errors = await validateAppData({
			appData: app,
			deploy: true,
			buildType: type,
			titleData,
			movieIdInfo
		});
		let hasErrors = errors?.length;
		let newStatus = { validating: false };
		if (hasErrors) {
			newStatus.errors = errors;
		}
		Store.emit(actions.UPDATE_MY_APP, { status: newStatus }, app.meta.id);
		if (hasErrors) {
			return;
		}
		return app;
	}));

	// Reverse the order, so the first one being build shows up at the top
	buildApps = buildApps.filter(e => e).reverse();

	// No apps to be built
	if (!buildApps.length) {
		return;
	}

	if (type !== 'prod') {
		attachDummyTitle(buildApps);
		buildApps = attachDemoRegionSelectorData(buildApps);
	}

	try {
		let res = await fetch('/build', {
			method: 'POST',
			body: JSON.stringify({
				apps: buildApps,
				buildStatsData,
				type,
				user: Object.assign({}, Store.get().user, window.user),
				debug
			}),
			headers: authHeader
		});
		return res.json();
	} catch (err) {
		console.error('BUILD ERR', err);
		let errorState = { errors: [{ message: 'Error calling /build: ' + err }] };
		apps.forEach(app => {
			Store.emit(actions.UPDATE_MY_APP, { status: errorState }, app.meta.id);
		});
	}
};

export const uploadApp = (app, target, invisible) => {
	//TODO: add in saving script for when they push to production
	let store = Store.get();
	let startedAt = accessNested(store.myApps, [app.meta.id, 'status', 'build', 'startedAt']);
	let user = Object.assign({}, store.user, window.user);
	let body = { app, target, user, buildTime: startedAt, invisible: invisible };
	return fetch('/upload', {
		method: 'POST',
		body: JSON.stringify(body),
		headers: authHeader
	}).then(res => res.json()).then(res => {
		if (res.error || target !== 'prod' || invisible) {
			return res;
		}
		let store = Store.get();
		let fullAppOriginal = store.apps[app.meta.id];
		// If there is no app...
		if (!fullAppOriginal) {
			return res;
		}
		// Store this push in the history of the appdata so we have a record of all the live pushes
		let fullApp = store.apps[app.meta.id].toJS();
		let time = Date.now();

		// Get the history safely
		const history = createNested(fullApp, 'attributes.dev.history');

		history[time] = {
			user: window.user.name,
			type: target,
			createdAt: time
		};

		fullApp.attributes.dev.history = history;

		return updateAppData(
			fullApp.id,
			fullApp.type.replace('app_data_', ''),
			fullApp,
			fullAppOriginal
		).then(data => Store.emit(actions.UPDATE_APP_DATA, data));
	}).catch(err => {
		console.error('UPLOAD ERR', err);
		return { error: err };
	});
};

export const prepareSony = (app, target) => {
	return fetch('/build/prepare-sony', {
		method: 'POST',
		body: JSON.stringify({
			app,
			target,
			user: Store.get().user
		}),
		headers: authHeader
	}).then(res => res.json()).catch(err => console.error('PREPARE SONY ERR', err));
};

export const prepareNetflix = (app, target) => {
	return fetch('/build/prepare-netflix', {
		method: 'POST',
		body: JSON.stringify({
			app,
			target,
			user: Store.get().user
		}),
		headers: authHeader
	}).then(res => res.json()).catch(err => console.error('PREPARE NETFLIX ERR', err));
};

export const validateAppData = (data) => {
	// data can contain: appData, deploy, buildType, titleData, movieIdInfo
	return fetch('/build/validate', {
		method: 'POST',
		body: JSON.stringify(data),
		headers: authHeader
	}).then(res => res.json()).catch(err => console.error('VALIDATE APP DATA', err));
};

export const resetApp = (app, target) => {
	return fetch('/reset', {
		method: 'POST',
		body: JSON.stringify({
			app,
			target,
			user: Store.get().user
		}),
		headers: authHeader
	}).then(res => res.json()).catch(err => console.error('RESET ERR', err));
};

export const invalidateApp = (merged) => {
	return fetch('/distribution/invalidate', {
		method: 'POST',
		body: JSON.stringify({ merged }),
		headers: authHeader
	}).then(r => r.json()).catch(err => console.error('INVALIDATION ERR', err));
};
export const invalidateStatic = (merged) => {
	return fetch('/distribution/invalidate/static', {
		method: 'POST',
		body: JSON.stringify(merged),
		headers: authHeader
	}).then(r => r.json()).catch(err => console.error('INVALIDATION ERR', err));
};

export const updateCloudfrontBehaviors = (merged) => {
	return fetch('/distribution/update-behaviors', {
		method: 'POST',
		body: JSON.stringify({
			merged
		}),
		headers: authHeader
	}).then(r => r.json()).catch(err => console.error('UPDATE CLOUDFRONT ERR', err));
};

export const getGoogleSpreadsheet = (sheetId, opts = {}) => {
	return fetch('/gsheets/' + (opts.full ? 'full/' : '') + sheetId, {
		method: 'GET',
		headers: authHeader
	}).then(r => r.json()).catch(err => console.error('GET GSHEET ERR', err));
};

export const attachExistingDistribution = async (cloudfrontId, path, slugs, app) => {
	try {
		let res = await fetch('/distribution/get?' + new URLSearchParams({
			id: cloudfrontId
		}), {
			method: 'GET',
			headers: authHeader
		});
		let data = await res.json();

		if (data.statusCode >= 400) {
			throw data.message;
		}
		let update = cloneDeep(app);
		if (!update.attributes.dev) {
			update.attributes.dev = {};
		}
		const distributionDomainName = accessNested(data, 'Distribution.DomainName', '');
		update.attributes.dev.cloudfrontID = cloudfrontId;
		update.attributes.dev.cloudfrontDomain = distributionDomainName;
		update.attributes.dev.pathForCloudfront = path;
		app = await updateAppData(update.id, 'apps', update, app);
		Store.emit(actions.UPDATE_APP_DATA, app);

		// add lambda to change origin
		const customOriginData = {
			path,
			studio: slugs.studio,
			title: slugs.title,
			region: slugs.region
		};

		let result = await fetch('/distribution/set-custom-origin', {
			method: 'POST',
			body: JSON.stringify({ customOriginData, cloudfrontId }),
			headers: authHeader
		});

		let resultData = await result.json();

		if (resultData.statusCode >= 400) {
			throw 'Error setting custom origin';
		}

		return app;
	} catch (err) {
		Store.emit(actions.SHOW_MODAL, err, 'Error attaching existing distribution');
		console.error('DISTRIBUTION ERROR:', err);
		return false;
	}
};

export const attachDistribution = async (app) => {
	try {
		// Should probably get the merged data locally without an API call
		let merged = await getAppDataMerged(app.id);

		let res = await fetch('/distribution/create', {
			method: 'POST',
			body: JSON.stringify(merged),
			headers: authHeader
		});
		let data = await res.json();

		let update = cloneDeep(app);
		if (!update.attributes.dev) {
			update.attributes.dev = {};
		}
		if (data.statusCode >= 400) {
			throw data.message;
		}
		update.attributes.dev.cloudfrontID = data.Distribution.Id;
		update.attributes.dev.cloudfrontDomain = data.Distribution.DomainName;

		app = await updateAppData(update.id, 'apps', update, app);
		Store.emit(actions.UPDATE_APP_DATA, app);
		return app;
	} catch (err) {
		Store.emit(actions.SHOW_MODAL, err, 'Distribution Creation Error');
		console.error('DISTRIBUTION ERROR:', err);
		return false;
	}
};

// updates is an array of objects containing `path` (e.g. 'DistributionConfig.Origins.Quantity') and `value`
export const updateDistribution = (app, updates) => {
	let id = accessNested(app, 'attributes.dev.cloudfrontID');
	if (!id) return Promise.reject('no distribution');
	return fetch('/distribution/update', {
		method: 'POST',
		body: JSON.stringify({
			id: id,
			updates
		}),
		headers: authHeader
	}).then(res => res.json());
};

export const getComponentFiles = () => {
	return fetch('/misc/component_files', {
		method: 'GET',
		headers: authHeader
	}).then(r => r.json());
};
