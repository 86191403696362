import { h } from 'preact';
import Page from 'components/pages/page';
import { Link } from 'components/core/link/link';
import pure from 'utils/pure';
import s from 'components/pages/docs/docs.sss';
import Arch from 'components/pages/docs/arch';
import MarkdownDoc from 'components/pages/docs/markdownDoc';
// import qs from 'qs';
import { joinClasses } from 'utils/utils';
import slugify from 'slugify';

slugify.extend({ '|': '' });
function slug(str) {
	if (!str || typeof str !== 'string') {
		return undefined;
	}
	return slugify(str, { lower: true, remove: /[^\w\s-]/g });
}

export default @pure class Docs extends Page {

	constructor(props) {
		super(props);
		this.state.activeDoc = this.getActiveDoc(props);
		this.state.docStructure = null;
		this.state.loading = true;
	}

	async componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}
		try {
			const res = await fetch('/doc/docStructure.json');
			const docStructure = await res.json();
			docStructure.forEach(group => {
				if (!group.items) {
					return;
				}
				group.slug = slug(group.title);
				group.items.forEach(item => {
					item.group = group;
					item.slug = slug(item.label);
				});
			});
			this.setState({
				docStructure,
				activeDoc: this.getActiveDoc(null, docStructure)
			});
		} catch (e) {
			console.error('Error while fetching docs', e);
		}
		this.setState({ loading: false });
	}

	componentWillReceiveProps(props) {
		let activeDoc = this.getActiveDoc(props);
		if (activeDoc !== this.state.activeDoc) {
			this.setState({ activeDoc });
		}
	}

	componentDidUpdate(props, state) {
		if (state.activeDoc !== this.state.activeDoc && this.$content) {
			this.$content.scrollTop = 0;
		}
	}

	getActiveDoc(props, docStructure) {
		docStructure = docStructure || this.state.docStructure;
		if (!docStructure || !docStructure.length) {
			return null;
		}

		props = props || this.props;
		let query = props.query || {};
		let allItems = [].concat(...docStructure.map(e => e.items)).filter(e => e);
		let requested = slug(query.doc);
		let matching = allItems.filter(item => item.slug === requested);
		if (!matching.length) {
			return docStructure[0].items && docStructure[0].items[0];
		}
		return matching.find(item => item.group.slug === query.group) || matching[0];
	}

	renderMenuItem(doc, isActive) {
		let className = joinClasses(s.groupItem, isActive && s.active);
		let content = doc.label;
		if (!isActive) {
			content = (
				<Link class={s.groupItemLink} pageId="docs" queryString={{ group: doc.group.slug, doc: doc.slug }}>
					{content}
				</Link>
			);
		}
		return <li class={className}>{content}</li>;
	}

	renderMenuContent() {
		if (this.state.loading) {
			return [];
		}
		let activeDoc = this.state.activeDoc;
		let structure = this.state.docStructure;
		if (!structure || !Array.isArray(structure)) {
			return (
				<div class={s.error}>
					Error loading the list of available docs. Please contact a dev.
				</div>
			);
		}
		return structure.map(group => (
			<div class={s.group}>
				<h2 class={s.groupTitle}>{group.title}</h2>
				<ul class={s.groupItems}>
					{group.items && group.items.map(item => this.renderMenuItem(item, item === activeDoc))}
				</ul>
			</div>
		));
	}

	render(props, state) {
		let activeDoc = state.activeDoc;
		let doc;
		if (activeDoc) {
			if (activeDoc.comp === 'Arch') {
				doc = <Arch activeDoc={activeDoc} />;
			} else {
				doc = <MarkdownDoc activeDoc={activeDoc} />;
			}
		}

		return (
			<div class={joinClasses(s.container, state.loading && s.loading)}>
				<div class={s.nav}>
					<h1 class={s.title}>Documentation</h1>
					{this.renderMenuContent()}
				</div>
				<div class={s.content} ref={e => this.$content = e}>
					{doc}
				</div>
			</div>
		);
	}

}
