import { h, Component } from 'preact';
import { createAppData, getProviderTitles, createMovieMasterId, updateProviderTitle } from 'services/dataApi';
import { joinClasses } from 'utils/utils';
import s from 'components/pages/apps/titleCreator.sss';
import Store from 'store/store';
import * as actions from 'store/actions';
import slug from 'slugify';

function addToQuery(q, path, o) {
	if (typeof o !== 'object') {
		return q[path] = o;
	}
	let ks = Object.keys(o);
	ks.forEach(k => addToQuery(q, path + '[' + k + ']', o[k]));
	return q;
}

// @pure
export default class TitleCreator extends Component {

	constructor() {
		super();
		this.state.title = '';
		this.save = this.save.bind(this);
	}

	save(e) {
		e.preventDefault();
		let title = this.state.title;
		if (!title) {
			return;
		}
		let titleLower = title.toLowerCase();
		let existing = this.props.titles.find(e => e.en?.toLowerCase() === titleLower);
		if (existing) {
			return this.props.onSave(existing);
		}
		let slugMessage = 'The title you entered is all lowercase.\nYou should input be the title, not the slug.\nDo you want to continue anyway?';
		// eslint-disable-next-line no-alert
		if (titleLower === title && !confirm(slugMessage)) {
			return;
		}
		let whitespaceMessage = 'The title you entered contains spaces at the beginning or end. This is usually not desired.\nDo you want to continue anyway?';
		// eslint-disable-next-line no-alert
		if (title.trim() !== title && !confirm(whitespaceMessage)) {
			return;
		}
		Store.emit(actions.SHOW_MODAL, <WebediaModal title={title} />, 'Set webedia ids', 'webedia', async (code, result) => {
			if (!code || code === 'CANCEL') return;
			let appData = {
				type: 'app_data_titles',
				attributes: {
					meta: {
						title: {
							en: this.state.title,
							slug: slug(this.state.title, { lower: true, remove: /[^\w\s-]/g })
						}
					}
				},
				rel: { base: this.props.base }
			};
			if (code === 'DISABLE') {
				appData.attributes.dev = { noWebedia: true };
			} else if (result && result.length) {
				appData.attributes.meta.title.webediaIds = result;
			}
			let title = await createAppData('titles', appData);
			Store.emit(actions.NEW_TITLE, title);
			this.props.onSave(title);
			if (result && result.length) {
				let filter = { pidmod: [], movie_masters: { is_null: 'true' } };
				result.forEach(wid => filter.pidmod.push('wwm:' + wid, 'webus:' + wid, 'webuk:' + wid));
				let q = addToQuery({}, 'filter', filter);
				let movies = await getProviderTitles(q);
				if (!movies || !movies.length) return;
				let movieMaster = await createMovieMasterId(title.attributes.meta.title.slug);
				await Promise.all(
					movies.map(movie => updateProviderTitle(movie.id, { movie_master_id: movieMaster.id }))
				);
			}
			Store.emit(actions.UPDATE_PROVIDER_TITLES, title.id);
		});
	}

	render({ onCancel }, { title }) {
		return (
			<form class={s.base} onSubmit={this.save}>
				<button class={s.cancel} type="button" onClick={onCancel}>Select Existing Title</button>
				<span class={s.or}>or</span>
				<input class={s.newTitle} onInput={this.linkState('title')} placeholder="English Title" />
				<button class={s.save} type="submit" disabled={!title}>Save</button>
			</form>
		);
	}

}

class WebediaModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: !!props.title,
			suggestions: [],
			userInput: ''
		};
		if (props.title) {
			let url = 'http://powster.json.cinema-source.com/?ver=3.1&query=movie&appId=UKPOW&search=' + props.title;
			fetch(url).then(res => res.json()).then(d => {
				let suggestions = [];
				if (d && d.movies && d.movies.movie) {
					suggestions = d.movies.movie.map(m => ({ title: m.title, id: m.movie_id }));
				}
				this.setState({ loading: false, suggestions: suggestions });
			}).catch(() => this.setState({ loading: false }));
		}
	}

	getParsedValues(userInput) {
		let allValues = userInput.split(/,|\n/).map(s => s.trim()).filter(e => e);
		return [...new Set(allValues)];
	}

	addSuggestion(id) {
		let current = this.state.userInput;
		if (current && !current.endsWith('\n')) {
			current += '\n';
		}
		current += id;
		this.setState({ userInput: current });
	}

	close(result, data) {
		Store.emit(actions.HIDE_MODAL, result, data);
	}

	render(props, { loading, suggestions, userInput }) {
		let parsed = this.getParsedValues(userInput);
		let suggested = suggestions.filter(s => parsed.indexOf(s.id) === -1);
		let hasSuggestions = suggested && suggested.length;
		return (
			<div class={s.webediaModal}>
				<textarea class={s.values} onInput={this.linkState('userInput')} value={userInput} rows="4" />
				<div class={joinClasses(s.loading, !loading && s.hidden)} />
				<div class={joinClasses(s.suggestions, !hasSuggestions && s.hidden)}>
					<div class={s.cta}>{suggested.length + ' suggestion' + (suggested.length > 1 ? 's' : '')}</div>
					<div class={s.list}>
						{suggested.map(sugg => <div class={s.suggestion} title={sugg.id} onClick={() => this.addSuggestion(sugg.id)}>{sugg.title}</div>)}
					</div>
					<button type="button" class={s.addAll} onClick={() => this.addSuggestion(suggested.map(s => s.id).join('\n'))}>Add all</button>
				</div>
				<div class={s.cta}>You can find ids with the <a href="http://powster.westworldmedia.com/powsterhome.asp?function=login" target="_blank">Webedia Search Page</a> (Powster ; StePowster!)</div>
				<div class={s.parsed}>
					<div class={s.indicator}>You entered : </div>
					<div class={joinClasses(s.value, !parsed.length && s.none)}>{parsed.join(', ')}</div>
				</div>
				<div class={s.resultButtons}>
					<button type="button" class={s.cancel} onClick={this.close}>Cancel title creation</button>
					<button type="button" class={s.noWebedia} onClick={() => this.close('DISABLE')}>Disable webedia ids for this title</button>
					<button type="button" class={s.confirm} onClick={() => this.close('OK', parsed)}>{parsed.length ? 'Confirm' : 'Add them later'}</button>
				</div>
			</div>
		);
	}
}
