import { h } from 'preact';

export default (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="img" {...props}>
			<path d="M8 2c-1.63 0-1.834.007-2.474.036-.638.029-1.075.131-1.456.279a2.932 2.932 0 00-1.063.692 2.932 2.932 0 00-.692 1.063c-.148.382-.25.818-.279 1.456C2.007 6.166 2 6.37 2 8s.007 1.834.036 2.474c.029.639.131 1.075.279 1.456.153.395.358.729.692 1.063.334.334.668.539 1.063.692.382.148.818.25 1.456.279.64.029.844.036 2.474.036 1.63 0 1.834-.007 2.474-.036.638-.029 1.075-.131 1.456-.279a2.932 2.932 0 001.063-.692c.334-.334.539-.668.692-1.063.148-.382.25-.818.279-1.456C13.993 9.834 14 9.63 14 8c0-1.63-.007-1.834-.036-2.474-.029-.638-.131-1.075-.279-1.456a2.932 2.932 0 00-.692-1.063 2.932 2.932 0 00-1.063-.692c-.382-.148-.818-.25-1.456-.279C9.834 2.007 9.63 2 8 2m0 1.081c1.602 0 1.792.006 2.425.035.584.027.902.124 1.114.207.28.109.48.239.69.449.21.21.34.41.449.69.082.211.18.529.207 1.114.028.632.034.822.034 2.424 0 1.602-.006 1.792-.035 2.425-.027.585-.125.902-.207 1.114-.109.28-.239.48-.449.69-.21.21-.41.34-.69.449-.211.082-.529.18-1.114.207-.632.028-.822.034-2.424.034s-1.792-.006-2.425-.035c-.585-.027-.902-.125-1.114-.207a1.862 1.862 0 01-.69-.449c-.21-.21-.34-.41-.449-.69-.082-.211-.179-.528-.206-1.113-.029-.633-.035-.823-.035-2.425 0-1.602.006-1.792.035-2.425.027-.585.124-.902.207-1.114.109-.28.239-.48.449-.69.21-.21.41-.34.69-.449.211-.082.529-.18 1.114-.207.632-.028.822-.034 2.424-.034" />
			<path d="M7.986 9.982a1.995 1.995 0 110-3.99 1.995 1.995 0 010 3.99m0-5.07a3.074 3.074 0 100 6.147 3.074 3.074 0 000-6.147M11.182 5.509a.718.718 0 110-1.436.718.718 0 010 1.436z" />
		</svg>
	);
};
