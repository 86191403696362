import { h } from 'preact';

const Vimeo = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" {...props}>
			<path d="M21.99 7.164c-.09 2.026-1.449 4.799-4.078 8.32-2.72 3.677-5.02 5.516-6.903 5.516-1.165 0-2.151-1.122-2.958-3.366l-1.614-6.17C5.84 9.221 5.197 8.1 4.51 8.1c-.15 0-.672.327-1.569.981L2 7.818c.978-.896 1.949-1.8 2.913-2.71C6.228 3.922 7.214 3.3 7.872 3.237c1.553-.156 2.51.95 2.868 3.318.389 2.556.657 4.145.807 4.768.448 2.12.941 3.179 1.479 3.179.418 0 1.046-.686 1.882-2.057.837-1.371 1.285-2.415 1.345-3.132.12-1.184-.329-1.776-1.345-1.776-.478 0-.97.109-1.479.327.986-3.334 2.869-4.955 5.648-4.861 2.062.062 3.033 1.449 2.913 4.16Z" />
		</svg>
	);
};

export default Vimeo;