import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';

import { joinClasses } from 'utils/utils';
import { apiGet } from 'services/dataApi';

import s from 'components/pages/apps/modals/lookerSetup.sss';

const lookerTemplates = [
	{ name: 'Single Region', id: '2ce6469f-8d4d-4618-a03f-b37e9efa0648', dataSource: 'ds25' },
	{ name: 'Multi Region', id: '7a97aa1a-4797-4eaa-bffb-b1663269d796', dataSource: 'ds0', multiRegion: true },
	{ name: 'Single Region - Ticketing & Home Ent', id: 'dac11cce-dc6d-4468-b568-76eb31a67831', dataSource: 'ds0' },
];

export default class LookerSetup extends Component {

	constructor(props) {
		super(props);

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onTemplateChange = this.onTemplateChange.bind(this);
		this.setUrl = this.setUrl.bind(this);
		this.submit = this.submit.bind(this);

		const ga4 = this.props.appData?.dev?.ga4 || {};

		this.state = {
			loadingAccountSummaries: true,
			reportTitle: this.getReportTitle(props),
			email: ga4.email,
			account: ga4.account,
			property: ga4.property,
			template: lookerTemplates[ga4.multiRegion ? 1 : 0],
			url: '',
		};
	}

	componentDidMount() {
		this.loadAccountsSummary();
		this.checkRegionGroupDashboard();
	}

	async loadAccountsSummary() {
		const state = this.state;
		if (!state.email || !state.account || !state.property) {
			return this.setState({ error: 'Missing data, please make sure to run the GA4 Setup' });
		}
		this.setState({ loadingAccountSummaries: true });
		try {
			const accountSummaries = await apiGet('/google/analytics-account-summaries', { email: this.state.email });
			const desiredAccountName = state.account.trim().toLowerCase();
			const activeAccount = accountSummaries.find(e => e.name.trim().toLowerCase() === desiredAccountName);
			if (!activeAccount) {
				throw new Error(`Could not find account ${state.account}`, { cause: 'custom' });
			}
			const desiredPropertyName = state.property.trim().toLowerCase();
			const activeProperty = activeAccount.properties.find(e => e.name.trim().toLowerCase() === desiredPropertyName);
			if (!activeProperty) {
				throw new Error(`Could not find property ${state.property} in account ${state.account}`, { cause: 'custom' });
			}
			this.setState({
				loadingAccountSummaries: false,
				accountId: activeAccount.id.split('/').pop(),
				propertyId: activeProperty.id.split('/').pop(),
			});
			this.updateCreationURL();
		} catch (e) {
			console.error(e);
			this.setState({ loadingAccountSummaries: false, error: e?.cause === 'custom' ? e.message : 'Error loading GA data, please try again later' });
		}
	}

	async checkRegionGroupDashboard() {
		if (!this.props.appData?.dev?.ga4?.multiRegion) {
			return;
		}
		const appsList = Store.get().list.apps;
		const appsToGet = this.getRegionGroup().map(r => appsList[r.app]).filter(e => e);
		const allNeededLevels = appsToGet.flatMap(app => [{ type: 'app', id: app.id }, ...Object.entries(app.rel).map(([type, id]) => ({ type, id }))]);
		await Store.emit('REQUEST_MISSING', allNeededLevels);

		const loadedApps = Store.get().apps;
		const dashboards = appsToGet.map(app => {
			const appData = this.props.getMerged(loadedApps[app.id])?.merged;
			return appData?.pages?.['bolt-toolkit']?.data?.GADashboard;
		}).filter(e => e);

		if (dashboards.length) {
			this.setState({ url: dashboards[0] });
		}
	}

	getStreams() {
		return this.state.template?.multiRegion ? this.getRegionGroup().map(r => r.slug) : [this.state.property];
	}

	getRegion() {
		return this.props.appData?.meta?.region?.slug || 'intl';
	}

	getRegionGroup() {
		const regionSlug = this.getRegion();
		const regionBase = regionSlug.split('_')[0];
		return this.props.titleRegions?.filter(({ slug }) => slug === regionBase || slug.startsWith(regionBase + '_')) || [];
	}

	onKeyDown(e) {
		if (e.key === 'Escape') {
			e.preventDefault();
			this.cancel();
			return;
		}
	}

	onTemplateChange(e) {
		this.setState({ template: lookerTemplates.find(t => t.id === e.target.value) || lookerTemplates[0] });
		this.updateCreationURL();
	}

	cancel() {
		Store.emit(actions.HIDE_MODAL, 'CANCEL');
	}

	getReportTitle(props = this.props) {
		let meta = props.appData?.meta;
		const region = this.getRegion().split('_')[0].toUpperCase();
		return `${meta?.studio?.name || 'Studio'}: ${meta?.title?.en || 'Movie'}: ${region} - Analytics Dashboard`;
	}

	updateCreationURL(state = this.state) {
		if (!state.template || !state.accountId || !state.propertyId) {
			return;
		}

		const template = state.template;
		const ds = template.dataSource;
		// const url = new URL(`https://lookerstudio.google.com/u/${state.email}/reporting/create`);
		const url = new URL('https://lookerstudio.google.com/reporting/create');

		url.searchParams.append('c.reportId', template.id);
		url.searchParams.append('r.reportName', state.reportTitle);
		url.searchParams.append('r.mode', 'edit');

		url.searchParams.append(`ds.${ds}.connector`, 'googleAnalytics');
		url.searchParams.append(`ds.${ds}.accountId`, state.accountId);
		url.searchParams.append(`ds.${ds}.propertyId`, state.propertyId);
		url.searchParams.append(`ds.${ds}.datasourceName`, `${state.account} > ${state.property}`);

		const withAccountChooser = `https://accounts.google.com/AccountChooser?continue=${encodeURIComponent(url)}`;

		this.setState({ createUrl: withAccountChooser });
	}

	setUrl(e) {
		this.setState({ url: e.target.value });
	}

	submit(e) {
		e.preventDefault();
		// Remove specific account and edit mode
		const url = this.state.url.trim().replace(/\/u\/\d+\//, '/').replace(/\/edit$/, '');
		if (!url) {
			return;
		}
		Store.emit(actions.HIDE_MODAL, { ok: true, url });
	}

	renderError() {
		return (
			<div class={s.container} onKeyDown={this.onKeyDown}>
				<div class={s.error}>{this.state.error}</div>
				<div class={s.buttons}>
					<button type="button" class={s.cancel} onClick={this.cancel}>Close</button>
				</div>
			</div>
		);
	}

	render() {
		const state = this.state;
		if (state.error) {
			return this.renderError();
		}
		let content = (
			<div class={s.loadingContent} key="loading">Loading Google Analytics data...</div>
		);
		if (!state.loadingAccountSummaries) {
			content = (
				<div class={s.setup} key="setup">
					<p>Setup steps:</p>
					<ol>
						<li>Click on <a href={state.createUrl} target="_blank">this link</a></li>
						<li>Make sure to choose the account <em>{state.email}</em></li>
						<li>Complete the dashboard setup</li>
						<li>
							<label>
								Paste the final dashboard link below:
								<div class={joinClasses(s.inputWrapper, s.url)}>
									<input type="url" value={state.url} onInput={this.setUrl} placeholder="https://lookerstudio.google.com/..." required />
									<a class={s.preview} href={state.url} target="_blank">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="m243-240-51-51 405-405H240v-72h480v480h-72v-357L243-240Z" /></svg>
									</a>
								</div>
							</label>
						</li>
					</ol>
				</div>
			);
		}
		const region = this.getRegion();
		return (
			<form class={s.container} onSubmit={this.submit} onKeyDown={this.onKeyDown}>
				<label class={s.line} key="template">
					<span class={s.description}>Dashboard Template</span>
					<select onChange={this.onTemplateChange} value={state.template?.id}>
						{lookerTemplates.map(template => <option value={template.id} key={template.id}>{template.name}</option>)}
					</select>
				</label>
				<div class={s.recap} key="recap">
					<div class={s.item}>
						<div class={s.label}>Email</div>
						<div class={s.value}>{state.email}</div>
					</div>
					<div class={s.item}>
						<div class={s.label}>Account</div>
						<div class={s.value}>{state.account}</div>
					</div>
					<div class={s.item}>
						<div class={s.label}>Property</div>
						<div class={s.value}>{state.property}</div>
					</div>
					<div class={s.item}>
						<div class={s.label}>Stream(s)</div>
						<div class={s.value}>{this.getStreams().flatMap((s, i) => [!!i && ', ', s === region ? <strong>{s}</strong> : s])}</div>
					</div>
				</div>
				<hr />
				{content}
				<div class={s.buttons}>
					<button type="button" class={s.cancel} onClick={this.cancel}>Cancel</button>
					<button type="submit" class={joinClasses(s.confirm)} disabled={!this.state.url}>Confirm</button>
				</div>
			</form>
		);
	}

}