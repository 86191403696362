import { h } from 'preact';
import * as BaseComp from 'components/pages/page.js';
import { setPageTitle } from 'utils/setPageTitle';

export default class Page extends BaseComp.default {

	componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}

		let pageTitle = this.pageTitle || this.props.activePage.title;
		if (!pageTitle) {
			let id = this.props.id || '';
			pageTitle = id[0].toUpperCase() + id.slice(1);
		}
		setPageTitle(pageTitle);
	}

}
