export default function getGAData(a, data, gaCategory) {
	let region = __INITIAL_STATE__.appData.meta.region.slug;
	let tData = {
		hitType: 'event',
		eventCategory: data.gaCategory || gaCategory,
		eventAction: data.gaAction || ('website/core/' + region + '/' + a)
	};
	if (data.l) tData.eventLabel = data.l.toString();
	if (data.v) tData.eventValue = parseInt(data.v);
	if (data.ni) tData.nonInteraction = 1;
	return tData;
}
