import { h, Component } from 'preact';
import pure from 'utils/pure';
import { accessNested, joinClasses } from 'utils/utils';
import { Link } from 'components/core/link/link';
import { updateBuildAppData, stopWatch } from 'services/buildSockets';
import s from 'components/shared/infoSidebar/myApps/myAppsItem.sss';
import ErrorModal from 'components/shared/modal/errorModal';
import Store from 'store/store';
import * as actions from 'store/actions';
import { uploadApp, invalidateApp, prepareSony, prepareNetflix } from 'services/buildApi';
import { runScript } from 'services/scriptApi';
import BuildProgressModal from 'components/shared/infoSidebar/myApps/buildProgressModal';

const BUILDTYPES = {
	comingSoon: 'comingSoon',
	local: 'local',
	demo: 'demo',
	prod: 'prod',
	stage: 'stage'
};

const STATUS = {
	hidden: 'hidden',
	scheduled: 'scheduled',
	processing: 'processing',
	error: 'error',
	complete: 'complete'
};

// const SONY_REGIONS = ['us', 'gb', 'be', 'be-nl'];
const SONY_STUDIOS = ['sonypictures', 'sonypicturesclassics', 'funimation'];

/**
 * An app in the My apps list
 * @prop {Object} app - the app that it needs to display data about
 * @prop {String} buildType - whats the buildType
 * @prop {Function} toggleIssues - toggle to issues view
 * @prop {Object} status - whats the status of the app
**/
export default @pure class MyAppsItem extends Component {

	/**
	 * LifeCycle
	 */

	render(props) {
		let meta = props.app.meta;

		const progress = this.getProgress(props);

		let appQS = {
			app: meta.id,
			title: null,
			studio: null,
			studio_region: null,
			region: null,
			base: null,
			branch: null
		};
		return (
			<div class={s.container}>
				<div class={s.textContainer}>
					<div class={s.textLeft}>
						<div class={s.region}>{meta.region.slug}</div>
						<button class={s.remove} onClick={this.remove}>remove</button>
					</div>
					<Link pageId="editApp" queryString={appQS} class={s.titles}>
						<p class={s.subTitle}>{meta.studio.name}</p>
						<p class={s.title}>{meta.title.en}</p>
					</Link>
				</div>
				<div class={s.progress}>
					{progress && progress.map(item => {
						if (!item || item.status === STATUS.hidden) {
							return;
						}

						let time;
						if (item.time) {
							let date = new Date(item.time);
							let hours = ('0' + date.getHours()).slice(-2);
							let mins = ('0' + date.getMinutes()).slice(-2);
							time = hours + ':' + mins;
						}
						let progressBar;
						if (item.progress !== undefined) {
							progressBar = (
								<div class={s.progressBarContainer} key="progressBar">
									<div class={s.progressBar} style={{ transform: 'scaleX(' + item.progress + ')' }} />
								</div>
							);
						}
						return (
							<div class={joinClasses(s.progressItem, s[item.status])}>
								<div class={s.progressItemTime}>{time}</div>
								<div class={s.progressItemContent}>
									{item.content}
									{progressBar}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	/**
	 * DATA Handlers
	 */

	getUrl = (type, buildType) => {
		if (!type) {
			type = this.props.buildType;
			buildType = 'local';
		}

		let app = this.props.app;
		let meta = app.meta;
		let baseUrl = (app.dev?.customBuildFolder || `${meta.studio.slug}/${meta.title.slug}/${meta.region.slug}`) + '/';
		let url = baseUrl;

		// REVIEW: MOVE THIS TO SOMEWHERE ELSE
		switch (type) {
			case BUILDTYPES.comingSoon:
				url = 'http://prod.showtimes-static.s3-website-eu-west-1.amazonaws.com/' + baseUrl;
				break;
			case BUILDTYPES.local:
				let buildDest = '';
				if (buildType) {
					buildDest = buildType === 'comingSoon' ? 'prod' : buildType;
					buildDest += '/';
				}
				url = '/preview/' + baseUrl + buildDest;
				break;
			case BUILDTYPES.demo:
				url = 'http://demo.pow.io/' + baseUrl;
				break;
			case BUILDTYPES.prod:
				url = 'http://prod.showtimes-static.s3-website-eu-west-1.amazonaws.com/' + baseUrl;
				break;
			case 'stage':
				url = 'http://stage.showtimes-static.s3-website-eu-west-1.amazonaws.com/' + baseUrl;
				break;
		}

		if (app.redirection || app.geofencing) {
			url += '';
		}

		return url;
	};

	getProgress = (props) => {
		let buildProgress = this.getBuildProgress(props.status, props.buildType);
		let linkProgress = this.getLinkProgress(buildProgress, props.buildType);
		let extraButtons = this.getExtraButtons(props.buildType, buildProgress, linkProgress);
		let middle = [];

		let currentProgress = buildProgress;
		switch (props.buildType) {
			case BUILDTYPES.prod:
				// Updating Thundr
				if (accessNested(props, 'app.meta.id') === 2480) {
					return [
						buildProgress,
						buildProgress.status === STATUS.complete && {
							content: <p>Run <code>thundr updateThundr</code> to update</p>
						}
					].filter(e => e);
				}

				let deployStage = this.getDeploy(currentProgress, BUILDTYPES.stage);
				middle = middle.concat(deployStage);
				currentProgress = deployStage;
				// falls through
			case BUILDTYPES.comingSoon:
				let deployProd = this.getDeploy(currentProgress, props.buildType);
				middle = middle.concat(
					deployProd,
					this.getInvalidateProd(deployProd)
				);
				break;
			case BUILDTYPES.demo:
				middle = middle.concat(this.getDeploy(currentProgress, BUILDTYPES.demo));
				break;
			case BUILDTYPES.local:
				middle = middle.concat(this.getRebuild(currentProgress));
				break;
		}

		return [
			buildProgress,
			...middle,
			linkProgress,
			...extraButtons
		];
	};

	getBuildProgress = (status, type) => {
		if (status && status.errors) {
			return {
				content: <p>A problem occurred <span class={s.cbBtn} onClick={this.props.toggleIssues}>view</span></p>,
				status: STATUS.error
			};
		}

		if (status && status.validating) {
			return {
				content: 'Validating app data...',
				status: STATUS.scheduled
			};
		}

		// Not building yet
		if (!status || !status.build) {
			return {
				content: 'Waiting to be built...',
				status: STATUS.scheduled
			};
		}

		if (status.build.errorAt) {
			return {
				content: <p>A building error occurred <span class={s.cbBtn} onClick={this.displayError}>view</span></p>,
				status: STATUS.error,
				time: status.build.errorAt
			};
		}

		// Finished building
		if (status.build.finishedAt) {
			return {
				content: type === BUILDTYPES.local ? 'Watching App' : 'Finished Building',
				status: STATUS.complete,
				time: status.build.finishedAt
			};
		}

		// Started building
		if (status.build.startedAt) {
			let content = 'Building App...';
			let progress = undefined;
			if (status.build.bases) {
				let progresses = Object.values(status.build.bases).map(e => e.progress);
				if (progresses.length) {
					progress = progresses.reduce((s, v) => s + v) / progresses.length;
					content = (
						<div class={s.showProgress} onClick={this.showProgress}>
							{content}
						</div>
					);
				}
			}
			return {
				content: content,
				status: STATUS.processing,
				time: status.build.startedAt,
				progress
			};
		}

		console.log('Unexpected getBuildProgress data', status, type);
		return {
			content: 'Unknown state. Tell a dev',
			status: STATUS.error
		};
	};

	getLinkProgress = (prevStep) => {
		if (!prevStep || prevStep.status !== STATUS.complete) {
			return {
				status: STATUS.hidden
			};
		}
		let rel;
		let isThundr = accessNested(this.props, 'app.meta.id') === 2480;
		if (!isThundr) {
			// Let chrome create a new process for the opened tab (prevents crashing sometimes)
			// e.g. if the opened site gets paused in the inspector
			rel = 'noreferrer';
		}
		return {
			content: <div>Open <Link href={this.getUrl(BUILDTYPES.local, this.props.buildType)} target="_blank" rel={rel}>local</Link></div>
		};
	};

	getRebuild = (prevStep) => {
		if (!prevStep || prevStep.status !== STATUS.complete) {
			return { status: STATUS.hidden };
		}
		let extra = '';
		let title = '';
		if (this.props.dataChanged) {
			title = 'Data for this app has been changed by someone else since the last build. Click here to update.';
			extra = '⚠️ ';
		}
		let obj = {
			content: <button onClick={this.rebuild} title={title}>{extra}Update Data</button>
		};

		if (this.state.updateAppDataTime) {
			obj.time = this.state.updateAppDataTime;
		}
		return obj;
	};

	getExtraButtons = (type, buildProgress) => {
		let extra = [];
		// let built = buildProgress && buildProgress.status === STATUS.complete;
		// let studio = accessNested(this.props, 'app.meta.studio.slug');
		// // let region = accessNested(this.props, 'app.meta.region.slug');
		// if (built && studio === 'sonypictures' && type === BUILDTYPES.demo) {
		// 	extra.push({
		// 		content: <button class={s.prepare} onClick={e => this.prepareSony(type, e)}>Prepare for Sony deploy</button>
		// 	});
		// }
		return extra;
	};

	getDeploy = (prevStep, type) => {
		if (prevStep.status !== STATUS.complete) {
			return { status: STATUS.hidden };
		}

		let studio = accessNested(this.props, 'app.meta.studio.slug');
		let hostedByPowster = accessNested(this.props, 'app.dev.hostedByPowster');
		let isSonyHosted = !hostedByPowster && SONY_STUDIOS.includes(studio);
		if (isSonyHosted && type !== 'demo') {
			return {
				content: <button onClick={e => this.prepareSony(type, e)}>Prepare for Sony Deploy</button>
			};
		}

		let isNetflixHosted = !hostedByPowster && studio === 'netflix';
		if (isNetflixHosted && type !== 'demo') {
			return {
				content: <button onClick={e => this.prepareNetflix(type, e)}>Netflix Push to Github & Deploy Prod</button>
			};
		}

		let result;
		let deployStatus = this.props.status[`deploy${type}`];
		// Not deploying yet
		if (!deployStatus) {
			result = {
				content: <button onClick={this.deploy.bind(this, type)}>Deploy to {type}</button>
			};
		} else if (deployStatus.status === STATUS.processing) {
			// Started deploying
			result = {
				content: `Deploying to ${type}`,
				status: STATUS.processing,
				time: deployStatus.startedAt
			};
		} else if (deployStatus.status === STATUS.error) {
			// Error
			result = {
				content: <p>An error occurred <span class={s.cbBtn} onClick={this.displayError}>view</span></p>,
				status: STATUS.error,
				time: deployStatus.errorTime
			};
		} else if (deployStatus.status === STATUS.complete) {
			// Deployed
			result = {
				content: <div>Deployed to {type} (<Link href={this.getUrl(type)} target="_blank" rel="noreferrer" onClick={e => this.openLink(type, e)}>open</Link>)</div>,
				status: STATUS.complete,
				time: deployStatus.finishedAt
			};
		}

		if (result && isSonyHosted && type === 'demo') {
			result.content = [].concat(result.content, <button onClick={e => this.prepareSony(type, e)}>Prepare for Sony Deploy</button>);
		}

		if (result && isNetflixHosted && type === 'demo') {
			result.content = [].concat(result.content, <button onClick={e => this.prepareNetflix(type, e)}>Netflix Push to Github & Deploy Test</button>);
		}

		return result;
	};

	getInvalidateProd = (prevStep) => {
		if (prevStep.status === STATUS.complete) {
			let invalidateStatus = this.props.status.invalidateProd;

			// Not deploying yet
			if (!invalidateStatus) {
				return {
					content: <button target="_blank" onClick={this.invalidateProd}>Invalidate Prod</button>
				};
			}

			// Started deploying
			if (invalidateStatus.status === STATUS.processing) {
				return {
					content: 'Starting invalidation',
					status: STATUS.processing,
					time: invalidateStatus.startedAt
				};
			}

			// Deployed
			if (invalidateStatus.status === STATUS.complete) {
				// Lets auto invalidate
				return {
					content: 'Invalidation requested',
					status: STATUS.complete,
					time: invalidateStatus.finishedAt
				};
			}
		}

		return {
			status: STATUS.hidden
		};
	};

	checkDownloadableAssets(target) {
		if (target !== 'demo' && target !== 'stage') {
			return;
		}
		let app = this.props.app;

		let pages = app.pages || {};
		let downloadableAssets = [].concat(
			// Gallery pages
			...Object.keys(pages).map(id => {
				let page = pages[id];
				if (!page || (page.base && page.base !== 'showtimes')) return;
				let comp = page.compName || id;
				if (comp !== 'gallery' || !page.data || !page.data.downloadButton) return;
				let assets = Object.values(page.data.list || {});
				return assets.map(e => e && (e.srcFull || e.src)).filter(e => e);
			}).filter(e => e),
			// Media room / Press room
			...Object.keys(pages).map(id => {
				let page = pages[id];
				if (!page || (page.base && page.base !== 'showtimes')) return;
				let comp = page.compName || id;
				if (comp !== 'mediaRoom' || !page.data) return;
				let assetsByCat = Object.values(page.data.assets || {}).map(e => Object.values((e && e.items) || {}));
				return [].concat(...assetsByCat).map(e => e && e.link).filter(e => e);
			}).filter(e => e),
		);
		if (!downloadableAssets.length) {
			return;
		}
		let studio = accessNested(app, 'meta.studio.slug');
		let title = accessNested(app, 'meta.title.slug');
		let region = accessNested(app, 'meta.region.slug');
		let s3Path = 's3://' + [
			'st-static-' + (target === 'demo' ? 'stage' : 'prod'),
			studio, title, ''
		].join('/');
		let prefixes = {
			MEDIA_ROOT: '',
			IMAGE_ROOT: 'images/',
			VIDEO_ROOT: 'video/',
			IMAGE_REGION_ROOT: 'images/regions/' + region + '/',
		};
		let prefixesRegex = new RegExp('^%(' + Object.keys(prefixes).join('|') + ')%/(.*)$');
		downloadableAssets = downloadableAssets.map(elem => {
			if (!elem) return;
			let parts = prefixesRegex.exec(elem);
			if (!parts) return;
			return (prefixes[parts[1]] || '') + parts[2];
		}).filter(e => e);
		let args = [
			's3', 'cp', s3Path, s3Path,
			'--exclude', '*'
		].concat(...downloadableAssets.map(asset => ['--include', asset]));
		args.push(
			'--content-disposition', 'attachment',
			'--metadata-directive', 'REPLACE',
			'--recursive'
		);
		return runScript({
			command: 'aws',
			args: args
		}).then(() => console.log('Updated metadata (downloadable) for files', downloadableAssets));
	}

	/**
	 * Interaction Handlers
	 */

	displayError = () => {
		const build = this.props.status.build;
		let buildErr = build.error;
		if (buildErr?.stack) {
			buildErr = buildErr.stack;
		}
		if (buildErr) {
			Store.emit(actions.SHOW_MODAL, <ErrorModal error={buildErr} extras={build.errorExtras} app={this.props.app} showHelpRequest />, 'Build Error', 'BUILD_ERROR');
			return;
		}

		['demo', 'stage', 'prod'].find(type => {
			let deployErr = this.props.status[`deploy${type}`]?.error;
			if (deployErr) {
				Store.emit(actions.SHOW_MODAL, <ErrorModal error={deployErr} />, 'Deploy Error', 'DEPLOY_ERROR');
				return true;
			}
		});
	};

	invalidateProd = () => {
		let app = this.props.app;

		let newState = {};
		newState.status = STATUS.processing;
		newState.startedAt = Date.now();
		Store.emit(actions.UPDATE_MY_APP_STATUS, { invalidateProd: newState }, app.meta.id);


		invalidateApp(app).then(() => {
			newState.status = STATUS.complete;
			newState.finishedAt = Date.now();
			Store.emit(actions.UPDATE_MY_APP_STATUS, { invalidateProd: newState }, app.meta.id);
		});
	};

	showProgress = () => {
		let app = this.props.app;
		Store.emit(
			actions.SHOW_MODAL,
			props => <BuildProgressModal id={app.meta.id} {...props} />,
			'',
			'progress',
			undefined,
			'myApps'
		);
	};

	openLink = (target) => {
		let newState = {};
		let newStatus = {};
		newState.status = STATUS.complete;
		newState.startedAt = Date.now();
		newStatus['open' + target] = newState;
		Store.emit(actions.UPDATE_MY_APP_STATUS, newStatus, this.props.app.meta.id);
	};

	prepareSony = (target) => {
		prepareSony(this.props.app, target);
	};

	prepareNetflix = (target) => {
		prepareNetflix(this.props.app, target);
	};

	deploy = (target, e) => {
		// let app = this.props.app;
		let meta = this.props.app.meta;
		let studio = meta.studio.slug;
		let region = meta.region.slug;


		let newState = {};
		let newStatus = {};
		newState.status = STATUS.processing;
		newState.startedAt = Date.now();
		newStatus['deploy' + target] = newState;

		let invisible = e && (e.ctrlKey && e.altKey);

		if (!invisible && target === 'prod' && !this.props.status['openstage']) {
			// If stage link hasn't been opened and we're trying to push
			// to production, show this window first
			// eslint-disable-next-line no-alert
			if (confirm('Confirm staging link is working before pushing to Production') === true) {
				// automatically open the link in a new tab if they confirm
				window.open(this.getUrl('stage'), '_blank');
				// add in the openstage status
				this.openLink('stage');
			}
			return;
		}

		let hostedByPowster = accessNested(this.props, 'app.dev.hostedByPowster');
		let isSonyHosted = !hostedByPowster && SONY_STUDIOS.includes(studio);
		let isNetflixHosted = !hostedByPowster && studio === 'netflix';


		if (isSonyHosted) {
			// eslint-disable-next-line no-alert
			if (confirm('WARNING: This platform should be be hosted on Sony\'s servers, are you sure you want to deploy?') !== true) {
				return;
			}
		}

		if (isNetflixHosted) {
			// eslint-disable-next-line no-alert
			if (confirm('WARNING: This platform should be be hosted on Netflix\'s servers, are you sure you want to deploy?') !== true) {
				return;
			}
		}


		Store.emit(actions.UPDATE_MY_APP_STATUS, newStatus, this.props.app.meta.id);

		return uploadApp(this.props.app, target, invisible).then(data => {
			return Promise.resolve(this.checkDownloadableAssets(target)).then(() => data);
		}).then(data => {
			if (data && data.error) {
				console.log('Error deploying', data);
				newState.status = STATUS.error;
				newState.error = data.message;
				newState.errorTime = Date.now();
			} else {
				newState.status = STATUS.complete;
				newState.finishedAt = Date.now();
			}

			newStatus['deploy' + target] = newState;
			Store.emit(actions.UPDATE_MY_APP_STATUS, newStatus, this.props.app.meta.id);
			if (newState.status === STATUS.complete && target === 'prod') {
				// Automatically starts the invalidation
				this.invalidateProd();
			}
		});
	};


	rebuild = () => {
		const id = this.props.app?.meta?.id;
		if (!id) {
			return;
		}
		Store.emit(actions.UPDATE_MY_APP, {
			status: { build: { finishedAt: 0, startedAt: Date.now() } },
			dataChanged: false
		}, id);
		updateBuildAppData(id);
		this.setState({ updateAppDataTime: Date.now() });
	};

	remove = () => {
		const id = this.props.app?.meta?.id;
		stopWatch(id);
		Store.emit(actions.REMOVE_MY_APP, id);
	};

}
