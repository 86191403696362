import { h, Component } from 'preact';
import pure from 'utils/pure';
import s from 'components/shared/history/history.sss';
import formatDateTime from 'utils/formatDateTime';
import dateDistance from 'utils/dateDistance.mjs';
import { accessNested } from 'utils/utils';

export default @pure class History extends Component {

	render(props) {
		const deploys = Object.values(accessNested(props, 'merged.dev.history') || {});
		deploys.sort((a, b) => b.createdAt - a.createdAt);

		const deployHistory = deploys.map(deploy => {
			let at = new Date(+deploy.createdAt);
			let distance = dateDistance(at);
			let dateSimple = formatDateTime(at, 'd MMM yy - HH:mm');

			return (
				<div class={s.deploy}>
					<p class={s.deployUser}>{deploy.user}</p>
					<p class={s.deployTime} title={at.toISOString()}>{distance} ({dateSimple})</p>
				</div>
			);
		});

		return <div class={s.container}>{deployHistory}</div>;
	}

}
