let added = false;
const className = 'tabbing';

function onKeyDown(e) {
	if (e.key === 'Tab') {
		document.body.classList.add(className);
	}
}
function onMouseDown() {
	document.body.classList.remove(className);
}

const register = () => {
	if (added) {
		return;
	}
	added = true;
	window.addEventListener('keydown', onKeyDown, true);
	window.addEventListener('mousedown', onMouseDown, true);
	return { remove };
};
const remove = () => {
	if (!added) {
		return;
	}
	added = false;
	window.removeEventListener('keydown', onKeyDown, true);
	window.removeEventListener('mousedown', onMouseDown, true);
	document.body.classList.remove(className);
};

export default register;
