import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import s from 'components/shared/contextMenu/contextMenu.sss';
import { joinClasses } from 'utils/utils';
import pure from 'utils/pure';


export default @pure class ContextMenu extends Component {

	constructor(props) {
		super(props);
		this.hideSelf = this.hideSelf.bind(this);
		this.rightClickCheck = this.rightClickCheck.bind(this);
	}

	componentDidMount() {
		this.checkPos();
	}

	componentDidUpdate() {
		this.checkPos();
	}

	hideSelf() {
		Store.emit(actions.HIDE_CONTEXT_MENU);
	}

	rightClickCheck(event) {
		if (event.button === 2) {
			this.hideSelf();
		}
	}

	checkPos() {
		if (!this.$menu) return;

		const x = this.props.position?.x || 0;
		const shouldBeReversedX = x + this.$menu.offsetWidth > document.documentElement.clientWidth;
		if (!this.state.reverseX !== !shouldBeReversedX) {
			this.setState({ reverseX: shouldBeReversedX });
		}

		const y = this.props.position?.y || 0;
		const shouldBeReversedY = y + this.$menu.offsetHeight > document.documentElement.clientHeight;
		if (!this.state.reverseY !== !shouldBeReversedY) {
			this.setState({ reverseY: shouldBeReversedY });
		}
	}

	render({ shown, position, items }, { reverseX, reverseY }) {
		if (!position) {
			position = { x: 0, y: 0 };
		}

		const style = {
			left: position.x + 'px',
			top:  position.y + 'px'
		};

		const domItems = (items || []).map(i => {
			if (i.separator) {
				return <div class={s.sep} />;
			}

			let action = i.action;
			if (i.autoClose) {
				action = (e) => {
					Store.emit(actions.HIDE_CONTEXT_MENU);
					i.action(e);
				};
			}

			return <div class={joinClasses({ s, item: true, disabled: i.disabled, [i.class]: true })} onClick={action}>{i.name}</div>;
		});

		return (
			<div class={joinClasses(s.wrapper, shown && s.shown)}>
				<div class={s.modal} onMousedown={this.hideSelf} />
				<div class={joinClasses(s.menu, reverseX && s.reverseX, reverseY && s.reverseY)} style={style} onMousedown={this.rightClickCheck} ref={$ref => $ref && (this.$menu = $ref)}>
					{domItems}
				</div>
			</div>
		);
	}

}
