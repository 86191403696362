import { h, Component } from 'preact';
import cloneDeep from '@nodeutils/defaults-deep';

import Attribute from 'components/shared/editor/formEditor/attributes/attribute';
import FormRemoveButton from 'components/shared/editor/formEditor/formRemoveButton';

import { sortObjectByOrder, joinClasses } from 'utils/utils';
import { showHiddenChars } from 'components/shared/editor/formEditor/attributes/utils';

import s from 'components/shared/editor/formEditor/formEditor.sss';

function idfy(str) {
	return str.replace(/[^a-z0-9_.:-]/gi, '');
}

/**
* Component for editing an object.
* @param  {Object} value The value of the object.
* @param  {Boolean} base is this the first object attribute of the object
* @param  {String} attrKeyPath the path to the key in the json object
*/
export default class ObjectAttribute extends Component {

	constructor(props) {
		super(props);

		this.state.newKey = '';
		this.state.newKeyType = 'string';
		this.state.addNewEnabled = false;

		this.selectSelf = this.selectSelf.bind(this);
		this.moverMouseDown = this.moverMouseDown.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.keyDown = this.keyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.keyDown);
		document.addEventListener('mousemove', this.mouseMove);
		document.addEventListener('mouseup', this.mouseUp);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.keyDown);
		document.removeEventListener('mousemove', this.mouseMove);
		document.removeEventListener('mouseup', this.mouseUp);
	}

	moverMouseDown(event) {
		event.preventDefault();
		if (this.grabbed) return;
		this.grabbed = true;

		this.duplicate = this.base.cloneNode(true);
		this.duplicate.className += ' ' + s.placeholder;
		const thisRect = this.base.getBoundingClientRect();
		this.duplicate.style.width = thisRect.width + 'px';
		this.duplicate.style.left = thisRect.left + 'px';
		this.duplicate.style.top = event.clientY + 'px';
		document.body.appendChild(this.duplicate);

		this.indicator = document.createElement('div');
		this.indicator.className = s.indicator;
		this.indicator.style.left = thisRect.left + 'px';
		this.indicator.style.width = thisRect.width + 'px';
		this.indicator.style.top = thisRect.top + 'px';
		document.body.appendChild(this.indicator);

		// this.destVal = this.props.value[this.props.orderedBy];
		this.newOrder = null;
	}

	mouseMove(event) {
		if (!this.grabbed) return;

		const parent = this.base.parentElement;

		this.duplicate.style.top = event.clientY + 'px';

		let beforeKey = undefined;
		let afterKey = undefined;
		let rect;

		const found = [].find.call(parent.children, el => {
			rect = el.getBoundingClientRect();

			if (event.clientY < rect.top + rect.height * .5) {
				afterKey = el.getAttribute('data-attr');
				this.indicator.style.top = rect.top + 'px';
				return true;
			}

			beforeKey = el.getAttribute('data-attr');
		});

		if (!found) {
			this.indicator.style.top = rect.bottom + 'px';
			afterKey = undefined;
		}

		const scrollParent = parent.parentElement;
		const parentRect = scrollParent.getBoundingClientRect();

		if (event.clientY > parentRect.bottom - 50) {
			scrollParent.scrollTop += 10;
		}
		if (event.clientY < parentRect.top + 50) {
			scrollParent.scrollTop -= 10;
		}

		const attrName = this.props.attributeName;
		if (beforeKey === attrName || afterKey === attrName) {
			// this.destVal = this.props.value[this.props.orderedBy];
			this.newOrder = null;
		} else if (beforeKey || afterKey) {
			const pObj = this.props.parent;

			if (Array.isArray(pObj)) {
				let val = pObj.slice();
				let from = +attrName;
				let to = +(afterKey || val.length);
				if (to && from < to) to--;
				val.splice(to, 0, val.splice(from, 1)[0]);
				this.newOrder = val;
			} else {
				let newOrder = [].map.call(parent.children, el => el.getAttribute('data-attr')).filter(k => k && k !== attrName);
				let pos = newOrder.indexOf(beforeKey) + 1;
				newOrder.splice(pos, 0, attrName);
				this.newOrder = newOrder;
			}
		}
	}

	mouseUp() {
		if (!this.grabbed) return;
		this.grabbed = false;

		document.body.removeChild(this.duplicate);
		document.body.removeChild(this.indicator);

		this.duplicate = null;
		this.indicator = null;

		// this.props.updateJSON(this.props.attrKeyPath + this.props.orderedBy + '.', this.destVal);
		if (this.newOrder) {
			let edits = [];
			if (Array.isArray(this.props.parent)) {
				edits = [{
					path: this.props.activePath,
					value: this.newOrder
				}];
			} else {
				edits = this.newOrder.map((key, index) => {
					return {
						path: this.props.activePath + key + '.' + this.props.orderedBy + '.',
						value: index + 1
					};
				});
			}
			this.props.updateJSON(edits);
		}
	}

	keyDown(e) {
		let ctrl = e.ctrlKey || e.metaKey;
		// Ctrl + Shift + C
		if (ctrl && e.shiftKey && e.keyCode === 67) {
			e.preventDefault();
			this.collapseAll();
		}
	}

	collapseAll() {
		let jsonSchema = this.props.jsonSchema;
		let groups = Object.keys((jsonSchema && jsonSchema.group) || {});
		let allKeys = ['misc'].concat(groups);
		let collapsedKeys = allKeys.map(k => k + 'Collapsed');
		let newCollapsed = collapsedKeys.find(k => !this.state[k]);
		let newState = {};
		collapsedKeys.forEach(k => newState[k] = newCollapsed);
		this.setState(newState);
	}

	selectSelf() {
		this.props.setActivePath?.(this.props.attrKeyPath);
	}

	/**
	 * Map function for creating the attributes
	 * @param  {Object} unusedProps - Properties that are in the schema, but not in the appdata§
	 * @param  {Object} nonGrouped - Used for the grouping, these aren't grouped
	 * @param  {String} attr - the key of the particular item it's mapping
	 * @return {Component} return the component
	 */
	mapAttributes = (unusedProps, nonGrouped, orderedBy, attr) => {
		const {
			baseJSONSchema,
			jsonSchema,
			attrKeyPath,
			value,
			updateJSON,
			renameElement,
			cleanJSON,
			jsonPaths,
			setActivePath,
			activeType,
			branch,
			activePath,
			setErrors,
			// title,
			activeValue,
			activeValueFull,
			dynamicSchemaRef,
			movieIdInfo,
			appId
		} = this.props;

		let newJSONSchema = jsonSchema?.properties?.[attr];

		if (!newJSONSchema && jsonSchema?.properties?.allOf?.length) {
			newJSONSchema = jsonSchema.properties.allOf.reduce((o, e) => cloneDeep(o, e), {});
		}

		// remove the values inside of the nonGrouped array so that non-grouped values still show at bottom
		if (nonGrouped && nonGrouped[attr] !== undefined) {
			delete nonGrouped[attr];
		}

		if (value[attr] === undefined) {
			return;
		}

		if (unusedProps && unusedProps[attr]) {
			delete unusedProps[attr];
		}

		const newAttrKeyPath = attrKeyPath + attr.replace(/\./g, '[[[dot]]]') + '.';

		return (
			<Attribute
				id={'attr-' + idfy(attr)}
				parent={value}
				value={value[attr]}
				activeValue={activeValue}
				activeValueFull={activeValueFull}
				attributeName={attr}
				key={newAttrKeyPath}
				updateJSON={updateJSON}
				renameElement={renameElement}
				cleanJSON={cleanJSON}
				attrKeyPath={newAttrKeyPath}
				jsonPaths={jsonPaths}
				jsonSchema={newJSONSchema || {}} // Not sure if this is right? just a quick fix
				baseJSONSchema={baseJSONSchema || jsonSchema || {}}
				setActivePath={setActivePath}
				activeType={activeType}
				branch={branch}
				activePath={activePath}
				displayVariables={this.props.displayVariables}
				setErrors={setErrors}
				orderedBy={orderedBy}
				dynamicSchemaRef={dynamicSchemaRef}
				movieIdInfo={movieIdInfo}
				appId={appId}
			/>
		);
	};

	renderItemContent() {
		let titleTitle = this.props.attributeName;
		let title = titleTitle;
		let description;
		let jsonSchema = this.props.jsonSchema;
		if (jsonSchema && jsonSchema.title) {
			title = jsonSchema.title;
		} else if (jsonSchema?.propertyDisplayTitle && this.props?.value?.[jsonSchema?.propertyDisplayTitle]) {
			title = this.props?.value?.[jsonSchema?.propertyDisplayTitle];
		} else {
			title = showHiddenChars(title);
		}
		if (jsonSchema && jsonSchema.description) {
			description = <span class={s.description}>{jsonSchema.description}</span>;
		}

		return (
			<div class={s.centerContent} title={titleTitle}>
				{title}
				{description}
			</div>
		);
	}

	renderGroup(title, items, key) {
		if (!items.length) {
			return;
		}
		key = key || title;
		let collapsedKey = key + 'Collapsed';
		let titleElemKey = '$' + key + 'Title';
		let collapsed = this.state[collapsedKey];
		let toggleState = {};
		toggleState[collapsedKey] = !collapsed;
		let toggleCollapse = () => {
			this.setState(toggleState);
			setTimeout(() => {
				if (this[titleElemKey]) {
					this[titleElemKey].scrollIntoView({ block: 'nearest', inline: 'nearest' });
				}
			}, 0);
		};
		return (
			<div class={joinClasses(s.group, collapsed && s.collapsed)}>
				<h1 class={s.groupTitle} ref={e => this[titleElemKey] = e}>
					<span onClick={toggleCollapse}>{title}</span>
				</h1>
				<div class={s.groupItems}>
					{items}
				</div>
			</div>
		);
	}

	render(props) {
		const {
			value,
			base,
			parent,
			attributeName,
			attrKeyPath,
			// setErrors,
			updateJSON,
			jsonPaths,
			jsonSchema,
			setActivePath,
			activeType,
			// activePath,
			orderedBy,
			dynamicSchemaRef
		} = props;

		if (base) {
			let attrs = [];
			let miscAttrs = [];
			const wizard = '';

			// Create copy of the schema
			const unusedProps = jsonSchema && jsonSchema.properties && JSON.parse(JSON.stringify(jsonSchema.properties));
			const nonGrouped = cloneDeep(value);
			let useGroups = jsonSchema && jsonSchema.group;

			if (useGroups) {
				attrs = Object.keys(jsonSchema.group).map(groupKey => {
					const groupItem = jsonSchema.group[groupKey];
					if (groupItem.groupByProperty) {
						groupItem.properties = Object.entries(this.props.value).map(([key, val]) => {
							const [property, value] = groupItem.groupByProperty.split('.');
							if (val && (val[property] === value)) {
								return key;
							}
						});
					}

					const items = groupItem.properties.map(v => this.mapAttributes(unusedProps, nonGrouped, null, v)).filter(e => e);
					if (!items.length) return;
					return this.renderGroup(groupItem.title, items, groupKey);
				}).filter(e => e);
			}

			let sortBy = jsonSchema && jsonSchema.sortBy;
			let propertiesOrder = sortObjectByOrder(jsonSchema.properties, (value, key) => key).reverse();

			let nonGroupedKeys = Object.keys(nonGrouped).sort((a, b) => propertiesOrder.indexOf(b) - propertiesOrder.indexOf(a));

			if (!sortBy) {
				let hasOrder = nonGroupedKeys.some(k => nonGrouped[k] && typeof nonGrouped[k] === 'object' && 'order' in nonGrouped[k]);
				if (hasOrder) sortBy = 'order';
			}
			if (sortBy === '_flatObjectValue') {
				miscAttrs = nonGroupedKeys.map(v => this.mapAttributes(unusedProps, {}, sortBy, v)).sort((a, b) => a.attributes.value - b.attributes.value);
			} else if (sortBy) {
				miscAttrs = sortObjectByOrder(nonGrouped, (v, k) => this.mapAttributes(unusedProps, {}, sortBy, k), sortBy);
			} else {
				miscAttrs = nonGroupedKeys.map(v => this.mapAttributes(unusedProps, {}, null, v));
			}

			if (useGroups) {
				miscAttrs = this.renderGroup('Miscellaneous', miscAttrs, 'misc');
			}

			return (
				<div class={s.objectNav}>
					{wizard}
					{attrs}
					{miscAttrs}
				</div>
			);
		}

		const activeTypes = [activeType];
		if (activeType === 'apps') activeTypes.push('branches');
		const paths = jsonPaths[attrKeyPath] || [];

		let activeLevel = activeTypes.find(type => paths.indexOf(type) !== -1);
		let branch = paths.indexOf('branches') !== -1;
		let containerClass = joinClasses(s.displayContainerObject, activeLevel && s.activeLevel, branch && s.branch);
		let mover;
		if (orderedBy || Array.isArray(parent)) {
			mover = <div class={s.mover} onMousedown={this.moverMouseDown} />;
		}


		return (
			<div class={containerClass} data-attr={attributeName} onClick={this.selectSelf}>
				<div class={s.activeState} />
				{mover}
				{this.renderItemContent()}
				<button class={s.selectSelf} onClick={this.selectSelf} />
				<FormRemoveButton
					path={attrKeyPath}
					updateJSON={updateJSON}
					dynamicSchemaRef={dynamicSchemaRef}
				/>
			</div>
		);
	}

}
