import { h, Component } from 'preact';
// import s from './wizard.sss';
import pure from 'utils/pure';
import Store from 'store/store';
// import uniq from 'lodash/uniq';
// import concat from 'lodash/concat';
import merge from 'lodash/merge';
// import cloneDeep from "@nodeutils/defaults-deep";
import * as actions from 'store/actions';
import { accessNested } from 'utils/utils.js';

export default @pure class WebediaIds extends Component {

	constructor({ title }) {
		super();
		this.state.titleEn = title.attributes.meta.title.en;
		this.state.webediaIds = accessNested(title, "attributes.meta.title.webediaIds",  []);
		this.state.results = '';
		this.searchWebedia = this.searchWebedia.bind(this);
		this.renderResult = this.renderResult.bind(this);
		this.parseIds = this.parseIds.bind(this);
		this.save = this.save.bind(this);
		this.state.primary = '';
		this.state.alts = {};
		this.state.text = '';
	}

	searchWebedia() {
		if (!this.state.titleEn) return;
		let url = 'http://powster.json.cinema-source.com/?ver=3.1&query=movie&appId=UKPOW&search=' + encodeURIComponent(this.state.titleEn);
		fetch(url).then(res => res.json()).then(data => {
			this.setState({ results: data });
		});
	}
	togglePrimary(m, e) {
		let checked = e.target.checked;
		if (checked) {
			this.setState({ primary: m.movie_id });
		} else if (this.state.primary === m.movie_id){
			this.setState({ primary: '' });
		}
	}
	toggleAlt(m, e) {
		let checked = e.target.checked;
		let alts = this.state.alts;
		if (checked && !this.state.alts[m.movie_id]) {
			alts[m.movie_id] = true;
			this.setState({alts});
		} else {
			delete alts[m.movie_id];
			this.setState({alts});
		}
	}

	save() {
		let { title } = this.props;
		let update = {
			meta: {
				title: { webediaIds: this.state.webediaIds }
			}
		};
		let current = title.edited ? title.edited.toJS() : title.attributes.toJS();
		let updatedAppData = merge(current, update);
		Store.emit(actions.UPDATE_EDITED_APP_DATA, this.props.title, updatedAppData);
		this.props.closeWizard();
	}

	renderResult(m) {
		return (
			<tr>
				<td><input type="checkbox" checked={this.state.primary === m.movie_id} onChange={this.togglePrimary.bind(this,m)}/></td>
				<td><input type="checkbox" checked={!!this.state.alts[m.movie_id]} onChange={this.toggleAlt.bind(this,m)}/></td>
				<td><span>{m.movie_id}</span></td>
				<td><span>{m.title}</span></td>
			</tr>
		);
	}

	parseIds(e) {
		let text = e.target.value;
		this.setState({ text, webediaIds: text.split(/\r?\n/).map(s => s.trim()) });
	}

	render({ closeWizard }, { webediaIds, text }) {
		if (!text && webediaIds.length) text = webediaIds.join('\n');
		return (
			<div>
				<h3>Search For Webedia Ids</h3>
				<p><a target="_blank" href="http://powster.westworldmedia.com/powsterhome.asp?function=login">Open Webedia Search</a></p>
				<dl>
					<dt>user:</dt>
					<dd>Powster</dd>
					<dt>password:</dt>
					<dd>StePowster!</dd>
				</dl>
				<p>Please find the associated webedia ids and paste them in the following text area, one id per line. The 2d version of the film should always be on the first line. Any additional ids (3d, imax, etc) can be in any order on the following lines.</p>
				<textarea onInput={this.parseIds} value={text}></textarea>
				<p>Identified ids: {webediaIds.join(', ')}</p>
				<button type="button" disabled={!webediaIds.length} onClick={this.save}>Save</button>
				<button type="button" onClick={closeWizard}>Cancel</button>

				{/*<input onInput={this.linkState('titleEn')} value={titleEn} placeholder="Title" />
				<button disabled={!titleEn} type="button" onClick={this.searchWebedia}>Search for Webedia ID</button>
				<table>
					<thead><tr><th>primary (2d)</th><th>alt</th><th>webedia id</th><th>titleEn</th></tr></thead>
					<tbody>{results && results.movies && results.movies.movie && results.movies.movie.map(this.renderResult)}</tbody>
				</table>*/}
			</div>
		);
	}
}
