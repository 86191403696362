import { h, Component } from 'preact';
import s from 'components/pages/messengerTools/messengerPages.sss';
import { joinClasses } from 'utils/utils';
import pasteData from 'utils/pasteData';
import DropdownSearchSelector from 'components/shared/dropdownSearchSelector/dropdownSearchSelector';

const API_KEY = typeof window !== 'undefined' && (window.__AUTH_USER__.apiKey || localStorage.getItem('__API_KEY__'));

// TODO: util or something
function messengerRequest(endpoint, opts) {
	if (typeof window === 'undefined') {
		return Promise.reject('TRYING TO CALL MESSENGER API SERVER-SIDE');
	}

	opts = opts || {};
	let qs = Object.assign({
		key: API_KEY
	}, opts.qs);

	let base = 'https://messenger.powster.com/pages';
	// let base = 'http://10.5.0.10:3033/pages';
	let url = new URL(base + endpoint, location.href);

	Object.keys(qs).forEach(queryItem => {
		url.searchParams.set(queryItem, qs[queryItem]);
	});
	return fetch(url.href, opts).then(res => {
		let status = res.status;
		if (status < 200 || status >= 300) {
			return res.text().then(content => Promise.reject(content));
		}
		return res.json();
	});
}

let pageList;
function getPageList(force) {
	if (!pageList || force) {
		pageList = messengerRequest('/page-list');
	}
	return pageList;
}
let flowList;
function getFlowList(force) {
	if (!flowList || force) {
		flowList = messengerRequest('/flow-list');
	}
	return flowList;
}
function updateInfos(data) {
	return messengerRequest('/page', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	});
}

// extract the actual domain from a "decorated" domain
//  e.g. https://google.com/ -> google.com
const extractDomain = url => url.match(/^(?:(?:https?:)?\/\/)?(.*?)\/?$/)[1];

export default class MessengerPages extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userTokenMode: 'default',
			pages: null,
			flows: null,
			// pageInfoSaving: true
		};
		this.updatePages = this.updatePages.bind(this);
		this.updateFlows = this.updateFlows.bind(this);
		this.updateSelectedPage = this.updateSelectedPage.bind(this);
		this.flowSelectionChanged = this.flowSelectionChanged.bind(this);
		this.resetPageInfoClick = this.resetPageInfoClick.bind(this);
		this.savePageInfo = this.savePageInfo.bind(this);
		this.askCustomUserToken = this.askCustomUserToken.bind(this);
		this.userTokenModeChange = this.userTokenModeChange.bind(this);
		this.refreshAccessToken = this.refreshAccessToken.bind(this);
		this.addWhitelistElement = this.addWhitelistElement.bind(this);
		this.removeWhitelistElement = this.removeWhitelistElement.bind(this);
		this.resetWhitelist = this.resetWhitelist.bind(this);
		this.saveWhitelist = this.saveWhitelist.bind(this);

		if (typeof window !== 'undefined') {
			try {
				this.state.customUserToken = localStorage.get('customUserToken');
			} catch(e) {}

			getPageList().then(this.updatePages).catch(e => console.log(e));
			getFlowList().then(this.updateFlows).catch(e => console.log(e));
		}
	}

	updatePages(list) {
		if (!list || !Array.isArray(list)) {
			return;
		}
		// Sort by most recently updated first
		list = list.sort((a, b) => b.modified_at.localeCompare(a.modified_at));
		let renderPageAutocompleteElem = (elem) => {
			return [
				<span class={s.fbId}>{elem.src.fb_id}</span>,
				<span class={s.fbDescription}>{elem.src.description}</span>
			];
		};
		this.pagesAutocompleteList = list.map(page => ({
			src: page,
			value: page.fb_id,
			label: page.description,
			render: renderPageAutocompleteElem
		}));
		this.setState({ pages: list });
	}

	updateFlows(list) {
		if (!list || !Array.isArray(list)) {
			return;
		}
		// Sort by most recently updated first
		list = list.sort((a, b) => b.modified_at.localeCompare(a.modified_at));
		this.flowsAutocompleteList = list.map(flow => ({
			src: flow,
			value: flow.id,
			label: flow.name
		}));
		this.setState({ flows: list });
	}

	checkPageAutocompleteWord(search, elem) {
		let page = elem.src;
		return search.split(',').every(needle => {
			needle = needle.trim().toLowerCase();
			if (!needle) return true;
			return page.fb_id.includes(needle) || page.description.toLowerCase().includes(needle);
		});
	}

	updateSelectedPage(value) {
		let page = value && value.src;
		if (!page || this.state.selectedPage === page) {
			return;
		}
		let changesMessage = 'You have unsaved changes. Discard them?\n(cancel to keep editing the current page)';
		// eslint-disable-next-line no-alert
		if (this.state.hasChanges && !confirm(changesMessage)) {
			return this.$pageSelector.select(this.state.selectedPage);
		}
		this.setState({
			loadingPage: true,
			tokenError: false,
			selectedPage: page,
			profile: null
		});
		this.resetPageInfo(page);
		messengerRequest('/profile', {
			qs: { access_token: page.access_token }
		}).then(profile => {
			let whitelist = ((profile && profile.whitelisted_domains) || []).slice();
			let getStarted = (profile && profile.get_started) || {};
			this.setState({
				loadingPage: false,
				profile,
				whitelist,
				whitelistSelection: null,
				getStartedPayload: getStarted.payload || '',
			});
		}).catch(e => {
			this.setState({ loadingPage: false, tokenError: true });
			console.log('Error getting the page profile', (e && e.error) || e);
		});
	}

	flowSelectionChanged(element) {
		this.setState({ flowId: element && element.value });
	}

	resetPageInfo(page) {
		if (!page || !page.id) {
			return;
		}
		this.setState({
			fbId: page.fb_id,
			accessToken: page.access_token,
			description: page.description,
			flowId: page.flow_id,
			appId: page.app_id,
		});
	}

	resetPageInfoClick() {
		if (!this.state.selectedPage) {
			return;
		}
		// eslint-disable-next-line no-alert
		if (!confirm('Are you sure you want to revert your changes to the Page Details')) {
			return;
		}
		this.resetPageInfo(this.state.selectedPage);
	}

	savePageInfo() {
		let state = this.state;
		let page = state.selectedPage;
		if (!page || state.pageInfoSaving) {
			return;
		}
		let update = {
			// fb_id: state.fbId,
			access_token: state.accessToken,
			description: state.description,
			flow_id: state.flowId,
			app_id: state.appId
		};
		this.setState({ pageInfoSaving: true });
		return updateInfos(Object.assign({ id: page.id }, update)).then(() => {
			let newPage = Object.assign(page, update);
			this.setState({ selectedPage: newPage });
		}).catch(() => {}).then(() => {
			this.setState({ pageInfoSaving: false });
		});
	}

	askCustomUserToken() {
		return pasteData().then(data => {
			if (!data || !data.match(/^[a-z0-9]{50,300}$/i)) {
				return null;
			}
			try {
				localStorage.setItem('customUserToken', data);
			} catch(e) {}
			this.setState({ customUserToken: data, userTokenMode: 'custom' });
			return data;
		});
	}

	userTokenModeChange(e) {
		let mode = e.target.value;
		if (mode === this.state.userTokenMode) {
			return;
		}
		if (mode === 'custom' && !this.state.customUserToken) {
			return this.askCustomUserToken().then(result => !result && this.setState({ userTokenMode: 'default' }));
		}
		this.setState({ userTokenMode: mode });
	}

	refreshAccessToken() {
		let page = this.state.selectedPage;
		if (!page) {
			return;
		}
		this.setState({ accessTokenLoading: true });

		let qs = ['page=' + encodeURIComponent(page.fb_id)];
		if (this.state.userTokenMode === 'custom' && this.state.customUserToken) {
			qs.push('userToken=' + encodeURIComponent(this.state.customUserToken));
		}
		fetch('/messenger/page_data?' + qs.join('&')).then(res => {
			let status = res.status;
			if (status < 200 || status >= 300) {
				return Promise.reject(res.text());
			}
			return res.json();
		}).then(data => {
			if (!data.access_token) {
				console.log('Missing access_token data', data);
				throw Error('User Token lacks page admin role');
			}
			this.setState({ accessTokenLoading: false, accessToken: data.access_token });
		}).catch(err => {
			console.error('Error getting access token', err);
			this.setState({ accessTokenLoading: false });
		});
	}

	addWhitelistElement() {
		// TODO: proper UI instead of prompt
		// eslint-disable-next-line no-alert
		let value = prompt('Enter the domain you wish to whitelist', 'https://');
		value = value && value.trim().toLowerCase();
		if (!value) {
			return;
		}
		let domain = extractDomain(value);
		let whitelist = this.state.whitelist || [];
		if (whitelist.find(d => extractDomain(d) === domain)) {
			return;
		}
		whitelist.push(value);
		this.setState({ whitelist, whitelistSelection: value });
	}

	removeWhitelistElement() {
		let toRemove = this.state.whitelistSelection;
		let whitelist = this.state.whitelist || [];
		let firstIndex = whitelist.indexOf(toRemove);
		whitelist = whitelist.filter(e => e !== toRemove);
		this.setState({ whitelist, whitelistSelection: whitelist[firstIndex] || whitelist[whitelist.length - 1] || null });
	}


	resetWhitelist() {
		// eslint-disable-next-line no-alert
		if (!confirm('Are you sure you want to revert your changes to the whitelisted domains')) {
			return;
		}
		let profile = this.state.profile;
		let whitelist = ((profile && profile.whitelisted_domains) || []).slice();
		this.setState({ whitelist, whitelistSelection: null });
	}

	saveWhitelist() {
		let state = this.state;
		let page = state.selectedPage;
		if (!page || state.whitelistSaving) {
			return;
		}
		let update = {
			whitelisted_domains: state.whitelist
		};
		this.setState({ whitelistSaving: true });
		return messengerRequest('/profile', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			qs: { access_token: page.access_token },
			body: JSON.stringify(update)
		}).then(() => {
			let newProfile = Object.assign(state.profile, update);
			this.setState({ profile: newProfile });
		}).catch(() => {}).then(() => {
			this.setState({ whitelistSaving: false });
		});
	}

	// RENDER FUNCTIONS

	userSelector() {
		return (
			<div class={s.user}>
				<div class={s.userSelector}>
					<div class={s.description} title="Only used to get the page Access Token - needs to be an admin of the page">User Token:</div>
					<input
						type="radio"
						name="userToken"
						value="default"
						id="userTokenDefault"
						checked={this.state.userTokenMode === 'default'}
						onChange={this.userTokenModeChange}
					/>
					<label for="userTokenDefault">
						Default (Anthony)
					</label>
					<input
						type="radio"
						name="userToken"
						value="custom"
						id="userTokenCustom"
						checked={this.state.userTokenMode === 'custom'}
						onChange={this.userTokenModeChange}
					/>
					<label for="userTokenCustom">
						Custom
						<button type="button" class={s.setCustomUserToken} onClick={this.askCustomUserToken}>set</button>
					</label>
				</div>
				<div class={s.details}>
					Use <a href="https://developers.facebook.com/tools/accesstoken" target="_blank">this page</a> to copy your <em>User Token</em> for <em>Powster Bot</em>
				</div>
			</div>
		);
	}

	pageSelector() {
		let pages = this.state.pages;
		let selector = <div class={s.selectorLoading}>Loading...</div>;
		if (pages) {
			selector = (
				<div class={s.pageInput}>
					<DropdownSearchSelector
						ref={e => this.$pageSelector = e}
						class={s.pageSelector}
						values={this.pagesAutocompleteList}
						placeholder="Select a page"
						onChange={this.updateSelectedPage}
						// autofocus
					/>
				</div>
			);
		}
		return (
			<fieldset class={joinClasses(s.selection)}>
				<legend>Page selection</legend>
				{selector}
			</fieldset>
		);
	}

	loadingIndicator() {
		let isLoading = this.state.loadingPage;
		return (
			<div class={joinClasses(s.loadingIndicator, isLoading && s.visible)}>
				<span>L</span>
				<span>O</span>
				<span>A</span>
				<span>D</span>
				<span>I</span>
				<span>N</span>
				<span>G</span>
			</div>
		);
	}

	pageInfo() {
		let state = this.state;
		let page = state.selectedPage;
		let isVisible = !!page;
		let flowSelector = <div class={s.selectorLoading}>Loading...</div>;
		if (state.flows) {
			flowSelector = (
				<DropdownSearchSelector
					ref={e => this.$flowSelector = e}
					class={s.flowInput}
					values={this.flowsAutocompleteList}
					value={state.flowId}
					placeholder="Select a flow"
					onChange={this.flowSelectionChanged}
				/>
			);
		}
		let hasChanges = page && (
			state.accessToken !== page.access_token ||
			state.description !== page.description ||
			state.flowId !== page.flow_id ||
			state.appId !== page.app_id
		);
		return (
			<fieldset class={joinClasses(s.info, isVisible && s.visible, state.pageInfoSaving && s.saving)}>
				<legend>Page details</legend>
				<label class={s.field}>
					<span class={s.name}>FB ID</span>
					<input type="text" value={state.fbId} readOnly onInput={this.linkState('fbId')} />
				</label><br />
				<label class={s.field}>
					<span class={s.name}>Access Token</span>
					<input type="text" value={state.accessTokenLoading ? '...' : state.accessToken} readOnly onInput={this.linkState('accessToken')} />
					<button class={s.refresh} onClick={this.refreshAccessToken} />
				</label><br />
				<label class={s.field}>
					<span class={s.name}>Description</span>
					<input type="text" value={state.description} onInput={this.linkState('description')} />
				</label><br />
				<label class={s.field}>
					<span class={s.name}>Flow</span>
					{/* <input type="text" value={state.flowId} onInput={this.linkState('flowId')} /> */}
					{flowSelector}
				</label><br />
				<label class={s.field}>
					<span class={s.name}>App</span>
					<input type="text" value={state.appId} onInput={this.linkState('appId')} />
				</label><br />
				<div class={s.buttons}>
					<button class={s.reset} onClick={this.resetPageInfoClick} disabled={!hasChanges}>Reset</button>
					<button class={s.save} onClick={this.savePageInfo} disabled={!hasChanges}>Save</button>
					<div class={s.savingIndicator}>Saving...</div>
				</div>
			</fieldset>
		);
	}

	tokenError() {
		let state = this.state;
		let isVisible = state.selectedPage && state.tokenError;
		return (
			<div class={joinClasses(s.tokenError, isVisible && s.visible)}>
				An error occurred while getting the page data. The access token could be incorrect or maybe you do not have sufficient permissions.
			</div>
		);
	}

	whitelist() {
		let state = this.state;
		let profile = state.profile;
		let isVisible = state.selectedPage && profile && !state.loadingPage;
		let savedList = (profile && profile.whitelisted_domains) || [];
		let list = this.state.whitelist || [];
		// pls forgive
		let hasChanges = JSON.stringify(list.slice().sort()) !== JSON.stringify(savedList.slice().sort());
		let listSize = Math.min(Math.max(list.length, 2), 5);
		return (
			<fieldset class={joinClasses(s.whitelist, isVisible && s.visible, state.whitelistSaving && s.saving)}>
				<legend>Whitelisted domains</legend>
				<div class={s.listContainer}>
					<select size={listSize} class={s.list} onChange={this.linkState('whitelistSelection')} value={state.whitelistSelection}>
						{list.map(el => <option value={el}>{el}</option>)}
					</select>
					<div class={s.listButtons}>
						<button class={s.add} onClick={this.addWhitelistElement} />
						<button class={s.remove} disabled={!state.whitelistSelection} onClick={this.removeWhitelistElement} />
					</div>
				</div>
				<div class={s.buttons}>
					<button class={s.reset} onClick={this.resetWhitelist} disabled={!hasChanges}>Reset</button>
					<button class={s.save} onClick={this.saveWhitelist} disabled={!hasChanges}>Save</button>
					<button class={s.delete} onClick={this.deleteWhitelist}>Delete</button>
					<div class={s.savingIndicator}>Saving...</div>
				</div>
			</fieldset>
		);
	}

	getStarted() {
		let state = this.state;
		let profile = state.profile;
		let isVisible = state.selectedPage && profile && !state.loadingPage;
		let saved = (profile && profile.get_started) || {};
		let hasChanges = state.getStartedPayload !== saved.payload;
		return (
			<fieldset class={joinClasses(s.getStarted, isVisible && s.visible, state.getStartedSaving && s.saving)}>
				<legend>Get started</legend>
				<label class={s.field}>
					<span class={s.name}>Payload</span>
					<input type="text" value={state.getStartedPayload} onInput={this.linkState('getStartedPayload')} />
				</label><br />
				<div class={s.buttons}>
					<button class={s.reset} onClick={this.resetGetStarted} disabled={!hasChanges}>Reset</button>
					<button class={s.save} onClick={this.saveGetStarted} disabled={!hasChanges}>Save</button>
					<button class={s.delete} onClick={this.deleteGetStarted}>Delete</button>
					<div class={s.savingIndicator}>Saving...</div>
				</div>
			</fieldset>
		);
	}

	persistentMenu() {

	}

	render({ dark }) {
		return (
			<div class={joinClasses(s.wrapper, dark && s.dark)}>
				{this.userSelector()}
				{this.pageSelector()}
				{this.pageInfo()}
				{this.loadingIndicator()}
				{this.tokenError()}
				{this.whitelist()}
				{this.getStarted()}
				{this.persistentMenu()}
			</div>
		);
	}

}
