import { h, Component } from 'preact';
import * as jsonpatch from 'fast-json-patch';
import defaultsDeep from '@nodeutils/defaults-deep';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import Store from 'store/store';
import * as actions from 'store/actions';

import {
	accessNested,
	arrayUnique,
	commaSeparatedList,
	condClass,
	joinClasses,
	triggerAnim,
} from 'utils/utils';
import { setPageTitle } from 'utils/setPageTitle';
import createNested from 'utils/createNested';
import objectPaths from 'utils/objectPaths';
import deleteNulls from 'utils/deleteNulls';
// import { replaceWithVNode } from 'utils/vnode';
import createTooltip from 'utils/tooltip';
import * as quickInput from 'utils/quickInput';
import * as keyboardShortcuts from 'utils/keyboardShortcuts';
import { quickNavigation } from 'utils/quickNavigation';
import { fetchMovieDataIfNecessary } from 'utils/fetchMovieDataIfNecessary';
// import { addBoltData } from 'utils/setUpBolt';
import baseNames, { SHOWTIMES_BASE, BOLT_BASE } from 'utils/baseNames';

import {
	updateAppData,
	deleteAppData,
	getAppData,
	getTitleData,
	getStudioRegionData,
	getStudioData,
	getRegionData,
	getBaseData
} from 'services/dataApi';
import { clearBuildCache } from 'services/buildSockets';
import {
	attachDistribution,
	resetApp,
	invalidateApp,
	invalidateStatic,
	// updateCloudfrontBehaviors
} from 'services/buildApi';

import { Link, goToPage } from 'components/core/link/link';
import route from 'components/core/link/route';
import AppDataEditor from 'components/pages/apps/appDataEditor';
import InfoSidebar from 'components/shared/infoSidebar/infoSidebar';
import BasicStartWizard from './wizards/basicStartWizard';
import BoltWizard from './wizards/boltWizard';
import WebediaIds from './wizards/webediaIds';
import GASetup from 'components/pages/apps/modals/gaSetup';
import LookerSetup from 'components/pages/apps/modals/lookerSetup';
import CreateOverride from 'components/pages/apps/createOverride';
import MassDeploy from 'components/pages/apps/modals/massDeploy';
import PixelPaxilModal from 'components/shared/modal/pixelPaxilModal';
import LaunchEmailGenerator from 'components/shared/modal/launchEmailGenerator';
import KeyboardShortcutsHelper from 'components/shared/modal/keyboardShortcuts';
import ConflictsModal from 'components/pages/apps/modals/conflictsModal';

import s from 'components/pages/apps/mainEdit.sss';

const onLocal = typeof window !== 'undefined' && location.href.indexOf('thundr.powster.com') === -1;

const STORAGE_DARK_MODE = 'thundr-dark-mode';

const WARNING_LEVELS = {
	INFO: 1,
	WARNING: 2,
	ERROR: 3
};

const resetQS = obj => Object.assign({
	app: null,
	title: null,
	studio: null,
	studio_region: null,
	region: null,
	base: null,
	branch: null
}, obj);

// const isAppLevel = level => level === LEVELS.APP || level === LEVELS.BRANCH;

const sortBySlug = (arr) => arr.sort((a, b) => (a?.slug || '').localeCompare(b?.slug));

const currentValue = (v) => typeof v === 'function' ? v() : v;

export default class MainEdit extends Component {

	constructor(props) {
		super(props);

		this.state = {
			merged: {},
			mergedFull: {},
			mergedPaths: {},
			wizard: null,
			actionsOpen: false,
			linksWarnings: {}
		};

		this.studios = [];
		this.bases = [];
		this.regions = [];
		this.title = '';

		this.extraActions = [];
		if (typeof window !== 'undefined') {
			try {
				let darkMode = localStorage.getItem(STORAGE_DARK_MODE);
				if (darkMode === 'true') {
					this.state.darkMode = true;
				}
			} catch (e) {}
		}

		this.registerActions = this.registerActions.bind(this);
		this.activateStartValuesWizard = this.activateStartValuesWizard.bind(this);
		this.activateBoltStartValuesWizard = this.activateBoltStartValuesWizard.bind(this);
		this.activateWebediaIdsWizard = this.activateWebediaIdsWizard.bind(this);
		this.createDistribution = this.createDistribution.bind(this);
		this.updateCloudfrontBehaviors = this.updateCloudfrontBehaviors.bind(this);
		this.generateLaunchEmail = this.generateLaunchEmail.bind(this);
		this.debugKeyboardShortcuts = this.debugKeyboardShortcuts.bind(this);
		this.deleteApp = this.deleteApp.bind(this);
		this.closeWizard = this.closeWizard.bind(this);
		this.activateMatcher = this.activateMatcher.bind(this);
		this.getMessengerCode = this.getMessengerCode.bind(this);
		this.setupRedirect = this.setupRedirect.bind(this);
		this.pixelPaxil = this.pixelPaxil.bind(this);
		this.gaSetup = this.gaSetup.bind(this);
		this.lookerSetup = this.lookerSetup.bind(this);
		this.createOverride = this.createOverride.bind(this);
		this.massDeploy = this.massDeploy.bind(this);
		this.openQuickNavigation = this.openQuickNavigation.bind(this);
		this.openActions = this.openActions.bind(this);
		this.onContainerClick = this.onContainerClick.bind(this);
		// this.setUpBolt = this.setUpBolt.bind(this);
		this.getAppsList = this.getAppsList.bind(this);
		this.makeWebediaIdsAnArray = this.makeWebediaIdsAnArray.bind(this);
		this.checkUpdates = this.checkUpdates.bind(this);
		this.saveData = this.saveData.bind(this);
		this.showConflicts = this.showConflicts.bind(this);
		this.getMerged = this.getMerged.bind(this);

		this.refreshTimer = null;
	}

	/**
	 * Life Cycle
	**/

	componentWillMount() {
		this.prepareLevel(this.props);
		// let ok = this.prepareLevel(this.props);
		// if (ok) this.updateData(this.props, this.state);
	}

	componentDidMount() {
		this.updateData(this.props, this.state);
		// this.scheduleUpdateCheck();
		this.shortcutsHandler = keyboardShortcuts.register({
			// Regular keybinding (most IDEs)
			'ctrl+P': { action: this.openQuickNavigation, name: 'Quick AppData navigation' },
			// Duplicate with different shortcut to mimic github / slack / ...
			'ctrl+K': { action: this.openQuickNavigation },
			'ctrl+shift+P': { action: this.openActions, name: 'Show and run actions' },
			'ctrl+O': { action: this.createOverride, name: 'Create an override' },
			'ctrl+S': { action: this.saveData, name: 'Save data' },
			'ctrl+.': { chord: true },
			'ctrl+.,M': { action: this.massDeploy, name: 'Advanced deploy' },
			'ctrl+shift+H': { action: this.debugKeyboardShortcuts, name: 'Show keyboard shortcuts helper' },
		});
	}

	componentWillUnmount() {
		clearTimeout(this.refreshTimer);
		this.refreshTimer = null;
		this.shortcutsHandler?.stop();
	}

	componentWillReceiveProps(props) {
		const changed = key => accessNested(props, key) !== accessNested(this.props, key);

		const queryValues = [
			'query.base',
			'query.region',
			'query.studio',
			'query.studio_region',
			'query.title',
			'query.app',
			'query.branch',
		];

		let levelNeedsUpdate = [
			...queryValues,
			'apps',
			'titles',
			'regions',
			'studios',
			'studioRegions',
			'bases'
		].some(changed);

		if (levelNeedsUpdate) {
			let ok = this.prepareLevel(props);
			if (ok) {
				this.updateData(props, this.state, this.props);
			}
		}

		if (this.state.wizard && queryValues.some(changed)) {
			this.closeWizard();
		}
	}

	componentWillUpdate(props, state) {
		if (state.actionsOpen && !this.state.actionsOpen) {
			let meta = state.merged && state.merged.meta;
			if (meta) {
				this.checkLinkState('demo', meta);
				this.checkLinkState('stage', meta);
				this.checkLinkState('prod', meta);
			}
		}
	}

	// componentDidUpdate(props) {
	// 	if (props.activeLevelId !== this.props.activeLevelId) {
	// 		this.this.scheduleUpdateCheck();
	// 	}
	// }

	registerActions(list) {
		if (!Array.isArray(list)) {
			list = [list];
		}
		this.extraActions.push(...list);
		return {
			unregister: () => {
				this.extraActions = this.extraActions.filter(a => !list.includes(a));
			},
		};
	}

	onContainerClick(e) {
		if (this.state.actionsOpen && this.$actions && !this.$actions.contains(e.target)) {
			this.setState({ actionsOpen: false });
		}
	}

	clearCache = () => {
		clearBuildCache(this.state.merged);
	};

	invalidate = async () => {
		let merged = this.state.merged;
		await invalidateApp(merged);
		await invalidateStatic(merged);
	};

	stopWatchers = () => {
		// Should do it though the sockets maybe ?
		let guid = accessNested(Store.get(), 'user.guid');
		fetch('/maintenance/stop-watchers?guid=' + guid).then(() => {
			let store = Store.get();
			if (!store.myApps) return;
			let myApps = Object.values(store.myApps).reduce((myApps, app) => {
				if (!app || app.buildType === 'local') return myApps;
				myApps[app.appId] = app;
				return myApps;
			}, {});
			store.set({ myApps });
		});
	};

	clearBuildQueue = () => {
		fetch('/build/clear-queue');
	};

	scheduleUpdateCheck(delay = 10000) {
		clearTimeout(this.refreshTimer);
		this.refreshTimer = setTimeout(() => this.checkUpdates(true), delay);
	}

	async checkUpdates(updateTimer) {
		let func = {
			apps: getAppData,
			titles: getTitleData,
			studios: getStudioData,
			studio_regions: getStudioRegionData,
			regions: getRegionData,
			bases: getBaseData,
		}[this.activeLevel()];
		if (!func || !window.user?.isAuthenticated) {
			return true;
		}
		let data;
		try {
			data = await func(this.state.activeAppData?.id);
			if (!data) {
				throw 'NO_RESPONSE';
			}
		} catch (e) {
			// Error getting data from server. Just try again in a bit (server hiccup ?)
			if (updateTimer === true) {
				this.scheduleUpdateCheck();
			}
			return;
		}
		if (!this.refreshTimer || data.id !== this.state.activeAppData?.id || data.type !== this.state.activeAppData?.type) {
			return true;
		}
		// Next one pls
		if (updateTimer === true) {
			this.scheduleUpdateCheck();
		}
		let current = this.state.activeAppData;
		let diff = jsonpatch.compare(current.attributes, data.attributes);
		if (!diff.length) return false; // All good
		let edited = current.edited;
		if (edited) {
			if (typeof edited.toJS === 'function') edited = edited.toJS();
			let newConflicts = diff.filter(elem => {
				let path = elem.path.slice(1).split('/').map(e => e.replace(/~1/g, '/').replace(/~0/g, '~'));
				let currentValue = accessNested(current.attributes, path);
				let editedValue = accessNested(edited, path);
				let newValue = accessNested(data.attributes, path);
				if (isEqual(newValue, editedValue)) {
					return false;
				}
				if (isEqual(currentValue, editedValue)) {
					let attribute = path.pop();
					if (elem.op === 'remove') {
						delete accessNested(edited, path)[attribute];
					} else {
						accessNested(edited, path)[attribute] = newValue;
					}
					return false;
				}
				return true;
			});
			data.conflicts = (current.conflicts || []).concat(newConflicts);
			data.edited = edited;
		}
		if (this.activeLevel() === 'apps' && accessNested(this.props, ['myApps', this.state.activeAppData.id])) {
			Store.emit(actions.UPDATE_MY_APP, { dataChanged: true }, this.state.activeAppData.id);
		}
		Store.emit(actions.UPDATE_APP_DATA, data);
		return data;
	}

	// This should probably be in appDataEditor
	showConflicts(data) {
		data = data || this.state.activeAppData;
		Store.emit(
			actions.SHOW_MODAL,
			<ConflictsModal data={data} />,
			'Solve conflicts',
			'conflicts',
			result => {
				if (result === 'FINISHED') {
					this.saveData();
				}
			}
		);
	}

	async saveData(options) {
		let { data = this.state.activeAppData } = options || {};
		if (!data?.edited) {
			return;
		}

		this.setState({ saving: true });

		const newData = await this.checkUpdates();
		if (newData === true) {
			this.setState({ saving: false });
			return;
		}
		if (newData?.conflicts?.length) {
			this.setState({ saving: false });
			return this.showConflicts(newData);
		}

		data = newData || data;
		if (!data.edited || isEqual(data.edited, data.attributes)) {
			this.setState({ saving: false });
			return;
		}

		try {
			let update = cloneDeep(data);
			let newAttributes = data.edited;
			update.attributes = newAttributes;
			delete update.edited;

			let updated = await updateAppData(update.id, update.type.replace('app_data_', ''), update, data);
			Store.emit(actions.UPDATE_APP_DATA, updated);
			Store.emit(actions.VALIDATE_MY_APPS);
		} catch (err) {
			// TODO: Handle error properly
			console.log('Error saving data', err);
			createTooltip('An error occured while saving', { class: 'error', duration: 3000 });
		}
		this.setState({ saving: false });
	}

	getUrl(mode, meta, secure) {
		meta = meta || accessNested(this.state, 'merged.meta');
		if (!meta) return;
		let baseUrl = [meta.studio.slug, meta.title.slug, meta.region.slug].join('/');
		if (mode === 'demo') {
			return 'https://demo.pow.io/' + baseUrl + '/';
		}
		if (secure) {
			// We can't have https on the standard link because of the . in the bucket name
			return 'https://s3-eu-west-1.amazonaws.com/' + mode + '.showtimes-static/' + baseUrl + '/index.html';
		}
		return 'http://' + mode + '.showtimes-static.s3-website-eu-west-1.amazonaws.com/' + baseUrl + '/';
	}

	checkLinkState(mode, meta) {
		let warnings = this.state.linksWarnings;
		warnings[mode] = false;
		this.setState({ linksWarnings: warnings });
		return this.checkAnyLink(this.getUrl(mode, meta, true)).then(result => {
			if (result) return;
			let warnings = this.state.linksWarnings;
			warnings[mode] = true;
			this.setState({ linksWarnings: warnings });
		});
	}
	async checkAnyLink(url) {
		let req = new Request(url, { mode: 'cors', cache: 'no-store' });
		try {
			let res = await fetch(req);
			if (!res || !res.ok) {
				return false;
			}
			return res.text();
		} catch (e) {
			return false;
		}
	}

	/**
	 * Interaction Handlers
	**/

	// setUpBolt() {
	// 	let appData = cloneDeep(this.app);
	// 	let newDataAttributes = addBoltData(appData.attributes);
	// 	Store.emit(actions.UPDATE_EDITED_APP_DATA, this.app, newDataAttributes);
	// }

	deleteApp() {
		// let props = this.props;
		let app = this.state.merged;
		let meta = app && app.meta;
		if (!meta) {
			return;
		}
		let securityCheck = 'i-am-sure';
		let modalSubmit = e => {
			e.preventDefault();
			let elem = e.target.elements['confirmation'];
			if (elem.value !== securityCheck) {
				elem.focus();
				triggerAnim(elem, s.error);
				return;
			}
			Store.emit(actions.HIDE_MODAL, 'OK');
		};
		let modal = (
			<form class={s.securityModal} onSubmit={modalSubmit}>
				<div class={s.description}>
					You are about to <em>completely delete</em> the app <span class={s.appName}>{meta.title.en} | {meta.region.slug.toUpperCase()}</span>.<br />
					THIS CANNOT BE UNDONE.<br />
					Please type <span class={s.securityValue}>{securityCheck}</span> in the box below to confirm.<br />
				</div>
				<div class={s.securityInput}><input type="text" name="confirmation" value="" autocomplete="off" /></div>
				<div class={s.buttons}>
					<button type="button" onClick={() => Store.emit(actions.HIDE_MODAL)}>Cancel</button>
					<input type="submit" class={s.confirmDelete} value="I AM SURE, DELETE" />
				</div>
			</form>
		);
		this.setState({ actionsOpen: false });
		Store.emit(actions.SHOW_MODAL, modal, 'SECURITY CHECK', 'securityModal', async v => {
			if (v !== 'OK') {
				return;
			}
			let id = this.state.activeAppData?.id;
			try {
				await deleteAppData(id, this.activeLevel());
				Store.emit(actions.REMOVE_APP, id);
				goToPage('apps');
			} catch (err) {
				console.error('error deleting app', err);
				createTooltip('An error occured, please ask a dev', { class: 'error', duration: 3000 });
			}
		});
	}
	delete(type) {
		let app = this.state.merged;
		let meta = app && app.meta;
		if (!meta) return;
		let securityCheck = meta.title.slug;
		let modalSubmit = e => {
			e.preventDefault();
			let elem = e.target.elements['confirmation'];
			if (elem.value !== securityCheck) {
				elem.focus();
				triggerAnim(elem, s.error);
				return;
			}
			Store.emit(actions.HIDE_MODAL, 'OK');
		};
		let modal = (
			<form class={s.securityModal} onSubmit={modalSubmit}>
				<div class={s.description}>
					You are about to remove <span class={s.appName}>{meta.title.en} | {meta.region.slug.toUpperCase()}</span> from {type}.<br />
					THIS CANNOT BE UNDONE. The app can be redeployed, but it might not been the same as the currently deployed version if any change has been made.<br />
					Please type <span class={s.securityValue}>{securityCheck}</span> in the box below to confirm.<br />
				</div>
				<div class={s.securityInput}><input type="text" name="confirmation" value="" autocomplete="off" /></div>
				<div class={s.buttons}>
					<button type="button" onClick={() => Store.emit(actions.HIDE_MODAL)}>Cancel</button>
					<input type="submit" class={s.confirmDelete} value="I AM SURE, DELETE" />
				</div>
			</form>
		);
		this.setState({ actionsOpen: false });
		Store.emit(actions.SHOW_MODAL, modal, 'SECURITY CHECK', 'securityModal', v => {
			if (v !== 'OK') return;
			resetApp(app, type).catch(err => console.error('error taking down app', err));
		});
	}

	closeWizard() {
		this.setState({ wizard: null });
	}

	activateStartValuesWizard() {
		const wizard = (
			<BasicStartWizard
				mergedData={this.state.merged}
				activateWebedia={this.activateWebediaIdsWizard}
				thundrData={this.props.thundrAppData}
				app={this.state.activeAppData}
				closeWizard={this.closeWizard}
				sheetId={this.state.merged?.dev?.formResponsesSheetId}
			/>
		);

		this.setState({ wizard });
	}

	activateBoltStartValuesWizard() {
		const wizard = (
			<BoltWizard
				mergedData={this.state.merged}
				activateWebedia={this.activateWebediaIdsWizard}
				thundrData={this.props.thundrAppData}
				app={this.state.activeAppData}
				closeWizard={this.closeWizard}
				boltBase
			/>
		);

		this.setState({ wizard });
	}

	activateWebediaIdsWizard() {
		if (this.activeLevel() !== 'titles') {
			return;
		}
		this.setState({ wizard: <WebediaIds title={this.state.activeAppData} closeWizard={this.closeWizard} /> });
	}

	activateMatcher() {
		let titleId;
		if (this.activeLevel() === 'titles') {
			titleId = this.state.activeAppData?.id;
		} else {
			titleId = this.state.activeAppData?.rel?.title;
		}
		if (!titleId) {
			return;
		}
		let pageId = 'matchTitle';
		let href = this.props.thundrAppData?.pages?.matchTitle?.path + '?title=' + titleId;
		route(href, pageId);
	}

	createDistribution() {
		this.setState({ creatingDistribution: true });
		attachDistribution(this.state.activeAppData).then(() => {
			this.setState({ creatingDistribution: false });
		});
	}

	async updateCloudfrontBehaviors() {
		this.setState({ updatingCloudfrontBehaviors: true });
		try {
			// behaviors already updated in invalidation code
			// await updateCloudfrontBehaviors(this.state.merged);
			await invalidateApp(this.state.merged);
			createTooltip('Cloudfront updated and invalidation triggered', { duration: 3000 });
		} catch (err) {
			console.log('Error updating cloudfront', err);
			createTooltip('Error updating cloudfront', { class: 'error', duration: 3000 });
		}
		this.setState({ updatingCloudfrontBehaviors: false });
	}

	async getMessengerCode() {
		let appId = this.state.activeAppData?.id;
		let pageId = accessNested(this.state.activeAppData, 'attributes.meta.messenger.pageId');
		if (this.activeLevel() !== 'apps' || !appId || !pageId) return;
		let resultWindow = window.open('about:blank');

		// Motivational waiting
		let script = document.createElement('script');
		script.text = 'var m=["Please wait :)","","I hope you are having a wonderful day.","","You rock.","","You are the definition of amazing.","","You should give talks about how to be awesome but instead of explaining you would just stand there and people would try to be like you.","","","","Hmmmmm","It has been a while hasn\'t it?","Yeah, that\'s probably broken tbh"],i=0,t=setInterval(function(){i>=m.length?clearInterval(t):document.body.innerHTML+="<br>"+m[i++]},1000)';
		resultWindow.document.body.innerHTML = 'Generating the code...';
		resultWindow.document.body.appendChild(script);

		try {
			const res = await fetch('/misc/generate_messenger_code', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ appId: appId, pageId: pageId })
			});
			const url = await res.text();
			if (!url) {
				throw new Error('No result');
			}
			if (!url.startsWith('http://') && !url.startsWith('https://')) {
				throw new Error('Invalid result url: ' + url);
			}
			resultWindow.location.href = url;
		} catch (err) {
			console.error('messenger code error', err);
			resultWindow.close();
			createTooltip('Error generating the image', { class: 'error', duration: 3000 });
		}
	}

	async setupRedirect() {
		// TODO: use modal and good UX rather than prompt/alert
		if (this.activeLevel() !== 'apps') return;

		let app = this.state.merged;
		let isLive = app?.meta?.live;
		if (isLive) return createTooltip('You can no longer setup a redirection once the app is live', { class: 'error', duration: 3000 });

		// eslint-disable-next-line no-alert
		let url = prompt('Please enter the url to redirect to');
		if (!url) return;
		if (!url.match(/^\S+\.\S+/i)) {
			return createTooltip('The url seems invalid, please check and try again', { class: 'error', duration: 3000 });
		}
		if (!url.match(/^https?:\/\//)) {
			url = 'https://' + url;
		}

		let slugs = {
			studio: app?.meta?.studio?.slug,
			title: app?.meta?.title?.slug,
			alternateTitle: app?.meta?.title?.alternateSlug,
			region: app?.meta?.region?.slug,
		};
		try {
			let response = await fetch('/misc/setup_redirection', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ slugs, url })
			});
			if (!response.ok) {
				let err = await response.json();
				throw err;
			}
		} catch (err) {
			console.error('redirection setup error', err);
			return createTooltip(['Error setting up the redirection', err?.message].filter(e => e).join(':\n'), { class: 'error', duration: 3000 });
		}
		createTooltip('Redirection page in place! Starting invalidation', { duration: 3000 });
		try {
			// Wanted to do an automatic check but the update is not instant with the invalidation etc. so giving wrong results
			await invalidateApp(this.state.merged);
		} catch (err) {
			console.log('Error while invalidating after redirection', err);
		}
	}

	pixelPaxil(updateJSON, jsonSchema, activeJSON, json) {
		const { mergedFull, activeAppData } = this.state;

		let trackingSystem;
		const loadedExternally = !!mergedFull?.apis?.tracking?.loadedExternally;
		const studio = mergedFull?.meta?.studio?.slug;
		const showtimesOneTrust = mergedFull?.apis?.tracking?.oneTrust || {};
		const boltOneTrust = mergedFull?.options?.oneTrustCookieConsent?.snippet;
		const usesOneTrust = showtimesOneTrust.scriptId || showtimesOneTrust.scripts || boltOneTrust;

		if (!loadedExternally && usesOneTrust) {
			trackingSystem = 'oneTrust';
		}
		if (!loadedExternally &&
			mergedFull?.options?.requireUserConsent &&
			mergedFull?.apis?.tracking?.sonyCookies?.popupPushdown) {
			trackingSystem = 'evidon';
		}
		if (loadedExternally) {
			switch (studio) {
				case 'paramountpictures':
				case 'sonypictures':
				case 'sonypicturesclassics':
					trackingSystem = 'evidon';
					break;
				case 'warnerbros':
				case 'universalstudios':
				case 'annapurnapictures':
					trackingSystem = 'oneTrust';
					break;
				default:
					break;
			}
		}

		return Store.emit(
			actions.SHOW_MODAL,
			<PixelPaxilModal
				merged={mergedFull}
				activeData={activeAppData}
				updateJSON={updateJSON}
				jsonSchema={jsonSchema}
				activeJSON={activeJSON}
				json={json}
				trackingSystem={trackingSystem}
			/>,
			'Pixel Paxil™'
		);
	}

	generateLaunchEmail() {
		Store.emit(
			actions.SHOW_MODAL,
			<LaunchEmailGenerator appData={this.state.mergedFull} titleRegions={this.regions} />,
			'Live Email Generator',
			'generate'
		);
	}

	debugKeyboardShortcuts() {
		Store.emit(
			actions.SHOW_MODAL,
			<KeyboardShortcutsHelper />,
			'Keyboard Shortcuts',
			'checkShortcuts'
		);
	}

	gaSetup() {
		let state = this.state;
		let modal = <GASetup appData={state.merged} />;
		Store.emit(actions.SHOW_MODAL, modal, 'GA4 Setup', 'ga4', result => {
			if (!result?.ok) {
				return;
			}
			let isApp = state.activeAppData.type === 'apps';
			let isTitle = state.activeAppData.type === 'titles';
			let isGlobal = !(isTitle || isApp);

			let newData = window.structuredClone(state.activeAppData);
			newData.edited = newData.edited || window.structuredClone(newData.attributes);
			let hasChange = false;
			const ga4 = createNested(newData.edited, 'dev.ga4');
			['email', 'account', !isGlobal && 'property', 'multiRegion'].forEach(p => {
				if (p && result[p] && state.merged?.dev?.ga4?.[p] !== result[p]) {
					ga4[p] = result[p];
					hasChange = true;
				}
			});
			let measurmentPath = 'apis.tracking.powsterGATracking';
			let measurmentName = isGlobal ? 'powsterCustomGAGlobal' : 'powsterCustomGATitle';
			if (state.merged?.dev?.trackingV2) {
				measurmentPath = 'tracking.variables';
				measurmentName = isGlobal ? 'powStudio' : 'powTitle';
			}
			let existing = accessNested(state.merged, `${measurmentPath}.${measurmentName}`);
			let replace = existing !== result.measurementId;
			if (existing && replace) {
				// eslint-disable-next-line no-alert
				replace = confirm(`Replace exising ${measurmentName} (${existing}) with new one (${result.measurementId}) ?`);
			}
			if (replace) {
				const variables = createNested(newData.edited, measurmentPath);
				variables[measurmentName] = result.measurementId;
				hasChange = true;
			}
			if (hasChange) {
				this.saveData({ data: newData });
			}
		});
	}

	lookerSetup() {
		let state = this.state;
		if (!state.merged?.pages?.['bolt-toolkit']) {
			createTooltip('bolt-toolkit needs to be enabled to set up a Looker dashboard', { class: 'error', duration: 3000 });
			return;
		}
		let modal = <LookerSetup appData={state.merged} titleRegions={this.regions} getMerged={this.getMerged} />;
		Store.emit(actions.SHOW_MODAL, modal, 'Looker Studio Dashboard Setup', 'looker', result => {
			if (!result?.ok) {
				return;
			}
			let newData = window.structuredClone(state.activeAppData);
			newData.edited = newData.edited || window.structuredClone(newData.attributes);
			const boltToolkitData = createNested(newData.edited, ['pages', 'bolt-toolkit', 'data']);
			boltToolkitData.GADashboard = result.url;
			this.saveData({ data: newData });
			createTooltip('Dashboard saved', { duration: 3000 });
		});
	}

	createOverride() {
		if (this.state.wizard) {
			return;
		}
		let studio = accessNested(this.state.merged, 'meta.studio.slug');
		let title = accessNested(this.state.merged, 'meta.title.slug');
		let region = accessNested(this.state.merged, 'meta.region.slug');
		let modal = <CreateOverride studio={studio} title={title} region={region} />;
		Store.emit(actions.SHOW_MODAL, modal, 'Create an override', 'override', result => {
			// Modal closed
			// console.log('Override creation result:', result);
		});
	}

	massDeploy() {
		let modal = <MassDeploy getMerged={this.getMerged} activeAppData={this.state.activeAppData} />;
		Store.emit(actions.SHOW_MODAL, modal, 'Advanced Deploy', 'massdeploy');
	}

	convertToTrackingV2() {
		// alert('Not implemented yet');
		createTooltip('Not implemented yet', { duration: 3000 });
		return;

		// let state = this.state;
		// let newData = window.structuredClone(state.activeAppData);
		// newData.edited = newData.edited || window.structuredClone(newData.attributes);
		// if (newData.edited.dev?.trackingV2) {
		// 	return;
		// }

		// createNested(newData.edited, 'dev');
		// newData.edited.dev.trackingV2 = true;

		// let variables = createNested(newData.edited, 'tracking.variables');
		// let existingGA = state.merged.apis?.tracking?.powsterGATracking;
		// if (state.merged.tracking?.variables?.powTitle !== existingGA?.powsterCustomGATitle) {
		// 	variables.powTitle = existingGA?.powsterCustomGATitle;
		// }
		// if (state.merged.tracking?.variables?.powStudio !== existingGA?.powsterCustomGAGlobal) {
		// 	variables.powStudio = existingGA?.powsterCustomGAGlobal;
		// }
		// console.log('Updating to', newData);
		// this.saveData(newData);
	}

	toggleDarkMode() {
		let darkMode = !this.state.darkMode;
		this.setState({ darkMode });
		try {
			localStorage.setItem(STORAGE_DARK_MODE, darkMode.toString());
		} catch (e) {}
	}

	async openQuickNavigation() {
		if (this.state.wizard) {
			return;
		}
		await quickNavigation({
			appData: this.state.mergedFull,
			// (the baseSchema should be set at this level rather than using window.currentSchema)
			schema: window.currentSchema,
			// Add extra dot to get inside object values
			navigate: path => window.setActivePath?.(path + '.')
		});
	}

	async openActions() {
		if (this.state.wizard) {
			return;
		}
		const list = [
			// !this.state.merged?.dev?.trackingV2 && { value: 'useTrackingV2', name: 'Convert to Tracking V2', action: this.convertToTrackingV2 },
			{ value: 'override', name: 'Create an override', keybinding: ['ctrl', 'O'], action: this.createOverride },
			{ value: 'darkMode', name: 'Toggle dark mode (beta)', action: this.toggleDarkMode },
			{ value: 'massDeploy', name: 'Advanced deploy', keybinding: [['ctrl', '.'], ['M']], action: this.massDeploy },
			{ value: 'generateLaunchEmail', name: 'Generate live email', action: this.generateLaunchEmail },
			{ value: 'checkShortcuts', name: 'Keyboard shortcuts helper', keybinding: ['ctrl', 'shift', 'H'], action: this.debugKeyboardShortcuts },
			!!this.state.activeAppData?.edited && { value: 'save', name: 'Save', keybinding: ['ctrl', 'S'], action: this.save },
			...this.extraActions
		].filter(e => e && !currentValue(e.hidden)).sort((a, b) => a.name.localeCompare(b.name));
		let result = await quickInput.show({ list });
		result?.action?.call(this);
	}

	updateData() {
		// let activeLevel = this.activeLevel();
		// if (activeLevel === 'apps' || activeLevel === 'titles') {
		// 	let titleData = this.state.activeAppData;
		// 	if (activeLevel === 'apps') {
		// 		titleData = props.titles[this.parentId];
		// 	}
		// 	let matched = accessNested(titleData, 'uiExtras.matched');
		// 	let identifier = props.activeLevel + ':' + props.activeLevelId;
		// 	if (this.requestedProviderTitles !== identifier) {
		// 		this.requestedProviderTitles = identifier;
		// 		this.setState({ matched: null });
		// 		Store.emit(actions.UPDATE_PROVIDER_TITLES, titleData.id, true);
		// 	}
		// 	if (matched !== state.matched) {
		// 		this.setState({ matched: matched });
		// 	}
		// }

		let merged = this.state.merged;
		setPageTitle(
			this.activeLevel() === 'bases' ? 'Base' : '',
			accessNested(merged, 'meta.title.en'),
			(accessNested(merged, 'meta.region.slug') || '').toUpperCase(),
			accessNested(merged, 'meta.studio.name'),
			'Edit', 'Apps'
		);
	}

	makeWebediaIdsAnArray() {
		const currentValue = this.state.merged?.meta?.title?.webediaIds;
		if (!currentValue || Array.isArray(currentValue)) return;

		const data = this.state.activeAppData;
		const newAppData = cloneDeep(data.attributes);
		newAppData.meta.title.webediaIds = typeof currentValue === 'object' ? Object.values(currentValue) : [currentValue];

		Store.emit(actions.UPDATE_EDITED_APP_DATA, data, newAppData);
	}

	activeLevel() {
		return this.state.activeAppData?.type;
	}

	async getAppsList() {
		let activeLevel = this.activeLevel();
		if (activeLevel === 'apps') {
			return [this.state.merged];
		}
		if (activeLevel === 'titles') {
			let appIds = this.regions.map(r => r.app).filter(e => e);
			await Store.emit('REQUEST_MISSING', appIds.flatMap(id => {
				let rels = this.props.list.apps[id]?.rel || {};
				return [
					{ type: 'app', id },
					...Object.entries(rels).map(([type, id]) => ({ type, id }))
				];
			}));
			let loadedApps = Store.get().apps;
			return appIds.map(id => this.getMerged(loadedApps[id])?.merged);
		}
		return [];
	}

	prepareLevel(props) {
		const { list, apps, titles, regions, studios, studioRegions, bases, query } = props;

		let missingData = [];
		let checkMissing = (type, id) => {
			let data = list[type + 's']?.[id];
			[[type, id]].concat(Object.entries(data?.rel || {})).forEach(([type, id]) => {
				if (!props[type + 's'][id]) {
					missingData.push({ type, id });
				}
			});
		};

		this.title = '';
		this.studios = [];
		this.bases = [];
		this.regions = [];

		let activeAppData;
		let extraLevels = [];
		if (query.app) {
			let rels = list.apps[query.app]?.rel;
			if (!rels) {
				this.setState({ error: 'requested app not found' });
				return false;
			}
			checkMissing('app', query.app);
			activeAppData = apps[query.app];

			this.title = list.titles[rels.title]?.en;
			this.bases = this.getTitleBases(rels.title, rels.base);
			this.studios = this.getTitleStudios(rels.base, rels.title, rels.studio);
			this.regions = this.getTitleRegions(rels.base, rels.studio, rels.title, rels.region);
		} else if (query.title) {
			let rels = list.titles[query.title]?.rel;
			if (!rels) {
				this.setState({ error: 'requested title not found' });
				return false;
			}
			checkMissing('title', query.title);
			activeAppData = titles[query.title];
			this.title = list.titles[query.title]?.en;
			this.bases = this.getTitleBases(query.title, rels.base);
			this.studios = this.getTitleStudios(rels.base, query.title, query.studio);
			if (query.studio) {
				checkMissing('studio', query.studio);
				let studio = studios[query.studio];
				extraLevels.push(studio);
				this.regions = this.getTitleRegions(
					rels.base,
					query.studio,
					query.title
				);
			}
		} else if (query.studio_region) {
			let rels = list.studioRegions[query.studio_region]?.rel;
			if (!rels) {
				this.setState({ error: 'requested studio-region not found' });
				return false;
			}
			checkMissing('studioRegion', query.studio_region);
			activeAppData = studioRegions[query.studio_region];
			this.title = list.studios[rels.studio]?.name;
			this.bases = this.getStudioBases(rels.studio, rels.base);
			this.regions = this.getStudioRegions(rels.base, rels.studio, rels.region);
		} else if (query.studio) {
			let rels = list.studios[query.studio]?.rel;
			if (!rels) {
				this.setState({ error: 'requested studio not found' });
				return false;
			}
			checkMissing('studio', query.studio);
			activeAppData = studios[query.studio];
			this.title = list.studios[query.studio]?.name;
			this.bases = this.getStudioBases(query.studio, rels.base);
			this.regions = this.getStudioRegions(rels.base, query.studio);
		} else if (query.region) {
			let rels = list.regions[query.region]?.rel;
			if (!rels) {
				this.setState({ error: 'requested region not found' });
				return false;
			}
			checkMissing('region', query.region);
			activeAppData = regions[query.region];
			this.title = 'Base Data';
			this.bases = this.getBases(rels.base);
			this.regions = this.getRegions(rels.base, query.region);
		} else if (query.base) {
			if (!list.bases[query.base]) {
				this.setState({ error: 'requested base not found' });
				return false;
			}
			checkMissing('base', query.base);
			activeAppData = bases[query.base];
			this.title = 'Base Data';
			this.bases = this.getBases(query.base);
			this.regions = this.getRegions(query.base);
		}

		if (missingData.length) {
			this.setState({ loadingData: true });
			// requestMissing(missingData);
			Store.emit('REQUEST_MISSING', missingData);
			return false;
		}

		if (!activeAppData) {
			this.setState({ loadingData: false, error: 'requested object not found' });
			return false;
		}

		let newState = {
			loadingData: false,
			error: null,
			activeAppData,
			...this.getMerged(activeAppData, query.branch, extraLevels),
		};
		this.setState(newState);
		this.scheduleUpdateCheck(0);

		return true;
	}

	getActiveKeys(state = this.state) {
		let list = Object.entries(state?.merged?.meta?.keys || {}).map(([key, value]) => {
			if (!value) return;
			let tooltip = key.replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5').toLowerCase();
			if (typeof value === 'string') return { key, tooltip, display: value };
			if (typeof value === 'object' && !Array.isArray(value)) return { key, tooltip, ...value };
		});
		const appData = state?.mergedFull;
		const status = (status) => joinClasses(s.status, s[status]);
		const link = (to, text) => <button class={s.content} onClick={() => window.setActivePath(to)}>{text}</button>;
		if (appData?.dev?.trackingV2) {
			list.push({ key: 'trackingV2', display: 'PXL2', tooltip: 'Tracking V2' });
			const level = this.activeLevel();
			if (level === 'apps' || level === 'studio_regions') {
				if (appData?.tracking?.variables?.disablePowsterGA) {
					list.push({ key: 'disablePowsterGA', display: link('tracking.variables.disablePowsterGA', 'Powster GA tracking'), tooltip: 'Powster GA tracking disabled', class: status('disabled') });
				} else {
					list.push(
						...['powStudio', level === 'apps' && 'powTitle'].filter(e => e).map(id => {
							const value = appData?.tracking?.variables?.[id];
							return {
								key: id,
								display: link(`tracking.variables.${id}`, id),
								tooltip: value == null ? 'Item disabled' : value || 'Not set',
								class: status(value == null ? 'disabled' : (value ? 'valid' : 'error')),
							};
						})
					);
				}
			}
			if (level === 'apps') {
				if (!appData?.pages?.['bolt-toolkit']) {
					list.push({ key: 'boltToolkit', display: link('pages.bolt-toolkit', 'Bolt Toolkit'), tooltip: 'Bolt Toolkit not enabled', class: status('disabled') });
				} else {
					const looker = appData?.pages?.['bolt-toolkit']?.data?.GADashboard;
					list.push({
						key: 'GADashboard',
						display: link('pages.bolt-toolkit.data.GADashboard', 'Looker'),
						class: status(looker == null ? 'disabled' : (looker ? 'valid' : 'error')),
						tooltip: looker == null ? 'Item disabled' : (looker ? null : 'Not set'),
					});
				}
			}
		}
		return list.filter(e => e?.display);
	}

	getMerged(obj, branch, extraLevels, excludedLevels) {
		if (!obj) {
			return null;
		}
		const relsOrder = ['base', 'region', 'studio', 'studioRegion', 'title', 'app'];
		const store = Store.get();
		let list = [obj].concat(
			extraLevels,
			Object.keys(obj.rel || {}).sort((a, b) => relsOrder.indexOf(b) - relsOrder.indexOf(a)).map(key => {
				let from = store[key + 's'];
				let id = obj.rel[key];
				return from?.[id];
			})
		).filter(e => e && !excludedLevels?.includes(e.type));
		let usedBranch;
		if (branch) {
			let branchData = accessNested(obj, ['attributes', 'dev', 'branches', branch]);
			let editedBranchData = accessNested(obj, ['edited', 'dev', 'branches', branch]);
			let activeBranchData = editedBranchData || branchData;
			if (activeBranchData?.content) {
				usedBranch = { id: branch, type: 'branches', name: activeBranchData.name, attributes: activeBranchData.content };
				list.unshift(usedBranch);
			}
		}
		let mergedFull = defaultsDeep(...list.map(this.getActiveData));
		let merged = deleteNulls(structuredClone(mergedFull), true);
		let mergedPaths = objectPaths(mergedFull, list.map(e => ({ title: e.type, object: this.getActiveData(e) })));

		return {
			branch: usedBranch,
			merged,
			mergedFull,
			mergedPaths
		};
	}

	getActiveData(appData) {
		let value = appData.edited || appData.attributes;
		return value?.toJS ? value.toJS() : value;
	}

	getBases(activeId) {
		return Object.values(this.props.list.bases).map(base => ({
			id: base.id,
			name: baseNames[base.id],
			active: base.id == activeId,
			queryString: resetQS({ base: base.id })
		}));
	}
	getTitleBases(titleId, activeId) {
		// Uses all titles with the same slug (bit icky but it's how we identify "same" titles across bases)
		let srcSlug = this.props.list.titles[titleId]?.slug;

		// Have to loop over apps to get the studio value (maybe we should we have a title_studio level?)
		let basesMapping = Object.values(this.props.list.apps).reduce((obj, app) => {
			if (obj[app.rel.base]) {
				return obj;
			}
			let title = this.props.list.titles[app.rel.title];
			if (title?.slug === srcSlug) {
				obj[app.rel.base] = { title: app.rel.title, studio: app.rel.studio };
			}
			return obj;
		}, {});

		return Object.values(this.props.list.bases).filter(base => basesMapping[base.id]).map(base => ({
			id: base.id,
			name: baseNames[base.id],
			active: base.id == activeId,
			queryString: resetQS(basesMapping[base.id])
		}));
	}
	getStudioBases(studioId, activeId) {
		// Uses all studios with the same slug (c.f. getTitleBases)
		let srcSlug = this.props.list.studios[studioId]?.slug;

		let basesMapping = Object.values(this.props.list.studios).reduce((obj, studio) => {
			if (obj[studio.rel.base]) {
				return obj;
			}
			if (studio.slug === srcSlug) {
				obj[studio.rel.base] = { studio: studio.id };
			}
			return obj;
		}, {});

		return Object.values(this.props.list.bases).filter(base => basesMapping[base.id]).map(base => ({
			id: base.id,
			name: baseNames[base.id],
			active: base.id == activeId,
			queryString: resetQS(basesMapping[base.id])
		}));
	}

	getTitleStudios(baseId, titleId, activeId) {
		let studiosMapping = Object.values(this.props.list.apps).reduce((obj, app) => {
			if (app.rel.base == baseId && app.rel.title == titleId) {
				obj[app.rel.studio] = true;
			}
			return obj;
		}, {});

		return sortBySlug(Object.values(this.props.list.studios).filter(studio => studiosMapping[studio.id]).map(studio => ({
			id: studio.id,
			slug: studio.slug,
			active: studio.id == activeId,
			queryString: resetQS({ studio: studio.id, title: titleId }),
			parentQueryString: resetQS({ studio: studio.id })
		})));
	}

	getRegions(baseId, activeId) {
		return [
			{
				id: 0,
				slug: 'ALL',
				active: !activeId,
				queryString: resetQS({ base: baseId })
			}
		].concat(
			sortBySlug(Object.values(this.props.list.regions).filter(region => region.rel.base == baseId).map(region => ({
				id: region.id,
				slug: region.slug,
				active: region.id == activeId,
				queryString: resetQS({ region: region.id })
			})))
		);
	}
	getTitleRegions(baseId, studioId, titleId, activeId) {
		let regionsMapping = Object.values(this.props.list.apps).reduce((obj, app) => {
			if (app.rel.base == baseId && app.rel.title == titleId && app.rel.studio == studioId) {
				obj[app.rel.region] = app.id;
			}
			return obj;
		}, {});
		return [
			{
				id: 0,
				slug: 'ALL',
				active: !activeId,
				queryString: resetQS({ title: titleId, studio: studioId })
			}
		].concat(
			sortBySlug(Object.values(this.props.list.regions).filter(region => regionsMapping[region.id]).map(region => ({
				id: region.id,
				slug: region.slug,
				active: region.id == activeId,
				queryString: resetQS({ app: regionsMapping[region.id] }),
				app: regionsMapping[region.id]
			})))
		);
	}
	getStudioRegions(baseId, studioId, activeId) {
		let regionsMapping = Object.values(this.props.list.studioRegions).reduce((obj, studioRegion) => {
			if (studioRegion.rel.base == baseId && studioRegion.rel.studio == studioId) {
				obj[studioRegion.rel.region] = studioRegion.id;
			}
			return obj;
		}, {});
		return [
			{
				id: 0,
				slug: 'ALL',
				active: !activeId,
				queryString: resetQS({ studio: studioId })
			}
		].concat(
			sortBySlug(Object.values(this.props.list.regions).filter(region => regionsMapping[region.id]).map(region => ({
				id: region.id,
				slug: region.slug,
				active: region.id == activeId,
				queryString: resetQS({ studio_region: regionsMapping[region.id] })
			})))
		);
	}

	getWarnings() {
		let state = this.state;
		let activeLevel = this.activeLevel();
		let warnings = [];

		// Error for matched movies without format
		// if (activeLevel === 'titles' || activeLevel === 'apps') {
		// 	let matched = state.matched || [];
		// 	if (!matched.length) {
		// 		let titleId = isApp ? this.parentId : activeLevelId;
		// 		warnings.push({
		// 			level: WARNING_LEVELS.WARNING,
		// 			content: (
		// 				<div class={s.warning}>
		// 					No movies matched<br />
		// 					<Link pageId="matchTitle" queryString={resetQS({ title: titleId })} renderCustomChild>Go to the movie matcher</Link>
		// 				</div>
		// 			)
		// 		});
		// 	} else {
		// 		let noFormat = matched.find(movie => !movie.attributes.format_id);
		// 		if (noFormat) {
		// 			let titleId = isApp ? this.parentId : activeLevelId;
		// 			warnings.push({
		// 				level: WARNING_LEVELS.ERROR,
		// 				content: (
		// 					<div class={s.error}>
		// 						At least one matched movie doesn't have a format assigned.<br />
		// 						<Link pageId="matchTitle" queryString={resetQS({ title: titleId })} renderCustomChild>Go to the movie matcher</Link>
		// 					</div>
		// 				)
		// 			});
		// 		}
		// 	}
		// }

		if (activeLevel === 'apps') {
			const base = state.activeAppData?.rel?.base;
			if (base === BOLT_BASE) {
				const showtimesPages = Object.entries(state.merged?.pages || {}).filter(([, p]) => p?.type === 'showtimes');
				const movieIdInfo = Store.get().movieIdInfo;

				showtimesPages.forEach(([key, page]) => {
					const movieIds = page.data?.movieId;
					fetchMovieDataIfNecessary(movieIds);
					const list = arrayUnique(commaSeparatedList(movieIds));
					const movieIdData = list.map(id => ({ id, data: movieIdInfo[id] }));
					const isLoading = (data) => !data || data === 'loading';
					const error = movieIdData.find(({ data }) => !isLoading(data) && (data === 'error' || !data?.movieSlug));
					if (error) {
						warnings.push({
							level: WARNING_LEVELS.ERROR,
							content: (
								<div class={s.error}>
									Movie ID <code>{error.id}</code> does not exist for page <strong>{key}</strong><br /><br />
									<button class={s.edit} onClick={() => window.setActivePath?.(`pages.${key}.data.movieId`)}>Edit</button>
								</div>
							)
						});
					} else {
						const noScreenings = movieIdData.find(({ data }) => !isLoading(data) && !data?.numScreenings);
						if (noScreenings) {
							warnings.push({
								level: WARNING_LEVELS.WARNING,
								content: (
									<div class={s.warning}>
										Movie <strong>{noScreenings.data.movieSlug}</strong> has no screenings (for page <strong>{key}</strong>)<br /><br />
										<button class={s.edit} onClick={() => window.setActivePath?.(`pages.${key}.data.movieId`)}>Edit</button>
									</div>
								)
							});
						} else if (movieIdData.some(({ data }) => isLoading(data))) {
							warnings.push({
								level: WARNING_LEVELS.WARNING,
								content: (
									<div class={s.warning}>
										Movie info not yet available for page <strong>{key}</strong>
									</div>
								)
							});
						}
					}
				});
			}

			let headerScripts = accessNested(state, 'merged.doc.headerScripts') || {};
			// Check for dev version of sony pixels
			let devDTM = Object.keys(headerScripts).find(key => {
				let script = headerScripts[key];
				return typeof script === 'string' && script.match(/\/\/assets\.adobedtm\.com\/.+development/);
			});
			if (devDTM) {
				warnings.push({
					level: WARNING_LEVELS.WARNING,
					content: (
						<div class={s.warning}>
							Using a development Sony DTM tag<br /><br />
							<button class={s.edit} onClick={() => window.setActivePath?.('doc.headerScripts.' + devDTM)}>Edit</button>
						</div>
					)
				});
			}
			const powsterGATracking = accessNested(state, 'merged.apis.tracking.powsterGATracking');
			const trackingV2 = accessNested(state, 'merged.dev.trackingV2');
			const trackingV2Vars = accessNested(state, 'merged.tracking.variables');
			if (powsterGATracking || trackingV2) {
				let isMissingID = trackingV2
					? !trackingV2Vars?.disablePowsterGA && (!trackingV2Vars?.powTitle || !trackingV2Vars?.powStudio)
					: !powsterGATracking.powsterCustomGATitle || !powsterGATracking.powsterCustomGAGlobal;
				if (isMissingID) {
					const onButtonClick = trackingV2 ? () => window.setActivePath?.('tracking.variables.') : () => window.setActivePath?.('apis.tracking.powsterGATracking.');
					warnings.push({
						level: WARNING_LEVELS.WARNING,
						content: (
							<div class={s.warning}>
								Powster GA Tracking is enabled on this site, but a title/studio tracking ID has not been set. Please ensure you set the correct title/studio tracking IDs<br /><br />
								<button class={s.edit} onClick={onButtonClick}>Add Tag IDs</button>
							</div>
						)
					});
				} else if (!trackingV2) {
					warnings.push({
						level: WARNING_LEVELS.INFO,
						content: (
							<div class={s.info}>
								Powster GA Tracking is enabled. Please ensure you have correctly setup a property within GA for this title, and within GA you have applied the region dimension to the property, and have setup the relevant region views for your property<br /><br />
								<a class={s.edit} target="_blank" href="https://analytics.google.com/analytics/web/">Configure Google Analytics</a>
							</div>
						)
					});
				}
			}
			const { oneTrustCookieConsent, enableEnsighten } = state.merged?.options || {};
			if (oneTrustCookieConsent && enableEnsighten) {
				warnings.push({
					level: WARNING_LEVELS.WARNING,
					content: (
						<div class={s.warning}>
							<strong>Both Ensighten and OneTrust are enabled</strong><br /><br />
							The OneTrust ID for Ensighten should be set as "Ensighten: OneTrust GUID" in Features, not in the separate OneTrust configuration object.<br /><br />
							<button class={s.edit} onClick={() => window.setActivePath?.('options.oneTrustCookieConsent')}>Update OneTrust configuration</button>
						</div>
					)
				});
			}
		} else if (activeLevel === 'titles') {
			const webediaIds = state.merged?.meta?.title?.webediaIds;
			if (webediaIds && !Array.isArray(webediaIds)) {
				warnings.push({
					level: WARNING_LEVELS.ERROR,
					content: (
						<div class={s.error}>
							If set, Webedia IDs should be an array<br /><br />
							<button class={s.edit} onClick={() => window.setActivePath?.('meta.title.webediaIds')}>See</button>, <button class={s.fix} onClick={this.makeWebediaIdsAnArray}>Auto-Fix</button>
						</div>
					)
				});
			}
		}

		return warnings;
	}

	renderActions() {
		let state = this.state;
		let isApp = this.activeLevel() === 'apps';
		if (!isApp || !state.merged || !state.merged.meta) {
			return;
		}
		let meta = state.merged.meta;
		let hasRedirection = !!(state.merged.redirection || state.merged.geofencing);
		let qs = hasRedirection ? '' : '';
		let liveButton = (mode) => {
			let title = undefined;
			let hasWarning = state.linksWarnings[mode];
			if (hasWarning) {
				title = 'This version seems to not be live';
			}
			return <Link href={this.getUrl(mode, meta) + qs} target="_blank" class={s.button + condClass(hasWarning, s.warning)} title={title} renderCustomChild>{mode}</Link>;
		};
		let deleteButton = (mode) => {
			return <button type="button" class={s.button} onClick={() => this.delete(mode)}>{mode}</button>;
		};
		return (
			<div class={joinClasses(s.actions, state.actionsOpen && s.open)} ref={e => e && (this.$actions = e)} key="actions">
				<div class={s.icon} onClick={() => this.setState({ actionsOpen: !state.actionsOpen })}>⚙️</div>
				<div class={s.content}>
					<div class={s.category}>
						<h3>Live links</h3>
						<div class={s.inner}>
							<div class={s.buttons}>
								{liveButton('demo')}
								{liveButton('stage')}
								{liveButton('prod')}
							</div>
						</div>
					</div>
					<div class={s.category}>
						<input type="checkbox" id="debug-expander" />
						<label for="debug-expander">Debug</label>
						<div class={s.inner}>
							<div class={s.buttons}>
								<button type="button" class={s.button} onClick={this.clearCache}>Clear build cache</button>
								<button type="button" class={s.button} onClick={this.invalidate}>Invalidate</button>
								<button type="button" class={joinClasses(s.button, s.stopWatchers)} onClick={this.stopWatchers}>Stop watchers</button>
								<button type="button" class={joinClasses(s.button, s.clearBuildQueue)} onClick={this.clearBuildQueue}>Clear build queue</button>
							</div>
						</div>
					</div>
					<div class={joinClasses(s.category, s.danger)}>
						<input type="checkbox" id="takedown-expander" />
						<label for="takedown-expander">Take down</label>
						<div class={s.inner}>
							<div class={s.buttons}>
								{deleteButton('demo')}
								{deleteButton('stage')}
								{deleteButton('prod')}
							</div>
						</div>
					</div>
					<div class={joinClasses(s.category, s.danger)}>
						<input type="checkbox" id="deletion-expander" />
						<label for="deletion-expander">Deletion</label>
						<div class={s.inner}>
							<div class={s.buttons}>
								<button type="button" class={s.button} onClick={this.deleteApp}>Delete app</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render(props, state) {
		let { activeAppMode } = props;
		let { activeAppData, creatingDistribution, updatingCloudfrontBehaviors } = state;
		// if (state.loadingData) {
		// 	return <div class={s.fullLoading}>Loading Data...</div>;
		// }
		if (state.error) {
			let text = 'ERROR';
			if (typeof state.error === 'string') {
				text += ': ' + state.error;
			}
			return <div class={s.fullError}>{text}</div>;
		}
		if (state.wizard) {
			return state.wizard;
		}

		let activeLevel = this.activeLevel();

		let warnings = this.getWarnings();
		let warningLevel = Math.max.apply(Math, [0].concat(warnings.map(w => w.level))) || 0;
		let warningIcon = ['🤘', 'ℹ️', '⚠️', '🚫'][warningLevel];
		let levelClass = ['nothing', 'info', 'warning', 'error'][warningLevel];
		let warningTitle = warningLevel === 0 ? 'No errors / warnings' : null;

		let content = <div class={s.fullLoading}>Loading Data...</div>;
		let sidebar;
		if (!state.loadingData) {
			const isApp = activeLevel === 'apps';
			const isTitle = activeLevel === 'titles';
			const updateCloudfrontEnabled = isApp && !updatingCloudfrontBehaviors;
			let createDistribEnabled = isApp && !accessNested(activeAppData, 'attributes.dev.cloudfrontID') && !creatingDistribution;
			let createDistribClasses = joinClasses(s.createDistrib, creatingDistribution && s.loading);
			let createDistribLabel = [
				<div class={s.loadingIcon} />,
				'Create Distribution'
			];
			const base = state.activeAppData?.rel?.base;
			let startValuesWizard = { id: 'startValues', activate: this.activateStartValuesWizard, label: 'Set Form Data', enabled: isApp };
			if (base === BOLT_BASE) {
				startValuesWizard.activate = this.activateBoltStartValuesWizard;
				startValuesWizard.label = 'Bolt Wizard';
			}
			const trackingTitleSet = !!accessNested(state.merged, 'tracking.variables.powTitle');
			const hasGA4Data = ['account', 'property', 'email'].every(key => state.merged?.dev?.ga4?.[key]);
			const lookerDashboardCanBeSetUp = trackingTitleSet && hasGA4Data;
			const lookerDashboard = accessNested(state.merged, 'pages.bolt-toolkit.data.GADashboard');
			const lookerDashboardWithAccountChooser = lookerDashboard && `https://accounts.google.com/AccountChooser?continue=${encodeURIComponent(lookerDashboard)}&email=${state.merged?.dev?.ga4?.email || ''}`;
			let wizards = [
				// { id: 'setUpBolt', activate: this.setUpBolt, label: 'Set up Bolt!', visible: isApp, enabled: accessNested(state.merged, 'dev.defaultBase') !== 'bolt' },
				startValuesWizard,
				{ id: 'createDistribution', activate: this.createDistribution, label: createDistribLabel, visible: createDistribEnabled, class: createDistribClasses },
				{ id: 'updateCloudfrontBehaviors', activate: this.updateCloudfrontBehaviors, label: 'Update Cloudfront', visible: isApp, enabled: updateCloudfrontEnabled },
				{ id: 'webediaIds', activate: this.activateWebediaIdsWizard, label: 'Set Webedia Ids', visible: isTitle },
				{ id: 'matcher', activate: this.activateMatcher, label: 'Match Movie IDs', visible: base === SHOWTIMES_BASE && (isTitle || isApp) },
				{ id: 'messenger', activate: this.getMessengerCode, label: 'Messenger Code', visible: !!(isApp && accessNested(state.merged, 'meta.messenger.pageId')) },
				{ id: 'redirect', activate: this.setupRedirect, label: 'Set Up Redirection', visible: isApp, enabled: !accessNested(state.merged, 'meta.live') },
				{ id: 'pixelPaxil', activate: this.pixelPaxil, label: 'Pixel Paxil™', visible: !state.merged?.dev?.trackingV2 },
				{ id: 'generateLaunchEmail', activate: this.generateLaunchEmail, label: 'Generate Live Email', visible: isApp },
				{ id: 'gaSetup', activate: this.gaSetup, label: 'GA4 setup', visible: base === BOLT_BASE },
				{ id: 'lookerSetup', activate: this.lookerSetup, label: 'Looker setup', visible: lookerDashboardCanBeSetUp && !lookerDashboard },
				{ id: 'viewLookerDashboard', link: lookerDashboardWithAccountChooser, label: 'View Looker Dashboard', visible: !!lookerDashboard },
				{ id: 'override', activate: this.createOverride, label: 'Override...', visible: onLocal },
			];

			content = (
				<div class={s.editors} key="editors">
					<AppDataEditor
						query={props.query}
						wizards={wizards}
						data={state.activeAppData}
						devTypes={props.devTypes}
						merged={state.merged}
						mergedFull={state.mergedFull}
						mergedPaths={state.mergedPaths}
						getMerged={this.getMerged}
						activeType={activeLevel}
						checkUpdates={this.checkUpdates}
						branchData={this.state.branch}
						thundrAppData={props.thundrAppData}
						saveData={this.saveData}
						showConflicts={this.showConflicts}
						saving={state.saving}
						registerActions={this.registerActions}
					/>
				</div>
			);
			sidebar = (
				<InfoSidebar
					myApps={props.myApps}
					data={state.activeAppData}
					activeLevel={activeLevel}
					getAppsList={this.getAppsList}
					merged={state.merged}
					getMerged={this.getMerged}
					branch={this.state.branch}
					saveData={this.saveData}
					warningLevel={warningLevel}
					activeAppMode={activeAppMode}
					key="sidebar"
				/>
			);
		}
		return (
			<div class={joinClasses(s.wrapper, this.state.darkMode && 'dark-mode')}>
				<div class={s.container} onClickCapture={this.onContainerClick}>
					<div class={s.icons} key="icons">
						<div class={joinClasses(s.warnings, s.openOnHover, s[levelClass])} key="warnings">
							<div class={s.icon} data-emoji={warningIcon} title={warningTitle} />
							<div class={s.content}>
								{warnings.map(w => w.content)}
							</div>
						</div>
						{this.renderActions()}
					</div>
					<header class={s.header} key="header">
						<div class={joinClasses(s.bases, this.bases.length <= 1 && s.hidden)}>
							{this.bases.map(base => (
								<Link class={joinClasses(s.base, base.active && s.active)} pageId="editApp" queryString={base.queryString} renderCustomChild key={base.id}>
									{base.name || base.id}
								</Link>
							))}
						</div>
						<h2 class={joinClasses(s.studios, this.studios.length > 1 && s.multiple)}>
							{this.studios.map(studio => (
								<Link class={joinClasses(s.studio, studio.active && s.active)} pageId="editApp" queryString={studio.active ? studio.parentQueryString : studio.queryString} renderCustomChild key={studio.id}>
									{studio.slug}
								</Link>
							))}
						</h2>
						<h1 class={s.mainTitle}>{this.title}</h1>
						<div class={s.regionsList}>
							{this.regions.map(region => (
								<Link class={joinClasses(s.regionLink, region.active && s.active)} pageId="editApp" queryString={region.queryString} renderCustomChild key={region.id}>
									{region.slug}
								</Link>
							))}
						</div>
						<ul class={s.keys}>
							{this.getActiveKeys().map((data) => (
								<li class={joinClasses(s[data.key], data.class)} key={data.key}>
									{typeof data.display === 'string' ? <span class={s.content} key="content">{data.display}</span> : data.display}
									{!!data.tooltip && <span class={s.tooltip} key="tooltip">{data.tooltip}</span>}
								</li>
							))}
						</ul>
					</header>
					{content}
				</div>
				{sidebar}
			</div>
		);
	}
}
