import { h, Component } from 'preact';
import * as jsonpatch from 'fast-json-patch';
import isEqual from 'lodash/isEqual';
import Store from 'store/store';
import * as actions from 'store/actions';
import pasteData from 'utils/pasteData';
import createTooltip from 'utils/tooltip';
import { accessNested, removeClass, triggerAnim } from 'utils/utils';
import * as keyboardShortcuts from 'utils/keyboardShortcuts';

import DiffList from 'components/pages/apps/diffList';

import s from 'components/pages/apps/modals/viewChangesModal.sss';

export default class ViewChangesModal extends Component {
	constructor(props) {
		super(props);
		const { data } = props;

		this.state = {
			edited: data.edited,
			diff: this.calculateDiff(data)
		};

		this.export = this.export.bind(this);
		this.import = this.import.bind(this);
		this.close = this.close.bind(this);
	}

	componentDidMount() {
		this.base.querySelector('input, textarea, button')?.focus();
		this.shortcutsHandler = keyboardShortcuts.register({
			Escape: { action: this.close },
		});
		// Preact reuses the component sometimes, clear up previous successes
		this.base.querySelectorAll(`.${s.copied}`).forEach(e => removeClass(e, s.copied));
	}

	componentWillUnmount() {
		this.shortcutsHandler?.stop();
	}

	calculateDiff(data) {
		return data.edited ? jsonpatch.compare(data.attributes, data.edited) : [];
	}

	export(e) {
		const text = JSON.stringify(this.state.diff, null, '\t');
		const input = document.createElement('textarea');
		Object.assign(input.style, { opacity: 0, position: 'fixed', left: '-9px', top: '-9px', width: '1px', height: '1px' });
		document.body.append(input);
		input.value = text;
		input.select();
		try {
			document.execCommand('copy');
			triggerAnim(e.target, s.copied);
		} catch (err) { /* Copy fails silently */ }
		input.remove();
	}

	async import() {
		try {
			const rawPaste = await pasteData();
			if (!rawPaste) return;
			const newDiff = JSON.parse(rawPaste);
			// const error = jsonpatch.validate(newDiff);
			// if (error) throw error;
			const current = this.props.data.attributes;
			const edited = this.state.edited;
			const newEdited = jsonpatch.applyPatch(edited || current, newDiff, false, false).newDocument;
			if (edited) {
				let conflicts = newDiff.filter(elem => {
					let path = elem.path.slice(1).split('/').map(e => e.replace(/~1/g, '/').replace(/~0/g, '~'));
					let currentValue = accessNested(current, path);
					let editedValue = accessNested(edited, path);
					let newValue = accessNested(newEdited, path);
					return !isEqual(newValue, editedValue) && !isEqual(currentValue, editedValue);
				});
				if (conflicts.length && !confirm('These changes would override some of the current unsaved data, do you want to proceed?')) {
					return;
				}
			}
			Store.emit(actions.UPDATE_EDITED_APP_DATA, this.props.data, newEdited);
			this.setState({ edited: newEdited, diff: this.calculateDiff({ ...this.props.data, edited: newEdited }) });
		} catch (e) {
			createTooltip('Failed to import changes data', { class: 'error', duration: 5000 });
			console.error(e);
		}
	}

	close() {
		Store.emit(actions.HIDE_MODAL);
	}

	render(props, { diff }) {
		return (
			<div class={s.wrapper}>
				{!diff.length ? (
					<div class={s.noChanges}>No changes</div>
				) : (
					<DiffList class={s.diffList} changes={diff} />
				)}
				<div class={s.actions} key="actions">
					<button class={s.ok} onClick={this.close}>Close</button>
					<button class={s.export} onClick={this.export}>Export</button>
					<button class={s.import} onClick={this.import}>Import</button>
				</div>
			</div>
		);
	}

}
