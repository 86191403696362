import { h } from 'preact';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import s from 'components/shared/editor/formEditor/attributes/backgroundAttribute.sss';

export default class BackgroundAttribute extends DisplayAttribute {
	handleBgImages = (val) => {
		const meta = this.props.activeValueFull?.meta;
		const studio = meta?.studio?.slug;
		const title = meta?.title?.slug;
		const region = meta?.region?.slug;
		const sharedMedia = 'https://s3-eu-west-1.amazonaws.com/st-static-stage';
		const imageRoot = `${sharedMedia}/${studio}/${title}/images`;
		const imageRegionRoot = `${sharedMedia}/${studio}/${title}/images/regions/${region}`;
		const mediaRoot = `${sharedMedia}/${studio}/${title}`;
		const sharedImageRoot = `${sharedMedia}/images`;
		const studioRoot = `${sharedMedia}/${studio}`;
		return val.replace(/%IMAGE_ROOT%/g, imageRoot)
			.replace(/%IMAGE_REGION_ROOT%/g, imageRegionRoot)
			.replace(/%MEDIA_ROOT%/g, mediaRoot)
			.replace(/%SHARED_IMAGE_ROOT%/g, sharedImageRoot)
			.replace(/%STUDIO_ROOT%/g, studioRoot);
	};

	updateValue = (e) => {
		let value = e.target.value + '';
		if (value.startsWith('http') || value.startsWith('%')) {
			value = `url(${value})`;
		}
		this.setState({ value });
		this.onSave();
	};

	getInput = (description) => {
		let input;

		const { value } = this.state;
		let bgStyle = this.handleBgImages(value);

		const linkUrl =
			'https://gradient.style/';

		input = (
			<div class={s.colorWrap}>
				<a
					href={linkUrl}
					target="_blank"
					rel="noopener noreferrer"
					class={s.colorBoxLink}
				>
					<div
						class={s.colorBox}
						style={{
							background: bgStyle,
							backgroundPosition: 'center',
							backgroundSize: 'cover'
						}}
						key="colorBox"
					/>
				</a>
				<input type="text" ref={(ref) => (this.$input = ref)} class={s.textarea} value={value} onInput={this.updateValue} placeholder={description} />
			</div>
		);

		return input;
	};
	
}
