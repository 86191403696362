import Freezer from 'freezer-js';

const Store = new Freezer({}, {
	// live: true
});

Store.on('afterAll', function (reactionName) {
	// UNCOMMENT THIS TO LOG EVENTS
	//   PLEASE COMMENT BACK BEFORE PUSHING
	// console.log(...arguments);

	if (reactionName === 'update') {
		return;
	}
	dispatch(reactionName);
});


Store.on(undefined, () => {
	// console.trace();
	throw new Error('Undefined action! Please define your actions!');
});

function dispatch(eventName) {
	if (typeof window === 'undefined' || typeof Event !== 'function' || !window.dispatchEvent) {
		return;
	}
	const event = new Event(eventName);
	window.dispatchEvent(event);
}

if (typeof window !== 'undefined') {
	window.Store = Store;
	dispatch('NEW_STORE');
}

export default Store;
