import { h, Component } from 'preact';
import pLimit from 'p-limit';
import Store from 'store/store';
import * as actions from 'store/actions';

import { joinClasses, parseDate, throttle, triggerAnim } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import { escapeSpecialChars } from 'utils/toRegExp';
import { buildApp, invalidateApp, uploadApp } from 'services/buildApi';

import Portal from 'components/shared/portal/portal';

import s from 'components/pages/apps/modals/massDeploy.sss';

const limiter = pLimit(10);

const parseDateValueAsTimestamp = (value) => +parseDate(value);
const parseDateValueAsDate = (value) => formatDateTime(parseDate(value), 'yyyy-MM-dd');
// const parseBooleanValue = (value) => value === 'yes' ? true : value === 'no' ? false : undefined;
const parseBooleanValueDefaultTrue = (value) => value !== 'false' && value !== 'no';
const appSelectors = {
	deployed: { check: (app) => app.history?.length },
	'deployed-after': { valueParser: parseDateValueAsTimestamp, check: (app, value) => app.history?.length && app.history.at(-1).at > value },
	'deployed-before': { valueParser: parseDateValueAsTimestamp, check: (app, value) => app.history?.length && app.history.at(-1).at < value },
	release: { valueParser: parseDateValueAsDate, check: (app, value) => app.release === value },
	'release-after': { valueParser: parseDateValueAsDate, check: (app, value) => app.release && app.release > value },
	'release-before': { valueParser: parseDateValueAsDate, check: (app, value) => app.release && app.release > value },
	bolt: { valueParser: parseBooleanValueDefaultTrue, check: (app, value) => (app.rel.base === 2) === !!value },
	id: { check: (app, value) => app.id.toString() === value },
	title: { check: (app, value, { title }) => title.slug === value?.toLowerCase() || title.en === value },
	'title-id': { check: (app, value) => app.rel.title === +value },
	studio: { check: (app, value, { studio }) => studio.slug === value?.toLowerCase() || studio.name === value || app.rel.studio === +value },
	'studio-id': { check: (app, value) => app.rel.studio === +value },
	region: { check: (app, value, { region }) => region.slug === value?.toLowerCase() || app.rel.region === +value },
	'region-id': { check: (app, value) => app.rel.region === +value },
	url: { check: ({ url }, value) => new RegExp(`^(https?://)?${escapeSpecialChars(value || '')}`, 'i').test(url) },
	'*': {
		check: (app, value, extraData) => {
			return [
				'id',
				'region',
				'studio',
				'title',
				value?.includes('.') && 'url',
			].filter(e => e).some(key => appSelectors[key].check(app, value, extraData));
		}
	},
};

const getSelectorParts = line => line.split(/(?<=^(?:[^"]|"(?:[^"]|"")*")*)(?:,|\t)/g);
// const isType = (type, condition) => condition === type || condition.startsWith(`${type}:`);

const count = (needle, haystack) => haystack.split(needle).length - 1;
const goToPosition = (input, line, col, length = 0) => {
	let val = input.value;
	let pos = 0;
	for (let i = 0; i < line; i++) {
		let next = val.indexOf('\n', pos);
		if (next === -1) break;
		pos = next + 1;
	}
	pos += col;
	input.selectionStart = pos;
	input.selectionEnd = pos + length;
};

// const dateSelectors = ['deployed-before', 'deployed-after', 'release-before', 'release-after'];
const removeQuotes = s => s.replace(/^"(.+)"$/, (_, s) => s.replaceAll('""', '"'));

const triggerClick = e => (e.key === 'Enter' || e.key === 'Space') && e.currentTarget.click();
const showPicker = e => (e.currentTarget.matches('input') ? e.currentTarget : e.currentTarget.querySelector('input'))?.showPicker();

const checkInput = (input) => {
	if (input) input.checked = true;
};

export default class CreateOverride extends Component {

	constructor(props) {
		super(props);

		this.onStoreUpdate = this.onStoreUpdate.bind(this);
		this.onMessage = this.onMessage.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onAppSelectInput = this.onAppSelectInput.bind(this);
		this.onAppSelectKeyDown = this.onAppSelectKeyDown.bind(this);
		this.onAppSelectionResultsScroll = throttle(this.onAppsListScroll.bind(this, '$appSelectionResults', 16, 'appSelectionDisplayOffset'), 30);
		this.onBuildScroll = throttle(this.onAppsListScroll.bind(this, '$buildAppsList', 24, 'buildDisplayOffset'), 30);
		this.onReviewScroll = throttle(this.onAppsListScroll.bind(this, '$reviewAppsList', 24, 'reviewDisplayOffset'), 30);
		this.onDeployScroll = throttle(this.onAppsListScroll.bind(this, '$deployAppsList', 24, 'deployDisplayOffset'), 30);
		this.addCurrentLevel = this.addCurrentLevel.bind(this);
		this.addCurrentTitle = this.addCurrentTitle.bind(this);
		this.addBolt = this.addBolt.bind(this);
		this.addNonBolt = this.addNonBolt.bind(this);
		this.addDeployedAnyTime = this.addDeployedAnyTime.bind(this);
		this.addDeployedBefore = this.addDeployedBefore.bind(this);
		this.addDeployedAfter = this.addDeployedAfter.bind(this);
		this.addReleaseBefore = this.addReleaseBefore.bind(this);
		this.addReleaseOn = this.addReleaseOn.bind(this);
		this.addReleaseAfter = this.addReleaseAfter.bind(this);
		this.copyAppsSelection = this.copyAppsSelection.bind(this);
		this.copyErrors = this.copyErrors.bind(this);
		this.copyDenied = this.copyDenied.bind(this);
		this.closeCurrentReview = this.closeCurrentReview.bind(this);
		this.build = this.build.bind(this);
		this.review = this.review.bind(this);
		this.deploy = this.deploy.bind(this);
		this.cancel = this.cancel.bind(this);
		this.finish = this.finish.bind(this);

		this.maxDisplayedResults = 100;
		this.state = {
			step: 'app-selection',
			appSelectionInput: '',
			apps: [],
			unusedInputs: [],
		};
	}

	componentDidMount() {
		Store.on('update', this.onStoreUpdate);
		window.addEventListener('message', this.onMessage);
		this.resizeObserver = new ResizeObserver(this.onResize);
		this.resizeObserver.observe(this.base);
		if (this.$appInput?.value) {
			this.onAppSelectInput();
		}
		// Should be handled by autofocus but in some cases it does not work
		this.$appInput?.focus();
	}

	componentWillUnmount() {
		Store.off('update', this.onStoreUpdate);
		window.removeEventListener('message', this.onMessage);
		this.resizeObserver.disconnect();
		this.unmounted = true;
	}

	onStoreUpdate(store, old) {
		const hasRelevantChange = [
			'myApps',
			'apps',
			'titles',
			'regions',
			'studios',
		].some(key => store[key] !== old[key]);
		if (hasRelevantChange) {
			this.forceUpdate();
		}
	}

	onMessage(e) {
		if (e.data?.type !== 'sync') return;
		const sync = document.body.querySelector('[name="mass-deploy-preview-sync"]')?.checked;
		if (!sync) return;
		// console.log('MESSAGE', e, e.data, e.target, e.source);
		const other = [...document.body.querySelectorAll(`.${s.preview} iframe`)].find(f => f.contentWindow !== e.source);
		// console.log('SENDING SYNC TO', other, e.data);
		if (!other) return;
		other.contentWindow.postMessage(e.data, '*');
	}

	async onResize() {
		if (this.handlingResize) {
			return;
		}
		this.handlingResize = true;
		const el = this.base;
		const height = el.clientHeight;
		if (this.previousHeight == undefined || height === this.previousHeight) {
			this.previousHeight = height;
			this.handlingResize = false;
			return;
		}
		el.style.transition = 'none';
		el.style.height = `${this.previousHeight}px`;
		el.offsetHeight;
		el.style.transition = '';
		el.style.height = `${height}px`;
		await Promise.all(el.getAnimations().map(a => a.finished));
		this.previousHeight = height;
		el.style.height = '';
		this.handlingResize = false;
	}

	onKeyDown(e) {
		if (e.key === 'Escape') {
			e.preventDefault();
			this.cancel();
			return;
		}
	}

	// Allow inserting tabs in the textarea, and add Esc(+Tab) as an alternative way to move the focus
	onAppSelectKeyDown(e) {
		if (e.key === 'Tab' && !e.shiftKey) {
			e.preventDefault();
			// const input = e.target;
			// Default selectionMode (preserve) is not great for our use case
			// input.setRangeText('\t');
			// input.setRangeText('\t', input.selectionStart, input.selectionEnd, 'end');
			// ^ Does not preserve the undo stack... Is there really no better way to do this? The following is non-standard
			document.execCommand('insertText', false, '\t');
			return;
		}
		if (e.key === 'Escape') {
			e.preventDefault();
			e.stopPropagation();
			e.target.blur();
			return;
		}
	}

	onAppSelectInput() {
		let val = this.$appInput?.value || '';
		const list = Store.get().list;
		const conditions = val.split('\n').map(line => {
			return getSelectorParts(line).map(part => {
				part = part.trim();
				if (!part) return null;
				let [type, ...value] = part.split(':');
				if (!value.length && !(type in appSelectors)) {
					type = '*';
					value = [part];
				}
				value = removeQuotes(value.join(':'));
				const selector = appSelectors[type] || appSelectors['*'];
				if (selector.valueParser) {
					value = selector.valueParser(value);
				}
				return { selector, value };
			}).filter(e => e);
		}).filter(e => e.length);
		const results = Object.values(list.apps).map(app => {
			const [title, studio, region] = ['title', 'studio', 'region'].map(key => list[`${key}s`][app.rel[key]]);
			const extraData = { title, studio, region };
			const pass = conditions.some(cond => cond.every(({ selector, value }) => selector?.check(app, value, extraData)));
			if (!pass) {
				return null;
			}
			return {
				id: app.id,
				studio: studio.name, studioSlug: studio.slug,
				title: title.en, titleSlug: title.slug,
				region: region.slug,
				rel: app.rel
			};
		}).filter(e => e);
		// Show conditions with no results ? Seems slow, or would have to process per condition rather than per app
		this.setState({ appSelectionInput: val });
		this.updateApps(results);
	}

	updateApps(list) {
		const max = list.length - this.maxDisplayedResults;
		this.setState({
			apps: list,
			appSelectionDisplayOffset: Math.max(Math.min(this.state.appSelectionDisplayOffset, max), 0),
			buildDisplayOffset: Math.max(Math.min(this.state.buildDisplayOffset, max), 0),
			reviewDisplayOffset: Math.max(Math.min(this.state.reviewDisplayOffset, max), 0),
			deployDisplayOffset: Math.max(Math.min(this.state.deployDisplayOffset, max), 0),
		});
	}

	onAppsListScroll(scrollerName, rowHeight, stateName) {
		const scroller = this[scrollerName];
		if (!scroller) {
			return;
		}
		const thHeight = 21;
		const centerRow = Math.round((scroller.scrollTop + scroller.clientHeight / 2 - thHeight) / rowHeight);
		const max = this.state.apps.length - this.maxDisplayedResults;
		let offset = Math.max(Math.min(centerRow - Math.floor(this.maxDisplayedResults / 2), max), 0);
		const current = this.state[stateName] || 0;
		if (offset !== current && (!offset || offset === max || Math.abs(offset - current) >= 10)) {
			this.setState({ [stateName]: offset });
		}
	}

	addToNewLine(text) {
		if (!text) return;
		const el = this.$appInput;
		const val = el.value;
		el.selectionStart = el.selectionEnd = val.length;
		el.focus();
		document.execCommand('insertText', false, `${val && !val.endsWith('\n') ? '\n' : ''}${text}`);
	}

	addToCurrentLines(text) {
		// Uses execCommand (and complexifies the logic) to be able to preserve the undo stack of the input
		const input = this.$appInput;
		const val = input.value;
		const fromLine = count('\n', val.slice(0, input.selectionStart));
		const toLine = fromLine + count('\n', val.slice(input.selectionStart, input.selectionEnd));
		const [type] = text.split(':');
		const lines = val.split('\n');
		input.focus();
		const existingValueRegex = new RegExp(`(?<=^(?:[^"]|"(?:[^"]|"")*")*)(?<=(?:^|\\t|,)\\s*)${type}(?::([^,\\t"]|"(?:[^"]|"")")*)?(?=\\s*(?:\\t|,|$))`);
		for (let i = fromLine; i <= toLine; i++) {
			let existing = lines[i].match(existingValueRegex);
			if (existing) {
				goToPosition(input, i, existing.index, existing[0].length);
				document.execCommand('insertText', false, text);
			} else {
				goToPosition(input, i, lines[i].length);
				document.execCommand('insertText', false, (lines[i].length ? ', ' : '') + text);
			}
		}
	}

	addCurrentLevel() {
		const activeAppData = this.props.activeAppData;
		switch (activeAppData?.type) {
			case 'apps':
				return this.addToNewLine(`id:${activeAppData.id}`);
			case 'titles':
				return this.addToNewLine(`title-id:${activeAppData.id}`);
			case 'studios':
				return this.addToNewLine(`studio-id:${activeAppData.id}`);
			case 'regions':
				return this.addToNewLine(`region-id:${activeAppData.id}`);
			case 'studio_regions':
				return this.addToNewLine(`studio-id:${activeAppData.rel.studio}, region-id:${activeAppData.rel.region}`);
		}
	}

	addCurrentTitle() {
		const app = this.props.activeAppData;
		const titleId = app?.rel.title || (app?.type === 'titles' && app.id);
		if (!titleId) return;
		const title = Store.get().list.titles[titleId];
		if (!title?.slug) return;
		this.addToNewLine(`title:${title.slug}`);
	}

	addBolt() {
		this.addToCurrentLines('bolt:yes');
	}
	addNonBolt() {
		this.addToCurrentLines('bolt:no');
	}
	addDeployedAnyTime() {
		this.addToCurrentLines('deployed');
	}
	addDeployedBefore(e) {
		this.addToCurrentLines(`deployed-before:${e.target.value.replace('T', ' ')}`);
	}
	addDeployedAfter(e) {
		this.addToCurrentLines(`deployed-after:${e.target.value.replace('T', ' ')}`);
	}
	addReleaseBefore(e) {
		this.addToCurrentLines(`release-before:${e.target.value}`);
	}
	addReleaseOn(e) {
		this.addToCurrentLines(`release:${e.target.value}`);
	}
	addReleaseAfter(e) {
		this.addToCurrentLines(`release-after:${e.target.value}`);
	}

	copyAppsSelection(e) {
		const apps = this.state.apps;
		const text = apps.map(({ studioSlug, titleSlug, region }) => [studioSlug, titleSlug, region].join('\t')).join('\n');
		navigator.clipboard.writeText(text);
		if (e?.currentTarget) {
			triggerAnim(e.currentTarget, s.success);
		}
	}
	copyErrors(e) {
		const apps = this.state.apps;
		const store = Store.get();
		const appsWithErrors = apps.filter(app => store.myApps?.[app.id]?.status?.errors?.length);
		if (!appsWithErrors.length) {
			return;
		}
		const text = appsWithErrors.map(({ studioSlug, titleSlug, region }) => [studioSlug, titleSlug, region].join('\t')).join('\n');
		navigator.clipboard.writeText(text);
		if (e?.currentTarget) {
			triggerAnim(e.currentTarget, s.success);
		}
	}
	copyDenied(e) {
		const apps = this.state.apps;
		const deniedApps = apps.filter(app => app.confirmed === false);
		if (!deniedApps.length) {
			return;
		}
		const text = deniedApps.map(({ studioSlug, titleSlug, region }) => [studioSlug, titleSlug, region].join('\t')).join('\n');
		navigator.clipboard.writeText(text);
		if (e?.currentTarget) {
			triggerAnim(e.currentTarget, s.success);
		}
	}

	openReview(app) {
		if (this.state.currentReview !== app) {
			this.setState({ currentReview: app });
		}
	}
	reviewConfirm(app) {
		app.confirmed = true;
		this.nextReview();
	}
	reviewDeny(app) {
		app.confirmed = false;
		this.nextReview();
	}
	closeCurrentReview() {
		this.setState({ currentReview: null });
	}
	nextReview() {
		const state = this.state;
		if (!state.apps || !state.currentReview) {
			return;
		}
		const currentIndex = state.apps.indexOf(state.currentReview);
		const unreviewed = app => app.confirmed == undefined;
		const next = state.apps.slice(currentIndex + 1).find(unreviewed) || state.apps.slice(0, currentIndex + 1).find(unreviewed);
		if (next) {
			this.openReview(next);
		} else {
			this.closeCurrentReview();
		}
	}

	async build() {
		this.setState({ step: 'building' });
		Store.emit(actions.SET_ACTIVE_APP_MODE, 'prod');
		const apps = this.state.apps;
		const allNeededLevels = apps.flatMap(app => [{ type: 'app', id: app.id }, ...Object.entries(app.rel).map(([type, id]) => ({ type, id }))]);
		await Store.emit('REQUEST_MISSING', allNeededLevels);
		const loadedApps = Store.get().apps;
		await Promise.all(apps.map(app => limiter(async () => {
			if (this.unmounted) {
				return;
			}
			const appData = this.props.getMerged(loadedApps[app.id])?.merged;
			await buildApp([appData], 'prod', undefined, false);
			const hasError = Store.get().myApps?.[app.id]?.status?.errors?.length;
			if (this.unmounted || hasError) {
				return;
			}
			const deployState = {
				status: 'processing',
				startedAt: Date.now(),
			};
			Store.emit(actions.UPDATE_MY_APP_STATUS, { ['deploystage']: deployState }, app.id);
			const uploadData = await uploadApp(appData, 'stage');
			// await this.checkDownloadableAssets(target);
			if (uploadData?.error) {
				deployState.status = 'error';
				deployState.error = uploadData.message;
				deployState.errorTime = Date.now();
			} else {
				deployState.status = 'complete';
				deployState.finishedAt = Date.now();
			}
			Store.emit(actions.UPDATE_MY_APP_STATUS, { ['deploystage']: deployState }, app.id);
		})));
	}

	review() {
		const apps = this.state.apps;
		const store = Store.get();
		const appsWithoutErrors = apps.filter(app => !store.myApps?.[app.id]?.status?.errors?.length);
		this.updateApps(appsWithoutErrors);
		this.setState({ step: 'review' });
	}

	async deploy() {
		const apps = this.state.apps;
		const confirmedApps = apps.filter(app => app.confirmed === true);
		this.updateApps(confirmedApps);
		this.setState({ step: 'deploy' });

		const loadedApps = Store.get().apps;
		await Promise.all(confirmedApps.map(app => limiter(async () => {
			if (this.unmounted) {
				return;
			}
			const appData = this.props.getMerged(loadedApps[app.id])?.merged;
			const deployState = { status: 'processing', startedAt: Date.now() };
			Store.emit(actions.UPDATE_MY_APP_STATUS, { ['deployprod']: deployState }, app.id);
			const uploadData = await uploadApp(appData, 'prod');
			if (uploadData?.error) {
				deployState.status = 'error';
				deployState.error = uploadData.message;
				deployState.errorTime = Date.now();
			} else {
				deployState.status = 'complete';
				deployState.finishedAt = Date.now();
			}
			Store.emit(actions.UPDATE_MY_APP_STATUS, { ['deployprod']: deployState }, app.id);
			if (!this.unmounted && deployState.status === 'complete') {
				Store.emit(actions.UPDATE_MY_APP_STATUS, { invalidateProd: { status: 'processing', startedAt: Date.now() } }, app.id);
				await invalidateApp(appData);
				Store.emit(actions.UPDATE_MY_APP_STATUS, { invalidateProd: { status: 'complete', startedAt: Date.now() } }, app.id);
			}
		})));
	}

	cancel() {
		Store.emit(actions.HIDE_MODAL, 'CANCEL');
	}

	finish() {
		Store.emit(actions.HIDE_MODAL, 'SUCCESS');
	}

	renderAppSelection() {
		const active = this.props.activeAppData;
		const addCurrentButtons = [
			active && active?.type !== 'bases' && { key: 'currentLevel', label: `Current ${active.type.replace(/s$/, '').replaceAll('_', '+')}`, onClick: this.addCurrentLevel },
			active?.type === 'apps' && { key: 'currentTitle', label: 'Current title', onClick: this.addCurrentTitle },
		].filter(e => e);
		return (
			<div class={joinClasses(s.step, s.appSelection)} inert={this.state.step !== 'app-selection'} key="app-selection">
				<div class={s.title} key="title">Select apps to deploy</div>
				<div class={s.input} key="input">
					<div class={s.tools}>
						{addCurrentButtons.map(({ key, label, onClick }) => <button class={s.button} onClick={onClick} key={key}>{label}</button>)}
						<div class={s.buttonGroup} key="bolt">
							<button class={joinClasses(s.button, s.name)}>Bolt...</button>
							<div class={s.expanded}>
								<button class={s.button} onClick={this.addBolt}><span class={s.icon}>⚡</span>Yes</button>
								<button class={s.button} onClick={this.addNonBolt}>No</button>
							</div>
						</div>
						<div class={s.buttonGroup} key="deployed">
							<button class={joinClasses(s.button, s.name)}>Deployed...</button>
							<div class={s.expanded}>
								<button class={s.button} onClick={this.addDeployedAnyTime}>Any time</button>
								<label class={s.button} title="note: checks latest deploy only" tabIndex="0" onClick={showPicker} onKeyDown={triggerClick}>
									<input class={s.invisible} type="datetime-local" onChange={this.addDeployedBefore} tabIndex="-1" />
									Before
								</label>
								<label class={s.button} tabIndex="0" onClick={showPicker} onKeyDown={triggerClick}>
									<input class={s.invisible} type="datetime-local" onChange={this.addDeployedAfter} tabIndex="-1" />
									After
								</label>
							</div>
						</div>
						<div class={s.buttonGroup} key="release">
							<button class={joinClasses(s.button, s.name)}>Release Date...</button>
							<div class={s.expanded}>
								<label class={s.button} tabIndex="0" onClick={showPicker} onKeyDown={triggerClick}>
									<input class={s.invisible} type="date" onFocus={showPicker} onChange={this.addReleaseBefore} tabIndex="-1" />
									Before
								</label>
								<label class={s.button} tabIndex="0" onClick={showPicker} onKeyDown={triggerClick}>
									<input class={s.invisible} type="date" onFocus={showPicker} onChange={this.addReleaseOn} tabIndex="-1" />
									On
								</label>
								<label class={s.button} tabIndex="0" onClick={showPicker} onKeyDown={triggerClick}>
									<input class={s.invisible} type="date" onFocus={showPicker} onChange={this.addReleaseAfter} tabIndex="-1" />
									After
								</label>
							</div>
						</div>
					</div>
					<textarea
						ref={e => this.$appInput = e}
						onInput={this.onAppSelectInput}
						onKeyDown={this.onAppSelectKeyDown}
						placeholder="App ids or conditions"
						autofocus
					/>
					<div class={s.help}>Same row: AND - Multiple rows: OR</div>
				</div>
				{this.renderAppSelectionResults()}
				<div class={s.buttons} key="buttons">
					<button onClick={this.cancel}>Cancel</button>
					<button class={s.copy} onClick={this.copyAppsSelection} disabled={!this.state.apps.length}>Copy detected apps</button>
					<button onClick={this.build} disabled={!this.state.apps.length}>Ok!</button>
				</div>
			</div>
		);
	}

	renderAppSelectionResults() {
		const state = this.state;
		if (!state.appSelectionInput) {
			return;
		}
		const offset = state.appSelectionDisplayOffset || 0;
		const rowSize = 16;
		const sizeBefore = offset * rowSize;
		const sizeAfter = Math.max(0, state.apps.length - offset - this.maxDisplayedResults) * rowSize;
		return (
			<fieldset class={s.results} onScroll={this.onAppSelectionResultsScroll} ref={e => this.$appSelectionResults = e} key="results">
				<legend>{state.apps.length ? `Detected apps (${state.apps.length})` : 'No results'}</legend>
				<table class={!state.apps.length && s.hidden}>
					<thead>
						<tr>
							<th>Studio</th>
							<th>Title</th>
							<th>Region</th>
						</tr>
					</thead>
					<tbody>
						<tr class={s.spacer} key="spacer-before"><td colspan="3" style={{ height: `${sizeBefore}px` }} /></tr>
						{state.apps?.slice(offset, offset + this.maxDisplayedResults).map(app => (
							<tr key={app.id}>
								<td>{app.studio}</td>
								<td>{app.title}</td>
								<td>{app.region}</td>
							</tr>
						))}
						<tr class={s.spacer} key="spacer-after"><td colspan="3" style={{ height: `${sizeAfter}px` }} /></tr>
					</tbody>
				</table>
			</fieldset>
		);
	}

	renderBuild() {
		const state = this.state;
		const store = Store.get();
		const apps = state.apps;
		const data = apps.map(app => {
			const rels = Object.entries(app.rel);
			const loadProgress = rels.filter(([rel, v]) => store[`${rel}s`][v]).length / rels.length;
			const status = store.myApps?.[app.id]?.status;
			const buildError = !!status?.errors?.length;
			const buildStatus = Object.values(status?.build?.bases || {});
			const buildProgress = buildError ? 0 : buildStatus.map(b => b.progress).reduce((a, b) => a + b, 0) / (buildStatus.length || 1);
			const deployStatus = status?.deploystage?.status;
			const deployProgress = buildError ? 0 : (deployStatus === 'complete' ? 1 : (deployStatus === 'processing' ? 0.5 : 0));
			return { app, loadProgress, buildError, buildProgress, deployProgress };
		});
		const done = data.every(a => a.buildError || a.deployProgress === 1);
		const errorsCount = data.filter(a => a.buildError).length;
		const offset = state.buildDisplayOffset || 0;
		const rowSize = 24;
		const sizeBefore = offset * rowSize;
		const sizeAfter = Math.max(0, state.apps.length - offset - this.maxDisplayedResults) * rowSize;
		return (
			<div class={joinClasses(s.step, s.buildAndStage)} inert={this.state.step !== 'building'} key="build">
				<div class={s.title}>Building and deploying to stage</div>
				<div class={s.data} onScroll={this.onBuildScroll} ref={e => this.$buildAppsList = e}>
					<table>
						<thead>
							<tr>
								<th>Studio</th>
								<th>Title</th>
								<th>Region</th>
								<th class={s.progressColumn}>Load</th>
								<th class={s.progressColumn}>Build</th>
								<th class={s.progressColumn}>Stage</th>
							</tr>
						</thead>
						<tbody>
							<tr class={s.spacer} key="spacer-before"><td colspan="3" style={{ height: `${sizeBefore}px` }} /></tr>
							{data.slice(offset, offset + this.maxDisplayedResults).map(({ app, loadProgress, buildProgress, buildError, deployProgress }) => (
								<tr key={app.id}>
									<td>{app.studio}</td>
									<td>{app.title}</td>
									<td>{app.region}</td>
									<td>{this.renderProgress(loadProgress)}</td>
									<td>{this.renderProgress(buildProgress, buildError)}</td>
									<td>{this.renderProgress(deployProgress)}</td>
								</tr>
							))}
							<tr class={s.spacer} key="spacer-after"><td colspan="3" style={{ height: `${sizeAfter}px` }} /></tr>
						</tbody>
					</table>
				</div>
				<div class={s.buttons}>
					<button onClick={this.cancel}>Cancel</button>
					{!!errorsCount && <button class={s.copy} onClick={this.copyErrors}>Copy apps with errors</button>}
					{errorsCount < apps.length && <button onClick={this.review} disabled={!done}>Review</button>}
				</div>
			</div>
		);
	}

	renderReview() {
		const state = this.state;
		const apps = state.apps;
		const confirmed = apps.filter(app => app.confirmed === true);
		const denied = apps.filter(app => app.confirmed === false);
		// const done = apps.every(app => app.confirmed != undefined);

		const offset = state.reviewDisplayOffset || 0;
		const rowSize = 24;
		const sizeBefore = offset * rowSize;
		const sizeAfter = Math.max(0, apps.length - offset - this.maxDisplayedResults) * rowSize;
		return (
			<div class={joinClasses(s.step, s.review)} inert={this.state.step !== 'review'} key="review">
				<div class={s.title}>Compare stage with live</div>
				<div class={s.data} onScroll={this.onReviewScroll} ref={e => this.$reviewAppsList = e}>
					<table>
						<thead>
							<tr>
								<th>Studio</th>
								<th>Title</th>
								<th>Region</th>
								<th>Check</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							<tr class={s.spacer} key="spacer-before"><td colspan="3" style={{ height: `${sizeBefore}px` }} /></tr>
							{apps.slice(offset, offset + this.maxDisplayedResults).map((app) => (
								<tr key={app.id}>
									<td>{app.studio}</td>
									<td>{app.title}</td>
									<td>{app.region}</td>
									<td><button onClick={() => this.openReview(app)}>open</button></td>
									<td>{app.confirmed === true ? '✔️' : app.confirmed === false ? '❌' : '❔'}</td>
								</tr>
							))}
							<tr class={s.spacer} key="spacer-after"><td colspan="3" style={{ height: `${sizeAfter}px` }} /></tr>
						</tbody>
					</table>
				</div>
				<div class={s.buttons}>
					<button onClick={this.cancel}>Cancel</button>
					{!!denied.length && <button class={s.copy} onClick={this.copyDenied}>Copy denied list</button>}
					<button onClick={this.deploy} disabled={!confirmed.length}>Deploy {confirmed.length}</button>
				</div>
				{this.renderCurrentReview()}
			</div>
		);
	}

	renderCurrentReview() {
		const state = this.state;
		const app = state.currentReview;
		if (!app) {
			return;
		}
		const appPath = `${app.studioSlug}/${app.titleSlug}/${app.region}`;
		const stageLink = `http://stage.showtimes-static.s3-website-eu-west-1.amazonaws.com/${appPath}/?sync`;
		const prodLink = `http://prod.showtimes-static.s3-website-eu-west-1.amazonaws.com/${appPath}/?sync`;
		return (
			<Portal into="body">
				<div class={joinClasses(s.currentReview, s[`size-${state.previewSize}`])}>
					<div class={s.toolbar}>
						<label title="Synchronize scroll positions and clicks (if available)">
							<input type="checkbox" name="mass-deploy-preview-sync" ref={checkInput} />
							<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 -960 960 960"><path d="M216-192v-72h74q-45-40-71.5-95.5T192-480q0-101 61-177.5T408-758v75q-63 23-103.5 77.5T264-480q0 48 19.5 89t52.5 70v-63h72v192H216Zm336-10v-75q63-23 103.5-77.5T696-480q0-48-19.5-89T624-639v63h-72v-192h192v72h-74q45 40 71.5 95.5T768-480q0 101-61 177.5T552-202Z" /></svg>
						</label>
						<div class={s.size} key="sizes">
							<label title="Preview mobile size"><input type="radio" name="mass-deploy-preview-size" value="mobile" />📱</label>
							<label title="Preview large desktop size"><input type="radio" name="mass-deploy-preview-size" value="desktop" />💻</label>
							<label title="Auto-size mode"><input type="radio" name="mass-deploy-preview-size" value="auto" ref={checkInput} />Auto</label>
						</div>
						<div class={joinClasses(s.buttonGroup, s.downwards, s.openLinks)} key="open">
							<button class={joinClasses(s.button, s.name)}>Open...</button>
							<div class={s.expanded}>
								<a class={s.button} href={stageLink} target="_blank">stage</a>
								<a class={s.button} href={prodLink} target="_blank">prod</a>
							</div>
						</div>
						<button class={s.confirm} onClick={() => this.reviewConfirm(app)} title="Confirm deployment is ok" key="confirm">✔️</button>
						<button class={s.deny} onClick={() => this.reviewDeny(app)} title="Deny deployment of this app" key="deny">❌</button>
						<button class={s.close} onClick={this.closeCurrentReview} key="close">Close</button>
					</div>
					<div class={s.previews}>
						<div class={joinClasses(s.preview, s.left)}>
							<iframe src={stageLink} />
						</div>
						<div class={joinClasses(s.preview, s.right)}>
							<iframe src={prodLink} />
						</div>
					</div>
				</div>
			</Portal>
		);
	}

	renderDeploy() {
		const state = this.state;
		const store = Store.get();
		const apps = state.apps;
		const data = apps.map(app => {
			const status = store.myApps?.[app.id]?.status;
			const deployStatus = status?.deployprod?.status;
			const deployProdProgress = deployStatus === 'complete' ? 1 : (deployStatus === 'processing' ? 0.5 : 0);
			return { app, deployProdProgress };
		});
		const done = data.every(a => a.deployProdProgress === 1);
		const offset = state.deployDisplayOffset || 0;
		const rowSize = 24;
		const sizeBefore = offset * rowSize;
		const sizeAfter = Math.max(0, state.apps.length - offset - this.maxDisplayedResults) * rowSize;
		return (
			<div class={joinClasses(s.step, s.deploy)} inert={this.state.step !== 'deploy'} key="deploy">
				<div class={s.title}>Deploy to prod</div>
				<div class={s.data} onScroll={this.onDeployScroll} ref={e => this.$deployAppsList = e}>
					<table>
						<thead>
							<tr>
								<th>Studio</th>
								<th>Title</th>
								<th>Region</th>
								<th class={s.progressColumn}>Prod</th>
							</tr>
						</thead>
						<tbody>
							<tr class={s.spacer} key="spacer-before"><td colspan="3" style={{ height: `${sizeBefore}px` }} /></tr>
							{data.slice(offset, offset + this.maxDisplayedResults).map(({ app, deployProdProgress }) => (
								<tr key={app.id}>
									<td>{app.studio}</td>
									<td>{app.title}</td>
									<td>{app.region}</td>
									<td>{this.renderProgress(deployProdProgress)}</td>
								</tr>
							))}
							<tr class={s.spacer} key="spacer-after"><td colspan="3" style={{ height: `${sizeAfter}px` }} /></tr>
						</tbody>
					</table>
				</div>
				<div class={s.buttons}>
					<button onClick={this.cancel}>Cancel</button>
					<button onClick={this.finish} disabled={!done}>Finish</button>
				</div>
			</div>
		);
	}

	renderProgress(progress, error) {
		return <div class={joinClasses(s.progress, progress >= 1 && s.done, error && s.error)} style={`--mass-deploy-progress:${progress};`} />;
	}

	render(props, state) {
		return (
			<div class={joinClasses(s.container, s[`step-${state.step}`])} onKeyDown={this.onKeyDown} key="container">
				{this.renderAppSelection()}
				{this.renderBuild()}
				{this.renderReview()}
				{this.renderDeploy()}
			</div>
		);
	}

}