// From https://github.com/dcollien/Dreamcoat
//  ^ really well documented if you need details
// Generated from coffeescript
// TODO: rewrite with classes & all (very small start commented out at the bottom)

export default function ColorAnalyser(img, canvas, maxColorBits) {
	if (maxColorBits == null) {
		maxColorBits = 8;
	}
	canvas = canvas || document.createElement('canvas');
	this.octree = new Octree(maxColorBits);
	this.loadCanvas(img, canvas);
}

ColorAnalyser.prototype.loadCanvas = function(img, canvas) {
	let context;
	context = canvas.getContext('2d');
	this.imgWidth = img.width;
	this.imgHeight = img.height;
	canvas.width = this.imgWidth;
	canvas.height = this.imgHeight;
	context.drawImage(img, 0, 0);
	return this.imageData = context.getImageData(0, 0, this.imgWidth, this.imgHeight);
};

ColorAnalyser.prototype.getPixel = function(x, y, channels) {
	let channel, idx, _i, _results;
	if (channels == null) {
		channels = 3;
	}
	idx = (y * this.imgWidth + x) * 4;
	_results = [];
	for (channel = _i = 0; 0 <= channels ? _i < channels : _i > channels; channel = 0 <= channels ? ++_i : --_i) {
		_results.push(this.imageData.data[idx + channel]);
	}
	return _results;
};

ColorAnalyser.prototype.detectBackground = function() {
	let bgColor, border, bottom, color, colorFreqs, freq, left, mostFrequent, right, top, x, y, _i, _len;
	top = (function() {
		let _i, _ref, _results;
		_results = [];
		for (x = _i = 0, _ref = this.imgWidth; 0 <= _ref ? _i < _ref : _i > _ref; x = 0 <= _ref ? ++_i : --_i) {
			_results.push(this.getPixel(x, 0));
		}
		return _results;
	}).call(this);
	bottom = (function() {
		let _i, _ref, _results;
		_results = [];
		for (x = _i = 0, _ref = this.imgWidth; 0 <= _ref ? _i < _ref : _i > _ref; x = 0 <= _ref ? ++_i : --_i) {
			_results.push(this.getPixel(x, this.imgHeight - 1));
		}
		return _results;
	}).call(this);
	left = (function() {
		let _i, _ref, _results;
		_results = [];
		for (y = _i = 0, _ref = this.imgHeight; 0 <= _ref ? _i < _ref : _i > _ref; y = 0 <= _ref ? ++_i : --_i) {
			_results.push(this.getPixel(0, y));
		}
		return _results;
	}).call(this);
	right = (function() {
		let _i, _ref, _results;
		_results = [];
		for (y = _i = 0, _ref = this.imgHeight; 0 <= _ref ? _i < _ref : _i > _ref; y = 0 <= _ref ? ++_i : --_i) {
			_results.push(this.getPixel(this.imgWidth - 1, y));
		}
		return _results;
	}).call(this);
	border = ((top.concat(bottom)).concat(left)).concat(right);
	colorFreqs = {};
	for (_i = 0, _len = border.length; _i < _len; _i++) {
		color = border[_i];
		if (colorFreqs[color.toString()] != null) {
			colorFreqs[color.toString()]++;
		} else {
			colorFreqs[color.toString()] = 1;
		}
	}
	bgColor = top[0];
	mostFrequent = 0;
	for (color in colorFreqs) {
		freq = colorFreqs[color];
		if (freq > mostFrequent) {
			bgColor = color.split(',').map(function(x) {
				return parseInt(x);
			});
			mostFrequent = freq;
		}
	}
	return bgColor;
};

ColorAnalyser.prototype.rgbToHsl = function(r, g, b) {
	let d, h, l, max, min, s;
	r /= 255;
	g /= 255;
	b /= 255;
	max = Math.max(r, g, b);
	min = Math.min(r, g, b);
	l = (max + min) / 2;
	if (max === min) {
		h = s = 0;
	} else {
		d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
		}
		h /= 6;
	}
	return [h, s, l];
};

ColorAnalyser.prototype.hslToRgb = function(h, s, l) {
	let b, g, hue2rgb, p, q, r;
	if (s === 0) {
		r = g = b = l;
	} else {
		hue2rgb = function(p, q, t) {
			if (t < 0) {
				t += 1;
			}
			if (t > 1) {
				t -= 1;
			}
			if (t < 1 / 6) {
				return p + (q - p) * 6 * t;
			}
			if (t < 1 / 2) {
				return q;
			}
			if (t < 2 / 3) {
				return p + (q - p) * (2 / 3 - t) * 6;
			}
			return p;
		};
		q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}
	return [r * 255, g * 255, b * 255];
};

ColorAnalyser.prototype.chooseTextColor = function(backgroundColor, palette) {
	let b, color, count, g, h, l, lerp, mB, mG, mH, mR, mS, modeColor, modeCount, r, rgb, s, _i, _len, _ref, _ref1, _ref2, _ref3;
	if (palette == null) {
		palette = null;
	}
	r = backgroundColor[0], g = backgroundColor[1], b = backgroundColor[2];
	_ref = this.rgbToHsl(r, g, b), h = _ref[0], s = _ref[1], l = _ref[2];
	h += 0.5;
	if (h > 1) {
		h -= 1;
	}
	s = (1 - s) * 0.25;
	l = 1 - l;
	if (l < 0.5) {
		l = -l + 0.5;
	} else if (l > 0.5) {
		l = -l + 1.5;
	} else {
		l = 1;
	}
	if (palette != null) {
		lerp = function(t, from, to) {
			return t * to + (1 - t) * from;
		};
		_ref1 = palette[0], modeColor = _ref1[0], modeCount = _ref1[1];
		for (_i = 0, _len = palette.length; _i < _len; _i++) {
			_ref2 = palette[_i], color = _ref2[0], count = _ref2[1];
			if (count > modeCount) {
				modeCount = count;
				modeColor = color;
			}
		}
		mR = modeColor[0], mG = modeColor[1], mB = modeColor[2];
		_ref3 = this.rgbToHsl(mR, mG, mB), mH = _ref3[0], mS = _ref3[1];
		s = Math.min(s, mS);
		h = lerp(0.75, h, mH);
	}
	rgb = (this.hslToRgb(h, s, l)).map(Math.floor);
	return rgb;
};

ColorAnalyser.prototype.analyseImage = function(paletteSize, background, ignoreGrey) {
	let numVectors, palette, _ref;
	if (background == null) {
		background = null;
	}
	if (ignoreGrey == null) {
		ignoreGrey = false;
	}
	if (background == null) {
		background = this.detectBackground();
	}
	_ref = this.getClusteredPalette(paletteSize, 1, 1024, background, 32, ignoreGrey), palette = _ref[0], numVectors = _ref[1];
	return [palette, numVectors];
};

ColorAnalyser.prototype.getClusteredPalette = function(numClusters, threshold, paletteSize, exclude, error, ignoreGrey) {
	let cluster, clusterer, clusters, colors, numVectors, palette, _ref;
	_ref = this.getThresholdedPalette(threshold, paletteSize, exclude, error, ignoreGrey), palette = _ref[0], numVectors = _ref[1];
	clusterer = new KMeans(numClusters, 3);
	clusterer.setPoints(palette);
	clusters = clusterer.performCluster();
	colors = (function() {
		let _i, _len, _results;
		_results = [];
		for (_i = 0, _len = clusters.length; _i < _len; _i++) {
			cluster = clusters[_i];
			_results.push([cluster.getMean(), cluster.size]);
		}
		return _results;
	})();
	return [colors, numVectors];
};

ColorAnalyser.prototype.getThresholdedPalette = function(threshold, paletteSize, exclude, error, ignoreGrey) {
	let color, colors, newColors, numVectors, sum, _i, _len, _ref;
	_ref = this.getPalette(paletteSize, exclude, error, ignoreGrey), colors = _ref[0], numVectors = _ref[1];
	colors.sort(function(a, b) {
		return b[1] - a[1];
	});
	newColors = [];
	sum = 0;
	for (_i = 0, _len = colors.length; _i < _len; _i++) {
		color = colors[_i];
		newColors.push(color);
		sum += color[1];
		if (sum > (numVectors * threshold)) {
			break;
		}
	}
	return [newColors, numVectors];
};

ColorAnalyser.prototype.getFilteredPalette = function(stdDeviations, paletteSize, exclude, error, ignoreGrey) {
	let color, colors, filteredColors, freq, freqSum, i, meanDiff, meanFrequency, numColors, numVectors, stdDevFrequency, stdDevSum, _i, _j, _ref, _ref1, _ref2, _ref3, _ref4;
	_ref = this.getPalette(paletteSize, exclude, error, ignoreGrey), colors = _ref[0], numVectors = _ref[1];
	numColors = 0;
	freqSum = 0;
	for (i = _i = 0, _ref1 = colors.length; 0 <= _ref1 ? _i < _ref1 : _i > _ref1; i = 0 <= _ref1 ? ++_i : --_i) {
		_ref2 = colors[i], color = _ref2[0], freq = _ref2[1];
		freqSum += freq;
		numColors++;
	}
	meanFrequency = freqSum / numColors;
	stdDevSum = 0;
	for (i = _j = 0, _ref3 = colors.length; 0 <= _ref3 ? _j < _ref3 : _j > _ref3; i = 0 <= _ref3 ? ++_j : --_j) {
		_ref4 = colors[i], color = _ref4[0], freq = _ref4[1];
		meanDiff = freq - meanFrequency;
		stdDevSum += meanDiff * meanDiff;
	}
	stdDevFrequency = Math.sqrt(stdDevSum / numColors);
	filteredColors = (function() {
		let _k, _len, _ref5, _results;
		_results = [];
		for (_k = 0, _len = colors.length; _k < _len; _k++) {
			_ref5 = colors[_k], color = _ref5[0], freq = _ref5[1];
			if (Math.abs(freq - meanFrequency) < (stdDevFrequency * stdDeviations)) {
				_results.push([color, freq]);
			}
		}
		return _results;
	})();
	return [filteredColors, numVectors];
};

ColorAnalyser.prototype.getPalette = function(paletteSize, exclude, error, ignoreGrey) {
	let b, bIsWithinError, eb, eg, er, excludeGrey, g, gIsWithinError, gb, i, isExcluded, numVectors, pixelData, r, rIsWithinError, rb, rg, _i, _ref;
	if (error == null) {
		error = 0;
	}
	pixelData = this.imageData.data;
	for (i = _i = 0, _ref = pixelData.length; _i < _ref; i = _i += 4) {
		r = pixelData[i];
		g = pixelData[i + 1];
		b = pixelData[i + 2];
		isExcluded = false;
		if (exclude != null) {
			er = exclude[0], eg = exclude[1], eb = exclude[2];
			rIsWithinError = ((er - error) < r && r < (er + error));
			gIsWithinError = ((eg - error) < g && g < (eg + error));
			bIsWithinError = ((eb - error) < b && b < (eb + error));
			isExcluded = rIsWithinError && gIsWithinError && bIsWithinError;
		}
		excludeGrey = false;
		if (ignoreGrey) {
			rg = Math.abs(r - g) < error;
			rb = Math.abs(r - b) < error;
			gb = Math.abs(g - b) < error;
			excludeGrey = ignoreGrey && rg && rb && gb;
			isExcluded = isExcluded || excludeGrey;
		}
		if (!isExcluded) {
			this.octree.insertVector([r, g, b]);
		}
	}
	numVectors = this.octree.numVectors;
	return [this.octree.reduceToSize(paletteSize), numVectors];
};

let Octree = (function() {
	Octree.branching = 8;

	Octree.dimensions = 3;

	function Octree(maxBits) {
		let _ref, _results;
		this.maxBits = maxBits != null ? maxBits : 8;
		this.leafCount = 0;
		this.numVectors = 0;
		this.reducibleNodes = new Array(this.maxBits + 1);
		this.levelMasks = (function() {
			_results = [];
			for (let _i = _ref = this.maxBits - 1; _ref <= 0 ? _i <= 0 : _i >= 0; _ref <= 0 ? _i++ : _i--){ _results.push(_i); }
			return _results;
		}).apply(this).map(function(bit) {
			return Math.pow(2, bit);
		});
		this.root = new OctreeNode(this);
	}

	Octree.prototype.isVectorEqual = function(v1, v2) {
		let i, _i, _ref;
		if ((v1 == null) || (v2 == null)) {
			return false;
		}
		for (i = _i = 0, _ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
			if (v1[i] !== v2[i]) {
				return false;
			}
		}
		return true;
	};

	Octree.prototype.insertVector = function(newVect) {
		if ((this.prevNode != null) && (this.isVectorEqual(newVect, this.prevVect))) {
			this.prevNode.insertVector(newVect, this);
		} else {
			this.prevVect = newVect;
			this.prevNode = this.root.insertVector(newVect, this);
		}
		return this.numVectors++;
	};

	Octree.prototype.reduce = function() {
		let levelIndex, node;
		levelIndex = this.maxBits - 1;
		while (levelIndex > 0 && (this.reducibleNodes[levelIndex] == null)) {
			levelIndex--;
		}
		node = this.reducibleNodes[levelIndex];
		this.reducibleNodes[levelIndex] = node.nextReducible;
		this.leafCount -= node.reduce();
		return this.prevNode = null;
	};

	Octree.prototype.reduceToSize = function(itemCount) {
		while (this.leafCount > itemCount) {
			this.reduce();
		}
		return this.root.getData();
	};

	return Octree;

})();

let OctreeNode = (function() {
	function OctreeNode(octree, level) {
		if (level == null) {
			level = 0;
		}
		this.isLeaf = level === octree.maxBits;
		this.mean = (function() {
			let _i, _ref, _results;
			_results = [];
			for (_i = 0, _ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; 0 <= _ref ? ++_i : --_i) {
				_results.push(0);
			}
			return _results;
		})();
		this.count = 0;
		if (this.isLeaf) {
			octree.leafCount++;
			this.nextReducible = null;
			this.children = null;
		} else {
			this.nextReducible = octree.reducibleNodes[level];
			octree.reducibleNodes[level] = this;
			this.children = new Array(Octree.branching);
		}
	}

	OctreeNode.prototype.insertVector = function(v, octree, level) {
		let child, i, index, _i, _ref;
		if (level == null) {
			level = 0;
		}
		if (this.isLeaf) {
			this.count++;
			for (i = _i = 0, _ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
				if ((this.mean[i] == null) || this.count === 1) {
					this.mean[i] = v[i];
				} else {
					this.mean[i] = (this.mean[i] * (this.count - 1) + v[i]) / this.count;
				}
			}
			return this;
		} else {
			index = this.getIndex(v, level, octree);
			child = this.children[index];
			if (child == null) {
				child = new OctreeNode(octree, level + 1);
				this.children[index] = child;
			}
			return child.insertVector(v, octree, level + 1);
		}
	};

	OctreeNode.prototype.getIndex = function(v, level, octree) {
		let i, index, reverseIndex, shift, _i, _ref;
		shift = octree.maxBits - 1 - level;
		index = 0;
		for (i = _i = 0, _ref = Octree.dimensions; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
			reverseIndex = Octree.dimensions - 1 - i;
			index |= (v[i] & octree.levelMasks[level]) >> (shift - reverseIndex);
		}
		return index;
	};

	OctreeNode.prototype.reduce = function() {
		let child, childIndex, childSum, i, newCount, nodeSum, numChildren, _i, _j, _ref, _ref1;
		if (this.isLeaf) {
			return 0;
		}
		numChildren = 0;
		for (childIndex = _i = 0, _ref = Octree.branching; 0 <= _ref ? _i < _ref : _i > _ref; childIndex = 0 <= _ref ? ++_i : --_i) {
			child = this.children[childIndex];
			if (child != null) {
				newCount = this.count + child.count;
				for (i = _j = 0, _ref1 = Octree.dimensions; 0 <= _ref1 ? _j < _ref1 : _j > _ref1; i = 0 <= _ref1 ? ++_j : --_j) {
					nodeSum = this.mean[i] * this.count;
					childSum = child.mean[i] * child.count;
					this.mean[i] = (nodeSum + childSum) / newCount;
				}
				this.count = newCount;
				numChildren++;
				this.children[childIndex] = null;
			}
		}
		this.isLeaf = true;
		return numChildren - 1;
	};

	OctreeNode.prototype.getData = function(data, index) {
		let i, _i, _ref;
		if (data == null) {
			data = this.getData([], 0);
		} else if (this.isLeaf) {
			data.push([this.mean, this.count]);
		} else {
			for (i = _i = 0, _ref = Octree.branching; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
				if (this.children[i] != null) {
					data = this.children[i].getData(data, index);
				}
			}
		}
		return data;
	};

	return OctreeNode;

})();

let KMeans = (function() {
	function KMeans(numClusters, numDimensions) {
		this.numClusters = numClusters;
		this.clusters = (function() {
			let _i, _results;
			_results = [];
			for (_i = 0; 0 <= numClusters ? _i < numClusters : _i > numClusters; 0 <= numClusters ? ++_i : --_i) {
				_results.push(new Cluster(numDimensions));
			}
			return _results;
		})();
	}

	KMeans.prototype.setPoints = function(points) {
		let data, weight;
		return this.dataPoints = (function() {
			let _i, _len, _ref, _results;
			_results = [];
			for (_i = 0, _len = points.length; _i < _len; _i++) {
				_ref = points[_i], data = _ref[0], weight = _ref[1];
				_results.push(new DataPoint(data, weight));
			}
			return _results;
		})();
	};

	KMeans.prototype.performCluster = function() {
		let movesMade;
		this.initClusters();
		while (movesMade !== 0) {
			movesMade = this.clusterStep();
		}
		return this.clusters;
	};

	KMeans.prototype.initClusters = function() {
		let bestCenter, cluster, firstCenterIndex, maxDist, minDist, numPoints, point, _i, _j, _len, _len1, _ref, _ref1, _results;
		numPoints = this.dataPoints.length;
		firstCenterIndex = Math.floor(Math.random() * numPoints);
		this.clusters[0].addPoint(this.dataPoints[firstCenterIndex]);
		_ref = this.clusters.slice(1);
		_results = [];
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			cluster = _ref[_i];
			maxDist = 0;
			bestCenter = null;
			_ref1 = this.dataPoints;
			for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
				point = _ref1[_j];
				if (point.cluster == null) {
					minDist = this.nearestClusterDistance(point);
					if (minDist > maxDist) {
						maxDist = minDist;
						bestCenter = point;
					}
				}
			}
			_results.push(cluster.addPoint(bestCenter));
		}
		return _results;
	};

	KMeans.prototype.nearestClusterDistance = function(point) {
		let cluster, minDist, _i, _len, _ref;
		minDist = Number.POSITIVE_INFINITY;
		_ref = this.clusters;
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			cluster = _ref[_i];
			if ((cluster != null) && cluster.size > 0) {
				minDist = Math.min(minDist, cluster.getDistanceTo(point));
			}
		}
		return minDist;
	};

	KMeans.prototype.nearestClusterTo = function(point) {
		let cluster, distance, nearestCluster, nearestDistance, _i, _len, _ref;
		nearestCluster = null;
		nearestDistance = Number.POSITIVE_INFINITY;
		_ref = this.clusters;
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			cluster = _ref[_i];
			if ((cluster != null) && cluster.size > 0) {
				if (nearestCluster == null) {
					nearestCluster = cluster;
					nearestDistance = nearestCluster.getDistanceTo(point);
				} else {
					distance = cluster.getDistanceTo(point);
					if (distance < nearestDistance) {
						nearestCluster = cluster;
						nearestDistance = distance;
					}
				}
			}
		}
		return nearestCluster;
	};

	KMeans.prototype.reassignPoint = function(point, cluster) {
		let currentCluster, wasAbleToAssign;
		currentCluster = point.cluster;
		wasAbleToAssign = false;
		if (currentCluster == null) {
			cluster.addPoint(point);
			wasAbleToAssign = true;
		} else if (currentCluster.size > 1 && cluster !== currentCluster) {
			currentCluster.removePoint(point);
			cluster.addPoint(point);
			wasAbleToAssign = true;
		}
		return wasAbleToAssign;
	};

	KMeans.prototype.clusterStep = function() {
		let nearestCluster, numMoves, point, _i, _len, _ref;
		numMoves = 0;
		_ref = this.dataPoints;
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			point = _ref[_i];
			nearestCluster = this.nearestClusterTo(point);
			if (this.reassignPoint(point, nearestCluster)) {
				numMoves++;
			}
		}
		return numMoves;
	};

	return KMeans;

})();

let DataPoint = (function() {
	function DataPoint(data, weight, cluster) {
		this.data = data;
		this.weight = weight != null ? weight : 1;
		this.cluster = cluster != null ? cluster : null;
	}

	return DataPoint;

})();

let Cluster = (function() {
	function Cluster(numDimensions) {
		let _results;
		this.size = 0;
		this.dimensions = (function() {
			_results = [];
			for (let _i = 0; 0 <= numDimensions ? _i < numDimensions : _i > numDimensions; 0 <= numDimensions ? _i++ : _i--){ _results.push(_i); }
			return _results;
		}).apply(this);
		this.sum = (function() {
			let _j, _len, _ref, _results1;
			_ref = this.dimensions;
			_results1 = [];
			for (_j = 0, _len = _ref.length; _j < _len; _j++) {
				_results1.push(0);
			}
			return _results1;
		}).call(this);
	}

	Cluster.prototype.addPoint = function(point) {
		let i, _i, _len, _ref;
		this.size += point.weight;
		_ref = this.dimensions;
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			i = _ref[_i];
			this.sum[i] += point.data[i] * point.weight;
		}
		return point.cluster = this;
	};

	Cluster.prototype.removePoint = function(point) {
		let i, _i, _len, _ref, _results;
		point.cluster = null;
		this.size -= point.weight;
		_ref = this.dimensions;
		_results = [];
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			i = _ref[_i];
			_results.push(this.sum[i] -= point.data[i] * point.weight);
		}
		return _results;
	};

	Cluster.prototype.getMean = function() {
		let i, _i, _len, _ref, _results;
		_ref = this.dimensions;
		_results = [];
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			i = _ref[_i];
			_results.push(this.sum[i] / this.size);
		}
		return _results;
	};

	Cluster.prototype.getDistanceTo = function(point) {
		let centroid, diff, i, squaredDist, _i, _len, _ref;
		centroid = this.getMean();
		squaredDist = 0;
		_ref = this.dimensions;
		for (_i = 0, _len = _ref.length; _i < _len; _i++) {
			i = _ref[_i];
			diff = centroid[i] - point.data[i];
			squaredDist += diff * diff;
		}
		return squaredDist;
	};

	return Cluster;

})();

// export default class ColorAnalyzer {
//
// 	constructor(image, maxColorBits = 8) {
// 		this.octree = new Octree(maxColorBits);
// 		this.canvas = new Canvas();
// 	}
//
//
//
// }
//
// class Octree {
//
// 	constructor(maxBits = 8) {
// 		this.branching = 8;
// 		this.dimensions = 3;
// 		this.maxBits = maxBits;
// 		this.leafCount = 0;
// 		this.numVectors = 0;
// 		this.reducibleNodes = [];
// 		this.levelMasks = [];
// 		for (let i = maxBits-1; i >= 0; i--) {
// 			this.levelMasks.push(Math.pow(2, i));
// 		}
//
// 		this.root = new OctreeNode(this);
// 	}
//
// }
//
// class OctreeNode {
//
// 	constructor(octree, level = 0) {
// 		this.isLeaf = level === octree.maxBits;
// 		this.mean = new Array(octree.dimensions).fill(0);
// 		this.count = 0;
//
// 		if (isLeaf) {
// 			octree.leafCount++;
// 			this.nextReducible = null;
// 			this.children = null;
// 		} else {
// 			this.nextReducible = octree.reducibleNodes[level];
// 			octree.reducibleNodes[level] = this;
// 			this.children = [];
// 		}
// 	}
//
// }
