import { render, h, Component } from 'preact';
import { joinClasses } from 'utils/utils';
import pendingPromise from 'utils/pendingPromise';
import { clamp } from 'utils/math';
import { replaceWithVNode } from 'utils/vnode';
import { escapeSpecialChars } from 'utils/toRegExp';

import s from 'utils/quickInput.scss';

const isMac = typeof navigator !== 'undefined' && navigator.userAgent.includes('Mac OS X');
const keyNames = {
	ctrl: isMac ? 'Cmd' : 'Ctrl',
	alt: isMac ? 'Option' : 'Alt',
	shift: 'Shift',
};

// const highlight = (text, search) => replaceWithVNode(text, search, ([v]) => <strong>{v}</strong>, { ignoreCase: true });
const highlight = (text, search) => replaceWithVNode(text, search, e => e.slice(1).map((s, i) => i % 2 ? s : <strong>{s}</strong>), { ignoreCase: true });

class QuickInput extends Component {
	constructor(props) {
		super(props);

		this.maxResult = 50;
		this.state = {
			selectedIndex: 0,
			renderedList: this.getFilteredList('', props),
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onInput = this.onInput.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.renderOption = this.renderOption.bind(this);
	}

	componentDidMount() {
		this.base.querySelector('input').focus();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedIndex !== this.state.selectedIndex) {
			this.base.querySelector(`.${s.option}.${s.selected}`)?.scrollIntoView({ block: 'nearest' });
		}
	}

	getFilteredList(search, props = this.props) {
		let customResult = props.computeResults?.(search, props);
		if (Array.isArray(customResult)) {
			return customResult.slice(0, this.maxResult);
		}
		let list = props.list || [];
		if (search) {
			const terms = search.split(/\s+/).filter(e => e).map(escapeSpecialChars);
			// const anyTerm = new RegExp(terms.join('|'), 'gi');
			const allTerms = new RegExp(terms.join('.*?'), 'i');
			const termsGroups = new RegExp(terms.map(term => `(${term})`).join('(.*?)'), 'i');
			list = list.map(item => {
				// TODO: allow more complex search (use fuzzaldrin maybe ? check inputWithSuggestions)
				let full = (item.description || '') + (props.labelSeparator || '.') + item.name;
				let baseSearch = full.match(allTerms);
				if (baseSearch) {
					return { ...item, score: baseSearch.index - full.length, name: highlight(item.name, termsGroups), description: highlight(item.description, termsGroups) };
				}
				let valueSearch = item.value?.match(allTerms);
				if (valueSearch) {
					return { ...item, score: valueSearch.index - item.value.length, extra: highlight(item.value, termsGroups) };
				}
				return null;
			}).filter(e => e).sort((a, b) => b.score - a.score);
			if (props.allowCustom) {
				let label = props.customOptionLabel || 'Use "%s"';
				list.unshift({ value: search, name: replaceWithVNode(label, '%s', <strong>{search}</strong>), custom: true });
			}
		}
		return list.slice(0, this.maxResult);
	}

	submit(item) {
		this.props.onSubmit?.(item);
	}

	cancel() {
		this.props.onCancel?.();
	}

	onKeyDown(e) {
		switch (e.key) {
			case 'ArrowUp':
			case 'ArrowDown':
			case 'PageUp':
			case 'PageDown':
				e.preventDefault();
				const offsetAmount = e.key.startsWith('Page') ? 10 : 1;
				const offsetDirection = e.key.endsWith('Up') ? -1 : 1;
				const maxIndex = this.state.renderedList.length - 1;
				let newIndex = clamp(this.state.selectedIndex + offsetAmount * offsetDirection, 0, maxIndex);
				if (newIndex === this.state.selectedIndex) {
					newIndex = offsetDirection > 0 ? 0 : maxIndex;
				}
				this.setState({ selectedIndex: newIndex });
				break;
			case 'Enter':
				e.preventDefault();
				if (this.state.renderedList.length) {
					this.submit(this.state.renderedList[this.state.selectedIndex]);
				}
				break;
			case 'Escape':
				e.preventDefault();
				this.cancel();
				break;
		}
	}

	onInput(e) {
		this.setState({ renderedList: this.getFilteredList(e.target.value), selectedIndex: 0 });
	}

	async onBlur() {
		// Wait for focus to move to the next element
		await new Promise(r => setTimeout(r, 0));
		if (!this.base.contains(document.activeElement)) {
			this.cancel();
		}
	}

	renderNoOptions() {
		return this.renderOption({ name: this.props.noOptionsLabel || 'No matching results', value: null, disabled: true }, 0);
	}

	renderKeys(keybinding) {
		if (!Array.isArray(keybinding)) {
			return null;
		}
		return keybinding.flatMap((key, i) => Array.isArray(key) ? this.renderKeys(key) : [
			!!i && <span class={s.keybindingSep}>+</span>,
			<span class={s.keybindingKey}>{keyNames[key] || key}</span>
		]);
	}

	renderOption(option, i) {
		let pick = option.disabled ? null : () => this.submit(option);
		let select = () => this.setState({ selectedIndex: i });
		return (
			<button class={joinClasses(s.option, i === this.state.selectedIndex && s.selected)} onClick={pick} onFocus={select} key={option.value || option.name}>
				<div class={s.label}>
					{/* {!!option.icon && <div class={s.icon}>{option.icon}</div>} */}
					<div class={s.icon}>{option.icon}</div>
					<span class={s.name}>{option.name || option.value}</span>
					<span class={s.description}>{option.description}</span>
				</div>
				<div class={s.keybinding}>{this.renderKeys(option.keybinding)}</div>
				<span class={s.extra}>{option.extra}</span>
			</button>
		);
	}

	render(props) {
		const { renderedList } = this.state;
		return (
			<div class={s.box} onFocusOut={this.onBlur}>
				<div class={s.inputWrapper}>
					<input type="text" placeholder={props.prompt} onKeyDown={this.onKeyDown} onInput={this.onInput} />
				</div>
				<div class={s.options}>
					{renderedList.length ? renderedList.map(this.renderOption) : this.renderNoOptions()}
				</div>
			</div>
		);
	}
}

export async function show(options) {
	let activeElement = document.activeElement;
	let promise = pendingPromise();
	let container = document.createElement('div');
	// container.className = s.wrapper;
	document.body.append(container);

	render(
		<QuickInput
			{...options}
			onSubmit={(value) => promise.resolve(value)}
			onCancel={() => promise.resolve(null)}
		/>,
		container
	);

	let result = await promise;

	container.remove();
	activeElement?.focus();
	return result;
}