import { h, Component } from 'preact';
import formatDateTime from 'utils/formatDateTime';
import s from './runSitesAnalytics.sss';

const DEFAULT_SHEET = '18zHXBT11O2dPzGJaD2gyWptWcBgIOp_1aba-gVfeDzQ';

export default class RunSitesAnalytics extends Component {
	constructor() {
		super();
		this.state = {
			providers: []
		};
		this.runScript = this.runScript.bind(this);
		this.cleanSheetInput = this.cleanSheetInput.bind(this);
		this.updateSheetId = this.updateSheetId.bind(this);
		this.fromChanged = this.fromChanged.bind(this);
		this.toChanged = this.toChanged.bind(this);

		let defaultTo = new Date();
		defaultTo.setHours(0, 0, 0, 0);
		let defaultFrom = new Date();
		defaultFrom.setMonth(defaultFrom.getMonth() - 1);
		defaultFrom.setHours(0, 0, 0, 0);

		this.state.from = formatDateTime(defaultFrom, 'yyyy-MM-dd');
		this.state.to = formatDateTime(defaultTo, 'yyyy-MM-dd');

		this.state.sheetId = DEFAULT_SHEET;
	}

	async runScript() {
		let envVars = [
			// TODO: add field for cfId
			this.state.cfId && { name: 'CLOUDFRONT_ID', value: this.state.cfId },
			{ name: 'DATE_FROM', value: this.state.from },
			{ name: 'DATE_TO', value: this.state.to },
			{ name: 'SHEET', value: this.$sheet.value },
		].filter(Boolean);
		let instructions = {
			command: 'aws',
			args: [
				'ecs',
				'run-task',
				'--overrides', '{ "containerOverrides": [{ "name": "powblade-analytics-prod-prod", "environment": ' + JSON.stringify(envVars) + ' }] }',
				'--task-definition', 'arn:aws:ecs:eu-west-1:002658405754:task-definition/powblade-analytics-prod-prod',
				'--cluster', 'org-prod',
				'--count', '1',
				'--launch-type', 'FARGATE',
				'--network-configuration', 'awsvpcConfiguration={subnets=[subnet-0d57c271135c932fd]}'
			],
			opts: {},
		};
		// instructions = { command: 'echo', args: ['test'] };
		this.setState({ running: true });
		await this.props.runScript({ ...this.props.script, instructions });
		this.setState({ running: false });
	}

	cleanSheetInput() {
		// Yeah... (we don't have the pasted value yet)
		setTimeout(() => {
			const match = this.$sheet.value.match(/docs\.google\.com\/spreadsheets\/d\/([^/?#]+)/i);
			if (match) {
				this.$sheet.value = match[1];
				this.updateSheetId();
			}
		}, 0);
	}
	updateSheetId() {
		this.setState({ sheetId: this.$sheet.value || DEFAULT_SHEET });
	}
	fromChanged(e) {
		this.setState({ from: e.target.value });
	}
	toChanged(e) {
		this.setState({ to: e.target.value });
	}

	render(props, state) {
		let btnDisabled = state.running;

		return (
			<div class={s.wrapper}>
				<label>
					<div class={s.description}>Spreadsheet</div>
					<input ref={e => (this.$sheet = e)} type="text" class={s.input} placeholder="(default)" onPaste={this.cleanSheetInput} onInput={this.updateSheetId} />
					<a href={'https://docs.google.com/spreadsheets/d/' + state.sheetId} target="_blank">open</a>
				</label>
				<label>
					<div class={s.description}>From</div>
					<input type="date" class={s.input} value={state.from} onInput={this.fromChanged} />
				</label>
				<label>
					<div class={s.description}>To</div>
					<input type="date" class={s.input} value={state.to} onInput={this.toChanged} />
				</label>
				<div class={s.note}>
					Notes:
					<ul>
						<li>If you use a custom sheet, it needs to be editable by <code>showtimes-data-importer@encoded-source-178411.iam.gserviceaccount.com</code> (either specifically or allowed for everyone)</li>
						<li>The ending date is NOT included in the range. For example, for all January sessions, select 1 Jan to 1 Feb</li>
					</ul>
				</div>
				<button onClick={this.runScript} disabled={btnDisabled}>Run it</button>
				{/* <div class={joinClasses(s.note, !state.showRanMessage && s.hidden)}>Note: The ending date is NOT included in the range. For example, for all events from January select 1 Jan to 1 Feb</div> */}
			</div>
		);
	}
}
