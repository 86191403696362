import { h, Component } from 'preact';
import pure from 'utils/pure';
// import schema from 'schema/schema';
import { accessNested, joinClasses, sortObjectByOrder } from 'utils/utils';
import checkCondition from 'utils/checkCondition';
import CodeEditor from 'components/shared/editor/codeEditor/codeEditor';
import * as helpers from 'components/shared/modal/addNewCustomEdit/customHelpers';

import s from 'components/shared/modal/addNewCustomEdit/addNewCustomEdit.sss';

export default @pure class AddNewCustomEdit extends Component {

	constructor(props) {
		super(props);

		this.state.current = {};

		if (props.typeData.properties) {
			Object.keys(props.typeData.properties).forEach(key => {
				const item = props.typeData.properties[key];
				if (item.value) {
					this.state[key + 'Value'] = item.value;
				}
			});
		}
	}

	openHelper = async () => {
		let helper = helpers[this.props.typeData.helper];
		if (!helper) {
			return;
		}
		let newState = await helper(this.state);
		if (newState) {
			this.setState(newState, () => this.onChangeHandler());
		}
	}

	elementChanged = (itemKey, value) => {
		const newState = {};
		if (this.props.type === 'youtubePlaylistVideo' && itemKey === 'ytid') {
			value = value.replace(/^https?:\/\/((www\.)?youtube\.(.+)[?&]v=|youtu\.be\/)([^?&/#]+).*$/i, '$4');
			// Already done with the "Assistant" button
			// if (!this.state.thumbSrcValue || this.state.thumbSrcValue === 'https://i.ytimg.com/vi/' + this.state.ytidValue + '/mqdefault.jpg') {
			// 	newState.thumbSrcValue = 'https://i.ytimg.com/vi/' + value + '/mqdefault.jpg';
			// }
		}
		if (this.props.type === 'youtubePlaylistPlaylist' && itemKey === 'playlistId') {
			value = value.replace(/^https?:\/\/((www\.)?youtube\.(.+)[?&]list=)([^?&/#]+).*$/i, '$4');
		}
		newState[itemKey + 'Value'] = value;
		this.setState(newState);

		// Calculate the value
		this.onChangeHandler();
	}

	onChangeHandler() {
		const obj = {};
		const { properties } = this.props.typeData;

		Object.keys(properties).forEach((itemKey) => {
			const item = properties[itemKey];
			if (item.default) {
				obj[itemKey] = item.default;
			} else if (this.state[`${itemKey}Value`]) {
				obj[itemKey] = this.state[`${itemKey}Value`];
			}
		});
		this.setState({ current: obj });

		if (typeof this.props.onChange === 'function') {
			this.props.onChange(obj);
		}
	}

	render() {
		let helper = '';

		// Lets see if there is a helper defined
		if (this.props.typeData.helper) {
			helper = <button onClick={this.openHelper} class={s.assistantBtn}>Assistant</button>;
		}

		return (
			<div>
				{sortObjectByOrder(this.props.typeData.properties, (item, itemKey) => {
					if (item.hiddenInAddNew) return;

					let hidden = item.hiddenInAddNew || item.hidden;
					if (hidden && typeof hidden === 'object') {
						let data = {
							...this.props.mergedValue,
							__tmpAddNewData: this.state.current
						};
						hidden = checkCondition(hidden, data, { localPath: '__tmpAddNewData.', currentAttribute: itemKey });
					}
					if (hidden) {
						return;
					}

					if (item.type === 'boolean') {
						return (
							<label class={joinClasses(s.item, s.toggle)} key={itemKey}>
								<div class={s.left}>
									<div class={s.itemTitle} key="title">{item.title || itemKey}</div>
									{!!item.description && <div class={s.itemDescription} key="description">{item.description}</div>}
								</div>
								<div class={s.checkbox} key="toggle">
									<input class={s.input} type="checkbox" checked={!!this.state[`${itemKey}Value`]} onChange={e => this.elementChanged(itemKey, e.target.checked)} />
									<div class={s.styledInput}>
										<div class={s.slider} />
									</div>
								</div>
							</label>
						);
					}

					const inputProps = {
						class: s.itemInput,
						value: this.state[`${itemKey}Value`],
						onChange: e => this.elementChanged(itemKey, e.target.value)
					};

					// If something has a default, make it non editable
					if (item.default) {
						inputProps.value = item.default;
					}
					if (item.default || item.readonly) {
						inputProps.readOnly = true;
					}

					let inputItem = <input key="input" {...inputProps} />;
					if (item.type === 'code') {
						inputItem = (
							<CodeEditor
								{...inputProps}
								class={joinClasses(inputProps.class, s.codeInput)}
								onChange={e => this.elementChanged(itemKey, e)}
								language={item.language}
								resize="vertical"
								hideMinimap
								key="codeInput"
							/>
						);
					} else if (item.multiline) {
						inputItem = <textarea key="input-multiline" {...inputProps} rows="2" />;
					}

					let suggestions;
					if (item.suggestions) {
						suggestions = Object.keys(accessNested(this.props.mergedValue, item.suggestions) || {});
					}
					if (item.options && item.options.possibilities) {
						suggestions = item.options.possibilities;
					}
					if (item.type === 'string' && suggestions && suggestions.length) {
						let selected = this.state[`${itemKey}Value`];
						inputItem = (
							<select key="input-select" {...inputProps}>
								<option value=""> --- Click to select --- </option>
								{suggestions.map(sug => {
									let { value, title } = sug;
									if (typeof sug === 'string') {
										value = sug;
										title = sug;
									}
									const optProps = {};
									// stupid hack so the right one gets selected when the elements get deleted and added again
									if (selected === sug) {
										optProps.selected = 'selected';
									}
									return <option value={value} {...optProps}>{title}</option>;
								})}
							</select>
						);
					}

					return (
						<label class={s.item} key={itemKey}>
							<div class={s.itemTitle} key="title">{item.title || itemKey}</div>
							{!!item.description && <div class={s.itemDescription} key="description">{item.description}</div>}
							{inputItem}
						</label>
					);
				})}
				{helper}
			</div>
		);
	}

}
