import { h, Component } from 'preact';
// import { redirect } from 'utils/utils';

// import { getPageTitle } from 'utils/clientUtils';

// NOTE: These is no more usecase for this file
// Should probably be removed
// Only positive is that it's nice to see a component is a page through its extends
export default class Page extends Component {

	componentWillMount() {}

	componentDidMount() {

		// NOTE: Moved this to the SET_ACTIVE_PAGE action,
		// This function can be overriden meaning page titles will be wrong

		// NOTE (anthony): When ovrriding functions we should make sure to call the parent anyway, e.g.:
		// if (typeof super.componentDidMount === 'function') super.componentDidMount(...arguments);
		// This ensures if we add things later, it will use them
	}

}
