/* SEE BELOW FOR HOW TO USE */
let isIOS = (function() {
	if (typeof navigator === 'undefined') {
		return false;
	}
	const agent = ('' + navigator.userAgent).toLowerCase();
	return agent.indexOf('ipad') !== -1 || agent.indexOf('iphone') !== -1;
})();

let now = Date.now || (() => new Date().getTime());

function getScroll(elem, hor) {
	if (elem === window) {
		return (hor ? window.pageXOffset || document.documentElement.scrollLeft : window.pageYOffset || document.documentElement.scrollTop) || 0;
	}
	return hor ? elem.scrollLeft : elem.scrollTop;
}

function setScroll(elem, v, hor) {
	if (elem === window) {
		window.scrollTo(hor ? v : getScroll(window, true), hor ? getScroll(window, false) : v);
	} else {
		elem[hor ? 'scrollLeft' : 'scrollTop'] = v;
	}
}

function getElemPos(e, parent, hor) {
	let prop = hor ? 'offsetLeft' : 'offsetTop';
	let pos = e[prop];
	let last = e;
	while ((e = e.parentElement) && e !== parent) {
		if (e === last.offsetParent) {
			last = e;
			pos += e[prop];
		}
	}
	if (parent !== window && e !== parent) return undefined;
	return pos;

	// Other possibility
	// let eBounds = e.getBoundingClientRect();
	// let pBounds = parent.getBoundingClientRect();
	// return eBounds[hor ? 'left' : 'top'] - pBounds[hor ? 'left' : 'top'];
}

function endScroll(elem) {
	let t = elem._smthscrl;
	if (!t) return;
	cancelAnimationFrame(t.timer);
	t.timer = null;
	if (typeof t.callback === 'function') {
		t.callback();
	}
	t.callback = null;
}

const relativeToKeywords = { top: 0, middle: 50, bottom: 100 };
function oneDir(elem, p, horizontal) {
	let t = elem._smthscrl;
	let currentScroll = getScroll(elem, horizontal);
	let dirIndex = horizontal ? 1 : 0;
	if (t.previousScroll[dirIndex] == undefined) {
		t.previousScroll[dirIndex] = currentScroll;
	}
	// Try to not block the user from scrolling. The user shouldn't be forced to watch the full scrolling animation
	// Disabled that on iOS as it seemed to be stopping too early
	//   (guessing some rounding or smoothscroll happens, which changes the scroll without the user touching)
	if (currentScroll !== t.previousScroll[dirIndex] && !t.force && !isIOS) {
		return endScroll(elem);
	}
	let to = t.to;
	if (Array.isArray(to)) {
		to = to[dirIndex];
	}
	if (to instanceof Element) {
		to = getElemPos(t.to, elem, horizontal);
		let sizeProp = horizontal ? 'clientWidth' : 'clientHeight';
		let rT = t.relativeTo;
		if (rT === 'view') {
			let maxPos = to - t.offsetStart;
			let minPos = to - (elem[sizeProp] - t.to[sizeProp]) + t.offsetEnd;
			to = Math.min(Math.max(currentScroll + t.offset, minPos), maxPos);
		} else {
			rT = relativeToKeywords[rT] || parseFloat(rT) || 0;
			to -= Math.max(elem[sizeProp] - t.to[sizeProp], 0) * rT * 0.01;
		}
	}
	to -= t.offset;

	let newValue = t.from[dirIndex] + p * (to - t.from[dirIndex]);
	setScroll(elem, newValue, horizontal);
	t.previousScroll[dirIndex] = getScroll(elem, horizontal);
}

const directions = {
	ver: [false],
	hor: [true],
	both: [false, true]
};
function loop(elem) {
	let t = elem._smthscrl;
	if (!t) {
		return;
	}
	// If t.timer is null, the scrolling has been stopped, don't request the next frame
	if (t.timer) {
		t.timer = requestAnimationFrame(loop.bind(this, elem));
	}
	let time = now() - t.start;
	let duration = t.duration;

	if (time >= duration) {
		time = duration;
	}
	let p = duration ? t.easing(time / duration) : 1;
	let directionsArray = directions[t.direction] || [];
	directionsArray.forEach(hor => oneDir(elem, p, hor));
	if (!duration || time >= duration) {
		return endScroll(elem);
	}
}

// elem     : element to scroll (parent container, having a scrollbar)
//   - if elem is null or the string 'root', the script will use either body or the documentElement based on which is detected to have a scrollbar
// to       : scroll to this. Can be a number (scrollTop = to at the end) or an element ! (more on that later)
// dur      : duration (in ms). Default to 300. 0 to instant scroll.
// settings : object containing
//   - callback : callback to be called after the scroll is ended (either goal reached or the user scrolled during it)
//   - direction: 'ver' (default) for vertical, 'hor' for horizontal or 'both' (in this case, to can be an array of two numbers, [verticalPos, horizontalPos])
//   - easing: a function taking a number v between 0 and 1 as argument and returning another number
//       easing(0) should be 0 and easing(1) should be 1 ! (can be used to handle acceleration)
//       default to ease-out-quad (decelerating)
//       See https://gist.github.com/gre/1650294 for a list of basic functions
//   - offset: number to be subtracted from the current scroll (useful if `to` is not a number)
//       an offset of 20 would scroll 20 pixels past the required value
//   - relativeTo: used when `to` is an element. Indicates where the element should be on the screen (works like background-position).
//       'top' (default): top of `to` is aligned with the top of elem (eq to '0%')
//       'bottom': bottom of `to` is aligned with the bottom of elem (eq to '100%')
//       'middle': middle of `to` is aligned with the middle of elem (eq to '50%')
//       'x%': aligns the xth percent of `to` with the xth percent of `elem`
//       'view': try to make the element visible (fully if possible)
//          will not scroll if the element is already fully in view
//          if to is bigger than elem, makes sure the top of to is visible
//   - offsetStart: used when `to` is an element and relativeTo is 'view'. ensure a minimum margin from the top or left, depending on the direction (can be negative)
//   - offsetEnd: used when `to` is an element and relativeTo is 'view'. ensure a minimum margin from the bottom or right, depending on the direction (can be negative)
//   - force: boolean (default false) to force the smoothScroll to finish (even if the user scrolled)
export function smoothScrollTo(elem, to, dur, settings) {
	if (elem === null || elem === 'root') {
		let doc = document;
		elem = doc.scrollingElement || (doc.compatMode === 'BackCompat' ? doc.body : doc.documentElement);
	}
	if (!elem || to === undefined) {
		return;
	}
	let t = elem._smthscrl;
	if (t && t.timer) {
		endScroll(elem);
	}
	let defaultEasing = t => 1 - (t = 1 - t) * t;
	settings = settings || {};
	let dir = 'ver';
	switch (settings.direction) {
		case 'horizontal':
		case 'hor':
			dir = 'hor';
			break;
		case 'both':
			dir = 'both';
			break;
	}
	// Legacy horizontal mode
	if (settings.horizontal) dir = 'hor';
	let currentScroll = [undefined, undefined];
	if (dir === 'ver' || dir === 'both') currentScroll[0] = getScroll(elem, false);
	if (dir === 'hor' || dir === 'both') currentScroll[1] = getScroll(elem, true);
	let start = now();
	if (settings.timeStart) {
		start = settings.timeStart < 60000 ? start + settings.timeStart : settings.timeStart;
	}
	if (typeof dur === 'function') {
		let from = dir === 'both' ? currentScroll : currentScroll[dir === 'ver' ? 0 : 1];
		dur = dur(from, to, elem);
	}
	dur = Number.isFinite(+dur) ? Math.abs(+dur) : 300;
	t = elem._smthscrl = {
		timer: null,
		easing: typeof settings.easing === 'function' ? settings.easing : defaultEasing,
		to: to,
		direction: dir,
		offset: settings.offset || 0,
		relativeTo: settings.relativeTo || 'top',
		offsetStart: settings.offsetStart || 0,
		offsetEnd: settings.offsetEnd || 0,
		from: currentScroll,
		previousScroll: [undefined, undefined],
		duration: dur,
		force: !!settings.force,
		callback: settings.callback,
		start: start
	};
	if (dur) {
		t.timer = requestAnimationFrame(loop.bind(this, elem));
	} else {
		loop(elem);
	}
	return { stop: endScroll.bind(this, elem) };
}

// Like smoothScrollTo but with v being an offset instead of an absolute value (v can't be an element)
export function smoothScrollBy(elem, v, d, settings) {
	smoothScrollTo(elem, getScroll(elem, settings && settings.horizontal) + v, d, settings);
}

// Old way to scroll to an element
// Use smoothScrollTo with v an element to achieve this now.
// export function scrollToElem(elem, elem2, d, settings) {
// 	let top = getElemPos(elem2, elem, settings.horizontal);
// 	if (top == undefined) return;
// 	smoothScrollTo(elem, top, d, settings);
// }

export function isSmoothScrolling(elem) {
	let t = elem._smthscrl;
	return t && t.timer;
}
