
/* eslint-disable no-alert */

import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import s from 'components/shared/modal/pixelPaxilModal.sss';
import pixelDetector from 'utils/pixelDetector';
import createTooltip from 'utils/tooltip';
import createNested from 'utils/createNested';
import schema from 'schema/schema';
import { accessNested, joinClasses } from 'utils/utils';

export default class PixelPaxilModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			errors: [],
			showErrors: false,
			newState: null,
			categoryValue: '',
			existingCategoryValue: '',
			trackingSystem: this.props.trackingSystem
		};
		this.handleChange = this.handleChange.bind(this);
		this.addPixel = this.addPixel.bind(this);
		this.accessNestedModified = this.accessNestedModified.bind(this);
		this.toolTipGenerator = this.toolTipGenerator.bind(this);
		this.setTrackingCategory = this.setTrackingCategory.bind(this);
	}

	componentDidMount() {
		const trackingSystem = this.state.trackingSystem;

		switch (trackingSystem) {
			case 'evidon':
				this.setState({ categoryValue: 'UNCATEGORIZED' });
				break;
			case 'oneTrust':
				this.setState({ categoryValue: 'TARGETING' });
				break;
			default:
				break;
		}
		this.getCategories(trackingSystem);
	}

	handleChange () {
		this.setState({value: event.target.value});
	}

	addPixel (code, activeData, merged)  {
		if (!code) {
			this.state.errors.push("No pixel code detected. Please ensure to paste your pixel code, then try again.");
			this.setState({ showErrors: true });
			return;
		}

		let result = pixelDetector(code, merged);
		if (!result) {
			this.state.errors.push("This pixel code could not be recognised. Please open a support ticket. ");
			this.setState({ showErrors: true });
			return;
		}
		if (result.cancelled) {
			this.state.errors.push("Pixel type could not be recognised. Please specify 'ticket', 'landing' or 'view'. ");
			this.setState({ showErrors: true });
			return;
		}
		if (result.error) {
			let message =
				"Your pixel was recognized" +
				(result.name ? " (" + result.name + ")" : "") +
				", but an error occurred while extracting the details.";
			if (typeof result.error === "string") {
				message += "\n" + result.error;
			}
			this.state.errors.push(message);
			this.setState({ showErrors: true });
			return;
		}
		if (result.edits) {
			let activeAppData = activeData;
			let currentData =
				activeAppData.edited || activeAppData.attributes;

			let baseJSON = JSON.parse(JSON.stringify(currentData));

			result.edits.forEach(edit => {
				if (!edit) return;
				let path = edit.categoryPath.split(".");
				let key = path.pop();
				let parent = this.accessNestedModified(
					baseJSON,
					path.join("."),
					{}
				);
				if (!parent) {
					parent = createNested(baseJSON,path.join("."));
				}

				// if GTM obj exists, add pixel values
				if (key === 'googleTagManager' && parent[key]) {
					parent[key] = Object.assign({...parent[key]}, edit.value);
				}
				if (key !== 'googleTagManager' || (key === 'googleTagManager' && !parent[key])) {
					parent[key] = edit.value;
				}

				if (!parent[key].trackingCategories && !parent[key].oneTrustCategory) {
					this.setTrackingCategory(parent, key);
				}
				if (parent[key].trackingCategories) {
					this.setState({ existingCategoryValue: parent[key].trackingCategories });
				}
				if (parent[key].oneTrustCategory) {
					this.setState({ existingCategoryValue: parent[key].oneTrustCategory });
				}
			});

			Store.emit(
				actions.UPDATE_EDITED_APP_DATA,
				activeData,
				baseJSON
			);
		}

		if (result.path && result.name) {
			const { existingCategoryValue } = this.state;
			if (existingCategoryValue) {
				createTooltip(
					`${result.name} successfully added (but double check just in case) and\ntracking category was already set to ${existingCategoryValue}.\n \nDon't forget to save!`,
					{ duration: 6000 }
				);
			} else {
				createTooltip(
					`${result.name} successfully added (but double check just in case).\nDon't forget to save!`,
					{ duration: 5000 }
				);
			}

			Store.emit(actions.HIDE_MODAL);
		}
	}

	setTrackingCategory(parent, key) {
		const { trackingSystem } = this.state;

		switch (trackingSystem) {
			case 'evidon':
				parent[key].trackingCategories = this.state.categoryValue;
				break;
			case 'oneTrust':
				parent[key].oneTrustCategory = this.state.categoryValue;
				break;
			default:
				break;
		}
	}

	accessNestedModified(obj, path, def) {
		if (typeof path === 'string') path = path.split('.');
		if (!Array.isArray(path)) return def; // Path is not an array
		let len = path.length;
		if (!len) return obj;
		for (let i = 0; i < len; i++) {
			let prop = path[i];
			if (!obj || !Object.prototype.hasOwnProperty.call(obj, prop)) {
				return;
			}
			obj = obj[prop];
		}
		return obj;
	}

	getCategories(trackingSystem) {
		let item;

		switch (trackingSystem) {
			case 'oneTrust':
				item = accessNested(schema, 'definitions.oneTrustCategories', []);
				break;
			default:
				break;
		}

		if (item?.options?.possibilities?.length) {
			let opts = item.options.possibilities;
			const newState = {
				newKey: 'trackingCategories',
				type: item.type,
				options: opts,
				value: opts[0] && typeof opts[0] === 'object' ? opts[0].value : opts[0]
			};

			this.setState({ newState });
		}
	}

	toolTipGenerator(e) {
		console.log(e);
	}

	getEditor() {
		const { newState, trackingSystem } = this.state;

		let editor = (
			<select class={s.select} onChange={this.linkState('categoryValue')}>
				{newState.options.map((possibility, i) => {
					const extraProps = {};
					if (!i) {
						extraProps.selected = 'selected';
					}

					if(typeof possibility === 'object') {
						const { title = '', value = '', description = '' } = possibility;
						return (
							<option
								class={s.editorOption}
								value={value}
								title={description}
								onmouseover={this.toolTipGenerator}
								{...extraProps}
							>
								{title}
							</option>);
					}

					return <option value={possibility} {...extraProps}>{possibility}</option>;
				})}
			</select>
		);

		return (
			<div class={joinClasses(s.editorContainer, s.selectEditorContainer)} key="editor">
				<p class={s.categoryTitle}>{trackingSystem === 'oneTrust' ? 'One Trust Pixel Category' : 'Pixel Category'}</p>
				{editor}
			</div>
		);

	}

	render (props) {
		const { newState } = this.state;
		const { activeData, merged } = props;
		let errorsContent, editor;

		if (newState) {
			editor = this.getEditor();
		}

		if (this.state.showErrors) {
			errorsContent = (
				<div class={s.errorWrap} key="error">
					{this.state.errors.map(err => <div class={s.error}>{err}</div>)}
				</div>
			);
		}
		return (
			<form class={s.securityModal}>
				<div class={s.securityInput}>
					<textarea rows="25" cols="70" value={this.state.value} onChange={this.handleChange} autocomplete="off" placeholder="Please add your pixel code here" />
				</div>
				{errorsContent}
				<div class={newState ? s.buttonWrapper : s.wrapper}>
					{editor}
					<div class={s.buttons}>
						<button type="button" class={s.discard} onClick={() => Store.emit(actions.HIDE_MODAL)}>Discard</button>
						<button type="button" class={s.addBtn} onClick={() => this.addPixel(this.state.value, activeData, merged)}>Add</button>
					</div>
				</div>
			</form>
		);
	}
}
