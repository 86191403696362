import Filter from 'models/Filter';

function v(val, def) {
	return val === undefined ? def : val;
}

export default class Filters {

	constructor({ format = {}, movie = {}, stDate = {}, timeOfDay = {}, exhibitor = {}, theater = {}, member = {} } = {}, copy) {

		this.format = new Filter(999, v(format.visible, true), 'format', 'label', copy.$ANY_FORMAT, format.options, format.current, format.excluded, 'slug', 'group');
		this.movie = new Filter(998, movie.visible, 'movie', 'title', copy.$ANY_MOVIE, movie.options, movie.current, movie.excluded, 'slug', 'group');

		this.stDate = new Filter(1, v(stDate.visible, true), 'stDate', 'label', copy.$ANY_DAY, stDate.options, stDate.current, stDate.excluded);
		this.timeOfDay = new Filter(2, v(timeOfDay.visible, true), 'timeOfDay', 'label', copy.$ANY_TIME, timeOfDay.options, timeOfDay.current, timeOfDay.excluded);
		this.hour = new Filter(10, false, 'hour', 'id', '', [], [], []);
		this.minute = new Filter(10, false, 'minute', 'id', '', [], [], []);

		this.exhibitor = new Filter(10, exhibitor.visible, 'exhibitor', 'domain', copy.$ANY_CHAIN, exhibitor.options, exhibitor.current, exhibitor.excluded, 'domain');
		this.ticketProvider = new Filter(10, false, 'ticketProvider', 'name', 'Any provider', [], [], [], 'id');
		this.theater = new Filter(10, theater.visible, 'theater', 'id', copy.$ANY_THEATER, theater.options, theater.current, theater.excluded);

		// We should rather do providerTheater than "webedia" (or even just check theater for a value formatted "prodider:id")
		this.webedia = new Filter(10, false, 'webedia', 'providerTheaterId', '', [], [], [], 'providerTheaterId');
		this.member = new Filter(10, member.visible, 'member', 'name', copy.$NO_MEMBER_FILTERING, member.options, member.current);

	}

}
