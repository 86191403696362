/**
 * Make an object with all the paths that exist in the given object and
 * specifying where they are coming from
 * @param	{Object} obj - The base object that it will compare to
 * @param	{Array} options - array of different object which it will use to compare to, to match with the base object
 */
export default function objectPaths(obj, options) {
	// NOTE: Options Structure [{ title:'TITLE|STRING|', object:DATA|OBJECT| },...];
	let pathsObj = {};
	createObjectPathDeep(obj, pathsObj, options);
	return pathsObj;
}

function createObjectPathDeep(obj, pathsObj, options, path = '') {
	Object.keys(obj).forEach((key) => {
		let newPath = `${path}${key}.`;
		let newOptions = options.filter(o => (
			o.object && Object.prototype.hasOwnProperty.call(o.object, key)
		));

		if (typeof obj[key] === 'object' && obj[key] !== null) {
			let newOptionsTest = newOptions.map(option => (
				{ ...option, object: option.object[key] }
			));

			createObjectPathDeep(obj[key], pathsObj, newOptionsTest, newPath);
		}

		pathsObj[newPath] = newOptions.map(o => o.title);
	});
}
