import { h, Component } from 'preact';
import pure from 'utils/pure';
import Store from 'store/store';
import { accessNested, parseDate } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import s from 'components/shared/live/live.sss';
import { Link } from 'components/core/link/link';

const resetQS = obj => Object.assign({
	app: null,
	title: null,
	studio: null,
	studio_region: null,
	region: null,
	base: null,
	branch: null
}, obj);

const relationships = {
	regions: 'region',
	studios: 'studio',
	studio_regions: 'studioRegion',
	titles: 'title',
};

export default @pure class Live extends Component {

	componentWillMount() {
		this.updateAppsList();
	}

	updateAppsList() {
		let { apps, titles, regions } = Store.get();

		// Determine whether the query is looking for studio level or regional studios
		let relationship = relationships[this.props.activeType];
		let requestedId = this.props.data.id;

		// Filter out all apps that don't match the current studio query
		let liveApps = Object.values(apps).filter(app => (
			app.rel[relationship] === requestedId && accessNested(app, 'attributes.meta.live')
		)).map(app => {
			let title = titles[app.rel.title];
			let region = regions[app.rel.region];
			return {
				id: app.id,
				release: parseDate(accessNested(app, 'attributes.meta.dates.release')),
				title: accessNested(title, 'attributes.meta.title.en') || accessNested(app, 'attributes.meta.title.locale'),
				region: accessNested(region, 'attributes.meta.region.slug'),
				url: accessNested(app, 'attributes.meta.url')
			};
		}).sort((a, b) => {
			if (!a.release) {
				return !b.release ? b.id - a.id : 1;
			}
			return !b.release ? -1 : b.release - a.release;
		});

		this.setState({ liveApps });
	}

	/**
	 * Render
	**/

	render(props, state) {
		if (!state.liveApps?.length) {
			return <div class={s.empty}>No apps available</div>;
		}
		return (
			<div class={s.container}>
				{state.liveApps.map(app => (
					<div class={s.row} key={app.id}>
						<div class={s.details}>
							<div class={s.title} key="title">
								{app.title}<span class={s.region}>{app.region}</span>
							</div>
							<div class={s.subrow}>
								{!!app.url && <a class={s.link} target="_blank" href={app.url} key="url">{app.url}</a>}
								{!!app.release && <span class={s.date}>release: {formatDateTime(app.release, 'yyyy-MM-dd')}</span>}
							</div>
						</div>
						<Link
							class={s.editButton}
							title={app.title}
							pageId="editApp"
							queryString={resetQS({ app: app.id })}
							renderCustomChild
						>
							Edit
						</Link>
					</div>
				))}
			</div>
		);
	}

}
