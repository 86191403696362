import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';

import slug from 'slugify';
import pure from 'utils/pure';
import { joinClasses, triggerAnim } from 'utils/utils';

import { Link, goToPage } from 'components/core/link/link';

import s from 'components/shared/editor/formEditor/formBranch.sss';

export default @pure class FormBranch extends Component {

	constructor() {
		super();
		this.state.dropdownActive = false;

		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.addNewBranch = this.addNewBranch.bind(this);
	}

	toggleDropdown() {
		this.setState({ dropdownActive: !this.state.dropdownActive });
	}

	removeBranch(branch) {
		let modalSubmit = e => {
			e.preventDefault();
			let elem = e.target.elements.confirmation;
			if (elem?.value !== 'DELETE') {
				elem.focus();
				triggerAnim(elem, s.error);
				return;
			}
			Store.emit(actions.HIDE_MODAL, 'OK');
		};
		const modal = (
			<form class={s.deleteContainer} onSubmit={modalSubmit}>
				<div class={s.deleteTitle}>
					Deleting the <strong>{this.props.branches[branch].name}</strong> branch will remove all of the data saved in that branch. This CANNOT be undone.
				</div>
				<div class={s.deleteConfirm}>
					<input class={s.deleteInput} name="confirmation" value="" placeholder="type 'DELETE' to proceed" autocomplete="off" pattern="DELETE" required />
					<button class={s.deleteBtn} type="submit">Delete</button>
					<button class={s.deleteBtnCancel} onClick={() => Store.emit(actions.HIDE_MODAL)}>Cancel</button>
				</div>
			</form>
		);

		Store.emit(actions.SHOW_MODAL, modal, 'Delete Branch', 'deleteBranchModal', (v) => {
			if (v !== 'OK') return;
			goToPage('editApp', { app: this.props.appId, branch: null });
			this.props.updateJSON(`dev.branches.${branch}`, null, false, false, true);
		});
	}

	async addNewBranch(e) {
		e.preventDefault();
		const branchName = this.state.newBranchName;
		if (!branchName) {
			return;
		}
		const branchSlug = slug(branchName, { lower: true, remove: /[^\w\s-]/g });

		// if (this.props.edited) {
		// 	alert('Save edited app data before creating a branch'); // eslint-disable-line no-alert
		// 	return;
		// }

		if (Object.keys(this.props.branches || {}).includes(branchSlug) || branchSlug === 'production') {
			alert('The branch name is already used for a different branch'); // eslint-disable-line no-alert
			return;
		}

		this.props.updateJSON(`dev.branches.${branchSlug}`, { content: {}, name: branchName }, false, false, true);

		goToPage('editApp', { branch: branchSlug });

		// Save the data ?
		// await this.props.saveData();

		this.setState({ dropdownActive: false });
	}

	renderRemoveButton(branch) {
		// onclick should be on a button wrapping the icon
		return (
			<button class={s.removeBtn} onClick={() => this.removeBranch(branch)}>
				<svg viewBox="0 0 1000 1000">
					<path d="M854.8,111.4H601.4V60.7c0-28-22.7-50.7-50.7-50.7H449.3c-28,0-50.7,22.7-50.7,50.7v50.7H145.2c-28,0-50.7,22.7-50.7,50.7v50.7h811v-50.7C905.5,134.1,882.8,111.4,854.8,111.4z M145.2,939.3c0,28,22.7,50.7,50.7,50.7h608.3c28,0,50.7-22.7,50.7-50.7V263.4H145.2V939.3z M652.1,415.5c0-28,22.7-50.7,50.7-50.7s50.7,22.7,50.7,50.7v422.4c0,28-22.7,50.7-50.7,50.7s-50.7-22.7-50.7-50.7V415.5z M449.3,415.5c0-28,22.7-50.7,50.7-50.7s50.7,22.7,50.7,50.7v422.4c0,28-22.7,50.7-50.7,50.7s-50.7-22.7-50.7-50.7V415.5z M246.6,415.5c0-28,22.7-50.7,50.7-50.7s50.7,22.7,50.7,50.7v422.4c0,28-22.7,50.7-50.7,50.7s-50.7-22.7-50.7-50.7V415.5z"/>
				</svg>
			</button>
		);
	}

	renderDropdown() {
		const branches = this.props.branches;
		const activeBranch = this.props.activeBranch;

		return [
			<div class={s.backdrop} onClick={this.toggleDropdown} />,
			<div class={s.dropdown}>
				<form class={s.dropdownHeader} onSubmit={this.addNewBranch}>
					<h3 class={s.dropdownTitle}>Add Branch</h3>
					<div class={s.dropdownAdd}>
						<input
							class={s.dropdownInput}
							placeholder="Enter version name"
							onInput={this.linkState('newBranchName')}
							ref={$ref => this.$addInput = $ref}
						/>
						<button
							class={joinClasses(s.dropdownAddButton, !this.state.newBranchName && s.disabled)}
							type="submit"
						>
							Add
						</button>
					</div>
				</form>
				<div class={s.dropdownList}>
					<div class={s.dropdownItem}>
						<Link
							pageId="editApp"
							queryString={{ branch: null }}
							extraOnClick={this.toggleDropdown}
							class={joinClasses(s.dropdownItemLink, !activeBranch && s.selected)}
						>
							Production
						</Link>
					</div>
					{Object.entries(branches || {}).map(([branch, data]) => (
						<div class={s.dropdownItem} key={branch}>
							<Link
								pageId="editApp"
								queryString={{ branch }}
								extraOnClick={this.toggleDropdown}
								class={joinClasses(s.dropdownItemLink, branch === activeBranch?.id && s.selected)}
							>
								{data?.name}
							</Link>
							{this.renderRemoveButton(branch)}
						</div>
					))}
				</div>
			</div>
		];
	}

	render(props) {
		return (
			<div class={s.container}>
				{this.state.dropdownActive && this.renderDropdown()}
				<div class={s.button} onClick={this.toggleDropdown}>{props.activeBranch?.name || 'Production'}</div>
			</div>
		);
	}

}
