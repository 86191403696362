export default function route(url, pageId) {
	if (typeof history !== 'undefined') {

		// console.log('push state', pageId);
		// Push the new url to the pushState
		// the history listen even in the update file will take care of the rest
		// debugger;
		history.pushState({ pageId }, '', url);
	}
}
