import { h, Component } from 'preact';
import isEqual from 'lodash/isEqual';
import pure from 'utils/pure';
import Store from 'store/store';
import * as actions from 'store/actions';
import { Link, goToPage } from 'components/core/link/link';
import { joinClasses, parseDate } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import dateDistance from 'utils/dateDistance.mjs';
import { apiGet } from 'services/dataApi';

import s from 'components/pages/apps/appList.sss';

const defaultActiveKeys = {
	title: true,
	created: true,
	release: true,
	firstDeploy: false,
	lastDeploy: false,
	screenings: false,
	visits: false,
	activeSessions: false,
	status: true,
	url: false,
};

const resetQS = obj => Object.assign({
	app: null,
	title: null,
	studio: null,
	studio_region: null,
	region: null,
	base: null,
	branch: null
}, obj);

const weirdFlags = {
	intl: '🗺️',
	mde: '🗺️',
	lam: '🗺️',
	cam: '🗺️'
};
// From https://github.com/thekelvinliu/country-code-emoji
let getFlag = country => weirdFlags[country] || String.fromCodePoint(...[...country.slice(0, 2).toUpperCase()].map(c => c.charCodeAt() + 127397));

let csvValue = val => (val == undefined ? '' : !/[,\t"]/.test(val) ? val : '"' + (val + '').replace(/"/g, '""') + '"');

const defaultRenderers = {
	string: v => v,
	number: v => (+v || 0).toLocaleString(),
	date: (v, meta) => {
		const date = parseDate(v);
		if (!date) {
			return '-';
		}
		return (
			<time dateTime={date?.toISOString()} title={formatDateTime(date, `yyyy-MM-dd${meta?.hideTime ? '' : ' HH:mm'}`)}>{dateDistance(date)}</time>
		);
	},
};
const defaultCSVRenderers = {
	string: v => v,
	number: v => +v || 0,
	date: (v, meta) => formatDateTime(parseDate(v), `yyyy-MM-dd${meta?.hideTime ? '' : ' HH:mm'}`),
};
const getRenderer = (meta) => typeof meta?.render === 'function' ? meta.render : (item, key) => (defaultRenderers[meta?.type] || defaultRenderers.string)(item[key], meta);
const getCSVRenderer = (meta) => typeof meta?.csvValue === 'function' ? meta.csvValue : (item, key) => (defaultCSVRenderers[meta?.type] || defaultCSVRenderers.string)(item[key], meta);

export default @pure class AppList extends Component {

	constructor() {
		super();
		this.appListScrolled = this.appListScrolled.bind(this);
		this.setSortBy = this.setSortBy.bind(this);
		this.changeBoltFilter = this.changeBoltFilter.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.exportCsv = this.exportCsv.bind(this);
		this.toggleColumn = this.toggleColumn.bind(this);
		this.resetColumns = this.resetColumns.bind(this);
		this.renderColumnsButton = this.renderColumnsButton.bind(this);
		this.changeFirstDeployStartFilter = this.updateFilterValueFactory('firstDeployFilterStart');
		this.changeFirstDeployEndFilter = this.updateFilterValueFactory('firstDeployFilterEnd');
		this.changeLastDeployStartFilter = this.updateFilterValueFactory('lastDeployFilterStart');
		this.changeLastDeployEndFilter = this.updateFilterValueFactory('lastDeployFilterEnd');

		this.state.data = [];
		this.state.filteredData = [];
		this.state.sortBy = 'title';
		this.state.sortDir = 1;
		this.state.numVisible = 100;
		this.state.firstDeployFilterStart = '';
		this.state.firstDeployFilterEnd = '';
		this.state.lastDeployFilterStart = '';
		this.state.lastDeployFilterEnd = '';
		this.state.boltFilter = null;
		this.state.showFilters = false;
		this.state.activeKeys = { ...defaultActiveKeys };
		this.state.appsStatus = {};

		this.keys = Object.keys(defaultActiveKeys).concat('actions');
		this.keysMeta = {
			title: {
				title: 'Title',
				csvTitle: ['studio', 'title', 'region'],
				csvValue: item => [item.title, item.region, item.studio],
				render: (item) => {
					let region = item.region;
					let displayedRegion = getFlag(region) + ' ' + region;
					return [
						item.live ? <div class={s.liveIndicator} key="live" /> : undefined,
						<h3 class={s.title} key="title">
							<Link class={s.action} title={item.title} pageId="editApp" queryString={resetQS({ app: item.appId })} data-app={item.appId}>{item.title}</Link>
						</h3>,
						<div class={joinClasses(s.studioRegion, item.bolt && s.bolt)} key="studioregion">{item.studio} - {displayedRegion}</div>
					];
				}
			},
			created: {
				title: 'App Creation',
				type: 'date',
			},
			release: {
				title: 'Release Date',
				type: 'date',
				hideTime: true,
			},
			firstDeploy: {
				title: 'First Deploy',
				type: 'date',
			},
			lastDeploy: {
				title: 'Latest Deploy',
				type: 'date',
			},
			screenings: {
				title: 'Screenings',
				type: 'number',
				warning: 'Using legacy data - may not be accurate',
				render: (item) => (
					<Link class={s.action} pageId="screenings" queryString={resetQS({ app: item.appId })}>
						{(+item.screenings || 0).toLocaleString()}
					</Link>
				)
			},
			visits: {
				title: 'Visits',
				type: 'number',
				warning: 'Using legacy data - may not be accurate',
				render: (item) => (
					<Link class={s.action} pageId="insights" queryString={resetQS({ app: item.appId })}>
						{(+item.visits || 0).toLocaleString()}
					</Link>
				)
			},
			activeSessions: {
				title: 'Active Sessions',
				type: 'number',
				warning: 'Using legacy data - may not be accurate',
			},
			url: {
				title: 'URL',
				render: item => item.url ? <Link href={item.url} target="_blank" rel="noreferrer">{item.url.replace(/^https?:\/\/(www\.)?/i, '')}</Link> : '-'
			},
			status: { title: 'Site Status' },
			actions: {
				title: 'Actions',
				csvHidden: true,
			},
		};
		this.didLuckyRedirect = false;

		Store.on(actions.APP_LIST_SCROLLED, this.appListScrolled);
	}

	componentDidMount() {
		let data = this.getData(this.props);
		let filteredData = this.getFilteredData(this.props, this.state, data);
		let activeKeys = this.state.activeKeys;
		try {
			let localStoreageKeys = JSON.parse(localStorage.getItem('activeKeys'));
			Object.assign(activeKeys, localStoreageKeys);
		} catch (e) { /* */ }
		this.setState({
			data: data,
			filteredData: filteredData,
			activeKeys: activeKeys
		});
		// Cache ?
		apiGet('/proxy/stdata/thundr/app_overview').then(response => {
			this.setState({ appsStatus: response });
			this.updateData();
		});
	}

	componentWillUnmount() {
		Store.off(actions.APP_LIST_SCROLLED, this.appListScrolled);
	}

	componentWillReceiveProps(nextProps) {
		let data = this.state.data;
		let filteredData = this.state.filteredData;
		// Which props are triggering a data update
		let dataUpdateProps = ['list', 'screeningCounts', 'visitCounts', 'activeSessionCounts'];
		let dataChanged = dataUpdateProps.some(prop => !isEqual(nextProps[prop], this.props[prop]));
		if (dataChanged) {
			this.updateData(nextProps);
		} else if (nextProps.filter !== this.props.filter) {
			this.setState({ filteredData: this.getFilteredData(nextProps, this.state, data) });
		}
		if (nextProps.imfeelinglucky && filteredData.length && !this.didLuckyRedirect) {
			this.didLuckyRedirect = true;
			this.editApp(0);
		}
	}

	updateData(props = this.props) {
		const data = this.getData(props);
		this.setState({ data, filteredData: this.getFilteredData(props, this.state, data) });
	}

	appListScrolled(e) {
		let state = this.state;
		let el = e.target;
		if (el.scrollTop > el.scrollHeight - el.clientHeight - 10 && state.filteredData.length > state.numVisible) {
			this.setState({ numVisible: state.numVisible + 50 });
		}
	}

	editApp(app) {
		if (typeof app === 'number') {
			app = this.state.filteredData[app];
		}
		if (!app) {
			return;
		}
		goToPage('editApp', { app: app.appId });
	}

	getData(props) {
		props = props || this.props;
		return Object.values(props.list.apps).map(app => {
			let ids = {};
			let rels = {};
			let slugs = {};
			['title', 'studio', 'region'].forEach(type => {
				let id = app.rel[type];
				let rel = props.list[type + 's'][id];
				let slug = rel?.slug;
				if (!slug) {
					console.error('RELATIONSHIP ERROR', app, type, id, rel, slug);
				}
				ids[type] = id;
				rels[type] = rel;
				slugs[type] = slug;
			});

			let url = app.url;
			let liveLink = <span class={s.noLink}>&#8599;</span>;
			if (url) {
				liveLink = <a href={url} class={joinClasses(s.action, s.vanity)} target="_blank" rel="noreferrer">&#8599;</a>;
			}

			let key = slugs.title + '|' + slugs.region;
			// slicedKey = ISO region2 e.g. be for be_fr and be_nl (used for screenings count)
			let slicedKey = slugs.title + '|' + slugs.region?.slice(0, 2);

			let appQS = resetQS({ app: app.id });
			let titleQS = resetQS({ title: ids.title });

			return {
				appId: app.id,
				bolt: app.rel.base === 2,
				title: rels.title?.en,
				studio: rels.studio?.name,
				region: slugs.region || '',
				titleSlug: slugs.title || '',
				studioSlug: slugs.studio || '',
				live: !!app.live,
				created: app.createdAt,
				release: app.release,
				firstDeploy: app.history?.at(0)?.at,
				lastDeploy: app.history?.at(-1)?.at,
				screenings: props.screeningCounts[slicedKey] || 0,
				visits: props.visitCounts[key] || 0,
				activeSessions: props.activeSessionCounts[key] || 0,
				url,
				status: this.state.appsStatus[app.id] || 'unknown',
				history: app.history,
				actions: (
					<span class={s.actions}>
						{liveLink}
						<Link class={s.action} pageId="editApp" queryString={appQS}>edit</Link>
						<Link class={s.action} pageId="matchTitle" queryString={titleQS}>match</Link>
					</span>
				),
			};
		});
	}

	getFilteredData(props, state, data) {
		props = props || this.props;
		state = state || this.state;
		data = data || state.data;
		let { filter } = props;
		let { sortBy, sortDir } = state;
		if (filter) {
			let filters = filter.split(',').map(v => v.trim());
			data = data.filter(this.filterApps(filters));
		}

		let checkRange = (data, startStr, endStr, getAttribute) => {
			const start = startStr && parseDate(startStr);
			const end = endStr && parseDate(endStr);
			if (!start && !end) {
				return data;
			}
			if (end) {
				end.setDate(end.getDate() + 1);
			}
			return data.filter(app => {
				let val = getAttribute(app);
				return val && (!start || val >= start) && (!end || val < end);
			});
		};

		data = checkRange(data, state.firstDeployFilterStart, state.firstDeployFilterEnd, app => app.history?.[0]?.at);
		data = checkRange(data, state.lastDeployFilterStart, state.lastDeployFilterEnd, app => app.history?.[app.history?.length - 1]?.at);

		if (typeof state.boltFilter === 'boolean') {
			data = data.filter(app => !!app.bolt === state.boltFilter);
		}

		if (sortBy) {
			data = data.sort(sortAlpha.bind(this, sortBy, this.keysMeta[sortBy]?.type || 'string', sortDir));
		}

		return data;
	}

	exportCsv() {
		const { activeKeys } = this.state;
		// contains active keys
		let keys = Object.keys(activeKeys).filter(k => activeKeys[k] && !this.keysMeta[k]?.csvHidden);

		// Add the first row of CSV file
		let csvLines = [
			keys.flatMap(k => this.keysMeta[k]?.csvTitle || k),
			...this.state.filteredData.map((app, i) => (
				keys.flatMap(k => getCSVRenderer(this.keysMeta[k])(app, k, i))
			))
		];

		const csvContent = csvLines.map(line => line.map(csvValue).join(',')).join('\n');
		const data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
		const filename = `thundr_data_export_${formatDateTime(new Date(), 'yyyy\\-MM\\-dd')}.csv`;

		let link = document.createElement('a');
		link.setAttribute('href', data);
		link.setAttribute('download', filename);
		link.style.display = 'none';
		// firefox needs the element in the page
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	stopPropagation(e) {
		e.stopPropagation();
	}

	toggleColumn(e) {
		e.stopPropagation();
		if (e.target.value) {
			let activeKeys = { ...this.state.activeKeys };
			activeKeys[e.target.value] = e.target.checked;
			this.setState({ activeKeys });
			try {
				localStorage.setItem('activeKeys', JSON.stringify(this.state.activeKeys));
			} catch (e) { /* */ }
		}
	}

	resetColumns() {
		this.setState({ activeKeys: { ...defaultActiveKeys } });
		try {
			localStorage.removeItem('activeKeys');
		} catch { /* */ }
	}

	setSortBy(key) {
		let sortBy = this.state.sortBy;
		let sortDir = this.state.sortDir;
		if (this.state.sortBy === key) {
			sortDir *= -1;
		} else {
			sortBy = key;
		}
		let newState = Object.assign({}, this.state, { sortDir, sortBy });
		let filteredData = this.getFilteredData(this.props, newState);
		this.setState({ sortDir, sortBy, filteredData, numVisible: 100 });
	}

	toggleFilters() {
		this.setState({
			showFilters: !this.state.showFilters,
			filteredData: this.getFilteredData(this.props)
		});
	}

	updateFilterValueFactory(stateName) {
		return e => {
			this.setState({ [stateName]: e.srcElement.value });
			// Relies on Preact's synchronous state update
			this.setState({ filteredData: this.getFilteredData() });
		};
	}

	changeBoltFilter(e) {
		let val = { true: true, false: false, any: null }[e.target.value];
		if (val == undefined) val = null;
		if (val !== this.state.boltFilter) {
			this.setState({ boltFilter: val });
			// Relies on Preact's synchronous state update
			this.setState({ filteredData: this.getFilteredData() });
		}
	}

	renderColumnsButton() {
		const { activeKeys } = this.state;
		return [
			<button class={s.columns} popovertarget="columns-selection">Columns</button>,
			<div id="columns-selection" class={s.columnsDropdown} popover="auto">
				<button class={s.reset} onClick={this.resetColumns}>🔄 Reset to default</button>
				{this.keys
					.filter(key => !['title', 'studio', 'region', 'actions'].includes(key))
					// .sort()
					.map(key => (
						<label class={s.columnSelection}>
							<input
								type="checkbox"
								name={key}
								value={key}
								checked={activeKeys[key]}
								onChange={this.toggleColumn}
							/>
							{this.keysMeta[key]?.title || key}
						</label>
					))}
			</div>
		];
	}

	render({ minimal }, { filteredData, sortBy, activeKeys, showFilters, boltFilter }) {
		let filteredKeys = this.keys.filter(key => key !== 'actions' && activeKeys[key]);
		if (minimal) {
			filteredKeys = filteredKeys.filter(k => ['title', 'release'].includes(k));
		}
		filteredKeys.push('actions');

		let colClass = key => key && s['col' + key[0].toUpperCase() + key.slice(1)];
		return (
			<div class={minimal ? s.minimalListContainer : s.listContainer}>
				<div class={s.buttons} key="buttons">
					<div class={s.buttonsLeft}>
						{this.renderColumnsButton()}
						<button class={joinClasses(s.historyToggle, showFilters && s.on)} onClick={this.toggleFilters}>{showFilters ? 'Hide App Filters' : 'Filter Apps'}</button>
						<button class={s.exportData} onClick={this.exportCsv}>Export Data</button>
					</div>
					<div class={s.buttonsRight}>
						<Link class={s.newApp} pageId="newApp" queryString={resetQS()}>New App</Link>
					</div>
					{showFilters && (
						<div class={s.filtersWrapper} key="filters">
							<div class={joinClasses(s.filter, s.bolt)}>
								<div class={s.text}>Bolt</div>
								<div class={s.value}>
									<input type="radio" name="filterbolt" id="filterboltyes" value="true" onChange={this.changeBoltFilter} checked={boltFilter === true} />
									<label for="filterboltyes">Yes</label>
									<input type="radio" name="filterbolt" id="filterboltno" value="false" onChange={this.changeBoltFilter} checked={boltFilter === false} />
									<label for="filterboltno">No</label>
									<input type="radio" name="filterbolt" id="filterboltany" value="any" onChange={this.changeBoltFilter} checked={typeof boltFilter !== 'boolean'} />
									<label for="filterboltany">Any</label>
								</div>
							</div>
							<div class={joinClasses(s.filter, s.firstDeploy)}>
								<div class={s.text}>First deploy</div>
								<div class={joinClasses(s.value, s.dateRange)}>
									<label class={s.start}>
										From: <input class={s.dateFilter} type="date" value={this.state.firstDeployFilterStart} onChange={this.changeFirstDeployStartFilter} />
									</label>
									<label class={s.end}>
										To: <input class={s.dateFilter} type="date" value={this.state.firstDeployFilterEnd} onChange={this.changeFirstDeployEndFilter} />
									</label>
								</div>
							</div>
							<div class={joinClasses(s.filter, s.lastDeploy)}>
								<div class={s.text}>Last deploy</div>
								<div class={joinClasses(s.value, s.dateRange)}>
									<label class={s.start}>
										From: <input class={s.dateFilter} type="date" value={this.state.lastDeployFilterStart} onChange={this.changeLastDeployStartFilter} />
									</label>
									<label class={s.end}>
										To: <input class={s.dateFilter} type="date" value={this.state.lastDeployFilterEnd} onChange={this.changeLastDeployEndFilter} />
									</label>
								</div>
							</div>
						</div>
					)}
				</div>
				<table key="table" style={`--columns: ${filteredKeys.length};`}>
					<tr class={joinClasses(s.row, s.listHeader)}>
						{filteredKeys.map(key => {
							const meta = this.keysMeta[key];
							return (
								<td class={joinClasses(s.col, sortBy === key && s.active, colClass(key))} onClick={key !== 'actions' && this.setSortBy.bind(this, key)} key={key}>
									{!!meta?.warning && <span class={s.warning} data-text={meta.warning} />}
									{meta?.title || key}
								</td>
							);
						})}
					</tr>
					{filteredData.slice(0, this.state.numVisible).map((item, rowIdx) => (
						<tr class={s.row} key={item.appId}>
							{filteredKeys.map(key => (
								<td class={joinClasses(s.col, s[`type-${this.keysMeta[key]?.type}`], colClass(key))} key={key}>
									{getRenderer(this.keysMeta[key])(item, key, rowIdx)}
								</td>
							))}
						</tr>
					))}
				</table>
			</div>
		);
	}

	// ---- /!\ This function may be doing way more than what we actually need (but it's pretty cool, you gotta admit) ----
	// Filter behaviour (for each comma-separated parts):
	// text   : fuzzy search text in the fields title, titleInitials, region, studio, titleSlug, studioSlug OR exact appId
	// ~text  : same as above
	// ^text  : one of the fields must start with text
	// $text  : one of the fields must end with text
	// =text  : text must be an exact match of one of the fields
	// |text  : change the case sensitivity of the filter. Case-insensitive by default, when | is added the filter must match the field case
	// !text  : invert the filter. Used alone = text must not be part of any of the fields
	// !=text : no field can be exactly text
	// ... (any combination of ! or | with another indicator)
	// you can also specify a field: "region:us" will only find a movie for which the region is us, will not match even if its name is "Us"
	// (trick: you can also specify multiple fields like in "title:region:us")
	// If you want your text to start with one of these special chars, prepend it with ~, for example "~~abc" will match "thing~abc other thing" but not "hey abc hey"
	// Examples:
	// - "Fifty Shades, !grey" will match "fifty shades darker" but not "fifty shades of grey"
	// - "!~!" will match every movie that does not contain an exclamation point anywhere
	// - "universal, !dm3" will match universal movies that are not despicable me 3
	// - "us, !=us" will match movies that contain "us" (in the title, initials or studio name), but not any movie in the region us or not a movie that would just be called "Us"
	// - "us, region:!us" will match movies that contain "us" (including a movie that would just be called "Us") but are not in the region us
	filterApps(filter) {
		let compareFunctions = {
			partial: (s, v) => s.includes(v),
			start: (s, v) => s.startsWith(v),
			end: (s, v) => s.endsWith(v),
			exact: (s, v) => s === v,
			greater: (s, v) => s > v,
			lower: (s, v) => s < v,
		};
		const simpleFields = ['title', 'region', 'studio', 'titleSlug', 'studioSlug'];
		const simpleFieldsExcludedByDefault = ['status', 'created', 'release'];
		let fields = [
			...simpleFields.map(name => ({ name })),
			...simpleFieldsExcludedByDefault.map(name => ({ name, defaultExclude: true })),
			{ name: 'url', defaultExclude: (filter) => !filter.includes('.') },
			{ name: 'titleInitials', computed: app => app.titleSlug.split('-').map(e => e.replace(/^(([0-9]+).*|([a-z]?).*)$/i, '$2$3')).join('') },
			{ name: 'id', property: 'appId', compare: compareFunctions.exact }
		];
		let indicators = fields.map(f => ({ code: f.name.toLowerCase() + ':', includeField: f.name, continue: true })).concat([
			{ code: '!', negative: 'toggle', continue: true },
			{ code: '|', case: 'toggle', continue: true, excludeField: ['titleSlug', 'studioSlug'] },
			{ code: '~', fn: 'partial' },
			{ code: '^', fn: 'start' },
			{ code: '$', fn: 'end' },
			{ code: '=', fn: 'exact' },
			{ code: '>', fn: 'greater' },
			{ code: '<', fn: 'lower' },
		]);
		return (app) => filter.every(f => {
			let compare = compareFunctions.partial;
			let negative = false;
			let caseSensitive = false;
			let fieldsList = [];
			let excludedFields = [];
			for (let i = 0, l = indicators.length; i < l; i++) {
				let ind = indicators[i];
				if (f.toLowerCase().startsWith(ind.code)) {
					f = f.slice(ind.code.length);
					if ('negative' in ind) negative = ind.negative === 'toggle' ? !negative : !!ind.negative;
					if ('case' in ind) caseSensitive = ind.case === 'toggle' ? !caseSensitive : !!ind.case;
					if ('fn' in ind) compare = typeof ind.fn === 'function' ? ind.fn : (compareFunctions[ind.fn] || compareFunctions.partial);
					if (ind.includeField) fieldsList = fieldsList.concat(ind.includeField);
					if (ind.excludeField) excludedFields = excludedFields.concat(ind.excludeField);
					if (!ind.continue) {
						break;
					} else {
						i = -1; // Recheck all the indicators
					}
				}
			}
			if (!caseSensitive) f = f.toLowerCase();
			return negative !== fields.some(e => {
				if (fieldsList.length && !fieldsList.includes(e.name)) return false;
				if (!fieldsList.length && maybeFunction(e.defaultExclude, f, e)) return false;
				if (excludedFields.indexOf(e.name) !== -1) return false;
				let str = (typeof e.computed === 'function' ? e.computed(app) : app[e.property || e.name]) + '';
				if (!caseSensitive) str = str.toLowerCase();
				return (e.compare || compare)(str, f);
			});
		});
	}
}

function maybeFunction(fn, ...args) {
	return typeof fn === 'function' ? fn(...args) : fn;
}

const sortFallback = ['title', 'region', 'studio'];
function sortAlpha(attr, type, dir, a, b) {
	let attrA = a[attr];
	let attrB = b[attr];
	let diff = 0;
	if (type === 'string') {
		diff = (attrA || '').localeCompare(attrB, { sensitivity: 'base' });
	} else {
		if (type === 'date') {
			attrA = parseDate(attrA) || 0;
			attrB = parseDate(attrB) || 0;
		}
		diff = (+attrA || 0) - (+attrB || 0);
	}
	if (diff !== 0) return diff * dir;
	let next = sortFallback.at(sortFallback.indexOf(attr) + 1);
	return (next && a[next]?.localeCompare(b[next], { sensitivity: 'base' })) || 0;
}
