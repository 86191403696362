import { h, Component } from 'preact';
import s from 'components/pages/scripts/scripts.sss';

export default class RunButton extends Component {
	constructor() {
		super();
		this.state = {
			disabled: false,
		};
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if (typeof this.props.onClick !== 'function') {
			return;
		}
		this.setState({ disabled: true });
		Promise.resolve(this.props.onClick()).then(() => this.setState({ disabled: false }));
	}

	render() {
		return (
			<button class={s.button} onClick={this.onClick} disabled={this.state.disabled}>
				{this.props.children}
			</button>
		);
	}
}
