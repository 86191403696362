import { h } from 'preact';
import formatDateTime from 'utils/formatDateTime';
import pure from 'utils/pure';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import s from 'components/shared/editor/formEditor/formEditor.sss';


export default @pure class DateAttribute extends DisplayAttribute {

	constructor(props) {
		super(props);
		this.computeDateValue(this.state.value);
	}

	computeDateValue(v) {
		let date = new Date(v);
		if (isNaN(+date)) date = new Date();
		const inputFormatDate = formatDateTime(date, 'yyyy-MM-dd\\THH:mm');
		this.fieldValue = inputFormatDate;
	}

	getInput = (value, description) => {
		return (
			<input
				type="datetime-local"
				value={this.fieldValue}
				class={s.input}
				ref={$ref => this.$input = $ref}
				onChange={this.updateTempValue}
				onBlur={this.onDateChange}
				placeholder={description}
			/>
		);
	}

	updateTempValue = () => {
		this.fieldValue = this.$input.value;
	}

	onDateChange = () => {
		const parsed = this.$input.value.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/);
		const newVal = !parsed ? 0 : new Date(+parsed[1], parsed[2] - 1, +parsed[3], +parsed[4], +parsed[5]).getTime();
		this.setState({ value: newVal });
		this.onSave();
	}

}
