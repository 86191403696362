import { h } from 'preact';

const Tumblr = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" role="img" {...props}>
			<path d="M11.732 11.117a.232.232 0 00-.236.01c-.536.353-1.08.531-1.616.531-.288 0-.536-.064-.758-.197a.84.84 0 01-.341-.381c-.049-.129-.048-.477-.047-.958V7.189h2.437a.231.231 0 00.231-.231V4.899a.231.231 0 00-.231-.231H8.733V2.231A.23.23 0 008.502 2H6.861a.23.23 0 00-.229.203c-.069.564-.197 1.033-.381 1.394a2.95 2.95 0 01-.722.92c-.24.205-.652.403-1.223.589a.23.23 0 00-.159.219v1.634c0 .127.103.231.231.231h1.336v3.815c0 .562.06.985.182 1.294.125.313.347.608.66.877.308.264.683.47 1.115.614.42.136.753.21 1.316.21.476 0 .929-.049 1.348-.147a7.1 7.1 0 001.385-.505.23.23 0 00.133-.209V11.32a.232.232 0 00-.121-.203z" />
		</svg>
	);
};

export default Tumblr;
