import { h, Component } from 'preact';
import pure from 'utils/pure';
import { accessNested } from 'utils/utils';
import * as dataApi from 'services/dataApi';
// import values from 'lodash/values';
import * as actions from 'store/actions';
// import deserialise from 'utils/deserialise';
import s from './insights.sss';
// import cloneDeep from "lodash/cloneDeep";
import Table from 'components/shared/table/table.js';
import moment from 'moment';

function addToQuery(q, path, o) {
	if (typeof o !== 'object') {
		return q[path] = o;
	}
	let ks = Object.keys(o);
	ks.forEach(k => addToQuery(q, path + '['+k+']', o[k]));
}

export default @pure class Insights extends Component {

	constructor() {
		super();
		this.events = [
			{ slug: 'get_tickets_exhibitor', description: 'Top 20 theaters' },
			{ slug: 'location', description: 'Top 20 geolocated cities' },
			{ slug: 'location_search', description: 'Top 20 location searches' },
			{ slug: 'selected_ticket_provider_from_choices', description: 'Top interstitial providers' },
			{ slug: 'ticket_provider', description: 'Top non-interstitial providers' }
		];
		// this.events = ['selected_ticket_provider_from_choices'];
		this.state.selectedEvent = null;
		this.state.start = null;
		this.state.end = null;
		this.state.data = [];
		this.state.error = null;
		this.state.loading = false;
		// this.getEventData = this.getEventData.bind(this);
		this.cache = {};
		this.keyTypes = {
			total: 'number',
			unique: 'number',
			count: 'number',
			lat: 'number',
			lon: 'number'
		};
		this.defaultSortPriority = [
			{ name: 'count', dir: -1 },
			{ name: 'total', dir: -1 },
			{ name: 'unique', dir: -1 },
			{ name: 'value', dir: 1 }
		];
	}

	getEventData(eventSlug) {
		let q = {
			"filter[apps][studios][0]": this.props.studio.slug,
			"filter[apps][titles][0]": this.props.title.slug,
			"filter[apps][regions][0]": this.props.region.slug
		};
		// let gaStudioSlug = accessNested(this.props, 'studio.attributes.meta.studio.gaStudioSlug');
		// if (gaStudioSlug) {
		// 	q['filter[apps][studios][1]'] = gaStudioSlug;
		// }
		if (this.state.start) q['filter[start]'] = moment(this.state.start).format('YYYY-MM-DD');
		if (this.state.end) q['filter[end]'] = moment(this.state.end).format('YYYY-MM-DD');

		this.setState({ error: null, loading: true, data: [] });
		dataApi.getInsightsCounts(eventSlug, q).then(res => {
			let data = res.map(d => d.attributes);
			// data.forEach(d => d.hasOwnProperty('count') && (d.count = +d.count));
			this.setState({ loading: false, data: data });
		}).catch(e => console.log(e) || this.setState({error: e, loading: false}));
	}

	getScreeningData(aggregation) {
		if (!this.screeningData) {
			this.setState({ error: null, loading: true, data: [] });

			let q = {
				"filter[apps][studios][0]": this.props.studio.slug,
				"filter[apps][titles][0]": this.props.title.slug,
				"filter[apps][regions][0]": this.props.region.slug
			};
			// let gaStudioSlug = accessNested(this.props, 'studio.attributes.meta.studio.gaStudioSlug');
			// if (gaStudioSlug) {
			// 	q["filter[apps][studios][1]"] = gaStudioSlug;
			// }
			if (this.state.start) q["filter[start]"] = moment(this.state.start).format("YYYY-MM-DD");
			if (this.state.end) q["filter[end]"] = moment(this.state.end).format("YYYY-MM-DD");
			addToQuery(q, "aggregation", ['format', 'date', 'datetime', 'city']);

			let formatISODate = (d, replacement) => d.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d+)Z$/, replacement || '$3/$2/$1');
			this.screeningData = dataApi.getInsightsScreenings(q).then(d => {
				if (!d) {
					return false;
				}
				let transformDates = (format) => {
					return (date) => {
						if (!+date.value) {
							date.value = '';
						} else {
							date.value = formatISODate(new Date(+date.value).toISOString(), format);
						}
					};
				};
				d.date && d.date.forEach(transformDates());
				d.datetime && d.datetime.forEach(transformDates('$3/$2/$1 $4:$5'));
				return d;
			}).catch(e => {
				console.log(e);
				this.setState({error: e, loading: false});
				this.screeningData = null;
			});
		}
		this.screeningData.then(d => {
			if (!d) return;
			this.setState({ selectedEvent: 'screening-'+aggregation, loading: false, data: (d[aggregation] || []) });
		});
	}

	selectEvent(e){
		this.setState({selectedEvent: e.slug});
		this.getEventData(e.slug);
	}

	render({ title, region }, { data, error, loading, selectedEvent }) {
		let infoClass = [s.info, error && s.error, loading && s.loading].filter(e => e).join(' ');
		let infoContent;
		if (loading) {
			infoContent = <div class={s.icon} />;
		} else if (error) {
			infoContent = 'Error!';
		} else if (selectedEvent && (!data || !data.length)) {
			infoContent = 'No data';
		}

		let defaultSort = data && data.length && this.defaultSortPriority.find(k => k.name in data[0]);

		return (
			<div class={s.container}>
				<h2 class={s.legend}>Insights</h2>
				<h5 class={s.movieName}>{title.en + ' - ' + region.slug?.toUpperCase()}</h5>
				<div class={s.dateRange}>
					<label class={s.from}>From <input type="date" value={this.state.start} onChange={this.linkState('start')} /></label>
					<label class={s.to}> to <input type="date" value={this.state.end} onChange={this.linkState('end')} /></label>
				</div>
				<ul class={s.buttons}>
					{this.events.map(e => (
						<li class={s.elem}><button title={e.slug} onClick={() => this.selectEvent(e)}>{e.description}</button></li>
					))}
				</ul>
				<ul class={s.buttons}>
					<span class={s.description}>Clicked screenings:</span>
					<li class={s.elem}><button onClick={() => this.getScreeningData('format')}>Format</button></li>
					<li class={s.elem}><button onClick={() => this.getScreeningData('date')}>Date</button></li>
					<li class={s.elem}><button onClick={() => this.getScreeningData('datetime')}>Date/Time</button></li>
					<li class={s.elem}><button onClick={() => this.getScreeningData('city')}>City</button></li>
				</ul>
				<div class={infoClass}>{infoContent}</div>
				<Table class={s.data} keys={data && data.length ? Object.keys(data[0]) : []} data={data} scrollActionName={actions.EVENT_DATA_LIST_SCROLLED} keyTypes={this.keyTypes} defaultSort={defaultSort.name} defaultSortDir={defaultSort.dir} />
			</div>
		);
	}
}
