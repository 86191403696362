import { h, Component } from 'preact';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { accessNested } from 'utils/utils';
import s from './screeningsReportForm.sss';
import Selector from 'components/shared/form/selector';
import AutoCompleteInput from 'components/shared/autoCompleteInput/autoCompleteInput';
import slug from 'slugify';

import { getProvidersList } from 'services/dataApi';

export default class ScreeningsReportForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			providers: [],
			titles: [],
			regions: []
		};
		this.runScript = this.runScript.bind(this);
		this.onTitleChange = this.onTitleChange.bind(this);

		getProvidersList().then(response => {
			this.setState({ providers: response.map(e => e.id).sort() });
		});

		this.refreshTitles(props.titles);
		this.refreshRegions(props.regions);
	}

	componentWillReceiveProps(newProps) {
		if (newProps.titles !== this.props.titles) {
			this.refreshTitles(newProps.titles);
		}
		if (newProps.regions !== this.props.regions) {
			this.refreshRegions(newProps.regions);
		}
	}

	refreshAutocompleteValues(obj, stateName, access, validate) {
		let list = values(obj).map(e => accessNested(e, 'attributes.' + access));
		validate = validate || (e => e);
		list = list.filter(validate).sort().map(elem => ({
			value: elem
		}));

		let newState = {};
		newState[stateName] = list;
		this.setState(newState);
	}
	refreshTitles(titles) {
		this.refreshAutocompleteValues(titles, 'titles', 'meta.title.slug');
	}
	refreshRegions(regions) {
		let validRegion = /^[a-z]{2}$/;
		this.refreshAutocompleteValues(regions, 'regions', 'meta.region.slug', e => validRegion.test(e));
	}

	// Ensure the title is slugified
	onTitleChange(data) {
		let val = data && data.value;
		if (!val || !this.$title) {
			return;
		}
		let slugified = slug(val, { lower: true, remove: /[^\w\s-]/g });
		if (slugified !== val) {
			this.$title.setValue(slugified);
		}
	}

	runScript() {
		let provider = this.$provider.getValue();
		let pmid = this.$pmid.value;
		let title = this.$title.getValue().value;
		let region = this.$region.getValue().value;

		let script = cloneDeep(this.props.script);
		if (!pmid && !title) {
			// eslint-disable-next-line
			alert('Please specify a provider movie ID or a title (movie master slug)');
			return;
		}
		if (provider) {
			script.instructions.args.push('-p', provider);
		}
		if (pmid) {
			script.instructions.args.push('-m', pmid);
		}
		if (title) {
			script.instructions.args.push('-M', title);
		}
		if (region) {
			script.instructions.args.push('-c', region);
		}
		this.setState({ running: true });
		this.props.runScript(script).then(() => this.setState({ running: false }));
	}

	render(props, state) {
		// let btnDisabled = running || !sheetId || !movieId;
		let btnDisabled = state.running;

		return (
			<div class={s.wrapper}>
				All optional but you need to specify either a <code>Provider Movie ID</code> or a <code>Title</code>
				<label>
					<div class={s.description}>Provider</div>
					<Selector ref={e => (this.$provider = e)} items={state.providers} selectMessage="Any provider" />
				</label>
				<label>
					<div class={s.description}>Provider Movie ID</div>
					<input ref={e => (this.$pmid = e)} type="text" class={s.input} />
				</label>
				<label>
					<div class={s.description}>Title slug</div>
					<AutoCompleteInput
						ref={e => (this.$title = e)}
						words={state.titles}
						class={s.titleSelector}
						focusedClass={s.focused}
						noResetButton
						defaultSelect
						debounce
						validateOnBlur
						onChange={this.onTitleChange}
					/>
				</label>
				<label>
					<div class={s.description}>Country</div>
					<AutoCompleteInput
						ref={e => (this.$region = e)}
						words={state.regions}
						class={s.regionSelector}
						focusedClass={s.focused}
						noResetButton
						defaultSelect
						debounce
					/>
				</label>
				<button onClick={this.runScript} disabled={btnDisabled}>👌 gimme dat sheet</button>
			</div>
		);
	}

}
