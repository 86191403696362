if (typeof window !== 'undefined') {
	let history = window.history;
	['pushState', 'replaceState'].forEach(fn => {
		let base = history[fn].bind(history);
		let eventName = 'on' + fn.toLowerCase();
		let windowEvent = fn.toLowerCase();
		history[fn] = (...args) => {
			let returnValue = base(...args);
			if (typeof history[eventName] === 'function') {
				history[eventName]({ state: args[0] });
			}
			window.dispatchEvent(new Event(windowEvent));
			return returnValue;
		};
	});
}
