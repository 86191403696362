const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY;

let convert = (ms, unit) => ~~(ms / unit);
let plural = (count, word) => count + ' ' + word + (count !== 1 ? 's' : '');

function getDateDistanceString(date, fromDate) {
	let difference = date - fromDate;
	let dist = Math.abs(difference);
	if (dist < MINUTE) {
		return plural(convert(dist, SECOND), 'second');
	}
	if (dist < HOUR) {
		return plural(convert(dist, MINUTE), 'minute');
	}
	if (dist < DAY) {
		return plural(convert(dist, HOUR), 'hour');
	}
	if (dist < MONTH) {
		return plural(convert(dist, DAY), 'day');
	}
	let months = Math.abs(dateDiffInMonths(date, fromDate));
	if (months < 12) {
		return plural(months, 'month');
	}
	let years = ~~(months / 12);
	return plural(years, 'year');
}

function dateDiffInMonths(date, fromDate) {
	let years = date.getFullYear() - fromDate.getFullYear();
	let months = date.getMonth() - fromDate.getMonth();
	return months + 12 * years;
}

export default function dateDistance(date, fromDate = new Date()) {
	let str = getDateDistanceString(date, fromDate);
	return date > fromDate ? 'in ' + str : str + ' ago';
}
