import { h } from 'preact';

function debounce(f, d) {
	if (d == undefined) d = 250;
	let timer = null;
	let args = [];
	let thisArg = null;
	function trigger() {
		f.apply(thisArg, args);
	}
	function onEvent() {
		thisArg = this;
		args = [].slice.call(arguments);
		clearTimeout(timer);
		timer = setTimeout(trigger, d);
	}
	return onEvent;
}

function throttle(fn, threshold, scope) {
	if (typeof window === 'undefined') {
		return fn;
	}
	if (threshold == undefined) threshold = 250;
	let timeout = threshold === 'frame' ? requestAnimationFrame : setTimeout;
	let clear = threshold === 'frame' ? cancelAnimationFrame : clearTimeout;
	let last;
	let deferTimer;
	return function () {
		let context = scope || this;
		let now = Date.now();
		let args = arguments;
		if (last && now < last + threshold) {
			clear(deferTimer);
			deferTimer = timeout(() => {
				last = Date.now();
				fn.apply(context, args);
			}, threshold);
		} else {
			last = now;
			fn.apply(context, args);
		}
	};
}

// Make a function only callable once
// Saves the return value and returns it for subsequent calls
function once(f) {
	let done = false;
	let result;
	return function () {
		if (done) return result;
		done = true;
		return result = f.apply(this, arguments);
	};
}

// DOM classNames handling

// Long versions accepting arrays
// function hasClass(elem, className) {
// 	if (!elem || !className) return false;
// 	let arr = Array.isArray(className) ? className : className.split(' ');
// 	if(arr.length > 1) return arr.every(cn=>hasClass(elem, cn));
// 	className = arr[0];
// 	return (' ' + elem.className + ' ').indexOf(' ' + className + ' ') !== -1;
// }
// function addClass(elem, className) {
// 	if (!elem || !className) return;
// 	let arr = Array.isArray(className) ? className : className.split(' ');
// 	if(arr.length > 1) return arr.forEach(cn=>addClass(elem, cn));
// 	className = arr[0];
// 	if(!hasClass(elem, className))
// 		elem.className += ' ' + className;
// }
// function removeClass(elem, className) {
// 	if (!elem || !className) return;
// 	let arr = Array.isArray(className) ? className : className.split(' ');
// 	if(arr.length > 1) return arr.forEach(cn=>removeClass(elem, cn));
// 	className = arr[0];
// 	if(hasClass(elem, className))
// 		elem.className = (' ' + elem.className + ' ').replace(className, ' ').slice(1,-1);
// }
// function toggleClass(e,c) { (hasClass(e,c) ? removeClass : addClass)(e,c); }

// Short versions
function hasClass(e, c) {
	return (' ' + e.className + ' ').indexOf(' ' + c + ' ') !== -1;
}
function addClass(e, c) {
	if (!hasClass(e, c)) e.className += (e.className ? ' ' : '') + c;
}
// function removeClass(e, c) { e.className = e.className.split(' ').filter(v => v != c).join(' '); }
function removeClass(e, c) {
	e.className = (' ' + e.className + ' ').replace(' ' + c + ' ', ' ').slice(1, -1);
}
function triggerAnim(e, c) {
	removeClass(e, c); e.offsetWidth; addClass(e, c);
}
function toggleClass(e, c) {
	(hasClass(e, c) ? removeClass : addClass)(e, c);
}

// Easy way to add a class based on a condition (also check the class exists)
function condClass(cond, className) {
	if (arguments.length === 1) {
		className = cond;
		cond = true;
	}
	if (Array.isArray(className)) return className.map(cn => condClass(cond, cn)).join('');
	return cond && className && typeof className === 'string' ? ' ' + className : '';
}

// Turns an object to a string
// { abc: true, def: false, xyz: true } -> 'abc xyz'
// Warning: "s" has a special value and be used as a lookup object (meant to be an imported sss)
//   will use the class s.abc instead of 'abc' if s object specified and the value exists
//   { s: { abc: 'comp__abc', def: 'comp__def' }, abc: true } -> 'comp__abc'
function expandClasses(classes) {
	if (!classes || typeof classes !== 'object' || Array.isArray(classes)) {
		return classes;
	}
	let mapKey = 's';
	let map = classes[mapKey];
	let hasMap = map && typeof map === 'object';
	let list = Object.keys(classes).filter(key => (!hasMap || key !== mapKey) && classes[key]);
	if (hasMap) {
		list = list.map(e => map[e] || e);
	}
	return list.join(' ');
}
/**
 * Join several classes together.
 * @example
 * joinClasses('square', 'blue', false && 'active');
 * // returns 'square blue'
 * @param {...string} classNames - the class names to join
 * @return {string} the full class name
**/
function joinClasses() {
	return [].concat.apply([], arguments).map(expandClasses).filter(e => e).join(' ');
}

// Access to a property of a nested object, or return a default value if the path cannot be accessed
// For example, if obj is { nested: { property : [ 'hello' ] } }
// to get 'hello', path would need to be 'nested.property.0' or ['nested', 'property', 0]
// if we use 'non.existing.path' as a path, it would return def
function accessNested(obj, path, def) {
	if (typeof path === 'string') path = path ? path.split('.') : [];
	if (!Array.isArray(path)) return def; // Path is not an array
	let len = path.length;
	for (let i = 0; i < len; i++) {
		let prop = path[i];
		if (!obj || !Object.prototype.hasOwnProperty.call(obj, prop)) {
			return def;
		}
		obj = obj[prop];
	}
	return obj;
}
// mini version with less checks for console one-liners:
// var n=(o,p,k)=>(o&&(p=p.split?p.split('.'):p).length)?n(o[p.shift()],p):o;

// Used for example with appData.pages.showtimes.data.stuff, when you can specify either one or a list of values
// - if an array, returns it
// - if a string (e.g. 'hello'), returns it as the only element: ['hello']
// - if an object (e.g. { 1: 'abc', 2: 'def' }) returns the values: ['abc', 'def']
// - if an object of booleans (e.g. { abc: true, def: true, ghi: false }) returns the keys for true values ['abc', 'def']
// Also removes falsy values
function asList(value) {
	if (!value) return [];
	if (typeof value !== 'object') return [value];
	if (!Array.isArray(value)) value = Object.keys(value).map(k => typeof value[k] === 'boolean' ? value[k] && k : value[k]);
	return value.filter(e => e);
}

function commaSeparatedList(value) {
	if (!value || typeof value !== 'string') {
		return [];
	}
	return value.split(',').map(a => a.trim()).filter(a => a);
}

/* ---- ARRAY STUFF ---- */

// Returns an array with only unique values (remove duplicates)
function arrayUnique(array) {
	if (!array || !Array.isArray(array)) return array;
	return [...new Set(array)];
}

// Returns the union of a set of arrays ([1,2,3], [2,3,4] => [1,2,3,4])
function arrayUnion(/* array1, array2, ... */) {
	return arrayUnique([].concat.apply([], arguments));
}

/* ---- ----------- ---- */

// Return a date based on a field set in thundr
// If a number, consider it a timestamp
// If a string yyyy-MM-dd, get the beginning of this day in local time (usually release dates for example are local)
// If something else tries new Date(val)
function appDataDate(val) {
	if (!isNaN(val)) {
		return new Date(+val);
	}
	if (typeof val === 'string') {
		let m = val.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
		return m ? new Date(+m[1], m[2] - 1, +m[3]) : new Date(val);
	}
	return new Date(NaN);
}

function sortObjectByOrder(object, mapCallback, property) {
	if (!object) {
		return [];
	}
	if (typeof mapCallback === 'string' && arguments.length === 2) {
		property = mapCallback;
		mapCallback = undefined;
	}
	property = property || 'order';
	let getProperty = typeof property === 'function' ? property : obj => obj?.[property];

	let objectByOrder = Object.keys(object).map(k => ({ key: k, value: object[k] })).sort((obj1, obj2) => {
		if (!obj1.value) return 1;
		if (!obj2.value) return -1;

		let prop1 = getProperty(obj1.value, obj1.key);
		let prop2 = getProperty(obj2.value, obj2.key);
		// Keep it ==, not === to also get nulls
		let noProp1 = prop1 == undefined;
		let noProp2 = prop2 == undefined;
		// If both don't have an order set, sort them by keys (so that it works with 1, 2, etc.)
		if (noProp1 && noProp2) return obj1.key.localeCompare(obj2.key, undefined, { numeric: true });
		if (noProp1) return 1;
		if (noProp2) return -1;

		let stringCompare = isNaN(prop1) || isNaN(prop2);
		// sort them by order
		return stringCompare ? prop1.toString().localeCompare(prop2.toString()) : prop1 - prop2;
	});

	if (typeof mapCallback !== 'function') {
		mapCallback = value => value;
	}
	return objectByOrder.map((el, i, all) => mapCallback(el.value, el.key, i, all));
}

function addToQuery(q, path, o) {
	if (o == null) {
		return q;
	}
	if (typeof o !== 'object') {
		return q[path] = o;
	}
	const ks = Object.keys(o);
	ks.forEach(k => addToQuery(q, path + '[' + k + ']', o[k]));
	return q;
}
function toQueryString(obj) {
	if (!obj) return '';
	let value = obj;
	if (typeof obj !== 'string') {
		let query = {};
		Object.keys(obj).forEach(key => addToQuery(query, key, obj[key]));
		value = Object.keys(query).reduce((a, k) => {
			let v = query[k];
			if (v == null) return a;
			let addedVal = encodeURIComponent(k);
			if (typeof v !== 'boolean' && v !== '') {
				addedVal += '=' + encodeURIComponent(v);
			}
			a.push(addedVal);
			return a;
		}, []).join('&');
	}
	return value ? '?' + value : '';
}

// Parse dates in a few formats (e.g. dd/mm/yyyy ; yyyy-mm-dd)
function parseDate(d) {
	if (!d || d instanceof Date) {
		return d;
	}
	if (typeof d === 'number') {
		return new Date(d);
	}
	if (typeof d !== 'string') {
		return undefined;
	}
	// dd/mm/yyyy with optional day and month
	let match = d.match(/^(?:(?:([0-9]{1,2})\/)?([0-9]{1,2})\/)?([0-9]{4})(?:,? (\d{1,2}):(\d{1,2})(?::(\d{1,2}))?)?$/);
	if (match) {
		return new Date(+match[3], +(match[2] || 1) - 1, +(match[1] || 1), +match[4] || 0, +match[5] || 0, +match[6] || 0);
	}
	// yyyy-mm-dd
	// Could be handled by new Date(string) but interpreted as UTC and we want local so we need to handle that "manually"
	// (we want to be able to use getDate() etc. instead of getUTCDate())
	match = d.match(/^(\d{1,4})-(\d{1,2})(?:-(\d{1,2})(?:[T ](\d{1,2}):(\d{1,2})(?::(\d{1,2})(\.\d+)?)?Z?)?)?$/);
	if (match) {
		return new Date(+match[1], +match[2] - 1, +match[3] || 1, +match[4] || 0, +match[5] || 0, +match[6] || 0);
	}
	// stringified timestamp
	if (!Number.isNaN(+d)) {
		return new Date(+d);
	}
	// try native date parsing
	const date = new Date(d);
	return isNaN(date) ? undefined : date;
}

/**
 * Join several parts of a path together.
 *
 * @example
 * // returns 'http://myserver.com/images/title.jpg'
 * joinPaths('http://myserver.com', 'images', '/title.jpg');
 * @param {...string} parts - the path parts to join
 * @return {string} the final path containing all the parts
 */
function joinPaths() {
	// [...arguments].map(e => e.replace(/^\/|\/$/, '')).filter(e => e).join('/');
	let path = '';
	let slash = false; // whether slash is needed before
	for (let i = 0, il = arguments.length; i < il; i++) {
		let part = arguments[i];
		if (!part) continue;
		// ^ ensure empty string is not counted as a path part
		if (i > 0 && part.charAt(0) === '/') {
			part = part.substring(1);
		}
		path += slash ? '/' + part : part;
		slash = path.charAt(path.length - 1) !== '/';
	}
	return path;
}

/**
 * Return whether the given object has only numeric key strings.
 * Can be used to detect if an object is in reality an array.
 *
 * @example
 * hasOnlyNumericKeys({"0": "foo", "1": "bar", "3": "baz"}); // true
 * hasOnlyNumericKeys({"0": "foo", "sam": "bar"}); // false
 * hasOnlyNumericKeys({"0": "foo", "Infinity": "bar"}); // false
 * @param {Object} obj - the input object
 * @return {boolean} true if obj is an array with string keys, false otherwise
 */
function hasOnlyNumericKeys(obj) {
	for (const key in obj) {
		if (!isFinite(+key)) {
			return false;
		}
	}
	return true;
}

/**
 * Convert an object to an array. (stricter version of asList)
 * Non-numeric/Infinite keys will be removed in the array representation of the
 * object.
 *
 * @param {Object} obj - the input object
 * @return {Array} an array equivalent to obj
 */
function objToArr(obj) {
	const arr = [];
	for (const key in obj) {
		const idx = +key;
		if (isFinite(idx)) {
			arr[idx] = obj[key];
		}
	}
	return arr;
}

/**
 * Sanitize recursively an object created in Thundr.
 * Note that numeric value will be casted to Number unless a '@s' is explicitely
 * specified at the end of the key.
 *
 * @example
 * sanitizeObj({"power": "45", "range": {"0": "-5.6", "1": "5.2"}, "id@s": "124"})
 * // return {"power": 45, "range": [-5.6, 5.2], "id": "124"}
 * @param {Object} obj - the input object
 * @param {Object|Array} the sanitized object or array
 */
function sanitizeObj(obj) {
	const out = {};
	for (let key in obj) {
		let val = obj[key];
		if (val != null && typeof val === 'object') {
			val = sanitizeObj(val);
		} else if (key.endsWith('@s')) {
			key = key.substring(0, key.length - 2);
		} else {
			val = isFinite(+val) ? +val : val;
		}
		out[key] = val;
	}
	if (hasOnlyNumericKeys(out)) {
		return objToArr(out);
	}
	return out;
}

/**
 * Convert a multiline text to an array of JSX elements.
 *
 * @param {string} text - string with 0 or more \n
 * @return {Array.<string|JSX>} an array of JSX elements
 */
const textToJsxArray = (() => {
	function reduceTextJsx(jsxArray, line, i) {
		if (i > 0) {
			jsxArray.push(<br />);
		}
		jsxArray.push(line);
		return jsxArray;
	}

	return (text) => {
		let lines = text;
		if (typeof lines === 'string') lines = text.split('\n');
		const jsxArray = lines.reduce(reduceTextJsx, []);
		return jsxArray;
	};
})();

/**
 * Return the part part of a URI.
 *
 * @example
 * pathOnly('this/is/a/folder/file.txt');
 * // ^ returns 'this/is/a/folder/'
 *
 * @param {string} path - the full URI of a resource
 * @return {string} only the path part of the resource
 */
function pathOnly(path) {
	let rpath = decodeURIComponent(path);
	const index = rpath.lastIndexOf('/');
	if (index === -1 || index === rpath.length - 1) return rpath;
	let res = rpath.substr(index + 1);
	const qsIndex = res.indexOf('?');
	if (qsIndex > -1) {
		res = res.substring(0, qsIndex);
		rpath = rpath.substring(0, index + 1 + qsIndex);
	}
	if (res.length === 0) return rpath;
	const pointIndex = res.lastIndexOf('.');
	if (pointIndex === -1 || pointIndex === res.length - 1) return rpath + '/';
	return rpath.substring(0, index + 1);
}

/**
 * Return whether a variable is a JS (key, value) object.
 *
 * @param {*} obj - the object to test
 * @return {!boolean} true if obj is an object, false otherwise
 */
function isObject(obj) {
	return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

/**
 * Return whether an object/array is empty.
 *
 * @param {*} obj
 * @returns {boolean} true if obj/array is empty, false otherwise
 */
function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}

/**
 * Return whether a variable is a valid numberic value (string number are allowed).
 *
 * @param {*} obj - the object to test
 * @return {!boolean} true if val is numeric, false otherwise
 */
function isNumeric(val) {
	return !isNaN(parseFloat(val)) && isFinite(val);
}

/**
 * Parse an string array of numeric numbers.
 *
 * @example
 * parseNumericArray('5, 2, 1.2, 7'); // returns [5, 2, 1.2, 7]
 *
 * @param {string} arrayStr - the string array
 * @return {Array.<number>|null} the array of numeric value, or null in case the parsing failed
 */
function parseNumericArray(arrayStr) {
	if (typeof arrayStr !== 'string') return null;
	const parts = arrayStr.split(/[|/,;]/);
	// use ES5 loop to early out in case of non-numeric value
	const il = parts.length;
	for (let i = 0; i < il; i++) {
		const val = parseFloat(parts[i].trim());
		if (!isFinite(val)) return null;
		parts[i] = val;
	}
	return parts;
}

/**
 * Quick helper to choose whether a node should be displayed.
 *
 * @example
 * <div style={displayWhen(isLoading)} />
 *
 * @param {boolean} cond - the condition to display the node
 * @return {{display: string}} the element display style
 */
const displayWhen = (cond) => ({ display: cond ? 'block' : 'none' });

/**
 * Return the uri of an image if it exists in appData -> styling -> images || returns the default image path
 *
 * @param {*} obj - appData
 * @param {!string} imgName - name of image we are trying to find
 * @param {!string} defaultImg - value returned if the imgName doesn't exist
 * @return {!string} path of the onesheet stored in appData or default value
 */
function getStylingImagePath(appData, imgName, defaultImg) {
	return accessNested(appData, ['styling', 'images', imgName]) || defaultImg;
}

/**
 * Checks to see if a given object is empty e.g. {} or null or undefined
 *
 * @param {object} inputObject
 * @returns bool
 */
function isEmptyObject(inputObject) {
	return inputObject == null || Object.keys(inputObject).length === 0;
}

/**
 * Transforms HTML tags in HTML entities for safe rendering.
 *
 * @example
 * encodeHtml('<script>'); // returns "&lt;script&gt;"
 * encodeHtml('%3Cscript%3E'); // returns "%lt;script%gt;"
 *
 * @param {!string} str - the string to encode
 * @return {!string} the string with HTML tags replaced with HTML entities
 */
const encodeHtml = (str) => {
	return (str.toString())
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/%3C/g, '&lt;')
		.replace(/%3E/g, '&gt;');
};

/**
 * Transforms HTML tags in HTML entities recursively in any object.
 *
 * @param {*} obj - the input object
 * @return {*} the same object with all string encoded with HTML entities
 */
const encodeHtmlRecursively = (obj) => {
	if (typeof obj === 'string') return encodeHtml(obj);
	if (Array.isArray(obj)) return obj.map(e => encodeHtmlRecursively(e));
	if (isObject(obj)) {
		for (const key in obj) obj[key] = encodeHtmlRecursively(obj[key]);
	}
	return obj;
};

const loadScript = (url) => {
	return new Promise((resolve, reject) => {
		let script = document.createElement('script');
		script.onload = resolve;
		script.onerror = reject;
		script.src = url;
		document.body.appendChild(script);
	});
};

/**
 *  Checks the equality between two arrays and returns true if there is no difference.
 * @param {Array} arr1
 * @param {Array} arr2
 */
const arraysEqual = (arr1, arr2) => {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		return false;
	}
	return arr1.length === arr2.length && arr1.every((val, i) => val === arr2[i]);
};

const escapeString = (variable) => {
	if (typeof variable !== 'string') return variable;
	return variable.replace(/'/g, "\\'").replace(/"/g, '\\"');
};

const escapeObjectValues = (obj) => Object.entries(obj).reduce((acc, [k, v]) => {
	acc[k] = escapeString(v);
	return acc;
}, {});


export { debounce, throttle, once };
export { accessNested };
export { hasClass, addClass, removeClass, triggerAnim, toggleClass, condClass, joinClasses };
export { asList, commaSeparatedList };
export { arrayUnique, arrayUnion, arraysEqual };
export { appDataDate };
export { sortObjectByOrder };
export { addToQuery, toQueryString };
export { parseDate };
export { joinPaths };
export { hasOnlyNumericKeys, objToArr, sanitizeObj };
export { textToJsxArray };
export { pathOnly };
export { isObject, isEmpty, isNumeric };
export { parseNumericArray };
export { displayWhen };
export { getStylingImagePath };
export { encodeHtml, encodeHtmlRecursively };
export { loadScript };
export { isEmptyObject };
export { escapeString, escapeObjectValues };