import { h, Component } from 'preact';
// import values from 'lodash/values';
import slug from 'slugify';
import { attachDistribution, attachExistingDistribution } from 'services/buildApi.js';
import { createAppData, getStudioRegionData } from 'services/dataApi.js';
import Store from 'store/store';
import * as actions from 'store/actions';
import pure from 'utils/pure';
import { Link } from 'components/core/link/link.js';
import Selector from 'components/shared/form/selector.js';
import TitleCreator from 'components/pages/apps/titleCreator.js';
import StudioCreator from 'components/pages/apps/studioCreator.js';
import s from 'components/pages/apps/appCreator.sss';

import baseNames from 'utils/baseNames';
import formatDateTime from 'utils/formatDateTime';
import { joinClasses } from 'utils/utils';

export default @pure class AppCreator extends Component {

	constructor() {
		super();

		this.submit = this.submit.bind(this);
		this.toggleNewTitle = this.toggleNewTitle.bind(this);
		this.newTitleSaved = this.newTitleSaved.bind(this);
		this.toggleNewStudio = this.toggleNewStudio.bind(this);
		this.newStudioSaved = this.newStudioSaved.bind(this);

		this.selectedStudio = this.selected.bind(this, 'studio');
		this.selectedTitle = this.selected.bind(this, 'title');
		this.selectedRegion = this.selected.bind(this, 'region');
		this.selectNoCloudfront = this.selectNoCloudfront.bind(this);
		this.selectShowOnPowsterMovies = this.selectShowOnPowsterMovies.bind(this);

		this.updateLocale = this.updateLocale.bind(this);
		this.updateRelease = this.updateRelease.bind(this);
		this.updateVanities = this.updateVanities.bind(this);
		this.updateCloudfrontId = this.updateCloudfrontId.bind(this);
		this.updatePathOnExistingCloudfront = this.updatePathOnExistingCloudfront.bind(this);

		this.state = {
			base: 2,
			formData: this.getFormDataTemplate(),
			vanityDomains: '',
			newTitle: false,
			newStudio: false,
			showOnPowsterMovies: true,
			// show today as the release date
			releaseDate: formatDateTime(new Date(), 'yyyy-MM-dd')
		};
	}

	componentWillMount() {
		this.updateStateFromProps();
		this.setState({ formData: this.getFormDataTemplate() });
	}

	componentWillReceiveProps(newProps) {
		if (newProps.list !== this.props.list ||
			newProps.titles !== this.props.titles ||
			newProps.studios !== this.props.studios ||
			newProps.regions !== this.props.regions ||
			newProps.like !== this.props.like) {
			this.updateStateFromProps(newProps);
		}
	}

	updateStateFromProps(props = this.props) {
		const {
			list,
			like
		} = props;
		const state = this.state;

		if (like) {
			this.setState({
				studio: state.studio || list.studios[like.rel.studio],
				title: state.title || list.titles[like.rel.title],
				region: state.region || list.regions[like.rel.region]
			});
		}

		let fStudios = Object.values(list.studios).filter(studio => studio.rel.base == state.base);
		let fTitles = Object.values(list.titles).filter(title => title.rel.base == state.base);
		let fRegions = Object.values(list.regions).filter(region => region.rel.base == state.base);
		let studio = state.studio && fStudios.find(v => v.id === state.studio.id);
		let title = state.title && fTitles.find(v => v.id === state.title.id);
		let region = state.region && fRegions.find(v => v.id === state.region.id);
		this.setState({
			bases: Object.values(props.list.bases),
			studios: fStudios,
			titles: fTitles,
			regions: fRegions,
			studio,
			title,
			region
		});
	}

	updateLocale(e) {
		if (e && e.target) {
			this.setState({ localeTitle: e.target.value });
		}
	}
	updateVanities(e) {
		if (e && e.target) {
			this.setState({ vanityDomains: e.target.value });
		}
	}
	updateRelease(e) {
		if (e && e.target) {
			this.setState({ releaseDate: e.target.value });
		}
	}
	updateCloudfrontId(e) {
		if (e && e.target) {
			this.setState({ existingCloudfront: e.target.value });
		}
	}
	updatePathOnExistingCloudfront(e) {
		if (e && e.target) {
			let path = e.target.value;
			if (!path.startsWith('/')) {
				path = `/${path}`;
			}
			this.setState({ pathForExistingCloudfront: path });
		}
	}
	selectNoCloudfront(e) {
		this.setState({ noCloudfrontNeeded: e.target.checked });
	}
	selectShowOnPowsterMovies(e) {
		this.setState({ showOnPowsterMovies: e.target.checked });
	}

	newTitleSaved(title) {
		this.setState({ title, newTitle: false });
	}

	newStudioSaved(studio) {
		this.setState({ studio, newStudio: false });
	}

	async submit(event) {
		event.preventDefault();
		this.setState({ error: null });
		let state = this.state;

		if (!state.studio) return this.setState({ error: 'Please select a studio' });
		if (!state.title) return this.setState({ error: 'Please select a title' });
		if (!state.region) return this.setState({ error: 'Please select a region' });

		let { noCloudfrontNeeded, existingCloudfront, pathForExistingCloudfront } = state;

		// if noCloudfrontNeeded is set we ignore the existingCloudfront value later on (the field is hidden)
		// if (noCloudfrontNeeded && existingCloudfront) {
		// 	return this.setState({ error: 'Contradicting info: Cloudfront ID field and "No Cloudfront distribution" checkbox cannot both be filled' });
		// }

		if (!noCloudfrontNeeded && existingCloudfront && (!pathForExistingCloudfront || pathForExistingCloudfront === '/')) {
			return this.setState({ error: 'If an existing Cloudfront ID is used, please specify a path for this app' });
		}

		let vanities = [];

		if (state.vanityDomains) {
			let srcVanities = state.vanityDomains + '';
			// Split on newlines and remove empty lines
			vanities = srcVanities.split('\n').map(s => s.trim().toLowerCase()).filter(e => e);
			let errVanity;
			// No dot or space or multiple special chars in a row (for ex "abc..com")
			errVanity = vanities.find(v => v.indexOf('.') === -1 || v.indexOf(' ') !== -1 || v.match(/[^a-z0-9]{2,}/));
			if (errVanity) {
				return this.setState({
					error: 'The vanity ' + errVanity + ' seems incorrect'
					// TODO: add autofix button
				});
			}
			// errVanity = vanities.find(v => v.startsWith('http://') || v.startsWith('https://') || v.endsWith('/'));
			errVanity = vanities.find(v => v.indexOf('/') !== -1);
			if (errVanity) {
				return this.setState({
					error: 'Ensure no vanity starts with http:// or ends with / (' + errVanity + ')'
					// TODO: add autofix button
				});
			}
			// Remove duplicates and add the www version for all vanities without subdomain
			let allVanities = vanities.reduce((all, vanity) => {
				all[vanity] = true;
				if (vanity.match(/^[^.]+(\.[^.]{2,3})?\.[^.]+$/) && !vanity.startsWith('www.')) {
					all['www.' + vanity] = true;
				}
				return all;
			}, {});
			vanities = Object.keys(allVanities);
		}

		let localeTitle = state.localeTitle || state.title?.en;
		if (!localeTitle) {
			return this.setState({
				error: 'No localized title was specified'
			});
		}
		let localeSlug = slug(localeTitle, { lower: true, remove: /[^\w\s._~-]/g });
		// If the slugified version is invalid use the english slug
		if (!localeSlug.match(/^[a-z0-9-]+$/) || !localeSlug.match(/[a-z]/)) {
			localeSlug = state.title?.slug;
		}

		this.setState({ loading: true });

		// Try to limit duplicate creation by fetching the latest app list
		// This is slow though
		// Do it on duplicate error only
		// await Store.emit('UPDATE_APPS_LIST');

		const studioId = state.studio.id;
		const titleId = state.title.id;
		const regionId = state.region.id;

		const dupe = Object.values(this.props.list.apps).find((a) => (
			a.rel.studio === studioId && a.rel.title === titleId && a.rel.region === regionId
		));
		if (dupe) {
			this.setState({
				loading: false,
				error: [
					<span class={s.dupe}>This app already exists</span>,
					<Link
						linkClass={s.goToApp}
						pageId="editApp"
						queryString={{ app: dupe.id }}
						noMergeQS
					>
						Edit this app
					</Link>
				]
			});
			Store.emit('UPDATE_APPS_LIST');
			// throw new Error(`[Duplicate] An app (id: ${dupe.id}) already exists that matches the selected studio, title, and region.`);
			return;
		}

		let app = {
			type: 'app_data',
			attributes: {
				meta: {
					title: {
						locale: localeTitle,
						localeSlug: localeSlug
					},
					dates: {
						release: state.releaseDate
					},
					live: false,
					vanities: vanities,
					url: ''
				},
				options: {}
			},
			rel: {
				base: state.base,
				region: regionId,
				title: titleId,
				studio: studioId
			}
		};
		if (vanities.length) {
			app.attributes.meta.url = 'https://' + vanities[0];
		}
		if (!state.showOnPowsterMovies) {
			app.attributes.dev = app.attributes.dev || {};
			app.attributes.dev.hideFromPowsterMovies = true;
		}

		// Waiting for production greenlight to apply to all new apps
		// app.attributes.options.useShowtimes2 = true;
		// app.attributes.options.useHomeEnt2 = true;

		try {
			app = await createAppData('app_data', app);
			Store.emit(actions.NEW_APP, app);
			const desiredCloudfrontId = !noCloudfrontNeeded && existingCloudfront?.trim();
			const desiredPath = pathForExistingCloudfront?.trim();
			if (!noCloudfrontNeeded && !desiredCloudfrontId) {
				app = await attachDistribution(app) || app;
			}
			if (desiredCloudfrontId && desiredPath && desiredPath !== '/') {
				const slugs = {
					studio: state.studio.slug,
					title: state.title.slug,
					region: state.region.slug
				};
				app = await attachExistingDistribution(desiredCloudfrontId, desiredPath, slugs, app) || app;
			}

			// Ensure we have the studioRegion
			const state = Store.get();
			const studioRegionId = app.rel.studioRegion;
			if (!state.list.studioRegions[studioRegionId]) {
				Store.emit(actions.UPDATE_APP_DATA, await getStudioRegionData(studioRegionId));
			}
			this.setState({ loading: false });
			if (typeof this.props.onCreated === 'function') {
				this.props.onCreated(app);
			}
		} catch (error) {
			console.error(error);
			let msg = 'Error creating the app';
			let errData = error;
			try {
				errData = await error.json();
			} finally { /**/ }
			if (errData) {
				let errorMsg = errData?.message || errData;
				msg += '. The error is:\n' + errorMsg;
			}
			this.setState({ error: msg, loading: false });
		}
	}

	selected(type, data) {
		const change = {};
		change[type] = data;
		this.setState(change);
	}
	selectedBase(base) {
		this.setState({ base: base.id });
		this.updateStateFromProps();
	}

	toggleNewTitle(event) {
		event.preventDefault();
		this.setState({ newTitle: !this.state.newTitle });
	}

	toggleNewStudio(event) {
		event.preventDefault();
		this.setState({ newStudio: !this.state.newStudio });
	}

	getFormDataTemplate() {
		return [
			{
				releaseDate: {
					title: 'Release Date',
					type: 'date',
					data: {
						appDataPath: 'meta.dates.release'
					}
				}
			},
			{
				ticketsAvailable: {
					title: 'Tickets Available',
					type: 'date',
					data: {
						appDataPath: 'meta.dates.most'
					}
				}
			}
		];
	}

	render(props, state) {
		const {
			region = {},
			studio = {},
			title = {},
			bases,
			regions,
			studios,
			titles,
			newTitle,
			newStudio,
			localeTitle,
			releaseDate,
			vanityDomains,
			noCloudfrontNeeded,
			existingCloudfront,
			pathForExistingCloudfront,
			showOnPowsterMovies,
			error,
			loading
		} = state;

		let titleContent = '';
		if (newTitle) {
			titleContent = <TitleCreator onCancel={this.toggleNewTitle} onSave={this.newTitleSaved} titles={titles} base={state.base} />;
		} else {
			titleContent = (
				<div class={s.content}>
					<button type="button" class={s.newTitle} onClick={this.toggleNewTitle}>Create New Title</button>
					<span class={s.or}>or</span>
					<Selector
						disabled={!!newTitle}
						items={titles}
						getLabel="en"
						value={title}
						selectMessage="-- Select a title --"
						onChange={this.selectedTitle}
					/>
				</div>
			);
		}

		let defaultTitle = title?.en;

		let studioContent = '';
		if (newStudio) {
			studioContent = <StudioCreator onCancel={this.toggleNewStudio} onSave={this.newStudioSaved} studios={studios} base={state.base} />;
		} else {
			studioContent = (
				<div class={s.content}>
					<button type="button" class={s.newStudio} onClick={this.toggleNewStudio}>Create New Studio</button>
					<span class={s.or}>or</span>
					<Selector items={studios} getLabel="name" value={studio} selectMessage="-- Select a studio --" onChange={this.selectedStudio} />
				</div>
			);
		}

		return (
			<form class={s.form} onSubmit={this.submit}>
				<div class={s.legend} key="legend">Create App</div>

				<div class={joinClasses(s.row, s.base)} key="base">
					<span class={s.spacer}>Base</span>
					<div class={s.selection}>
						{bases.map(base => (
							<button
								type="button"
								class={joinClasses(s.baseButton, base.id == state.base && s.active)}
								onClick={() => this.selectedBase(base)}
								key={base.id}
							>
								{baseNames[base.id] || base.id}
							</button>
						))}
					</div>
				</div>

				<div class={joinClasses(s.row, s.studio)} key="studio">
					<span class={s.spacer}>Studio</span>
					{studioContent}
				</div>

				<div class={joinClasses(s.row, s.title)} key="title">
					<span class={s.spacer}>Title</span>
					{titleContent}
				</div>

				<label class={joinClasses(s.row, s.region)} key="region">
					<span class={s.spacer}>Region</span>
					<Selector items={regions} getLabel="slug" value={region} selectMessage="-- Select a region --" onChange={this.selectedRegion} />
				</label>

				<label class={joinClasses(s.row, s.localeTitle)} key="localeTitle">
					<span class={s.spacer}>Localised Title</span>
					<input type="text" value={localeTitle} onInput={this.updateLocale} placeholder={defaultTitle} />
				</label>

				<label class={joinClasses(s.row, s.releaseDate)} key="release">
					<span class={s.spacer}>Release Date</span>
					<input type="date" value={releaseDate} onInput={this.updateRelease} />
				</label>

				<label class={joinClasses(s.row, s.vanity)} key="vanity">
					<span class={s.spacer}>Vanity Domains</span>
					<textarea value={vanityDomains} onInput={this.updateVanities} cols="40" rows="3" />
				</label>

				<label class={joinClasses(s.row, s.noCloudfront)} key="noCf">
					<input type="checkbox" onChange={this.selectNoCloudfront} />
					No Cloudfront distribution needed
				</label>

				{!noCloudfrontNeeded && (
					<label class={joinClasses(s.row, s.cloudfront)} key="cfId">
						<div class={s.spacer}>
							<span>Cloudfront ID</span>
							{/* <span class={s.extraInfo}>(leave blank to create new)</span> */}
						</div>
						<input type="text" value={existingCloudfront} onInput={this.updateCloudfrontId} placeholder="Leave blank to create new" />
						<a class={s.find} href="https://console.aws.amazon.com/cloudfront/v3/home?region=eu-west-1#/distributions" target="_blank">
							Find existing distributions
						</a>
					</label>
				)}

				{!noCloudfrontNeeded && existingCloudfront && (
					<label class={joinClasses(s.row, s.path)} key="cfPath">
						<span class={s.spacer}>Path</span>
						<input type="text" value={pathForExistingCloudfront} onInput={this.updatePathOnExistingCloudfront} />
					</label>
				)}

				<label class={joinClasses(s.row, s.showOnPowsterMovies)} key="showOnPowsterMovies">
					<input type="checkbox" checked={showOnPowsterMovies} onChange={this.selectShowOnPowsterMovies} />
					Show this site on <a href="https://movies.powster.com" target="_blank">Powster Movies</a> once the app is set live
				</label>

				<div class={s.buttons} key="buttons">
					<button class={joinClasses(s.create, loading && s.loading)} type="submit" disabled={loading}>
						<div class={s.loadingIcon} />
						Create This App
					</button>
				</div>

				{error && <p class={s.error} key="error">{error}</p>}
			</form>
		);
	}

}
