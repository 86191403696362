// Clamps a value between two bounds. If a bound is undefined, ignore that bound.
// Note: the order of operations is important for undefined handling
export function clamp(v, min, max) {
	return v <= min ? min : v >= max ? max : v;
}

// Linear interpolation between a and b
//   usually you want t in [0, 1] (0 -> a, 1 -> b)
export function lerp(t, a, b) {
	return a + t * (b - a);
}

// Inverse linear interpolation: gets the progress of a value between two bounds
// ilerp(20, 0, 50) === 0.4 (because 20 is 40% of the way between 0 and 50)
export function ilerp(t, a, b) {
	return (t - a) / (b - a);
}

// Similar to n % m, but returns a positive number even if n is negative
export function mod(n, m) {
	if (!m) {
		return 0;
	}
	let v = n % m;
	return v < 0 ? v + m : v;
}

// Moves a number from an interval to another
// e.g. 2.2 from [2, 3] to [0, 100] gives 20 (20% on the way from 2 to 3)
// clampResult to limit the result to the desired interval
export function scale(n, fromMin, fromMax, toMin, toMax, clampResult, easing) {
	let p = ilerp(n, fromMin, fromMax);
	if (clampResult) {
		p = clamp(p, 0, 1);
	}
	if (easing) {
		if (typeof easing === 'string') {
			easing = Easing[easing];
		}
		if (typeof easing === 'function') {
			p = easing(p);
		}
	}
	return lerp(p, toMin, toMax);
}

// Dot product
export function dot(x1, y1, x2, y2) {
	return x1 * x2 + y1 * y2;
}

// Cartesian distance between two 2D points
export function dist(x1, y1, x2, y2) {
	const dx = x2 - x1;
	const dy = y2 - y1;
	return Math.sqrt(dx * dx + dy * dy);
}

// Taken from https://gist.github.com/gre/1650294
// I put a caps because we alread have some variables called `easing`.
export const Easing = {
	// no easing, no acceleration
	linear: (t) => t,
	// small acceleration, large deceleration (approximation of CSS "ease", default transition timing function)
	// from https://github.com/mietek/ease-tween
	ease: (t) => 1.0042954579734844 * Math.exp(-6.4041738958415664 * Math.exp(-7.2908241330981340 * t)),
	// accelerating from zero velocity
	quadIn: (t) => t * t,
	// decelerating to zero velocity
	quadOut: (t) => t * (2 - t),
	// acceleration until halfway, then deceleration
	quadInOut: (t) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
	// accelerating from zero velocity
	cubicIn: (t) => t * t * t,
	// decelerating to zero velocity
	cubicOut: (t) => (--t) * t * t + 1,
	// acceleration until halfway, then deceleration
	cubicInOut: (t) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
	// accelerating from zero velocity
	quartIn: (t) => t * t * t * t,
	// decelerating to zero velocity
	quartOut: (t) => 1 - (--t) * t * t * t,
	// acceleration until halfway, then deceleration
	quartInOut: (t) => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
	// accelerating from zero velocity
	quintIn: (t) => t * t * t * t * t,
	// decelerating to zero velocity
	quintOut: (t) => 1 + (--t) * t * t * t * t,
	// acceleration until halfway, then deceleration
	quintInOut: (t) => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t
};