import { h, Component } from 'preact';
import Page from 'components/pages/page.js';
import s from 'components/pages/messengerTools/messengerTools.sss';
import { joinClasses } from 'utils/utils';

import messengerPages from 'components/pages/messengerTools/messengerPages.js';
const tabs = [
	{ id: 'pages', name: 'Pages', comp: messengerPages },
	{ id: 'actions', name: 'Actions', comp: () => 'not yet' },
	{ id: 'testing', name: 'Testing', comp: () => 'not yet' }
];

// Modulo that is always positive (-32 % 10 returns -2, mod(-32, 10) returns 8)
function mod(n, m) {
	while (n < 0) n += m;
	while (n >= m) n -= m;
	return n;
}
function clamp(n, m, M) { return n < m ? m : n > M ? M : n; }
function lerp(n, m, M) { return m + n * (M - m); }
function ilerp(n, m, M, c) { let v = (n - m) / (M - m); return c ? clamp(v, 0, 1) : v; }
// function inOutCubic(t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1; }
function inQuad(t) { return t*t; }

// TODO: merge this and theater matcher thing
export default class MessengerTools extends Page {
	constructor(props) {
		super(props);
		this.state = {
			currentTab: tabs[0].id
		};
		this.tabWheel = this.tabWheel.bind(this);
		this.toggleTheme = this.toggleTheme.bind(this);
		try {
			this.state.dark = !!+localStorage.getItem('messenger-dark-theme');
		} catch(e) {}
	}

	selectTab(id) {
		this.setState({ currentTab: id });
	}

	tabWheel(e) {
		let quantity = e.deltaY;
		let changeTab = e.deltaMode === e.DOM_DELTA_LINE || (e.deltaMode === e.DOM_DELTA_PIXEL && Math.abs(quantity) >= 50);
		if (changeTab && quantity) {
			let index = tabs.findIndex(e => e.id === this.state.currentTab);
			index += (quantity < 0 ? -1 : 1);
			// index = mod(index, tabs.length);
			index = clamp(index, 0, tabs.length - 1);
			this.selectTab(tabs[index].id);
		}
	}

	renderTab = (tab) => {
		return (
			<div class={joinClasses(s.tab, this.state.currentTab === tab.id && s.active)} title={tab.name} onClick={() => this.selectTab(tab.id)}>
				<div class={s.name} data-text={tab.name} />
			</div>
		);
	}

	renderUnknownTab() {
		return (
			<div class={s.error}>Sorry, an error occurred!</div>
		);
	}

	toggleTheme(e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		let newState = !this.state.dark;
		this.setState({ dark: newState });
		try {
			localStorage.setItem('messenger-dark-theme', +newState);
		} catch(e) {}
	}

	render(props, { currentTab, dark }) {
		let {
			appData,
			user
		} = props;
		let tab = tabs.find(tab => tab.id === currentTab);
		let TabComp = tab ? tab.comp : this.renderUnknownTab;
		return (
			<div class={joinClasses(s.wrapper, dark && s.dark)}>
				<div class={s.tabs} onWheel={this.tabWheel}>
					{ tabs.map(this.renderTab) }
				</div>
				<div class={s.content}>
					<TabComp {...{ appData, user, dark }} />
				</div>
				<ThemeIcon class={s.themeIcon} onMousedown={this.toggleTheme} dark={dark} />
			</div>
		);
	}
}

class ThemeIcon extends Component {
	constructor(props) {
		super(props);
		this.step = this.step.bind(this);
	}

	componentDidMount() {
		let c = this.$c;
		this.ctx = this.$c.getContext('2d');
		c.width = 50;
		c.height = 50;
		this.ctx.lineWidth = 1.4;
		this.goal = this.props.dark ? 1 : 0;
		this.t = this.goal;
		this.step();
	}

	componentWillReceiveProps(props) {
		if (props.dark !== this.props.dark) {
			this.goal = props.dark ? 1 : 0;
			this.run();
		}
	}

	run() {
		if (this.timer) {
			return;
		}
		this.timer = requestAnimationFrame(this.step);
	}
	stop() {
		cancelAnimationFrame(this.timer);
		this.timer = null;
	}
	step() {
		if (!this.ctx) return;
		this.t += (this.goal - this.t) / 10;
		if (Math.abs(this.t - this.goal) < .001) {
			this.t = this.goal;
			this.timer = null;
		}
		let t = this.t;
		let ctx = this.ctx;
		// Gross. Clean that up dude.
		ctx.clearRect(0, 0, 50, 50);
		let color = Math.round(255 * this.t);
		ctx.strokeStyle = 'rgb('+color+','+color+','+color+')';
		ctx.beginPath();
		let orad = 12;
		ctx.ellipse(25, 25, orad, orad, Math.PI / 3, 0, Math.PI, false);
		// let radP = inOutCubic(ilerp(t, 0, 0.65, true));
		let radP = ilerp(t, 0, 0.65, true);
		let rad = lerp(radP, orad * -0.5, orad);
		ctx.ellipse(25, 25, orad, Math.abs(rad), Math.PI / 3, Math.PI, 0, rad < 0);
		let raysP = ilerp(t, 0.4, 1, true);
		let rays = 10;
		let rayFrom = 15;
		let rayLen = 6;
		let rayDuration = 4 / rays;
		let rayScatter = (1 - rayDuration) / rays;
		for (let i = 0; i < rays; i++) {
			let a = 2 * Math.PI * -i / rays - Math.PI / 2;
			let from = i * rayScatter;
			let rP = inQuad(ilerp(raysP, from, from + rayDuration, true));
			let rT = rayFrom + rayLen * rP;
			ctx.moveTo(25 + rayFrom * Math.cos(a), 25 + rayFrom * Math.sin(a));
			ctx.lineTo(25 + rT * Math.cos(a), 25 + rT * Math.sin(a));
		}
		ctx.stroke();
		if (this.timer) {
			this.timer = requestAnimationFrame(this.step);
		}
	}

	render({ dark, ...attributes }) {
		return <canvas {...attributes} ref={e => this.$c = e} />;
	}
}
