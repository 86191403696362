import { h } from 'preact';
import pure from 'utils/pure';
import ObjectAttribute from 'components/shared/editor/formEditor/attributes/objectAttribute';
import s from 'components/shared/editor/formEditor/attributes/pageAttribute/pageAttribute.sss';


export default @pure class PageAttribute extends ObjectAttribute {

	renderItemContent() {
		let { value, attributeName } = this.props;
		value = value || {};

		return (
			<div class={s.item}>
				<div class={s.itemName}>{attributeName}</div>
				<div class={s.itemPath}>Path: {value.path}</div>
			</div>
		);
	}

}
