import { h, Component } from 'preact';
import cloneDeep from 'lodash/cloneDeep';
import { accessNested } from 'utils/utils';
import { getMovieMaster, createMovieMasterId } from 'services/dataApi';
import s from './salesListForm.sss';
import Selector from 'components/shared/form/selector.js';

export default class SalesListForm extends Component {
	// getMovieMaster({"limit": 1, "filter[slug][0]": tSlug})
	constructor() {
		super();
		this.state = {
			sheetId: '',
			movieId: '',
			running: false,
			availableRegions: [],
			region: undefined
		};
		this.selectedMovie = this.selectedMovie.bind(this);
		this.selectedRegion = this.selectedRegion.bind(this);
		this.runScript = this.runScript.bind(this);
	}

	selectedMovie(value) {
		if(!value) return this.setState({ movieId: '' });
		let titleId = value.id;
		let apps = this.props.apps, regions = this.props.regions;
		apps = Object.keys(apps).map(k => apps[k]);
		let availableRegions = apps.filter(a => a.rel.title === titleId).map(a => regions[a.rel.region].attributes.meta.region.slug);
		this.setState({ availableRegions: availableRegions });
		let mmid = accessNested(value, 'uiExtras.movieMaster.id');
		if(mmid) {
			this.setState({ movieId: mmid });
		} else {
			this.setState({ movieId: '' });
			let tSlug = value.attributes.meta.title.slug;
			getMovieMaster({'limit': 1, 'filter[slug][0]': tSlug}).then(movieMaster => {
				if (movieMaster) {
					return movieMaster;
				}

				return createMovieMasterId({ slug: tSlug });
			}).then(movieMaster => {
				let title = this.props.titles[titleId];
				let uiExtras = (title.uiExtras && cloneDeep(title.uiExtras)) || {};
				uiExtras.movieMaster = movieMaster;
				title.set({uiExtras});
				this.setState({ movieId: movieMaster.id });
			});
		}
	}

	selectedRegion(value) {
		this.setState({ region: value });
	}

	runScript() {
		let script = cloneDeep(this.props.script);
		script.instructions.args.push('--sheet_id', this.state.sheetId, '--movie', this.state.movieId);
		if(this.state.region) script.instructions.args.push('--country', this.state.region);
		this.setState({running: true});
		this.props.runScript(script).then(() => this.setState({running: false}));
	}

	render(props, {sheetId, movieId, running, availableRegions}) {
		let titles = props.titles;
		let btnDisabled = running || !sheetId || !movieId;

		return (
			<div class={s.wrapper}>
				<div class={s.info}>You need to give edit access to <span class={s.email}>showtimes-data-importer@showtimes-gdrive.iam.gserviceaccount.com</span></div>
				<label>Google Sheet ID <input type="text" value={this.state.sheetId} onInput={this.linkState('sheetId')} /></label>
				<label>Movie <Selector items={Object.values(titles)} getLabel="attributes.meta.title.en" selectMessage="-- Select a title --" onChange={this.selectedMovie} /></label>
				<label>Region <Selector items={availableRegions} selectMessage="All regions" onChange={this.selectedRegion} /></label>
				<button onClick={this.runScript} disabled={btnDisabled}>RUN!!!1!</button>
			</div>
		);
	}
}
