import { h, Component } from 'preact';
import { joinClasses } from 'utils/utils';
import pure from 'utils/pure';
import s from 'components/shared/misc/loading.sss';


export default @pure class Loading extends Component {

	render(props) {
		return (
			<div class={joinClasses(s.loader, props.class)} />
		);
	}

}
