import { h, Component } from 'preact';
import s from 'components/shared/editor/creativeBuild/creativeBuildInfo.sss';

export default class CreativeBuildInfo extends Component {
	constructor(props) {
		super(props);
		this.onGitBranchChange = this.onGitBranchChange.bind(this);
		this.onPersonChange = this.onPersonChange.bind(this);
	}

	updateValue(attr, val) {
		let branchId = this.props.branchId;
		if (!branchId) {
			return;
		}
		const base = structuredClone(this.props.currentAppData);
		let parent = ['dev', 'branches', branchId].reduce((parent, path) => {
			if (!(path in parent)) {
				parent[path] = {};
			}
			return parent[path];
		}, base);
		parent[attr] = val;
		this.props.updateData(base);
	}

	onGitBranchChange(event) {
		this.updateValue('gitBranch', event.target.value);
	}
	onPersonChange(event) {
		this.updateValue('pointPerson', event.target.value);
	}

	render({ creativeBranch }) {
		const {
			gitBranch,
			pointPerson,
			name,
		} = creativeBranch;

		return (
			<div class={s.creativeBuild}>
				<div class={s.creativeBranch}>{name}</div>
				<label class={s.fieldContainer}>
					<div class={s.fieldName}>GIT BRANCH</div>
					<div class={s.dynamicWidthContainer}>
						<input type="text" value={gitBranch} onInput={this.onGitBranchChange} />
						<p class={s.dynamicInputField}>{gitBranch}</p>
					</div>
				</label>
				<label class={s.fieldContainer}>
					<div class={s.fieldName}>POINT PERSON</div>
					<div class={s.dynamicWidthContainer}>
						<input type="text" value={pointPerson} onInput={this.onPersonChange} />
						<p class={s.dynamicInputField}>{pointPerson}</p>
					</div>
				</label>
			</div>
		);
	}
}
