import { h } from 'preact';
import Page from 'components/pages/page.js';
// import isEqual from 'lodash/isEqual';

let SelectReact;
let renderedSelect;
let ReactDOM;

// if (typeof global.window === 'object') {
// 	SelectReact = require('components/shared/select/reactSelect');
// 	renderedSelect = SelectReact.default;
// 	ReactDOM = require('react-dom');
// }

export default class Select extends Page {

	// shouldComponentUpdate(nextProps) {
	// 	let simpleChange = [
	// 		'autoFocus',
	// 		'isDisabled',
	// 		'isMulti',
	// 		'isSearchable',
	// 		'name',
	// 		'onChange',
	// 	].some(prop => this.props[prop] !== nextProps[prop]);

	// 	return simpleChange || !isEqual(this.props.options, nextProps.options);
	// }

	// componentDidUpdate() {
	// 	renderedSelect(this.reactContainer, this.props);
	// }

	// componentDidMount() {
	// 	if (typeof global.window === 'object') {
	// 		renderedSelect(this.reactContainer, this.props);
	// 	}
	// }

	// componentWillUnmount() {
	// 	if (ReactDOM) {
	// 		ReactDOM.unmountComponentAtNode(this.reactContainer);
	// 	}
	// }

	render() {
		// return (
		// 	<div ref={el => this.reactContainer = el} />
		// );
		const {
			isMulti,
			classNamePrefix,
			options,
			...props
		} = this.props;
		return (
			<select {...props} multiple={isMulti}>
				{options.map(({ label, value }) => (
					<option value={value} key={value}>{label || value}</option>
				))}
			</select>
		);
	}
}
