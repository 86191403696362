import { addClass, joinClasses } from 'utils/utils';
import s from 'utils/tooltip.sss';

function call(fn, ...args) {
	if (typeof fn === 'function') {
		fn(...args);
	}
}

// Options support:
//   class: the class to apply to the tooltip
//   leavingClass: the class to apply to the tooltip before removing it from the dom
//   duration: duration in ms after which automatically removes the tooltip. 0 (or false) to not automatically remove the tooltip
//   domRemoveDelay: (default 'auto') duration in ms after the remove request before actually removing the element from the dom (to let the exit animation play out)
//       if 'auto', tries to figure it out based on the css animation / transition duration
//   clickToRemove: whether a click on the tooltip removes it or not
//   callback: function called when the tooltip is getting removed
//   domCallback: function called once the element is totally removed from the dom
// Returns an object containing:
//   dom: a reference to the DOM object of the tooltip
//   remove: a function to remove the tooltip
export default function createTooltip(text, options) {
	options = Object.assign({
		class: '',
		leavingClass: s.leaving,
		duration: 0,
		domRemoveDelay: 'auto',
		clickToRemove: true,
		callback: null,
		domCallback: null,
	}, options);
	const dom = document.createElement('div');
	dom.className = joinClasses(s.tooltip, options.class);
	dom.innerHTML = text;
	document.body.appendChild(dom);

	let removed = false;
	let remove = function() {
		if (removed) return;
		removed = true;
		if (options.leavingClass) {
			addClass(dom, options.leavingClass);
		}
		let removeDelay = options.domRemoveDelay;
		if (removeDelay === 'auto') {
			let style = getComputedStyle(dom);
			let animDuration = Math.max(maxTime(style.animationDuration), maxTime(style.transitionDuration));
			// Add 500ms in case the css animation lags
			removeDelay = animDuration ? animDuration + 500 : 0;
		}
		setTimeout(() => {
			document.body.removeChild(dom);
			call(options.domCallback);
		}, +removeDelay || 0);
		call(options.callback);
	};

	if (options.clickToRemove) {
		dom.addEventListener('click', remove);
	}

	if (+options.duration > 0) {
		setTimeout(remove, +options.duration);
	}

	let update = (text) => {
		dom.innerHTML = text;
	};

	return { update, dom, remove };
}

// Return in ms the maximum duration in a string representing a list of css durations
// e.g. maxTime('1.3s, 200ms') === 1300
function maxTime(durations) {
	if (!durations) return 0;
	return Math.max(...durations.split(',').map(duration => {
		let data = duration.trim().match(/^(\d*(?:\.\d+)?)(m?s)$/);
		if (!data) return 0;
		return data[1] * (data[2] === 's' ? 1000 : 1);
	}));
}
