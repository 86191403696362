/**
 * Delete all the null properties in a specified object
 * @param  {Object} defaults - the object that needs to be filtered
 * @param  {Boolean} recurse - whether it does it recursively or not - warning: no loop detection
**/
export default function deleteNulls(obj, recurse) {
	for (let i in obj) {
		if (obj[i] === null) {
			delete obj[i];
		} else if (recurse && typeof obj[i] === 'object') {
			deleteNulls(obj[i], recurse);
		}
	}
	return obj;
}
