
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!! WARNING !!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//    DO NOT IMPORT THIS FILE
// legacy utility functions (brings in a bunch of imports from the old "showtimes" base)

import queryString from 'qs';
import formatDateTime from 'utils/formatDateTime';
import Store from 'store/store';
import * as actions from 'store/actions';
import Geo from 'models/Geo';
import Filters from 'models/Filters';
import { addToQuery, toQueryString, accessNested, asList, parseDate } from 'utils/utils';
import { track } from 'utils/tracking';
// import { gateways } from 'store/ticketingPublic';

/**
 * Add Client data to the initial state of the store
 * @return {Object} The current initial State
 */
function buildClientStoreData(initialState) {
	// NOTE: this is a random hack to make hover work on mobile in the navigation
	// Should we maybe put it somewhere else ? Seems a bit weird to have it in that function
	window.addEventListener('touchend', () => {});

	window.addEventListener('unload', (ev) => {
		Store.emit(actions.CLOSE_APP, ev);
	});

	if (initialState.showtimes) {
		// HACK: uses JSON.stringify + parse to convert to plain object instead of class, so that Freezer convert it to frozen object with `.set` etc.
		initialState.showtimes.filters = JSON.parse(JSON.stringify(new Filters(undefined, initialState.appData && initialState.appData.copy)));
	}

	const dimensions = getDims();
	const device = getDevice();
	const deviceSize = getSize(dimensions, device);
	const orientation = dimensions.width > dimensions.height ? 'landscape' : 'portrait';
	const isIOS = getIsIOS(device);
	const iOSVersion = getIOSVersion();
	const isIE = getIsIE();
	const isEdge = getIsEdge();
	const isFirefox = getIsFirefox();
	const isTouch = !!('ontouchstart' in window) || window.navigator.msMaxTouchPoints > 0;
	const isChrome = !!getIsChrome();
	const isSafari = getIsSafari();
	const hasWebGL = getHasWebGL();
	const pixelConsent = accessNested(initialState, 'appData.options.requireUserConsent', true);

	if (initialState.header) {
		const order = ['none', 'mobile', 'tablet', 'desktop'];
		initialState.header.sideNavActive = order.indexOf(deviceSize) <= order.indexOf(initialState.header.sideNavBelow);
	}

	initialState.client = {
		dimensions,
		deviceSize,
		device,
		orientation,
		isIOS,
		iOSVersion,
		isEdge,
		isIE,
		isFirefox,
		isChrome,
		isTouch,
		isSafari,
		hasWebGL,
		pixelConsent
	};

	return initialState;
}

function getSize(dimensions = getDims(), device = getDevice()) {
	// const largeDesktopStartWidth = 1280; // not using this...yet. (Be careful ! We use the result of this function in the tracking)
	const breakpoints = [[768, 'mobile'], [1024, 'tablet'], [Infinity, 'desktop']];

	for (let i = 0; i < breakpoints.length; i++) {
		if (dimensions.width < breakpoints[i][0]) {
			// We should avoid doing this as it will cause some disconnects between the CSS and JS
			// The best is probably to take into account the device where we need instead
			if (device === 'iphone' && i === 1) {
				return 'mobile'; // because iPhone x
			}
			return breakpoints[i][1];
		}
	}

	return 'unknown';
}

function getDims() {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	};
}


function getQueryParameterByName(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function _queryStringItemSet(qs, key, val) {
	if (val === null) {
		delete qs[key];
	} else {
		qs[key] = val;
	}
}
function updateQueryStringItem(key, value, state, usePush) {
	const parsedQS = queryString.parse(location.search.slice(1));
	if (typeof key !== 'object') {
		_queryStringItemSet(parsedQS, key, value);
	} else {
		usePush = state;
		state = value;
		Object.keys(key).forEach(prop => {
			_queryStringItemSet(parsedQS, prop, key[prop]);
		});
	}
	let qs = queryString.stringify(parsedQS);
	qs = qs ? '?' + qs : '';
	const method = usePush ? 'pushState' : 'replaceState';
	history[method](state, '', (location.pathname || '') + qs);
}

/**
 * Get the Device data.
 * @return {Object} Device data object
 */
function getDevice() {
	if (typeof window !== 'undefined') {
		const agent = ('' + navigator.userAgent).toLowerCase();
		if (agent.indexOf('android') !== -1) {
			return 'android';
		}
		if (agent.indexOf('iphone') !== -1) {
			return 'iphone';
		}
		if (agent.indexOf('ipad') !== -1) {
			return 'ipad';
		}
	}
	return 'desktop';
}

function findParent(el, tagName) {
	tagName = tagName.toUpperCase();
	let parent = el.parentElement;
	while (parent) {
		if (parent.tagName === tagName) {
			return parent;
		}
		parent = parent.parentElement;
	}
}


/**
 * Is the browser Chrome?
 * @return {boolean|string} is the browser chrome
 */
function getIsChrome() {
	if (getIsEdge()) {
		return false;
	}
	if (typeof window !== 'undefined') {
		return (navigator.userAgent.match('CriOS') || !!window.chrome);
	}
	return false;
}


/**
 * Is the browser IE (or Edge)?
 * @return {boolean|string} is the browser ie/which version
 */
function getIsIE() {
	if (typeof window === 'undefined') {
		return;
	}
	const ua = window.navigator.userAgent;

	const msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	const trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		const rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	const edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
}

/**
 * Is the browser Edge?
 * @return {boolean|string} is the browser edge
 */
function getIsEdge() {
	const ua = window.navigator.userAgent;
	const edge = ua.indexOf('Edge/');
	if (edge > 0) {
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}
	return false;
}

/**
 * Is the browser Firefox?
 * @return {boolean} is the browser firefox
 */
function getIsFirefox() {
	return window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

/**
 * Is the device IOS?
 * @return {boolean} is the device ios
 */
function getIsIOS() {
	if (typeof window === 'undefined') {
		return false;
	}
	const agent = ('' + navigator.userAgent).toLowerCase();
	return agent.indexOf('ipad') !== -1 || agent.indexOf('iphone') !== -1;
}

/**
 * Get version of ios (if it is ios).
 * @return {string} version of ios
 */
function getIOSVersion() {
	let match = /CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent);
	if (!match) {
		return false;
	}
	if (!match[1]) {
		return 3.2;
	}
	return parseFloat(match[1].replace('_', '.').replace(/_/g, '')) || false;
}

/**
 * Return whether WebGL is available.
 * @return {boolean} true if WebGL is available, false otherwise
 */
function getHasWebGL() {
	const canvas = document.createElement('canvas');
	const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
	return !!gl;
}

function getIsSafari() {
	return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
}

/**
 * Calculate the status of showtimes (is it live yet or not).
 * @param {Date} activeDate - the date that showtimes is going live
 * @param {boolean} forceLive - Should we force showtimes to go live (-1 to prevent live status)
 * @param {Object} pages - All the pages that the app has
 * @return {boolean} is showtimes live or not
 */
function calculateShowtimesStatus(activeDate, forceLive, pages) {
	return (activeDate <= Date.now() || forceLive || (pages.showtimes && pages.showtimes.path === '/')) && forceLive !== -1;
}


/**
 * Get the _pk_id/ac_id
 */
function getAC_ID() {
	const cookie = document.cookie.split(';')
		.find(c => c.trim().slice(0, 6) === '_pk_id');

	if (cookie) return cookie.split('=')[1].split('.')[0];
	else return null;
}

if (typeof window !== 'undefined') window.getAC_ID = getAC_ID;

/**
 * Get the client geo from ip
**/
let geoFromIp;
function getGeoFromIPAsync(apiRoot) {
	if (geoFromIp) {
		return geoFromIp;
	}
	apiRoot = apiRoot || accessNested(Store.get(), 'appData.apis.stdata.root') || 'https://stdata.powster.com';
	return geoFromIp = fetch(apiRoot + '/geo').then(res => res.json()).catch(e => {
		// If error, try getting the capital city of the app region
		let countryCode = accessNested(Store.get(), 'appData.meta.region.slug') || 'us';
		if (countryCode.indexOf('_') !== -1) {
			countryCode = countryCode.split('_')[0];
		}
		if (countryCode.length !== 2) {
			throw e;
		}
		return fetch(apiRoot + '/capital?country=' + countryCode).then(r => r.json());
	}).then(data => {
		if (data && data.countryCode) {
			data.countryCode = data.countryCode.toLowerCase();
		}
		return data;
	}).catch(e => {
		geoFromIp = null;
		Store.emit(actions.API_ERROR, 'StData', e);
		throw e;
	});
}

function rawSearchLocation(search, srcRegion) {
	const q = { search };

	let region = srcRegion;
	if (region && /[a-z]{2}(_[a-z]+)?/.test(region)) {
		// Special cases (ccTLD, not ISO ; see https://developers.google.com/maps/documentation/geocoding/intro#RegionCodes)
		if (region === 'gb') {
			region = 'uk';
		}
		q.region = region.slice(0, 2);
	}

	// 09-16-20: to prevent returning Los Ángeles, Argentina
	if (search.toLowerCase() === 'los angeles') {
		q.region = 'us';
	}

	return fetch('https://showtimes.s-prod.pow.io/v1.0/geo/search' + toQueryString(q)).then(res => res.json()).then(data => {
		if (data.result !== 'ok') {
			Store.emit(actions.SET_THEATERS, [], data.result);
			Store.get().showtimes.set({ loading: false, error: 'GEOCODING' });
			track({ a: 'location_fail', l: status });
			// probably 'ZERO_RESULTS' (we should check though)
			Store.emit(actions.API_ERROR, 'Google Maps Geocoding', 'Geocoding error');
			throw new Error('Geocoding error');
		}
		let geoData = data.response;
		geoData.countryCode = (geoData.countryCode || srcRegion || '').toLowerCase();
		geoData.src = search;
		return new Geo(geoData);
	});
}

const euCountries = ['at', 'be', 'bg', 'hr', 'ch', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gr', 'hu', 'ie', 'it', 'lv', 'lt', 'lu', 'mt', 'nl', 'pl', 'pt', 'ro', 'sk', 'si', 'es', 'se'];
const honoraryEuCountries = ['gb', 'ch'];

function isUserInEU() {
	return getGeoFromIPAsync().then(geo => {
		if (!geo || !geo.countryCode) {
			return false;
		}
		if (euCountries.includes(geo.countryCode)) {
			return true;
		}
		if (honoraryEuCountries.includes(geo.countryCode)) {
			return geo.countryCode;
		}
		return false;
	});
}

function rawSearchLocations(search) {
	let store = Store.get();
	let geo = store.client.geo || {};
	const q = { search, lat: geo.lat, lon: geo.lon };

	return fetch('https://showtimes.s-prod.pow.io/v1.1/geo/search' + toQueryString(q)).then(res => res.json()).catch(() => undefined).then(data => {
		if (data.result !== 'ok') {
			Store.emit(actions.SET_THEATERS, [], data.result);
			Store.get().showtimes.set({ loading: false, error: 'GEOCODING' });
			track({ a: 'location_fail', l: status });
			// probably 'ZERO_RESULTS' (we should check though)
			Store.emit(actions.API_ERROR, 'Google Maps Geocoding', 'Geocoding error');
			throw new Error('Geocoding error');
		}
		const geoData = data.response.map((r) => {
			r.src = search;
			geo = new Geo(r);
			geo.label = r.label;
			return geo;
		});
		geoData.src = search;
		return geoData;
	});
}
async function searchLocation(loc, region, source) {
	// Start the loading animation when we start geocoding, not when it's done
	Store.get().showtimes.set({ loading: true });

	track({ a: 'location_search' });

	return Promise.resolve(region).catch(() => null)
		.then((region) => {
			if (typeof loc !== 'object') {
				return rawSearchLocation(loc, region);
			}
			return loc;
		})
		.then(result => {
			// Update the actual location
			Store.emit(actions.SET_CLIENT_GEO, result, source || 'search');
			track({ a: 'location', l: 'SUCCESS' });
		}).catch(() => {
			Store.emit(actions.SET_THEATERS, [], 'GEOCODING');
			Store.get().showtimes.set({ loading: false, error: 'GEOCODING' });
			track({ a: 'location_fail' });
		});
}

/**
 * Generates a fake API response with a bunch of screenings
 * TODO: Move that to the back-end. Create an endpoint stdata/screenings/fake and just call it
 */
// function generateFakeTimes(geo) {
// 	geo = geo || {};
// 	const formats = [{
// 		type: 'formats',
// 		id: Math.random() + '',
// 		attributes: { name: '2d' }
// 	}];
// 	const movies = [{
// 		type: 'movies',
// 		id: Math.random() + '',
// 		attributes: {
// 			provider_id: 'wwm',
// 			provider_movie_id: Math.random() + '',
// 			format_id: formats[0].id,
// 			title: 'Dummy movie'
// 		}
// 	}];
// 	const theaters = [];
// 	for (let i = ~~(Math.random() * 10 + 1); i--;) {
// 		theaters.push(
// 			{
// 				type: 'theaters',
// 				id: Math.random() + '',
// 				attributes: {
// 					name: 'Dummy Theater #' + (i + 1),
// 					address: 'Nowhere',
// 					city: '???',
// 					country: geo.countryCode || 'gb',
// 					url: 'http://google.com',
// 					lat: (geo.lat || 0) + (Math.random() - .5) * .08,
// 					lon: (geo.lon || 0) + (Math.random() - .5) * .16,
// 					tz: 'Europe/London',
// 					provider_id: 'wwm',
// 					provider_theater_id: Math.random() + ''
// 				}
// 			}
// 		);
// 	}
// 	const screenings = [];
// 	const now = Date.now();
// 	for (let i = ~~(Math.random() * 50 + 50); i--;) {
// 		const sTime = new Date(now + Math.random() * 7 * 24 * 60 * 60 * 1000);
// 		screenings.push(
// 			{
// 				type: 'screenings',
// 				id: '8ac5ca20-f78f-11e6-9bb3-efc78af321f4',
// 				attributes: {
// 					movie_id: movies[0].id,
// 					theater_id: theaters[~~(Math.random() * theaters.length)].id,
// 					provider_id: 'wwm',
// 					date: sTime.toISOString(),
// 					time: formatDateTime(sTime, 'hh\\:mm\\:ss'),
// 					url: 'http://google.com',
// 					is_visible: true
// 				}
// 			}
// 		);
// 	}
// 	return {
// 		data: screenings,
// 		included: formats.concat(movies).concat(theaters),
// 		meta: []
// 	};
// }

// Switch things like be_fr to be
function getCountry2(country) {
	return country && (country + '').toLowerCase().replace(/^([a-z]+)_.+$/, '$1');
}

function getBoundingRegions(state) {
	let br = accessNested(state, 'activePage.data.boundingRegions') || accessNested(state, 'appData.pages.showtimes.data.boundingRegions') || [];
	let disabledBr = [];
	let extraBr = [];
	if (!Array.isArray(br)) {
		disabledBr = Object.keys(br).filter(k => br[k] === false);
		extraBr = Object.keys(br).filter(k => br[k] === true);
	}
	if (accessNested(state, 'appData.options.autoBoundingRegions')) {
		const dynamicData = Store.get().dynamicData;
		if (dynamicData && dynamicData.regions) {
			const regions = dynamicData.regions;
			br = Object.keys(regions).filter(k => regions[k]);
		}
	}
	if (br.toJS) br = br.toJS();

	// Convert to array
	br = asList(br);
	if (br.indexOf('intl') !== -1) return null;

	// Turn into object for duplicate removal
	let brObject = {};
	br.concat(extraBr, accessNested(state, 'appData.meta.region.slug')).forEach(country => {
		if (!country) return;
		// Turn it into a regular country code
		let country2 = getCountry2(country);
		if (disabledBr.indexOf(country2) !== -1) {
			return;
		}
		// Also check the full country if country2 is different
		if (country2 !== country && disabledBr.indexOf(country) !== -1) {
			return;
		}
		brObject[country2] = true;
	});

	return Object.keys(brObject);
}

// TODO: We should probably merge getGeoScreeningsAsync and getTheatersScreeningsAsync they are very similar
// (do we even still use getTheatersScreeningsAsync ?)

/**
 * Get screenings from location
 * @param {Object} geo
 * @param {Object} state current Store (used for bounding region check etc.
 * @param {Boolean} noBoundingRegionCheck Do not check the region
 */
function getGeoScreeningsAsync(geo, state, noBoundingRegionCheck) {
	const { appData } = state || {};
	// if (appData && accessNested(appData, 'options.generateFakeTimes')) {
	// 	return Promise.resolve(generateFakeTimes(geo));
	// }

	const { lat, lon, countryCode } = geo;
	const stdataApiRoot = appData.apis.stdata.root;

	let be = accessNested(state, 'activePage.data.blockedExhibitors', {});
	// convert blockedExhibitors to object if it's an array
	if (Array.isArray(be)) {
		be = be.reduce((be, exhibitor) => {
			be[exhibitor] = true;
			return be;
		}, {});
	}

	const br = getBoundingRegions(state);
	if (br && !noBoundingRegionCheck) {
		if (br.indexOf(getCountry2(countryCode)) === -1) {
			return Promise.reject('BOUNDING_REGION');
		}
	}

	const q = {
		'include[0]': 'theaters',
		'include[1]': 'exhibitors',
		'include[2]': 'movies',
		'include[3]': 'formats'
	};

	addTitleFilterToQuery(q, appData);

	// blockedExhibitors.forEach(x => addToQuery(q, 'filter[exhibitors][exclude][domain]', x));
	addToQuery(q, 'filter[exhibitors][exclude][domain]', Object.keys(be).filter(d => d && be[d]));

	const removeRadiusRestriction = accessNested(state, 'activePage.data.useGlobalScreenings');

	// gets every screening in the world if useGlobalScreenings is enabld in thundr
	if (!removeRadiusRestriction) {
		q['filter[theaters][lat]'] = lat;
		q['filter[theaters][lon]'] = lon;
		const radiusFactor = accessNested(state, 'activePage.data.radiusFactor', 1);
		if (radiusFactor !== 1) q['filter[theaters][rf]'] = radiusFactor;
	}

	if (br) {
		addToQuery(q, 'filter[theaters][country]', br.filter(r => r.match(/^[a-z]{2}$/)));
	}

	let blockedProviders = asList(accessNested(state, 'activePage.data.blockedProviders', {}));
	addToQuery(q, 'filter[providers][id_not]', blockedProviders);

	return fetch(stdataApiRoot + '/screenings' + toQueryString(q), {
		method: 'GET',
		headers: {
			'Accept': 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json'
		}
	}).then(res => res.json());
}


// SET CONTAINING TO TRUE IF ids ARE SCREENINGS IDS AND YOU WANT ALL SCREENINGS FROM THE THEATERS CONTAINING THEM
// (otherwise ids are theaters ids)
function getTheatersScreeningsAsync({ appData }, ids, containing) {
	const stdataApiRoot = appData.apis.stdata.root;

	const q = {
		'include[0]': 'theaters',
		'include[1]': 'exhibitors',
		'include[2]': 'movies',
		'include[3]': 'formats'
	};

	addTitleFilterToQuery(q, appData);

	const prop = containing ? 'theater_containing' : 'theater_id';
	ids.forEach((id, idx) => {
		q['filter[' + prop + '][' + idx + ']'] = id;
	});

	return fetch(`${stdataApiRoot}/screenings${toQueryString(q)}`, {
		method: 'GET',
		headers: {
			'Accept': 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json'
		}
	}).then((res) => (res.json()));
}

function getCitiesAsync(state) {
	const { appData, client, overrideCountry, activePage } = state;
	const stdataApiRoot = appData.apis.stdata.root;
	const citiesFilters = Store.get().showtimes.citiesFilters; // probably not safe, but we need to get the latest version of the filters
	const targetCountry = overrideCountry || client.geo.countryCode;

	const q = {};

	if (targetCountry !== 'intl') {
		let countries = getBoundingRegions(state);
		if (countries) {
			if (countries.indexOf(targetCountry) === -1) {
				countries.push(targetCountry);
			}
			countries = countries.filter(r => r.match(/^[a-z]{2}$/));
			addToQuery(q, 'filter[theaters][country]', countries);
		}
	}

	const ignoreProviderRegions = accessNested(activePage, 'data.ignoreProviderRegions', {});
	const ignoreCityProviderRegions = accessNested(activePage, 'data.ignoreCityProviderRegions', {});
	const ignore = Object.assign({}, ignoreProviderRegions, ignoreCityProviderRegions);
	addToQuery(q, 'filter[ipr]', Object.keys(ignore).map(provider => {
		let list = ignore[provider] || {};
		let countries = Object.keys(list).filter(k => list[k]);
		if (countries.length) {
			return { p: provider, c: countries.join('.') };
		}
	}).filter(e => e));

	if (citiesFilters && citiesFilters.pidmod) {
		// Get the cities specific to a pidmod instead of for all screenings
		addToQuery(q, 'filter[movies][pidmod]', [citiesFilters.pidmod]);
	} else {
		addTitleFilterToQuery(q, appData);
	}

	if (appData.options && appData.options.citiesUseMovieAggregate) {
		addToQuery(q, 'useMovieAggregate', 'true');
	}

	const fetchPath = stdataApiRoot + '/cities' + toQueryString(q);

	return fetch(fetchPath, {
		method: 'GET',
		headers: {
			'Accept': 'application/vnd.api+json',
			'Content-Type': 'application/vnd.api+json'
		}
	}).then(res => res.json());
}

function addTitleFilterToQuery(q, appData) {
	let store = Store.get();
	let pageData = accessNested(store, 'activePage.data') || accessNested(appData || store.appData, 'pages.showtimes.data') || {};
	const overrideMovieIds = asList(pageData.overrideMovieIds);
	const extraMovieIds    = asList(pageData.extraMovieIds);
	const movieMasterIds   = asList(appData.meta.movieMasterIds);
	const titleSlugs       = [appData.meta.title.slug].concat(asList(pageData.extraTitleSlugs));

	if (overrideMovieIds && overrideMovieIds.length) {
		addToQuery(q, 'filter[movies][pidmod]', overrideMovieIds);
	} else {
		if (movieMasterIds.length) {
			addToQuery(q, 'filter[movies][mmid]', movieMasterIds);
		} else {
			addToQuery(q, 'filter[titles][slug]', titleSlugs);
		}
		if (extraMovieIds && extraMovieIds.length) {
			addToQuery(q, 'filter[movies][pidmod]', extraMovieIds);
		}
	}

	return q;
}

function isInViewport(element, offsets = {}) {
	const rect = element.getBoundingClientRect();
	const html = document.documentElement;

	return (
		rect.top >= (offsets.top || 0) &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || html.clientHeight) &&
		rect.right <= (window.innerWidth || html.clientWidth)
	);
}

// Returns the path of a page
function getPath(page/*, showtimesStatus*/) {
	let store = null;
	const getStore = () => store || (store = Store.get());
	if (typeof page === 'string') {
		page = accessNested(getStore(), ['appData', 'pages', page]);
	}
	if (!page) return undefined;

	// if (showtimesStatus === undefined) {
	// 	showtimesStatus = accessNested(getStore(), 'showtimes.status');
	// }
	// return page['path' + (showtimesStatus ? 'After' : 'Before') + 'Showtimes'] || page.path;
	return page.path;
}

function getPageTitle(activePage, appData) {
	appData = appData || Store.get().appData;
	const copy = appData.copy;

	// Hm ... This doesn't very feel like a very clean thing to do (not localized, etc.)
	// const activePageName = activePage.id[0].toUpperCase() + activePage.id.slice(1);
	const activePageName = copy[activePage.$copy] || '';
	const release = parseDate(accessNested(appData, 'meta.dates.release', 0));
	const params = Object.assign({
		PAGE_NAME: activePageName,
		RELEASE_DATE: formatDateTime(release, copy.$DATE_FORMAT || 'MMMM d, yyyy', copy.$DATETIME_LOCALE)
	}, copy);

	const altTitle = '$' + (activePage.id || '').toUpperCase() + '_ALT_TITLE';
	const pageTitle = copy[altTitle] || copy.$DYNAMIC_TITLE;
	return getCopyWithParams(params, pageTitle);
}

// ↓ This should be in utils.js tbh

// Gets a copy value, and replaces %$THING% by other copy data
// Example:
// copy = {
// 	'$MSG': 'Hello %$NAME%',
// 	'$NAME': '%$FIRST_NAME% %$SURNAME%',
// 	'$FIRST_NAME': 'John',
// 	'$SURNAME': 'Doe'
// };
// getCopyWithParams(copy, '$MSG'); // Hello John Doe
// getCopyWithParams(copy, 'Bye %$NAME%'); // Bye John Doe (if attribute is not a copy element, it uses that value directly)
// getCopyWithParams(copy, '%$FIRST_NAME% is %$MOOD%'); // John is $MOOD (if a variable is not defined, outputs its name)
// getCopyWithParams(copy, '100% with %$SURNAME%'); // 100% with Doe (a % sign alone still works)
// getCopyWithParams(copy, 'a \\%protected% part'); // a %protected% part (prepend with a \ to prevent parsing ; warning : needs to be doubled in string literals because of default JS escaping)
function _getCopyWithParams(copy, attribute, _n) {
	// Prevent infinite recursion (e.g. $A = %$B%, $B = %$A%)
	if (++_n > 10) return attribute;
	// let content = copy.hasOwnProperty(attribute) ? copy[attribute] : attribute;
	const content = accessNested(copy, attribute, attribute) + ''; // Allow using %subObject.$VALUE%
	return content.replace(/(\\)?%([^% ]+)%/g, (m, s, g) => s ? '%' + g + '%' : _getCopyWithParams(copy, g, _n));
}
function getCopyWithParams(copy, attribute) { return _getCopyWithParams(copy, attribute, 0); }


// Returns a tt image name based on some conditional data (used in home)
function getSizeBasedTT(data, client, baseName, alternateName) {
	baseName = baseName || 'tt.png';
	alternateName = alternateName || 'tt2.png';
	if (!data) {
		return baseName;
	}

	client = client || Store.get().client;
	if (typeof data === 'string') {
		data = { 1: data };
	}

	let values = Object.keys(data).map(k => {
		let val = data[k];
		if (val && typeof val === 'object' && !val.name) {
			val = Object.assign({ name: k + '.png' }, val);
		}
		return val;
	}).filter(e => e);

	let groups = [];
	if (values.every(val => typeof val === 'object')) {
		groups = values.sort((a, b) => {
			if (a.order && b.order) return a.order - b.order;
			if (a.order) return -1;
			if (b.order) return 1;
			return values.indexOf(a) - values.indexOf(b);
		});
	} else {
		groups = [data];
	}

	let dSize = client.deviceSize;
	let device = client.device;
	let orientation = client.orientation;
	let result = groups.find(group => {
		let { name, order, ...conditions } = group;
		conditions = asList(conditions);
		return !conditions.length || conditions.some(cond => {
			if (!cond) return false;
			let ok = true;
			if (cond[0] === '!') {
				ok = false;
				cond = cond.slice(1);
			}

			let [cDevice, cOrientation] = cond.split(':');
			if (cDevice && cDevice !== '*') {
				let invalid = false;
				if (cDevice.startsWith('device=')) {
					invalid = cDevice.slice(7) !== device;
				} else if (cDevice.startsWith('size=')) {
					invalid = cDevice.slice(5) !== dSize;
				} else {
					invalid = cDevice !== dSize && cDevice !== device;
				}
				if (invalid) return !ok;
			}
			if (cOrientation && cOrientation !== '*' && cOrientation !== orientation) return !ok;
			return ok;
		});
	});

	return result ? result.name || alternateName : baseName;
}

// Should put that in the appData
const staticSigningService = 'https://static-maps.s-prod.pow.io';
// const staticSigningService = 'https://static-maps.s-test.pow.io';
// const staticSigningService = 'http://localhost:6661';

// Request a signed static maps url and puts it in the store when done (also returns a Promise with the result)
function requestSignedStaticMapUrl(url) {
	let store = Store.get();
	let urls = store.showtimes.staticMaps;
	if (urls[url]) {
		return Promise.resolve(urls[url]);
	}
	urls.set(url, { loading: true });

	let data = {
		url: url,
		studio: accessNested(store, 'appData.meta.studio.slug')
	};

	track({ a: 'request-static-map' });
	// Dummy version
	// return new Promise(resolve => setTimeout(() => resolve({ signed: url }), 5000)).then(result => {
	return fetch(staticSigningService + '/sign', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	}).then(res => res.json()).then(result => {
		let urls = Store.get().showtimes.staticMaps;
		urls.set(url, result);
		return result;
	}).catch(err => {
		console.log('Static map signature error', err);
		Store.get().showtimes.staticMaps.set('signError', true);
		Store.emit(actions.API_ERROR, 'Static Maps', err);
		// Store.emit(actions.LOAD_DYNAMIC_MAP);
	});
}

const trackNearestShowtimeTicketClick = (() => {
	let tracked = false;

	return (theater) => {
		if (tracked) return; // Track only once per session
		tracked = true;

		const params = queryString.parse(window.location.search.substr(1));
		if (params.r === 'nearest-cinema') {
			let eventType = 'no_result';
			let eventValue = params.tmid || params.tid;
			if (params.tmid || params.tid) {
				if ((params.tmid && params.tmid === theater.masterId) || (params.tid && params.tid === theater.id)) {
					eventType = 'right_guess';
				} else {
					eventValue += ' => ' + (theater.masterId || theater.id);
					eventType = 'wrong_guess';
				}
			}

			track({ a: 'ticket_click_nearest_showtime_' + eventType, l: eventValue, useCustomPageTrack: true });
		}
	};
})();

export {
	getGeoFromIPAsync,
	getGeoScreeningsAsync,
	isUserInEU,
	rawSearchLocation,
	rawSearchLocations,
	searchLocation,
	getTheatersScreeningsAsync,
	getCitiesAsync,
	buildClientStoreData,
	getSize,
	getDims,
	getDevice,
	updateQueryStringItem,
	calculateShowtimesStatus,
	getAC_ID,
	isInViewport,
	getQueryParameterByName,
	findParent,
	getPath,
	getPageTitle,
	getCopyWithParams,
	getSizeBasedTT,
	getIsIOS,
	getIsIE,
	getIsChrome,
	requestSignedStaticMapUrl,
	trackNearestShowtimeTicketClick
};
