module.exports = {
	$schema: "http://json-schema.org/draft-04/schema#",
	type: "object",
	properties: {
		geofencing: {
			type: "object",
			title: "Geofencing",
			description: "Redirecting users out of the app country to somewhere else",
			properties: { }
		},
		header: {
			type: "object",
			title: "Header",
			description: "Settings related to the header",
			properties: {
				nav: {
					type: "object",
					sortBy: "order",
					properties: {
						allOf: [
							{ $ref: "#/definitions/navLink" },
							{ $ref: "#/definitions/navPage" }
						]
					}
				},
				breakpoint: {
					type: "string",
					options: {
						possibilities: ["none", "mobile", "desktop", "tablet"]
					}
				},
				extraContent: {
					type: "object",
					description:
						"image link to be referenced in the imageRoot folder as 'extra_header_content.png'",
					properties: {
						url: {
							type: "string",
							description: "a string of the site you want to link to"
						},
						position: {
							type: "string",
							options: {
								possibilities: ["left", "right"]
							}
						},
						trackId: {
							type: "string",
							description: "id to be tracked for analytics"
						},
						hideOnMobile: {
							type: "boolean",
							description:
								"if you want the link to be visible in the collapsed menu"
						}
					}
				},
				boltSizing: {
					type: "string",
					options: ["h1", "h2", "h3", "h4"]
				},
				NTLiveHeader: { type: "hidden" }
			}
		},
		background: {
			type: "object",
			title: "Background",
			description: "Global background options"
		},
		copy: {
			type: "object",
			title: "Copy",
			description: "All of the copy used in the app",
			group: {
				content: {
					title: "Content",
					properties: [
						"$DIRECTED_BY",
						"$PRODUCED_BY",
						"$WRITTEN_BY",
						"$EXECUTIVE_PROD",
						"$DIRECTOR",
						"$PRODUCER",
						"$WRITER",
						"$STORY",
						"$CAST",
						"$RELEASE_DATE",
						"$FOLLOW",
						"$GALLERY",
						"$GALLERY_DOWNLOAD",
						"$GALLERY_DOWNLOAD_ALL",
						"$HOME",
						"$HOME_ENT",
						"$OFFICIAL_WEBSITE",
						"$PRIVACY",
						"$TERMS",
						"$COOKIES",
						"$COOKIE_CONSENT",
						"$COPYRIGHT_STUDIO",
						"$SHARE",
						"$SYNOPSIS",
						"$VIDEOS",
						"$ONESHEET_DESCRIPTION",
						"$DIGITAL",
						"$DVD",
						"$BLU_RAY",
						"$HOME_ENT_AVAILABILITY_TITLE",
						"$HOME_ENT_PRICE_ALT",
						"$HOME_ENT_EDITION_ALT",
						"$HOME_ENT_FROM_ALT",
						"$HOME_ENT_ERROR",
						"HOME_ENT_ERROR_CTA",
						"$RENT",
						"$BUY",
						"$PRE_ORDER",
						"$STREAM"
					]
				},
				share: {
					title: "Share",
					properties: [
						"$SOCIAL_COPY",
						"$SHARE_URL",
						"$HOME_META_DESCRIPTION",
						"$HOME_META_TITLE",
						"$META_TITLE",
						"$META_DESCRIPTION",
						"$SHOWTIMES_META_DESCRIPTION",
						"$SHOWTIMES_META_TITLE",
						"$SYNOPSIS_META_DESCRIPTION",
						"$SYNOPSIS_META_TITLE",
						"$VIDEO_META_TITLE",
						"$VIDEO_META_DESCRIPTION",
						"$GALLERY_META_DESCRIPTION",
						"$GALLERY_META_TITLE"
					]
				},
				generalTicketing: {
					title: "General Ticketing",
					properties: [
						"$GET_TICKETS",
						"$SHOWTIMES",
						"$ANY_CHAIN",
						"$ANY_DAY",
						"$ANY_FORMAT",
						"$ANY_MOVIE",
						"$ANY_THEATER",
						"$ANY_TIME",
						"$BACK",
						"$OR",
						"$SEARCH",
						"$BACK_TO_SEARCH_AND_FILTER",
						"$CALENDAR_ADD",
						"$REMIND_ME",
						"$FACEBOOK_NOTIFICATION",
						"$FILTER_CTA",
						"$CTA_TITLE",
						"$MORE_SHOWTIMES_FOUND_IN",
						"$MORE_SHOWTIMES_AFTER_TEXT",
						"$SELECT_TICKET_PROVIDER",
						"$SELECT_TIME_TO_PURSHASE",
						"$OTHER_NEAR_CINEMAS",
						"$FILTER_RESULTS",
						"$NO_SHOWTIMES",
						"$OR_WATCH_TRAILER",
						"$CHANGE_TIME",
						"$COMING_SOON",
						"$ENCODED_MESSAGE",
						"$RELEASE_DESCRIPTION",
						"$META_DESCRIPTION"
					]
				},

				directTicketing: {
					title: "Direct Ticketing",
					properties: [
						"$AREA_FOR",
						"$BOOK_TICKETS_AGAIN",
						"$BOOKING_DETAILS",
						"$BOOKING_FEE",
						"$CANNOT_LEAVE_ORPHAN_SEATS",
						"$GO_TO_THEATER_WEBSITE",
						"$GRAB_SEATS_AGAIN",
						"$I_HAVE_A_LOYALTY_CARD",
						"$LOADING_SECURE_PAYMENTS",
						"$LOYALTY_NUMBER",
						"$MX_CANT_CONNECT_TO_LOYALTY",
						"$MX_CINEMA_TICKET_SERVICE_ACCESS_ERROR",
						"$MX_INSUFFICIENT_LOYALTY_MEMBER_DETAILS",
						"$MX_LOYALTY_MEMBER_NOT_FOUND",
						"$MX_LOYALTY_NOT_ALLOWED_FOR_CINEMA_CHAIN",
						"$MX_SEATS_UNAVAILABLE",
						"$MX_UNEXPECTED_CINEMA_TICKET_SERVICE_ERROR",
						"$NO_PAYMENT_GATEWAY_FOUND",
						"$NO_TICKET_AVAIL",
						"$NO_TICKET_AVAIL_AREA",
						"$NO_TICKET_AVAIL_AT_THE_MOMENT",
						"$NOT_ENOUGH_TICKET_FOR_COMPANION",
						"$ONLY_N_TICKETS_AVAIL",
						"$ONLY_N_TICKETS_AVAIL_AREA",
						"$ORDER_COMPLETED",
						"$PICK_THE_WHEELCHAIR",
						"$PICKUP_INFO",
						"$PICKUP_INFO_MESSAGE",
						"$PLEASE_BRING_YOUR_BOOKING_REF",
						"$POW_AUTH_EXPIRED",
						"$POW_AUTH_FAIL",
						"$POW_NOT_YOUR_ORDER",
						"$POW_PRIVATE_SCREENING",
						"$POW_TOO_MANY_ORDERS",
						"$POW_TOO_MANY_TICKETS",
						"$PRINT_YOUR_TICKET",
						"$PROVIDE_PAYMENT_INFO",
						"$RESERVE",
						"$RESET_VIEW",
						"$SEAT_IS_COMPANION",
						"$SEATING_BACK",
						"$SEATING_FRONT",
						"$SEATING_SCREEN",
						"$SEATS_ARE_COMPANION",
						"$STILL_N_SEATS_TO_PICK",
						"$THOSE_SEATS_WERE_RESERVED_RECENTLY",
						"$TICKET_NOT_FOUND",
						"$TICKETING_COMPLETED_MESSAGE",
						"$TICKETING_TIMEOUT_MESSAGE",
						"$TICKETING_TIMEOUT_WARNING",
						"$TICKETS_AT_BOX_OFFICE",
						"$TICKETS_AVAILABLE",
						"$TICKETS_SELECTED",
						"$TOTAL",
						"$WHAT_CONTACT_DETAILS",
						"$WHAT_PAYMENT_DETAILS",
						"$WHICH_SEATS"
					]
				},

				errors: {
					title: "Errors",
					properties: [
						"$FIELD_IS_EMPTY",
						"$FIELD_IS_TOO_SHORT",
						"$FIELD_IS_TOO_LONG",
						"$EMAIL_IS_NOT_VALID",
						"$CHECK_SEARCH_SPELLED_CORRECTLY",
						"$NO_DIRECTIONS_FOUND",
						"$NO_SHOWTIMES_DESCRIPTION",
						"$REGION_MISMATCH_MODAL_MAIN",
						"$REGION_MISMATCH_MODAL_SECONDARY",
						"$REGION_MISMATCH_MODAL_STAY",
						"$SEARCHED_LOCATION_OUTSIDE_REGION",
						"$SEARCHED_LOCATION_OUTSIDE_REGION_MOBILE",
						"$SEARCH_FAILED",
						"$UNABLE_TO_FIND_LOCATION",
						"$UNABLE_TO_SEARCH_LOCATION",
						"$UNKNOW_ERROR"
					]
				},
				messenger: {
					title: "Messenger",
					properties: [
						"$MESSENGER_CTA",
						"$MESSENGER_DESCRIPTION",
						"$MESSENGER_NOT_SENT",
						"$MESSENGER_REMINDER",
						"$MESSENGER_SAVED",
						"$MESSENGER_SENT",
						"MESSENGER_ERROR",
						"messenger",
						"groupView",
						"messengerFlow"
					]
				},
				timings: {
					title: "Timings",
					properties: [
						"$TODAY",
						"$TOMORROW",
						"$MORNING",
						"$AFTERNOON",
						"$EVENING",
						"$LATE",
						"$DATETIME_LOCALE",
						"$DAYS",
						"$HOURS",
						"$MINUTES",
						"$SECONDS",
						"$DAY_FORMAT",
						"$DATE_FORMAT",
						"$TIME_FORMAT",
						"$TIME_PERIOD"
					]
				},
				cookies: {
					title: "Cookies",
					properties: [
						"$COOKIES_MODAL_TITLE",
						"$COOKIES_MODAL_TEXT",
						"$COOKIES_MODAL_ACCEPT",
						"$COOKIES_MODAL_DENY",
						"$YOUTUBE_NO_COOKIES",
						"$YOUTUBE_NO_COOKIES_ALLOW",
					]
				}
			},
			properties: {
				$META_TITLE: {
					type: "string",
					description: "Sets the content for the meta title, twitter title and og title tags, unless page title has already been set in ${PAGE_ID}_META_TITLE"
				},
				$HOME_META_DESCRIPTION: {
					type: "string",
					description: "For the home page - sets the content of the meta description, og and twitter description tagst"
				},
				$HOME_META_TITLE: {
					type: "string",
					description: "For the home page - sets the content for the meta title, twitter title and og title tags"
				},
				$SHOWTIMES_META_DESCRIPTION: {
					type: "string",
					description: "For the showtimes page - sets the content of the meta description, og and twitter description tags"
				},
				$SHOWTIMES_META_TITLE: {
					type: "string",
					description: "For the showtimes page - sets the content for the meta title, twitter title and og title tags"
				},
				$SYNOPSIS_META_DESCRIPTION: {
					type: "string",
					description: "For the synopsis page - sets the content of the meta description, og and twitter description tags"
				},
				$SYNOPSIS_META_TITLE: {
					type: "string",
					description: "For the synopsis page - sets the content for the meta title, twitter title and og title tags"
				},
				$VIDEOS_META_TITLE: {
					type: "string",
					description: "For the video page - sets the content for the meta title, twitter title and og title tags"
				},
				$VIDEOS_META_DESCRIPTION: {
					type: "string",
					description: "For the video page - sets the content of the meta description, og and twitter description tags"
				},
				$GALLERY_META_DESCRIPTION: {
					type: "string",
					description: "For the gallery page - sets the content of the meta description, og and twitter description tags"
				},
				$GALLERY_META_TITLE: {
					type: "string",
					description: "For the gallery page - sets the content for the meta title, twitter title and og title tags"
				},
				$SOCIAL_COPY: {
					type: "string",
					description: "Message that get's shared when someone presses the share buttons (url will be appended at the end)"
				},
				$SHARE_URL: {
					type: "string",
					description: "Override to give the share message a custom url (will normally use the url of the site)"
				},
				$HASHTAG: {
					type: "string"
				},
				$ONESHEET_DESCRIPTION: {
					type: "string",
					description: "Description of onesheet for accessibility"
				},
				$ENCODED_MESSAGE: {
					type: "string"
				},
				$RELEASE_DESCRIPTION: {
					type: "string"
				},
				$SYNOPSIS_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the synopsis page and the og site name meta tag"
				},
				$HOME_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the home page and the og site name meta tag"
				},
				$HOMEENT_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the home entertainment page and the og site name meta tag"
				},
				$GALLERY_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the gallery page and the og site name meta tag"
				},
				$SHOWTIMES_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the showtimes page and the og site name meta tag"
				},
				$DYNAMIC_TITLE: {
					type: "string",
					description: "Sets the <title> tag and the og site title meta tag, unless the titles have already been set with alt_title"
				},
				$CAST: {
					type: "string"
				},
				$RELEASE_DATE_TEXT: {
					type: "string"
				},
				$VIDEOS_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the videos page and the og site name meta tag"
				},
				$TITLE: {
					type: "string"
				},
				$SYNOPSIS_DESCRIPTION: {
					type: "string"
				},
				$META_DESCRIPTION: {
					type: "string",
					description: "Sets the content of the meta description, og and twitter description tags, unless the page description has already been set in ${PAGE_ID}_META_DESCRIPTION"
				},
				$CAST_ALT_TITLE: {
					type: "string",
					description: "Sets the <title> tag of the cast page and the og site name meta tag"
				},
				$STUDIO_NAME: {
					type: "string"
				},
				$COPYRIGHT_STUDIO: {
					type: "string"
				},
				$COMING_SOON: {
					type: "string"
				},
				$TIME_FORMAT: {
					type: "string"
				},
				$SEARCH: {
					type: "string"
				},
				$AFTERNOON: {
					type: "string"
				},
				$EXECUTIVE_PROD: {
					type: "string"
				},
				$COPYRIGHT_POWSTER: {
					type: "string"
				},
				$UNABLE_TO_FIND_LOCATION: {
					type: "string"
				},
				$ANY_THEATER: {
					type: "string"
				},
				$CALENDAR_ADD: {
					type: "string"
				},
				$COPY_LINK_IOS: {
					type: "string"
				},
				$START_ORGANIZING: {
					type: "string"
				},
				$SHOWTIMES: {
					type: "string"
				},
				$ANY_DAY: {
					type: "string"
				},
				$SELECT_TIME_TO_PURSHASE: {
					type: "string"
				},
				$SHOWTIMES_IFRAME: {
					type: "string"
				},
				$CAST_ROLE: {
					type: "string"
				},
				$NO_SHOWTIMES_DESCRIPTION: {
					type: "string"
				},
				$SEARCH_FAILED: {
					type: "string"
				},
				$TERMS: {
					type: "string"
				},
				$HOME: {
					type: "string"
				},
				$HOME_ENT: {
					type: "string"
				},
				$ANY_CHAIN: {
					type: "string"
				},
				$EDIT: {
					type: "string"
				},
				$ANY_FORMAT: {
					type: "string"
				},
				$UNABLE_TO_SEARCH_LOCATION: {
					type: "string"
				},
				$OTHER_NEAR_CINEMAS: {
					type: "string"
				},
				$MORE_SHOWTIMES_FOUND_IN: {
					type: "string"
				},
				groupView: {
					type: "object",
					title: "Group Webview",
					description: "Copy for the messenger group webview"
				},
				messenger: {
					type: "object",
					properties: {
						UNSUB_ONE_TICKETS: {
							type: "string"
						},
						HELP: {
							type: "string"
						},
						UNSUB_ONE: {
							type: "string"
						},
						DEFAULT_ANSWER: {
							type: "string"
						},
						UNSUB_MULTIPLE: {
							type: "string"
						},
						SUB_LIST_ELEM: {
							type: "string"
						},
						CONFIRM_RELEASE: {
							type: "string"
						},
						UNSUB_FAIL: {
							type: "string"
						},
						TICKETS_BUTTON: {
							type: "string"
						},
						TICKETS_TITLE: {
							type: "string"
						},
						ALREADY_SUBSCRIBED: {
							type: "string"
						},
						UNSUB_ONE_RELEASE: {
							type: "string"
						},
						NO_SUBSCRIPTION: {
							type: "string"
						},
						NICE_EVENT_TYPES: {
							type: "object",
							properties: {
								tickets: {
									type: "string"
								},
								release: {
									type: "string"
								}
							}
						},
						RELEASE_SUBTITLE: {
							type: "string"
						},
						TICKETS_SUBTITLE: {
							type: "string"
						},
						SUB_LIST_AFTER: {
							type: "string"
						},
						CONFIRM_TICKETS: {
							type: "string"
						},
						RELEASE_TITLE: {
							type: "string"
						},
						UNSUB_ALL: {
							type: "string"
						},
						SUB_LIST_INTRO: {
							type: "array",
							items: {
								type: "string"
							}
						},
						RELEASE_BUTTON: {
							type: "string"
						}
					}
				},
				$FACEBOOK_NOTIFICATION: {
					type: "string"
				},
				$NO_MEMBER_FILTERING: {
					type: "string"
				},
				$DAY_FORMAT: {
					type: "string"
				},
				$OFFICIAL_WEBSITE: {
					type: "string"
				},
				$COOKIES: {
					type: "string"
				},
				$DIRECTED_BY: {
					type: "string"
				},
				$FILTER_CTA: {
					type: "string"
				},
				$MORNING: {
					type: "string"
				},
				$COPY_LINK: {
					type: "string"
				},
				$MORE: {
					type: "string"
				},
				$TIME_PERIOD: {
					type: "string"
				},
				$ANY_TIME: {
					type: "string"
				},
				$SYNOPSIS: {
					type: "string"
				},
				$TEXT_COPYRIGHT: {
					type: "string"
				},
				$CHECK_SEARCH_SPELLED_CORRECTLY: {
					type: "string"
				},
				$UNKNOW_ERROR: {
					type: "string"
				},
				$WORLDWIDE_RELEASE: {
					type: "string"
				},
				$DATE_FORMAT: {
					type: "string"
				},
				$SEARCHED_LOCATION_OUTSIDE_REGION: {
					type: "string"
				},
				$ANY_MOVIE: {
					type: "string"
				},
				$SHARE: {
					type: "string"
				},
				$LATE: {
					type: "string"
				},
				$WRITTEN_BY: {
					type: "string"
				},
				$TODAY: {
					type: "string"
				},
				$COPY_LINK_SUCCESS: {
					type: "string"
				},
				$GET_TICKETS: {
					type: "string"
				},
				$NO_SHOWTIMES: {
					type: "string"
				},
				$PRIVACY: {
					type: "string"
				},
				$PRODUCED_BY: {
					type: "string"
				},
				$COOKIE_CONSENT: {
					type: "string"
				},
				$VIDEOS: {
					type: "string"
				},
				$HEARTED_SHOWTIMES: {
					type: "string"
				},
				$CTA_TITLE: {
					type: "string"
				},
				$MESSENGER_CTA: {
					type: "string"
				},
				$DISTANCE_UNIT: {
					type: "string"
				},
				$TOMORROW: {
					type: "string"
				},
				$DATETIME_LOCALE: {
					type: "object",
					properties: {
						$MONTHS_SHORT: {
							type: "array",
							items: {
								type: "string"
							}
						},
						$AM: {
							type: "string"
						},
						$TIME_SEPARATOR: {
							type: "string"
						},
						$DAYS_FULL: {
							type: "array",
							items: {
								type: "string"
							}
						},
						$PM: {
							type: "string"
						},
						$DATE_SEPARATOR: {
							type: "string"
						},
						$DAYS_SHORT: {
							type: "array",
							items: {
								type: "string"
							}
						},
						$MONTHS_FULL: {
							type: "array",
							items: {
								type: "string"
							}
						}
					}
				},
				$REMIND_ME: {
					type: "string"
				},
				$EVENING: {
					type: "string"
				},
				$COPY_LINK_FAIL: {
					type: "string"
				},
				$BACK_TO_SEARCH_AND_FILTER: {
					type: "string"
				},
				// Direct Ticketing
				$AREA_FOR: {
					type: "string",
					description: "Used when they are more than 1 seating area (e.g. Area for: VIP, Premium)"
				},
				$BOOK_TICKETS_AGAIN: {
					type: "string",
					description: "Used after an error or a timeout in the ticketing pane"
				},
				$BOOKING_DETAILS: {
					type: "string"
				},
				$BOOKING_FEE: {
					type: "string"
				},
				$CANNOT_LEAVE_ORPHAN_SEATS: {
					type: "string",
					description: "Warns the user that they're leaving a gap between selected seats"
				},
				$GO_TO_THEATER_WEBSITE: {
					type: "string",
					description: "Button that redirects to the theater's website if ticketing doesn't work"
				},
				$GRAB_SEATS_AGAIN: {
					type: "string",
					description: "Used after an error or a timeout in the ticketing pane"
				},
				$I_HAVE_A_LOYALTY_CARD: {
					type: "string"
				},
				$LOADING_SECURE_PAYMENTS: {
					type: "string"
				},
				$LOYALTY_NUMBER: {
					type: "string",
				},
				$MX_CANT_CONNECT_TO_LOYALTY: {
					type: "string",
					description: "Displayed when the connection to the loyalty service failed"
				},
				$MX_CINEMA_TICKET_SERVICE_ACCESS_ERROR: {
					type: "string",
					description: "Displayed when the connection to the cinema's ticketing service failed"
				},
				$MX_INSUFFICIENT_LOYALTY_MEMBER_DETAILS: {
					type: "string"
				},
				$MX_LOYALTY_MEMBER_NOT_FOUND: {
					type: "string"
				},
				$MX_LOYALTY_NOT_ALLOWED_FOR_CINEMA_CHAIN: {
					type: "string",
					description: "Notifies the user that the loyalty membership is disabled for the cinema"
				},
				$MX_SEATS_UNAVAILABLE: {
					type: "string",
					description: "Displayed when they are not enough seats available for the screening"
				},
				$MX_UNEXPECTED_CINEMA_TICKET_SERVICE_ERROR: {
					type: "string",
					description: "Message saying that an error occurred at the cinema's ticketing service"
				},
				$NO_PAYMENT_GATEWAY_FOUND: {
					type: "string",
					description: "Notifies the user there's no payment gateway available to process the order"
				},
				$NO_TICKET_AVAIL: {
					type: "string",
					description: "The user doesn't have enough tickets to pick those seats"
				},
				$NO_TICKET_AVAIL_AREA: {
					type: "string",
					description: "Not enough tickets in this seating area to pick those seats (e.g. VIP)"
				},
				$NO_TICKET_AVAIL_AT_THE_MOMENT: {
					type: "string",
					description: "No tickets available for this screening at the moment"
				},
				$NOT_ENOUGH_TICKET_FOR_COMPANION: {
					type: "string",
					description: "Not enough ticket to pick this (wheelchair's) companion seat"
				},
				$ONLY_N_TICKETS_AVAIL: {
					type: "string"
				},
				$ONLY_N_TICKETS_AVAIL_AREA: {
					type: "string",
					description: "Only N tickets available for this specific seating area (e.g. VIP)"
				},
				$ORDER_COMPLETED: {
					type: "string"
				},
				$PICK_THE_WHEELCHAIR: {
					type: "string",
					description: "Pick the wheelchair space associated with the selected companion seat first"
				},
				$PICKUP_INFO: {
					type: "string",
					description: "Title of the section that describes how to collect tickets at the theater"
				},
				$PICKUP_INFO_MESSAGE: {
					type: "string",
					description: "Tickets are collected at the box office, bring the booking ref to get them"
				},
				$PLEASE_BRING_YOUR_BOOKING_REF: {
					type: "string"
				},
				$POW_AUTH_EXPIRED: {
					type: "string",
					description: "Error shown when the user authentication has expired"
				},
				$POW_AUTH_FAIL: {
					type: "string",
					description: "Error shown when the user cannot be authenticated"
				},
				$POW_NOT_YOUR_ORDER: {
					type: "string",
					description: "Error shown when the user is trying to modify an order he doesn't own"
				},
				$POW_PRIVATE_SCREENING: {
					type: "string",
					description: "Error shown when the user is not allowed to book a ticket for a screening"
				},
				$POW_TOO_MANY_ORDERS: {
					type: "string",
					description: "Error shown when the user has used all his order available for a promotion"
				},
				$POW_TOO_MANY_TICKETS: {
					type: "string",
					description: "Error shown when the user is asking for more tickets than allowed"
				},
				$PRINT_YOUR_TICKET: {
					type: "string"
				},
				$PROVIDE_PAYMENT_INFO: {
					type: "string",
					description: "CTA telling the user to provide their payment info to the theater"
				},
				$RESERVE: {
					type: "string",
					description: "Button saying: Go to payment"
				},
				$RESET_VIEW: {
					type: "string",
					description: "Tooltip for the button that resets the view in the seating map viewer"
				},
				$SEAT_IS_COMPANION: {
					type: "string",
					description: "Disclaimer shown when a companion seat is picked without a wheelchair"
				},
				$SEATING_BACK: {
					type: "string",
					description: "Label of the back area on the seating plan"
				},
				$SEATING_FRONT: {
					type: "string",
					description: "Label for the front area on the seating plan"
				},
				$SEATING_SCREEN: {
					type: "string",
					description: "Label for the screen on the seating plan"
				},
				$SEATS_ARE_COMPANION: {
					type: "string",
					description: "Disclaimer shown when several companion seats are picked without wheelchair"
				},
				$STILL_N_SEATS_TO_PICK: {
					type: "string",
					description: "The user still has N seats to pick before going to the next step"
				},
				$THOSE_SEATS_WERE_RESERVED_RECENTLY: {
					type: "string",
					description: "Error shown when 2 users pick the same seats at the same time"
				},
				$TICKET_NOT_FOUND: {
					type: "string",
					description: "Error shown when the user tries to print a non-existent cinema ticket"
				},
				$TICKETING_COMPLETED_MESSAGE: {
					type: "string",
					description: "Thanks the user and tells the booking confirmation was sent by email"
				},
				$TICKETING_TIMEOUT_MESSAGE: {
					type: "string",
					description: "User ran out of time, their seats were freed up"
				},
				$TICKETING_TIMEOUT_WARNING: {
					type: "string",
					description: "Saying: Time to complete purchase"
				},
				$TICKETS_AT_BOX_OFFICE: {
					type: "string",
					description: "Saying that tickets can be collected at the box office with the booking ref"
				},
				$TICKETS_AVAILABLE: {
					type: "string"
				},
				$TICKETS_SELECTED: {
					type: "string"
				},
				$TOTAL: {
					type: "string"
				},
				$WHAT_CONTACT_DETAILS: {
					type: "string",
					description: "Title of the contact details section"
				},
				$WHAT_PAYMENT_DETAILS: {
					type: "string",
					description: "Title of the payment details section"
				},
				$WHICH_SEATS: {
					type: "string",
					description: "Title of the seating section"
				}
				// End Direct Ticketing
			}
		},
		meta: {
			type: "object",
			title: "Meta",
			description: "Settings related to the app itself (studio, title,...)",
			properties: {
				url: {
					type: "string",
					required: true,
					pattern: "^https?://.+[^/]$",
					feedback: "Needs to start with https:// and not end with /"
				},
				genre: {
					type: "string",
					options: [
						"Action",
						"Adventure",
						"Animation",
						"Biography",
						"Comedy",
						"Concert / Performance",
						"Crime",
						"Documentary",
						"Drama",
						"Family",
						"Fantasy",
						"Foreign Language",
						"Historical/ Period Drama",
						"Horror",
						"Musical",
						"Reality",
						"Romance",
						"Romantic Comedy",
						"Sci-Fi",
						"Thriller",
						"Western"
					]
				},
				dates: {
					type: "object",
					properties: {
						release: {
							$ref: "#/definitions/date"
						},
						limited: {
							$ref: "#/definitions/date"
						},
						live: {
							$ref: "#/definitions/datetime"
						},
						most: {
							$ref: "#/definitions/date"
						},
						boxOffice: {
							$ref: "#/definitions/date"
						}
					}
				},
				id: {
					type: "integer"
				},
				status: {
					type: "string"
				},
				movieMasterIds: {
					type: "array",
					items: {
						type: "string"
					}
				},
				extraRegions: {
					type: "object",
					title: "Extra Regions",
					description: "Regions with external urls that need to show up in the Regional Modal Popup"
				},
				title: {
					type: "object",
					properties: {
						en: {
							type: "string"
						},
						slug: {
							type: "string",
							pattern: "^[a-z0-9-]+$",
							feedback: "Should only contain lowercase letters numbers and dashes"
						},
						locale: {
							type: "string"
						},
						localeSlug: {
							type: "string",
							pattern: "^[a-z0-9-]+$",
							feedback: "Should only contain lowercase letters numbers and dashes"
						},
						webediaIds: {
							type: "array"
						}
					},
					required: ["en", "slug", "locale", "localeSlug", "webediaIds"]
				},
				studio: {
					type: "object",
					properties: {
						domain: {
							type: "string"
						},
						slug: {
							type: "string"
						},
						name: {
							type: "string"
						}
					}
				},
				region: {
					type: "object",
					properties: {
						slug: {
							type: "string"
						},
						code: {
							type: "string"
						}
					}
				},
				vanities: {
					type: "array",
					items: {}
				},
				affiliate: {
					type: "string"
				},
				youtubeNoCookies: {
					type: "boolean",
					title: "Youtube No Cookies",
					description: "Use 'youtube-nocookie' domain for youtube iframe embed"
				},
				social: {
					type: "object",
					properties: {}
				},
				messenger: {
					type: "object",
					properties: {
						pageId: {
							type: "string"
						},
						appId: {
							type: "string"
						}
					}
				},
				noIndex: {
					type: "boolean",
					description: "add noIndex meta tag to doc"
				}
			}
		},
		options: {
			type: "object",
			title: "Features",
			description: "Disabling and enabling features",
			properties: {
				allOf: [{ $ref: "#/definitions/feature" }],
				autoBoundingRegions: {
					type: "boolean",
					description:
						"Use automatic bounding regions instead of the ones in pages.showtimes.data"
				},
				forceLive: {
					type: "boolean",
					description:
						"Forces the app to Showtimes mode with get tickets on home and showtimes in header"
				},
				enableDirectTicketing: {
					type: "boolean",
					description: "Turns on direct ticketing for theaters that support it"
				},
				enableTealiumCookieConsent: {
					type: "boolean",
					title: "Tealium Cookie Consent",
					description:
						"Enable or disable the Tealium cookie consent management tool"
				},
				enableEnsighten: {
					type: "boolean",
					title: "Enable Ensighten",
					description:
						"Enable or disable the Ensighten tracking"
				},
				isDemoTicketing: {
					type: "boolean",
					description: "Turns on fake direct ticketing for all theaters, do not use in production"
				},
				useGoogleAnalytics: {
					type: "boolean",
					description: "Add google analytics to the page for tracking"
				},
				useEnglishCopy: {
					type: "boolean",
					description: "Uses English copy as the default Showtimes language"
				},
				useMessengerRemind: {
					type: "boolean",
					description:
						"Turns on the messenger functionality on the home page remind me section"
				},
				showtimesVerticalStory: {
					type: "boolean",
					description:
						"Turns on the vertical story feature in place of the onesheet on the showtimes page"
				},
				onTrailered: {
					type: "boolean",
					description:
						"Adds the app to the Trailered app so it is a visible trailer in the playlist"
				},
				formatFilterIsStrict: {
					type: "boolean",
					description:
						"Makes format filter fuzzy on showtimes page, i.e. when 'on', ?format=3d will only show 3d screenings, when 'off' it will show 3d imax as well"
				},
				loadShowtimesLinksInPage: {
					type: "boolean",
					description: "Opens showtimes screenings in the same window"
				},
				useBoxOfficeCalendar: {
					type: "boolean",
					description:
						"Uses the box office date instead of the release date for auto-changing to Showtimes mode"
				},
				useFormatFilter: {
					type: "boolean",
					description:
						"Allows you to filter the URL with the query string, like ?format=2d. Really should ALWAYS be on"
				},
				useProviderInterstitial: {
					type: "boolean",
					description:
						"Turns on the interstitial section when clicking on Showtimes screenings, that allows for users to select offers and specific providers (usually for US)"
				},
				useTitleFilter: {
					type: "boolean",
					description:
						"On apps with multiple movies, this turns the filter dropdown into a dropdown of all movies (like on Operaworld)"
				},
				cookiesModal: {
					type: "object",
					title: "Cookies Modal",
					description: "Settings related to the Cookies Modal",
					properties: {
						position: {
							type: "string",
							options: {
								possibilities: [
									{
										title: "Fixed Top",
										value:"fixedTop"
									},
									{
										title: "Sticky Top",
										value:"stickyTop"
									},
									{
										title: "Sticky Bottom",
										value:"stickyBottom"
									}
								]
							}
						},
						lockout: {
							type: "boolean",
							description:
								"If you want to lock the page while cookie modal is active"
						},
						enableModal: {
							type: "boolean",
							description:
								"If you want to enabled Cookies modal on site"
						}
					}
				},
				SMECookieConsent: {
					type: "boolean",
					title: "Sony Music Entertainment Cookies Consent",
					description: "Enable and configure the Sony Music Entertainment cookie consent management tool",
				},
				didomiCookieConsent: {
					type: "object",
					title: "Didomi Cookies Consent",
					description: "Enable and configure the Didomo cookie consent management tool",
					properties: {
						scripts: {
							type: "string",
							title: "Scripts",
							description: "Enter the code snippet containing the Didomi scripts provided by the client"
						}
					}
				},
				disableShowtimesVerticalStoryGifHeader: {
					type: "boolean",
					description: "Turns off the gif header on mobile for vertical stories"
				},
				hideMovieName: {
					type: "boolean",
					description:
						'For multi-movie apps, turn this on so that the movie name in the screenings list will be hidden. e.g instead of "Operaworld: La Traviata", the title will be "La Traviata"'
				},
				googleOptimize: {
					type: "string",
					title: "Google Optimize",
					description: "Allows A/B manipulation and testing via Google Optimize"
				},
				sonyFeatures: {
					type: "object",
					title: "Sony Features",
					description: "Configure the features specific to Sony",
					properties: {
						sonyDataLayer: {
							type: "object",
							title: "Sony DataLayer",
							description: "Configure sony dataLayer object",
							properties: {
								gpmsId: {
									type: "string",
									title: "GPMS ID",
								},
								sonySlug: {
									type: "string",
									title: "Sony Slug",
								},
								contentType: {
									type: "string",
									title: "Content Type",
								},
								siteCountry: {
									type: "string",
									title: "Site Country",
								}
							}
						}
					}
				},
				NTLiveFeatures: { type: "object" },
				oneTrustCookieConsent: { type: "object" },
				ensightenOneTrustGUID: {
					type: "string",
					title: "Ensighten: OneTrust GUID",
					description: "Unique GUID which is passed to Ensighten, applies the correct OneTrust script to the site - only for use with Disney Studios (Disney, 20th Century Studios, Searchlight, Marvel etc.)."
				}
			}
		},
		pages: {
			type: "object",
			title: "Pages",
			description: "All of the pages the the app has",
			properties: {
				allOf: [
					{ $ref: "#/definitions/page" },
					{ $ref: "#/definitions/showtimes", attrValue: "showtimes", attr: "id,component" },
					{ $ref: "#/definitions/videos", attrValue: "videos", attr: "id,component" },
					{ $ref: "#/definitions/synopsis", attrValue: "synopsis", attr: "id,component" },
					{ $ref: "#/definitions/home", attrValue: "home", attr: "id,component" },
					{ $ref: "#/definitions/homeEnt", attrValue: "homeEnt", attr: "id,component" },
					{ $ref: "#/definitions/smartHomeEnt", attrValue: "smartHomeEnt", attr: "id,component" },
					{ $ref: "#/definitions/socialWall", attrValue: "socialWall", attr: "id,component" },
					{ $ref: "#/definitions/about", attrValue: "about", attr: "id,component" },
					{ $ref: "#/definitions/vfx", attrValue: "vfx", attr: "id,component" },
					{ $ref: "#/definitions/scrollSite", attrValue: "scrollSite", attr: "id,component" },
				],
			}
		},
		redirection: {
			type: "object",
			title: "Redirection",
			description: "Redirect to another site under certain conditions",
			allOf: [{ $ref: "#/definitions/redirectionObject" }],
			properties: {
				allOf: [{ $ref: "#/definitions/redirectionObject" }]
			}
		},
		doc: {
			type: "object",
			title: "Html Document",
			description:
				"Everything related to scripts and stylesheets in the document",
			properties: {
				styleLinks: {
					type: "object",
					description: "Links to external styling links",
					title: "Style Links",
					properties: {
						font: {
							type: "string"
						}
					}
				},
				metaTags: {
					type: "object",
					description: "Set site HTML meta data information, such as keywords, description etc.",
					title: "Meta Tags",
					properties: {
						twitterMeta: {
							type: "object",
							title: "Twitter Meta",
							description: "Set specific Twitter meta tags to enable various share card types.",
							properties: {
								description: {
									title: "Post Description",
									type: "string",
									description: "The body text of the post. Maximum character length 280."
								},
								url: {
									title: "Post URL",
									type: "string",
									description: "The URL you wish to attribute to the post."
								},
								hashtags: {
									title: "Post Hashtags",
									type: "string",
									description: "The hash tags you wish to attribute to the post. You can add multiple hashtags by comma separating the values."
								},
								via: {
									title: "Post Via",
									type: "string",
									description: "Twitter accounts you wish to tag in the post. You can add multiple accounts by comma separating the values."
								},
								related: {
									title: "Post Related",
									type: "string",
									description: "Keywords/Phrases used by Twitter for SEO purposes."
								},
								cardSite: {
									title: "Card site",
									type: "string",
									description: "The Twitter @username the card should be attributed to."
								},
								cardTitle: {
									title: "Card title",
									type: "string",
									description: "Title of card content (max 70 characters)."
								},
								cardDescription: {
									title: "Card description",
									type: "string",
									description: "Description of card content (maximum 200 characters)."
								},
								cardImage: {
									title: "Card Image",
									type: "string",
									description: "The image to display on the card."
								},
								cardImageAlt: {
									title: "Card Image Alt",
									type: "string",
									description: "A text description of the Card image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters."
								},
								playerVideo: {
									title: "Player Video",
									type: "string",
									description: "Only applicable if card type is Player Card.  The HTTPS URL of the video you wish to display."
								},
								playerWidth: {
									title: "Player Width",
									type: "string",
									description: "Only applicable if card type is Player Card. Width of Player specified in pixels."
								},
								playerHeight: {
									title: "Player Height",
									type: "string",
									description: "Only applicable if card type is Player Card. Height of Player specified in pixels."
								},
								cardType: {
									type: "string",
									title: "Card Type",
									description: "Set which Twitter card type you wish to display.",
									options: {
										possibilities: [
											{
												title: "None",
												value:"none",
												description: ""
											},
											{
												title: "Summary Card",
												value:"summaryCard",
												description: ""
											},
											{
												title: "Summary Card Large Image",
												value:"summaryCardLargeImage",
												description: ""
											},
											{
												title: "Player Card",
												value:"playerCard",
												description: ""
											}
										]
									}
								},
							}
						},
						facebookMeta: {
							type: "object",
							title: "Facebook Meta",
							description: "Set specific Facebook meta tags to enable various share card types.",
							properties: {
								title: {
									title: "Post title",
									type: "string",
									description: "The title of your article without any branding such as your site name."
								},
								description: {
									title: "Post Description",
									type: "string",
									description: "The body text of the post. Maximum character length 280."
								},
								url: {
									title: "Post URL",
									type: "string",
									description: "The URL you wish to attribute to the post."
								},
								type: {
									title: "Post Type",
									type: "string",
									description: "The type of media of your content.",
									options: {
										possibilities: [
											{
												title: "website",
												value:"website",
												description: ""
											}
										]
									}
								},
								cardImage: {
									title: "Card Image",
									type: "string",
									description: "URL for the share image. To update an image after it's been published, use a new URL for the new image. Images are cached based on the URL and won't be updated unless the URL changes."
								},
								cardSecureImage: {
									title: "Card Secure Image",
									type: "string",
									description: "https:// URL for the image."
								},
								cardImageType: {
									type: "string",
									title: "Card Type",
									description: "Set which Facebook card type you wish to display.",
									options: {
										possibilities: [
											{
												title: "PNG",
												value:"image/png",
												description: ""
											},
											{
												title: "JPEG",
												value:"image/jpeg",
												description: ""
											},
											{
												title: "GIF",
												value:"image/gif",
												description: ""
											}
										]
									}
								},
								cardImageWidth: {
									title: "Card Image Width",
									type: "string",
									description: "Width of Image specified in pixels. For a small share card ensure image is 426W x 567H. For a larger share card ensure image is 1280W x 720H."
								},
								cardImageHeight: {
									title: "Card Image Height",
									type: "string",
									description: "Height of Image specified in pixels. For a small share card ensure image is 426W x 567H. For a larger share card ensure image is 1280W x 720H."
								}
							}
						}
					}
				},
				headerScripts: {
					type: "object",
					description: "Links to external Javascript scripts that need to be loaded in the header",
					title: "Header Scripts",
					properties: {
						demo: {
							type: "object",
							title: "Demo Scripts",
							description: "Scripts that should only load on Demo"
						},
						prod: {
							type: "object",
							title: "Production Scripts",
							description: "Scripts that should only load on Production"
						}
					}
				},
				verificationCodes: {
					title: 'Verification Codes',
					type: 'object',
					properties: {
						fbVerificationCode: {
							title: 'Facebook Verification Code',
							description: 'Set the Facebook Verification Code for this domain',
							type: 'string'
						}
					}
				}
			}
		},
		styling: {
			type: "object",
			title: "Styling",
			description: "Everything related to styling",
			properties: {
				logoShade: {
					type: "string",
					description: "Tint for the cinema logos (dark: white logos, light: black logos)",
					// options: {
					// 	possibilities: ["light", "dark"]
					// }
				},
				socialIconShade: {
					type: "string",
					description: "Tint for the social icons logos",
					// options: {
					// 	possibilities: ["light", "dark"]
					// }
				},
				dataProviderLogoShade: {
					type: "string",
					description: "Tint for the data provider (e.g. movietimes) logo",
					// options: {
					// 	possibilities: ["light", "dark"]
					// }
				},
				mapLogoShade: {
					type: "string",
					description: "Tint for the cinema logos on the GoogleMap",
					options: {
						possibilities: [
							"light",
							"dark"
						]
					}
				},
				images: {
					type: "object",
					title: "Images",
					description: "",
					properties: {
						"bg": {
							type: "string",
							title: "Background Image",
							description: "bg -- The background image used on desktop"
						},
						"200x200": {
							type: "string",
							title: "Share Image",
							description: "200x200 -- Share Image"
						},
						"legal-logos": {
							type: "string",
							title: "Legal Logos",
							description: "legal-logos -- Footer legal logos"
						},
						"mob_bg_landscape": {
							type: "string",
							title: "Mobile Background Landscape",
							description: "mob_bg_landscape -- Mobile Background Landscape"
						},
						"onesheet": {
							type: "string",
							title: "Onesheet",
							description: "onesheet -- One sheet image used on showtimes page"
						},
						"portrait_bg": {
							type: "string",
							title: "Portrait Background Image",
							description: "portrait_bg -- Tablet Portrait Bg Image"
						},
						"tab_bg_landscape": {
							type: "string",
							title: "Landscape Background Image",
							description: "tab_bg_landscape -- Tablet Landscape Bg Image"
						},
						"title-short-bare": {
							type: "string",
							title: "Title Short Bare",
							description: "title-short-bare -- Mobile Header Image"
						},
						"tt": {
							type: "string",
							title: "Title Treatment",
							description: "tt -- Title treatment on home page"
						},

					},
				},
				variables: {
					group: {
						base: {
							title: "Base Styles (Defaults)",
							properties: [
								"base-textColor",
								"base-highlightColor",
								"base-bgColor",
								"base-bgMobileColor",
								"base-btnBgColor",
								"base-btnTextColor",
								"defaultFont",
								"defaultFontWeight",
								"defaultFontStyle",
								"defaultLetterSpacing",
								"fontSize",
								"titleFont",
								"base-textCase",
								"keyboardFocusColor"
							]
						},
						header: {
							title: "Header",
							properties: [
								"header-height",
								"header-navHeight",
								"header-maxWidth",
								"header-mobileBgColor",
								"header-mobileTextColor",
								"header-itemToggleFontSize",
								"header-itemFontSize",
								"header-mobileActiveTextColor",
								"header-mobileActiveBgColor",
								"header-activeTextColor",
								"header-bgColor",
								"header-textColor",
								"header-activeBgColor",
								"header-hashtagFlex"
							]
						},
						homeEnt: {
							title: "HomeEnt",
							properties: [
								"homeEnt-highlightColor",
								"homeEnt-bgColor",
								"homeEnt-textColor",
								"homeEnt-btnTextColor",
								"homeEnt-btnBgColor"
							]
						},
						smartHomeEnt: {
							title: "Smart Home Ent",
							properties: [
								"smartHomeEnt-onesheetBgColor",
								"smartHomeEnt-contentBgColor",
								"smartHomeEnt-formatBtnFontSize",
								"smartHomeEnt-formatBtnTxtColor",
								"smartHomeEnt-formatBtnBorderColor",
								"smartHomeEnt-formatBtnBgColor",
								"smartHomeEnt-activeFormatBtnTxtColor",
								"smartHomeEnt-activeFormatBtnBgColor",
								"smartHomeEnt-formatBtnHoverBg",
								"smartHomeEnt-formatBtnHoverTxtColor",
								"smartHomeEnt-formatBtnHoverBorderColor",
								"smartHomeEnt-availabilityTxtColor",
								"smartHomeEnt-availabilityHoverTxtColor",
								"smartHomeEnt-availabilityHighlightColor",
								"smartHomeEnt-activeAvailabilityTxtColor",
								"smartHomeEnt-backTxtColor",
								"smartHomeEnt-backArrowColor",
								"smartHomeEnt-errorBtnTxtColor",
								"smartHomeEnt-errorBtnBgColor",
								"smartHomeEnt-editionTxtColor",
								"smartHomeEnt-priceBtnBgColor",
								"smartHomeEnt-priceBtnTxtColor",
								"smartHomeEnt-priceBtnBorderColor",
								"smartHomeEnt-priceBtnBgHoverColor",
								"smartHomeEnt-priceBtnTxtHoverColor",
								"smartHomeEnt-priceBtnBorderHoverColor",
								"smartHomeEnt-merchantBtnBgColor",
								"smartHomeEnt-merchantBtnBgHoverColor",
								"smartHomeEnt-merchantIconBoxShadowColor",
								"smartHomeEnt-merchantNameColor",
								"smartHomeEnt-merchantNameHoverColor",
								"smartHomeEnt-playIconColor",
								"smartHomeEnt-playIconHoverColor"
							]
						},
						showtimesTheaters: {
							title: "Showtimes: Theaters",
							properties: [
								"theater-bgColor",
								"theater-logoColor",
								"theater-inactiveBgColor",
								"theater-dateTextColor",
								"theater-dateTextActiveColor",
								"theater-inactiveTextColor",
								"theater-dateBgColor",
								"theater-dateBgActiveColor",
								"theater-textColor"
							]
						},
						showtimesScreenings: {
							title: "Showtimes: Screenings Section",
							properties: [
								"screeningsPanel-bgColor",
								"screeningsPanel-textColor",
								"screeningsPanel-btnTextColor",
								"screeningsPanel-btnBgColor",
								"screeningsPanel-movieTextColor",
								"provider-bgColor",
								"provider-textColor",
								"showtimesHomeEnt-bgColor",
								"showtimesHomeEnt-textColor",
								"showtimesHomeEnt-btnTextColor",
								"showtimesHomeEnt-btnBgColor"
							]
						},
						showtimesMap: {
							title: "Showtimes: Map",
							properties: ["mapDirections-bgColor", "mapDirections-textColor"]
						},
						gallery: {
							title: "Gallery & Video",
							properties: ["gallery-bgColor", "toggleHeight", "playlistHeight"]
						},
						synopsis: {
							title: "Synopsis",
							properties: [
								"synopsis-paragraphMobileFontSize",
								"synopsis-paragraphTabletFontSize",
								"synopsis-paragraphDesktopFontSize",
								"synopsis-headerMobileFontSize",
								"synopsis-headerTabletFontSize",
								"synopsis-headerDesktopFontSize"
							]
						},
						home: {
							title: "Home",
							properties: [
								"homeContent-maxWidth",
								"TTPosition",
								"TTPositionTablet",
								"TTPositionDesktop",
								"TTPositionDesktopDevice",
								"TTPositionTabletDesktopDevice",
								"TTPositionDesktopDesktopDevice"
							]
						},
						modals: {
							title: "Modals",
							properties: ["contentBox-maxWidth", "contentBox-textAlign"]
						},
						footer: {
							title: "Footer",
							properties: ["footer-bgColor", "footer-textColor"]
						},
						media: {
							title: "Media Queries (Breakpoints)",
							properties: [
								"breakTablet",
								"breakTabletLandscape",
								"breakDesktop",
								"breakLargeDesktop"
							]
						},
						brand: {
							title: "Studio Brand Page",
							properties: [
								"brand-textColor",
								"brand-highlightColor",
								"brand-bgColor",
								"brand-font",
								"brand-titleFont"
							]
						},
						regionMismatchModal: {
							title: "Region Mismatch Modal",
							properties: [
								"regionMismatchModal-font",
								"regionMismatchModal-bgColor",
								"regionMismatchModal-highlightColor",
								"regionMismatchModal-textColor",
								"regionMismatchModal-locationBarColor",
								"regionMismatchModal-lowlightColor"
							]
						},
						ticketing: {
							title: "Ticketing",
							properties: [
								"ticketing-headerBgColor",
								"ticketing-headerTextColor",
								"ticketing-mainBgColor",
								"ticketing-mainTextColor",
								"ticketing-btnBgColor",
								"ticketing-btnTextColor",
								"ticketing-seatingThemeColor",
								"ticketing-seatingHighlightColor",
								// "ticketing-availSeatBgColor",
								// "ticketing-availSeatTextColor",
								// "ticketing-reservedSeatBgColor",
								// "ticketing-reservedSeatTextColor",
								// "ticketing-pickedSeatBgColor",
								// "ticketing-pickedSeatTextColor",
								// "ticketing-disabledPersonSeatBgColor",
								// "ticketing-disabledPersonSeatTextColor",
								"ticketing-seatSlotBgColor",
								"ticketing-seatSlotTextColor"
							]
						}
					},
					type: "object",
					title: "Variables",
					description: "Style Variables",
					properties: {
						"regionMismatchModal-lowlightColor": {
							$ref: "#/definitions/color",
							title: "REGION MISMATCH MODAL: Lowlight Color",
							description: "The Region Mimsmatch Modal lowlight color"
						},
						"regionMismatchModal-locationBarColor": {
							$ref: "#/definitions/color",
							title: "REGION MISMATCH MODAL: Location Bar Color",
							description: "The Region Mimsmatch Modal location bar color"
						},
						"regionMismatchModal-textColor": {
							$ref: "#/definitions/color",
							title: "REGION MISMATCH MODAL: Text Color",
							description: "The Region Mimsmatch Modal text color"
						},
						"regionMismatchModal-highlightColor": {
							$ref: "#/definitions/color",
							title: "REGION MISMATCH MODAL: Highlight Color",
							description: "The Region Mimsmatch Modal highlight color"
						},
						"regionMismatchModal-bgColor": {
							$ref: "#/definitions/color",
							title: "REGION MISMATCH MODAL: Background Color",
							description: "The Region Mimsmatch Modal background color"
						},
						"regionMismatchModal-font": {
							type: "string",
							title: "REGION MISMATCH MODAL: Font",
							description: "The Region Mimsmatch Modal font"
						},
						fontSize: {
							type: "string",
							title: "Default font size",
							description:
								"The font size that every text is based on. Be careful, changing this affects the whole app"
						},
						"brand-textColor": {
							$ref: "#/definitions/color",
							title: "BRAND PAGE: Text Color",
							description: "The Studio Brand Page text color"
						},
						"brand-bgColor": {
							$ref: "#/definitions/color",
							title: "BRAND PAGE: Background Color",
							description: "The Studio Brand Page background color"
						},
						"brand-highlightColor": {
							$ref: "#/definitions/color",
							title: "BRAND PAGE: Highlight Color",
							description: "The Studio Brand Page highlight color"
						},
						"brand-font": {
							type: "string",
							title: "BRAND PAGE: Font Family",
							description: "The Studio Brand Page font family"
						},
						"brand-titleFont": {
							type: "string",
							title: "BRAND PAGE: Title Font Family",
							description: "The Studio Brand Page font family for Titles"
						},
						"base-textColor": {
							$ref: "#/definitions/color",
							title: "BASE: Text Color",
							description: "The Base text color"
						},
						"base-highlightColor": {
							$ref: "#/definitions/color",
							title: "BASE: Highlight Color",
							description: "The Highlight color"
						},
						"base-textCase": {
							type: "string",
							title: "BASE: Text Case (uppercase, capitalize, lowercase, none)",
							description: "The Base Text Case setting"
						},
						"keyboardFocusColor": {
							$ref: "#/definitions/color",
							title: "BASE: Keyboard Focus Color",
							description: "The color of an element's outline when focused on by the keyboard tab key"
						},
						"provider-bgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Provider Background Color",
							description:
								"The background color of the ticket provider button"
						},
						"provider-textColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Provider Text Color",
							description:
								"The text color of the ticket provider button"
						},
						"homeContent-maxWidth": {
							type: "string",
							title: "HOME: Max Width ",
							description: "The max width set on the home content"
						},
						"TTPosition": {
							"type": "string",
							"title":"HOME: Mobile TT position",
							"description": "Mobile title treatment position"
						},
						"TTPositionTablet": {
							"type": "string",
							"title":"HOME: Tablet TT position",
							"description": "Tablet title treatment position"
						},
						"TTPositionDesktop": {
							"type": "string",
							"title":"HOME: Desktop TT position",
							"description": "Desktop title treatment position"
						},
						"TTPositionDesktopDevice": {
							"type": "string",
							"title":"HOME: Mobile size TT position on desktop",
							"description": "Mobile size title treatment position on desktop device"
						},
						"TTPositionTabletDesktopDevice": {
							"type": "string",
							"title":"HOME: Tablet size TT position on desktop",
							"description": "Tablet size title treatment position on desktop device"
						},
						"TTPositionDesktopDesktopDevice": {
							"type": "string",
							"title":"HOME: Desktop size TT position on desktop",
							"description": "Desktop size title treatment position on desktop device"
						},
						"header-height": {
							type: "string",
							title: "HEADER: Bar Height",
							description: "The height of the header bar"
						},
						"header-navHeight": {
							type: "string",
							title: "HEADER: Nav Height",
							description: "The height of the header nav menu"
						},
						"header-maxWidth": {
							type: "string",
							title: "HEADER: Max Width",
							description: "The maximum width of the header nav menu"
						},
						"header-mobileBgColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Mobile Background Color",
							description: "The header background colour on mobile"
						},
						"header-mobileActiveTextColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Mobile Active Item Text Color",
							description: "The active item's text colour on mobile headers"
						},
						"header-mobileActiveBgColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Mobile Active Item Background Color",
							description:
								"The active item's background colour on mobile headers"
						},
						"header-activeTextColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Active Item Text Color",
							description: "The active item's text colour"
						},
						"header-activeBgColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Active Item Background Color",
							description: "The active item's background colour on the header"
						},
						"header-mobileTextColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Mobile Text Color",
							description: "The header text colour on mobile"
						},
						"header-itemToggleFontSize": {
							type: "string",
							title: "HEADER: itemToggle Font Size",
							description: "The font size for the header items inside the toggle menu"
						},
						"header-itemFontSize": {
							type: "string",
							title: "HEADER: Item Font Size",
							description: "The font size for the header items"
						},
						"header-hashtagFlex": {
							type: "string",
							title: "HEADER: Hashtag Flex",
							description: "The flex value of the header hashtag"
						},
						"homeEnt-highlightColor": {
							$ref: "#/definitions/color",
							title: "HOMEENT: Highlight Color",
							description: "The HomeEnt logo background color"
						},
						"homeEnt-bgColor": {
							$ref: "#/definitions/color",
							title: "HOMEENT: Background Color",
							description: "The HomeEnt background color"
						},
						"homeEnt-textColor": {
							$ref: "#/definitions/color",
							title: "HOMEENT: Text Color",
							description: "The HomeEnt text color"
						},
						"homeEnt-btnTextColor": {
							$ref: "#/definitions/color",
							title: "HOMEENT: Button Text Color",
							description: "The HomeEnt button text color"
						},
						"homeEnt-btnBgColor": {
							$ref: "#/definitions/color",
							title: "HOMEENT: Big Button Text Color",
							description: "The HomeEnt big button text color"
						},
						"apps-bgColor": {
							$ref: "#/definitions/color",
							title: "APPS: Apps List Background Color",
							description: "Background Color of Sidebar/Apps List"
						},
						"base-bgColor": {
							$ref: "#/definitions/color",
							title: "BASE: Background Color",
							description: "The default background color for videos, gallery, showtimes, etc."
						},
						"base-bgMobileColor": {
							$ref: "#/definitions/color",
							title: "BASE: Mobile Background Color",
							description: "The mobile background color for videos, gallery, showtimes, etc."
						},
						defaultFont: {
							type: "string",
							title: "BASE: Default Font",
							description: "The Default Font Family"
						},
						"defaultFontWeight": {
							"type": "string",
							"title":"BASE: Default Font Weight",
							"description": "The Default Font Weight"
						},
						"defaultFontStyle": {
							"type": "string",
							"title":"BASE: Default Font Style",
							"description": "The Default Font Style",
							options: {
								possibilities: ["normal", "italic", "oblique"]
							}
						},
						"defaultLetterSpacing": {
							"type": "string",
							"title":"BASE: Default Letter Spacing",
							"description": "The Default Letter Spacing"
						},
						"theater-bgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Background",
							description: "Color for the Theater background (showtimes)"
						},
						"footer-bgColor": {
							$ref: "#/definitions/color",
							title: "FOOTER: Background Color",
							description: "Background color for the footer"
						},
						"gallery-bgColor": {
							$ref: "#/definitions/color",
							title: "GALLERY: Background Color",
							description: "Background color for the gallery"
						},
						breakDesktop: {
							type: "string",
							title: "MEDIA QUERIES: Breakpoint Desktop",
							description: "The responsive breakpoint for the desktop"
						},
						"screeningsPanel-movieTextColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Movie Text Color",
							description: "The text color of the movie name/format in the screenings panel"
						},
						"showtimesHomeEnt-bgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS HOME ENT - Background Color",
							description: "The background color of the watch at home section"
						},
						"showtimesHomeEnt-textColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS HOME ENT - Text Color",
							description: "The text color of the watch at home section"
						},
						"showtimesHomeEnt-btnTextColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS HOME ENT - Button Text Color",
							description: "The button text color of the watch at home section"
						},
						"showtimesHomeEnt-btnBgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS HOME ENT - Button Background Color",
							description:
								"The button background color of the watch at home section"
						},
						"mapDirections-textColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES MAP: Directions Icon/Text color",
							description:
								"The color of the directions icons (and background of active direction)"
						},
						"mapDirections-bgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES MAP: Directions Background Color",
							description: "The background of the directions buttons on the map"
						},
						playlistHeight: {
							type: "string",
							title: "GALLERY/VIDEO: Playlist Height",
							description: "The height of the playlist"
						},
						"footer-textColor": {
							$ref: "#/definitions/color",
							title: "FOOTER: Text Color",
							description: "The text color for the footer"
						},
						"theater-logoColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Cinema Logo Color",
							description: "The logo color for the theater"
						},
						toggleHeight: {
							type: "string",
							title: "GALLERY/VIDEO: Toggle Bar Height",
							description: "The height of the toggle bar above the playlist"
						},
						"synopsis-paragraphMobileFontSize": {
							type: "string",
							title: "SYNOPSIS: Mobile Font Size - paragraph",
							description: "The paragraph font size for mobile views"
						},
						"synopsis-paragraphTabletFontSize": {
							type: "string",
							title: "SYNOPSIS: Tablet Font Size - paragraph",
							description: "The paragraph font size for tablet views"
						},
						"synopsis-paragraphDesktopFontSize": {
							type: "string",
							title: "SYNOPSIS: Desktop Font Size - paragraph",
							description: "The paragraph font size for desktop views"
						},
						"synopsis-headerMobileFontSize": {
							type: "string",
							title: "SYNOPSIS: Mobile Font Size - header",
							description: "The header font size for mobile views"
						},
						"synopsis-headerTabletFontSize": {
							type: "string",
							title: "SYNOPSIS: Tablet Font Size - header",
							description: "The header font size for tablet views"
						},
						"synopsis-headerDesktopFontSize": {
							type: "string",
							title: "SYNOPSIS: Desktop Font Size - header",
							description: "The header font size for desktop views"
						},
						"theater-inactiveBgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Inactive Theater Background",
							description: "The background color for an inactive theater"
						},
						"screeningsPanel-btnTextColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Showtimes Button Text Color",
							description: "The text color for the buttons on screenings"
						},
						"contentBox-maxWidth": {
							type: "string",
							title: "MODALS: Content Box Max Width",
							description:
								"Max width for content boxes (ex. Synopsis content box)"
						},
						"contentBox-textAlign": {
							type: "string",
							title: "MODALS: Content Box Text Align",
							description:
								"Text alignment for content boxes (ex. Synopsis content box)"
						},
						titleFont: {
							type: "string",
							title: "BASE: Title Font Family",
							description: "Secondary font family applied to titles"
						},
						"screeningsPanel-bgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Panel Background",
							description: "The background color for the screenings panel"
						},
						"base-btnBgColor": {
							$ref: "#/definitions/color",
							title: "BASE: Button Background",
							description: "The button background color"
						},
						"base-btnTextColor": {
							$ref: "#/definitions/color",
							title: "BASE: Button Text Color",
							description: "The text color for the button"
						},
						"screeningsPanel-btnBgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Showtimes Button Background",
							description: "Background color for the showtimes buttons"
						},
						"theater-dateTextColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Date Text Color (inactive)",
							description: "The text color for inactive theater dates"
						},
						"screeningsPanel-textColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: SCREENINGS - Text Color",
							description: "The text color for the screenings panel"
						},
						"theater-inactiveTextColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Theater Text Color (inactive)",
							description: "The text color for inactive theaters"
						},
						"theater-dateBgColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Date Background (inactive)",
							description:
								"The background color for inactive dates on a theater panel"
						},
						"theater-dateBgActiveColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Date Background (active) ",
							description:
								"The background color for active dates on a theater panel"
						},
						"theater-dateTextActiveColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Date Text Color (active) ",
							description: "The text color for active dates on a theater panel"
						},
						breakTablet: {
							type: "string",
							title: "MEDIA QUERIES: Breakpoint Tablet",
							description:
								"The responsive breakpoint (starting from) tablet devices"
						},
						breakTabletLandscape: {
							type: "string",
							title: "MEDIA QUERIES: Breakpoint Tablet Landscape",
							description:
								"The responsive breakpoint (starting from) tablet devices in landscape"
						},
						"theater-textColor": {
							$ref: "#/definitions/color",
							title: "SHOWTIMES: THEATERS - Text Color",
							description: "The text color for theaters"
						},
						"header-bgColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Background Color",
							description: "The header background color"
						},
						"header-textColor": {
							$ref: "#/definitions/color",
							title: "HEADER: Text Color",
							description: "The text color for header"
						},
						breakLargeDesktop: {
							type: "string",
							title: "MEDIA QUERIES: Breakpoint Large desktop devices",
							description:
								"The responsive breakpoint (starting from) for large desktop devices"
						},
						"ticketing-headerBgColor": {
							"$ref": "#/definitions/color",
							title: "TICKETING: Header background color",
							description: "The background color of the ticketing header"
						},
						"ticketing-headerTextColor": {
							"$ref": "#/definitions/color",
							title: "TICKETING: Header text color",
							description: "The text color of the ticketing header"
						},
						"ticketing-mainBgColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Main background color",
							description: "The background color of the ticketing content panel"
						},
						"ticketing-mainTextColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Main text color",
							description: "The text color of the ticketing content panel"
						},
						"ticketing-btnBgColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Button background color",
							description: "Background color of the buttons in the ticketing pane"
						},
						"ticketing-btnTextColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Button text color",
							description: "Text color of the buttons in the ticketing pane"
						},
						"ticketing-seatingThemeColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Seating theme color",
							description: "Main color used for the seating plan"
						},
						"ticketing-seatingHighlightColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Seating highlight color",
							description: "Color used in the seating plan for picked"
						},
						// "ticketing-availSeatBgColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Available seat background color",
						// 	description: "The background color of an available seat in the seating map"
						// },
						// "ticketing-availSeatTextColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Available seat text color",
						// 	description: "The text color of an available seat in the seating map"
						// },
						// "ticketing-reservedSeatBgColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Reserved seat background color",
						// 	description: "The background color of a reserved seat in the seating map"
						// },
						// "ticketing-reservedSeatTextColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Reserved seat text color",
						// 	description: "The text color of a reserved seat in the seating map"
						// },
						// "ticketing-pickedSeatBgColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Picked seat background color",
						// 	description: "The background color of a picked (selected) seat in the seating map"
						// },
						// "ticketing-pickedSeatTextColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Picked seat text color",
						// 	description: "The text color of a picked (selected) seat in the seating map"
						// },
						// "ticketing-disabledPersonSeatBgColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Disabled person seat background color",
						// 	description: "The background color of a wheelchair/companion seat in the seating map"
						// },
						// "ticketing-disabledPersonSeatTextColor": {
						// 	$ref: "#/definitions/color",
						// 	title: "TICKETING: Disabled person seat text color",
						// 	description: "The text color of a wheelchair/companion seat in the seating map"
						// },
						"ticketing-seatSlotBgColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Seat slot background color",
							description: "The background color of a seat slot (in ticket details or bottom bar)"
						},
						"ticketing-seatSlotTextColor": {
							$ref: "#/definitions/color",
							title: "TICKETING: Seat slot text color",
							description: "The text color of a seat slot (in ticket details or bottom bar)"
						},
						"smartHomeEnt-onesheetBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Onesheet background color",
							description: "The background color of the onesheet container"
						},
						"smartHomeEnt-contentBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Content background color",
							description: "The background color of the main content section"
						},
						"smartHomeEnt-formatBtnFontSize": {
							type: "string",
							title: "SMART HOME ENT: Format button font size",
							description: "Font size of the format button"
						},
						"smartHomeEnt-formatBtnTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Format button text color",
							description: "The text color of the format button (digital, blu-ray, dvd)"
						},
						"smartHomeEnt-formatBtnBorderColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Format button border color",
							description: "The border color of the format button"
						},
						"smartHomeEnt-formatBtnBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Format button background color",
							description: "The background color of the format button"
						},
						"smartHomeEnt-activeFormatBtnTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Active format button text color",
							description: "The color of the format button text when it is the active format"
						},
						"smartHomeEnt-activeFormatBtnBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Active format button background color",
							description: "The background color of the format button when it is the active format"
						},
						"smartHomeEnt-formatBtnHoverBg": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Format button hover background color",
							description: "The background color of the format button when it is hovered over"
						},
						"smartHomeEnt-formatBtnHoverTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Format button text hover color",
							description: "The color of the format button text when it is hovered over"
						},
						"smartHomeEnt-formatBtnHoverBorderColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Format button hover border color",
							description: "The color of the format button border when it is hovered over"
						},
						"smartHomeEnt-availabilityTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Availability text color",
							description: "The text color of the availability options (buy, rent, stream etc)"
						},
						"smartHomeEnt-availabilityHoverTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Availability hover text color",
							description: "The text color of the availability option when hovered over"
						},
						"smartHomeEnt-availabilityHighlightColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Availability highlight color",
							description: "The color of the highlighted underline to mark the availability as the active option"
						},
						"smartHomeEnt-activeAvailabilityTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Active availability text color",
							description: "The text color of the availability option when it is active"
						},
						"smartHomeEnt-backTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Back text color",
							description: "The color of the text 'back' that brings the user back to the main view"
						},
						"smartHomeEnt-backArrowColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Back arrow color",
							description: "The color of the small arrow that appears next to the text 'back' that brings the user back to the main view"
						},
						"smartHomeEnt-errorBtnTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Error button text color",
							description: "The text color of the button to refresh the page after an error occurred"
						},
						"smartHomeEnt-errorBtnBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Error button background color",
							description: "The background color of the button to refresh the page after an error occurred"
						},
						"smartHomeEnt-editionTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Edition text color",
							description: "The color of the edition text (eg. HD, SD, normal)"
						},
						"smartHomeEnt-priceBtnBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Price button background color",
							description: "The background color of the price button, that links out to the merchant site"
						},
						"smartHomeEnt-priceBtnTxtColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Price button text color",
							description: "The text color of the price button"
						},
						"smartHomeEnt-priceBtnBorderColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Price button border color",
							description: "The border color of the price button"
						},
						"smartHomeEnt-priceBtnBgHoverColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Price button background hover color",
							description: "The background color of the price button when hovered over"
						},
						"smartHomeEnt-priceBtnTxtHoverColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Price button text hover color",
							description: "The text color of the price button when hovered over"
						},
						"smartHomeEnt-priceBtnBorderHoverColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Price button border hover color",
							description: "The border color of the price button when hovered over"
						},
						"smartHomeEnt-merchantBtnBgColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Merchant button background color",
							description: "The background color of the merchant button (eg. Amazon, iTunes)"
						},
						"smartHomeEnt-merchantBtnBgHoverColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Merchant button background hover color",
							description: "The background of the merchant button when hovered over"
						},
						"smartHomeEnt-merchantIconBoxShadowColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Merchant icon box shadow color",
							description: "The color of the box shadow for the merchant icons"
						},
						"smartHomeEnt-merchantNameColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Merchant name color",
							description: "The color of the merchant name (only visible with JustWatch data provider)"
						},
						"smartHomeEnt-merchantNameHoverColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Merchant name hover color",
							description: "The color of the merchant name when hovered over (only visible with JustWatch data provider)"
						},
						"smartHomeEnt-playIconColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Play icon color",
							description: "The color of the play icon on the merchant buttons"
						},
						"smartHomeEnt-playIconHoverColor": {
							$ref: "#/definitions/color",
							title: "SMART HOME ENT: Play icon hover color",
							description: "The color of the play icon on the merchant buttons, when hovered over"
						}
					}
				}
			}
		},
		urls: {
			type: "object",
			title: "Assets",
			description: "Assets and their urls",
			properties: {
				bgVideo: {
					type: "string"
				},
				cloudfront: {
					type: "string"
				},
				roots: {
					type: "object",
					properties: {
						media: {
							type: "string"
						},
						image: {
							type: "string"
						},
						imageRegion: {
							type: "string"
						},
						studio: {
							type: "string"
						},
						video: {
							type: "string"
						},
						sharedImages: {
							type: "string"
						}
					}
				}
			}
		},
		footer: {
			type: "object",
			title: "Footer",
			description:
				"Everything related to the footer on the page (copyright, ...)",
			properties: {
				copyright: {
					type: "object",
					sortBy: "order",
					properties: {
						studio: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						},
						powster: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						}
					}
				},
				links: {
					type: "object",
					sortBy: "order",
					properties: {
						cookies: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						},
						terms: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						},
						cookieConsent: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						},
						privacy: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						},
						studio: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						},
						release: {
							type: "object",
							properties: {
								url: {
									type: "string"
								},
								trackId: {
									type: "string"
								},
								order: {
									type: "integer"
								},
								$copy: {
									type: "string"
								}
							}
						}
					}
				},
				textCopyright: {
					type: "object",
					properties: {
						$copy: {
							type: "string"
						}
					}
				},
				NTLiveFooter: { type: "hidden" }
			}
		},
		apis: {
			type: "object",
			title: "Api Settings",
			description: "All of the api settings like tracking",
			properties: {
				mapTiler: {
					description: "Map settings for MapTiler service",
					type: "object",
					properties: {
						mapId: {
							type: "string",
							title: "Map ID",
							description: "Map ID. I.E for the URL: https://cloud.maptiler.com/maps/1e0aabbf-a391-456f-82b9-5c755735ec7b/, it would be 1e0aabbf-a391-456f-82b9-5c755735ec7b"
						},
						key: {
							type: "string",
							title: "Key",
							description: "Map key. This is the same for all builds and should be set in base."
						}
					}
				},
				gmaps: {
					type: "object",
					properties: {
						key: {
							type: "string"
						}
					}
				},
				tracking: {
					type: "object",
					properties: {
						loadedExternally: {
							type: "boolean",
							description: "Cookie tracking system (i.e. One Trust, Evidon) is being loaded by client"
						},
						googleAnalytics: {
							type: "object",
							properties: {
								category: {
									type: "string"
								},
								studio: {
									type: "string",
									title: "Studio GA Code",
									description: "The GA code for the studio"
								},
								global: {
									type: "string",
									title: "Powster Global GA Code",
									description: "The Powster global GA code, this should never be overriden!"
								},
								local: {
									type: "string",
									title: "Local GA Code",
									description: "Extra GA code that can be added to any app"
								}
							}
						},
						insights: {
							type: "object",
							properties: {
								root: {
									type: "string"
								}
							},
							trackingCategories: {
								$ref: "#/definitions/trackingCategories"
							}
						},
						sonyCookies: {
							type: "object",
							title: "Sony Cookies",
							description: "Variables for Sony Evidon banner implementation",
							properties: {
								evidonId: {
									type: "string",
									title: "Evidon ID",
									description: "ID for loading evidon scripts. Should be same for all apps, unless specified"
								},
								gtm: {
									type: "string",
									title: "GTM ID",
									description: "The GTM to be loaded if the banner is accepted"
								},
								popupPushdown: {
									type: "boolean",
									description: "Should the consent banner push down the page content"
								}
							}
						},
						kppidCookie: {
							type: "object",
							title: "Kppid Cookie (SPE US)",
							properties: {
								enabled: {
									type: "boolean",
									title: "Is Enabled",
									description: "Requirement for Sony Pictures for US builds. Kppid with 11 characters alphanumeric value is set in the head of the page"
								}
							}
						},
						googleTagManager: {
							type: "object",
							title: "Google Tag Manager",
							properties: {
								tagId: {
									type: "object",
									title: "Tag ID",
									description:
										"Standard Google Tag Manager ID (Will start with GTM)"
								},
								uaId: {
									type: "string",
									title: "Global Site Tag ID",
									description:
										"Enhanced Ecommerce GTM id (Will probably start with either UA, DC or AW)"
								},
								trackingCategories: {
									$ref: "#/definitions/trackingCategories"
								},
								embedInHead: {
									type: "boolean",
									title: "Embed in Head",
									description:
										"Embed the Google Tag Manager in HTML Page Head. Requirement for Fox + OneTrust. Do not set OneTrust category for GTAG if this property is true"
								}
							}
						},
						oneTrust: {
							type: "object",
							title: "OneTrust Integration",
							properties: {
								scriptId: {
									type: "string",
									title: "Script ID",
									description:
										"Script ID for the OneTrust integration"
								},
								useTestScript: {
									type: "boolean",
									title: "Use test version",
									description:
										"Select whenever oneTrust production is not refreshed"
								},
								oneTrustFix: {
									type: "boolean",
									title: "oneTrustFix",
									description:
										"if true it adds a OneTrust HotFix sent to us at 31st of July"
								},
								newUniversalScript: {
									type: "boolean",
									title: "New Universal Script",
									description:
										"if true it uses the new script format given to us by Universal March 2020"
								},
								useCookiePro: {
									type: "boolean",
									title: "Use Cookie Pro",
									description:
										"Select for clients using Onetrust Cookie Pro system"
								},
								useAutoblock: {
									type: "boolean",
									title: "Use Autoblock feature from new OneTrust",
									description:
										"Select for clients using autoblock feature"
								},
								displayInIframe: {
									type: "boolean",
									title: "Display In Iframe",
									description: "Please select this toggle if the site is displayed in iframe and the parent site has OneTrust"
								},
								integrity: {
									type: "string",
									title: "Integrity",
									description: "Enter string you recieved from studio, mainly used with Universal sites."
								},
								snippet: {
									type: "string",
									title: "Snippet",
									description: "Enter the code snippet containing the OneTrust scripts provided by the client"
								},
								crossOrigin: {
									type: "string",
									title: "Should add crossorigin attribute",
									description: "Should add crossorigin attribute. Defaults to yes - 'anonymous'. Add none to disable"
								}
							}
						},
						operam: {
							type: "object",
							title: "Operam",
							description: "Add in Operam Tag Integration",
							properties: {
								initId: {
									type: "string",
									title: "Init Id",
									description: "Connects specific data to the clients account"
								},
								loadId: {
									type: "string",
									title: "Load Id",
									description: "For routing data to various providers and pixels"
								},
								trackId: {
									type: "string",
									title: "Track Id",
									description: "Value to be tracked when the page is loaded, usually 'view' or 'pageview'"
								},
								trackingCategories: {
									$ref: "#/definitions/trackingCategories"
								}
							}
						},
						pixels: {
							type: "object",
							$children: {
								properties:[
									{
										trackingCategories: {
											$ref: "#/definitions/trackingCategories"
										}
									}
								]
							},
							properties: {
								facebookTag: {
									type: "object",
									title: "Facebook",
									description: "Facebook Tag Pixels (snippets including fbq)",
									properties: {
										id: {
											type: "string",
											title: "Facebook ID",
											description:
												"Facebook pixel id (NOTE: you can add in multiple ids by seperating them with a comma ex: xxxx,xxxx)"
										},
										trackId: {
											type: "string",
											title: "Track ID",
											description:
												"Track Id Name (will most likely be PageView)"
										},
										trackTicketClickEvent: {
											type: "string",
											title: "Track Ticket Click Event",
											description:
												"Provide the event name that should be send through to facebook (ex. fbq('trackCustom,EVENT_NAME)) on ticket click"
										}
									}
								},
								twitterTag: {
									type: "object",
									title: "Twitter",
									description: "Twitter Tag Pixels (snippets including twq)",
									properties: {
										id: {
											type: "string",
											title: "Twitter Pixel ID",
											description: "will be the id being used in the init function | ex. twq('init', 'ID_IS_HERE')"
										},
										trackId: {
											type: "string",
											title: "Track Event Name",
											description: "Event Name on Load, default will be PageView if not set | ex twq('track','TRACK_ID_HERE')"
										}
									}
								},
								gwallet: {
									type: "object",
									title: "Google Wallet",
									description: "Google Wallet Pixel (snippets including gwallet.com)",
									properties: {
										id: {
											type: "String",
											title: "Tag Id",
											description: "the id being used for the pixel"
										}
									}
								},
								remarketing: {
									type: "object",
									title: "Remarketing",
									description: "Google Remarketing Tag | ex. //www.googleadservices.com/pagead/conversion.js",
									properties: {
										id: {
											type: "string",
											title: "Tag Id",
											description: "ID for the remarketing tag | ex. 	var google_conversion_id = TAG HERE"
										},
										label: {
											type: "string",
											title: "Tag Label",
											description: "Optional label for the tag | google_conversion_label = \"value\""
										},
										remarketingOnly: {
											type: "boolean",
											title: "Remarketing Only",
											description: "Default to true | google_remarketing_only = true/false"
										}
									}
								},
								floodlight: {
									type: "object",
									title: "Floodlight",
									description: "Floodlight Tag | ex. fls.doubleclick.net",
									properties: {
										landing: {
											type: "string",
											title: "Landing",
											description: "ID to fire on landing | the id is available in {ID}.fls.doubleclick.net"
										}
									}
								},
								yandex: {
									type: "object",
									title: "Yandex",
									description: "Yandex Tag | ex. mc.yandex.ru/metrika/tag.js",
									properties: {
										id: {
											type: "string",
											title: "Tag Id",
											description: "ID for the Yandex pixel | ex. 54340573"
										}
									}
								},
								lionsgateus: {
									type: "object",
									title: "Lionsgate US",
									description: "Lionsgate US Tag",
									properties: {
										id: {
											type: "string",
											title: "DX Number",
											description: "ID for Lionsgate US pixel | ex. 54340573"
										}
									}
								},
								snapchat: {
									type: "object",
									title: "Snapchat",
									description: "Snapchat Pixel (snippets including snaptr)",
									properties: {
										id: {
											type: "string",
											title: "Tag Id",
											description: "ID for the snapchat pixel | ex. snaptr('init','PIXEL_ID_HERE',{});"
										},
										trackId: {
											type: "string",
											title: "Track Event Name",
											description: "Event Name on Load, default will be PAGE_VIEW if not set | ex snaptr('track','TRACK_ID_HERE')"
										}
									}
								},
								plista: {
									type: "object",
									title: "Plista",
									description: "Plista ITC/BTK pixel for Universal | CH",
									properties: {
										domainId: {
											type: "string",
											title: "Domain Id",
											description: "Domain ID for Plista pixel | ex. 38040);"
										},
										campaignId: {
											type: "string",
											title: "Campaign Id",
											description: "Campaign ID for Plista pixel | ex. 1060548);"
										},
									}
								},
								pinterest: {
									type: "object",
									title: "Pinterest",
									description: "Pinterest Pixel Tag",
									properties: {
										id: {
											type: "string",
											title: "Pinterest Tag ID",
											description: "will be the ID being used in the load function | ex. pintrk('load', 'ID_IS_HERE')"
										}
									}
								},
								ad2: {
									type: "object",
									title: "AD2",
									description: "Ad2 Tag",
									properties: {
										dn: {
											type: "string"
										},
										events: {
											type: "string",
											title: "Events",
											description: 'Events called by trackAdEvent, should be comma-separated'
										}
									}
								},
								yahoo: {
									type: "object",
									title: "Yahoo",
									description: "Yahoo Tag",
									properties: {
										projectId: {
											type: "string",
											title: "Project ID"
										},
										pixelId: {
											type: "string",
											title: "Pixel ID"
										}
									}
								}
							}
						},
						powsterGATracking: {
							type: "object",
							title: "Powster GA Tracking",
							description: "Enable the new refined Powster GA tracking for titles",
							properties: {
								powsterCustomGATitle: {
									type: "string",
									title: "Powster Title GA Tracking ID",
									description:
										"Please insert Title Google Analytics Tracking ID to enable tracking at title level"
								},
								powsterCustomGAGlobal: {
									type: "string",
									title: "Powster Studio GA Tracking ID",
									description:
										"Please insert Studio Google Analytics Tracking ID to enable tracking at global level"
								},
								trackingCategory: {
									type: "string",
									title: "Tracking category",
									description: "Please select the tracking category for the Powster GTM",
									$ref: "#/definitions/trackingCategories"
								}
							}
						},
					}
				},
				mailchimpPopup: {
					type: "object",
					title: "Mailchimp Subscriber Popup",
					description: "Embed Mailchimp  Subscriber Popup Widgets on site",
					properties: {
						id: {
							type: "string",
							title: "ID"
						}
					}
				},
				stdata: {
					type: "object",
					properties: {
						root: {
							type: "string"
						}
					}
				},
				groupr: {
					type: "object",
					properties: {
						root: {
							type: "string"
						}
					}
				}
			}
		},
		dev: {
			type: "object",
			title: "Development",
			description: "Development settings",
			properties: {
				realTimeLogsArn: {
					type: "string",
					title: "CloudWatch Real Time Logs ARN",
					description: "ARN of the real time logs configuration. Should be set in based. Used for analytics hosting dashboard"
				},
				noDeploy: {
					type: "boolean"
				},
				removeDev: {
					type: "boolean"
				},
				sharedCloudfrontID: {
					type: "string"
				},
				sharedAssets: {
					type: "object",
					properties: {
						outlookCal: {
							type: "string"
						},
						googleCal: {
							type: "string"
						},
						iCal: {
							type: "string"
						}
					}
				},
				assetUpdateDateTime: {
					$ref: "#/definitions/datetime",
					title: "Datetime for asset folder switch",
					description: "If set, assets using %IMAGE_REGION_ROOT_DYNAMIC% will look in the updates folder after this date"
				}
			}
		}
	},
	definitions: {
		color: {
			type: "color"
		},
		date: {
			type: "date"
		},
		datetime: {
			type: "datetime"
		},
		provider: {
			type: "object",
			properties: {
				rank: { type: "string" }
			}
		},
		trackingCategories: {
			type: "string",
			title: "Tracking Category",
			description: "Categories for tracking pixels/cookies",
			options: {
				possibilities: [
					{
						title: "Uncategorized",
						value:"UNCATEGORIZED",
						description: ""
					},
					{
						title: "Strictly Necessary",
						value:"STRICTLY_NECESSARY",
						description: "These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services you have asked for, like shopping baskets or e-billing, cannot be provided."
					},
					{
						title: "Functional",
						value:"FUNCTIONAL",
						description: "These cookies allow the website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites."
					},
					{
						title: "Targeting",
						value:"TARGETING",
						description: "These cookies are used to deliver adverts more relevant to you and your interests They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organisations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organisation."
					},
					{
						title: "Performance",
						value:"PERFORMANCE",
						description: "These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works."
					}
				]
			}
		},
		oneTrustCategories: {
			type: "string",
			title: "One Trust Tracking Category",
			description: "One Trust categories for tracking pixels/cookies",
			options: {
				possibilities: [
					{
						title: "Targeting",
						value:"TARGETING",
						description: "These cookies are used to deliver adverts more relevant to you and your interests They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organisations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organisation."
					},
					{
						title: "Strictly Necessary",
						value:"STRICTLY_NECESSARY",
						description: "These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services you have asked for, like shopping baskets or e-billing, cannot be provided."
					},
					{
						title: "Functional",
						value:"FUNCTIONAL",
						description: "These cookies allow the website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites."
					},
					{
						title: "Performance",
						value:"PERFORMANCE",
						description: "These cookies collect information about how visitors use a website, for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works."
					}
				]
			}
		},
		currencyOptions: {
			type: "string",
			title: "Currency Options",
			description: "Currency Symbol prefix",
			options: {
				possibilities: [
					{
						title: "GBP",
						value:"£",
						description: "British Pound Stirling",
						regionList: ["gb"]
					},
					{
						title: "USD",
						value:"$",
						description: "US Dollar",
						regionList: ["us"]
					},
					{
						title: "EUR",
						value:"€",
						description: "Euro",
						regionList: ["fr", "de", "au", "be_fr", "be_nl", "ch", "ch_fr", "es", "ie", "it", "nl"]
					}
				]
			}
		},
		providersArray: {
			type: "object",
			sortBy: "rank",
			properties: {
				allOf: [{ $ref: "#/definitions/provider" }]
			}
		},
		feature: {
			type: "boolean",
			baseType: "boolean"
		},
		page: {
			type: "page",
			typeName: "page",
			baseType: "object",
			properties: {
				data: { type: "object", hiddenInAddNew: true },
				path: { type: "string" },
				$copy: { type: "string" },
				redirectOnLoad: {
					type: "string",
					title: "Redirect To (on load)",
					description: "Page ID towards which the user will be redirected to on load"
				}
			},
			required: ["data", "$copy", "path"]
		},
		redirectionObject: {
			type: "object",
			properties: {
				url: {
					type: "string",
					title: "URL",
					description: "The URL to redirect to"
				},
				country: {
					type: "string",
					title: "Country is",
					description: "Country codes for which the redirection SHOULD happen"
				},
				countryNot: {
					type: "string",
					title: "Country is not",
					description: "Country codes for which the redirection SHOULD NOT happen"
				}
			}
		},
		vimeoVideo: {
			$ref: "#/definitions/vimeoVideo",
			required: ["vimeoID"],
			type: "vimeoVideo",
			title: "Vimeo Video",
			properties: {
				vimeoID: {
					type: "string",
					title: "Vimeo ID",
					description: "The id of the Vimeo video (number at the end of the vimeo url)"
				},
				thumbSrc: {
					type: "string",
					title: "Thumbnail url",
					description: "The url to the thumbnail"
				},
			},
		},
		youtubePlaylistVideo: {
			required: ["ytid", "name", "thumbSrc", "publishedAt"],
			type: "youtubePlaylistVideo",
			title: "Youtube Video",
			helper: "YoutubeVideo",
			properties: {
				ytid: {
					type: "string",
					title: "Youtube ID",
					description: "The id of the youtube video"
				},
				name: {
					type: "string",
					title: "Name",
					description: "Name of the video"
				},
				thumbSrc: {
					type: "string",
					title: "Thumbnail url",
					description: "The url to the thumbnail"
				},
				publishedAt: {
					type: "string",
					title: "Published at",
					description: "The date the video was published on youtube"
				},
				from: {
					$ref: "#/definitions/datetime",
					description: "Enable only after this date/time",
					title: "From",
					hiddenInAddNew: true
				},
				to: {
					$ref: "#/definitions/datetime",
					description: "Enable only until this date/time",
					title: "To",
					hiddenInAddNew: true
				}
			}
		},
		youtubePlaylistPlaylist: {
			type: "youtubePlaylistPlaylist",
			title: "Youtube Playlist",
			properties: {
				playlistId: {
					type: "string",
					title: "Youtube Playlist ID",
					description: "The id of the youtube playlist"
				},
				playlistOrder: {
					default: [],
					type: "array",
					title: "Playlist Order",
					description: "Order of the items in playlist",
					hiddenInAddNew: true
				},
				disabledItems: {
					default: [],
					type: "array",
					title: "Disabled Items",
					description: "Items to be disabled in playlist",
					hiddenInAddNew: true
				},
				from: {
					$ref: "#/definitions/datetime",
					description: "Enable only after this date/time",
					title: "From",
					hiddenInAddNew: true
				},
				to: {
					$ref: "#/definitions/datetime",
					description: "Enable only until this date/time",
					title: "To",
					hiddenInAddNew: true
				}
			}
		},
		disneyKalturaVideo: {
			$ref: "#/definitions/disneyKalturaVideo",
			type: "disneyKalturaVideo",
			title: "Disney Kaltura Video",
			properties: {
				disneySrc: {
					type: "string",
					title: "Kaltura video src",
					description: "The source of the Kaltura video, provided by Disney"
				},
				name: {
					type: "string",
					title: "Name",
					description: "Name of the video"
				},
				thumbSrc: {
					type: "string",
					title: "Thumbnail url",
					description: "The url to the thumbnail"
				}
			}
		},
		disneyMatterhornVideo: {
			$ref: "#/definitions/disneyMatterhornVideo",
			type: "disneyMatterhornVideo",
			title: "Disney Matterhorn Video",
			properties: {
				disneySrc: {
					type: "string",
					title: "Matterhorn video src",
					description: "The source of the Matterhorn video, provided by Disney"
				},
				name: {
					type: "string",
					title: "Name",
					description: "Name of the video"
				},
				thumbSrc: {
					type: "string",
					title: "Thumbnail url",
					description: "The url to the thumbnail"
				}
			}
		},
		navLink: {
			type: "object",
			title: "Link",
			properties: {
				$copy: {
					type: "string",
					title: "Copy",
					description: "Reference to the copy variable",
					suggestions: "copy" // give the path to the suggestions
				},
				link: {
					type: "string",
					title: "URL",
					description: "the url the link will navigate to"
				},
				target: {
					type: "string",
					default: "_blank"
				},
				type: {
					type: "string",
					default: "link",
					readonly: true
				},
				trackId: {
					type: "string",
					description: "id to be tracked for analytics"
				},
			},
			required: ["$copy"]
		},
		navPage: {
			type: "object",
			title: "Page",
			properties: {
				id: {
					type: "string",
					title: "Page",
					description: "the id of the page",
					suggestions: "pages"
				},
				type: {
					type: "string",
					default: "page",
					readonly: true
				}
			}
		},
		showtimes: {
			$ref: "#/definitions/showtimes",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						mapStyles: {
							type: "json",
							description: "Google Maps styling object"
						},
						providers: {
							sortBy: "rank",
							properties: {
								allOf: [{ $ref: "#/definitions/provider" }]
							}
						},
						providerLocation: {
							type: "object",
							properties: {
								allOf: [{ $ref: "#/definitions/providersArray" }]
							}
						},
						hideSuggestedCities: {
							type: "boolean",
							description: "Add this toggle if you want to hide the suggested cities at the bottom of the page"
						},
						searchInputAriaLabel: {
							type: "string",
							description: "Accessibility - aria label to tell screen readers what this input is for"
						},
						powRefQueryString: {
							type: "string",
							description: "Query string added to deeplinks identifying it as a referral from Powster. Only for fandango at the moment"
						},
						closableTrailer: {
							type: "object",
							properties: {
								src: {
									type: "string",
									description: "Path to a .mp4 file if not using a youtube video"
								},
								ytid: {
									type: "string",
									description: "Youtube Id"
								},
								closeText: {
									type: "string",
									description: "Optional text to be added to the close button"
								},
								overlayText: {
									type: "string",
									description: "Optional text to be added at the top of the video"
								},
								showTT: {
									type: "boolean",
									description: "Whether or not to show the title treatment above the video"
								}
							}
						},
						oneSheetImage: {
							type: "string",
							title: "Onesheet Image",
							description: "onesheet -- One sheet image used on showtimes page"
						},
						orderTitlesByDate: {
							type: "boolean",
							description: "Add this toggle to order titles by date, instead of alpha-order, for multi-event platforms with multiple titles (i.e. ROH Live Cinema Season)."
						},
					}
				}
			}
		},
		videos: {
			$ref: "#/definitions/videos",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						list: {
							type: "array",
							properties: {
								allOf: [
									{ $ref: "#/definitions/youtubePlaylistVideo" },
									{ $ref: "#/definitions/youtubePlaylistPlaylist" },
									{ $ref: "#/definitions/vimeoVideo" },
									{ $ref: "#/definitions/disneyKalturaVideo" },
									{ $ref: "#/definitions/disneyMatterhornVideo" }
								]
							}
						},
						youtubePlaylistID: {
							type: "string"
						},
						autoCollapse: {
							type: "boolean",
							description: "Collapses the video thumbnails after a short time",
							title: "Collapse Thumbnails"
						},
						"customDesktopUI": {
							"type": "boolean",
							"description": "Setting this to true will cause youtube player controls, title-info, and annotations to be hidden"
						},
						xLink: {
							type: "string",
							description: "Optionally link to another page",
							title: "xLink"
						},
						optionalText: {
							type: "boolean",
							description: "Include SEO terms for video",
							title: "SEO for video"
						},
						disableAutoRedirect: {
							type: "boolean",
							description: "Include SEO terms for video",
							title: "disableAutoRedirect"
						},
						disableVideoTitle: {
							type: "boolean",
							description: "Disable our own video title that appears over Youtube videos",
							title: "disableVideoTitle"
						}
					}
				}
			}
		},
		synopsis: {
			$ref: "#/definitions/synopsis",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						"includeCast": {
							"type": "boolean",
							"description": "Include cast data to the right of synopsis (copy in $SYNOPSIS_DESCRIPTION)"
						},
						"customStyling": {
							"type": "boolean",
							"description": "Change styling to content container to .customContainer"
						},
						"descriptionCopy": {
							"type": "string",
							"description": "Add custom description copy to overwrite $SYNOPSIS_DESCRIPTION"
						},
						"headerCopy": {
							"type": "string",
							"description": "Add custom header copy to overwrite $SYNOPSIS_HEADER"
						},
						// "imageSrcCast": {
						// 	"type": "string",
						// 	"description": "Image source for title treatment with cast included"
						// },
						"imageSrc": {
							"type": "string",
							"description": "Image source for title treatment"
						}
					}
				}
			}
		},
		home: {
			$ref: "#/definitions/home",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						countdownDate: {
							// $ref: "#/definitions/datetime"
							type: "string"
						},
						releaseDateData: {
							type: "object",
							properties: {
								allOf: [{ $ref: "#/definitions/date" }]
								// "start": { "$ref": "#/definitions/date" }
							}
						},
						closableTrailer: {
							type: "object",
							properties: {
								src: {
									type: "string",
									description: "Path to a .mp4 file if not using a youtube video"
								},
								ytid: {
									type: "string",
									description: "Youtube Id"
								},
								closeText: {
									type: "string",
									description: "Optional text to be added to the close button"
								},
								overlayText: {
									type: "string",
									description: "Optional text to be added at the top of the video"
								},
								showTT: {
									type: "boolean",
									description: "Whether or not to show the title treatment above the video"
								}
							}
						}
					}
				}
			}
		},
		homeEnt: {
			$ref: "#/definitions/homeEnt",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						promotionalRedirection: {
							title: "Promotional Redirection",
							type: "object",
							properties: {
								promotionalRedirectionUrl: {
									title: "Promotional Redirection URL",
									type: "string",
									description:
										"URL that the promotional redirection will redirect to"
								},
								promotionalRedirectionTimer: {
									title: "Promotional Redirection Timer",
									type: "string",
									description:
										"Countdown timer for the promotional redirection"
								},
								promotionalBackgroundOpacity: {
									title: "Promotional Background Opacity",
									type: "string",
									description:
										"Leave following entry field blank and click Add. Default value will be $promoBackgroundOpacity. Set background opacity (0 to 1) under Styling > Variables",
									value: "$promoBackgroundOpacity"
								}
							}
						},
						transparentNoLink: {
							title: "Transparent dead links",
							type: "string",
							description:
								"Links without urls become partially transparent and unclickable"
						}
					}
				}
			}
		},
		smartHomeEnt: {
			$ref: "#/definitions/smartHomeEnt",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						"useFindAnyFilmData": {
							"type": "boolean",
							"description": "Do you want to use the Find Any Film HomeEnt data feed?"
						},
						"useJustWatchData": {
							"type": "boolean",
							"description": "Do you want to use the JustWatch HomeEnt data feed?"
						},
						"titleId": {
							"type": "string",
							"description": "Add title id, if you are using Find Any Film you can find this at findanyfilm.com, e.g. 164182 = Joker, if you are using Just Watch please use their id"
						},
						currencyOptions: {
							$ref: "#/definitions/currencyOptions"
						}
					}
				}
			}
		},
		socialWall: {
			$ref: "#/definitions/socialWall",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						id: {
							type: "string",
							description: "The id of the wall from the aggregator"
						},
						type: {
							type: "string",
							description: "The type of social wall, eg curator, walls.io",
							options: {
								possibilities: [ "walls", "curator" ]
							}
						},
						title: {
							type: "object",
							description: "(Optional) - Object for page title",
							properties: {
								text: {
									type: "string",
									description: "Set the title text for page"
								},
								order: {
									type: "string",
									description: "(Optional) - Set where the title sits on the page"
								}
							}
						},
						paragraph: {
							type: "object",
							description: "(Optional) - Object for page paragraph",
							properties: {
								text: {
									type: "string",
									description: "Set the paragraph text for page"
								},
								order: {
									type: "string",
									description: "(Optional) - Set where the paragraph sits on the page"
								}
							}
						}
					}
				}
			}
		},
		about: {
			$ref: "#/definitions/about",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						hero: {
							type: "object",
							description: "For the video / image at the top of the page",
							properties: {
								type: {
									type: "string",
									description: "Set to either 'video' or 'image'"
								},
								title: {
									type: "object",
									description: "(Optional) - For text to be set over video or image",
									properties: {
										pixels: {
											type: "string",
											description: "(Optional) - y and x distance from edge. Include px"
										},
										position: {
											type: "string",
											description: "(Optional) - Set where the image is over the asset. Takes top/bottom/center, left/right/center"
										},
										text: {
											type: "string",
											description: "Text to show"
										}
									}
								},
								frame: {
									type: "boolean",
									description: "(Optional) - For bottom of image to be framed",
								}
							}
						},
						title: {
							type: "string",
							description: "Title outside of image"
						},
						list: {
							type: "object",
							description: "For the rows of content",
							properties: {
								allOf: [{
									type: {
										type: "string",
										description: "What type of content? text, video, youtube, image, row, divider"
									},
									order: {
										type: "string",
										description: "Set where the item sits on the page"
									}
								}]
							}
						}
					}
				}
			}
		},
		vfx: {
			$ref: "#/definitions/vfx",
			type: "object",
			properties: {
				data: {
					type: "object",
					properties: {
						layers: {
							type: "array",
							description: "Data relating to text to overlay for different layers",
							properties: {
								allOf: [{
									properties: {
										desc: {
											type: "string",
											description: "Text typically describing the layer"
										},
										name: {
											type: "string",
											description: "Title of layer e.g Final Shot"
										}
									}
								}]
							}
						},
						video: {
							type: "object",
							description: "Data relating to the video",
							properties: {
								desktop: {
									type: "object",
									required: ["layerCount", "layoutColumns", "layoutRows", "src"],
									properties: {
										layerCount: {
											type: "string",
											description: "Number of layers to be used"
										},
										layoutColumns: {
											type: "string",
											description: "How many columns are in the video"
										},
										layoutRows: {
											type: "string",
											description: "How many rows are in the video"
										},
										src: {
											type: "string",
											description: "Video source"
										}
									}
								},
								mobile: {
									type: "object",
									properties: {
										layerCount: {
											type: "string",
											description: "Number of layers to be used"
										},
										layoutColumns: {
											type: "string",
											description: "How many columns are in the video"
										},
										layoutRows: {
											type: "string",
											description: "How many rows are in the video"
										},
										src: {
											type: "string",
											description: "Video source"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		scrollSite: {
			$ref: "#/definitions/page",
			properties: {
				data: {
					type: "object",
					properties: {
						scrollingSite: {
							type: "boolean",
							description: "This will update the url to match the section of the page the user is currently visiting"
						},
						sections: {
							type: "object",
							required: ["landing"],
							properties: {
								landing: {
									type: "object",
									description: "Landing section",
									properties: {
										background: {
											type: "object",
											description: "Background image or video data and file paths for the landing section",
											properties: {
												alt: {
													type: "string",
													description: "Text describing the image and will be added to the alt attribute on the HTML image tag"
												},
												preLoad: {
													type: "object",
													description: "Image that will preLoad while a background video is downloading",
													properties: {
														desktop: {
															type: "string",
															description: "File path for desktop devices"
														},
														mobile: {
															type: "string",
															description: "File path for mobile devices"
														},
														tablet: {
															type: "string",
															description: "File for tablet devices"
														}
													}
												},
												srcDesktop: {
													type: "string",
													description: "Video or image file path for desktop devices"
												},
												srcTablet: {
													type: "string",
													description: "Video or image file for tablet devices"
												},
												srcTabletLandscape: {
													type: "string",
													description: "Video or image file for tablet devices in landscape mode"
												},
												srcMobile: {
													type: "string",
													description: "Video or image file path for mobile devices"
												},
												srcMobileLandscape: {
													type: "string",
													description: "Video or image file path for mobile devices in landscape mode"
												}
											}
										},
										id: {
											type: "string",
											description: "Should be same name as the section, ie: 'landing'"
										},
										link: {
											type: "object",
											description: "To add a button on the landing section that can either scroll to another section, be an internal page link, or link to an external page",
											properties: {
												ariaLabel: {
													type: "string",
													description: "Accessibility - aria label to tell screen readers what this input is for"
												},
												label: {
													type: "string",
													description: "This is the text that will be shown on the button and visible by the user"
												},
												accessibleTitle: {
													type: "string",
													description: "HTML title attribute for button that appears on mouse hover"
												},
												type: {
													type: "object",
													description: "Type of button",
													properties: {
														href: {
															type: "string",
															description: "Link to an external page, ie. https://www.google.com. PageId and scrollSection must be disabled"
														},
														pageId: {
															type: "string",
															description: "Link to an internal page, enter name of the page, ie synopsis. Href and scrollSection must be disabled"
														},
														scrollSection: {
															type: "string",
															description: "Scroll to another section on the page. Enter the section ID, ie gallery. PageId and Href must be disabled"
														},
													}
												}
											}
										},
										order: {
											type: "integer",
											description: "Set where this section should be ordered on the page"
										},
										scrollArrow: {
											type: "object",
											description: "A button that scrolls to the next section on the page when clicked. Add svg file of the arrow here in Powblade: $imageRegionRoot/icons/arrow-down.svg",
											properties: {
												alt: {
													type: "string",
													description: "Text describing the image and will be added to the alt attribute on the HTML image tag, ie. 'Scroll to view more Rising Phoenix content'"
												},
												ariaLabel: {
													type: "string",
													description: "Accessibility - aria label to tell screen readers what this input is for, ie. Scroll"
												},
												accessibleTitle: {
													type: "string",
													description: "HTML title attribute for button that appears on mouse hover"
												},
											}
										},
										tt: {
											type: "object",
											description: "Title Treatment",
											properties: {
												alt: {
													type: "string",
													description: "Text describing the image and will be added to the alt attribute on the HTML image tag"
												},
												srcDesktop: {
													type: "string",
													description: "Image file path for desktop devices"
												},
												srcMobile: {
													type: "string",
													description: "Image file path for mobile devices"
												},
												srcMobileLandscape: {
													type: "string",
													description: "Image file path for mobile devices in landscape mode"
												},
												srcTablet: {
													type: "string",
													description: "Image file for tablet devices"
												},
												srcTabletLandscape: {
													type: "string",
													description: "Image file for tablet devices in landscape mode"
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
};
