import toRegExp from './toRegExp';

// Replace the content in a VNode by some other VNode
// for example, to put 'cat' in bold
// let result = replaceWithVNode(<div>I love my cat, he is the best</div>, 'cat', <b>cat</b>);
// - OR (works with regexp and callback functions) -
// let result = replaceWithVNode(<div>My cat ♥ your dog</div>, /\b(cat|dog)\b/g, match => <b>{match[1]}</b>);
// /!\ changes the src object (and returns it) !
export function replaceWithVNode(src, search, replace, options) {
	return _replaceWithVNode(src, search, replace, options, 0);
}
function _replaceWithVNode(src, search, replace, options, count) {
	if (!src || !search) return src;
	// Prevent deep recursion ?
	if (++count > 20) return src;
	search = toRegExp(search, options?.ignoreCase);
	let replaceFunc = replace;
	if (typeof replace !== 'function') replaceFunc = () => replace;
	// Array
	if (Array.isArray(src)) {
		return src.reduce((arr, elem) => arr.concat(_replaceWithVNode(elem, search, replace, options, count)), []);
	}
	// VNode
	if (typeof src === 'object' && src.children) {
		src.children = src.children.reduce((arr, child) => arr.concat(_replaceWithVNode(child, search, replace, options, count)), []);
		return src;
	}
	// Make sure to cast to string
	src = src + '';
	let result = [];
	let cPos = 0;
	let found;
	while (found = search.exec(src)) { // eslint-disable-line no-cond-assign
		result.push(src.slice(cPos, found.index), replaceFunc(found));
		cPos = found.index + found[0].length;
		// The regex is not global, stop at the first match (otherwise inifnite loop - stays on the same match when doing exec multiple times)
		if (!search.global) break;
	}
	// No match at all, just return the source instead of an array containing the source
	if (!result.length) return src;
	// Don't forget the part between the end of the last match and the end of the string
	result.push(src.slice(cPos));
	return result;
}

let _classCheck = (el, attr, val) => el.attributes && ~(' ' + (el.attributes.class || el.attributes.className || '') + ' ').indexOf(' ' + val + ' ');
let _attributeCheck = (el, attr, val) => attr ? el.attributes && (val ? el.attributes[attr] == val : Object.prototype.hasOwnProperty.call(el.attributes, attr)) : (val ? val === '*' || el.nodeName === val : false);
function _matchSelector(node, selector) {
	if (!node) return false;
	return selector.every(s => {
		let attr = undefined;
		let val = undefined;
		let fn = _attributeCheck;
		if (s[0] === '.') fn = _classCheck, val = s.slice(1);
		else if (s[0] === '#') attr = 'id', val = s.slice(1);
		else if (s[0] === '[') {
			let match = s.match(/^\[([^=]+)(=(['"]?)(.+)\3)?\]$/);
			if (match) attr = match[1], val = match[4];
		} else val = s;
		return fn(node, attr, val);
	});
}

// Return the subelement matching the selector
// - node: VNode in which to perform the search (for example <div class="myElement"><input id="val" disabled /></div>)
// - selector: CSS-like selector for the element you want to find (e.g. '#val')
// - asPath: if true returns the path to access the match (as an array of strings), instead of returning the matching element itself. Can be used in accessNested. (e.g. ['children', '2', 'children', '1'])
// Supported selector things :
// - + (adjacent)
// - ~ (sibling)
// -   (space ; descendant)
// - > (direct child)
// - .class
// - #id
// - [attribute]
// - [attribute="value"]
export function findInVNode(node, selector, asPath) {
	return _findInVNode(node, selector, asPath, false, [], 0);
}
function _findInVNode(node, selector, asPath, direct, currentPath, childrenOffset) {
	let parts = selector;
	if (!Array.isArray(selector)) parts = (selector || '').split(/\s*(\+|>|~|\s)\s*/g);
	if (!parts[0]) return undefined;


	let sel = parts[0].split(/(\[[^\]]+\]|[.#]?[^.#\[]+)/g).filter(e => e); // eslint-disable-line no-useless-escape
	let srcIsArray = Array.isArray(node);
	if (!srcIsArray) node = [node];
	for (let i = 0, l = node.length; i < l; i++) {
		let el = node[i];
		if (!el) continue;
		let nextNodes;
		let nextSelector = parts;
		let nextPath = currentPath.slice();
		let nextOffset = 0;
		if (srcIsArray) nextPath.push(i + childrenOffset);
		if (_matchSelector(el, sel)) {
			if (parts.length < 2) {
				if (asPath) {
					if (srcIsArray) currentPath.push(i + childrenOffset);
					// return currentPath.join('.');
					return currentPath;
				}
				return el;
			}
			nextNodes = el.children;
			nextPath.push('children');
			direct = false;
			let inter = parts[1];
			if (inter === '>') {
				direct = true;
			} else if (inter === '~' || inter === '+') {
				nextNodes = inter === '+' ? node.slice(i + 1, i + 2) : node.slice(i + 1);
				nextPath = currentPath.slice();
				nextOffset = childrenOffset + i + 1;
				direct = true;
			}
			nextSelector = parts.slice(2);
		} else if (!direct && el.children) {
			nextNodes = el.children;
			nextPath.push('children');
		}
		if (nextNodes) {
			let nextResult = _findInVNode(nextNodes, nextSelector, asPath, direct, nextPath, nextOffset);
			if (nextResult) {
				return nextResult;
			}
		}
	}
}
