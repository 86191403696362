import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import { joinClasses } from 'utils/utils';
import s from 'components/shared/infoSidebar/myApps/buildProgressModal.sss';

export default class BuildProgressModal extends Component {

	constructor(props) {
		super(props);
		let {
			myApps,
			id
		} = props;

		let app = myApps[id];
		if (!app) {
			Store.emit(actions.HIDE_MODAL, 'NO_APP');
			return;
		}
		let bases = app.status.build.bases || {};
		let basesList = Object.keys(bases);
		if (!basesList.length) {
			Store.emit(actions.HIDE_MODAL, 'NO_DATA');
			return;
		}

		let selectedBase = basesList.find(b => bases[b].progress < 1) || basesList[0];

		this.state.autoScroll = true;
		this.state.selectedBase = selectedBase;
	}

	componentDidMount() {
		if (this.$details) {
			this.$details.addEventListener('scroll', this.detailsScroll);
		}
		if (this.state.autoScroll && this.$details) {
			this.$details.scrollTop = this.$details.scrollHeight;
		}
	}
	componentWillUnmount() {
		if (this.$details) {
			this.$details.removeEventListener('scroll', this.detailsScroll);
		}
	}

	componentDidUpdate() {
		if (this.state.autoScroll && this.$details) {
			this.$details.scrollTop = this.$details.scrollHeight;
		}
	}

	detailsScroll = (e) => {
		let top = e.target.scrollTop;
		let max = e.target.scrollHeight - e.target.clientHeight;
		let lockDesired = top >= max - 10;
		if (lockDesired !== this.state.autoScroll) {
			this.setState({ autoScroll: lockDesired });
		}
	}

	renderTab(base, data) {
		let active = this.state.selectedBase === base;
		let onClick = () => this.setState({ selectedBase: base });
		return (
			<div class={joinClasses(s.tab, active && s.active)} onClick={onClick}>
				<div class={s.name}>{base}</div>
				<div class={s.progressBar} style={{ transform: 'scaleX(' + data.progress + ')' }} />
			</div>
		);
	}

	render(props, state) {
		let { myApps, id } = props;

		let { selectedBase } = state;

		let app = myApps[id];
		if (!app) {
			Store.emit(actions.HIDE_MODAL, 'NO_APP');
			return;
		}
		let bases = app.status.build.bases || {};
		let basesList = Object.keys(bases);
		if (!basesList.length) {
			Store.emit(actions.HIDE_MODAL, 'NO_DATA');
			return;
		}

		let tabs = basesList.map(k => this.renderTab(k, bases[k]));

		let data = bases[selectedBase];
		let currentProgress = (100 * data.progress).toFixed(2) + '%';
		return (
			<div class={s.container}>
				<div class={s.tabs}>{tabs}</div>
				<div class={s.numericValue}>{currentProgress}</div>
				<div class={s.details} ref={e => this.$details = e}>{data.messages}</div>
			</div>
		);
	}

}
