import Store from 'store/store';

// Utils related to copy

let copy = {};
let isCopySet = false;

/**
 * Gets copy for the provided key + replace variables inside that copy.
 *
 * @example
 * getCopy('GALLERY_ALT_TITLE', { movieName: 'Rogue One', studioName: 'Disney' });
 * // returns 'Rogue One: Gallery | Disney'
 *
 * getCopy('CURRENCY', { value: 20 });
 * // returns £20
 *
 * getCopy('GET_TICKETS');
 * // returns Get Tickets
 *
 * getCopy('DYNAMIC_TITLE', { movieName: 'Star Trek' });
 * // returns: Star Trek: Hello There | Star Trek
 *
 * getCopy('TICKETS', {}, 'tickets');
 * // TICKETS isn't in the copy object, so it will return the fallback
 * // returns: tickets
 *
 * @note If there are two keys KEY and $KEY defined in copy, you WON'T be able to access
 *       KEY with this function. Use appData instead (or find a different name).
 *
 * @param {string} copyKey - the copy key (with or without the starting $)
 * @param {Object} [opts={}] - options
 * @param {string} [fallback=''] - the fallback value
 * @return {string} the localized string
 */
export const getCopy = (copyKey, opts = {}, fallback = '', fromCopy, boltCopyType) => {
	if (fromCopy && typeof fromCopy !== 'object') {
		fromCopy = null;
	}

	if ((!fromCopy && !isCopySet) || boltCopyType) {
		// Haven't retrieved copy yet, so lets do that first
		const store = Store.get();
		copy = store.appData?.copy || store.copy || {};
		isCopySet = true;
	}

	if (!fromCopy) {
		if (boltCopyType === 'accessibilityCopy') {
			fromCopy = copy.accessibilityCopy;
		} else if (boltCopyType === 'baseCopy' || copy.baseCopy) {
			fromCopy = copy.baseCopy;
		} else {
			fromCopy = copy;
		}
	}

	let copyItem = null;
	if (copyKey) {
		if (copyKey.charAt(0) !== '$') {
			copyItem = fromCopy['$' + copyKey];
			if (copyItem === undefined) copyItem = fromCopy[copyKey];
		} else {
			copyItem = fromCopy[copyKey];
			if (copyItem === undefined) copyItem = fromCopy[copyKey.substr(1)];
		}
	}
	if (copyItem === undefined) copyItem = fallback;

	// Loop through all the options to see replace
	Object.keys(opts).forEach((key) => {
		// Transform movieName to MOVIE_NAME
		let realKey = key;
		if (key.toUpperCase() !== key) {
			realKey = key.replace(/([A-Z])/g, ' $1').replace(/ /g, '_').toUpperCase();
		}

		const regex = new RegExp('%' + realKey + '%', 'g');
		// Replace the keys with what was provided
		copyItem = copyItem.replace(regex, opts[key]);
	});

	return copyItem;
};
