import { h, Component } from 'preact';
import { createStudio } from 'services/dataApi.js';
import s from 'components/pages/apps/studioCreator.sss';
import Store from 'store/store';
import * as actions from 'store/actions';
import slug from 'slugify';

export default class StudioCreator extends Component {
	constructor() {
		super();
		this.state.studio = '';
		this.save = this.save.bind(this);
	}

	async save(e) {
		e.preventDefault();
		let studio = this.state.studio;
		if (!studio) {
			return;
		}
		studio = studio.trim();
		const studioLower = studio.toLowerCase();
		const existingStudio = this.props.studios.find((item) => item.name?.toLowerCase() === studioLower);

		if (existingStudio) {
			// eslint-disable-next-line no-alert
			alert('Studio already exists');
			return;
		}

		let slugMessage = 'The studio you entered is all lowercase.\nYou should input the studio title, not the slug.\nDo you want to continue anyway?';
		// eslint-disable-next-line no-alert
		if (studioLower === studio && !confirm(slugMessage)) {
			return;
		}

		let whitespaceMessage = 'The studio you entered contains spaces at the beginning or end. This is usually not desired.\nDo you want to continue anyway?';
		// eslint-disable-next-line no-alert
		if (studio.trim() !== studio && !confirm(whitespaceMessage)) {
			return;
		}

		const studioSlug = slug(studio, { lower: true, remove: /[^\w\s-]/g });

		const data = await createStudio({
			slug: studioSlug,
			name: studio,
			base: this.props.base
		});

		if (!data.id) {
			// eslint-disable-next-line no-alert
			alert('Error creating studio. Ask a dev!');
			return;
		}

		Store.emit(actions.NEW_STUDIO, data);
		this.props.onSave(data);
		this.setState({ studio: '' });
	}

	render({ onCancel }, { studio }) {
		return (
			<form class={s.base} onSubmit={this.save}>
				<button class={s.cancel} type="button" onClick={onCancel}>Select Existing Studio</button>
				<span class={s.or}>or</span>
				<input class={s.newStudio} value={studio} onInput={this.linkState('studio')} placeholder="Studio Name" />
				<button class={s.save} type="submit" disabled={!studio}>Save</button>
			</form>
		);
	}

}
