import { h, Component } from 'preact';
import pure from 'utils/pure';
import { accessNested } from 'utils/utils';
import Store from 'store/store';
import * as actions from 'store/actions';
import { Link, goToPage } from 'components/core/link/link.js';
import HTML from 'components/core/html/html.js';
import s from 'components/shared/infoSidebar/issues/issue.sss';
// import LEVELS from 'constants/levels.js';
// import { updateAppData } from 'services/dataApi.js';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Display a single issue
 * @prop {Object} issue - the issue that is being displayed
 * @prop {Int} appId - id if the app that has the problem
**/
export default @pure class Issue extends Component {

	render(props) {
		let interactions = [];

		let destination = { app: props.appId, title: null, studio: null, region: null, studio_region: null, base: null };
		if (props.issue.path) {
			let seeIssue = async (e) => {
				e?.preventDefault();
				if (window.setActivePath) {
					let navigated = goToPage('editApp', destination);
					if (navigated) {
						// Wait for content to be updated
						await new Promise(r => setTimeout(r, 0));
					}
					window.setActivePath(props.issue.path);
				}
			};
			// interactions.push(
			// 	<Link class={s.btn} pageId="editApp" queryString={{ app: props.appId, path: props.issue.path }}>See Issue</Link>
			// );
			interactions.push(
				<button class={s.btn} onClick={seeIssue}>See Issue</button>
			);
			if (Object.prototype.hasOwnProperty.call(props.issue, 'fix')) {
				let update = (e) => {
					// Ugly way for now ... Need to improve that (pass props and stuff)
					let currentAppData = Store.get().apps[props.appId].toJS();
					let editedAppData = cloneDeep(currentAppData.edited || currentAppData.attributes);
					let path = props.issue.path.split('.');
					let prop = path.pop();
					let parent = accessNested(editedAppData, path);
					if (parent && typeof parent === 'object') {
						parent[prop] = props.issue.fix;
					}
					Store.emit(actions.UPDATE_EDITED_APP_DATA, currentAppData, editedAppData);
					Store.emit(actions.VALIDATE_MY_APPS);
					seeIssue();
					// Save automatically ?
					// updateAppData(props.appId, '', editedAppData, currentAppData)
					// 	.then((data) => {
					// 		Store.emit(actions.UPDATE_APP_DATA, data);
					// 		Store.emit(actions.VALIDATE_MY_APPS);
					// 	}).catch((err) => {
					// 		console.log('ERROR SAVING', err);
					// 	});
				};
				interactions.push(<button class={s.btn} onClick={update}>Auto fix Issue</button>);
			}
		} else if (props.issue.pageId) {
			interactions.push(<Link class={s.btn} pageId={props.issue.pageId} queryString={props.issue.queryString}>See Issue</Link>);
		}

		return (
			<div class={s.container}>
				<HTML
					class={s.errorMessage}
					markdown
					content={props.issue.message}
				/>
				<div class={s.interactions}>
					{interactions}
				</div>
			</div>
		);

	}

}
