// Fires a callback when an object property changes
//   obj: the object to watch
//   props: a property name or an array of properties as strings to be watched
//   cb: Function to be called when any of props change in obj
//     cb is called with 3 parameters : name of the property which has changed, the old property value, the new property value
//   initiallyPaused: If true, do not start immediatly this watcher
// Returns an object containing :
//   start: a function which starts/restarts this watcher
//   stop: a function which stops this watcher
//   remove: completly removes this watcher - it can't be used anymore
// ----- EXAMPLE : watch(document, 'title', (prop, oldVal, newVal) => console.log('title changed : ' + newVal)) -----
// ----- EXAMPLE : watch(window, ['screenX', 'screenY'], () => console.log('window moved !')) -----
// WARNING : this is an intensive thing. Avoid using it if possible, and always prefer event listeners if available
//    ESPECIALLY intensive if accessing a DOM property triggering a reflow (e.g. clientWidth)
// Update : watches are using a single shared requestAnimationFrame
let watch = (function() {
	if (typeof window === 'undefined' || !window.requestAnimationFrame) {
		let empty = () => {};
		return () => ({ start: empty, stop: empty, remove: empty });
	}
	let watches = [];
	// Check every watch
	function check() {
		for (let i = 0, l = watches.length; i < l; i++) {
			let watch = watches[i];
			if (!watch.active) continue;
			let props = watch.props;
			let values = watch.values;
			for (let j = props.length; j--;) {
				let prop = props[j];
				let newVal = watch.obj[prop];
				let oldVal = values[prop];
				if (oldVal !== newVal) {
					watch.cb(prop, oldVal, newVal);
					values[prop] = newVal;
				}
			}
		}
		if (timer) timer = requestAnimationFrame(check);
	}
	let timer;
	// Add a watch
	return function(obj, props, cb, initiallyPaused) {
		props = Array.isArray(props) ? props.slice() : [props];
		let watch = {
			active: !initiallyPaused,
			obj: obj,
			props: props,
			values: {},
			cb: cb
		};
		watches.push(watch);
		if (!timer) timer = requestAnimationFrame(check);
		// Returns an object to have a way to stop a watch
		return {
			start: () => (watch.active = true),
			stop: () => (watch.active = false),
			remove: () => {
				let id = watches.indexOf(watch);
				if (id === -1) return;
				watches.splice(id, 1);
				if (!watches.length) {
					cancelAnimationFrame(timer);
					timer = null;
				}
			}
		};
	};
})();

export default watch;
