import { h, Component } from 'preact';
import s from 'components/pages/scripts/scripts.sss';

export default class GoogleSheetsControlPanel extends Component {

	constructor() {
		super();

		this.state = {
			sheetId: null,
			isDisabled: false,
		};
	}

	setDisabled(isDisabled) {
		this.setState({ isDisabled });
	}

	handleChange = (event) => {
		this.setState({ sheetId: event.target.value });
	}

	isValidSheetId() {
		let id = this.state.sheetId;
		return id && /^.+$/.test(this.state.sheetId);
	}

	buildScriptConfig() {
		if (!this.isValidSheetId()) {
			throw new Error('Invalid sheet id');
		}

		const defaultInstructions = this.props.script.instructions;

		const instructions = Object.assign({}, defaultInstructions, {
			args: [...defaultInstructions.args, this.state.sheetId]
		});

		return Object.assign({}, this.props.script, { instructions });
	}

	importSheet = () => {
		this.setDisabled(true);

		const scriptConfig = this.buildScriptConfig();

		this.props.runScript(scriptConfig).then(() => this.setDisabled(false));
	}

	render() {
		return (
			<div>
				<label>
					<span class={s.description}>
						Google sheet id:
					</span>
					<input
						type="text"
						class={s.input}
						value={this.state.sheetId}
						onInput={this.handleChange}
					/>
				</label>
				<button
					class={s.button}
					onClick={this.importSheet}
					disabled={this.state.isDisabled || !this.isValidSheetId()}
				>
					import sheet sheet
				</button>
			</div>
		);
	}
}
