import { h } from 'preact';
import GoogleSheetsControlPanel from 'components/pages/scripts/googleSheetsControlPanel';
import RunSitesAnalytics from 'components/pages/scripts/runSitesAnalytics';
import RunImporter from 'components/pages/scripts/runImporter';
import SalesListForm from 'components/pages/scripts/salesListForm';
import ScreeningsReportForm from 'components/pages/scripts/screeningsReportForm';
import RunButton from 'components/pages/scripts/runButton';
import { replaceWithVNode } from 'utils/vnode';
// import HTML from 'components/core/html/html';

// const useLineBreaks = raw => raw.split('\n').map(str => <div>{str}</div>);

export default [
	// {
	// 	id: 'debug',
	// 	description: 'Debug',
	// 	instructions: {
	// 		command: 'bash',
	// 		args: ['-c', 'for i in {1..5}; do for j in {1..20}; do echo result \\#$i.$j; done; sleep 5; done'],
	// 		opts: { },
	// 	},
	// 	options: {
	// 		// local: true,
	// 	},
	// 	hidden: typeof window === 'undefined' || !__IS_LOCAL__,
	// 	controlPanel: (runScript, script) => (
	// 		<RunButton onClick={() => runScript(script)}>LOOOOOL</RunButton>
	// 	)
	// },
	// {
	// 	id: 'importersSummary',
	// 	description: 'Get importers status',
	// 	instructions: {
	// 		command: 'node',
	// 		args: ['./reporters_v2/import_summary', '--debug'],
	// 		opts: { cwd: '/var/www/showtimes-data-importers' },
	// 	},
	// 	// formatResponse: data => <HTML content={data} markdown />,
	// 	controlPanel: (runScript, script) => (
	// 		<RunButton onClick={() => runScript(script)}>
	// 			Run imports summary script
	// 		</RunButton>
	// 	)
	// },
	{
		id: 'getSitesAnalytics',
		description: 'Get a report of session counts from AWS',
		instructions: {
			command: 'echo',
			args: [],
			opts: {},
		},
		formatResponse: (content, data) => {
			if (!data?.finished) {
				return '';
			}
			let results = [
				'Task launched, the results should appear as a new tab in the selected spreadsheet soon. If they still do not after a while, please contact a developer. '
			];
			try {
				let value = JSON.parse(content);
				let id = value.tasks[0].taskArn.split('/').pop();
				results.push(
					<a href={'https://eu-west-1.console.aws.amazon.com/ecs/v2/clusters/org-prod/tasks/' + id + '/logs?region=eu-west-1'} target="_blank">Task logs</a>
				);
			} catch (e) {}
			return results;
		},
		controlPanel: (runScript, script, props) => <RunSitesAnalytics runScript={runScript} script={script} {...props} />
	},
	{
		id: 'runImporter',
		description: 'Manually run an importer',
		instructions: {
			command: 'echo',
			args: [],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		},
		// formatResponse: data => <HTML content={data} markdown />,
		controlPanel: (runScript, script, props) => <RunImporter runScript={runScript} script={script} {...props} />
	},
	{
		id: 'importFromGoogleSheets',
		description: 'Import data from Google Sheets',
		instructions: {
			command: 'node',
			args: ['./importers/pow/gsheets', '--sheet_id'],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		},
		// formatResponse: useLineBreaks,
		controlPanel: (runScript, script) => <GoogleSheetsControlPanel runScript={runScript} script={script} />,
	},
	{
		id: 'wwwDeepLinks',
		description: 'Run WWM Deep Links Import',
		instructions: {
			command: 'node',
			args: ['./importers/wwm/deeplinks'],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		},
		// formatResponse: useLineBreaks,
		controlPanel: (runScript, script) => (
			<RunButton onClick={() => runScript(script)}>
				Run deep links script
			</RunButton>
		)
	},
	{
		id: 'byExpDeepLinks',
		description: 'Run ByExp Deep Links Import',
		instructions: {
			command: 'node',
			args: ['./importers/byexp/deeplinks'],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		},
		// formatResponse: useLineBreaks,
		controlPanel: (runScript, script) => (
			<RunButton onClick={() => runScript(script)}>
				Run deep links script
			</RunButton>
		)
	},
	{
		id: 'matchSales',
		description: 'Check sales list',
		instructions: {
			command: 'node',
			args: ['./utils/checkSales.js'],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		},
		// formatResponse: useLineBreaks,
		controlPanel: (runScript, script, props) => <SalesListForm runScript={runScript} script={script} {...props} />,
	},
	{
		id: 'screeningsReport',
		description: 'Get screenings report',
		instructions: {
			command: 'node',
			args: ['./utils/showtimesScreeningsReport.js'],
			opts: { cwd: '/var/www/showtimes-data-importers' },
		},
		formatResponse: raw => {
			return replaceWithVNode(raw, /https?:\/\/(\S+)/g, match => {
				return <a href={match[0]} target="_blank">{match[0]}</a>;
			});
		},
		controlPanel: (runScript, script, props) => <ScreeningsReportForm runScript={runScript} script={script} {...props} />,
	},
];
