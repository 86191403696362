export default class Geo {

	static MILES_FACTOR = 0.621371192;
	static kmToMiles(val) {
		return val * Geo.MILES_FACTOR;
	}
	static milesToKm(val) {
		return val / Geo.MILES_FACTOR;
	}

	setGoogleLatLng() {
		if (typeof window !== 'undefined' && window.google && window.google.maps) {
			this.googleLatLng = new window.google.maps.LatLng(this.lat, this.lon);
		}

		return this.googleLatLng;
	}

	toGoogleLatLng() {
		return this.googleLatLng || this.setGoogleLatLng();
	}

	constructor({ lat, lon, countryCode, city, src }) {
		this.lat         = lat;
		this.lon         = lon;
		this.city        = city;
		this.countryCode = (countryCode || '').toLowerCase();
		this.src         = src;

		this.setGoogleLatLng();
	}

	update({ lat, lon }) {
		this.lat = lat;
		this.lon = lon;

		this.setGoogleLatLng();
	}

	toString() {
		return JSON.stringify({
			lat:         this.lat,
			lon:         this.lon,
			city:        this.city,
			countryCode: this.countryCode
		});
	}

	equals(otherGeo) {
		if (!(otherGeo instanceof Geo)) return undefined;
		return otherGeo.lat === this.lat && otherGeo.lon === this.lon;
	}

	quickDistIndicator(otherGeo) {
		let dl = otherGeo.lat - this.lat;
		let dL = otherGeo.lon - this.lon;

		return dl * dl + dL * dL;
	}

	distanceFrom(otherGeo, unit, precision) {
		// if (!(otherGeo instanceof Geo)) {
		// 	return undefined;
		// }
		if (!otherGeo || otherGeo.lat === undefined || otherGeo.lon === undefined) {
			return undefined;
		}

		let R = 6371; // km
		let deg2rad = Math.PI / 180;
		let φ1 = otherGeo.lat * deg2rad;
		let φ2 = this.lat * deg2rad;
		// let Δφ = (this.lat - otherGeo.lat) * deg2rad;
		let Δφ = φ2 - φ1;
		let Δλ = (this.lon - otherGeo.lon) * deg2rad;

		let sinφ = Math.sin(Δφ / 2);
		let sinλ = Math.sin(Δλ / 2);
		let a = sinφ * sinφ + Math.cos(φ1) * Math.cos(φ2) * sinλ * sinλ;
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		let value = R * c;
		if (unit === 'mi') value = Geo.kmToMiles(value);

		if (precision != undefined) {
			let roundTo = Math.pow(10, ~~precision);
			value = Math.round(value * roundTo) / roundTo;
			// .toFixed ?
		}

		return value;
	}

}
