import { h, Component } from 'preact';
import isShallowEqual from 'shallow-equals';
import s from 'components/pages/docs/markdownDoc.sss';
import hljs from 'highlight.js';
import { joinClasses } from 'utils/utils';
import HTML from 'components/core/html/html';
import { getPageUrl, goToPage } from 'components/core/link/link';

let highlight = (code, lang) => lang ? hljs.highlight(code, { language: lang }).value : hljs.highlightAuto(code).value;

import { marked } from 'marked';
const renderer = new marked.Renderer();
let defaultLinkRenderer = renderer.link;
renderer.link = (data) => {
	let { href } = data;
	let extraAttributes = [];
	let docMatch = href.match(/^@(.+):(.+)$/);
	if (docMatch) {
		extraAttributes.push({ name: 'data-link', value: href });
		href = getPageUrl('docs', { group: docMatch[1], doc: docMatch[2] });
	} else if (!href.startsWith('#')) {
		extraAttributes.push({ name: 'target', value: '_blank' });
	}
	let html = defaultLinkRenderer.call(renderer, data);
	if (html && extraAttributes.length) {
		html = html.replace(/^<a /, '<a ' + extraAttributes.map(a => a.name + '="' + a.value + '"').join(' '));
	}
	return html;
};

export default class MarkdownDoc extends Component {

	constructor(props) {
		super(props);
		this.state.loading = true;
	}

	componentDidMount() {
		this.updateContent(this.props.activeDoc);
	}

	shouldComponentUpdate(props, state) {
		if (isShallowEqual(props, this.props) && isShallowEqual(state, this.state)) {
			return false;
		}
		return true;
	}

	componentWillReceiveProps({ activeDoc }) {
		if (activeDoc !== this.props.activeDoc) {
			this.updateContent(activeDoc);
		}
	}

	async updateContent(activeDoc) {
		if (!activeDoc) {
			return this.setState({ loading: false, error: true });
		}
		let file = activeDoc.file;
		if (this.activeDocFile === file) {
			return;
		}
		this.activeDocFile = file;
		this.setState({ loading: true, error: false, content: '' });
		try {
			let res = await fetch('/doc/' + activeDoc.file);
			if (res.status >= 400) {
				throw new Error(res.statusText);
			}
			let content = await res.text();
			if (this.activeDocFile !== file) {
				return;
			}
			this.setState({ content, loading: false });
		} catch(err) {
			if (this.activeDocFile !== file) {
				return;
			}
			console.error('GET DOC FILE ERR', err);
			this.setState({ error: true, loading: false });
		}
	}

	onClick(e) {
		let link = e?.target?.getAttribute('data-link') || '';
		let docMatch = link.match(/^@(.+):(.+)$/);
		if (docMatch) {
			e?.preventDefault();
			goToPage('docs', { group: docMatch[1], doc: docMatch[2] });
			return;
		}
	}

	render(props, state) {
		if (state.error) {
			return (
				<div class={s.error}>
					Error while loading this documentation file. Please contact a dev.
				</div>
			);
		}
		return (
			<HTML
				class={joinClasses(s.container, state.loading && s.loading)}
				content={state.content}
				markdown
				markdownOptions={{ highlight, renderer, gfm: true }}
				onClickCapture={this.onClick}
			/>
		);
	}
}
