import { getVideoData } from 'services/youtubeApi';

export const YoutubeVideo = async (state) => {
	// Check if we have a ytid set
	let id = state.ytidValue;
	if (!id) {
		return Promise.reject('No youtube id defined');
	}
	let data = await getVideoData(id);
	if (!data.items || !data.items.length) {
		throw new Error('Video ' + id + ' not found');
	}
	const item = data.items[0].snippet;
	return {
		nameValue: item.title,
		thumbSrcValue: item.thumbnails.medium?.url || item.thumbnails.default.url,
		publishedAtValue: new Date(item.publishedAt).toISOString().slice(0, 10)
	};
};
