function isError(res) {
	return res.status === 0 || (res.status >= 400 && res.status < 600);
	// ^ no-cors fetch can return status of 0
}

export async function handleApiResponse(res) {
	if (isError(res)) {
		// Do not call res.json(), some things need the error code (e.g. reports page)
		throw res;
	}
	return res.json();
}

export async function handleApiResponseText(res) {
	if (isError(res)) {
		throw res;
	}
	return res.text();
}
