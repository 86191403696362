let API_KEY = '';
if (typeof window !== 'undefined') {
	let localStorageKey = localStorage && localStorage.getItem && localStorage.getItem('__API_KEY__');
	API_KEY = window.__AUTH_USER__.apiKey || localStorageKey;
}

let execScriptEndpoint = 'https://thundr.s-prod.pow.io/proxy/thundr/exec_script';
let localExecScriptEndpoint = '/exec_script';

// NOTE: This needs to wrapped in a function because requires browser env
const buildFetchOptions = (data) => Object.assign({
	method: 'POST',
	headers: new Headers({
		// Authorization: localStorage.getItem('__API_KEY__'),
		'Content-Type': 'application/json',
	}),
	credentials: 'include'
}, data);

export const runScript = (instructions, persistent, options = {}) => {
	let url = execScriptEndpoint;
	if (options.local) {
		url = localExecScriptEndpoint;
	}
	const payload = Object.assign({
		key: API_KEY,
		persistent
	}, instructions);
	const fetchOptions = buildFetchOptions({ body: JSON.stringify(payload) });
	return fetch(url, fetchOptions).then(response => persistent ? response.json() : response.text());
};

export const getScriptResult = (id, options = {}) => {
	let url = execScriptEndpoint;
	if (options.local) {
		url = localExecScriptEndpoint;
	}
	const fetchOptions = buildFetchOptions({
		body: JSON.stringify({ key: API_KEY, result: id })
	});
	return fetch(url, fetchOptions).then(response => response.json());
};

// NOTE: This is used for testing when you don't want to run in the VM
export const runDummyScript = (instructions) => {
	const dummyResponse = JSON.stringify(instructions) + '\n' + Array.from(
		{ length: Math.floor(Math.random() * 100) },
		(e, i) => `This is row ${i} of data`
	).join('\n');

	return new Promise(res => setTimeout(() => res(dummyResponse), 1000));
};
