import 'base-styles/reset.sss';
import 'base-styles/global.sss';
import { h, render } from 'preact';
import { buildClientStoreData } from 'utils/clientUtils';
import ThundrApp from 'base/thundr/thundrApp';
import 'store/update';
import 'utils/historyPushEvent'; // Add events for when you do history.push

try {
	// let url = location.origin + location.pathname.replace(/\/[^/]*$/, '/');
	let url = location.href;
	if (location.protocol === 'about:' && window.parent !== window) {
		// url = window.parent.location.origin + window.parent.location.pathname.replace(/\/[^/]*$/, '/');
		url = window.parent.location.href;
	}

	// eslint-disable-next-line no-undef
	__webpack_public_path__ = new URL(window.__RELATIVE_ROOT__ || '', url).href;
} catch (e) {}

/* Auth
-----------------------------*/
window.AUTH_DOMAIN = 'https://auth.s-prod.pow.io';
window.authIframe = document.createElement('iframe');
Object.assign(window.authIframe.style, {
	position: 'fixed',
	top: 0, left: 0,
	width: '100%', height: '100%',
	zIndex: 1e6,
	visibility: 'hidden'
});
window.addEventListener('message', (e) => {
	if (e.origin !== window.AUTH_DOMAIN) {
		return;
	}
	let data = e.data || { isAuthenticated: false };
	if (!window.user || data.isAuthenticated !== window.user.isAuthenticated) {
		window.user = data;
		window.authIframe.style.visibility = data.isAuthenticated ? 'hidden' : 'visible';
		window.dispatchEvent(new CustomEvent('user-update', { detail: data }));
	}
}, false);
window.authIframe.src = `${window.AUTH_DOMAIN}/ui`;
document.body.append(window.authIframe);

/* Setting up the store
-----------------------------*/
let initialState = window.__INITIAL_STATE__;
if (window.__START_PAGE_ID__ && window.__START_PAGE_ID__ !== '{{__START_PAGE_ID__}}') {
	initialState.activePage = initialState.appData.pages[window.__START_PAGE_ID__];
}
const initialStore = buildClientStoreData(initialState);
// window.Store = Store;

/* Initial render of the app
----------------------------------*/
render(<ThundrApp initialStore={initialStore} />, document.body, document.getElementById('app'));
