import { clamp } from 'utils/math';

let getCSSColor = (() => {
	if (typeof document === 'undefined') {
		return () => null;
	}
	let cache = {};
	return value => {
		if (cache[value]) {
			return cache[value];
		}
		document.head.style.color = '';
		document.head.style.color = value;
		let result = document.head.style.color && getComputedStyle(document.head).color;
		return cache[value] = (result || null);
	};
})();

function pad2(n) {
	return ('0' + n).slice(-2);
}
export default class Color {
	constructor(r, g, b, a = 1) {
		this.r = +r || 0;
		this.g = +g || 0;
		this.b = +b || 0;
		this.a = +a || 0;
		if (arguments.length === 1) {
			if (typeof r === 'number') {
				this.r = this.g = this.b = r || 0;
			} else {
				this.fromString(r + '');
			}
		}
	}

	fromString(str) {
		if (!str) {
			this.r = this.g = this.b = this.a = 0;
			return false;
		}
		if (str === 'transparent') {
			this.r = this.g = this.b = this.a = 0;
			return true;
		}
		str = str + '';
		let match;
		// rgb/rgba
		match = str.match(/^rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:\s*,\s*(\d*(?:\.\d*)?))?/i);
		if (match) {
			this.r = +match[1];
			this.g = +match[2];
			this.b = +match[3];
			this.a = match[4] !== undefined ? +match[4] || 0 : 1;
			return true;
		}
		// Full hex (#abcdef)
		match = str.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})?$/i);
		if (match) {
			this.r = parseInt(match[1], 16);
			this.g = parseInt(match[2], 16);
			this.b = parseInt(match[3], 16);
			this.a = match[4] !== undefined ? parseInt(match[4], 16) / 255 : 1;
			return true;
		}
		// Short hex (#123)
		match = str.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])([0-9a-f])?$/i);
		if (match) {
			this.r = parseInt(match[1].repeat(2), 16);
			this.g = parseInt(match[2].repeat(2), 16);
			this.b = parseInt(match[3].repeat(2), 16);
			this.a = match[4] !== undefined ? parseInt(match[4] + match[4], 16) / 255 : 1;
			return true;
		}

		// Check other CSS colors (named colors, hsl, etc.):
		let css = getCSSColor(str);
		if (!css || css === str) {
			this.r = this.g = this.b = this.a = 0;
			return false;
		}
		return this.fromString(css);
	}

	toHex(alpha = this.a) {
		let values = [this.r, this.g, this.b];
		if (alpha !== 1) {
			values.push(alpha * 255);
		}
		return '#' + values.map(v => pad2(clamp(Math.round(v), 0, 255).toString(16))).join('');
	}
	toRGBString(alpha = this.a) {
		let rgb = [this.r, this.g, this.b].map(v => clamp(Math.round(v), 0, 255)).join(', ');
		alpha = clamp(alpha, 0, 1);
		if (alpha === 1) {
			return 'rgb(' + rgb + ')';
		}
		return 'rgba(' + rgb + ', ' + alpha + ')';
	}
	toString(alpha = this.a) {
		return alpha === 1 ? this.toHex(alpha) : this.toRGBString(alpha);
	}

	// https://gist.github.com/mjackson/5311256
	// h, s, v in [0, 1]
	toHSV() {
		let r = this.r / 255;
		let g = this.g / 255;
		let b = this.b / 255;
		let max = Math.max(r, g, b);
		let min = Math.min(r, g, b);
		let d = max - min;

		let h = 0;
		let s = max ? d / max : 0;
		let v = max;

		if (d) {
			switch (max) {
				case r: h = (g - b) / d + (g < b ? 6 : 0); break;
				case g: h = (b - r) / d + 2; break;
				case b: h = (r - g) / d + 4; break;
			}

			h /= 6;
		}
		return { h, s, v, a: this.a };
	}

	// h, s, v in [0, 1]
	fromHSV(h, s, v, a = 1) {
		let r = 0;
		let g = 0;
		let b = 0;

		let hInt = Math.floor(h *= 6);
		let hFrac = h - hInt;

		let p = v * (1 - s);
		let q = v * (1 - hFrac * s);
		let t = v * (1 - (1 - hFrac) * s);

		switch (hInt % 6) {
			case 0: r = v, g = t, b = p; break;
			case 1: r = q, g = v, b = p; break;
			case 2: r = p, g = v, b = t; break;
			case 3: r = p, g = q, b = v; break;
			case 4: r = t, g = p, b = v; break;
			case 5: r = v, g = p, b = q; break;
		}

		this.r = 255 * r;
		this.g = 255 * g;
		this.b = 255 * b;
		this.a = a;
		return this;
	}
}
