import { h, Component } from 'preact';
import { joinClasses } from 'utils/utils';
import s from 'components/shared/dropdownSearchSelector/dropdownSearchSelector.sss';

// Inspiration: https://semantic-ui.com/modules/dropdown.html

// Example `values` prop
// [
// 	{ value: 'fr', label: 'France', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/fr.png' },
// 	{ value: 'gb', alternate: ['uk', 'england'], label: 'United Kingdom', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/gb.png' },
// 	{ value: 'ru', label: 'Russia', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/ru.png' },
// 	{ value: 'tw', label: 'Taiwan', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/tw.png' },
// 	{ value: 'us', label: 'United States', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/us.png' },
// 	{ value: 'de', label: 'Germany', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/de.png' },
// 	{ value: 'ca', label: 'Canada', icon: 'https://dx35vtwkllhj9.cloudfront.net/images/flags/ca.png' },
// 	{ value: 'lol', label: 'LOL' },
// ]

export default class DropdownSearchSelector extends Component {

	constructor(props) {
		super(props);
		this.suggestionMouseDown = this.suggestionMouseDown.bind(this);
		this.onInput = this.onInput.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.renderElement = this.renderElement.bind(this);
		this.sanitizeValues(props);
		// Current value for the whole element
		this.state.activeValue = null;
		this.state.highlightedValue = null;
		// Current user-typed value
		this.state.typed = '';
		this.state.displayedValues = this.values;

		this.$suggestions = {};
	}

	componentDidMount() {
		if (this.props.value) {
			this.updateValue(this.props.value);
		}
		if (this.props.autofocus) {
			this.$input.focus();
		}
	}

	componentWillReceiveProps(props) {
		if (props.value !== this.props.value) {
			this.updateValue(props.value);
		}
	}

	updateValue(value) {
		this.select(this.values.find(el => el.value === value));
	}

	sanitizeValues(props) {
		props = props || this.props;
		this.values = (props.values || []).filter(e => e);
	}

	highlight(element) {
		if (element !== this.state.highlightedValue) {
			this.setState({ highlightedValue: element });
			if (element) {
				let dom = this.$suggestions[element.value];
				if (dom) {
					dom.scrollIntoView({ block: 'nearest' });
				}
			}
		}
	}

	select(element, e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		this.setState({ highlightedValue: null, activeValue: element });
		this.$input.blur();
		if (typeof this.props.onChange === 'function') {
			this.props.onChange(element);
		}
	}

	matches(element, value) {
		if (typeof element.matches === 'function') {
			return element.matches(value);
		}
		if (!value) {
			return true;
		}
		let lower = value.toLowerCase();
		let isIn = val => val && val.toLowerCase().indexOf(lower) !== -1;
		return isIn(element.label) || isIn(element.value);
	}

	updateSearch(value, base) {
		if (value === this.state.typed && !base) {
			return;
		}
		let values = this.values.filter(element => this.matches(element, value));
		this.setState({ typed: value, displayedValues: values, highlightedValue: values[0] });
		if (base) {
			let index = values.indexOf(this.state.activeValue);
			if (index !== -1) {
				setTimeout(() => this.highlight(values[index]), 50);
			}
		}
	}

	suggestionMouseDown(e) {
		e.preventDefault();
	}

	onInput() {
		this.updateSearch(this.$input.value);
	}

	onKeyDown(e) {
		if (e.code === 'ArrowUp') {
			e.preventDefault();
			let values = this.state.displayedValues;
			if (values.length) {
				let index = values.indexOf(this.state.highlightedValue);
				if (index !== -1) index--;
				if (index < 0) index += values.length;
				this.highlight(values[index]);
			}
		}
		if (e.code === 'ArrowDown') {
			e.preventDefault();
			let values = this.state.displayedValues;
			if (values.length) {
				let index = (values.indexOf(this.state.highlightedValue) + 1) % values.length;
				this.highlight(values[index]);
			}
		}
		if (e.code === 'Enter') {
			e.preventDefault();
			if (this.state.highlightedValue) {
				this.select(this.state.highlightedValue);
			}
		}
		if (e.code === 'Escape') {
			e.preventDefault();
			this.setState({ highlightedValue: null });
			this.$input.blur();
		}
	}

	onFocus() {
		this.updateSearch('', true);
		this.setState({ open: true });
	}

	onBlur() {
		let newState = { typed: '', open: false };
		let highlighted = this.state.highlightedValue;
		if (highlighted && this.state.activeValue !== highlighted) {
			newState.activeValue = highlighted;
			if (typeof this.props.onChange === 'function') {
				this.props.onChange(highlighted);
			}
		}
		this.setState(newState);
	}

	getClass(type) {
		let val = this.props.class;
		let extra = val && val[type];
		if (typeof val === 'string' && type === 'wrapper') {
			extra = val;
		}
		return joinClasses(s[type], extra);
	}

	renderActiveValue() {
		let element = this.state.activeValue;
		let classes = [this.getClass('element'), this.getClass('selected'), this.state.typed && s.hidden];
		if (!element) {
			let placeholder = this.props.placeholder || 'Pick something';
			return (
				<div class={joinClasses(...classes, s.placeholder)}>{placeholder}</div>
			);
		}
		return <div class={joinClasses(...classes)}>{this.renderElementContent(element)}</div>;
	}
	renderElement(element) {
		if (!element) {
			return;
		}
		return (
			<div
				class={joinClasses(this.getClass('element'), element === this.state.highlightedValue && this.getClass('highlighted'))}
				key={element.value}
				onClick={e => this.select(element, e)}
				onMouseDown={this.suggestionMouseDown}
				// onMouseEnter={() => this.highlight(element)}
				ref={e => this.$suggestions[element.value] = e}
			>
				{this.renderElementContent(element)}
			</div>
		);
	}
	renderElementContent(element) {
		if (typeof element.render === 'function') {
			return element.render(element);
		}
		let content = [];
		if (element.icon) {
			content.push(<img src={element.icon} class={this.getClass('icon')} />);
		}
		content.push(<span class={this.getClass('label')}>{element.label || element.value}</span>);
		return content;
	}

	render() {
		let wrapperClass = joinClasses(this.getClass('wrapper'), this.state.open && this.getClass('open'));
		if (!this.values.length) {
			return <div class={wrapperClass} />;
		}
		let suggestions = (
			<div class={joinClasses(this.getClass('element'), this.getClass('noResults'))}>
				{this.props.noResults || 'No results found'}
			</div>
		);
		if (this.state.displayedValues.length) {
			suggestions = this.state.displayedValues.map(this.renderElement);
		}
		return (
			<div class={wrapperClass}>
				<input
					type="text"
					ref={e => this.$input = e}
					class={this.getClass('input')}
					value={this.state.typed}
					onInput={this.onInput}
					onKeyDown={this.onKeyDown}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				/>
				{this.renderActiveValue()}
				<div class={this.getClass('suggestions')}>
					{suggestions}
				</div>
			</div>
		);
	}

}
