import isShallowEqual from 'shallow-equals';

/**
 * Tells if a component should update given its next props and state.
**/
function shouldComponentUpdate(nextProps, nextState) {
	return !isShallowEqual(nextProps, this.props, isSameProp) || !isShallowEqual(nextState, this.state);
}

/**
 * Makes the given component "pure".
**/
function pureRenderDecorator(target) {
	if (target?.kind !== 'class') {
		return target;
	}
	target.finisher = Base => class extends Base { shouldComponentUpdate() { return shouldComponentUpdate.apply(this, arguments); } };
	return target;
}

function isSameProp(a, b) {
	return a === b || (Array.isArray(a) && Array.isArray(b) && !a.length && !b.length);
}

export default pureRenderDecorator;
