import Store from 'store/store';
import * as actions from 'store/actions';
import { initSockets } from 'services/buildSockets';
import { getMergedAppData } from 'utils/appDataUtils';
import defaultsDeep from '@nodeutils/defaults-deep';
import { getSize } from 'utils/clientUtils';
import { accessNested } from 'utils/utils';
import { smoothScrollTo } from 'utils/smoothScroll';
import { validateAppData } from 'services/buildApi';
import { getScreeningCounts, getVisitCounts, getProviderTitles, getPTSuggestions, getMovieMaster, createMovieMasterId } from 'services/dataApi';
import qs from 'qs';
// import cloneDeep from "lodash/cloneDeep";

// const NO_DATA = 'NO_DATA';
/*
 * Listen to event triggers and update the store
**/


/* PAGE INTERACTIONS
--------------------------------*/

/**
 * Update the active page
 * @param	{ String } url - The new url
 * @param	{ Object } page - the page object
 */
Store.on(actions.SET_ACTIVE_PAGE, (page) => {
	let state = Store.get();
	if (typeof window !== 'undefined' && state.activePage.id !== page.id) {
		// Scroll to top ?
		// (will change the scroll if the new page is smaller anyway)
		smoothScrollTo(document.body, 0, 200);
	}
	state.set({
		activePage: page,
		query: qs.parse(location.search.slice(1))
	});
});

/* CLIENT INTERACTIONS
--------------------------------*/

/**
 * Update the Dimensions
 * @param	{ Object } dimensions - The new url
 */
Store.on(actions.SET_CLIENT_SIZE, (dimensions) => {
	const clientStore = Store.get().client;

	let deviceSize = getSize(dimensions);

	clientStore.set({
		dimensions,
		deviceSize
	});
});

Store.on(actions.SET_CLIENT_GEO, (geo) => {
	const clientStore = Store.get().client;
	clientStore.set({ geo });
});


/* WATCHING APPS
--------------------------------*/

/**
 * Update the Dimensions
 * @param	{ Object } json - The new json for the watched app
 */
Store.on(actions.SET_WATCHED_APP, (json) => {
	const store = Store.get();

	store.set({
		watchedApp: json
	});
});


/* Page Load
---------------------*/

let id;
// Save a unique id, this will be used IN THE FUTURE so we can have multiple users on one platform
if (typeof window !== 'undefined') {
	id = crypto.randomUUID();
	let user = window.__AUTH_USER__ || {};
	user.guid = id;
	Store.get().set({ user }).now();
}

Store.on(actions.PAGE_STATE_LOADED, () => {
	if (!id) {
		return;
	}

	let state = Store.get();

	initSockets(id);

	// NOTE: (Anthony) Why do we have tracking on thundr ?
	// initPiwik(state.appData);
	// track({a: state.activePage.id + '-view', ni: true});

	if (typeof addEventListener === 'function') {
		window.onpopstate = history.onpushstate = history.onreplacestate = (e) => {
			if (e.state && e.state.pageId) {
				let state = Store.get();
				const activePage = state.appData.pages[e.state.pageId];
				// URL: (location.pathname || '')+(location.search || '')
				Store.emit(actions.SET_ACTIVE_PAGE, activePage);

				// track({a: activePage.id + '-view'});
			} else {
				// Find active page based on url
				let state = Store.get();
				let pages = state.appData.pages;
				let activePage = Object.keys(pages).find(p => {
					let page = pages[p];
					if (!page || !page.path) return false;
					let fileName = page.baseHtml || 'index.html';
					let possibilities = [page.path + fileName];
					if (fileName === 'index.html') possibilities.push(page.path);
					return possibilities.find(poss => location.pathname.endsWith(poss));
				});
				if (activePage) Store.emit(actions.SET_ACTIVE_PAGE, pages[activePage]);
				// state.set({query: qs.parse(location.search.slice(1))});
			}
		};

		window.addEventListener('resize', () => {
			Store.emit(actions.SET_CLIENT_SIZE, {
				width: window.innerWidth,
				height: window.innerHeight
			});
		}, false);
	}

	state.set({ query: qs.parse(location.search.slice(1)) });

	if (state.activePage.redirectOnLoad) {
		let redirectTo = state.appData.pages[state.activePage.redirectOnLoad];
		if (redirectTo) {
			let path = redirectTo.path;
			if (path && path[0] === '/') {
				path = path.substring(1);
				path = (state.activePage.path.substring(1).match(/\//g) || []).map(() => ('../')).join('') + path;
			}
			history.pushState({ pageId: state.activePage.redirectOnLoad }, '', path);
		}
	}

	if (window.user && window.user.isAuthenticated) {
		updateCounts();
	}
	window.addEventListener('user-update', e => {
		if (e && e.detail && e.detail.isAuthenticated) {
			updateCounts();
		}
	});

	// Update session counts every 5min (same as the cache update on the server side)
	// setInterval(() => Store.emit(actions.UPDATE_SESSION_COUNTS), 5 * 60 * 1000);
});

function updateCounts() {
	getScreeningCounts().then(data => {
		const providers = [];
		const screeningCounts = data.reduce((prev, sc) => {
			let id = [sc.attributes.title_slug, sc.attributes.country].join('|');
			prev[id] = (prev[id] || 0) + parseInt(sc.attributes.count);
			return prev;
		}, {});

		Store.get().set({
			screeningCounts,
			providers
		});
	});

	getVisitCounts().then(data => {
		const visitCounts = data.reduce((prev, vc) => {
			let id = [vc.attributes.title_slug, vc.attributes.region_slug].join('|');
			prev[id] = (prev[id] || 0) + parseInt(vc.attributes.count);
			return prev;
		}, {});

		Store.get().set({
			visitCounts
		});
	});

	// Store.emit(actions.UPDATE_SESSION_COUNTS);
}

// Store.on(actions.UPDATE_SESSION_COUNTS, () => {
// 	getSessionCounts().then(data => {
// 		const activeSessionCounts = data.reduce((prev, sc) => {
// 			let id = [sc.attributes.title_slug, sc.attributes.region_slug].join('|');
// 			prev[id] = (prev[id] || 0) + parseInt(sc.attributes.count);
// 			return prev;
// 		}, {});

// 		Store.get().set({
// 			activeSessionCounts
// 		});
// 	});
// });


// MODAL
// -----
// Show the modal dialog with a custom content
Store.on(actions.SHOW_MODAL, (content, title, type, callback, extraProps) => {
	let state = Store.get();
	if (!state.modal) state = state.set({ modal: {} });
	// TODO find a way to clear previous content maybe ?
	state.modal.set({
		active: true,
		content: content,
		title: title,
		callback: callback,
		type: type,
		extraProps: extraProps
	});
});

// Closes the modal and notify the caller
Store.on(actions.HIDE_MODAL, (result, data) => {
	let modal = Store.get().modal;
	if (!modal) return;

	modal.callback?.(result, data);
	modal.set({ active: false });
});


Store.on(actions.UPDATE_EDITED_APP_DATA, (data, value) => {
	let state = Store.get();
	try {
		state[getStoreProp(data)][data.id].set('edited', value).now();
	} catch (e) {
		console.log('Failed to edit appData', e);
	}
});

const listAttributes = {
	apps: {
		live: 'attributes.meta.live',
		url: 'attributes.meta.url',
		release: 'attributes.meta.dates.release',
		history: (full) => {
			const h = accessNested(full, 'attributes.dev.history');
			if (!h) return null;
			return Object.values(h).map(h => ({ at: h.createdAt, by: h.user }));
		},
	},
	titles: {
		slug: 'attributes.meta.title.slug',
		en: 'attributes.meta.title.en',
	},
	regions: {
		slug: 'attributes.meta.region.slug',
	},
	studios: {
		slug: 'attributes.meta.studio.slug',
		name: 'attributes.meta.studio.name',
	}
};
function generateListData(type, full) {
	return {
		id: full.id,
		rel: full.rel,
		...(Object.fromEntries(Object.entries(listAttributes[type] || {}).map(([key, val]) => [key, typeof val === 'function' ? val(full, key) : accessNested(full, val)])))
	};
}

Store.on(actions.UPDATE_APP_DATA, (data) => {
	let state = Store.get();
	let prop = getStoreProp(data);
	if (!state[prop]) {
		return;
	}
	let current = state[prop][data.id] || {};
	data.rel = data.rel || current.rel || {};
	data.uiExtras = current.uiExtras || {};
	state[prop].set(data.id, data).now();
	state.list[prop].set(data.id, generateListData(prop, data)).now();
});

Store.on(actions.UPDATE_APP_DATA_ATTRIBUTES, (type, id, attributes) => {
	let state = Store.get();
	let prop = getStoreProp({ type }) || type;
	if (!state[prop]) {
		return;
	}
	let current = accessNested(state, [prop, id]) || {};
	current.attributes = attributes;
	state[prop].set(id, attributes).now();
});

Store.on(actions.NEW_TITLE, (title) => {
	Store.get().titles.set(title.id, title);
	Store.get().list.titles.set(title.id, generateListData('titles', title));
});

Store.on(actions.NEW_TITLES, (titles) => {
	titles.forEach(title => Store.emit(actions.NEW_TITLE, title));
});

Store.on(actions.NEW_STUDIO, (studio) => {
	Store.get().studios.set(studio.id, studio);
	Store.get().list.studios.set(studio.id, generateListData('studios', studio));
});

Store.on(actions.NEW_APP, (app) => {
	Store.get().apps.set(app.id, app);
	Store.get().list.apps.set(app.id, generateListData('apps', app));
});
Store.on(actions.REMOVE_APP, (app) => {
	let id = app && typeof app === 'object' ? app.id : app;
	Store.get().apps.remove(id);
	Store.get().list.apps.remove(id);
});

Store.on(actions.UPDATE_PROVIDER_TITLES, async (titleID, noSuggestions) => {
	let store = Store.get();
	let titles = store.titles;
	let tSlug = accessNested(titles[titleID], 'attributes.meta.title.slug');
	if (!tSlug) return;

	let updateUIExtras = (prop, val) => {
		let title = Store.get().titles[titleID];
		let uiExtras = title.uiExtras ? title.uiExtras.toJS() : {};
		uiExtras[prop] = val;
		title.set({ uiExtras });
	};

	try {
		let movieMaster = await getMovieMaster({ limit: 1, 'filter[slug][0]': tSlug });
		if (!movieMaster) {
			movieMaster = await createMovieMasterId({ slug: tSlug });
		}
		updateUIExtras('movieMaster', movieMaster);
	} catch (e) {}

	try {
		const matched = await getProviderTitles({ 'filter[movie_masters][slug][0]': tSlug });
		updateUIExtras('matched', matched);
	} catch (e) {
		updateUIExtras('matched', []);
	}

	if (!noSuggestions) {
		let allTitles = {};
		let titleEnglish = accessNested(titles[titleID], 'attributes.meta.title.en');
		if (titleEnglish) {
			allTitles[titleEnglish] = true;
		}
		Object.values(store.apps).forEach(app => {
			if (app.rel.title !== titleID) return;
			let title = accessNested(app.attributes, 'meta.title.locale');
			if (title) {
				allTitles[title] = true;
			}
		});
		try {
			let titlesList = Object.keys(allTitles);
			let suggestions = titlesList.length ? await getPTSuggestions(titlesList) : [];
			updateUIExtras('suggested', suggestions);
		} catch (e) {
			updateUIExtras('suggested', []);
		}
	}
});

/* Notifications
---------------------*/

Store.on(actions.UPDATE_NOTIFICATION, (notif) => {
	Store.get().set({ notification: notif });
});

function getStoreProp(data) {
	let type = data && data.type;
	if (!type) {
		return;
	}
	return type.replace(/_([a-z])/ig, (m, l) => l.toUpperCase());
}

/* MY APPS
--------------------------------*/
Store.on(actions.SET_ACTIVE_APP_MODE, type => {
	Store.get().set({
		activeAppMode: type
	});
});

Store.on(actions.ADD_MY_APPS, (apps, type, status, titleData) => {
	let state = Store.get();
	let newState = (state.myApps && state.myApps.toJS()) || {};

	// TODO: what happens if you add it when something already exists?
	// starting order should be higher then
	let startOrder = (state.myApps && Object.keys(state.myApps).length) || 0;

	apps.forEach((app, id) => {
		//TODO: lets add in a url
		let myApp = {
			app: app,
			appId: app.meta.id,
			buildType: type,
			createdAt: Date.now(),
			order: startOrder + id,
			status: {}
		};
		if (titleData) {
			myApp.title = titleData;
		}
		if (status[id]) {
			myApp.status = status[id];
		}
		newState[app.meta.id] = myApp;
	});

	if (!state.myApps) {
		state.set({ myApps: newState });
	} else {
		state.myApps.set(newState);
	}
});

Store.on(actions.CLEAR_MY_APPS, () => {
	Store.get().set({ myApps: {} });
});

/**
 * Update my app data
 * @param  {Object} app - The new app (not whole app needed, just what needs updating)
 * @return {Integer} id - id of the app that needs updating
 * @return {Boolean} merge - should it merge or override
 */
Store.on(actions.UPDATE_MY_APP, (app, id, merge = true) => {
	let state = Store.get();
	if (!state.myApps[id]) {
		// ADD_MY_APPS?
		return;
	}

	if (merge) {
		app = defaultsDeep({}, app, state.myApps[id].toJS());
	}

	state.myApps[id].set(app);
});

Store.on(actions.REMOVE_MY_APP, (id) => {
	Store.get().myApps.remove(id + '');
});


/**
 * Validate my apps
 */
Store.on(actions.VALIDATE_MY_APPS, async () => {
	let state = Store.get();
	if (!state.myApps) {
		return;
	}
	let myApps = state.myApps.toJS();

	await Promise.all(Object.keys(myApps).map(async id => {
		const myApp = myApps[id];
		if (!myApp) return null;

		let mergedApp = getMergedAppData(id);

		const errors = await validateAppData({
			appData: mergedApp,
			deploy: true,
			buildType: myApp.buildType,
			titleData: myApp.title,
			// movieIdInfo: state.movieIdInfo
		});

		if (!myApp.status) {
			Store.get().myApps[id].set({ status: {} });
		}
		Store.get().myApps[id].status.set({ error: errors && errors.length ? errors : null });
	}));
});


/**
 * Update status of a my app
 * @param  {Object} status - The new status of the app (not whole app needed, just what needs updating)
 * @return {Integer} id - id of the app that needs updating
 */
Store.on(actions.UPDATE_MY_APP_STATUS, (status, id) => {
	let state = Store.get();
	let myApp = state.myApps[id];
	if (!myApp) return;
	if (myApp.status) {
		myApp.status.set(status);
	} else {
		myApp.set({ status: status });
	}
});

/* USER
--------------------------------*/

Store.on(actions.SET_USER, (user) => {
	Store.get().set({ user: user });
});


/* CONTEXT MENU
--------------------------------*/

Store.on(actions.SHOW_CONTEXT_MENU, (pos, items) => {
	Store.get().contextMenu.set({ shown: true, position: pos, items: items });
});
Store.on(actions.HIDE_CONTEXT_MENU, () => {
	Store.get().contextMenu.set({ shown: false });
});

/* USER LIST
--------------------------------*/

Store.on(actions.SET_USERS, (list) => {
	if (!Array.isArray(list)) return;
	Store.get().set({ users: list });
});
Store.on(actions.UPDATE_USER, (user) => {
	if (!user) return;
	let state = Store.get(), list = state.users.slice();
	let current = list.findIndex(u => u.id === user.id);
	if (current >= 0) list.splice(current, 1, user);
	else list.push(user);
	state.set({ users: list });
});
Store.on(actions.DELETE_USER, (user) => {
	let id = user.id || user;
	if (!id) return;
	let state = Store.get(), list = state.users.slice();
	let current = list.findIndex(u => u.id === id);
	if (current >= 0) {
		list.splice(current, 1);
		state.set({ users: list });
	}
});
