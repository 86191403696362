import { h } from 'preact';

const svgStyle = {
	height: '17px',
};

export default (props) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
			<path d="M13.4019 11.2735L18.6575 5H17.4121L12.8487 10.4472L9.20386 5H5L10.5117 13.2372L5 19.8159H6.24548L11.0646 14.0635L14.9138 19.8159H19.1176L13.4016 11.2735H13.4019ZM11.6961 13.3097L11.1376 12.4895L6.69425 5.9628H8.60724L12.1931 11.2301L12.7515 12.0503L17.4127 18.8969H15.4997L11.6961 13.31V13.3097Z" />
		</svg>
	);
};