import { h, Component } from 'preact';
import { clamp, Easing } from 'utils/math';


export default class ScrollingNumber extends Component {

	constructor(props) {
		super(props);

		const sanitized = this.sanitizeInput(props.value);
		const from = 'from' in props ? this.sanitizeInput(props.from) : sanitized;
		this.goal = sanitized;
		this.state = { value: from };

		this.nextStep = this.nextStep.bind(this);
		this.timer = null;
	}

	componentDidMount() {
		this.start();
	}

	componentWillUpdate(props) {
		if (props.value !== this.props.value) {
			this.goal = this.sanitizeInput(props.value);
			this.start();
		}
	}

	sanitizeInput(value) {
		value = +value;
		return Number.isFinite(value) ? value : 0;
	}

	start() {
		this.started = Date.now();
		this.from = this.state.value;
		if (!this.timer) {
			this.timer = requestAnimationFrame(this.nextStep);
		}
	}

	nextStep() {
		const { duration } = this.props;
		let { easing } = this.props;

		if (typeof easing !== 'function') {
			easing = Easing.quartOut;
		}

		const t = clamp((Date.now() - this.started) / (duration || 250), 0, 1);
		this.setState({ value: this.from + easing(t) * (this.goal - this.from) });

		if (t === 1) {
			this.timer = null;
			return;
		}

		this.timer = requestAnimationFrame(this.nextStep);
	}

	defaultFormat(number) {
		// if (number < 1000) return number;
		// return d3.format('.2s')(number);
		return Math.round(number);
	}

	render({ value, format, duration, easing, from, ...extra }) {
		if (typeof format !== 'function') {
			format = this.defaultFormat;
		}
		const displayed = format(this.state.value);
		return <div {...extra}>{displayed}</div>;
	}

}
