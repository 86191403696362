import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import { joinClasses, accessNested } from 'utils/utils';
import s from 'components/pages/apps/modals/advancedSearchModal.sss';
import Close from '../../../../components/bolt/icons/close';
import Search from '../../../../components/bolt/icons/search';

class FormDataHelper {
	static DATE_OPTIONS = {
		today: 0,
		yesterday: -86400000,
		// tomorrow: 86400000
	};
  
	constructor(formRef, searchFilterOptions, regionFilterOptions) {
		this.$form = formRef;
		this.searchFilterOptions = searchFilterOptions;
		this.regionFilterOptions = regionFilterOptions;
	}
  
	processFormData() {
		const formData = new FormData(this.$form);
		const data = {
			search: formData.get('search'),
			region: formData.get('region'),
			date: this.updateDateField(formData),
			regionfiltertype: this.findFilterOption(this.regionFilterOptions, formData.get('regionfiltertype')),
			searchfiltertype: this.findFilterOption(this.searchFilterOptions, formData.get('searchfiltertype')),
			status: formData.get('status'),
			// page: formData.get('page'),
		};
		return data;
	}
  
	updateDateField(formData) {
		const dateStored = formData.get('date');
		// console.log('updateDateField', 'dateStored', dateStored);

		if (dateStored === 'dateRange') {
			return this.formatDeployDate(formData);
		}
		return this.formatSingleDate(formData);
	}

	formatSingleDate(formData) {
		const dateStored = formData.get('date');
		if (!dateStored) return '';

		const date = dateStored === 'specific' || dateStored === 'release'
			? new Date(formData.get('datespecified'))
			: new Date(Date.now() + (FormDataHelper.DATE_OPTIONS[dateStored] ?? 0));

		return this.formatDate(date);
	}
  
	formatDeployDate(formData) {
		const from = new Date(formData.get('dateRangeFilterStart'));
		const to = new Date(formData.get('dateRangeFilterEnd'));
		return `${this.formatDate(from)}, ${this.formatDate(to)}`;
	}
  
	findFilterOption(options, value) {
		return options?.find(option => option.value === value)?.property;
	}
  
	buildSearchString(data) {
		const searchParts = [
			data.searchfiltertype ? `${data.searchfiltertype}${data.search}` : data.search,
			data.region ? `region:${data.regionfiltertype || ''}${data.region}` : '',
			data.status ? `status:${data.status}` : '',
		];
		return searchParts.filter(Boolean).join(',');
	}
  
	formatDate(date) {
		return date?.toISOString()?.replace(/T.*/, ''); // YYYY-MM-DD

		// return date.toISOString().replace(/T.*/, '').split('-').reverse().join('-'); // DD-MM-YYYY

		// const d = date.toISOString().replace(/T.*/, '');
		// return Math.floor(new Date(d).getTime() / 1000); // Unix timestamp
	}
}

const FILTER_PROPERTIES = {
	ANY: '',
	STARTS_WITH: '^',
	ENDS_WITH: '$',
	EXACT_MATCH: '=',
	EXCLUDE: '!',
	TRUTHY: 'true',
	FALSY: 'false',
};

const SEARCH_FILTER_OPTIONS = [
	{ value: null, label: 'Any', property: FILTER_PROPERTIES.ANY },
	{ value: 'startsWith', label: 'Starts With', property: FILTER_PROPERTIES.STARTS_WITH },
	{ value: 'endsWith', label: 'Ends With', property: FILTER_PROPERTIES.ENDS_WITH },
	{ value: 'exactMatch', label: 'Exact Match', property: FILTER_PROPERTIES.EXACT_MATCH },
];
const REGIONS = [
	'au','at','bg','by','ca','ch','cz','de','dk','ee','es','fr','gb','hr','hu','it','lu','lv','mn','mt','my','ne','nl','no','nz','pl','pt','ru','se','sg','sk','th','tj','ua','us','tw', 'intl'
];
const REGION_FILTER_OPTIONS = [
	{ value: 'include', label: 'Exact Match', property: FILTER_PROPERTIES.EXACT_MATCH },
	{ value: 'exclude', label: 'Exclude', property: FILTER_PROPERTIES.EXCLUDE },
];
const DATE_OPTIONS = [
	{ value: '', label: 'Any Date' },
	{ value: 'release', label: 'Release (Date Specific)' },
	{ value: 'today', label: 'Deployed Today' },
	{ value: 'yesterday', label: 'Deployed Yesterday' },
	{ value: 'specific', label: 'Deployed (Date Specific)' },
	{ value: 'dateRange', label: 'Deployed (Date Range)' },
];
const STATUS_FILTER_OPTIONS = [
	{ value: '', label: 'Any' },
	{ value: 'live', label: 'Live' },
	// { value: '!live', label: 'Not Live' },
	{ value: 'decomissioned', label: 'Decomissioned' },
	{ value: 'unpublished', label: 'Unpublished' },
];
// const PAGE_OPTIONS = [
// 	{ value: '', label: 'Any Page' },
// 	{ value: 'boltHome', label: 'boltHome' },
// 	{ value: 'boltSplashHome', label: 'boltSplashHome' },
// 	{ value: 'boltSplash', label: 'boltSplash' },
// ];

export default class AdvancedSearchModal extends Component {
	constructor() {
		super();

		this.initiateState = {
			isSearchModesOpen: false,
			search: {
				value: '',
				filterType: '' | 'startsWith' | 'endsWith' | 'exactMatch',
				filterProperty: ''
			},
			region: {
				selected: '',
				filterType: 'exact' | 'exclude',
			},
			date: {
				selected: '',
				dateRange: {
					from: '',
					to: '',
				},
				specific: '',
			},
			status: '',
		};
		this.state = this.initiateState;


		this.hide = this.hide.bind(this);
		this.clearSearchField = this.clearSearchField.bind(this);
		this.toggleSearchModeOptions = this.toggleSearchModeOptions.bind(this);
		this.updateSearchValue = this.updateSearchValue.bind(this);
		this.updateRegion = this.updateRegion.bind(this);
		this.onDateFieldFocus = this.onDateFieldFocus.bind(this);
		this.updateDate = this.updateDate.bind(this);
		this.updateStatus = this.updateStatus.bind(this);
		this.updateFilterType = this.updateFilterType.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onSubmitForm = this.onSubmitForm.bind(this);
	}

	componentWillMount() {
		let data = this.props.data;

		console.log('componentWillMount() -- data', data?.[0]);
	}

	componentDidMount() {
		this.$form.reset();
		this.$searchInput.focus();
	}

	componentWillUpdate(props, state) {}

	componentWillUnmount() {
		this.setState(this.initiateState);
	}
	
	updateSearchValue(e) {
		let s = { ...this.state.search, value: e.target.value };
		this.setState({ search: s });
	}

	toggleSearchModeOptions() {
		this.setState({ isSearchModesOpen: !this.state.isSearchModesOpen });
	}
	
	clearSearchField() {
		this.setState({ search: { ...this.state.search, value: '' } });
		this.$searchInput.focus();
	}

	updateFilterType(e, type, options) {
		const newState = {
			search: { 
				filterType: e.target.value, 
				filterProperty: options?.searchProperty 
			},
			region: { 
				filterType: e.target.value 
			}
		};

		this.setState(prevState => ({
			[type]: { 
				...prevState[type], 
				...newState[type] 
			}
		}));
	}

	updateRegion(e) {
		let r = { ...this.state.region, selected: e.target.value };
		this.setState({ region: r });
	}

	onDateFieldFocus(e) {
		e.target.max = new Date().toISOString().split('T')[0];
	}

	updateDate(e, key, extraKey) {
		const formatValue = (value) => value.includes('-') ? value.split('-').reverse().join('-') : value;
		// console.log('updateDate', 'key', key, 'extraKey', extraKey, 'e.target.value', e.target.value);
	
		this.setState(prevState => {
			const updatedDate = { ...prevState.date, selected: key };
	
			switch (key) {
				case 'specific':
				case 'release':
					updatedDate.specific = formatValue(e.target.value);
					updatedDate.dateRange = {};
					break;
				case 'dateRange':
					updatedDate.specific = '';
					updatedDate.dateRange = {
						...prevState.date.dateRange,
						[extraKey]: formatValue(e.target.value)
					};
					break;
				default:
					updatedDate.specific = '';
			}
			return { date: updatedDate };
		});
	}

	updateStatus(e) {
		this.setState({ status: e.target.value });
	}

	canSubmit() {
		const { date } = this.state;
		return !(
			(date.selected === 'specific' && !date.specific)
			|| (date.selected === 'release' && !date.specific)
			|| (date.selected === 'dateRange' && (!date.dateRange.from || !date.dateRange.to))
		);
	}

	onSubmitForm(e) {
		e.preventDefault();

		const helper = new FormDataHelper(
			this.$form,
			SEARCH_FILTER_OPTIONS,
			REGION_FILTER_OPTIONS,
		);
		const rawData = helper.processFormData();

		if (this.state.date.selected === 'release') {
			rawData.search = rawData.search
				? rawData.search.includes('release')
					? rawData.search
					: `${rawData.search},release:${rawData.date}`
				: `release:${rawData.date}`;
			rawData.date = '';
		}

		const searchQuery = helper.buildSearchString(rawData);
		// console.log('onSubmitForm', ' REMOVED ', 'rawData', rawData, 'searchQuery', searchQuery);
		// this.hide();
		// if (!searchQuery) return;
		this.props.onAdvanceSearch(rawData, searchQuery);
	}

	onKeyDown(e) {
		if (e.keyCode === 27) { // ESC
			this.hide();
			return e.preventDefault();
		}
	}

	hide(e) {
		Store.emit(actions.HIDE_MODAL, 'CANCEL');
	}

	render(props, { data, region, date, search, isSearchModesOpen }) {

		const canSearch = this.canSubmit();
		const currentSearchMode = SEARCH_FILTER_OPTIONS.find(mode => mode.value === search.filterType) || SEARCH_FILTER_OPTIONS[0];
		
		return (
			<aside class={s.container}>
				<form class={s.form} onSubmit={this.onSubmitForm} onKeyDown={this.onKeyDown} ref={($ref) => (this.$form = $ref)}>
					{/* SEARCH */}
					<div class={s.searchContainer}>
						<Search />
						<input
							class={s.searchInput}
							type="text"
							name="search"
							id="advancedSearchTitle"
							value={search.value}
							placeholder="Search by Title, Studio, or Region"
							onInput={this.updateSearchValue}
							// autoComplete="off"
							ref={($ref) => (this.$searchInput = $ref)}
						/>
						<button type="button" class={joinClasses(s.clearButton, search.value && s.visible)} onClick={this.clearSearchField} disabled={!search.value}>
							<Close />
						</button>
					</div>
					
					{/* SEARCH OPTIONS */}
					<div class={s.serachModeWrapper} key="searchOptions" onMouseLeave={() => this.setState({ isSearchModesOpen: false })}>
						<button class={s.toggleButton} type="button" onClick={this.toggleSearchModeOptions}>
							<span class={s.arrow} style={{ transform: isSearchModesOpen ? 'rotate(90deg)' : 'none' }}>▶</span>
							Search Mode: <label>{currentSearchMode?.label}</label>
						</button>
						<div class={s.modesContainer} style={{ maxHeight: isSearchModesOpen ? '200px' : '0' }}>
							<div class={s.modesGrid}>
								{SEARCH_FILTER_OPTIONS.map(mode => (
									<div class={joinClasses(s.modeButton, currentSearchMode.value === mode.value && s.active)}>
										<input
											type="radio" name="searchfiltertype" id={`filter${mode.value}`} value={mode.value}
											onInput={e => this.updateFilterType(e, 'search', { searchProperty: mode.property })}
											checked={search.filterType === mode.value}
										/>
										<label for={`filter${mode.value}`}>{mode.label}</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<div class={s.divider} />

					{/* REGION */}
					<div class={s.field}>
						<label class={s.label}>Region</label>
						<div class={s.regionWrapper}>
							<select name="region" class={s.filter} onChange={this.updateRegion}>
								<option disabled selected>Select Region</option>
								<option value="">ANY</option>
								{REGIONS.map(region => <option value={region}>{region.toUpperCase()}</option>)}
							</select>
							{region?.selected && (
								<div class={joinClasses(s.options, s.refineRegion)}>
									<select name="regionfiltertype" class={s.filter} onChange={e => this.updateFilterType(e, 'region')}>
										{/* <option disabled selected>Select A Type</option> */}
										{REGION_FILTER_OPTIONS.map(d => <option value={d.value}>{d.label}</option>)}
									</select>
								</div>
							)}
						</div>
					</div>

					{/* DATE(S) */}
					<div class={s.field}>
						<label class={s.label}>Date</label>
						<select
							name="date"
							class={s.filter}
							onChange={(e) => this.updateDate(e, e.target.value)}
						>
							<option disabled selected>Select A Date</option>
							{DATE_OPTIONS.map(d => <option value={d.value}>{d.label}</option>)}
						</select>
					</div>

					{date.selected === 'dateRange' && (
						<div class={joinClasses(s.field, s.dateRangeWrapper)}>
							<div>
								<label class={s.label}>From: </label>
								<input
									type="date"
									id="date"
									name="dateRangeFilterStart"
									onFocus={this.onDateFieldFocus}
									onInput={e => this.updateDate(e, 'dateRange', 'from')}
								/>
							</div>
							<div>
								<label class={s.label}>To: </label>
								<input
									id="date"
									type="date"
									name="dateRangeFilterEnd"
									onFocus={this.onDateFieldFocus}
									onInput={e => this.updateDate(e, 'dateRange', 'to' )}
								/>
							</div>
						</div>
					)}
					{(date.selected === 'specific' || date.selected === 'release') && (
						<input
							class={s.dateSingular}
							id="date"
							type="date"
							name="datespecified"
							onFocus={date.selected === 'specific' ? this.onDateFieldFocus : null}
							onInput={e => this.updateDate(e, date.selected)}
						/>
					)}

					{/* PAGE */}
					{/* <div class={s.field}>
						<label class={s.label}>Pages</label>
						<select
							name="page"
							class={s.filter}
							// onChange={(e) => this.updateDate(e, e.target.value)}
						>
							<option disabled selected>Select A Page</option>
							{PAGE_OPTIONS.map((d) => (
								<option value={d.value}>{d.label}</option>
							))}
						</select>
					</div> */}

					<div class={s.field}>
						<label class={s.label}>Status</label>
						<div class={s.regionWrapper}>
							<select name="status" class={s.filter} onChange={this.updateStatus}>
								<option disabled selected>Select Status</option>
								{STATUS_FILTER_OPTIONS.map(d => <option value={d.value}>{d.label}</option>)}
							</select>
						</div>
					</div>


					<div class={s.buttons} key="buttons">
						<button class={s.cancel} type="button" value="cancel" onClick={this.hide}>Cancel</button>
						<button class={s.search} type="submit" value="search" disabled={!canSearch}>Search</button>
					</div>
				</form>
			</aside>
		);
	}
}
