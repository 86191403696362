import { h, Component } from 'preact';
import { parseDate } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import s from 'components/shared/table/table.sss';


export default class Table extends Component {

	constructor(props) {
		super();

		this.renderRow = this.renderRow.bind(this);

		const defaultSorting = (props.defaultSorting || []).map(e => e && typeof e === 'string' ? { key: e, dir: 1 } : e).filter(e => e);
		this.state.sorting = defaultSorting.length ? defaultSorting : [
			{ key: props.defaultSort || props.keys[0], dir: props.defaultSortDir || 1 }
		];
	}

	componentWillMount() {
		this.updateSorted(this.props, this.state);
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextProps.data !== this.props.data || nextState.sorting !== this.state.sorting) {
			this.updateSorted(nextProps, nextState);
		}
	}

	updateSorted(props, state) {
		let data = props.data || [];
		if (!Array.isArray(data)) data = [data];
		this.sortedData = data.sort(this.sortValues.bind(this, state.sorting, props.keyTypes));
	}

	sortValues(sorting, keyTypes, a, b) {
		sorting = sorting.slice();

		const currentSorting = sorting.shift();
		if (!currentSorting) return 0;

		const attr = currentSorting.key;
		const dir = currentSorting.dir;
		let attrA = a[attr];
		let attrB = b[attr];

		keyTypes = keyTypes || {};
		const type = keyTypes[attr] || (attrA && typeof attrA || attrB && typeof attrB) || 'string';

		if (type === 'string' || type === 'url') {
			attrA = (attrA + '').toLowerCase();
			attrB = (attrB + '').toLowerCase();
		}

		if (type === 'number') {
			attrA = +attrA;
			attrB = +attrB;
		}

		if (type === 'date' || type === 'datetime') {
			attrA = new Date(attrA);
			attrB = new Date(attrB);
		}

		if (attrA < attrB) return -1 * dir;
		if (attrA > attrB) return 1 * dir;

		return this.sortValues(sorting, keyTypes, a, b);
	}

	setSortBy(key) {
		const sorting = this.state.sorting.slice();
		const first = sorting[0];

		if (first && first.key === key) {
			first.dir = -1 * first.dir;
			this.setState({ sorting: sorting });
			return;
		}

		const current = sorting.find(e => e.key === key);
		if (current) sorting.splice(sorting.indexOf(current), 1);
		sorting.unshift({ key: key, dir: 1 });
		this.setState({ sorting: sorting });
	}

	renderValue(value, type, key) {
		if (!value) return <div class={s.none} />;

		const formats = this.props.formats;
		if (formats && typeof formats[key] === 'function') {
			return formats[key](value, key);
		}

		if (type === 'date') {
			// const date = new Date(value);
			const date = parseDate(value);
			return formatDateTime(date, 'dd/MM/yyyy');
		}

		if (type === 'datetime') {
			const date = new Date(value);
			return formatDateTime(date, 'dd/MM/yyyy hh:mm');
		}

		if (type === 'time') {
			return value.split(':').slice(0,2).join(':');
		}

		if (type === 'url') {
			value = value.replace(/^\/\//, 'http://');
			let text = value.replace(/^https?:\/\//, '');
			if (text === value) value = 'http://' + value;
			let title = '';

			// const firstSlash = text.indexOf('/');
			// if (firstSlash === text.length - 1) {
			// 	text = text.slice(0, -1);
			// } else if (firstSlash !== -1) {
			// 	const maxLength = 30;
			// 	const hl = maxLength >> 1;
			// 	if (text.length > maxLength) {
			// 		text = text.slice(0, hl - 1) + '...' + text.slice(-hl - 1);
			// 		title = value;
			// 	}
			// }

			// Remove the trailing slashes and hash. Also remove query string, is it fine ?
			text = text.replace(/(^([^/]+)\/)?(\?.+)?(#.+)?$/, '$2');

			const maxLength = 25;
			// const hl = maxLength >> 1;
			const hl = 15;
			if (text.length > maxLength) {
				text = text.slice(0, hl - 2) + '...' + text.slice(-(maxLength - hl - 2));
				title = value;
			}
			return <a href={value} target="_blank" title={title} data-display={text}>{value}</a>;
		}

		// if (type === 'number') {
		// 	const formats = this.props.formats;
		// 	if (formats && typeof formats[key] === 'function') {
		// 		return formats[key](value, key);
		// 	}
		// 	return value;
		// }

		return value;
	}

	renderCell(item, key) {
		const keyTypes = this.props.keyTypes;
		const type = keyTypes && keyTypes[key];

		const columnClasses = this.props.columnClasses;
		const extraClass = columnClasses && columnClasses[key];

		const min = this.props.minimal;
		const vwm = this.props.visibleWhenMin;

		const classes = [min && !vwm.includes(key) && s.hidden, type && s['t_' + type], s['v_' + key], extraClass];

		return (
			<td class={classes.filter(e => e).join(' ')}>
				{this.renderValue(item[key], type, key)}
			</td>);
	}

	renderRow(item) {
		if (!item) return;
		return (
			<tr class={s.row}>
				{this.props.keys.map(this.renderCell.bind(this, item))}
			</tr>
		);
	}

	render(props, { sorting }) {
		const {
			keys,
			visibleWhenMin = [],
			minimal,
			columnNames = {},
			columnTitles = {},
			from,
			count
		} = props;

		let data = this.sortedData;
		if (count) {
			const trueFrom = from || 0;
			data = data.slice(trueFrom, trueFrom + count);
		} else {
			if (from) data = data.slice(from);
		}

		const classes = [s.table].concat(this.props.class);
		const activeSort = sorting && sorting[0] && sorting[0].key;

		return (
			<table class={classes.filter(e => e).join(' ')}>
				<thead>
					<tr>
						{keys.map(key => (
							<th
								class={(activeSort === key && s.active) || (minimal && !visibleWhenMin.includes(key) && s.hidden)}
								onClick={this.setSortBy.bind(this, key)}
								title={columnTitles[key]}
							>
								{columnNames[key] || key}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map(this.renderRow)}
				</tbody>
			</table>
		);
	}

}
