import { h } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';
import qs from 'qs';
import { joinClasses, toQueryString, accessNested } from 'utils/utils';
import { getPath } from 'utils/clientUtils';
import { track, trackLinkOut } from 'utils/tracking';
import route from 'components/core/link/route';
import * as svg from 'components/shared/svgIcons/svgIcons.js';
import s from 'components/core/link/link.sss';
import ExitModal from 'components/shared/modal/exitModal';
import { getStaticData } from 'utils/staticData';

const isServer = typeof window === 'undefined';


// Static data from the store
let staticData;

/**
 * Link component to navigate through the site
 * @prop  {String} trackId - tracking id that gets called when clicked
 * @prop  {String} pageId - the id for the page it needs to navigate to
 * @prop  {String} href - href for component
 * @prop  {Object} activePage - whats the current active page, not required!
 * @prop  {String} iconStyle - styling that gets applied to icon
 * @prop  {String} iconClass - class that gets applied to icon
 * @prop  {String} linkClass - class that gets added to link
 * @prop  {Boolean} noMergeQS - should the query parameters not be merged
 * @prop  {Boolean} download - true to force download rather than link out
 * @return {Object}  link dom element
 */
export const Link = (fullProps) => {
	let {
		trackId,
		trackLabel,
		ariaLabel,
		children,
		pageId,
		href,
		activePage,
		pages,
		iconStyle,
		iconClass,
		linkClass,
		noMergeQS,
		appData,
		queryString,
		childId,
		removeSourceQS,
		extraOnClick, // Function called on link click, in addition to the standard handlers
		renderCustomChild, // Enable when you want to ignore the span element and render your own children
		exitModal,    // Use the exit modal - only useful if Features > exitModal is off in the appData
		ignoreExitModal, // Ignore the exit modal for outbound links
		childHTML,
		HTMLTag = 'a',
		scrollSection, // ID of section to scroll to
		style,
		ignoreConfirmationPrompt,
		...props
	} = fullProps;

	if (!staticData) {
		// Get the static data if it hasn't been loaded in yet
		// So we can use it in the click events etc...
		staticData = getStaticData();
	}

	if (pageId) {
		// page id is provided, so its an "internal" link
		let store;
		let getStore = () => store || (store = Store.get());
		if (!activePage) {
			// No activePage providedn
			activePage = getStore().activePage;
		}

		if (!href) {
			let pagePath = getPath(pageId);
			if (pagePath) {
				// See if the page provided actually exists
				href = getRelativeRoot(getPath(activePage)) + pagePath.slice(1);
			} else {
				// No luck in finding it
				href = '.';
			}
			if (scrollSection) {
				href += '#' + scrollSection;
			}
		} else if (href[0] === '/') {
			// Already provided with an href
			href = getRelativeRoot(getPath(activePage)) + href.substring(1);
		}
	}


	if (queryString) {
		if (!href) {
			href = typeof window === 'undefined' ? '' : location.href;
		}

		// Keep the current page query string if internal link and not specified otherwise
		let newQS = isServer || !pageId || noMergeQS ? {} : qs.parse(location.search.substr(1));
		if (!removeSourceQS) {
			let baseQS = href.match(/\?([^#]*)(#|$)/);
			newQS = Object.assign(newQS, qs.parse((baseQS && baseQS[1]) || ''));
		}
		let removeV = true;
		if (queryString) {
			let queryStringObj = typeof queryString === 'string' ? qs.parse(queryString) : queryString;
			if (queryStringObj.v) {
				removeV = false;
			}
			newQS = Object.assign(newQS, queryStringObj);
		}
		// removes youtube ID from URL when navigating away from the specific video page
		if (removeV && newQS.v) {
			delete newQS.v;
		}
		let newQSString = toQueryString(newQS);
		href = href.replace(/(\?([^#]*))?(#|$)/, newQSString + '$3');
	}

	let onClick;
	// Only redirect through page if it doesn't have a target blank
	if (pageId && fullProps.target !== '_blank') {
		onClick = generateOnClick(
			handlePageLinkClick.bind(this, { pageId, queryString, noMergeQS, trackId, trackLabel, ignoreConfirmationPrompt }),
			extraOnClick
		);
	} else {
		onClick = generateOnClick(
			trackLinkOut.bind(this, href, trackId, trackLabel),
			extraOnClick,
			clickOut.bind(this, href, exitModal, !!ignoreExitModal)
		);
	}

	const IconComponent = svg[iconClass];
	const anchorLink = (iconClass || iconStyle) ? s.iconLink : s.link;

	if (props.class) {
		linkClass = props.class;
		delete props.class;
	}

	let inside = children;
	if (!renderCustomChild) {
		inside = [];
		if (iconStyle) {
			inside.push(
				<div style={iconStyle} class={s.iconLinkUrl} />
			);
		}
		if (IconComponent) {
			inside.push(
				<IconComponent class={s.iconLinkSvg} />
			);
		}
		const extraSpanProps = {};
		if (childHTML) {
			extraSpanProps.dangerouslySetInnerHTML = { __html: childHTML };
		}
		inside.push(
			<span class={anchorLink} id={childId} {...extraSpanProps}>
				{children}
			</span>
		);
	}

	return (
		<HTMLTag
			class={joinClasses(s.top, linkClass)}
			href={href}
			onClick={onClick}
			aria-label={ariaLabel || ''}
			style={style || ''}
			{...props}
		>
			{inside}
		</HTMLTag>
	);
};

function generateOnClick() {
	let fList = Array.prototype.concat.apply([], arguments);
	fList = fList.filter(f => typeof f === 'function');
	if (!fList.length) return;
	// Do not put an arrow function here so that we can use this and arguments
	return function() {
		fList.forEach(f => {
			// try-catch to prevent pixel errors breaking everything
			try {
				f.apply(this, arguments);
			} catch(e) {
				console.error(e);
			}
		});
	};
}

function clickOut(href, useExitModal, ignoreExitModal, ev) {
	if ((useExitModal || staticData.options.exitModal) && !ignoreExitModal) {
		// Display the exit modal before allowing the user to leave the site
		ev.preventDefault();
		// NOTE: This may be annoying if you're looking at a screening far down the list and it brings you back up
		// We should find other ways to solve the issues caused by not having that
		// window.scrollTo(0, 0);
		Store.emit(actions.SHOW_MODAL, <ExitModal href={href} vanity={staticData.meta.url} />, null, { type: 'themed' });
	}
	Store.emit(actions.LINK_CLICK_OUT, ev);
}

export function getRelativeRoot(path) {
	return (path.substring(1).match(/\//g) || []).map(() => ('../')).join('');
}

function getAbsoluteUrl(relativeUrl) {
	let a = document.createElement('a');
	a.href = relativeUrl;
	return a.href;
}

// Get the relative url for a page.
// page can be a string (page id) or a page appData object
export function getRelativePageUrl(page) {
	if (!page) return '.';

	let href = '.';
	let pagePath = getPath(page);
	if (pagePath) {
		let activePage = Store.get().activePage;
		href = getRelativeRoot(getPath(activePage)) + pagePath.slice(1);
	}

	return href;
}

export function getAbsolutePageUrl(page) {
	return getAbsoluteUrl(getRelativePageUrl(page));
}

export function getPageUrl(pageId, queryString, noMergeQS, hash) {
	if (!pageId) return;
	let page = accessNested(Store.get(), ['appData', 'pages', pageId]);
	if (!page) {
		return;
	}
	let href = getRelativePageUrl(page);

	// Keep the current page query string if internal link and not specified otherwise
	let newQS = isServer || noMergeQS ? {} : qs.parse(location.search.slice(1));
	if (queryString) {
		newQS = Object.assign(newQS, typeof queryString === 'string' ? qs.parse(queryString) : queryString);
	}
	let newQSString = toQueryString(newQS);
	href = href + newQSString;
	if (hash && typeof hash === 'string') {
		href += (hash[0] === '#' ? '' : '#') + hash;
	}

	return href;
}
export function goToPage(pageId, queryString, noMergeQS, hash) {
	let href = getPageUrl(pageId, queryString, noMergeQS, hash);
	if (!href || getAbsoluteUrl(href) === location.href) {
		return false;
	}
	route(href, pageId);
	return true;
}
if (typeof window !== 'undefined') {
	window.goToPage = goToPage;
	// window.getPageUrl = getPageUrl;
}

function handlePageLinkClick({ pageId, queryString, noMergeQS, trackId, trackLabel, ignoreConfirmationPrompt }, event) {
	if (event.stopImmediatePropagation) event.stopImmediatePropagation();
	event.stopPropagation();
	event.preventDefault();

	if (typeof window.beforePageChange === 'function' && !ignoreConfirmationPrompt) {
		let confirmation = window.beforePageChange();
		// eslint-disable-next-line no-alert
		if (confirmation && typeof confirmation === 'string' && !confirm(confirmation)) {
			return;
		}
	}

	// let href = event.currentTarget.getAttribute('href');
	let href = getPageUrl(pageId, queryString, noMergeQS);
	route(href, pageId);
	let page = accessNested(Store.get(), ['appData', 'pages', pageId]);
	if (page) {
		// removing this as getting duplicate calls with onUrlChange in store/update, can find another solution if this causes problems
		// Store.emit(actions.SET_ACTIVE_PAGE, page);
	}

	trackId = trackId || 'clicked-' + pageId;
	let trackData = { a: trackId };
	if (trackLabel) trackData.l = trackLabel;
	track(trackData);

	Store.emit(actions.LINK_CLICKED, { trackId, pageId, link: href });
	return false;
}
