import { h, Component } from 'preact';
import { accessNested, sortObjectByOrder, joinClasses } from 'utils/utils';
import { Link } from 'components/core/link/link.js';
import ScrollBox from 'components/shared/scrollBox/scrollBox';

import s from 'components/shared/sidebar/sidebar.sss';

export default class Sidebar extends Component {

	constructor(props) {
		super(props);

		this.logout = this.logout.bind(this);
		this.renderedNav = this.renderNav(props);
	}

	componentWillReceiveProps(props) {
		if (props.activePage?.id !== this.props.activePage?.id) {
			this.renderedNav = this.renderNav(props);
		}
	}

	async logout(e) {
		e.preventDefault();
		try {
			window.authIframe?.contentWindow.postMessage('logout', '*');
		} catch (err) {

		} finally {
			this.setState({ loggingOut: false });
		}
	}

	renderNav(props) {
		const activePage = props.activePage;
		const { header, pages, copy } = props.appData;
		const nav = header.nav;
		return sortObjectByOrder(nav, navItem => {
			const pageId = navItem && navItem.id;
			const page = pages[pageId];
			if (!page) {
				return;
			}
			const subPageOf = accessNested(activePage, 'data.subPageOf');
			const isActive = (pageId === activePage.id || pageId === subPageOf);
			let copyValue = page.$copy;
			return (
				<Link pageId={pageId} linkClass={joinClasses(s.item, isActive && s.active)} key={navItem.id}>
					{copy[copyValue] || copyValue || pageId}
				</Link>
			);
		});
	}

	render() {
		return (
			<div class={s.sidebar} inert={this.props.inert}>
				<ScrollBox direction="Y" class={s.nav} scrollableTopClass={s.scrollTop} scrollableBottomClass={s.scrollBottom} useSimpleEventListeners device="mobile" hideScrollerBar>
					{this.renderedNav}
				</ScrollBox>
				<a class={joinClasses(s.logout, this.state.loggingOut && s.pending)} href={`${window.AUTH_DOMAIN}/logout`} onClick={this.logout}>logout</a>
			</div>
		);
	}

}
