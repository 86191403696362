import { h, Component } from 'preact';
import pure from 'utils/pure';
import { marked } from 'marked';

const getNewTabRenderer = (() => {
	let newTabRenderer;
	return () => {
		if (newTabRenderer) {
			return newTabRenderer;
		}
		newTabRenderer = new marked.Renderer();
		let defaultLinkRenderer = newTabRenderer.link;
		newTabRenderer.link = (...args) => {
			let html = defaultLinkRenderer.call(newTabRenderer, ...args);
			if (html) {
				html = html.replace(/^<a /, '<a target="_blank" ');
			}
			return html;
		};
		return newTabRenderer;
	};
})();

export default @pure class HTML extends Component {

	render(props) {
		let {
			tag: HTMLTag = 'div',
			content,
			markdown,
			markdownOptions,
			inline,
			children,
			// removeComments,
			forceNewTabLinks,
			activePage,
			...extraProps
		} = props;

		content = (content || children[0] || '') + '';
		// if (removeComments) {
		// 	// This regex will look for any single comments starting with '//'
		// 	// and replace all characters up to the end of that line with an empty string.
		// 	// It will also search for multiline comments (wrapped with /* */) and remove them as well
		// 	// Bit risky in case we want to actually use /* or // in a copy. No way to escape it
		// 	content = content.replace(/(\/\*([\s\S]*?)\*\/)|(^\/\/(.*)$)/gm, '');
		// }

		if (markdown) {
			let options = { ...markdownOptions };
			if (forceNewTabLinks) {
				options.renderer = getNewTabRenderer();
			}
			content = (inline ? marked.parseInline : marked)(content, options);
		}

		return <HTMLTag {...extraProps} dangerouslySetInnerHTML={{ __html: content }} />;
	}

}
