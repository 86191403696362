import { h, Component } from 'preact';
import * as jsonpatch from 'fast-json-patch';
import Store from 'store/store.js';
import * as actions from 'store/actions.js';
import { joinClasses } from 'utils/utils';
import { getChanges, updateAppData } from 'services/dataApi';

import ScrollBox from 'components/shared/scrollBox/scrollBox';
import Loading from 'components/shared/misc/loading';
import CodeEditor from 'components/shared/editor/codeEditor/codeEditor';
import DiffList from 'components/pages/apps/diffList';

import formatDateTime from 'utils/formatDateTime';
import dateDistance from 'utils/dateDistance.mjs';

import s from 'components/shared/appHistory/appHistory.sss';

const isDeploy = diff => diff?.op === 'add' && (diff.path === '/dev/history' || diff.path.startsWith('/dev/history/'));

const getPaths = (prefix, value) => {
	if (!value || typeof value !== 'object') {
		return [prefix];
	}
	return Object.entries(value).flatMap(([key, value]) => getPaths(`${prefix}/${key.replace(/[~/]/g, e => '~' + (e === '~' ? 0 : 1))}`, value));
};

export default class AppHistory extends Component {

	constructor(props) {
		super(props);
		this.refresh = this.refresh.bind(this);
		this.changeMode = this.changeMode.bind(this);
		this.onFilterInput = this.onFilterInput.bind(this);
		this.onDotKeyDown = this.onDotKeyDown.bind(this);
		this.restoreCurrent = this.restoreCurrent.bind(this);

		this.state = {
			loading: true,
			newDataAvailable: false,
			data: [],
			mode: 'list', // full | list
		};
		this.loadId = 0;
		this.displayedChangesCache = null;
	}

	async componentDidMount() {
		await this.refresh();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.$scroller && prevState.data !== this.state.data) {
			this.$scroller.scroll = 9999999;
		}
		if (this.props.data?.id !== prevProps.data?.id) {
			this.refresh();
		} else if (this.props.data !== prevProps.data) {
			this.setState({ newDataAvailable: true });
		}
	}

	diffItemMatchesFilter(filter, diffItem) {
		const paths = diffItem.op === 'add' ? getPaths(diffItem.path, diffItem.value) : [diffItem.path];
		return paths.some(path => path?.includes(filter));
	}

	_getDisplayedChanges(filter) {
		if (!filter) {
			return this.state.data;
		}
		return this.state.data.filter(change => change.author === filter || change.diff.list?.some(d => this.diffItemMatchesFilter(filter, d)));
	}

	getDisplayedChanges() {
		const filter = this.state.filter;
		if (!this.displayedChangesCache || this.displayedChangesCache.filter !== filter || this.displayedChangesCache.version !== this.state.dataVersion) {
			this.displayedChangesCache = { filter, version: this.dataVersion, list: this._getDisplayedChanges(filter) };
		}
		return this.displayedChangesCache.list;
	}

	async refresh() {
		let loadId = ++this.loadId;
		let previouslySelectedChange = this.state.selectedChange;
		this.setState({ loading: true, error: null, invalidReconstruction: false, selectedChange: null, newDataAvailable: false });
		let data;
		try {
			const changes = await getChanges(this.props.activeType, this.props.data?.id);
			if (loadId !== this.loadId) return;
			data = changes.map(raw => ({
				id: raw.id,
				at: new Date(raw.applied_at),
				author: raw.author,
				diff: raw.diff,
				// size: Math.max(1, raw.diff?.list?.reduce((sum, c) => sum + getPaths('/', c.value).length, 0)),
				isDeploy: isDeploy(raw.diff?.list?.[0])
			}));
		} catch (e) {
			console.log(e);
			if (loadId !== this.loadId) return;
			let error = e.message || e.status;
			this.setState({ loading: false, error });
			return;
		}
		let reconstructed = {};
		try {
			reconstructed = this.buildFromChanges(data);
			let diffsWithCurrent = jsonpatch.compare(reconstructed, this.props.data.attributes);
			if (diffsWithCurrent.length) {
				throw diffsWithCurrent;
			}
		} catch (e) {
			console.log('Invalid reconstruction:', e);
			this.setState({ invalidReconstruction: true });
		}
		const newSelected = data && previouslySelectedChange && data.find(e => e.id === previouslySelectedChange.id);
		this.setState({ loading: false, data, dataVersion: loadId, selectedChange: newSelected, newDataAvailable: false });
	}

	buildFromChanges(changes, base) {
		let result = jsonpatch.applyPatch(
			base || {},
			jsonpatch.deepClone(changes.flatMap(change => change.diff.list)),
			false,
			!base // if a base is given, do not mutate it (clone it instead)
		).newDocument;
		// id automatically added by the back-end can cause differences cause often not set explicitely in the actual data
		if (this.props.activeType === 'apps') {
			result.meta = result.meta || {};
			result.meta.id = this.props.data.id;
		}
		return result;
	}

	selectChange(change) {
		let stateBeforeChange = {};
		let stateAtChange = {};
		let index = this.state.data.indexOf(change);
		if (index === -1) {
			return;
		}
		try {
			stateBeforeChange = this.buildFromChanges(this.state.data.slice(0, index));
			stateAtChange = this.buildFromChanges([this.state.data[index]], stateBeforeChange);
		} catch (e) {
			console.log('Error reconstructing state', e);
		}
		let changesWithCurrent = jsonpatch.compare(this.props.data.attributes, stateAtChange).filter(e => !e.path.startsWith('/dev/history'));
		this.setState({ selectedChange: change, stateBeforeChange, stateAtChange, changesWithCurrent });
		document.querySelector('[data-id="' + change.id + '"]')?.focus();
	}

	changeMode(e) {
		this.setState({ mode: e.currentTarget.value });
	}

	onFilterInput(e) {
		this.setState({ filter: e.currentTarget.value });
	}

	onDotKeyDown(e) {
		let change = this.state.selectedChange;
		let list = this.getDisplayedChanges();
		let index = list?.indexOf(change);
		if (!change || index === -1) {
			return;
		}
		let other;
		switch (e.key) {
			case 'ArrowRight':
				other = list[index + 1];
				break;
			case 'ArrowLeft':
				other = list[index - 1];
				break;
			default: return;
		}
		e.preventDefault();
		if (other) {
			this.selectChange(other);
		}
	}

	async restoreCurrent() {
		// eslint-disable-next-line no-alert
		if (!confirm('You will update the data to make it similar to the currently selected version. Any unsaved changes will be lost.\nAre you sure?')) {
			return;
		}
		this.setState({ updatingAppData: true });
		try {
			// Ensure history is not overriden
			let newAttributes = jsonpatch.deepClone(this.state.stateAtChange);
			let baseHistory = this.props.data.attributes?.dev?.history;
			if (baseHistory) {
				newAttributes.dev = newAttributes.dev || {};
				newAttributes.dev.history = baseHistory;
			}
			let data = await updateAppData(this.props.data.id, this.props.data.type, { attributes: newAttributes }, this.props.data);
			Store.emit(actions.UPDATE_APP_DATA, data);
			// Wait for the store change to propagate, so that we don't set newDataAvailable after the refresh
			await new Promise(r => setTimeout(r, 0));
			await this.refresh();
			this.selectChange(this.state.data[this.state.data.length - 1]);
		} catch (e) {
			console.log('Error when restoring state', e);
		}
		this.setState({ updatingAppData: false });
	}

	renderDiffList(change) {
		return <DiffList class={s.diffList} changes={change.diff.list} key="list" />;
	}

	renderDiffFull(change) {
		// let content = JSON.stringify(this.state.stateAtChange, null, 4);
		// if (content.length < 1_000_000) {
		// 	content = prettyPrintJson.toHtml(this.state.stateAtChange, { indent: 4, linkUrls: false });
		// }
		// return <HTML class={s.diffState} content={content} key="full" />;
		return (
			<CodeEditor
				class={s.diffFull}
				value={this.state.stateAtChange}
				diffWith={this.state.stateBeforeChange}
				readOnly
				dark
				ref={e => this.$editor = e}
				key="full"
			/>
		);
	}

	renderModeCheckbox(mode, name) {
		let checked = this.state.mode === mode;
		return (
			<label class={joinClasses(checked && s.checked)}>
				<input type="radio" name="mode" value={mode} checked={checked} onChange={this.changeMode} />
				{name}
			</label>
		);
	}

	renderDetails() {
		let state = this.state;
		let change = state.selectedChange;
		if (!change) {
			return;
		}
		let restoreDisabled = false;
		let restoreHint = 'As seen in full mode\nEquivalent to reverting all subsequent changes';
		if (state.updatingAppData) {
			restoreDisabled = true;
			restoreHint = 'Updating...';
		} else if (!state.changesWithCurrent?.length) {
			restoreDisabled = true;
			restoreHint = 'No changes with the current version';
		}
		return (
			<div class={s.details} key="details">
				<div class={s.title}>
					<time class={s.at} datetime={change.at.toISOString()} title={formatDateTime(change.at, 'd MMM yyyy, HH:mm:ss')}>{dateDistance(change.at)}</time>
					<span class={s.separator}>-</span>
					<span class={s.by}>by {change.author}</span>
				</div>
				<div class={s.actions}>
					<div class={s.mode}>
						{this.renderModeCheckbox('full', 'Full')}
						{this.renderModeCheckbox('list', 'Changes')}
					</div>
					<button
						class={joinClasses(s.restore, state.invalidReconstruction && s.warning)}
						disabled={restoreDisabled}
						onClick={this.restoreCurrent}
						title={restoreHint}
					>
						Restore this version
					</button>
				</div>
				<div class={joinClasses(s.display, s['mode-' + this.state.mode])}>
					{this.renderDiffList(change)}
					{this.renderDiffFull(change)}
				</div>
			</div>
		);
	}

	render(props, state) {
		if (state.error) {
			return <div class={s.error}>Sorry, an error occured while loading the history: {state.error}</div>;
		}
		if (state.loading && !state.data?.length) {
			return <Loading class={s.loading} />;
		}
		if (!state.data.length) {
			return <div class={s.error}>No changes were recorded for this</div>;
		}
		let warning;
		if (state.invalidReconstruction) {
			warning = (
				<div class={s.warningMessage} key="warning">⚠ The current state of the data could not be reconstructed from the changes. This might be because of some unregistered changes and could cause issues when restoring previous states.</div>
			);
		}
		let newData;
		if (state.newDataAvailable) {
			newData = (
				<div class={s.newDataMessage} key="new">ℹ Changes detected, click <button type="button" onClick={this.refresh}>here</button> to load</div>
			);
		}
		let previousMonth = undefined;
		let displayed = this.getDisplayedChanges();
		return (
			<div class={s.container}>
				{warning}
				<div class={s.timeline} key="timeline">
					<ScrollBox class={s.scroller} slop={8} ref={e => (this.$scroller = e)}>
						<div class={s.dots}>
							{displayed.flatMap(change => {
								let month = change.at.toISOString().slice(0, 7);
								let monthChanged = month !== previousMonth;
								previousMonth = month;
								return [
									monthChanged && <div class={s.monthSeparator} data-date={formatDateTime(change.at, 'MMM yyyy')} key={'month-' + month} />,
									<button
										class={joinClasses(s.dot, change === state.selectedChange && s.selected, change.isDeploy && s.deploy)}
										onClick={() => this.selectChange(change)}
										onKeyDown={this.onDotKeyDown}
										data-id={change.id}
										key={change.id}
									>
										<div class={s.indicator} style={`--size: ${+change.size || 1}`} data-size={+change.size || 1} />
										<div class={s.tooltip}>
											{change.author}, {formatDateTime(change.at, 'ddd d MMM yyyy "at" HH:mm')}
										</div>
									</button>
								];
							})}
						</div>
					</ScrollBox>
					{newData}
				</div>
				<input type="text" class={s.filter} onInput={this.onFilterInput} placeholder="Filter changes (try a partial path, e.g. boltShowtimes/alternatePaths)" key="filter" />
				{this.renderDetails()}
			</div>
		);
	}

}
