import { h, Component } from 'preact';
import { joinClasses } from 'utils/utils';
import HTML from 'components/core/html/html';
import FormRemoveButton from 'components/shared/editor/formEditor/formRemoveButton';
import s from 'components/shared/editor/formEditor/formEditor.sss';

import { showHiddenChars } from 'components/shared/editor/formEditor/attributes/utils';

export default class DisplayAttribute extends Component {

	constructor(props) {
		super(props);

		this.onSave = this.onSave.bind(this);

		this.state.value = props.value;
		this.validate(props);

		// Options for the component
		this.state.disableRemove = false;
		this.state.extra = null;
		this.state.showExtraContent = true;
		this.inputFullWidth = false;
	}

	componentWillReceiveProps(nextProps) {
		const { value } = nextProps;
		if (value !== this.props.value) {
			this.validate(nextProps);
			this.setState({ value });
		}
	}

	validate(props) {
		const schema = props.jsonSchema;
		let required = schema?.required;
		if (Array.isArray(required)) {
			required = required.includes(this.props.activeType);
		}

		if (required === true && !props.value) {
			props.setErrors({
				error: true,
				path: props.attrKeyPath
			});
			this.setState({ error: 'This item is required' });
			return;
		}

		if (schema && schema.pattern) {
			const re = new RegExp(schema.pattern);
			if (!re.test(props.value)) {
				props.setErrors({
					error: true,
					path: props.attrKeyPath
				});
				this.setState({ error: schema.feedback || 'The data seems incorrect' });
				return;
			}
		}

		if (this.state.error) {
			props.setErrors({
				error: false,
				path: props.attrKeyPath
			});
			this.setState({ error: null });
		}
	}

	onSave(e, value) {
		value = value || this.state.value;
		if (value !== this.props.value || value === null) {
			this.props.updateJSON(this.props.attrKeyPath, value);
		}
	}

	getInput() {
		return;
	}

	getExtraContent(value, description, jsonSchema) {
		if (this.props.jsonSchema?.help) {
			return <HTML content={jsonSchema?.help} markdown />;
		}
	}

	toggleExtraContent = () => {
		this.setState({
			showExtraContent: !this.state.showExtraContent
		});
	}

	render(props) {
		const {
			id,
			value,
			attrKeyPath,
			attributeName,
			updateJSON,
			jsonPaths,
			jsonSchema,
			activeType,
			parentType,
			dynamicSchemaRef
		} = props;

		const title = (jsonSchema && jsonSchema.title) || attributeName;
		const description = (jsonSchema && jsonSchema.description) || '';
		let activeLevel = false;

		let titleTitle = undefined;
		if (title !== attributeName) titleTitle = attributeName;

		const input = this.getInput(value, description, jsonSchema);

		const activeTypes = [activeType];
		if (activeType === 'apps') activeTypes.push('branches');

		const paths = jsonPaths[attrKeyPath] || [];
		if (activeTypes.find(type => paths.indexOf(type) !== -1)) {
			activeLevel = true;
		}

		let isBranch = paths.indexOf('branches') !== -1;
		let containerClass = joinClasses(s.displayContainerLight, activeLevel && s.activeLevel, isBranch && s.branch, this.state.error && s.errorContainer);

		let isStyling = attrKeyPath.includes('styling.');
		// TODO: option to always show variable names
		let displayVariables = isStyling;

		let displayedTitle = title;
		let displayedAttributeName = showHiddenChars(attributeName);

		if (title === attributeName) {
			displayedTitle = displayedAttributeName;
		}
		if (isStyling) {
			displayedAttributeName = '$' + displayedAttributeName;
		}

		let inputFullWidth = this.inputFullWidth;
		let extraContent = this.getExtraContent(value, description, jsonSchema);

		const shouldExpandMovieID = jsonSchema && jsonSchema.expandMovieID;

		const hideExtraContentCopy = shouldExpandMovieID ? 'Hide Info' : 'Hide Help';
		const showExtraContentCopy = shouldExpandMovieID ? 'Show Info' : 'Show Help';

		return (
			<div class={containerClass} id={id}>
				<div class={joinClasses(s.displayContainerLightContent, inputFullWidth && s.fullWidth)}>
					<div class={s.activeState} />
					<div class={s.inputTitle}>
						{displayVariables && <span class={s.attributeName}>{displayedAttributeName}</span>}
						<p title={titleTitle}>
							{displayedTitle}
							{!!extraContent && (
								<button class={s.toggleExtraContent} onClick={this.toggleExtraContent}>{this.state.showExtraContent ? hideExtraContentCopy : showExtraContentCopy}</button>
							)}
						</p>
						<span class={s.description}>
							{description}
						</span>
					</div>
					<div class={joinClasses(s.inputWrapper, inputFullWidth && s.fullWidth)}>
						{input}
						{!this.state.disableRemove && <FormRemoveButton updateJSON={updateJSON} path={attrKeyPath} parentType={parentType} dynamicSchemaRef={dynamicSchemaRef} />}
					</div>
				</div>
				{!!this.state.error && (
					<div class={s.errorMessage}>
						ERROR: {this.state.error}
					</div>
				)}
				{(!!extraContent && this.state.showExtraContent) && (
					<div className={s.extraContent}>
						{extraContent}
					</div>
				)}
			</div>
		);
	}

}
